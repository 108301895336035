import { useCallback, useEffect } from 'react'
import debounce from '@mui/material/utils/debounce'

const DEFAULT_DEBOUNCE_DELAY = 400

export const useDelayedWindowResize = <T extends (...args: any[]) => any>(
  callback: T,
  dependencies: ReadonlyArray<any>,
  delay: number = DEFAULT_DEBOUNCE_DELAY
) => {
  const performCallback = useCallback(callback, dependencies) // eslint-disable-line react-hooks/exhaustive-deps

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const windowResized = useCallback(debounce(performCallback, delay), [performCallback])

  useEffect(() => {
    window.addEventListener('resize', windowResized)
    return () => window.removeEventListener('resize', windowResized)
  }, [windowResized])
}
