import { ApiType } from 'src/Types/ApiType'
import type { SystemStatusSeverity, AffectedSystem, SystemStatusAlert } from 'src/Types/SystemStatusAlert'

import { callApiGet } from 'src/msGraphClient'
import { createApiParams } from 'src/Utils/network'
import config from 'src/Config/Config'
import { isProd } from 'src/reactAppEnv'

const { ALERTS } = config.API_FRAGMENTS.apim

type SystemStatusAlertsParams = {
  severity?: SystemStatusSeverity
  includeAzureAlerts?: boolean
  includeTeamsAlerts?: boolean
  affectedSystems?: AffectedSystem[]
}

export const isAlertMarkedAsTest = (alert: SystemStatusAlert) => /\[TEST]/i.test(alert.text)

export const getSystemStatusAlerts = async ({
  severity,
  affectedSystems,
  includeAzureAlerts = true,
  includeTeamsAlerts = true,
}: SystemStatusAlertsParams) => {
  const urlParams = createApiParams({
    states: 'Active',
    includeAzureAlerts,
    includeTeamsAlerts,
    severity,
    affectedSystems: affectedSystems?.join(', '),
  })

  const alerts = await callApiGet<SystemStatusAlert[]>(`${ALERTS}?${urlParams.toString()}`, ApiType.APIM, { parseDates: true })
  return alerts.filter(alert => (isProd ? !isAlertMarkedAsTest(alert) : true))
}
