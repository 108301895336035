import { YesOrNoDialog } from 'src/Components/YesOrNoDialog'
import { Trans } from 'react-i18next'
import { useAppStateStore } from 'src/Store/appState'
import { saveCriticalityReset } from 'src/Providers/AlarmCriticality'
import { useLanguage } from 'src/Hooks/useLanguage'
import type { Asset } from 'src/Types/AssetTypes'
import { getComponentTypeIdOrUndefined } from 'src/Features/CriticalityReset/getComponentTypeIdOrUndefined'

type OwnProps = {
  asset: Asset
  open: boolean
  onClose: () => void
}

export const CriticalityResetDialog = ({ asset, open, onClose }: OwnProps) => {
  const { t } = useLanguage()
  const queueMessage = useAppStateStore(state => state.appMessages.queueMessage)
  const { baneDataId, name, baneDataLocationName, assetType } = asset

  const handleAcceptCriticalityReset = async () => {
    try {
      const componentTypeId = getComponentTypeIdOrUndefined(assetType)
      if (!componentTypeId) {
        queueMessage({
          type: 'error',
          message: 'assets.criticalityReset.badComponentTypeMessage',
        })
        return
      }
      await saveCriticalityReset(baneDataId, componentTypeId)
    } catch (error) {
      queueMessage({
        type: 'error',
        message: 'assets.criticalityReset.failureMessage',
      })
      return
    }
    queueMessage({
      type: 'success',
      raw: true,
      message: t('assets.criticalityReset.successMessage', {
        name,
        baneDataLocationName,
      }),
    })
    onClose()
  }

  return (
    <YesOrNoDialog open={open} onAccept={handleAcceptCriticalityReset} onReject={onClose} onClose={onClose}>
      <Trans i18nKey="assets.criticalityReset.confirmationQuestion" values={{ name, baneDataLocationName }} />
    </YesOrNoDialog>
  )
}
