import Grid from '@mui/material/Grid'

import type { SensorTwin } from 'src/Types/SensorTwin'
import type { TabModelColumn } from 'src/Features/SwitchHeat/TabModel'
import type { SensorCode } from 'src/Types/SensorCode'

import { SensorValue } from 'src/Components/AssetCard/Sensor/SensorValue'
import { SubHeaderWithExpandableInfo } from 'src/Components/SubHeaderWithExpandableInfo'
import { SubHeaderKey } from 'src/Providers/Settings/SubHeader'
import { getColumnGroupedSensors } from 'src/Features/SwitchHeat/getColumnGroupedSensors'
import { AlarmCountChip } from 'src/Components/AlarmCountChip'
import { useLanguage } from 'src/Hooks/useLanguage'

type OwnProps = {
  sensors: SensorTwin[]
  columns: TabModelColumn[]
  title: string
  index: number
  sensorCodes: SensorCode[]
}

export const SwitchHeatTab = ({ sensors, columns = [], sensorCodes = [], title, index }: OwnProps) => {
  const { t } = useLanguage()
  const columnGrouped = getColumnGroupedSensors(sensors, columns)

  const displayedSensors = Object.values(columnGrouped).flat()

  if (displayedSensors.length === 0) {
    return null
  }

  const alarmSensorCodeSystems = sensorCodes.filter(c => c.category.name === 'Alarm').map(c => c.key)
  const alarmSensors = displayedSensors.filter(sensor => alarmSensorCodeSystems.includes(sensor.codeSystem))
  const alarmSensorsWithError = alarmSensors.filter(sensor => sensor.currentValue)

  const hideAlarmsText = t('alarms.hide', {
    classification: '',
  })
  const hideText = `${hideAlarmsText} ${title?.toLowerCase()}`
  return (
    <SubHeaderWithExpandableInfo
      title={
        <>
          {title} <AlarmCountChip alarmCount={alarmSensorsWithError.length} />
        </>
      }
      hideText={hideText}
      subHeaderKey={`${SubHeaderKey.SwitchHeatTemperatureInfo}_${index}` as any}
    >
      <Grid container alignItems="flex-start">
        {Object.entries(columnGrouped).map(([column, sensors]) => {
          return (
            <Grid container direction="column" wrap="nowrap" item sm={12} md={4} key={column}>
              {sensors.map(sensor => (
                <Grid key={sensor.codeSystem} item>
                  <SensorValue codeSystem={sensor.codeSystem} sensor={sensor} domain="SVV" />
                </Grid>
              ))}
            </Grid>
          )
        })}
      </Grid>
    </SubHeaderWithExpandableInfo>
  )
}
