import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import List from '@mui/material/List'
import PlaceIcon from '@mui/icons-material/PlaceOutlined'
import Box from '@mui/material/Box'

import { ButtonWithPopover } from 'src/Components/ButtonWithPopover'
import { RailwaySelector } from './RailwaySelector'
import { RailwaySectionSelector } from './RailwaySectionSelector'

import { useAppStateStore } from 'src/Store/appState'
import { useDeepRailways } from 'src/Hooks/NetworkData/useDeepRailways'
import { getFirstPendingLocation } from './getFirstPendingLocation'
import { getRailwaysViewModel } from './getRailwaysViewModel'
import { LocationSearchInput } from 'src/Components/Header/Locations/LocationSearchInput'
import type { RailwayModel } from 'src/Components/Header/Locations/types'
import { StretchLocationsSelector } from 'src/Components/Header/Locations/StretchLocationsSelector'
import { LocationButton } from 'src/Components/Header/Locations/LocationButton'

const StyledList = styled(List<'nav'>)(
  ({ theme }) => `
  font-size: 14px;
  padding: ${theme.spacing(0, 1, 1, 1)};
`
)

const TEXT_SEARCH_MATCH_MAX_COUNT = 100

export const LocationsSelector = () => {
  const { t } = useTranslation()

  const [isPopoverOpen, setOpenPopover] = useState(false)
  const [textSearchFilter, setTextSearchFilter] = useState('')
  const pendingStretchLocationIds = useAppStateStore(state => state.locations.pendingStretchLocationIds)
  const openRailwayId = useAppStateStore(state => state.locations.openRailwayId)
  const { data: railways } = useDeepRailways()

  const { railwayModel, textSearchMatchCount } = getRailwaysViewModel({
    railways: railways || [],
    pendingStretchLocationIds,
    openRailwayId,
    textSearchFilter,
  })

  const hasTextSearch = !!textSearchFilter.length
  const selectedRailway = railwayModel.find(railway => railway.isOpened)
  const numSelected = pendingStretchLocationIds.length
  const firstLocation = getFirstPendingLocation(railwayModel)
  const title = firstLocation?.name ?? t('location.title')

  const renderRailwaySections = (railway: RailwayModel) => {
    if (!hasTextSearch) {
      return null
    }
    return (
      <Box ml={2}>
        {railway.railwaySections
          .filter(railwaySection => railwaySection.searchTreeMatch)
          .map(railwaySection => (
            <StretchLocationsSelector
              railwaySection={railwaySection}
              stretchLocations={railwaySection.locations}
              isTextSearchMode
              key={railwaySection.railwaySectionId}
            />
          ))}
      </Box>
    )
  }

  function getPopoverContent() {
    if (hasTextSearch) {
      const hasNoTextSearchMatches = textSearchMatchCount === 0
      const hasTooManyTextSearchMatches = textSearchMatchCount > TEXT_SEARCH_MATCH_MAX_COUNT
      const displayResults = !hasNoTextSearchMatches && !hasTooManyTextSearchMatches
      return displayResults ? (
        <RailwaySelector
          railways={railwayModel}
          renderRailwaySections={renderRailwaySections}
          isTextSearchMode
          onListItemClicked={() => setTextSearchFilter('')}
        />
      ) : (
        <Box m={2}>
          <i>
            {hasNoTextSearchMatches && t('general.noMatches')}
            {hasTooManyTextSearchMatches && t('location.textSearch.tooManyMatches')}
          </i>
        </Box>
      )
    }

    return (
      <>
        <RailwaySelector railways={railwayModel} />
        {selectedRailway && <RailwaySectionSelector railway={selectedRailway} />}
      </>
    )
  }

  return (
    <ButtonWithPopover
      isPopoverOpen={isPopoverOpen}
      onOpenPopover={setOpenPopover}
      buttonContent={<LocationButton buttonContent={title} numSelected={numSelected} />}
      buttonEndIcon={<PlaceIcon />}
      popoverContent={
        <>
          <LocationSearchInput locationSearchFilter={textSearchFilter} setLocationSearchFilter={setTextSearchFilter} />
          <StyledList component="nav" aria-labelledby="nested-list-location-selector">
            {getPopoverContent()}
          </StyledList>
        </>
      }
    />
  )
}
