import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

import type { AppMessage } from 'src/Types/AppMessage'
import type { PendingDatePeriod } from 'src/Types/DatePeriod'

interface AppState {
  appMessages: {
    messages: AppMessage[]
    queueMessage: (message: AppMessage) => void
    dismissCurrentMessage: () => void
  }

  locations: {
    openRailwayId: string | undefined
    pendingStretchLocationIds: string[]
    selectedWeatherLocationId: string | undefined
    setOpenedRailwayId: (railwayId: string | undefined) => void
    addPendingStretchLocationId: (...locationIds: string[]) => void
    removePendingStretchLocationId: (...locationIds: string[]) => void
    setPendingStretchLocationIds: (locationIds: string[]) => void
    setSelectedWeatherLocationId: (locationId: string | undefined) => void
  }

  selectedDatePeriod: {
    pendingDatePeriod?: PendingDatePeriod
    lastUpdated?: number
    setLastUpdated: (lastUpdated: number) => void
    changePendingDatePeriod: (datePeriod?: PendingDatePeriod) => void
    changePendingPeriodStart: (datePeriod: number | undefined) => void
    changePendingPeriodEnd: (datePeriod: number | undefined) => void
  }
}

export const useAppStateStore = create<AppState>()(
  immer(set => ({
    appMessages: {
      messages: [],
      queueMessage: message =>
        set(state => {
          state.appMessages.messages.push(message)
        }),
      dismissCurrentMessage: () =>
        set(state => {
          state.appMessages.messages.shift()
        }),
    },

    locations: {
      openRailwayId: undefined,
      pendingStretchLocationIds: [],
      selectedWeatherLocationId: undefined,
      setOpenedRailwayId: railwayId =>
        set(state => {
          state.locations.openRailwayId = railwayId
        }),
      addPendingStretchLocationId: (...locationIds) =>
        set(state => {
          locationIds.forEach(locationId => {
            if (!state.locations.pendingStretchLocationIds.includes(locationId)) {
              state.locations.pendingStretchLocationIds.push(locationId)
            }
          })
        }),
      removePendingStretchLocationId: (...locationIds) =>
        set(state => {
          locationIds.forEach(locationId => {
            state.locations.pendingStretchLocationIds = state.locations.pendingStretchLocationIds.filter(l => l !== locationId)
          })
        }),
      setPendingStretchLocationIds: pendingStretchLocationIds =>
        set(state => {
          state.locations.pendingStretchLocationIds = Array.from(new Set(pendingStretchLocationIds))
        }),
      setSelectedWeatherLocationId: locationId =>
        set(state => {
          state.locations.selectedWeatherLocationId = locationId
        }),
    },

    selectedDatePeriod: {
      pendingDatePeriod: undefined,
      lastUpdated: undefined,
      setLastUpdated: lastUpdated =>
        set(state => {
          state.selectedDatePeriod.lastUpdated = lastUpdated
        }),
      changePendingDatePeriod: pendingDatePeriod =>
        set(state => {
          state.selectedDatePeriod.pendingDatePeriod = pendingDatePeriod
        }),
      changePendingPeriodStart: fromDate =>
        set(state => {
          const pendingDatePeriod = state.selectedDatePeriod.pendingDatePeriod
          if (pendingDatePeriod && typeof pendingDatePeriod !== 'string') {
            pendingDatePeriod.fromDate = fromDate
          }
        }),
      changePendingPeriodEnd: toDate =>
        set(state => {
          const pendingDatePeriod = state.selectedDatePeriod.pendingDatePeriod
          if (pendingDatePeriod && typeof pendingDatePeriod !== 'string') {
            pendingDatePeriod.toDate = toDate
          }
        }),
    },
  }))
)
