import HelpOutlined from '@mui/icons-material/HelpOutlined'

import type { CustomIconProps } from 'src/Components/CustomIcon'
import { CustomIcon } from 'src/Components/CustomIcon'
import { SwingDirection } from 'src/Types/SwingDirectionTypes'

type OwnProps = { direction: SwingDirection } & CustomIconProps

export const SwingDirectionIcon = ({ direction, ...props }: OwnProps) => {
  if (direction === SwingDirection.Unknown) {
    return <HelpOutlined htmlColor={props.color} />
  }

  return (
    <CustomIcon
      {...props} // eslint-disable-line react/jsx-props-no-spreading
      flipX={direction === SwingDirection.Right}
    >
      <path d="M4,4 v6 l2.29,-2.29 4.71,4.7 V20 h2 v-8.41 l-5.29,-5.3 L10,4z" />
    </CustomIcon>
  )
}
