/**
 * Does the same as Math.max, but doesn't throw for big arrays.
 *
 * @param arr - Array to find the largest number in.
 */
export const maxNum = (arr: (number | null)[]) => {
  let max = -Infinity
  for (let i = 0; i < arr.length; i++) {
    const val = arr[i]
    if (val !== null && val > max) {
      max = val
    }
  }
  return max
}

/**
 * Does the same as Math.min, but doesn't throw for big arrays.
 *
 * @param arr - Array to find the smallest number in.
 */
export const minNum = (arr: (number | null)[]) => {
  let min = Infinity
  for (let i = 0; i < arr.length; i++) {
    const val = arr[i]
    if (val !== null && val < min) {
      min = val
    }
  }
  return min
}

/**
 * Clamps a number to a range.
 *
 * @param num - Number to clamp.
 * @param min - Minimum value.
 * @param max - Maximum value.
 */
export const clamp = (num: number, min: number, max: number) => {
  if (min > max) {
    throw new Error('Invalid clamp range')
  }
  return Math.min(max, Math.max(min, num))
}

/**
 * Rounds a number to the closest step.
 *
 * @param num - Number to round.
 * @param step - Step value.
 */
export const roundToStep = (val: number, step = 1.0) => {
  const inv = 1.0 / step
  return Math.round(val * inv) / inv
}
