import type { ReactNode, SyntheticEvent } from 'react'
import { styled } from '@mui/material/styles'
import MUIAlert from '@mui/material/Alert'

import type { AlertSeverity } from 'src/Types/AlertSeverity'
import type { MuiAlertVariant } from 'src/Types/MuiTypes'

import { ignoreStyleProps } from 'src/Utils/style'
import themeColors from 'src/theme'
import type { WindowStatus } from 'src/Types/AssetTypes'

const SEVERITY_MAP: Record<AlertSeverity, [string, string]> = {
  error: [themeColors.errorText, themeColors.errorBackground],
  warning: [themeColors.warning, themeColors.warningBackground],
  info: [themeColors.info, themeColors.infoBackground],
  success: [themeColors.successText, themeColors.successBackground],
}

const CONTEXT_MAP: Record<WindowStatus, [string, string]> = {
  IrregularData: [themeColors.irregularDataText, themeColors.irregularData],
  Maintenance: [themeColors.noContactText, themeColors.noContactSemiLight],
  Out_of_Order: [themeColors.noContactText, themeColors.noContactSemiLight],
  NoContact: [themeColors.noContactText, themeColors.noContactSemiLight],
}

const StyledAlert = styled(
  MUIAlert,
  ignoreStyleProps('fullWidth', 'context')
)<StyleProps>(props => {
  const color = props.context ? CONTEXT_MAP[props.context][0] : SEVERITY_MAP[props.severity][0]
  const backgroundColor = props.context ? CONTEXT_MAP[props.context][1] : SEVERITY_MAP[props.severity][1]
  let outlineColor = backgroundColor
  if (props.variant === 'outlined') {
    outlineColor = color
  }
  return `
  border-radius: 0;
  width: ${props.fullWidth && '100%'};
  margin: ${props.margin !== undefined && `${props.margin}px`};
  color: ${props.variant === 'outlined' || props.context ? color : '#000'};
  border: 1px solid ${outlineColor};
  background-color: ${props.variant === 'outlined' ? '#fff' : backgroundColor};
  font-weight: bold;
  & div.MuiAlert-icon: {
    font-weight: 'bold';
  }
`
})

type StyleProps = {
  severity: AlertSeverity
  margin?: number
  variant?: MuiAlertVariant
  fullWidth?: boolean
  context?: WindowStatus
}

type OwnProps = StyleProps & {
  className?: string
  action?: ReactNode
  onClose?: (event: SyntheticEvent<Element, Event>) => void
  children: ReactNode
}

export const Alert = ({
  severity,
  margin,
  className,
  variant = 'filled',
  fullWidth = false,
  context = undefined,
  action,
  onClose,
  children,
}: OwnProps) => (
  <StyledAlert
    margin={margin}
    severity={severity}
    variant={variant}
    fullWidth={fullWidth}
    context={context}
    action={action}
    onClose={onClose}
    className={className}
  >
    {children}
  </StyledAlert>
)
