import { callApiGet } from 'src/msGraphClient'

import { ApiType } from 'src/Types/ApiType'
import type { SensorCode, SensorCodeDomain } from 'src/Types/SensorCode'

import config from 'src/Config/Config'

const { SENSOR_CODES } = config.API_FRAGMENTS.apim

export const getSensorCodes = (domain: SensorCodeDomain) => {
  const params = new URLSearchParams({
    domain,
  })
  return callApiGet<SensorCode[]>(`${SENSOR_CODES}?${params.toString()}`, ApiType.APIM)
}
