import axios from 'axios'
import path from 'path-browserify'
import type { AppMetadata } from 'src/Types/AppMetadata'
import { basePath } from 'src/Utils/basePath'
import { envValues } from 'src/reactAppEnv'

export const getBuildTimeAppMetadata = (): AppMetadata => {
  const { VITE_APP_VERSION, VITE_SOURCE_DATE, VITE_SOURCE_HASH, VITE_BUILD_ID, VITE_BUILD_NUMBER, VITE_BUILD_TIMESTAMP } =
    envValues
  return {
    version: VITE_APP_VERSION,
    sourceDate: VITE_SOURCE_DATE,
    sourceHash: VITE_SOURCE_HASH,
    buildId: VITE_BUILD_ID,
    buildNumber: VITE_BUILD_NUMBER,
    buildTimestamp: VITE_BUILD_TIMESTAMP,
  }
}

export const getDeployedAppMetadata = async () => {
  const pathToJson = path.join(basePath, 'meta.json')
  const response = await axios.get<AppMetadata>(`${pathToJson}?${Date.now()}`)
  return response.data
}
