import { getDateRange } from 'src/Features/SelectedDatePeriod/predefinedDatePeriods'
import {
  getOrchestratedSwitchHeatCabinetsAtLocations,
  getOrchestratedSwitchHeatCabinetsWithIds,
} from 'src/Providers/OrchestratedCabinet'
import { useAppStateStore } from 'src/Store/appState'
import { useQueryWithErrorHandling } from './useQueryWithErrorHandling'
import type { AggregatedAssetParams } from 'src/Hooks/NetworkData/useAggregatedAssets'

export const useSwitchHeatCabinetsWithAlarms = ({
  datePeriod,
  idType,
  ids,
  onlyWithAlarms = false,
  enableAllAlarmTypes = false,
  enabled = true,
}: AggregatedAssetParams) => {
  const lastUpdated = useAppStateStore(state => state.selectedDatePeriod.lastUpdated)

  const query = useQueryWithErrorHandling(
    ['switchHeatCabinetsWithAlarms', { idType, ids, datePeriod, onlyWithAlarms, enableAllAlarmTypes, lastUpdated, enabled }],
    () => {
      if (!datePeriod || !enabled) {
        return []
      }
      const dateRange = getDateRange(datePeriod)
      if (idType === 'baneDataLocationIds') {
        return getOrchestratedSwitchHeatCabinetsAtLocations(dateRange, ids, onlyWithAlarms, enableAllAlarmTypes)
      }

      return getOrchestratedSwitchHeatCabinetsWithIds(dateRange, ids, onlyWithAlarms, enableAllAlarmTypes)
    },
    {
      enabled: ids.length > 0,
    },
    'switchHeat.fetchError'
  )

  return {
    ...query,
    switchHeatCabinetsWithAlarms: query.data,
  }
}
