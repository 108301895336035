import type { ElementType } from 'react'
import Box from '@mui/material/Box'
import { purifyHtml } from 'src/Utils/purifyHtml'

type OwnProps = {
  rawHTML: string
  component?: ElementType
}

export const PurifiedHtml = ({ rawHTML, component = 'span' }: OwnProps) => {
  return <Box component={component} dangerouslySetInnerHTML={{ __html: purifyHtml(rawHTML) }} />
}
