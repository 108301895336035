import type { SystemStatus } from 'src/Types/SystemStatus'
import { AssetType } from 'src/Types/AssetTypes'
import { getAllPossibleAlarmClassifications } from 'src/Utils/alarms'
import { getAllPossibleCurrentWindowStatuses } from 'src/Utils/asset'

export const ALL_CURRENT_STATUS_OPTIONS: SystemStatus[] = [
  'Ok',
  ...getAllPossibleAlarmClassifications(),
  'Unknown',
  ...getAllPossibleCurrentWindowStatuses(),
  'NotMonitored',
]

export const ALL_SYSTEM_OPTIONS = [
  AssetType.TrackCircuit,
  AssetType.SwitchPointMachine,
  AssetType.SwitchMachineHeat,
  AssetType.SwitchHeat,
  AssetType.Bender,
  AssetType.Rack,
]
