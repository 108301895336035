import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

import { LegendBand } from 'src/Components/Chart/Legends/LegendBand'

import { useBreakpointDown } from 'src/Hooks/useBreakpoint'
import { ignoreStyleProps } from 'src/Utils/style'
import themeColors from 'src/theme'

const StyledPlainContainer = styled('div', ignoreStyleProps('isMediaMD'))<{ isMediaMD: boolean }>`
  display: flex;
  margin-left: ${props => (props.isMediaMD ? 4 : 16)}px;
`

const StyledLegendEntry = styled('div')`
  display: flex;
  align-items: center;
  padding: 2px 10px;
  margin-bottom: ${props => props.theme.spacing(1)};

  > span {
    margin-left: 6px;
    ${props => props.theme.breakpoints.down('sm')} {
      font-size: 12px;
    }
  }
`

export const WeatherLegend = () => {
  const { t } = useTranslation()
  const isMediaMD = useBreakpointDown('md')

  return (
    <StyledPlainContainer isMediaMD={isMediaMD}>
      <StyledLegendEntry>
        <LegendBand color={themeColors.primary} />
        <span>{t('weatherChart.tooltipTemperature')}</span>
      </StyledLegendEntry>
      <StyledLegendEntry>
        <LegendBand color={themeColors.chartColors.wind} />
        <span>{t('weatherChart.tooltipWindSpeed')}</span>
      </StyledLegendEntry>
      <StyledLegendEntry>
        <LegendBand color={themeColors.weather.rain} />
        <span>{t('weatherChart.tooltipPrecipitation')}</span>
      </StyledLegendEntry>
    </StyledPlainContainer>
  )
}
