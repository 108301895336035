import { ApiType } from 'src/Types/ApiType'
import type { SwitchHeatCabinet } from 'src/Types/SwitchHeatCabinet'
import type { HistoricSensorHistoryValue, SensorHistory, SensorTwinData, SensorTwinModus } from 'src/Types/SensorTwin'

import { callApiGet, callApiPatch } from 'src/msGraphClient'
import config from 'src/Config/Config'

const {
  SWITCH_HEAT_CONNECTED_SWITCHES,
  SWITCH_HEAT_CABINETS_BY_SWITCH,
  CURRENT_CABINET_VALUES,
  CURRENT_CABINET_VALUES_V2,
  HISTORY,
  HISTORY_V2,
  HISTORIC_VALUES,
  HISTORIC_VALUES_V2,
  ADJUST_HEAT,
  ADJUST_HEAT_V2,
  MODUS,
  MODUS_UPDATE,
} = config.API_FRAGMENTS.apim

export type SwitchHeatApiVersions = 'v1' | 'v2'

export const getSwitchHeatSensorDataForCabinet = (baneDataId: string, version: SwitchHeatApiVersions) => {
  const urlParams = new URLSearchParams({ baneDataId })

  return callApiGet<SensorTwinData>(
    `${version === 'v2' ? CURRENT_CABINET_VALUES_V2 : CURRENT_CABINET_VALUES}?${urlParams.toString()}`,
    ApiType.APIM,
    { parseDates: true }
  )
}

type SwitchHeatHistoryParams = {
  baneDataId: string
  codeSystemKeys: string[]
  fromDate: number
  toDate: number
  version: SwitchHeatApiVersions
}

export const getSwitchHeatSensorHistory = ({
  baneDataId,
  codeSystemKeys,
  fromDate,
  toDate,
  version,
}: SwitchHeatHistoryParams) => {
  const urlParams = new URLSearchParams({
    baneDataId,
    codeSystemKey: codeSystemKeys.join(','),
    fromDate: new Date(fromDate).toJSON(),
    toDate: new Date(toDate).toJSON(),
  })
  return callApiGet<SensorHistory[]>(`${version === 'v2' ? HISTORY_V2 : HISTORY}?${urlParams.toString()}`, ApiType.APIM)
}

export type ConnectedSwitch = {
  heatCabinetId: string
  baneDataId: string
  switchBaneDataName: string
}

export const getConnectedSwitches = (switchHeatCabinetBaneDataId: string) =>
  callApiGet<ConnectedSwitch[]>(`${SWITCH_HEAT_CONNECTED_SWITCHES}/${switchHeatCabinetBaneDataId}`, ApiType.APIM)

export const getConnectedCabinet = (baneDataId: string) =>
  callApiGet<SwitchHeatCabinet[]>(`${SWITCH_HEAT_CABINETS_BY_SWITCH}/${baneDataId}`, ApiType.APIM, { parseDates: true })

export const getHistoricHeatSensorValues = (
  baneDataId: string,
  codeSystemKeys: string[],
  date: number,
  version: SwitchHeatApiVersions
) => {
  const urlParams = new URLSearchParams({
    baneDataId,
    codeSystemKey: codeSystemKeys.join(','),
    fromDate: new Date(date).toJSON(),
  })

  return callApiGet<HistoricSensorHistoryValue[]>(
    `${version === 'v2' ? HISTORIC_VALUES_V2 : HISTORIC_VALUES}?${urlParams.toString()}`,
    ApiType.APIM
  )
}

export const getHistoricHeatSensorValuesForSwitch = (
  baneDataId: string,
  codeSystemKeys: string[],
  date: number,
  version: SwitchHeatApiVersions
): Promise<HistoricSensorHistoryValue[] | undefined> =>
  getConnectedCabinet(baneDataId).then(res =>
    res.length ? getHistoricHeatSensorValues(res[0].baneDataId, codeSystemKeys, date, version) : undefined
  )

type AdjustHeatValueParams = {
  sensorId: string
  eTag: string
  requestedValue: number
  version: SwitchHeatApiVersions
}

type AdjustHeatValueRequestBody = {
  requestedValue: number
  eTag?: string
}

export const adjustHeatValue = ({ sensorId, eTag, requestedValue, version }: AdjustHeatValueParams) => {
  const urlParams = new URLSearchParams({
    sensorId,
    ignoreResponse: 'false',
  })

  const body: AdjustHeatValueRequestBody = { requestedValue }
  if (version === 'v2') {
    body.eTag = eTag
  }
  return callApiPatch<HistoricSensorHistoryValue[]>(
    `${version === 'v2' ? ADJUST_HEAT_V2 : ADJUST_HEAT}?${urlParams.toString()}`,
    ApiType.APIM,
    body
  )
}

export const getSwitchHeatModus = (sensorId: string) => {
  const urlParams = new URLSearchParams({ sensorId })

  return callApiGet<SensorTwinModus[]>(`${MODUS}?${urlParams.toString()}`, ApiType.APIM, { parseDates: true })
}

type SetSwitchHeatModusBody = {
  previousRequestedValue: number | undefined
}

type SetSwitchHeatModusParams = {
  sensorId: string
  modus: SensorTwinModus
}

export const setSwitchHeatModus = ({ sensorId, modus }: SetSwitchHeatModusParams) => {
  const urlParams = new URLSearchParams({
    sensorId,
  })

  const body: SetSwitchHeatModusBody = { previousRequestedValue: undefined }
  return callApiPatch<unknown>(`${MODUS_UPDATE}/${modus}?${urlParams.toString()}`, ApiType.APIM, body)
}
