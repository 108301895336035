import { useState } from 'react'
import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'

import { ExpandCollapseButton } from 'src/Components/ExpandCollapseButton'
import { SensorValue } from 'src/Components/AssetCard/Sensor/SensorValue'
import { CardInformationEntry } from 'src/Components/AssetCard/CardInformationEntry'
import { SubHeaderWithExpandableInfo } from 'src/Components/SubHeaderWithExpandableInfo'
import { Divider } from 'src/Components/Divider'

import type { SensorTwin } from 'src/Types/SensorTwin'
import type { SwitchHeatCabinet } from 'src/Types/SwitchHeatCabinet'

import { useBreakpointDown } from 'src/Hooks/useBreakpoint'
import { useLanguage } from 'src/Hooks/useLanguage'
import { formatLocalDateTime } from 'src/Utils/format'
import { SubHeaderKey } from 'src/Providers/Settings/SubHeader'

const leftCol = ['PLSComError', 'LOG_FUNCTION', 'LOG_CTRMODE', 'IsMax']

const centerCol = ['TempControl', 'TempLeftTrack', 'TempRightTrack', 'SetpointTrack', 'SetpointCompensated']

const rightCol = ['TempCabinet', 'TempOut', 'Effort']

const predefinedSensors = [...leftCol, ...centerCol, ...rightCol]

type OwnProps = {
  switchHeatCabinet: SwitchHeatCabinet
  sensors: SensorTwin[]
}

export const SwitchHeatTemperatureInfo = ({ switchHeatCabinet, sensors }: OwnProps) => {
  const { t } = useLanguage()

  const isMediaMD = useBreakpointDown('md')

  const [expanded, setExpanded] = useState(false)

  const expandCollapseText = expanded ? t('switchHeat.sensorInfo.hideMore') : t('switchHeat.sensorInfo.showMore')

  const mapSensor = (codeSystem: string) => {
    const sensor = sensors.find(sensor => sensor.codeSystem === codeSystem)

    return sensor ? (
      <Grid key={codeSystem} item>
        <SensorValue codeSystem={codeSystem} sensor={sensor} domain="SVV" />
      </Grid>
    ) : undefined
  }

  return (
    <SubHeaderWithExpandableInfo
      title={t('switchHeat.temperatureInfo')}
      isDefaultExpanded
      subHeaderKey={SubHeaderKey.SwitchHeatTemperatureInfo}
    >
      <Grid container alignItems="flex-start">
        <Grid container direction="column" wrap="nowrap" item sm={12} md={4}>
          {leftCol.map(mapSensor)}
          {isMediaMD && <Divider margin={4} />}
        </Grid>
        <Grid container direction="column" wrap="nowrap" item sm={12} md={4}>
          {centerCol.map(mapSensor)}
        </Grid>
        <Grid container direction="column" wrap="nowrap" item sm={12} md={4}>
          <Grid item>
            {isMediaMD && <Divider margin={4} />}
            <CardInformationEntry
              label={t('switchHeat.sensorInfo.producer')}
              value={switchHeatCabinet.cabinetClassName ? switchHeatCabinet.cabinetClassName : t('switchHeat.sensorInfo.unknown')}
              longValue
            />
          </Grid>
          <Grid item>
            <CardInformationEntry
              label={t('general.installDate')}
              value={
                switchHeatCabinet.installDate
                  ? formatLocalDateTime(switchHeatCabinet.installDate, true)
                  : t('switchHeat.sensorInfo.unknown')
              }
              longValue
            />
          </Grid>
          {rightCol.map(mapSensor)}
        </Grid>
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider margin={4} />
        <Grid container>
          {sensors
            .filter(sensor => !predefinedSensors.includes(sensor.codeSystem))
            .map(sensor => sensor.codeSystem)
            .map(codeSystem => (
              <Grid item xs={12} sm={12} md={6} lg={4} key={codeSystem}>
                {mapSensor(codeSystem)}
              </Grid>
            ))}
        </Grid>
      </Collapse>
      <Grid container justifyContent="center">
        <Grid item>
          <ExpandCollapseButton
            isExpanded={expanded}
            onToggleExpand={() => setExpanded(isExpanded => !isExpanded)}
            text={expandCollapseText}
          />
        </Grid>
      </Grid>
      <Divider margin={4} />
    </SubHeaderWithExpandableInfo>
  )
}
