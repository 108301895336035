import { SwitchAlarmsAndSwings } from './SwitchAlarmsAndSwings'
import { SwitchCardSwitchHeatStatus } from './SwitchCardSwitchHeatStatus'
import { PointMachineHeatStatus } from './PointMachineHeat/PointMachineHeatStatus'
import { ErrorBoundary } from 'src/Components/ErrorBoundary'

import { AssetType } from 'src/Types/AssetTypes'
import type { AssetWithAlarms } from 'src/Types/AssetWithAlarms'
import type { DatePeriod } from 'src/Types/DatePeriod'

type OwnProps = {
  assetWithAlarms: AssetWithAlarms
  datePeriod: DatePeriod
}

export const SwitchCardExpandedContent = ({ assetWithAlarms, datePeriod }: OwnProps) => {
  const { asset } = assetWithAlarms
  const { baneDataId, assetType } = asset

  const isDerailerCard = assetType === AssetType.DerailerMachine
  const isPointMachineCard = assetType === AssetType.SwitchPointMachine

  return (
    <ErrorBoundary>
      {(isDerailerCard || isPointMachineCard) && (
        <SwitchAlarmsAndSwings assetWithAlarms={assetWithAlarms} datePeriod={datePeriod} />
      )}
      {isPointMachineCard && <SwitchCardSwitchHeatStatus baneDataId={baneDataId} datePeriod={datePeriod} />}
      {!isDerailerCard && (
        <PointMachineHeatStatus switchBaneDataId={baneDataId} datePeriod={datePeriod} isDefaultExpanded={!isPointMachineCard} />
      )}
    </ErrorBoundary>
  )
}
