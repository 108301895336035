import { useTranslation } from 'react-i18next'
import type { SensorCode } from 'src/Types/SensorCode'

import type { SensorTwin } from 'src/Types/SensorTwin'

import { hoursToTimeSpan, formatNumber } from 'src/Utils/format'

type SensorDisplayProps = {
  value: SensorTwin['currentValue']
  code: SensorCode
}

export const ValueDisplay = ({ value, code }: SensorDisplayProps) => {
  const { t } = useTranslation()

  const formatValue = () => {
    if (typeof value === 'number') {
      if (['DegreesCelsius', 'Percent'].includes(code.metric.unit)) {
        return `${formatNumber(value, 1)} ${code.metric.displayName}`
      }

      if (['KiloWattHour', 'Watt'].includes(code.metric.unit)) {
        return `${value} ${code.metric.displayName}`
      }

      if (code.metric.unit === 'Minute') {
        return `${value} ${t('general.minutes', { count: value })}`
      }

      if (code.metric.unit === 'Hour') {
        const { weeks, days, hours } = hoursToTimeSpan(value)
        const formattedWeeks = `${weeks > 0 ? `${weeks} ${t('general.weeks', { count: weeks })}, ` : ''}`
        const formattedDays = `${weeks > 0 || days > 0 ? `${days} ${t('general.days', { count: days })}, ` : ''}`
        return `${formattedWeeks}${formattedDays}${hours} ${t('general.hours', { count: hours })}`
      }

      return value
    }

    return ''
  }

  return <>{formatValue()}</>
}
