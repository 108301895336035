import { isPlainObject } from '@mui/utils'

import type { Favorite } from 'src/Types/Favorite'
import type { UserSettings } from 'src/Types/UserSettings'

import { loadFrontendSettings, saveFrontendSettings } from 'src/Providers/FrontendSettings'
import { parseFavorites } from 'src/Utils/favorites'
import { validateSettings } from 'src/Features/UserSettings/validateSettings'
import type { PageFilters } from 'src/Types/PageFilters'
import { parsePageFilters } from 'src/Providers/Settings/parsePageFilters'

const USER_SETTINGS_AREA = 'userSettings'
const FAVORITES_AREA = 'favorites'
const FILTERS_AREA = 'filters'

export const getRemoteUserSettings = async () => {
  const settings = await loadFrontendSettings(USER_SETTINGS_AREA)
  return validateSettings(settings)
}

export const storeRemoteUserSettings = async (userSettings: UserSettings) => {
  await saveFrontendSettings(USER_SETTINGS_AREA, JSON.stringify(userSettings))
}

export const getRemoteFavorites = async () => {
  const data = await loadFrontendSettings(FAVORITES_AREA)
  let hasSavedFavorites = false
  let parsedFavorites: Favorite[] | undefined
  if (isPlainObject(data)) {
    const { favorites } = data
    hasSavedFavorites = Array.isArray(favorites)
    parsedFavorites = hasSavedFavorites ? parseFavorites(favorites) : undefined
  }
  return parsedFavorites
}

export const storeRemoteFavorites = async (favorites: Favorite[]) => {
  await saveFrontendSettings(FAVORITES_AREA, JSON.stringify({ favorites }))
}

export const getPageFilters = async () => {
  const data = await loadFrontendSettings(FILTERS_AREA)
  let parsed: PageFilters[] | undefined
  if (isPlainObject(data)) {
    const { filters } = data
    parsed = parsePageFilters(filters)
  }
  return parsed
}

export const storePageFilters = async (filters: PageFilters[]) => {
  await saveFrontendSettings(FILTERS_AREA, JSON.stringify({ filters }))
}
