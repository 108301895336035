export type SystemStatusState = 'Planned' | 'Active' | 'Closed' | 'Lost'

export type SystemStatusSeverity = 'Info' | 'Warning' | 'Error' | 'Fatal'

export enum AffectedSystem {
  Internal = 'Internal',
  Switch = 'Sporveksel',
  TrackCircuit = 'Sporfelt',
  SwitchHeat = 'Sporvekselvarme',
  Weather = 'Weather',
  Assets = 'Assets',
  Alarms = 'Alarms',
  Statistics = 'Statistics',
  SignalEarthFaults = 'Signaljordfeil',
}

export type SystemStatusAlert = {
  source: string
  state: SystemStatusState
  title: string
  text: string
  severity: SystemStatusSeverity
  affectedSystems: AffectedSystem[]
  fromUtc: number
  toUtc: number
  isCollapsible: boolean
  isDefaultExpanded: boolean
}
