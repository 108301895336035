import DOMPurify from 'dompurify'

let purifier: DOMPurify.DOMPurifyI | undefined

export const purifyHtml = (rawHTML: string) => {
  if (!purifier) {
    purifier = DOMPurify(window)
  }
  return purifier.sanitize(rawHTML, {
    USE_PROFILES: { html: true },
    WHOLE_DOCUMENT: false,
    FORBID_TAGS: ['style', 'font'],
    FORBID_ATTR: ['style'],
  })
}
