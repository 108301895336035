import { styled } from '@mui/material/styles'
import { Dialog, DialogActions, DialogContent } from '@mui/material'
import { Button } from 'src/Components/Button'
import { useTranslation } from 'react-i18next'
import type { ReactNode } from 'react'

type OwnProps = {
  open: boolean
  onAccept: () => void
  onReject: () => void
  onClose: () => void
  children: ReactNode | string
}

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    padding: 0.5em;
  }
`

const StyledDialogContent = styled(DialogContent)`
  font-size: 14px;
`

const StyledDialogActions = styled(DialogActions)`
  justify-content: center;
`

export const YesOrNoDialog = ({ open, onAccept, onReject, onClose, children }: OwnProps) => {
  const { t } = useTranslation()

  return (
    <StyledDialog onClose={onClose} open={open}>
      <StyledDialogContent>{children}</StyledDialogContent>
      <StyledDialogActions>
        <Button onClick={onAccept}>{t('general.yes')}</Button>
        <Button onClick={onReject}>{t('general.no')}</Button>
      </StyledDialogActions>
    </StyledDialog>
  )
}
