import type { UserSettings } from 'src/Types/UserSettings'

import { validateSettings } from 'src/Features/UserSettings/validateSettings'
import * as RemoteSettings from './RemoteSettings'
import { defaultUserSettings } from 'src/Providers/Settings/defaultUserSettings'
import { getFavorites } from 'src/Providers/Settings/Favorites'
import { purgeOldAlarms, getOpenedAlarms } from 'src/Providers/Settings/Alarms'
import { trackException } from 'src/Tracking/trackException'
import { getSubHeaderDefaultExpanded, storeSubHeaderDefaultExpanded } from 'src/Providers/Settings/SubHeader'
import { migrateToSubHeaderDefaultExpanded } from 'src/Providers/Settings/migrateToSubHeaderDefaultExpanded'

export const getUserSettings = async (): Promise<UserSettings> => {
  let remoteUserSettings: UserSettings | undefined
  try {
    remoteUserSettings = await RemoteSettings.getRemoteUserSettings()
  } catch (e) {
    trackException(e)
    remoteUserSettings = undefined
  }

  return {
    ...defaultUserSettings,
    ...validateSettings(remoteUserSettings),
  }
}

export const storeUserSettings = async (settings: UserSettings) => {
  try {
    await RemoteSettings.storeRemoteUserSettings(settings)
  } catch (e) {
    trackException(e)
  }
}

export const getSettings = async () => {
  const [userSettings, favorites] = await Promise.all([getUserSettings(), getFavorites()])
  const openedAlarms = getOpenedAlarms()
  const storedDefaultExpanded = getSubHeaderDefaultExpanded()
  const defaultExpanded = migrateToSubHeaderDefaultExpanded(storedDefaultExpanded, userSettings)
  if (typeof storedDefaultExpanded === 'undefined') {
    storeSubHeaderDefaultExpanded(defaultExpanded)
  }

  return {
    userSettings,
    favorites,
    openedAlarms: purgeOldAlarms(openedAlarms, userSettings.recentAlarmPeriod),
    defaultExpanded,
  }
}
