import { styled } from '@mui/material/styles'
import { startOfDay } from 'date-fns/startOfDay'
import { endOfDay } from 'date-fns/endOfDay'
import { startOfToday } from 'date-fns/startOfToday'
import { endOfToday } from 'date-fns/endOfToday'
import { addDays } from 'date-fns/addDays'
import { subDays } from 'date-fns/subDays'
import { isToday } from 'date-fns/isToday'
import AppBar from '@mui/material/AppBar'
import Slide from '@mui/material/Slide'

import { WeatherTimeline } from 'src/Features/Weather/WeatherTimeline/WeatherTimeline'
import { MainHeader } from './MainHeader'

import { useAppStateStore } from 'src/Store/appState'
import { QueryStringStateKeys, useQueryStringState } from 'src/Store/useQueryStringState'
import { getDateRange } from 'src/Features/SelectedDatePeriod/predefinedDatePeriods'
import { useWeather } from 'src/Hooks/NetworkData/useWeather'
import { useScrollTrigger } from 'src/Hooks/useScrollTrigger'
import { useBreakpointDown } from 'src/Hooks/useBreakpoint'

const startOfPrevWeek = () => subDays(startOfToday(), 7).valueOf()
const endOfNextWeek = () => addDays(endOfToday(), 7).valueOf()

const StyledAppBar = styled(AppBar)`
  z-index: 1202;
  box-shadow: none;
`

export const Header = () => {
  const isSmall = useBreakpointDown('sm')

  const selectedWeatherLocationId = useAppStateStore(state => state.locations.selectedWeatherLocationId)
  const setSelectedWeatherLocationId = useAppStateStore(state => state.locations.setSelectedWeatherLocationId)

  const [selectedDatePeriod] = useQueryStringState(QueryStringStateKeys.datePeriod)
  const weatherDateRange = selectedDatePeriod ? getDateRange(selectedDatePeriod) : undefined
  let weatherFromDate = weatherDateRange?.fromDate
  let weatherToDate = weatherDateRange?.toDate

  if (weatherFromDate && weatherToDate) {
    weatherFromDate = startOfDay(weatherFromDate).valueOf()
    weatherToDate = endOfDay(weatherToDate).valueOf()

    if (isToday(weatherToDate)) {
      if (weatherFromDate >= subDays(startOfToday(), 2).valueOf()) {
        weatherFromDate = startOfPrevWeek()
      }
      weatherToDate = endOfNextWeek()
    }
  }

  const { weather, status: weatherQueryStatus } = useWeather({
    baneDataLocationId: selectedWeatherLocationId,
    fromDate: weatherFromDate,
    toDate: weatherToDate,
  })

  const header = (
    <StyledAppBar position="sticky">
      <MainHeader weather={weather} queryStatus={weatherQueryStatus} />
      {selectedWeatherLocationId && (
        <WeatherTimeline
          weather={weather}
          queryStatus={weatherQueryStatus}
          selectedWeatherLocationId={selectedWeatherLocationId}
          onChangeSelectedWeatherLocationId={setSelectedWeatherLocationId}
        />
      )}
    </StyledAppBar>
  )

  const smallHeaderVisible = !useScrollTrigger({ revealThreshold: 300 })

  return isSmall ? (
    <Slide appear={false} direction="down" in={smallHeaderVisible}>
      {header}
    </Slide>
  ) : (
    header
  )
}
