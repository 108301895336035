import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

import { Checkbox } from 'src/Components/Checkbox'
import { assetName } from 'src/Components/AssetCard/assetName'

import type { Asset } from 'src/Types/AssetTypes'
import type { Filter, AssetIdFilter } from 'src/Types/Filter'
import { FilterType } from 'src/Types/Filter'

import themeColors from 'src/theme'

type OwnProps = {
  filter?: AssetIdFilter
  assets: Asset[]
  onFilterUpdated: (filter: Filter) => void
}

const useStyles = makeStyles()(theme => ({
  dropdown: {
    margin: theme.spacing(1),
    width: '100%',
  },
  entry: {
    color: themeColors.text,
  },
}))

export const AssetIdFilterSelector = ({ onFilterUpdated, assets, filter }: OwnProps) => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const sortedAssetsByLocation = assets.sort((a, b) => a.baneDataLocationName.localeCompare(b.baneDataLocationName))

  const selectedIds = sortedAssetsByLocation.filter(a => filter?.values.includes(`${a.baneDataId}-${a.assetType}`)) || []
  const idsUpdated = (values: string[]) =>
    onFilterUpdated({
      type: FilterType.AssetId,
      values,
    })

  return (
    <Autocomplete
      className={classes.dropdown}
      multiple
      disableCloseOnSelect
      options={sortedAssetsByLocation}
      groupBy={option => option.baneDataLocationName}
      getOptionLabel={option => assetName(option, t)}
      noOptionsText={t('general.noMatches')}
      value={selectedIds}
      onChange={(event, values) => {
        const assetIdOptions = values.map(a => `${a.baneDataId}-${a.assetType}`)
        idsUpdated(assetIdOptions)
      }}
      renderOption={(props, option, { selected }) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <li {...props} style={{ padding: '6px 16px' }}>
          <Checkbox checked={selected} />
          <span className={classes.entry}>{assetName(option, t)}</span>
        </li>
      )}
      renderInput={params => (
        <TextField
          {...params} // eslint-disable-line react/jsx-props-no-spreading
          placeholder={t('general.search')}
          label={t('sideMenu.filterLabel.assetId')}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
      limitTags={2}
    />
  )
}
