import Box from '@mui/material/Box'

import { RackSectionStream } from './RackSectionStream/RackSectionStream'
import { BenderStream } from './BenderStream/BenderStream'

import type { AssetWithAlarms } from 'src/Types/AssetWithAlarms'

type OwnProps = {
  racksWithAlarms: AssetWithAlarms[]
  bendersWithAlarms: AssetWithAlarms[]
}

export const StreamView = ({ racksWithAlarms, bendersWithAlarms }: OwnProps) => {
  return (
    <>
      <BenderStream bendersWithAlarms={bendersWithAlarms} />
      <Box mt={4}>
        <RackSectionStream racksWithAlarms={racksWithAlarms} />
      </Box>
    </>
  )
}
