import { ApiType } from 'src/Types/ApiType'
import { AssetType } from 'src/Types/AssetTypes'

import type { DateRange } from 'src/Types/DatePeriod'
import type { AssetWithAlarms } from 'src/Types/AssetWithAlarms'
import { InternalAlarmSystem } from 'src/Types/AlarmSystem'
import type { OrchestratedPointMachineSwitch, SwitchObject } from 'src/Types/SwitchObject'
import type { GetOrchestratedAssetsParams } from 'src/Types/GetOrchestratedAssetsParams'

import { callApiGet } from 'src/msGraphClient'
import { getOrchestratedAssetIdParams } from 'src/Utils/asset'
import { getCriticalityValue } from 'src/Utils/criticality'
import config from 'src/Config/Config'

const { POINT_MACHINE_SWITCHES, DERAILERS } = config.API_FRAGMENTS.apim

export type PointMachineOrDerailerMachine = AssetType.DerailerMachine | AssetType.SwitchPointMachine

// Point machine switches and derailer machines
const createAsset = (
  assetData: OrchestratedPointMachineSwitch['assetData'],
  status: OrchestratedPointMachineSwitch['status'],
  switchAssetData: OrchestratedPointMachineSwitch['switchAssetData'] | undefined,
  assetType: PointMachineOrDerailerMachine
): SwitchObject => {
  return {
    assetType,
    baneDataId: assetData.baneDataId,
    name: assetData.name,
    baneDataLocationName: assetData.locationName,
    baneDataLocationId: assetData.locationId,
    componentCriticalityLocal: getCriticalityValue(assetData, 'ComponentCriticalityLocal'),
    alarmCriticalityAbsolute: getCriticalityValue(assetData, 'AlarmCriticalityAbsolute'),
    totalCriticalityAbsolute: getCriticalityValue(assetData, 'TotalCriticalityAbsolute'),
    assetStatusWindows: status.assetStatusWindows,
    isMonitored: status.isMonitored,
    statusWithoutAssetStatusWindows: status.statusWithoutAssetStatusWindows,
    statusWithAssetStatusWindows: status.statusWithAssetStatusWindows,
    showCriticality: status.showCriticality,
    dailyStatus: status.dailyStatus.map(ds => ({
      fromDateTime: ds.fromDateTime,
      toDateTime: ds.toDateTime,
      statusWithoutAssetStatusWindows: ds.statusWithoutAssetStatusWindows,
      statusWithAssetStatusWindows: ds.statusWithAssetStatusWindows,
    })),
    switchType: switchAssetData?.switchType,
    fromKm: assetData.fromKm,
    toKm: assetData.toKm,
  }
}

type GetGenericMachineParams = GetOrchestratedAssetsParams & {
  assetType: PointMachineOrDerailerMachine
}

/**
 * Get point machine switches or derailer machines
 */
const getGenericMachine = (params: GetGenericMachineParams): Promise<AssetWithAlarms[]> => {
  const urlParams = new URLSearchParams({
    fromDate: new Date(params.dateRange.fromDate).toJSON(),
    toDate: new Date(params.dateRange.toDate).toJSON(),
    onlyWithAlarms: params.onlyWithAlarms.toString(),
    feFilterEnabled: (!params.enableAllAlarmTypes).toString(),
  })

  const { idKey, value } = getOrchestratedAssetIdParams(params)
  urlParams.set(idKey, value)

  const endpoint = params.assetType === AssetType.DerailerMachine ? DERAILERS : POINT_MACHINE_SWITCHES

  return callApiGet<OrchestratedPointMachineSwitch[]>(`${endpoint}?${urlParams.toString()}`, ApiType.APIM, {
    parseDates: true,
  })
    .then(res =>
      res.map<AssetWithAlarms>(({ assetData, aggregateAlarms, status, switchAssetData }) => ({
        asset: createAsset(assetData, status, switchAssetData, params.assetType),
        alarms: aggregateAlarms.map(alarm => ({
          ...alarm,
          internalAlarmSystem: InternalAlarmSystem.AggregatedPointMachineAlarm,
        })),
      }))
    )
    .then(result => result.sort((a, b) => a.asset.name.localeCompare(b.asset.name, config.STRING_LOCALE)))
}

export const getGenericMachineAtLocations = (
  dateRange: DateRange,
  locationIds: string[],
  onlyWithAlarms: boolean,
  enableAllAlarmTypes: boolean,
  assetType: PointMachineOrDerailerMachine
) => getGenericMachine({ dateRange, locationIds, onlyWithAlarms, enableAllAlarmTypes, assetType })

export const getGenericMachineForBaneDataIds = (
  dateRange: DateRange,
  baneDataIds: string[],
  onlyWithAlarms: boolean,
  enableAllAlarmTypes: boolean,
  assetType: PointMachineOrDerailerMachine
) => getGenericMachine({ dateRange, baneDataIds, onlyWithAlarms, enableAllAlarmTypes, assetType })
