import { MenuItem } from 'src/Components/UIComponents'
import WifiOffOutlinedIcon from '@mui/icons-material/WifiOffOutlined'
import TroubleshootOutlinedIcon from '@mui/icons-material/TroubleshootOutlined'
import type { MouseEvent } from 'react'
import { useLanguage } from 'src/Hooks/useLanguage'
import { camelCase } from 'src/Utils/string'
import { AssetStatusEntityStatus } from 'src/Types/AssetStatusEntry'

export type ManualAssetStatusOperation = 'enter' | 'exit'

type OwnProps = {
  onClick?: (event: MouseEvent) => void
  status: AssetStatusEntityStatus
  operation: ManualAssetStatusOperation
  disableMenuItem: boolean
}

export const ManualAssetStatusMenuItemButton = ({ onClick, status, operation, disableMenuItem }: OwnProps) => {
  const { t } = useLanguage()

  const renderIconForStatus = () => {
    switch (status) {
      case AssetStatusEntityStatus.IrregularData:
        return <TroubleshootOutlinedIcon />

      case AssetStatusEntityStatus.NoContact:
        return <WifiOffOutlinedIcon />

      default:
        return null
    }
  }

  const getTitleForDisabled = () => {
    if (disableMenuItem) {
      switch (status) {
        case AssetStatusEntityStatus.Maintenance:
        case AssetStatusEntityStatus.OutOfOrder:
        case AssetStatusEntityStatus.NoContact:
          return t('manualAssetStatus.popoverTextWhenDisabled.noContact')

        case AssetStatusEntityStatus.IrregularData:
          return t('manualAssetStatus.popoverTextWhenDisabled.irregularData')

        default:
          break
      }
    }

    return undefined
  }

  return (
    <span title={getTitleForDisabled()}>
      <MenuItem onClick={onClick} disabled={disableMenuItem}>
        {renderIconForStatus()}
        {t(`manualAssetStatus.${operation}AssetStatusButton.${camelCase(status)}`)}
      </MenuItem>
    </span>
  )
}
