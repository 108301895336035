import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress'

const StyledLoader = styled('div')`
  margin-top: 20px;
  text-align: center;

  & > * {
    vertical-align: middle;
    margin-right: 4px;
  }
`

type OwnProps = {
  size?: number
  text?: string
}

export const FullWidthLoader = ({ size = 25, text }: OwnProps) => {
  const { t } = useTranslation()

  return (
    <StyledLoader>
      <CircularProgress color="inherit" size={size} /> {text || t('general.loading')}
    </StyledLoader>
  )
}
