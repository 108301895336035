import type { Asset } from 'src/Types/AssetTypes'
import { useState } from 'react'
import { CriticalityResetMenuItemButton } from 'src/Components/AssetCard/AssetCardActionsMenu/CriticalityResetMenuItemButton'
import { CriticalityResetDialog } from 'src/Features/CriticalityReset/CriticalityResetDialog'

type OwnProps = {
  asset: Asset
}

export const CriticalityResetMenuItem = ({ asset }: OwnProps) => {
  const [open, setOpen] = useState(false)
  const handleClick = () => setOpen(!open)
  const handleClose = () => setOpen(false)

  return (
    <>
      <CriticalityResetMenuItemButton onClick={handleClick} />
      <CriticalityResetDialog asset={asset} open={open} onClose={handleClose} />
    </>
  )
}
