import * as d3 from 'd3'

export const createTimeMultiFormat = (locale: d3.TimeLocaleObject) => {
  const formatMillisecond = locale.format('.%L')
  const formatSecond = locale.format('%H:%M:%S')
  const formatMinute = locale.format('%H:%M')
  const formatHour = locale.format('%H:%M')
  const formatWeek = locale.format('%d. %b')
  const formatMonth = locale.format('%B')
  const formatYear = locale.format('%Y')

  /* eslint-disable no-nested-ternary */
  return (value: string | number) => {
    const date = new Date(value)
    return (
      d3.timeSecond(date) < date
        ? formatMillisecond
        : d3.timeMinute(date) < date
          ? formatSecond
          : d3.timeHour(date) < date
            ? formatMinute
            : d3.timeDay(date) < date
              ? formatHour
              : d3.timeMonth(date) < date
                ? formatWeek
                : d3.timeYear(date) < date
                  ? formatMonth
                  : formatYear
    )(date)
  }
  /* eslint-enable no-nested-ternary */
}
