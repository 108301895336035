import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { makeStyles } from 'tss-react/mui'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import ArrowDownIcon from '@mui/icons-material/ArrowDownwardOutlined'

import { Button } from 'src/Components/Button'
import { Checkbox } from 'src/Components/Checkbox'
import { NeighborsIcon } from 'src/Components/NeighborsIcon'
import { TrackCircuitInfoPopper } from './TrackCircuitInfoPopper'

import type { TrackCircuit } from 'src/Types/TrackCircuitTypes'
import type { PopperReferenceObject } from 'src/Types/PopperReferenceObject'

import { useBreakpointDown } from 'src/Hooks/useBreakpoint'
import { flexibleTranslation } from 'src/Utils/language'
import themeColors from 'src/theme'

type HoveredTrackCircuit = {
  element: PopperReferenceObject
  trackCircuit: TrackCircuit
}

type OwnProps = {
  trackCircuits: TrackCircuit[]
  pendingTrackCircuitIds: string[]
  onSelectionUpdated: (selected: string[]) => void
  onAddTrackCircuits: (tcBaneDataIds: string[]) => void
  loading: boolean
}

const useStyles = makeStyles()(theme => ({
  chipContainer: {
    display: 'flex',
  },
  neighborsBtn: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },
  neighborsIcon: {
    marginLeft: 4,
    cursor: 'pointer',
    '& .disabled': {
      cursor: 'inherit',
    },
  },
  loading: {
    marginTop: 16,
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
    },
    '& .MuiIconButton-root': {
      marginTop: -5,
    },
  },
  arrowDownIcon: {
    borderRadius: 25,
    padding: 5,
    color: '#fff',
    backgroundColor: themeColors.secondary,
    '&:disabled': {
      backgroundColor: themeColors.disabled,
      color: themeColors.disabledText,
    },
    '&:hover': {
      backgroundColor: themeColors.tertiary,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  dropdown: {
    width: '100%',
  },
  dropdownOption: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
}))

export const TrackCircuitSelector = ({
  trackCircuits,
  pendingTrackCircuitIds,
  onSelectionUpdated,
  onAddTrackCircuits,
  loading,
}: OwnProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const isMediaSM = useBreakpointDown('sm')

  const [hoveredTrackCircuit, setHoveredTrackCircuit] = useState<HoveredTrackCircuit>()

  const removeTrackCircuit = (tcId: string) => onSelectionUpdated(pendingTrackCircuitIds.filter(otherId => otherId !== tcId))

  const showTrackCircuitInfo = (tcBaneDataId: string, element: PopperReferenceObject) => {
    const trackCircuit = trackCircuits.find(tc => tc.baneDataId === tcBaneDataId)
    if (trackCircuit) {
      setHoveredTrackCircuit({
        element,
        trackCircuit,
      })
    }
  }

  const hideTrackCircuitInfo = () => setHoveredTrackCircuit(undefined)

  const toggleTrackCircuitInfo = (tcBaneDataId: string, element: PopperReferenceObject) => {
    if (hoveredTrackCircuit?.trackCircuit.baneDataId === tcBaneDataId) {
      hideTrackCircuitInfo()
    } else {
      showTrackCircuitInfo(tcBaneDataId, element)
    }
  }

  const neighborsIconButton = (trackCircuit: TrackCircuit, showText: boolean = false) => {
    const neighborIds = trackCircuit.neighbors.filter(n => !!n.m40TrackCircuit).map(n => n.baneDataId)
    const hasNeighbors = neighborIds.length > 0
    const canAddNeighbors = neighborIds.some(neighborId => !pendingTrackCircuitIds.includes(neighborId))

    const disabledText = hasNeighbors ? t('trackCircuits.allNeighborsAlreadyAdded') : t('trackCircuits.chart.noNeighbors')

    const icon = (
      <NeighborsIcon
        className={clsx(classes.neighborsIcon, {
          disabled: !canAddNeighbors,
        })}
        title={canAddNeighbors ? t('trackCircuits.addNeighbors') : disabledText}
        color={canAddNeighbors ? themeColors.primary : themeColors.disabled}
        onClick={() => {
          if (canAddNeighbors) {
            onAddTrackCircuits(neighborIds)
          }
        }}
      />
    )

    if (!showText) {
      return icon
    }

    let neighborsButtonText = disabledText
    if (canAddNeighbors) {
      neighborsButtonText = t(flexibleTranslation('trackCircuits.addNeighbors', isMediaSM))

      const selfAlreadyAdded = pendingTrackCircuitIds.includes(trackCircuit.baneDataId)
      if (!selfAlreadyAdded) {
        neighborsButtonText += t('trackCircuits.andTrackCircuit', { trackCircuit: trackCircuit.m40TrackCircuit.tcId })
      }
    }

    return (
      <Button
        variant="outlined"
        buttonClass={classes.neighborsBtn}
        size={isMediaSM ? 'small' : 'medium'}
        disabled={!canAddNeighbors}
        onClick={event => {
          if (canAddNeighbors) {
            event.stopPropagation()
            const idsToAdd = neighborIds.concat(trackCircuit.baneDataId)
            onAddTrackCircuits(idsToAdd)
          }
        }}
      >
        {neighborsButtonText}
        {icon}
      </Button>
    )
  }

  const renderTags = (selected: string[]) =>
    selected.map(baneDataId => {
      const tc = trackCircuits.find(t => t.baneDataId === baneDataId)

      if (!tc) {
        return <span key={baneDataId} />
      }

      return (
        <span key={baneDataId} className={classes.chipContainer}>
          <Chip
            label={`${tc.baneDataLocationName} ${tc.m40TrackCircuit.tcId}`}
            size="small"
            icon={
              <InfoIcon
                color="primary"
                onMouseEnter={event => {
                  showTrackCircuitInfo(tc.baneDataId, event.currentTarget)
                }}
                onMouseLeave={hideTrackCircuitInfo}
                onClick={event => {
                  event.stopPropagation()
                  toggleTrackCircuitInfo(tc.baneDataId, event.currentTarget)
                }}
              />
            }
            onDelete={() => removeTrackCircuit(baneDataId)}
          />
          {neighborsIconButton(tc)}
        </span>
      )
    })

  const filterOptions = (options: string[], { inputValue }: { inputValue?: string }) => {
    if (options.length === 0 || inputValue) {
      return inputValue
        ? options.filter(baneDataId => {
            const tc = trackCircuits.find(t => t.baneDataId === baneDataId)
            return (
              !tc ||
              tc.m40TrackCircuit.tcId.toLowerCase().includes(inputValue.toLowerCase()) ||
              tc.baneDataLocationName.toLowerCase().includes(inputValue.toLowerCase())
            )
          })
        : options
    }
    return [t('general.selectAll'), ...options]
  }

  return (
    <>
      <Autocomplete
        id="track-circuits-dropdown"
        className={classes.dropdown}
        multiple
        disableCloseOnSelect
        autoComplete
        autoHighlight
        disabled={loading}
        options={trackCircuits.map(tc => tc.baneDataId)}
        filterOptions={filterOptions}
        groupBy={option => trackCircuits.find(tc => tc.baneDataId === option)?.baneDataLocationName || ''}
        value={pendingTrackCircuitIds}
        onChange={(event, values) => {
          const isSelectAll = values.some(baneDataId => baneDataId === t('general.selectAll'))
          if (isSelectAll) {
            if (trackCircuits.length === pendingTrackCircuitIds.length) {
              onSelectionUpdated([])
            } else {
              onSelectionUpdated(trackCircuits.map(tc => tc.baneDataId))
            }
          } else {
            onSelectionUpdated(values)
          }
        }}
        popupIcon={<ArrowDownIcon className={classes.arrowDownIcon} />}
        renderTags={renderTags}
        renderOption={(props, option, { selected }) => {
          const isSelectAll = option === t('general.selectAll')
          const tc = trackCircuits.find(t => t.baneDataId === option)
          const checked = selected || (isSelectAll && trackCircuits.length === pendingTrackCircuitIds.length)

          return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <li {...props}>
              <span className={classes.dropdownOption}>
                <Checkbox checked={checked} />
                {isSelectAll ? t('general.selectAll') : tc?.m40TrackCircuit.tcId}
              </span>
              {tc && neighborsIconButton(tc, true)}
            </li>
          )
        }}
        renderInput={params => (
          <TextField
            {...params} // eslint-disable-line react/jsx-props-no-spreading
            disabled={loading}
            placeholder={t('general.searchPlaceholder')}
            label={loading ? t('trackCircuits.loading') : t('trackCircuits.trackCircuitsDropdown')}
            className={classes.textField}
            variant="outlined"
          />
        )}
      />
      {hoveredTrackCircuit && (
        <TrackCircuitInfoPopper trackCircuit={hoveredTrackCircuit.trackCircuit} anchorElement={hoveredTrackCircuit.element} />
      )}
    </>
  )
}
