import type { ReactNode } from 'react'
import { Component } from 'react'
import { withAITracking } from '@microsoft/applicationinsights-react-js'

import { aiReactPlugin } from './telemetryService'

type OwnProps = {
  children: ReactNode
}

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking
 * that requires this to be a Class Component rather than a Functional Component
 */
class TelemetryProviderComponent extends Component<OwnProps> {
  render() {
    return this.props.children
  }
}

export const TelemetryProvider = withAITracking(aiReactPlugin, TelemetryProviderComponent)
