import { useState } from 'react'
import type { QueryStatus } from 'react-query'
import { useTranslation } from 'react-i18next'
import { eachDayOfInterval } from 'date-fns/eachDayOfInterval'
import { makeStyles } from 'tss-react/mui'
import Collapse from '@mui/material/Collapse'

import { WeatherTimelineSkeleton } from './WeatherTimelineSkeleton'
import { WeatherTimelineCard } from './WeatherTimelineCard/WeatherTimelineCard'
import { WeatherTimelineFooter } from './WeatherTimelineFooter'
import { MultiCarousel } from 'src/Components/Header/MultiCarousel'
import { CustomToolbar } from 'src/Components/CustomToolbar'

import type { Weather } from 'src/Types/WeatherTypes'

import { useUserProfileStore } from 'src/Store/userProfile'
import { useBreakpointUp } from 'src/Hooks/useBreakpoint'
import themeColors from 'src/theme'

const useStyles = makeStyles()(theme => ({
  weatherTimeline: {
    backgroundColor: themeColors.primary,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    minHeight: 35,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  collapse: {
    overflow: 'unset',
    width: '100%',
    cursor: 'pointer',
    userSelect: 'none',
  },
  weatherItem: {
    width: 120,
    height: '100%',
  },
  horizontalScroll: {
    display: 'inline-flex',
    float: 'left',
    width: 'auto',
  },
  weatherReference: {
    color: 'white',
    fontSize: 12,
    float: 'right',
    paddingLeft: 16,
    paddingTop: 160,
    width: 230,
    [theme.breakpoints.down('sm')]: {
      fontSize: 8,
    },
  },
  weatherRefLink: {
    color: themeColors.weather.minusDegrees,
    fontSize: 12,
    [theme.breakpoints.down('sm')]: {
      fontSize: 8,
    },
  },
}))

type OwnProps = {
  weather?: Weather[]
  queryStatus: QueryStatus
  selectedWeatherLocationId: string
  onChangeSelectedWeatherLocationId: (locationId: string) => void
}

export const WeatherTimeline = ({
  weather,
  queryStatus,
  selectedWeatherLocationId,
  onChangeSelectedWeatherLocationId,
}: OwnProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const isMediaM = useBreakpointUp(1000)
  const carouselItemLimit = isMediaM ? 9 : 4

  const updateSetting = useUserProfileStore(state => state.settings.updateSetting)
  const weatherDefaultExpanded = useUserProfileStore(state => state.settings.settings.weatherDefaultExpanded)
  const [expanded, setExpanded] = useState(weatherDefaultExpanded)

  if (queryStatus === 'loading') {
    return <WeatherTimelineSkeleton expanded={expanded} />
  }

  if (!weather?.length) {
    return null
  }

  const weatherDays = eachDayOfInterval({
    start: weather[0].fromTime,
    end: weather[weather.length - 1].toTime,
  })

  const handleToggleExpand = () => {
    updateSetting('weatherDefaultExpanded', !expanded)
    setExpanded(!expanded)
  }

  return (
    <CustomToolbar className={classes.weatherTimeline}>
      {!expanded && (
        <WeatherTimelineFooter
          selectedWeatherLocationId={selectedWeatherLocationId}
          expanded={false}
          onToggleExpand={handleToggleExpand}
          onChangeSelectedWeatherLocationId={onChangeSelectedWeatherLocationId}
        />
      )}
      <Collapse in={expanded} timeout="auto" unmountOnExit className={classes.collapse}>
        {expanded && (
          <>
            {weatherDays.length <= carouselItemLimit ? (
              <div className={classes.horizontalScroll}>
                {weatherDays.map(day => (
                  <WeatherTimelineCard key={day.valueOf()} weather={weather} day={day} />
                ))}
              </div>
            ) : (
              <MultiCarousel>
                {weatherDays.map(day => (
                  <div key={day.valueOf()} className={classes.weatherItem}>
                    <WeatherTimelineCard weather={weather} day={day} />
                  </div>
                ))}
                <span className={classes.weatherReference}>
                  {t('weather.iconReference.text')}
                  <a
                    href={t('weather.iconReference.url')}
                    className={classes.weatherRefLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('weather.iconReference.urlText')}
                  </a>
                </span>
              </MultiCarousel>
            )}
            <div>
              <WeatherTimelineFooter
                selectedWeatherLocationId={selectedWeatherLocationId}
                expanded
                onToggleExpand={handleToggleExpand}
                onChangeSelectedWeatherLocationId={onChangeSelectedWeatherLocationId}
              />
            </div>
          </>
        )}
      </Collapse>
    </CustomToolbar>
  )
}
