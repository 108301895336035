import { startOfToday } from 'date-fns/startOfToday'
import { subDays } from 'date-fns/subDays'
import { subWeeks } from 'date-fns/subWeeks'
import { subMonths } from 'date-fns/subMonths'
import { subHours } from 'date-fns'

import type { DatePeriod, PendingDatePeriod, DatePeriodKey, DateRange } from 'src/Types/DatePeriod'

export const predefinedDatePeriodKeys: DatePeriodKey[] = [
  'lastHour',
  'today',
  'last24',
  'lastDays',
  'lastWeek',
  'lastMonth',
  'selectTimePeriod',
]

export const getDateRangeForKey = (key: DatePeriodKey): DateRange => {
  switch (key) {
    case 'lastHour':
      return {
        fromDate: subHours(Date.now(), 1).valueOf(),
        toDate: Date.now(),
      }
    case 'today':
      return {
        fromDate: startOfToday().valueOf(),
        toDate: Date.now(),
      }
    case 'last24':
      return {
        fromDate: subDays(Date.now(), 1).valueOf(),
        toDate: Date.now(),
      }
    case 'lastDays':
      return {
        fromDate: subDays(startOfToday(), 2).valueOf(),
        toDate: Date.now(),
      }
    case 'lastWeek':
      return {
        fromDate: subWeeks(startOfToday(), 1).valueOf(),
        toDate: Date.now(),
      }
    case 'lastMonth':
      return {
        fromDate: subMonths(startOfToday(), 1).valueOf(),
        toDate: Date.now(),
      }
    default:
      return {
        fromDate: startOfToday().valueOf(),
        toDate: Date.now(),
      }
  }
}

const periodIsKey = (datePeriod: DatePeriod | PendingDatePeriod): datePeriod is DatePeriodKey => typeof datePeriod === 'string'

export const getDateRange = (datePeriod: DatePeriod) => (periodIsKey(datePeriod) ? getDateRangeForKey(datePeriod) : datePeriod)

export const getPendingDateRange = (datePeriod: PendingDatePeriod) => {
  if (!periodIsKey(datePeriod)) {
    const { fromDate, toDate } = datePeriod
    const isValidRange = fromDate && toDate && fromDate < toDate

    return isValidRange ? getDateRange(datePeriod as DatePeriod) : undefined
  }

  return getDateRange(datePeriod)
}

export const getDatePeriodKey = (datePeriod: DatePeriod | PendingDatePeriod): DatePeriodKey | undefined =>
  periodIsKey(datePeriod) ? datePeriod : 'selectTimePeriod'
