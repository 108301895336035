import type { TrackCircuitCurrentType, TrackCircuitCurrents, TrackCircuitCurrentsData } from 'src/Types/TrackCircuitCurrentTypes'
import { ApiType } from 'src/Types/ApiType'

import { callApiGet } from 'src/msGraphClient'
import config from 'src/Config/Config'

const { CURRENTS } = config.API_FRAGMENTS.apim

export type GetCurrentMeasurementsParams = {
  trackCircuitBaneDataIds: string[]
  fromDate: number
  toDate: number
  currentType: TrackCircuitCurrentType | 'All'
}

export const getCurrentMeasurements = async ({
  trackCircuitBaneDataIds,
  fromDate,
  toDate,
  currentType = 'All',
}: GetCurrentMeasurementsParams) => {
  const urlParams = new URLSearchParams({
    trackCircuitBaneDataIds: trackCircuitBaneDataIds.join(','),
    fromDate: new Date(fromDate).toJSON(),
    toDate: new Date(toDate).toJSON(),
    currentType,
  })

  const trackCircuitCurrents = await callApiGet<TrackCircuitCurrents[]>(`${CURRENTS}?${urlParams.toString()}`, ApiType.APIM, {
    parseDates: true,
  })

  return trackCircuitCurrents.map<TrackCircuitCurrentsData>(current => ({ ...current, fromDate, toDate, compressionRate: 0 }))
}
