export enum TrackCircuitCurrentType {
  FC = 'FcOnly',
  RC = 'RcOnly',
}

export type TrackCircuitCurrentMeasurement = [timestamp: number, currentMeasurement: number]

export type TrackCircuitCurrentMeasurementAugmented = [timestamp: number, currentMeasurement: number, isInsideGap: boolean]

export type TrackCircuitCompressionRates = 0 | 1 | 2 | 3

export type TrackCircuitCurrents = {
  compressionRate: TrackCircuitCompressionRates
  fcMeasurements: TrackCircuitCurrentMeasurement[]
  rcMeasurements: TrackCircuitCurrentMeasurement[]
  missingFcMeasurements: [number, number][]
  missingRcMeasurements: [number, number][]
  baneDataLocationId: string
  baneDataLocationName: string
  tcId: string
  trackCircuitBaneDataId: string
  station: string
}

export type TrackCircuitCurrentsContract = Omit<TrackCircuitCurrents, 'missingFcMeasurements' | 'missingRcMeasurements'> &
  Partial<Pick<TrackCircuitCurrents, 'missingFcMeasurements' | 'missingRcMeasurements'>>

export type TrackCircuitCurrentsData = TrackCircuitCurrents & {
  fromDate: number
  toDate: number
}
