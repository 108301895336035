/* eslint no-underscore-dangle: 0 */
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import type { Metric } from 'web-vitals'
import { getLCP, getCLS, getFID } from 'web-vitals'
import type { BrowserHistory } from 'history'
import history from 'history/browser'

import { getAccount, msalAuthenticatedPromise } from 'src/msGraphClient'
import { hash64 } from './Utils/misc'
import { getBuildTimeAppMetadata } from 'src/Utils/metadata'
import { getTelemetryRequestTrackingDisabled } from 'src/Providers/SessionSettings'
import { getErrorWithRealStack } from 'src/Tracking/trackException'
import config from 'src/Config/Config'
import { getTelemetryProps } from 'src/Tracking/telemetryProps'

export const aiReactPlugin = new ReactPlugin()

let appInsights: ApplicationInsights | null = null

/**
 * Track Web Vitals as metrics to AI
 * See: https://web.dev/vitals/
 */
const trackWebVitals = (ai: ApplicationInsights) => {
  const tracked: Record<string, boolean> = {}

  const trackMetric = (metric: Metric) => {
    if (tracked[metric.name]) {
      return
    }
    tracked[metric.name] = true
    ai.trackMetric({
      name: `app-web-vitals-${metric.name}`,
      average: metric.delta,
    })
  }

  getLCP(trackMetric)
  getCLS(trackMetric)
  getFID(trackMetric)
}

const setAppInsightsAuthenticatedUserContext = async (ai: ApplicationInsights) => {
  await msalAuthenticatedPromise

  const account = getAccount()
  const email = account?.username
  let didSetAuthentication = false

  if (email) {
    const [, domain] = email.split('@')
    if (email && domain) {
      didSetAuthentication = true
      ai.setAuthenticatedUserContext(hash64(email), domain, true)
    }
  }

  if (!didSetAuthentication) {
    ai.setAuthenticatedUserContext(hash64(`${Math.random() * 10000000}`), 'ukjent', true)
  }
}

const applyStackTracesToOnError = () => {
  const win: any = window
  const oldOnerrorOld = window.onerror
  if (!oldOnerrorOld) {
    return
  }

  const onerror: typeof window.onerror = async (event, source, lineno, colno, error) => {
    let useError = error
    if (error) {
      useError = await getErrorWithRealStack(error)
    }
    oldOnerrorOld?.apply(window, [event, source, lineno, colno, useError])
  }

  win.onerror = onerror
}

const createTelemetryService = (
  instrumentationKey: string,
  browserHistory: BrowserHistory,
  options: { disableRequestTracking?: boolean } = {}
) => {
  const { disableRequestTracking = false } = options

  if (!browserHistory) {
    throw new Error('Could not initialize Telemetry Service')
  }
  if (!instrumentationKey) {
    throw new Error('Instrumentation key not provided')
  }

  const ai = new ApplicationInsights({
    config: {
      instrumentationKey,
      maxBatchInterval: 10000,
      disableFetchTracking: disableRequestTracking,
      disableAjaxTracking: disableRequestTracking,
      extensions: [aiReactPlugin],
      enableCorsCorrelation: true,
      enableUnhandledPromiseRejectionTracking: true,
    },
  })

  const appMetadata = getBuildTimeAppMetadata()
  ai.addTelemetryInitializer(envelope => {
    const { version: appVersion, buildNumber } = appMetadata
    envelope.data = {
      ...envelope.data,
      appVersion,
      buildNumber,
      ...getTelemetryProps(),
    }
  })

  ai.loadAppInsights()

  setAppInsightsAuthenticatedUserContext(ai)

  trackWebVitals(ai)

  applyStackTracesToOnError()

  return ai
}

export const initializeTelemetryService = () => {
  if (config.isLocal) {
    return
  }
  const disableRequestTracking = getTelemetryRequestTrackingDisabled()
  try {
    appInsights = createTelemetryService(config.TELEMETRY_KEY, history, {
      disableRequestTracking,
    })
  } catch (e) {
    console.error(e)
  }
}

export const getAppInsights = () => appInsights
