import type { SystemStatusAlert } from 'src/Types/SystemStatusAlert'
import { parseDowntimeTextPattern } from 'src/Features/SystemStatusAlerts/parseDowntimeTextPattern'

export const extractInfoFromDowntimeText = (systemStatusAlert: SystemStatusAlert) => {
  const cleanText = systemStatusAlert.text.replace(/&nbsp;/g, ' ')
  const textPattern = parseDowntimeTextPattern(cleanText)

  const hidePeriod = textPattern.includes('HidePeriod'.toLowerCase())
  const hideFromOtherPages = textPattern.includes('HideFromOtherPages'.toLowerCase())
  const textToDisplay = cleanText.replace(/\[.*?]\s*/, '')

  return { textToDisplay, hidePeriod, hideFromOtherPages }
}
