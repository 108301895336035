import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import { WiThermometer } from 'weather-icons-react'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import NotificationImportantIcon from '@mui/icons-material/NotificationImportantOutlined'
import FavoriteIcon from '@mui/icons-material/StarOutlined'
import CallSplitIcon from '@mui/icons-material/CallSplitOutlined'
import TrackAlarmIcon from '@mui/icons-material/SettingsEthernetOutlined'
import BenderIcon from '@mui/icons-material/Traffic'

import { IconWithText } from 'src/Components/IconWithText'
import { StyledCircleIcon } from 'src/Components/SidePanelMenu/StyledCircleIcon'

import { useBreakpointDown } from 'src/Hooks/useBreakpoint'
import { ignoreStyleProps } from 'src/Utils/style'
import { flexibleTranslation } from 'src/Utils/language'
import { createPageLink } from 'src/Utils/pageLink'
import themeColors from 'src/theme'
import { useUserProfileStore } from 'src/Store/userProfile'
import { useFeatureOverride } from 'src/Hooks/useFeatureOverride'
import { SessionFeatures } from 'src/Hooks/useSessionFeature'
import { AppPage } from 'src/Types/AppPage'

type OwnProps = {
  isButtonView?: boolean
}

const StyledFormControl = styled(FormControl)(
  props => `
  margin: ${props.theme.spacing(1)};
  width: 100%;
`
)

const StyledGrid = styled(Grid, ignoreStyleProps('isActive'))<{ isActive: boolean }>`
  width: 100%;
  padding-left: 2px;
  position: relative;
  :before {
    content: '';
    position: absolute;
    width: 5px;
    height: 100%;
    left: 4px;
    background-color: ${props => (props.isActive ? `${themeColors.primary}` : 'transparent')};
  }
  :hover:before {
    background-color: ${themeColors.tertiary};
  }
`

const StyledIconButton = styled(IconButton, ignoreStyleProps('isActive'))<{ isActive: boolean }>`
  color: ${props => (props.isActive ? themeColors.primary : themeColors.secondary)};
  font-size: 14px;
  padding-top: 0;
  padding-left: 9px;
  width: 100%;
  min-height: 70px;
  div > svg {
    background-color: ${props => (props.isActive ? themeColors.primary : themeColors.secondary)};
  }
  :hover {
    color: ${themeColors.tertiary};
    background-color: unset;
    div > svg {
      background-color: ${themeColors.tertiary};
    }
  }
`

export const PageSelector = ({ isButtonView = false }: OwnProps) => {
  const { t } = useTranslation()
  const isRewrittenNavigation = useFeatureOverride(SessionFeatures.UseRewrittenNavigation)
  const navigate = useNavigate()
  const location = useLocation()
  const isMediaSM = useBreakpointDown('sm')
  const updateSetting = useUserProfileStore(state => state.settings.updateSetting)
  const isMenuOpen = useUserProfileStore(state => state.settings.settings.isMenuOpen)

  const fontSize = isMediaSM ? 'small' : 'medium'

  const pageListOld = [
    {
      path: AppPage.AlarmCards,
      text: t('alarms.title'),
      icon: <NotificationImportantIcon fontSize={fontSize} />,
      circleIcon: (
        <StyledCircleIcon>
          <NotificationImportantIcon fontSize={fontSize} />
        </StyledCircleIcon>
      ),
    },
    {
      path: AppPage.SwitchesAndHeatCards,
      text: t(flexibleTranslation('switches.title', isMediaSM)),
      icon: (
        <>
          <CallSplitIcon fontSize={fontSize} />
          <WiThermometer size={isMediaSM ? 22 : 24} />
        </>
      ),
      circleIcon: (
        <StyledCircleIcon>
          <CallSplitIcon fontSize={fontSize} />
        </StyledCircleIcon>
      ),
    },
    {
      path: AppPage.TrackCircuitsStream,
      text: t(flexibleTranslation('trackCircuits.title', isMediaSM)),
      icon: <TrackAlarmIcon fontSize={fontSize} />,
      circleIcon: (
        <StyledCircleIcon>
          <TrackAlarmIcon fontSize={fontSize} />
        </StyledCircleIcon>
      ),
    },
    {
      path: AppPage.SignalEarthStream,
      text: t(flexibleTranslation('benders.title', isButtonView)),
      icon: <BenderIcon fontSize={fontSize} />,
      circleIcon: (
        <StyledCircleIcon>
          <BenderIcon fontSize={fontSize} />
        </StyledCircleIcon>
      ),
    },
    {
      path: AppPage.Favorites,
      text: t('favorites.title'),
      icon: <FavoriteIcon fontSize={fontSize} />,
      circleIcon: (
        <StyledCircleIcon>
          <FavoriteIcon fontSize={fontSize} />
        </StyledCircleIcon>
      ),
    },
  ] as const

  const pageListNew = [
    {
      path: AppPage.AlarmCards,
      text: t('alarms.title'),
      icon: <NotificationImportantIcon fontSize={fontSize} />,
      circleIcon: (
        <StyledCircleIcon>
          <NotificationImportantIcon fontSize={fontSize} />
        </StyledCircleIcon>
      ),
    },
    {
      path: AppPage.SwitchesAndHeatCards,
      text: t(flexibleTranslation('switches.title', isMediaSM)),
      icon: (
        <>
          <CallSplitIcon fontSize={fontSize} />
          <WiThermometer size={isMediaSM ? 22 : 24} />
        </>
      ),
      circleIcon: (
        <StyledCircleIcon>
          <CallSplitIcon fontSize={fontSize} />
        </StyledCircleIcon>
      ),
    },
    {
      path: AppPage.TrackCircuitsStream,
      text: t(flexibleTranslation('trackCircuits.titleStream', isMediaSM || isButtonView)),
      icon: <TrackAlarmIcon fontSize={fontSize} />,
      circleIcon: (
        <StyledCircleIcon>
          <TrackAlarmIcon fontSize={fontSize} />
        </StyledCircleIcon>
      ),
    },
    {
      path: AppPage.TrackCircuitsCards,
      text: t(flexibleTranslation('trackCircuits.titleCards', isMediaSM || isButtonView)),
      icon: <TrackAlarmIcon fontSize={fontSize} />,
      circleIcon: (
        <StyledCircleIcon>
          <TrackAlarmIcon fontSize={fontSize} />
        </StyledCircleIcon>
      ),
    },
    {
      path: AppPage.SignalEarthStream,
      text: t(flexibleTranslation('signalEarth.titleStream', isButtonView)),
      icon: <BenderIcon fontSize={fontSize} />,
      circleIcon: (
        <StyledCircleIcon>
          <BenderIcon fontSize={fontSize} />
        </StyledCircleIcon>
      ),
    },
    {
      path: AppPage.SignalEarthCards,
      text: t(flexibleTranslation('signalEarth.titleCards', isButtonView)),
      icon: <BenderIcon fontSize={fontSize} />,
      circleIcon: (
        <StyledCircleIcon>
          <BenderIcon fontSize={fontSize} />
        </StyledCircleIcon>
      ),
    },
    {
      path: AppPage.Favorites,
      text: t('favorites.title'),
      icon: <FavoriteIcon fontSize={fontSize} />,
      circleIcon: (
        <StyledCircleIcon>
          <FavoriteIcon fontSize={fontSize} />
        </StyledCircleIcon>
      ),
    },
  ] as const

  const pageList = isRewrittenNavigation ? pageListNew : pageListOld

  const page =
    pageList.find(t => location.pathname === `/${t.path}`) ||
    pageList.find(t => location.pathname.startsWith(`/${t.path}`)) ||
    pageList[0]
  const currentPage = page.path

  const navigateToPath = (path: AppPage) => {
    if (path === currentPage) {
      updateSetting('isMenuOpen', !isMenuOpen)
    } else {
      navigate(createPageLink(path))
    }
  }

  if (isButtonView) {
    return (
      <Grid container direction="column" justifyContent="flex-start" alignItems="center">
        {pageList.map(page => (
          <StyledGrid item isActive={page.path === currentPage} key={page.path}>
            <StyledIconButton onClick={() => navigateToPath(page.path)} isActive={page.path === currentPage}>
              <IconWithText icon={page.circleIcon} text={page.text} isActive={page.path === currentPage} />
            </StyledIconButton>
          </StyledGrid>
        ))}
      </Grid>
    )
  }

  return (
    <StyledFormControl>
      <InputLabel id="page-selector-label">{t('sideMenu.page')}</InputLabel>
      <Select
        labelId="page-selector-label"
        id="page-selector-dropdown"
        value={currentPage}
        onChange={event => navigateToPath(event.target.value as AppPage)}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        {pageList.map(page => (
          <MenuItem key={page.path} value={page.path}>
            <Box mr={1} mb={-1} sx={{ color: themeColors.primary }}>
              {page.icon}
            </Box>
            {page.text}{' '}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  )
}
