import TrackAlarmIcon from '@mui/icons-material/SettingsEthernetOutlined'
import CallSplitIcon from '@mui/icons-material/CallSplitOutlined'
import BenderIcon from '@mui/icons-material/Traffic'
import RackIcon from '@mui/icons-material/StorageOutlined'
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing'
import { WiThermometer } from 'weather-icons-react'

import { AssetType } from 'src/Types/AssetTypes'

import themeColors from 'src/theme'

type OwnProps = {
  assetType: AssetType
}

export const AssetTypeIcon = ({ assetType }: OwnProps) => {
  switch (assetType) {
    case AssetType.SwitchPointMachine:
    case AssetType.SwitchMachineHeat:
      return <CallSplitIcon />
    case AssetType.TrackCircuit:
      return <TrackAlarmIcon />
    case AssetType.SwitchHeat:
      return <WiThermometer size={30} color={themeColors.primary} />
    case AssetType.Bender:
      return <BenderIcon />
    case AssetType.Rack:
      return <RackIcon />
    case AssetType.DerailerMachine:
      return <CallMissedOutgoingIcon />
    default:
      return null
  }
}
