import type { Filter } from 'src/Types/Filter'
import type { Alarm } from 'src/Types/Alarm'
import type { Asset } from 'src/Types/AssetTypes'
import { AssetType } from 'src/Types/AssetTypes'
import { statusFilterIncludesAssetStatus } from 'src/Utils/filter'

/**
 * Filter alarms on card view pages other than /alarms.
 * This will return unfiltered alarms when status filter contains NoContact or Irregular_Data,
 * or the asset is not a SwitchPointMachine or SwitchMachineHeat.
 * Otherwise, return filtered alarms with nrOfRealAlarms > 0.
 */
export const filterRealAlarms = (filters: Filter[], alarms: Alarm[], asset: Asset) => {
  if (
    statusFilterIncludesAssetStatus(filters) ||
    ![AssetType.SwitchPointMachine, AssetType.SwitchMachineHeat].includes(asset.assetType)
  ) {
    return alarms
  }

  return alarms.filter(a => a.nrOfRealAlarms > 0)
}
