import type { ReactNode } from 'react'
import { useRef } from 'react'
import clsx from 'clsx'
import { makeStyles } from 'tss-react/mui'
import Button from '@mui/material/Button'
import Popover from '@mui/material/Popover'
import themeColors from 'src/theme'

const useStyles = makeStyles()(theme => ({
  button: {
    backgroundColor: '#fff',
    border: `1px solid ${themeColors.hr}`,
    padding: 10,
    marginLeft: 10,
    marginRight: 10,
    minWidth: 250,
    textAlign: 'left',
    justifyContent: 'space-between',
    textTransform: 'inherit',
    borderRadius: 0,
    fontSize: 15,
    fontWeight: 'bold',
    '&:disabled': {
      border: `1px solid ${themeColors.disabled}`,
      backgroundColor: '#fff',
      color: themeColors.disabledText,
    },
    '&:hover': {
      border: `1px solid ${theme.palette.secondary.main}`,
      backgroundColor: '#fff',
    },
  },
}))

type OwnProps = {
  buttonContent: ReactNode
  popoverContent: ReactNode
  buttonClass?: string
  disabled?: boolean
  isPopoverOpen: boolean
  buttonEndIcon?: ReactNode
  className?: StringConstructor
  onOpenPopover: (didOpen: boolean) => void
}

export const ButtonWithPopover = ({
  buttonContent,
  popoverContent,
  isPopoverOpen,
  buttonClass,
  disabled = false,
  buttonEndIcon,
  className,
  onOpenPopover,
}: OwnProps) => {
  const { classes } = useStyles()
  const buttonRef = useRef<HTMLButtonElement>(null)

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => onOpenPopover(true)}
        ref={buttonRef}
        className={clsx(classes.button, buttonClass, className)}
        disabled={disabled}
        endIcon={buttonEndIcon}
      >
        {buttonContent}
      </Button>
      <Popover
        open={isPopoverOpen}
        onClose={() => onOpenPopover(false)}
        anchorEl={buttonRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{ square: true }}
      >
        {popoverContent}
      </Popover>
    </>
  )
}
