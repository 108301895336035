import { differenceInCalendarDays } from 'date-fns/differenceInCalendarDays'

import { ApiType } from 'src/Types/ApiType'
import type { NormalizedAlarm } from 'src/Types/NormalizedAlarm'
import type { DatePeriod } from 'src/Types/DatePeriod'

import { callApiGet } from 'src/msGraphClient'
import config from 'src/Config/Config'

const { ALARMS_BY_EVENT_ID, LAST_ALARMS } = config.API_FRAGMENTS.apim

export type AggregatedPointMachineAlarmsParams = {
  datePeriod: DatePeriod
  idType: 'locationIds' | 'baneDataIds'
  ids: string[]
}

export const getPointMachineAlarmsForSwing = async (switchSwingId: string) => {
  const urlParams = new URLSearchParams({
    alarmSystem: 'PointMachine',
    eventIds: switchSwingId,
  })

  return callApiGet<NormalizedAlarm[]>(`${ALARMS_BY_EVENT_ID}?${urlParams.toString()}`, ApiType.APIM, { parseDates: true }).then(
    alarms => alarms.sort((a, b) => a.fromDateTime - b.fromDateTime)
  )
}

export const getDaysSinceLastAlarm = (switchBaneDataId: string) => {
  const urlParams = new URLSearchParams({
    alarmSystem: 'PointMachine',
    baneDataIds: switchBaneDataId,
  })

  return callApiGet<NormalizedAlarm[]>(`${LAST_ALARMS}?${urlParams.toString()}`, ApiType.APIM, { parseDates: true }).then(
    alarms => {
      if (!alarms.length) {
        return undefined
      }

      const newestTimestamp = Math.max(...alarms.map(alarm => alarm.fromDateTime))
      return differenceInCalendarDays(Date.now(), newestTimestamp)
    }
  )
}
