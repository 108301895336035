import { Chip } from '@mui/material'
import { styled } from '@mui/material/styles'

import themeColors from 'src/theme'

const StyledChip = styled(Chip)`
  border-radius: 25%;
  margin-left: 8px;

  .MuiChip-label {
    color: white;
  }
`

type OwnProps = {
  number: number
  color?: string
}

export const WorkOrderCountChip = ({ number, color = themeColors.orange }: OwnProps) => {
  return <StyledChip label={number} sx={{ backgroundColor: color }} size="small" />
}
