import { styled } from '@mui/material/styles'
import themeColors from 'src/theme'

export const StyledContainer = styled('div')(
  props => `
  border: 1px solid ${themeColors.hr};
  margin: 16px;
  ${props.theme.breakpoints.down('sm')} {
    margin: 16px 0;
    border-left: none;
    border-right: none;
  }
`
)

export const StyledContent = styled('div')`
  & > div:nth-of-type(even) {
    background-color: ${themeColors.sectionAlternateRow};
  }
`
