import type { UserSettings } from 'src/Types/UserSettings'
import { NewAlarmSound } from 'src/Types/NewAlarmSound'

export const defaultUserSettings: UserSettings = {
  isMenuOpen: true,
  weatherDefaultExpanded: true,

  defaultSelectedStatusFilter: [],
  defaultSelectedSystemFilter: [],
  newAlarmSound: NewAlarmSound.None,
  alarmInformationDefaultExpanded: true,
  weatherChartDefaultExpanded: true,
  fcChartOnAlarmsDefaultExpanded: false,
  infoUnderChartsDefaultExpanded: true,
  recentAlarmPeriod: 'week',
  switchChartFullWidth: true,
  hideChartZoomButtons: false,
  showBenderCardView: true,
  showTrackCircuitCardView: false,

  debugPanelDefaultExpanded: false,
  expandedHeader: true,
}
