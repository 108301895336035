/**
 * Partitions an array based on the provided predicate.
 * Truthy values are returned in the first array, falsy in the second.
 *
 * @param arr - Array to partition.
 * @param predicate - Predicate function to determine a value's partition.
 */
export const partition = <T>(arr: T[], predicate: (val: T) => boolean) => {
  const ret: [T[], T[]] = [[], []]
  return arr.reduce((acc, val) => {
    const index = predicate(val) ? 0 : 1
    acc[index].push(val)
    return acc
  }, ret)
}

/**
 * If the array includes the element, remove it. Otherwise add it to the end.
 *
 * @param arr - Array to manipulate.
 * @param val - Value to add or remove.
 */
export const toggleArrayElement = <T>(arr: T[], val: T): T[] => (arr.includes(val) ? arr.filter(s => s !== val) : [...arr, val])

/**
 * Finds an item in an array with the lowest value of a given key.
 *
 * @param arr - Array to search.
 * @param getValue - Function that returns the value to compare in the given item.
 */
export const findLargest = <T>(coll: T[], getValue: (o: T) => number) => {
  if (!coll.length) {
    return undefined
  }

  const values = coll.map(getValue)
  const maxIndex = values.indexOf(Math.max(...values))
  return coll[maxIndex]
}

/**
 * Concatenates arrays and returns array of distinct values.
 */
export const concatDistinct = <T>(...arrays: T[][]) => {
  return Array.from(new Set(arrays.flatMap(x => x)))
}

export const sortArrayOfObjectsByKey = <T, Key extends keyof T>(array: T[], key: Key, order: string): T[] => {
  const sorted = array.sort((a, b) => {
    const type = typeof a[key]

    switch (type) {
      case 'string':
        return String(a[key]).localeCompare(String(b[key]))
      case 'number':
        return +a[key] - +b[key]
      default:
        return 1
    }
  })

  return order === 'asc' ? sorted : sorted.reverse()
}

/**
 * Applies a callback function to each element of an array and returns a new array
 * containing only the values where the callback result is not null or undefined.
 */
export const mapFiltered = <T>(arr: T[], callback: (obj: T) => T | null | undefined): T[] =>
  arr.map(element => callback(element)).filter(result => result !== null && result !== undefined) as T[]

/**
 * Returns the maximum value in an array.
 * This is a more robust alternative to Math.max(...arr) for large arrays.
 * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/max#examples
 */
export const getMax = (arr: number[]) => {
  let i = arr.length
  if (!i) {
    return Number.NEGATIVE_INFINITY
  }
  let max = arr[0]

  // eslint-disable-next-line no-plusplus
  while (i--) {
    max = arr[i] > max ? arr[i] : max
  }
  return max
}

/**
 * Returns the minimum value in an array.
 */
export const getMin = (arr: number[]) => {
  let i = arr.length
  if (!i) {
    return Number.POSITIVE_INFINITY
  }
  let min = arr[0]

  // eslint-disable-next-line no-plusplus
  while (i--) {
    min = arr[i] < min ? arr[i] : min
  }
  return min
}
