import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'

import { SubHeaderWithExpandableInfo } from 'src/Components/SubHeaderWithExpandableInfo'
import { BenderVoltageChart } from 'src/Features/SignalEarthFaults/BenderVoltageChart/BenderVoltageChart'
import { BenderIsolationChart } from 'src/Features/SignalEarthFaults/BenderIsolationChart/BenderIsolationChart'
import { WeatherChart } from 'src/Features/Weather/WeatherChart/WeatherChart'

import type { Bender } from 'src/Types/Bender'

import themeColors from 'src/theme'
import { useChartFocus } from 'src/Components/Chart/useChartFocus'
import { mapToLocations } from 'src/Features/Weather/WeatherChart/mapToLocations'
import { SubHeaderKey } from 'src/Providers/Settings/SubHeader'

const useStyles = makeStyles()(theme => ({
  card: {
    backgroundColor: '#fff',
    borderRadius: 0,
    margin: 16,
    overflow: 'inherit',
    [theme.breakpoints.down('sm')]: {
      margin: '8px 4px',
    },
  },
  cardContent: {
    paddingBottom: 0,
  },
  subSectionContainer: {
    border: `1px solid ${themeColors.sectionHeader}`,
    backgroundColor: '#fff',
    '& > p': {
      paddingLeft: 8,
    },
  },
}))

const CODE_SYSTEM_KEYS_ISOLATION = ['Insulation_resistance']

const CODE_SYSTEM_KEYS_VOLTAGE = ['Voltage_L1-PE', 'Voltage_L3-PE']

type OwnProps = {
  selectedFromDate: number
  selectedToDate: number
  selectedBenders: Bender[]
}

export const BenderStreamCard = ({ selectedFromDate, selectedToDate, selectedBenders }: OwnProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const { chartFocus, zoomUpdated } = useChartFocus({ fromDate: selectedFromDate, toDate: selectedToDate })

  const locations = mapToLocations(selectedBenders)

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <SubHeaderWithExpandableInfo
          title={t('benders.isolationChart.title')}
          subHeaderKey={SubHeaderKey.BenderStreamIsolationChart}
        >
          <div className={classes.subSectionContainer}>
            {selectedBenders.length === 0 && <p>{t('benders.streamIsolationInfo')}</p>}
            {selectedBenders.length > 0 && (
              <BenderIsolationChart
                selectedBenders={selectedBenders}
                datePeriod={{
                  fromDate: selectedFromDate,
                  toDate: selectedToDate,
                }}
                codeSystemKeys={CODE_SYSTEM_KEYS_ISOLATION}
                onZoomUpdated={zoomUpdated}
                view="stream"
              />
            )}
          </div>
        </SubHeaderWithExpandableInfo>
        <SubHeaderWithExpandableInfo title={t('benders.voltageChart.title')} subHeaderKey={SubHeaderKey.BenderStreamVoltageChart}>
          <div className={classes.subSectionContainer}>
            {selectedBenders.length === 0 && <p>{t('benders.streamVoltageInfo')}</p>}
            {selectedBenders.length > 0 && (
              <BenderVoltageChart
                selectedBenders={selectedBenders}
                datePeriod={{
                  fromDate: selectedFromDate,
                  toDate: selectedToDate,
                }}
                codeSystemKeys={CODE_SYSTEM_KEYS_VOLTAGE}
                groupedByBender
                onZoomUpdated={zoomUpdated}
              />
            )}
          </div>
        </SubHeaderWithExpandableInfo>

        <Box sx={{ mt: 2 }}>
          {locations.map(({ locationId, locationName }, index) => (
            <SubHeaderWithExpandableInfo
              title={t('weatherChart.title', { stationName: locationName })}
              hideText={t('weatherChart.hideWeather')}
              forcedClosed={index > 0}
              key={locationId}
              subHeaderKey={SubHeaderKey.WeatherChart}
            >
              <WeatherChart
                baneDataLocationId={locationId}
                fromDate={selectedFromDate}
                toDate={selectedToDate}
                chartFocus={chartFocus}
              />
            </SubHeaderWithExpandableInfo>
          ))}
        </Box>
      </CardContent>
    </Card>
  )
}
