import { AppPage } from 'src/Types/AppPage'
import { AssetType } from 'src/Types/AssetTypes'
import type { SystemStatus } from 'src/Types/SystemStatus'
import { ALL_CURRENT_STATUS_OPTIONS } from 'src/Features/UserSettings/UserSettingConstants'
import { getAllPossibleCurrentWindowStatuses } from 'src/Utils/asset'
import { getAllPossibleAlarmClassifications } from 'src/Utils/alarms'

export type GenericCardListRoutes =
  | AppPage.AlarmCards
  | AppPage.SwitchesAndHeatCards
  | AppPage.SignalEarthCards
  | AppPage.TrackCircuitsCards

export type CardPageConfig = {
  DEFAULT_SYSTEM_OPTIONS: AssetType[]
  AVAILABLE_STATUS_OPTIONS: SystemStatus[]
  DEFAULT_SELECTED_STATUS: SystemStatus[]
  queryLimitOnlyAlarms: boolean
  enableAutoUpdate: boolean
}

const windowStatuses = getAllPossibleCurrentWindowStatuses()
const alarmClassifications = getAllPossibleAlarmClassifications()

export const GENERIC_CARD_PAGE_CONFIG: Record<GenericCardListRoutes, CardPageConfig> = {
  [AppPage.AlarmCards]: {
    DEFAULT_SYSTEM_OPTIONS: [
      AssetType.TrackCircuit,
      AssetType.SwitchPointMachine,
      AssetType.SwitchMachineHeat,
      AssetType.SwitchHeat,
      AssetType.Bender,
      AssetType.Rack,
    ],
    AVAILABLE_STATUS_OPTIONS: [...alarmClassifications, ...windowStatuses],
    DEFAULT_SELECTED_STATUS: [...alarmClassifications, ...windowStatuses],
    queryLimitOnlyAlarms: true,
    enableAutoUpdate: true,
  },
  [AppPage.SwitchesAndHeatCards]: {
    DEFAULT_SYSTEM_OPTIONS: [
      AssetType.SwitchPointMachine,
      AssetType.SwitchMachineHeat,
      AssetType.SwitchHeat,
      AssetType.DerailerMachine,
    ],
    AVAILABLE_STATUS_OPTIONS: ALL_CURRENT_STATUS_OPTIONS,
    DEFAULT_SELECTED_STATUS: ['Ok', ...alarmClassifications, 'Unknown', ...windowStatuses],
    queryLimitOnlyAlarms: false,
    enableAutoUpdate: false,
  },
  [AppPage.SignalEarthCards]: {
    DEFAULT_SYSTEM_OPTIONS: [AssetType.Rack, AssetType.Bender],
    AVAILABLE_STATUS_OPTIONS: ['Ok', ...alarmClassifications, 'NotMonitored', ...windowStatuses],
    DEFAULT_SELECTED_STATUS: ['Ok', ...alarmClassifications, ...windowStatuses],
    queryLimitOnlyAlarms: false,
    enableAutoUpdate: false,
  },
  [AppPage.TrackCircuitsCards]: {
    DEFAULT_SYSTEM_OPTIONS: [AssetType.TrackCircuit],
    AVAILABLE_STATUS_OPTIONS: ['Ok', ...alarmClassifications, 'NotMonitored', ...windowStatuses],
    DEFAULT_SELECTED_STATUS: ['Ok', ...alarmClassifications, ...windowStatuses],
    queryLimitOnlyAlarms: false,
    enableAutoUpdate: false,
  },
}
