import { callApiGet } from 'src/msGraphClient'

import config from 'src/Config/Config'

import type { WorkOrder, WorkOrderCount, WorkOrderStatus, WorkOrderType, WorkOrderDiscipline } from 'src/Types/WorkOrderTypes'
import { ApiType } from 'src/Types/ApiType'

type WorkOrderContract = {
  banedataId: string
  changeBy: string
  changedateInBanedata: string
  comment_LongDescription: string | null
  createdBy: string
  description: string
  eventLogId: string
  id: string
  lead: number | string | null
  plannedTimeOfExecution: string | null
  status: WorkOrderStatus
  supervisor: string | null
  timeOfCompletion: string | null
  timeOfCreation: string
  timeOfLastRowChange: string
  objectName: string | null
  workOrderId: number
  workType: WorkOrderType
  discipline: WorkOrderDiscipline | null
}

type WorkOrdersContract = {
  workOrders: WorkOrderContract[]
  counts: {
    statusCounts: WorkOrderCount[]
    typeCounts: WorkOrderCount[]
  }
}

const { WORK_ORDERS } = config.API_FRAGMENTS.apim

type GetWorkOrdersProps = {
  banedataId: string
  completedOnly?: boolean
  nonCompletedOnly?: boolean
}

const mapWorkOrdersContract = (result: WorkOrdersContract) => {
  const workOrders = result.workOrders.map<WorkOrder>(workOrder => {
    const {
      // eslint-disable-next-line camelcase
      comment_LongDescription,
      status,
      workType,
    } = workOrder
    return {
      ...workOrder,
      // eslint-disable-next-line camelcase
      commentLongDescription: comment_LongDescription,
      status: status as WorkOrderStatus,
      workType: workType as WorkOrderType,
    }
  })

  const counts = result.counts

  return {
    workOrders,
    counts,
  }
}

export const getWorkOrders = async ({ banedataId, completedOnly = false, nonCompletedOnly = false }: GetWorkOrdersProps) => {
  const urlParams = new URLSearchParams({
    banedataId,
    completedOnly: completedOnly.toString(),
    nonCompletedOnly: nonCompletedOnly.toString(),
  })

  const result = await callApiGet<WorkOrdersContract>(`${WORK_ORDERS}?${urlParams.toString()}`, ApiType.APIM)

  return mapWorkOrdersContract(result)
}
