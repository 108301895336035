import { isPlainObject } from '@mui/utils'
import { getLocalSubHeaderDefaultExpanded, storeLocalSubHeaderDefaultExpanded } from 'src/Providers/Settings/LocalSettings'

export enum SubHeaderKey {
  AlarmTable = 'AlarmTable',
  BenderInCircuit = 'BenderInCircuit',
  BenderInfo = 'BenderInfo',
  BenderStatus = 'BenderStatus',
  BenderStreamIsolationChart = 'BenderStreamIsolationChart',
  BenderStreamVoltageChart = 'BenderStreamVoltageChart',
  BenderVoltageChart = 'BenderVoltageChart',
  BendersIsolationChart = 'BendersIsolationChart',
  IrregularDataTable = 'IrregularDataTable',
  NoContactTable = 'NoContactTable',
  PointMachineHeatChart = 'PointMachineHeatChart',
  PointMachinesInfo = 'PointMachinesInfo',
  RackChart = 'RackChart',
  RacksCurrent = 'RacksCurrent',
  SensorDataFeedCurrent = 'SensorDataFeedCurrent',
  SensorDataReturnCurrent = 'SensorDataReturnCurrent',
  SwingInfo = 'SwingInfo',
  SwingWeatherStatus = 'WeatherChart',
  SwitchHeatChart = 'SwitchHeatChart',
  SwitchHeatElements = 'SwitchHeatElements',
  SwitchHeatStatus = 'SwitchHeatStatus',
  SwitchHeatTemperatureForm = 'SwitchHeatTemperatureForm',
  SwitchHeatTemperatureInfo = 'SwitchHeatTemperatureInfo',
  SwitchInfoMinimal = 'SwitchInfoMinimal',
  SwitchStatistics = 'SwitchStatistics',
  SwingChart = 'SwingChart',
  TrackCircuitsFeedCurrent = 'TrackCircuitsFeedCurrent',
  TrackCircuitsInfo = 'TrackCircuitsInfo',
  TrackCircuitsReturnCurrent = 'TrackCircuitsReturnCurrent',
  WarningTable = 'WarningTable',
  WeatherChart = 'WeatherChart',
}

export type SubHeaderDefaultExpanded = Partial<Record<SubHeaderKey, boolean | undefined>>

export const getSubHeaderDefaultExpanded = () => {
  try {
    const value = getLocalSubHeaderDefaultExpanded()
    if (!value) {
      return undefined
    }
    const state = JSON.parse(value)
    if (isPlainObject(state)) {
      return state as SubHeaderDefaultExpanded
    }
    return undefined
  } catch (e) {
    return undefined
  }
}

export const storeSubHeaderDefaultExpanded = (expandedState: SubHeaderDefaultExpanded) => {
  storeLocalSubHeaderDefaultExpanded(expandedState)
}
