const MAX_POINTS_LENGTH = 10

export class GestureDirectionDetector {
  private points: [number, number][] = []

  addPoint(point: [x: number, y: number]) {
    this.points.push(point)
    if (this.points.length > MAX_POINTS_LENGTH) {
      this.points.shift()
    }
  }

  isVerticalMove() {
    if (this.points.length < MAX_POINTS_LENGTH) {
      return undefined
    }

    const diffs = this.points.reduce((agg: [number, number][], curr, index) => {
      if (index > 0) {
        const prev = this.points[index - 1]
        const diffX = curr[0] - prev[0]
        const diffY = curr[1] - prev[1]
        agg.push([diffX, diffY])
      }
      return agg
    }, [])

    const [sumX, sumY] = diffs.reduce(
      ([aggX, aggY], [x, y]) => {
        return [aggX + Math.abs(x), aggY + Math.abs(y)]
      },
      [0, 0]
    )
    const factor = sumX / sumY
    return factor < 0.3
  }

  reset() {
    this.points = []
  }
}
