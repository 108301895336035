import type { ReactNode } from 'react'
import { useState, useEffect, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'

import { Button } from 'src/Components/Button'
import { NoResult } from 'src/Components/NoResult'

import type { DateRange } from 'src/Types/DatePeriod'

import { QueryStringStateKeys, useQueryStringState } from 'src/Store/useQueryStringState'
import { useSelectedDateRange } from 'src/Hooks/useSelectedDateRange'
import { StreamViewSelectorContainer } from 'src/Components/StreamView/StreamViewSelectorContainer'

const useStyles = makeStyles()({
  fetchButton: {
    width: '100%',
  },
})

type QueryStringStateKey = typeof QueryStringStateKeys.rsIds | typeof QueryStringStateKeys.benderIds

type RenderSelectorOptions = {
  pendingIds: string[]
  onSelectionUpdated: (selectedIds: string[]) => void
}

type RenderSelectorComponent = (options: RenderSelectorOptions) => ReactNode

type RenderContentOptions = {
  selectedIds: string[]
  dateRange: DateRange
}

type RenderContentComponent = (options: RenderContentOptions) => ReactNode

type OwnProps = {
  queryStateKey: QueryStringStateKey
  validIds: string[]
  renderSelectorComponent: RenderSelectorComponent
  renderContentComponent: RenderContentComponent
  noneFoundText: string
}

const distinctJoin = (values: string[]) => [...new Set(values.sort())].toString()

const isSameSelection = (a: string[], b: string[]) => distinctJoin(a) === distinctJoin(b)

/**
 * Common logic used for stream view components handling query state and layout.
 */
export const StreamContainer = ({
  queryStateKey,
  validIds,
  renderSelectorComponent,
  renderContentComponent,
  noneFoundText,
}: OwnProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const [selectedStretchLocations] = useQueryStringState(QueryStringStateKeys.stretchLocations)
  const [selectedIds, setSelectedIds] = useQueryStringState(queryStateKey)

  const [updateTimestamp, setUpdateTimestamp] = useState(() => Date.now())

  const [pendingIds, setPendingIds] = useState<string[]>(() => selectedIds)

  const selectedDateRange = useSelectedDateRange([updateTimestamp])

  const updateChart = useCallback(() => setUpdateTimestamp(Date.now()), [])

  const updatePending = useCallback(
    (baneDataIds: string[]) => {
      const valid = validIds.filter(baneDataId => baneDataIds.includes(baneDataId))
      setPendingIds([...new Set(valid)])
    },
    [validIds]
  )

  const validatedPendingIds = useMemo(() => validIds.filter(id => pendingIds.includes(id)), [validIds, pendingIds])

  const validatedSelectedIds = useMemo(() => validIds.filter(id => selectedIds.includes(id)), [validIds, selectedIds])

  useEffect(() => {
    const hasValidSelectedIdsChanged = !isSameSelection(validatedSelectedIds, selectedIds)
    if (hasValidSelectedIdsChanged) {
      setSelectedIds(validatedSelectedIds.length ? validatedSelectedIds : undefined)
    }
  }, [selectedStretchLocations]) // eslint-disable-line react-hooks/exhaustive-deps

  const pendingChanged = !isSameSelection(pendingIds, selectedIds)
  const fetchButtonText =
    selectedIds.length === 0 || pendingChanged ? t('general.loadChartButton') : t('general.refreshChartButton')

  if (!validIds.length) {
    return <NoResult>{noneFoundText}</NoResult>
  }

  return (
    <>
      <StreamViewSelectorContainer
        selector={renderSelectorComponent({
          pendingIds: validatedPendingIds,
          onSelectionUpdated: updatePending,
        })}
        button={
          <Button
            buttonClass={classes.fetchButton}
            onClick={() => {
              if (pendingChanged) {
                setSelectedIds(validatedPendingIds)
              } else {
                updateChart()
              }
            }}
            disabled={validatedPendingIds.length === 0}
          >
            {fetchButtonText}
          </Button>
        }
      />
      {selectedDateRange &&
        renderContentComponent({
          selectedIds: validatedSelectedIds,
          dateRange: selectedDateRange,
        })}
    </>
  )
}
