import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import { styled } from '@mui/system'

import { ignoreStyleProps } from 'src/Utils/style'
import { useUserProfileStore } from 'src/Store/userProfile'
import { useCardIdFromUrl } from 'src/Hooks/useCardIdFromUrl'
import { QueryStringStateKeys, useQueryStringState } from 'src/Store/useQueryStringState'

type OwnProps = {
  filterMenu: ReactNode
  contentHeader: ReactNode
  children: ReactNode
}

type StyledContentProps = {
  isMenuOpen: boolean
  isSingleCardView: boolean
}

const StyledMainContent = styled(
  'div',
  ignoreStyleProps('isMenuOpen', 'isSingleCardView')
)<StyledContentProps>(({ isMenuOpen, isSingleCardView, theme }) => {
  let marginLeft = isMenuOpen ? 300 : 95
  let marginTop = 32
  if (isSingleCardView) {
    marginLeft = 0
    marginTop = 0
  }
  return `
  margin-top: ${marginTop}px;
  margin-left: ${marginLeft}px;
  ${theme.breakpoints.down('md')} {
    margin-left: 0;
  }
`
})

export const MainContentWithFilterMenu = ({ filterMenu, contentHeader, children }: OwnProps) => {
  const { t } = useTranslation()
  const isMenuOpen = useUserProfileStore(state => state.settings.settings.isMenuOpen)
  const [datePeriod] = useQueryStringState(QueryStringStateKeys.datePeriod)
  const isSingleCardView: boolean = !!useCardIdFromUrl()

  return (
    <>
      {!isSingleCardView ? filterMenu : null}
      <StyledMainContent isMenuOpen={isMenuOpen} isSingleCardView={isSingleCardView}>
        {contentHeader}
        {datePeriod ? children : <Box ml={2}>{t('sideMenu.info')}</Box>}
      </StyledMainContent>
    </>
  )
}
