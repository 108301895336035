import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

import { AssetInformationEntry } from 'src/Components/AssetCard/AssetInformationEntry'

import type { SwingTrend } from 'src/Types/SwingTypes'

import { formatNumber, formatLocalDateTime } from 'src/Utils/format'
import { ignoreStyleProps } from 'src/Utils/style'
import themeColors from 'src/theme'

const trendColor = (value: number) => {
  if (value < 0) {
    return themeColors.errorText
  }
  if (value < 0.2) {
    return themeColors.warningText
  }
  return themeColors.successText
}

const StyledTrendValue = styled('div', ignoreStyleProps('value'))<{ value: number }>`
  color: ${props => trendColor(props.value)};
`

type OwnProps = {
  trends: (SwingTrend | undefined)[]
  isCurrent: boolean
}

export const SwingTrendStatus = ({ trends, isCurrent }: OwnProps) => {
  const { t } = useTranslation()

  return (
    <>
      <AssetInformationEntry
        label={t('switches.statusLabels.swingTrend.slipCurrentDate')}
        values={trends.map(trend =>
          trend && typeof trend.timeOfLastSlip === 'number'
            ? formatLocalDateTime(trend.timeOfLastSlip)
            : t('switches.statusLabels.swingTrend.unknown')
        )}
      />
      <AssetInformationEntry
        label={t('switches.chart.slipCurrentLabel')}
        values={trends.map(trend =>
          trend && typeof trend.lastSlipCurrentValue === 'number'
            ? `${trend.lastSlipCurrentValue.toFixed(2)} ${isCurrent ? 'A' : 'W'}`
            : t('switches.statusLabels.swingTrend.unknown')
        )}
      />
      <AssetInformationEntry
        label={t('switches.statusLabels.swingTrend.swingMargin')}
        values={trends.map(trend =>
          trend && typeof trend.swingMargin === 'number' ? (
            <StyledTrendValue value={trend.swingMargin}>{formatNumber(trend.swingMargin * 100, 2, false)}%</StyledTrendValue>
          ) : (
            t('switches.statusLabels.swingTrend.unknown')
          )
        )}
      />
      <AssetInformationEntry
        label={t('switches.statusLabels.swingTrend.lockingMargin')}
        values={trends.map(trend =>
          trend && typeof trend.lockingMargin === 'number' ? (
            <StyledTrendValue value={trend.lockingMargin}>{formatNumber(trend.lockingMargin * 100, 2, false)}%</StyledTrendValue>
          ) : (
            t('switches.statusLabels.swingTrend.unknown')
          )
        )}
      />
    </>
  )
}
