import { IconWithText } from 'src/Components/IconWithText'
import { AlarmIcon } from 'src/Components/AlarmIcon'

import type { AlarmClassification } from 'src/Types/AlarmClassification'

import { useLanguage } from 'src/Hooks/useLanguage'

type OwnProps = {
  classification: AlarmClassification
  criticalityValue?: number
}

export const AssetCardAlarmIcon = ({ classification, criticalityValue }: OwnProps) => {
  const { t } = useLanguage()

  const criticalityAriaLabel = criticalityValue ? `${t('sideMenu.sortOrder.criticality')}: ${criticalityValue}` : ''
  const icon = <AlarmIcon classification={classification} />

  return <IconWithText aria-label={criticalityAriaLabel} icon={icon} text="" gap={0.75} isUpperCase />
}
