import type { ReactNode } from 'react'
import { Button } from 'src/Components/Button'
import OpenInNewIcon from '@mui/icons-material/OpenInNewOutlined'

type OwnProps = {
  href: string
  children: ReactNode
  icon?: ReactNode
}

export const LinkButton = ({ children, icon = <OpenInNewIcon fontSize="small" />, href }: OwnProps) => {
  return (
    <Button variant="outlined" href={href} openInNewWindow endIcon={icon}>
      {children}
    </Button>
  )
}
