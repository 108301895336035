import { styled } from '@mui/material/styles'
import { ignoreStyleProps } from 'src/Utils/style'
import Chip from '@mui/material/Chip'

type StyleProps = {
  chipColor: string
}

export const StyledChip = styled(Chip, ignoreStyleProps('chipColor'))<StyleProps>`
  margin-left: 8px;
  color: white;
  background-color: ${props => props.chipColor};
`
