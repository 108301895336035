import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

import { useTranslation } from 'react-i18next'
import { useAccess } from 'src/Hooks/useAccess'
import { useHideCriticality } from 'src/Hooks/useHideCriticality'

import type { Asset } from 'src/Types/AssetTypes'
import { AssetType } from 'src/Types/AssetTypes'
import { MenuWithButton } from 'src/Components/UIComponents'
import config from 'src/Config/Config'
import { CriticalityResetMenuItem, FavoriteMenuItem, ManualAssetStatusMenuItem, PermalinkMenuItem } from './index'
import { getCardIdByAsset } from 'src/Utils/cardId'
import { AssetStatusEntityStatus } from 'src/Types/AssetStatusEntry'
import { getOngoingWindows } from 'src/Utils/asset'

type OwnProps = {
  asset: Asset
}

export const AssetCardActionsMenu = ({ asset }: OwnProps) => {
  const { t } = useTranslation()

  const hasAssetStatusNoContactWriteAccess = useAccess('AssetStatus-NoContact.Write')
  const hasAssetStatusIrregularDataWriteAccess = useAccess('AssetStatus-IrregularData.Write')

  const supportsManualAssetStatus = [AssetType.SwitchPointMachine, AssetType.TrackCircuit].includes(asset.assetType)

  const [showStatusNoContactMenuItem, showStatusIrregularDataMenuItem] =
    config.FEATURES?.MANUAL_ASSET_STATUS && supportsManualAssetStatus
      ? [hasAssetStatusNoContactWriteAccess, hasAssetStatusIrregularDataWriteAccess]
      : [false, false]

  const ongoingWindows = getOngoingWindows(asset)
  const disableStatusNoContactMenuItem = ongoingWindows.some(w => w.status !== 'NoContact')
  const disableStatusIrregularDataMenuItem = ongoingWindows.some(w => w.status !== 'IrregularData')

  const hasAlarmCriticalityResetAccess = useAccess('Statistics.Read')
  const supportsCriticalityReset = !useHideCriticality()
  const showCriticalityResetOption = hasAlarmCriticalityResetAccess && supportsCriticalityReset

  return (
    <MenuWithButton
      id={`${getCardIdByAsset(asset)}-actions`}
      tooltip={t('general.showActions')}
      buttonStartIcon={<MoreHorizIcon />}
    >
      <PermalinkMenuItem asset={asset} />
      <FavoriteMenuItem asset={asset} />
      {showStatusNoContactMenuItem && (
        <ManualAssetStatusMenuItem
          asset={asset}
          status={AssetStatusEntityStatus.NoContact}
          disableMenuItem={disableStatusNoContactMenuItem}
        />
      )}
      {showStatusIrregularDataMenuItem && (
        <ManualAssetStatusMenuItem
          asset={asset}
          status={AssetStatusEntityStatus.IrregularData}
          disableMenuItem={disableStatusIrregularDataMenuItem}
        />
      )}
      {showCriticalityResetOption && <CriticalityResetMenuItem asset={asset} />}
    </MenuWithButton>
  )
}
