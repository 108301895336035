import { ApiType } from 'src/Types/ApiType'
import type { NormalizedAlarm } from 'src/Types/NormalizedAlarm'

import { callApiGet } from 'src/msGraphClient'
import { createApiParams } from 'src/Utils/network'
import config from 'src/Config/Config'

const { ALARMS } = config.API_FRAGMENTS.apim

type TrackCircuitAlarmsParams = {
  baneDataIds: string[]
  alarmTypeId?: number
  fromDate: number
  toDate: number
}

export const getTrackCircuitAlarms = ({ baneDataIds, alarmTypeId, fromDate, toDate }: TrackCircuitAlarmsParams) => {
  const urlParams = createApiParams({
    alarmSystem: 'TrackCircuit',
    baneDataIds: baneDataIds.join(','),
    fromDate: new Date(fromDate).toJSON(),
    toDate: new Date(toDate).toJSON(),
    alarmTypeId,
  })

  return callApiGet<NormalizedAlarm[]>(`${ALARMS}?${urlParams.toString()}`, ApiType.APIM, { parseDates: true })
}
