import { useMemo } from 'react'
import type { QueryStatus } from 'react-query'
import { useTranslation } from 'react-i18next'
import type { TFunction } from 'i18next'
import ListItem from '@mui/material/ListItem'
import { NoResult } from 'src/Components/NoResult'
import { FullWidthLoader } from 'src/Components/FullWidthLoader'
import { SidePanelMenu } from 'src/Components/SidePanelMenu/SidePanelMenu'
import { StreamView } from 'src/Features/SignalEarthFaults/StreamView/StreamView'
import { ViewSelectorButton } from 'src/Components/SidePanelMenu/ViewSelectorButton'
import type { AssetWithAlarms } from 'src/Types/AssetWithAlarms'
import { QueryStringStateKeys, useQueryStringState } from 'src/Store/useQueryStringState'
import { useBendersWithAlarms } from 'src/Hooks/NetworkData/useBendersWithAlarms'
import { useRacksWithAlarms } from 'src/Hooks/NetworkData/useRacksWithAlarms'
import { getLeastAdvancedQueryStatus } from 'src/Utils/network'
import { useNavigate } from 'react-router-dom'
import { createPageLink } from 'src/Utils/pageLink'
import { useFeatureOverride } from 'src/Hooks/useFeatureOverride'
import { SessionFeatures } from 'src/Hooks/useSessionFeature'
import { AppPage } from 'src/Types/AppPage'
import { MainContentWithFilterMenu } from 'src/Components/MainContentWithFilterMenu'
import { SystemStatusAlerts } from 'src/Features/SystemStatusAlerts/SystemStatusAlerts'

const getUserFeedback = (t: TFunction, allStatuses: QueryStatus[], assetsWithAlarms: AssetWithAlarms[]) => {
  const overallStatus = getLeastAdvancedQueryStatus(allStatuses)
  if (overallStatus === 'loading') {
    return <FullWidthLoader />
  }

  if (allStatuses.every(s => s === 'error')) {
    return <NoResult>{t('benders.fetchError')}</NoResult>
  }

  if (!assetsWithAlarms.length) {
    return <NoResult>{t('benders.noneFound')}</NoResult>
  }

  return null
}

export const SignalEarthStreamPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const isRewrittenNavigation = useFeatureOverride(SessionFeatures.UseRewrittenNavigation)
  const enableAllAlarmTypes = useFeatureOverride(SessionFeatures.EnableAllAlarmTypes)

  const [selectedStretchLocations] = useQueryStringState(QueryStringStateKeys.stretchLocations)
  const [datePeriod] = useQueryStringState(QueryStringStateKeys.datePeriod)

  const { bendersWithAlarms = [], status: bendersStatus } = useBendersWithAlarms({
    idType: 'baneDataLocationIds',
    ids: selectedStretchLocations,
    datePeriod,
    enableAllAlarmTypes,
  })

  const { racksWithAlarms = [], status: racksStatus } = useRacksWithAlarms({
    idType: 'baneDataLocationIds',
    ids: selectedStretchLocations,
    datePeriod,
    enableAllAlarmTypes,
  })

  const allStatuses = [bendersStatus, racksStatus]

  const assetsWithAlarms = useMemo(() => bendersWithAlarms.concat(racksWithAlarms), [bendersWithAlarms, racksWithAlarms])

  const filterMenu = (
    <SidePanelMenu hideClearButton hideSaveButton>
      {!isRewrittenNavigation && (
        <ListItem>
          <ViewSelectorButton isCardView={false} onCardViewUpdated={() => navigate(createPageLink(AppPage.SignalEarthCards))} />
        </ListItem>
      )}
    </SidePanelMenu>
  )

  return (
    <MainContentWithFilterMenu filterMenu={filterMenu} contentHeader={<SystemStatusAlerts />}>
      <>
        {getUserFeedback(t, allStatuses, assetsWithAlarms)}
        <StreamView racksWithAlarms={racksWithAlarms} bendersWithAlarms={bendersWithAlarms} />
      </>
    </MainContentWithFilterMenu>
  )
}
