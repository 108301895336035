import { useQueryWithErrorHandling } from 'src/Hooks/NetworkData/useQueryWithErrorHandling'
import { getAssetStatusEntryCauses } from 'src/Providers/AssetStatusCause'

export const useAssetStatusEntryCauses = (staleTimeInMinutes: number = 5) => {
  const query = useQueryWithErrorHandling(
    ['assetStatusEntryCauses'],
    () => getAssetStatusEntryCauses(),
    {
      staleTime: staleTimeInMinutes * 60 * 1000,
    },
    'manualAssetStatus.causes.fetchError'
  )

  return {
    ...query,
    assetStatusCauses: query.data,
  }
}
