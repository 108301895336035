import { useCallback } from 'react'
import { useSessionStorage } from 'src/Hooks/useSessionStorage'

export const SESSION_FEATURES_KEY = 'trackMonitoring.sessionFeatures'

export const SessionFeatures = {
  ExGenFeatureOn: 'switchheatv2',
  LodPanel: 'lod-panel',
  ForceFeatureToggle: 'feature-toggle',
  DisableLazyLoad: 'disable-lazy-load',
  LogReactParams: 'log-react-params',
  EnableStubbedSystemStatusAlerts: 'enable-stubbed-system-status-alerts',
  EnableAllAlarmTypes: 'enable-all-alarm-types',
  UseRewrittenNavigation: 'use-rewritten-navigation',
} as const

type SessionFeaturesType = typeof SessionFeatures
export type FeatureKey = SessionFeaturesType[keyof SessionFeaturesType]

export const useSessionFeatures = () => {
  const [features, setFeatures] = useSessionStorage<FeatureKey[]>(SESSION_FEATURES_KEY, [] as FeatureKey[])

  const toggleFeature = useCallback(
    (feature: FeatureKey, state?: boolean) => {
      const hasKey = features.includes(feature)
      const onOff = typeof state === 'undefined' ? !hasKey : state
      const newFeatures = onOff ? [...new Set([...features, feature])] : features.filter(k => k !== feature)
      setFeatures(newFeatures as FeatureKey[])
    },
    [features, setFeatures]
  )

  return { features, toggleFeature, setFeatures }
}
