import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'

import { SubHeaderWithExpandableInfo } from 'src/Components/SubHeaderWithExpandableInfo'
import { RackEarthCurrentChart } from 'src/Features/SignalEarthFaults/RackEarthCurrentChart/RackEarthCurrentChart'
import { WeatherChart } from 'src/Features/Weather/WeatherChart/WeatherChart'

import type { RackSectionSensor } from 'src/Types/Rack'

import themeColors from 'src/theme'
import { useChartFocus } from 'src/Components/Chart/useChartFocus'
import { mapToLocations } from 'src/Features/Weather/WeatherChart/mapToLocations'
import { SubHeaderKey } from 'src/Providers/Settings/SubHeader'

const useStyles = makeStyles()(theme => ({
  card: {
    backgroundColor: '#fff',
    borderRadius: 0,
    margin: 16,
    overflow: 'inherit',
    [theme.breakpoints.down('sm')]: {
      margin: '8px 4px',
    },
  },
  cardContent: {
    paddingBottom: 0,
  },
  subSectionContainer: {
    border: `1px solid ${themeColors.sectionHeader}`,
    backgroundColor: '#fff',
    '& > p': {
      paddingLeft: 8,
    },
  },
}))

type OwnProps = {
  selectedFromDate: number
  selectedToDate: number
  selectedRackSections: RackSectionSensor[]
}

export const RackSectionStreamCard = ({ selectedFromDate, selectedToDate, selectedRackSections }: OwnProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const { chartFocus, zoomUpdated } = useChartFocus({ fromDate: selectedFromDate, toDate: selectedToDate })

  const locations = mapToLocations(selectedRackSections)

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <SubHeaderWithExpandableInfo
          title={t('racks.rackCurrent')}
          hideText={t('racks.hideRackCurrent')}
          isDefaultExpanded
          subHeaderKey={SubHeaderKey.RacksCurrent}
        >
          <div className={classes.subSectionContainer}>
            {selectedRackSections.length === 0 ? (
              <p>{t('racks.info')}</p>
            ) : (
              <RackEarthCurrentChart
                selectedRackSections={selectedRackSections}
                datePeriod={{
                  fromDate: selectedFromDate,
                  toDate: selectedToDate,
                }}
                onZoomUpdated={zoomUpdated}
                view="stream"
              />
            )}
          </div>
        </SubHeaderWithExpandableInfo>

        <Box sx={{ mt: 2 }}>
          {locations.map(({ locationId, locationName }, index) => (
            <SubHeaderWithExpandableInfo
              title={t('weatherChart.title', { stationName: locationName })}
              hideText={t('weatherChart.hideWeather')}
              forcedClosed={index > 0}
              key={locationId}
              subHeaderKey={SubHeaderKey.WeatherChart}
            >
              <WeatherChart
                baneDataLocationId={locationId}
                fromDate={selectedFromDate}
                toDate={selectedToDate}
                chartFocus={chartFocus}
              />
            </SubHeaderWithExpandableInfo>
          ))}
        </Box>
      </CardContent>
    </Card>
  )
}
