export enum SwingDirection {
  Left = 'Left',
  Right = 'Right',
  Unknown = 'Unknown',
}

export enum SwingDirectionFilter {
  All = 'all',
  Left = 'Left',
  Right = 'Right',
}
