/**
 * Note: Needs to be in sync with 'asEnumText' inside getComponentTypeIdOrUndefined.test.ts
 */
export enum CriticalityComponentTypeEnum {
  Switch = 1,
  TrackCircuit = 2,
  SwitchHeat = 3,
  SignalEarthFault = 4,
  MachineHeat = 5,
}
