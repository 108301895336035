import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import { SubHeaderWithExpandableInfo } from 'src/Components/SubHeaderWithExpandableInfo'
import { AssetInformationEntry } from 'src/Components/AssetCard/AssetInformationEntry'
import { SwingTrendStatus } from './SwingTrendStatus'
import { Divider } from 'src/Components/Divider'
import { StyledContent } from './elements'
import { formatNumber, formatLocalDateTime } from 'src/Utils/format'
import type { MachineSwing } from 'src/Types/SwingTypes'
import { SubHeaderKey } from 'src/Providers/Settings/SubHeader'
import { MachinesBasicInfo } from 'src/Features/Switches/SwitchCard/SwingInfo/MachinesBasicInfo'

type OwnProps = {
  machineSwings: MachineSwing[] | undefined
  isLoading: boolean
  isCurrent: boolean
}

export const SwitchInfo = ({ machineSwings, isLoading, isCurrent }: OwnProps) => {
  const { t } = useTranslation()

  const pointMachineNames = machineSwings?.map(s => s.baneDataNameParsed)

  const title = t('switches.infoPointMachines', { pointMachineNames: pointMachineNames?.join(',') })

  const renderContent = () => {
    if (isLoading && !machineSwings?.length) {
      return (
        <Box m={2}>
          <CircularProgress color="inherit" size={15} /> {t('switches.swings.details.loading')}
        </Box>
      )
    }

    const referenceCurveSwingDurations = machineSwings?.map(s =>
      s.referenceCurve && s.referenceCurve.referenceCurveDuration
        ? `${formatNumber(s.referenceCurve.referenceCurveDuration / 1000, 2)} s`
        : '-'
    )

    return (
      <StyledContent>
        <MachinesBasicInfo machines={machineSwings} />
        <Divider />
        <AssetInformationEntry
          label={t('switches.statusLabels.swingTrend.referenceCurveDate')}
          values={machineSwings?.map(s =>
            s.referenceCurve?.date
              ? formatLocalDateTime(s.referenceCurve.date, true)
              : t('switches.statusLabels.swingTrend.unknown')
          )}
        />
        <AssetInformationEntry label={t('switches.statusLabels.referenceCurveDuration')} values={referenceCurveSwingDurations} />
        <Divider />
        <SwingTrendStatus trends={machineSwings?.map(s => s.swingAnalysis?.swingTrend) ?? []} isCurrent={isCurrent} />
      </StyledContent>
    )
  }

  return (
    <SubHeaderWithExpandableInfo
      title={title}
      isLoading={isLoading}
      isDefaultExpanded
      subHeaderKey={SubHeaderKey.PointMachinesInfo}
    >
      {renderContent()}
    </SubHeaderWithExpandableInfo>
  )
}
