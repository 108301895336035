import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

type OwnProps = {
  title: string
  text: string
  onConfirm: (didConfirm: boolean) => void
  type: 'delete' | 'confirm'
}

export const ConfirmDialog = ({ title, text, onConfirm, type }: OwnProps) => {
  const { t } = useTranslation()
  const buttons =
    type === 'delete' ? (
      <>
        <Button onClick={() => onConfirm(false)}>{t('general.cancel')}</Button>
        <Button onClick={() => onConfirm(true)} color="secondary">
          {t('general.delete')}
        </Button>
      </>
    ) : (
      <>
        <Button onClick={() => onConfirm(false)} color="secondary">
          {t('general.no')}
        </Button>
        <Button onClick={() => onConfirm(true)} color="primary">
          {t('general.yes')}
        </Button>
      </>
    )

  return (
    <Dialog
      open
      onClose={() => onConfirm(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
      </DialogContent>
      <DialogActions>{buttons}</DialogActions>
    </Dialog>
  )
}
