import { Tooltip, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import ErrorIcon from '@mui/icons-material/Error'

const StyledRoot = styled(Box)`
  position: absolute;
  top: 0;
  right: -20px;
`

type OwnProps = {
  messages?: string[]
}

export const TemperatureValueNotification = ({ messages }: OwnProps) => (
  <StyledRoot>
    <Tooltip title={messages?.map(message => <div key={message}>{message}</div>)} placement="top" arrow>
      <div>
        <ErrorIcon color="error" fontSize="small" />
      </div>
    </Tooltip>
  </StyledRoot>
)
