import { getAlarmTypes } from 'src/Providers/AlarmTypes'
import { useDomainConstantsStore } from 'src/Store/domainConstants'
import { useQueryWithErrorHandling } from './useQueryWithErrorHandling'
import type { AlarmType } from 'src/Types/AlarmType'
import { useGenericCacheBehaviour } from 'src/Hooks/useGenericCacheBehaviour'

export const useAlarmTypesLoader = () => {
  const setAlarmTypes = useDomainConstantsStore(state => state.alarmTypes.setAlarmTypes)

  const { getFreshOrCachedValue } = useGenericCacheBehaviour<AlarmType[]>({
    partialKey: 'alarmTypes',
    getFreshValue: () => getAlarmTypes(),
    onValueReceived: alarmTypes => setAlarmTypes(alarmTypes),
  })

  return useQueryWithErrorHandling(
    ['alarmTypes'],
    () => getFreshOrCachedValue(),
    {
      staleTime: Infinity,
    },
    'alarms.typesFetchError'
  )
}
