import React, { useState } from 'react'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { styled } from '@mui/material/styles'
import type { ManualAssetStatusPanelProps } from 'src/Features/ManualAssetStatus/ManualAssetStatusPanel'
import { ManualAssetStatusPanel } from 'src/Features/ManualAssetStatus/ManualAssetStatusPanel'
import theme from 'src/theme'
import type { AssetStatusEntityStatus } from 'src/Types/AssetStatusEntry'

const StyledDialog = styled(Dialog)``
const StyledDialogContent = styled(DialogContent)`
  max-width: 500px;
  color: ${theme.text};
  ${props => props.theme.breakpoints.up('sm')} {
    min-width: 450px;
  }
`
const StyledDialogTitle = styled(DialogTitle)`
  font-size: 14px;
  color: ${theme.text};
  font-weight: bold;
`

export type ManualAssetStatusModalProps = Pick<
  ManualAssetStatusPanelProps,
  'baneDataId' | 'domain' | 'fromDate' | 'toDate' | 'useManualAssetStatusBehaviour'
> & {
  status: AssetStatusEntityStatus
  title: string
  onClose?: () => void
}

export const ManualAssetStatusDialog = ({
  baneDataId,
  status,
  title,
  domain,
  fromDate,
  toDate,
  useManualAssetStatusBehaviour,
  onClose,
}: ManualAssetStatusModalProps) => {
  const [open, setOpen] = useState(true)

  const handleClose = () => {
    setOpen(false)
    onClose?.()
  }

  const handleCancel = () => handleClose()

  const handleSubmitSuccess = () => {
    handleClose()
  }

  return (
    <StyledDialog open={open} onClose={handleClose} onKeyDown={e => e.stopPropagation()}>
      <StyledDialogTitle>{title}</StyledDialogTitle>
      <StyledDialogContent>
        <ManualAssetStatusPanel
          status={status}
          baneDataId={baneDataId}
          domain={domain}
          fromDate={fromDate}
          toDate={toDate}
          useManualAssetStatusBehaviour={useManualAssetStatusBehaviour}
          onCancel={handleCancel}
          onSubmitSuccess={handleSubmitSuccess}
        />
      </StyledDialogContent>
    </StyledDialog>
  )
}
