import { publicUrl } from 'src/reactAppEnv'

export const getBasePath = (publicUrl: string | undefined) => {
  if (!publicUrl?.length) {
    return '/'
  }
  if (publicUrl.startsWith('/')) {
    return publicUrl
  }
  try {
    const url = new URL(publicUrl)
    return url.pathname
  } catch (e) {
    return '/'
  }
}

export const basePath = getBasePath(publicUrl)

export const isBranchBuild = basePath !== '/'
