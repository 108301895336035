import type { TrackCircuitCompressionRates } from 'src/Types/TrackCircuitCurrentTypes'

const MILLISECONDS_PER_DAY = 86400000

export const getCalculatedCompressionRate = (fromDate: number, toDate: number): TrackCircuitCompressionRates => {
  const duration = toDate - fromDate
  const days = Math.floor(duration / MILLISECONDS_PER_DAY)
  if (days <= 2) {
    return 0
  }
  if (days <= 4) {
    return 1
  }
  if (days <= 8) {
    return 2
  }
  return 3
}
