import type { ReactNode } from 'react'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreOutlined'
import ExpandLessIcon from '@mui/icons-material/ExpandLessOutlined'
import CircularProgress from '@mui/material/CircularProgress'

import { ExpandCollapseButton } from 'src/Components/ExpandCollapseButton'

import themeColors from 'src/theme'
import { PinnedIconButton } from 'src/Components/PinnedIconButton'
import { useSubHeaderExpandedState } from 'src/Hooks/useSubHeaderExpandedState'
import type { SubHeaderKey } from 'src/Providers/Settings/SubHeader'
import { ignoreStyleProps } from 'src/Utils/style'

const StyledContainer = styled('div')`
  background-color: #fff;
  border-style: solid;
  border-color: ${themeColors.sectionHeader};
  border-width: 1px 1px 0 1px;
  margin: 0;

  &:last-child {
    border-bottom-width: 1px;
  }
`

const StyledList = styled(
  List,
  ignoreStyleProps('isExpanded')
)<{ isExpanded: boolean }>(
  ({ isExpanded }) => `
  padding: 0;
  border-bottom: ${isExpanded ? `1px solid ${themeColors.sectionHeader}` : 'none'};
`
)

const StyledSectionHeader = styled(ListItem)(
  props => `
  padding: 0;
  cursor: pointer;

  > .MuiListItemButton-root {
    padding: 4px ${props.theme.spacing(2)};
    background-color: ${themeColors.sectionSubHeader};
    
    ${props.theme.breakpoints.down('sm')} {
      padding: 4px ${props.theme.spacing(1)};
    }
    
    &:focus, &:hover {
      background-color: ${themeColors.sectionSubHeaderHover};
    }
  }
`
)

const StyledActions = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
`

type OwnProps = {
  children: ReactNode
  title: ReactNode
  isDefaultExpanded?: boolean
  forcedClosed?: boolean
  isLoading?: boolean
  hideText?: string
  className?: string
  subHeaderKey?: SubHeaderKey
}

export const SubHeaderWithExpandableInfo = ({
  children,
  title,
  isDefaultExpanded = false,
  forcedClosed,
  isLoading = false,
  hideText,
  className,
  subHeaderKey,
}: OwnProps) => {
  const { t } = useTranslation()
  const { expanded, setExpanded, personalizedDefaultExpanded, saveDefaultExpanded } = useSubHeaderExpandedState({
    subHeaderKey,
    isDefaultExpanded,
    forcedClosed,
  })

  const altHideText = `${t('general.hide')} ${title}`

  return (
    <StyledContainer className={className}>
      <StyledList isExpanded={expanded}>
        <StyledSectionHeader>
          <ListItemButton onClick={() => setExpanded(isExpanded => !isExpanded)}>
            <ListItemText>
              {title} {isLoading && <CircularProgress size={15} />}
            </ListItemText>
            {subHeaderKey ? (
              <PinnedIconButton onClick={saveDefaultExpanded} pinned={personalizedDefaultExpanded} expanded={expanded} />
            ) : null}
            {expanded ? <ExpandLessIcon color="primary" /> : <ExpandMoreIcon color="primary" />}
          </ListItemButton>
        </StyledSectionHeader>
      </StyledList>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {children}
        <StyledActions>
          <ExpandCollapseButton
            isExpanded={expanded}
            onToggleExpand={() => setExpanded(isExpanded => !isExpanded)}
            text={hideText || altHideText}
          />
        </StyledActions>
      </Collapse>
    </StyledContainer>
  )
}
