import { useMemo, useState } from 'react'

import { type WorkOrder } from 'src/Types/WorkOrderTypes'
import type { Order, TableHead } from 'src/Types/TableTypes'

import { Table } from 'src/Components/UIComponents'
import { WorkOrdersTableRow } from './WorkOrdersTableRow'

import { useLanguage } from 'src/Hooks/useLanguage'

import { sortArrayOfObjectsByKey } from 'src/Utils/array'

type OwnProps = {
  workOrders: WorkOrder[]
  label: string
  isComplete?: boolean
}

export const WorkOrdersTable = ({ workOrders, label, isComplete = false }: OwnProps) => {
  const { t } = useLanguage()

  const defaultSortOrderForWorkOrderId = 'desc' as Order
  const defaultSortOrderForOtherColumns = 'asc' as Order

  const [orderBy, setOrderBy] = useState<keyof WorkOrder>('workOrderId')
  const [order, setOrder] = useState<Order>(defaultSortOrderForWorkOrderId)

  const tableHeads: TableHead[] = [
    { value: t('workOrders.description'), id: 'description' },
    { value: t('workOrders.workType'), id: 'workType' },
    { value: t('workOrders.status'), id: 'status' },
    { value: t('workOrders.discipline'), id: 'discipline' },
    { value: t('workOrders.timeOfCreation'), id: 'timeOfCreation' },
    { value: t('workOrders.workOrderId'), id: 'workOrderId' },
    { value: t('workOrders.assignedTo'), id: 'supervisor' },
  ]

  const sortedWorkOrders = useMemo(() => sortArrayOfObjectsByKey(workOrders, orderBy, order), [workOrders, orderBy, order])

  const handleSort = (key: string) => {
    if (key !== orderBy) {
      setOrderBy(key as keyof WorkOrder)
      setOrder(defaultSortOrderForOtherColumns)
    } else {
      setOrder(order === 'asc' ? 'desc' : 'asc')
    }
  }

  return (
    <Table
      label={label}
      tableHeads={tableHeads}
      noMatchesTextKey="workOrders.noFilteredMatches"
      order={order}
      orderBy={orderBy}
      handleSort={handleSort}
      usePagination
      canSortColumns
    >
      {sortedWorkOrders.map(wo => (
        <WorkOrdersTableRow key={wo.workOrderId} workOrder={wo} isComplete={isComplete} />
      ))}
    </Table>
  )
}
