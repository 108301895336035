import { useState, useEffect } from 'react'

import { TemperatureFormModus } from 'src/Features/SwitchHeat/Adjustment/TemperatureFormModus'
import { TemperatureForm } from 'src/Features/SwitchHeat/Adjustment/TemperatureForm'
import { TemperatureFormExpandable } from 'src/Features/SwitchHeat/Adjustment/TemperatureFormExpandable'

import type { SensorTwinData, SensorTwinModus } from 'src/Types/SensorTwin'

import {
  useSwitchHeatModusUpdate as useRealSwitchHeatModusUpdate,
  useSwitchHeatModus,
} from 'src/Hooks/NetworkData/useSwitchHeatModus'
import { useDetectFinalizedModusUpdate } from 'src/Features/SwitchHeat/Adjustment/useDetectFinalizedModusUpdate'
import { mapToTemperatureParameters } from 'src/Features/SwitchHeat/Adjustment/mapToTemperatureParameters'
import { useLanguage } from 'src/Hooks/useLanguage'
import { useAppStateStore } from 'src/Store/appState'
import { partition } from 'src/Utils/array'
import type { UpdatingTemperatureState } from 'src/Features/SwitchHeat/Adjustment/useTemperatureAdjustmentBehaviour'
import { TemperatureUpdateState } from 'src/Features/SwitchHeat/Adjustment/types'
import { useSwitchHeatAvailableModus as useRealSwitchHeatAvailableModus } from 'src/Hooks/NetworkData/useSwitchHeatAvailableModus'
import type { SensorCode } from 'src/Types/SensorCode'
import { getAppInsights } from 'src/telemetryService'
import type { TabModelColumn } from 'src/Features/SwitchHeat/TabModel'

type OwnProps = {
  switchHeatSensorData: SensorTwinData
  refetchSwitchHeatSensorData: () => void
  onUpdatingChange?: (updating: boolean) => void
  cardId?: string
  assetName?: string
  mocked?: boolean
  useSwitchHeatModusUpdate?: typeof useRealSwitchHeatModusUpdate
  useSwitchHeatAvailableModus?: typeof useRealSwitchHeatAvailableModus
  hasWriteAccess: boolean
  sensorCodes: SensorCode[]
  modusUpdateTimeout?: number
  columns: TabModelColumn[]
}

/**
 * Container for the temperature and modus form. The component is responsible for orchestrating the form state.
 */
export const TemperatureFormContainer = ({
  switchHeatSensorData,
  refetchSwitchHeatSensorData,
  cardId,
  assetName,
  onUpdatingChange,
  mocked = false,
  useSwitchHeatModusUpdate = useRealSwitchHeatModusUpdate,
  useSwitchHeatAvailableModus = useRealSwitchHeatAvailableModus,
  hasWriteAccess,
  sensorCodes,
  modusUpdateTimeout,
  columns,
}: OwnProps) => {
  const { t, currentLanguage } = useLanguage()

  const queueMessage = useAppStateStore(state => state.appMessages.queueMessage)

  const [changedModusValue, setChangedModusValue] = useState<SensorTwinModus | undefined>()
  const [updatingModusValue, setUpdatingModusValue] = useState<SensorTwinModus | undefined>()
  const [lastUpdatingModusValue, setLastUpdatingModusValue] = useState<SensorTwinModus | undefined>()

  const { mutate: updateSwitchHeatModus, status: updateSwitchHeatModusStatus } = useSwitchHeatModusUpdate()

  const {
    supportsModus,
    currentModus,
    requestedModus,
    modusSensorId,
    lastUpdated,
    eTag: modusETag,
  } = useSwitchHeatModus(switchHeatSensorData)

  const { availableModuses } = useSwitchHeatAvailableModus(supportsModus ? modusSensorId : undefined)

  const { finalResult: finalDetectedModusUpdateResult, detecting: isDetectingModusUpdate } = useDetectFinalizedModusUpdate({
    updateSwitchHeatModusStatus,
    currentModus,
    requestedModus,
    eTag: modusETag,
    modusUpdateTimeout,
  })

  useEffect(() => {
    if (!changedModusValue) {
      setChangedModusValue(currentModus)
    }
  }, [changedModusValue, currentModus])

  const [, otherSensors] = partition(switchHeatSensorData.sensors, sensor =>
    sensorCodes.some(c => c.key === sensor.codeSystem && c.category.name === 'Alarm')
  )

  const formSensors = otherSensors.filter(sensor => sensor.codeSystem !== 'CMD_MODE')
  const temperatureParameters = mapToTemperatureParameters(formSensors, sensorCodes, currentLanguage, t)
  const isAnyModusMax = [currentModus, requestedModus, updatingModusValue, changedModusValue].includes('max')
  const isWritable = hasWriteAccess
  const cardLink = cardId ? `#${cardId}` : undefined
  const assetNameInMessage = assetName ? `${t('general.for')} ${assetName}` : ''

  useEffect(() => {
    if (finalDetectedModusUpdateResult) {
      const appInsights = getAppInsights()
      const customProperties = {
        result: finalDetectedModusUpdateResult,
        assetName,
        modusSensorId,
        currentModus,
        requestedModus,
        intendedModus: lastUpdatingModusValue,
      }
      appInsights?.trackEvent({ name: 'update-modus-result' }, customProperties)

      switch (finalDetectedModusUpdateResult) {
        case 'success': {
          queueMessage({
            type: 'success',
            message: `${t('switchHeat.temperatureForm.updateModusSuccess')} ${assetNameInMessage}`,
            href: cardLink,
            duration: 10000,
            raw: true,
          })
          break
        }

        case 'error':
        case 'timeout': {
          queueMessage({
            type: 'error',
            message: `${t('switchHeat.temperatureForm.updateModusError')} ${assetNameInMessage}`,
            href: cardLink,
            duration: null,
            raw: true,
          })
          break
        }

        default:
          break
      }

      setUpdatingModusValue(undefined)
    }
  }, [finalDetectedModusUpdateResult]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onUpdatingChange?.(isDetectingModusUpdate)
  }, [isDetectingModusUpdate]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleModusInputChange = async (modus: SensorTwinModus | undefined) => {
    setChangedModusValue(modus)
  }

  const handleModusSubmitClicked = async (modus: SensorTwinModus | undefined) => {
    if (modus && modusSensorId) {
      updateSwitchHeatModus({ modus, sensorId: modusSensorId })
      setUpdatingModusValue(modus)
      setLastUpdatingModusValue(modus)
    }
  }

  const handleTemperatureFormSubmitted = (updateState: UpdatingTemperatureState[]) => {
    const allSuccessful = updateState.every(state => state.state === TemperatureUpdateState.Success)
    if (allSuccessful) {
      queueMessage({
        type: 'success',
        message: `${t('switchHeat.temperatureForm.updateParametersSuccess')} ${assetNameInMessage}`,
        href: cardLink,
        duration: 10000,
        raw: true,
      })
    } else {
      queueMessage({
        type: 'error',
        message: `${t('switchHeat.temperatureForm.updateParametersError')} ${assetNameInMessage}`,
        href: cardLink,
        duration: null,
        raw: true,
      })
    }

    refetchSwitchHeatSensorData()
  }

  const hasColumnWithParameters = columns.some(column => column.codeSystemKeys.length > 0)
  const hasTemperatureParameters = temperatureParameters.length > 0
  const hasAvailableModuses = Boolean(availableModuses?.length)

  return (
    <TemperatureFormExpandable
      modusComponent={
        hasAvailableModuses && (
          <TemperatureFormModus
            isWritable={isWritable}
            currentModus={currentModus}
            requestedModus={requestedModus}
            changedModus={changedModusValue}
            updatingModus={updatingModusValue}
            availableModuses={availableModuses}
            lastUpdated={lastUpdated}
            isUpdating={isDetectingModusUpdate}
            onModusInputChange={handleModusInputChange}
            onModusSubmitClicked={handleModusSubmitClicked}
          />
        )
      }
      formComponent={
        hasTemperatureParameters &&
        hasColumnWithParameters && (
          <TemperatureForm
            temperatureParameters={temperatureParameters}
            columns={columns}
            isWritable={isWritable && !isAnyModusMax}
            hasWriteAccess={hasWriteAccess}
            onSubmitted={handleTemperatureFormSubmitted}
            mockedUpdates={mocked}
          />
        )
      }
    />
  )
}
