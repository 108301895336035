import React from 'react'
import type { ReactNode } from 'react'
import { Button, Menu as MaterialMenu } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(() => ({
  iconAndTextButton: {
    padding: '6px 8px',
    '.MuiButton-startIcon': {
      margin: '0 8px 0 -4px',
    },
  },
  iconButton: {
    padding: '6px',
    minWidth: '24px',
    minHeight: '24px',
    '.MuiButton-startIcon': {
      margin: '0',
    },
  },
}))

type OwnProps = {
  id: string
  tooltip: string
  buttonStartIcon?: ReactNode
  hasButtonText?: boolean
  children: ReactNode
}

export const MenuWithButton = ({ id, tooltip, buttonStartIcon, hasButtonText = false, children }: OwnProps) => {
  const { classes } = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        id={`${id}-button`}
        color="primary"
        startIcon={buttonStartIcon}
        title={tooltip}
        aria-controls={open ? `${id}-menu` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        className={hasButtonText ? classes.iconAndTextButton : classes.iconButton}
        onClick={handleClick}
      >
        {hasButtonText ? tooltip : null}
      </Button>

      <MaterialMenu id={`${id}-menu`} open={open} anchorEl={anchorEl} aria-labelledby={`${id}-button`} onClose={handleClose}>
        {children}
      </MaterialMenu>
    </>
  )
}

export default MenuWithButton
