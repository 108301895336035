export enum AppPage {
  AlarmCards = 'alarms',
  TrackCircuitsCards = 'trackcircuits-cards',
  TrackCircuitsStream = 'trackcircuits',
  SwitchesAndHeatCards = 'switches',
  Favorites = 'favorites',
  SignalEarthCards = 'signalearth-cards',
  SignalEarthStream = 'signalearth',
}

export const ALL_APP_PAGES: AppPage[] = [
  AppPage.AlarmCards,
  AppPage.TrackCircuitsCards,
  AppPage.TrackCircuitsStream,
  AppPage.SwitchesAndHeatCards,
  AppPage.Favorites,
  AppPage.SignalEarthCards,
  AppPage.SignalEarthStream,
]
