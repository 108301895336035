import type { ReactNode } from 'react'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

type OwnProps = {
  selector: ReactNode
  button: ReactNode
}

const StyledWrapper = styled(Box)(
  ({ theme }) => `
    margin: 0 ${theme.spacing(2)};
    
    ${theme.breakpoints.down('sm')} {
      margin: ${theme.spacing(1)};
    }
`
)

const StyledContainer = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing(1)};
  width: 100%;
  align-items: center;
  
  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
    
    > div {
      width: 100%;
    }
  }
`
)

const StyledSelectorContainer = styled(Box)`
  flex: 1;
`

const StyledButtonContainer = styled(Box)`
  min-width: 25%;
`

/**
 * Component only used in stream view pages (/trackcircuits and /signalearth) to layout common selector and button.
 */
export const StreamViewSelectorContainer = ({ selector, button }: OwnProps) => {
  return (
    <StyledWrapper>
      <StyledContainer>
        <StyledSelectorContainer>{selector}</StyledSelectorContainer>
        <StyledButtonContainer>{button}</StyledButtonContainer>
      </StyledContainer>
    </StyledWrapper>
  )
}
