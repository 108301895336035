import { StorageValueType, SafeStorageValue } from 'src/Store/SafeStorageValue'

export const scriptEvalTime = Date.now()

export const getInitialPageLoadTime = () => {
  // eslint-disable-next-line no-underscore-dangle
  return '__initialPageLoadTime' in window ? (window.__initialPageLoadTime as number) : undefined
}

export const sessionInitialPageLoadTime = new SafeStorageValue(sessionStorage, 'initialPageLoadTime', StorageValueType.Number)

export const sessionScriptLoadDuration = new SafeStorageValue(sessionStorage, 'scriptLoadDuration', StorageValueType.Number)
