import * as d3 from 'd3'
import * as d3zoom from 'd3-zoom'
import { nanoid } from 'nanoid'
import { format } from 'date-fns/format'

import { resetZoomButton, zoomInButton, zoomOutButton } from 'src/Components/Chart/chartStyles'

import type {
  SimpleSelection,
  LinearTimeScale,
  LinearNumberScale,
  Extent,
  ChartLabels,
  Rect,
  SVGGSelection,
  SVGRectSelection,
  NumberInterval,
  ChartEntry,
} from 'src/Types/ChartTypes'
import { Language } from 'src/Types/LanguageTypes'
import type { SensorHistory } from 'src/Types/SensorTwin'
import type { SensorCode } from 'src/Types/SensorCode'

import { minNum, maxNum, clamp } from 'src/Utils/number'
import { createTimeMultiFormat } from 'src/Utils/createTimeMultiFormat'
import { getD3Locale } from 'src/Language/d3Locale'
import { formatNumber } from 'src/Utils/format'
import {
  getTooltipPosition,
  tooltipArrowBoxWidth,
  tooltipArrowPolygon,
  getTooltipConfig,
  getChartLineColorByIndex,
} from 'src/Utils/chart'
import themeColors from 'src/theme'
import { getTooltipMarkup } from 'src/Components/Chart/ChartRenderer'
import { enablePageScrollForChartRenderer } from 'src/Components/Chart/enablePageScrollForChartRenderer'

type ChartProps = {
  chart: HTMLDivElement
  timeline: NumberInterval
  numFullScaleSensors: number
  classes: {
    tooltip: string
    tooltipArrow: string
    zoomButton: string
  }
}

const OPTIMIZE_DATA = true

const parseValueHistory = (sensorHistory: SensorHistory, timeline: NumberInterval, dataPointThreshold: number): ChartEntry[] => {
  const mapped = sensorHistory.values ? [...sensorHistory.values] : []

  if (typeof sensorHistory.currentAtFromTime === 'number') {
    mapped.unshift([timeline.start, sensorHistory.currentAtFromTime])
  }

  if (mapped.length) {
    // Extend each line to the end of the chart by duplicating the last point unless it's too old
    const lastEntry = mapped.slice(-1)[0]

    if (dataPointThreshold <= 0 || timeline.end - lastEntry[0] < dataPointThreshold) {
      mapped.push([timeline.end, lastEntry[1]])
    }
  }

  if (!OPTIMIZE_DATA) {
    return mapped
  }

  return mapped
    .flatMap<ChartEntry>((entry, i, arr) => {
      const next = arr[i + 1]

      if (next === undefined || dataPointThreshold <= 0 || next[0] - entry[0] < dataPointThreshold) {
        return [entry]
      }

      return [entry, [0, null]]
    })
    .filter((entry, i, arr) => {
      // Always return the first and last entries
      if (i === 0 || i === arr.length - 1) {
        return true
      }

      // Optimize by skipping entries with the same measurement as the next.
      // If the rendered step algorithm is changed to curveStepAfter, this should
      // compare against the previous entry instead
      return entry[1] !== arr[i - 1][1]
    })
}

const renderValueCurve = (xScale: LinearTimeScale, yScale: LinearNumberScale) =>
  d3
    .line()
    .curve(d3.curveStepAfter)
    .x(d => xScale(d[0])!)
    .y(d => yScale(d[1])!)
    .defined(d => d[1] !== null)

const formatTick = (unit: string, decimals: number, value: number) => `${formatNumber(value, decimals, false)} ${unit}`

const formatYTick = (value: number, unit: string) => formatTick(unit, 0, value)

enum ElementClass {
  zeroLine = 'zeroLine',
  dataCurve = 'dataCurve',
  tooltipPoint = 'tooltip-point',
  tooltipArrow = 'tooltip-arrow',
  tooltipPoints = 'tooltip-points',
  zoomInButton = 'zoom-in-button',
  zoomOutButton = 'zoom-out-button',
  resetZoomButton = 'reset-zoom-button',
  leftYAxisLabel = 'left-y-axis-label',
  rightYAxisLabel = 'right-y-axis-label',
}
const classSelector = (key: ElementClass) => `.${key}`

const CHART_MARGIN: Rect = { top: 5, right: 52, bottom: 20, left: 54 }
const CHART_HEIGHT = 400
const ZOOM_BUTTON_MARGIN = 10
const ZOOM_BUTTON_SPACING = 35
const MIN_ZOOM = 1
const MAX_ZOOM_MAGIC_CONSTANT = 3000000 // This magic number results in a nice max zoom resolution
const X_PIXELS_PER_TICK = 90
const TOOLTIP_DATE_FORMAT = 'dd.MM.yyyy HH:mm:ss'
const TOOLTIP_POINT_RADIUS = 4

export class HeatSensorHistoryRenderer {
  chart: HTMLDivElement

  chartWidth: number

  timeline: NumberInterval

  sensorHistory: SensorHistory[] = []

  dataCurves: ChartEntry[][] = []

  inactiveSensors: string[] = []

  numFullScaleSensors: number

  svgContainer: SimpleSelection<SVGSVGElement>

  xScale: LinearTimeScale

  yLeftScale: LinearNumberScale

  yRightScale: LinearNumberScale

  currentXScale: LinearTimeScale

  currentYScale: LinearNumberScale

  currentYRightScale: LinearNumberScale

  xAxis: SVGGSelection

  yAxis: SVGGSelection

  yAxisGrid: SVGGSelection

  yAxis2: SVGGSelection

  zoom: d3.ZoomBehavior<SVGSVGElement, unknown>

  showZoomButtons = true

  clipUrl: string

  svgClipPath: SVGRectSelection

  mouseOverlay: SVGRectSelection

  tooltip: SimpleSelection<HTMLDivElement>

  tooltipArrow: SimpleSelection<SVGSVGElement>

  tooltipPoints: SVGGSelection

  multiTimeFormat?: ReturnType<typeof createTimeMultiFormat>

  labels: ChartLabels = {}

  seriesSensorCodes: SensorCode[] = []

  currentLanguage: Language = Language.no

  constructor({ chart, timeline, numFullScaleSensors, classes }: ChartProps) {
    this.chart = chart
    this.chartWidth = 0
    this.timeline = timeline
    this.numFullScaleSensors = numFullScaleSensors

    this.xScale = d3.scaleTime()

    this.yLeftScale = d3.scaleLinear().range([CHART_HEIGHT - CHART_MARGIN.bottom, CHART_MARGIN.top])

    this.yRightScale = d3.scaleLinear().range([CHART_HEIGHT - CHART_MARGIN.bottom, CHART_MARGIN.top])

    this.currentXScale = this.xScale
    this.currentYScale = this.yLeftScale
    this.currentYRightScale = this.yRightScale

    this.zoom = d3zoom.zoom<SVGSVGElement, any>().on('zoom', event => this.onZoom(event))

    this.svgContainer = d3.select(this.chart).html(null).append('svg').attr('height', CHART_HEIGHT)

    const clipId = nanoid()
    this.clipUrl = `url(#${clipId})`

    this.svgClipPath = this.svgContainer
      .append('clipPath')
      .attr('id', clipId)
      .append('rect')
      .attr('x', CHART_MARGIN.left + 1)
      .attr('y', CHART_MARGIN.top)
      .attr('height', CHART_HEIGHT - CHART_MARGIN.top - CHART_MARGIN.bottom)

    this.xAxis = this.svgContainer.append('g').attr('transform', `translate(0,${CHART_HEIGHT - CHART_MARGIN.bottom})`)

    this.yAxisGrid = this.svgContainer
      .append('g')
      .attr('transform', `translate(${CHART_MARGIN.left},0)`)
      .attr('color', themeColors.disabled)

    this.yAxis = this.svgContainer.append('g').attr('transform', `translate(${CHART_MARGIN.left},0)`)

    this.svgContainer
      .append('text')
      .classed(ElementClass.leftYAxisLabel, true)
      .attr('transform', 'rotate(-90)')
      .attr('y', 0)
      .attr('x', 0 - CHART_HEIGHT / 2)
      .attr('dy', '1em')
      .style('text-anchor', 'middle')

    this.yAxis2 = this.svgContainer.append('g')

    if (this.numFullScaleSensors > 0) {
      this.svgContainer
        .append('text')
        .classed(ElementClass.rightYAxisLabel, true)
        .attr('transform', 'rotate(-90)')
        .attr('y', 100)
        .attr('x', 0 - CHART_HEIGHT / 2)
        .attr('dy', '1em')
        .attr('fill', getChartLineColorByIndex(0))
        .style('text-anchor', 'middle')
    }
    this.mouseOverlay = this.svgContainer
      .append('rect')
      .attr('x', CHART_MARGIN.left + 1)
      .attr('y', CHART_MARGIN.top)
      .attr('height', CHART_HEIGHT - CHART_MARGIN.top - CHART_MARGIN.bottom)
      .style('fill', 'none')
      .style('pointer-events', 'all')
      .on('mouseout', () => this.hideTooltip())
      .on('mousemove', event => this.mouseMove(event as MouseEvent))

    enablePageScrollForChartRenderer(this.svgContainer, this.zoom)

    d3.select(this.chart)
      .append('button')
      .classed(ElementClass.resetZoomButton, true)
      .classed(classes.zoomButton, true)
      .style('right', `${CHART_MARGIN.right + ZOOM_BUTTON_MARGIN}px`)
      .style('bottom', `${CHART_MARGIN.bottom + 2 * ZOOM_BUTTON_SPACING + ZOOM_BUTTON_MARGIN}px`)
      .html(resetZoomButton)
      .on('click', () => this.resetZoom())

    d3.select(this.chart)
      .append('button')
      .classed(ElementClass.zoomInButton, true)
      .classed(classes.zoomButton, true)
      .style('right', `${CHART_MARGIN.right + ZOOM_BUTTON_MARGIN}px`)
      .style('bottom', `${CHART_MARGIN.bottom + ZOOM_BUTTON_SPACING + ZOOM_BUTTON_MARGIN}px`)
      .html(zoomInButton)
      .on('click', () => this.zoom.scaleBy(this.svgContainer.transition().duration(250), 1.5))

    d3.select(this.chart)
      .append('button')
      .classed(ElementClass.zoomOutButton, true)
      .classed(classes.zoomButton, true)
      .style('right', `${CHART_MARGIN.right + ZOOM_BUTTON_MARGIN}px`)
      .style('bottom', `${CHART_MARGIN.bottom + ZOOM_BUTTON_MARGIN}px`)
      .html(zoomOutButton)
      .on('click', () => this.zoom.scaleBy(this.svgContainer.transition().duration(250), 0.5))

    this.tooltip = d3.select(this.chart).append('div').classed(classes.tooltip, true).style('pointer-events', 'none')

    this.tooltipPoints = this.svgContainer
      .append('g')
      .classed(ElementClass.tooltipPoints, true)
      .style('pointer-events', 'none')
      .attr('clip-path', this.clipUrl)

    this.tooltipArrow = d3
      .select(this.chart)
      .append('svg')
      .style('position', 'absolute')
      .attr('height', getTooltipConfig().arrowHeight)
      .style('pointer-events', 'none')

    this.tooltipArrow.append('polygon').classed(classes.tooltipArrow, true).classed(ElementClass.tooltipArrow, true)

    this.hideTooltip()
    this.sizeUpdated()
  }

  getDomain() {
    if (this.sensorHistory.length === 0) {
      return {
        xMin: this.timeline.start,
        xMax: this.timeline.end,
        yMax: 0,
        yMin: 0,
      }
    }

    const temperatureYVals = this.sensorHistory.slice(this.numFullScaleSensors).flatMap(history => {
      if (history.values?.length) {
        return history.values.map(v => v[1])
      }

      return typeof history.currentAtFromTime === 'number' ? [history.currentAtFromTime] : []
    })

    return {
      xMin: this.timeline.start,
      xMax: this.timeline.end,
      yMax: maxNum(temperatureYVals),
      yMin: minNum([...temperatureYVals, 0]),
    }
  }

  hideTooltip() {
    this.tooltipPoints.style('display', 'none')
    this.tooltip.style('display', 'none')
    this.tooltipArrow.style('display', 'none')
  }

  updateTooltip(mouseX: number) {
    const xScale = this.currentXScale
    const yScale = this.currentYScale
    const yRightScale = this.currentYRightScale

    const overlayX = Math.round(clamp(mouseX, 0, this.chartWidth))
    const timelineDate = xScale.invert(overlayX)
    const timelineMillis = timelineDate.valueOf()

    const dataValues = this.dataCurves.flatMap((values, i) => {
      const dataPoint = values.find((v, i) => (i < values.length - 1 ? values[i + 1][0] >= timelineMillis : false))
      // Not found or before first entry
      if (!dataPoint || timelineMillis < values[0][0]) {
        return []
      }

      const codeSystem = this.sensorHistory[i].codeSystem
      return {
        value: dataPoint[1],
        label: codeSystem,
        index: i,
      }
    })

    const renderX = xScale(timelineMillis)
    const targetYLeft = maxNum([
      ...dataValues
        .slice(this.numFullScaleSensors)
        .filter(v => !this.inactiveSensors.includes(v.label))
        .map(v => v.value),
    ])
    const targetYRight = maxNum([
      ...dataValues
        .slice(0, this.numFullScaleSensors)
        .filter(v => !this.inactiveSensors.includes(v.label))
        .map(v => v.value),
    ])

    const renderYLeft = yScale(targetYLeft || 0)
    const renderYRight = yRightScale(targetYRight || 0)
    let renderY = Math.min(renderYLeft || Infinity, renderYRight || Infinity)
    if (renderY === Infinity) {
      renderY = CHART_HEIGHT - CHART_MARGIN.bottom
    }

    if (renderX !== undefined && (renderYLeft !== undefined || renderYRight !== undefined)) {
      if (!Number.isNaN(renderYLeft)) {
        this.tooltipPoints
          .selectAll<SVGCircleElement, SensorHistory>(classSelector(ElementClass.tooltipPoint))
          .attr('transform', (d, i) => {
            const scaleFunc = i < this.numFullScaleSensors ? yRightScale : yScale
            return `translate(${renderX},${scaleFunc(dataValues[i]?.value || 0)})`
          })
          .style('display', (d, i) => (!dataValues[i] || this.inactiveSensors.includes(dataValues[i].label) ? 'none' : null))
      }

      const tooltipText = dataValues
        .flatMap(val => {
          if (this.inactiveSensors.includes(val.label) || val.value === null) {
            return []
          }

          const code = this.seriesSensorCodes.find(uc => uc.key === val.label)
          const text = code
            ? `${code.localization.full[this.currentLanguage]}: ${formatNumber(val.value, 1)}${code.metric.displayName}`
            : formatNumber(val.value, 1)

          return `<li style="color: ${getChartLineColorByIndex(val.index)}"><span>${text}</span></li>`
        })
        .join('')

      this.tooltip.html(getTooltipMarkup(format(timelineMillis, TOOLTIP_DATE_FORMAT), tooltipText, dataValues.length))

      const tooltipPosition = getTooltipPosition(renderX, renderY, this.tooltip.node()!, this.chartWidth, {
        chartMargin: CHART_MARGIN,
      })

      this.tooltip.style('left', `${tooltipPosition.box.x}px`).style('top', `${tooltipPosition.box.y}px`)

      const arrowPos = tooltipPosition.arrow
      const tipOffset = arrowPos.tipX - arrowPos.x
      this.tooltipArrow
        .attr('width', tooltipArrowBoxWidth(tipOffset))
        .style('left', `${Math.min(arrowPos.x, arrowPos.tipX)}px`)
        .style('top', `${arrowPos.y}px`)
        .select(classSelector(ElementClass.tooltipArrow))
        .attr('points', tooltipArrowPolygon(tipOffset))
    }
  }

  mouseMove(event: MouseEvent) {
    const overlay = this.mouseOverlay.node()
    if (this.sensorHistory.length && overlay) {
      const mouseX = d3.pointer(event, overlay)[0]
      this.tooltipPoints.style('display', null)
      this.tooltip.style('display', null)
      this.tooltipArrow.style('display', null)
      this.updateTooltip(mouseX)
    }
  }

  setData(sensorHistory: SensorHistory[], dataPointThreshold: number, thresholdCodeSystems: string[]) {
    this.sensorHistory = sensorHistory

    const domain = this.getDomain()

    this.dataCurves = sensorHistory.map(history => {
      const threshold = thresholdCodeSystems.includes(history.codeSystem) ? dataPointThreshold : 0
      return parseValueHistory(history, this.timeline, threshold)
    })

    this.xScale.domain([domain.xMin, domain.xMax])
    this.yLeftScale.domain([domain.yMin, domain.yMax]).nice()
    this.yRightScale.domain([0, 100])
    this.zoom.scaleExtent([MIN_ZOOM, (domain.xMax - domain.xMin) / MAX_ZOOM_MAGIC_CONSTANT])
    this.resetZoom()
  }

  setInactiveSensors(inactiveSensors: string[], render: boolean = true) {
    this.inactiveSensors = inactiveSensors
    if (render) {
      this.updateSensorVisibility()
      this.update()
    }
  }

  updateSensorVisibility() {
    this.svgContainer.selectAll(classSelector(ElementClass.dataCurve)).attr('display', (d, i) => {
      const codeSystem = this.sensorHistory[i].codeSystem
      return this.inactiveSensors.includes(codeSystem) ? 'none' : null
    })
  }

  setLabels(labels: ChartLabels, seriesSensorCodes: SensorCode[], currentLanguage: Language) {
    this.labels = labels
    this.seriesSensorCodes = seriesSensorCodes
    this.currentLanguage = currentLanguage

    d3.select(this.chart).select(classSelector(ElementClass.resetZoomButton)).attr('title', labels.resetZoom)

    d3.select(this.chart).select(classSelector(ElementClass.zoomInButton)).attr('title', labels.zoomIn)

    d3.select(this.chart).select(classSelector(ElementClass.zoomOutButton)).attr('title', labels.zoomOut)

    d3.select(this.chart).select(classSelector(ElementClass.leftYAxisLabel)).text(labels.leftYAxisLabel)

    d3.select(this.chart).select(classSelector(ElementClass.rightYAxisLabel)).text(labels.rightYAxisLabel)

    this.multiTimeFormat = createTimeMultiFormat(getD3Locale(currentLanguage))
  }

  setShowZoomButtons(showZoomButtons: boolean) {
    this.showZoomButtons = showZoomButtons
  }

  private getChartWidth() {
    const parent = this.chart.parentElement
    return parent ? parent.clientWidth : 0
  }

  sizeUpdated() {
    const newWidth = Math.max(0, this.getChartWidth())
    if (newWidth !== this.chartWidth) {
      this.chartWidth = newWidth

      const clipWidth = this.chartWidth - CHART_MARGIN.left - CHART_MARGIN.right - 1

      this.svgContainer.attr('viewBox', [0, 0, this.chartWidth, CHART_HEIGHT].toString()).attr('width', this.chartWidth)

      this.xScale.range([CHART_MARGIN.left, this.chartWidth - CHART_MARGIN.right])

      this.svgClipPath.attr('width', this.chartWidth - CHART_MARGIN.left - CHART_MARGIN.right)

      const extent: Extent = [
        [CHART_MARGIN.left, CHART_MARGIN.top],
        [this.chartWidth - CHART_MARGIN.right, CHART_HEIGHT - CHART_MARGIN.bottom],
      ]

      this.yAxis2.attr('transform', `translate(${this.chartWidth - CHART_MARGIN.right},0)`)

      d3.select(this.chart)
        .select(classSelector(ElementClass.rightYAxisLabel))
        .attr('y', this.chartWidth - 20)

      this.zoom.translateExtent(extent).extent(extent)

      this.resetZoom()

      this.mouseOverlay.attr('width', clipWidth)
    }
  }

  update() {
    const xScale = this.currentXScale
    const yLeftScale = this.currentYScale
    const yRightScale = this.currentYRightScale

    const domain = this.getDomain()

    this.svgContainer
      .selectAll<SVGRectElement, [number, number][]>(classSelector(ElementClass.dataCurve))
      .attr('d', (d, i) => renderValueCurve(xScale, i < this.numFullScaleSensors ? yRightScale : yLeftScale)(d))

    this.svgContainer.selectAll<SVGPathElement, number>(classSelector(ElementClass.zeroLine)).attr('d', d => {
      const pos = `${xScale(domain.xMin)},${Math.ceil(yLeftScale(d)!) + 0.5}`
      const width = xScale(domain.xMax)!
      return `M${pos}H${width}`
    })

    let axisBottom = d3.axisBottom(xScale).ticks(Math.floor(this.chartWidth / X_PIXELS_PER_TICK))
    const timeFormat = this.multiTimeFormat
    if (timeFormat) {
      axisBottom = axisBottom.tickFormat(v => timeFormat(v.valueOf()))
    }

    this.xAxis.call(axisBottom)

    d3.select(this.chart)
      .selectAll(classSelector(ElementClass.zoomInButton))
      .style('display', this.showZoomButtons ? 'inherit' : 'none')
    d3.select(this.chart)
      .selectAll(classSelector(ElementClass.zoomOutButton))
      .style('display', this.showZoomButtons ? 'inherit' : 'none')
    d3.select(this.chart)
      .selectAll(classSelector(ElementClass.resetZoomButton))
      .style('display', this.showZoomButtons ? 'inherit' : 'none')
  }

  resetZoom() {
    this.zoom.transform(this.svgContainer.transition().duration(0), d3.zoomIdentity)
  }

  onZoom(event: d3.D3ZoomEvent<SVGSVGElement, any>) {
    const transform: d3.ZoomTransform = event.transform
    this.currentXScale = transform.rescaleX(this.xScale)

    this.hideTooltip()
    this.update()
  }

  render() {
    const yLeftScale = this.currentYScale
    const yRightScale = this.currentYRightScale

    const effortUnitIndex = 0
    const temperatureUnitIndex = 1

    this.yAxis.call(
      d3
        .axisLeft(yLeftScale)
        .tickFormat(v => formatYTick(v.valueOf(), this.seriesSensorCodes[temperatureUnitIndex].metric.displayName))
    )

    this.yAxisGrid.call(
      d3
        .axisLeft(yLeftScale)
        .tickFormat(_v => '')
        .tickSize(-this.chartWidth + CHART_MARGIN.right + CHART_MARGIN.left)
    )

    if (this.numFullScaleSensors > 0) {
      this.yAxis2.call(
        d3
          .axisRight(yRightScale)
          .tickFormat(v => formatYTick(v.valueOf(), this.seriesSensorCodes[effortUnitIndex].metric.displayName))
      )
    }
    const domain = this.getDomain()

    this.svgContainer
      .selectAll(classSelector(ElementClass.dataCurve))
      .data(this.dataCurves)
      .join('path')
      .classed(ElementClass.dataCurve, true)
      .attr('fill', 'none')
      .attr('stroke-width', (d, i) => (i < this.numFullScaleSensors ? 3 : 1.5))
      .attr('stroke', (d, i) => getChartLineColorByIndex(i))
      .style('pointer-events', 'none')
      .attr('clip-path', this.clipUrl)

    // Tooltip
    this.tooltipPoints
      .raise()
      .selectAll(classSelector(ElementClass.tooltipPoint))
      .data(this.dataCurves)
      .join('circle')
      .classed(ElementClass.tooltipPoint, true)
      .attr('r', TOOLTIP_POINT_RADIUS)

    this.svgContainer
      .selectAll(classSelector(ElementClass.zeroLine))
      .data(domain.yMin < 0 ? [0] : []) // Only add zero line if a value is below zero
      .join('path')
      .classed(ElementClass.zeroLine, true)
      .attr('stroke', themeColors.primary)
      .attr('stroke-width', 1)
      .attr('clip-path', this.clipUrl)

    this.update()
  }
}
