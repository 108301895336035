import { useTranslation } from 'react-i18next'

import { SubHeaderWithExpandableInfo } from 'src/Components/SubHeaderWithExpandableInfo'
import { AssetInformationEntry } from 'src/Components/AssetCard/AssetInformationEntry'
import { SwingAlarmStatus } from './SwingAlarmStatus'
import { WeatherStatus } from './WeatherStatus'
import { Divider } from 'src/Components/Divider'
import { StyledContent } from './elements'

import type { Asset } from 'src/Types/AssetTypes'
import { formatNumber, formatLocalDateTime } from 'src/Utils/format'
import { SubHeaderKey } from 'src/Providers/Settings/SubHeader'
import type { Swing } from 'src/Types/SwingTypes'
import type { NormalizedAlarm } from 'src/Types/NormalizedAlarm'

type OwnProps = {
  asset: Asset
  swing: Swing | undefined
  alarms: NormalizedAlarm[] | undefined
  isLoading: boolean
}

export const SwingInfo = ({ asset, swing, alarms, isLoading }: OwnProps) => {
  const { t } = useTranslation()

  const swingDurations = swing?.machineSwings.map(s =>
    s.isComplete && s.duration ? `${formatNumber(s.duration / 1000, 2)} s` : '-'
  )

  const renderContent = () => {
    if (!swing) {
      return null
    }

    return (
      <>
        <StyledContent>
          <AssetInformationEntry label={t('switches.statusLabels.time')} values={[formatLocalDateTime(swing.timestamp)]} />
          <AssetInformationEntry
            label={t('switches.statusLabels.pointMachineName')}
            values={swing.machineSwings.map(s => s.baneDataName)}
          />
          <AssetInformationEntry label={t('switches.statusLabels.duration')} values={swingDurations} />
          <Divider />
          <SwingAlarmStatus alarms={alarms || []} isLoading={isLoading} pointMachineSwings={swing.machineSwings} />
          <Divider />
          <AssetInformationEntry
            label={t('switches.statusLabels.direction.label')}
            values={swing.machineSwings.map(s =>
              s.direction ? t(`switches.statusLabels.direction.${s.direction}`) : t('switches.statusLabels.swingTrend.unknown')
            )}
          />
          <Divider />
        </StyledContent>
        <WeatherStatus locationId={asset.baneDataLocationId} swingTimestamp={swing.timestamp} baneDataId={asset.baneDataId} />
      </>
    )
  }

  return (
    <SubHeaderWithExpandableInfo
      title={t('switches.infoSwing')}
      isLoading={isLoading}
      isDefaultExpanded
      subHeaderKey={SubHeaderKey.SwingInfo}
    >
      {renderContent()}
    </SubHeaderWithExpandableInfo>
  )
}
