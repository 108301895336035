import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'

import type { RailwayLocationModel, RailwaySectionModel } from './types'

import { Checkbox } from 'src/Components/Checkbox'
import { StyledLocationListItemButton } from './SelectorComponents'
import { useLocationSelectorToggles } from 'src/Components/Header/Locations/useLocationSelectorToggles'

export const StyledRailwaySectionName = styled('span')`
  max-width: 95%;
`

type OwnProps = {
  railwaySection: RailwaySectionModel
  stretchLocations: RailwayLocationModel[]
  isTextSearchMode?: boolean
}

export const StretchLocationsSelector = ({ railwaySection, stretchLocations, isTextSearchMode = false }: OwnProps) => {
  const { toggleLocation, toggleRailwaySection } = useLocationSelectorToggles()

  return (
    <>
      <StyledLocationListItemButton
        isSelected={railwaySection.isAllPending || !isTextSearchMode}
        onClick={() => toggleRailwaySection(railwaySection)}
      >
        <StyledRailwaySectionName>{railwaySection.name}</StyledRailwaySectionName>
        <ListItemSecondaryAction>
          <Checkbox
            edge="end"
            onChange={() => toggleRailwaySection(railwaySection)}
            checked={railwaySection.isAllPending}
            inputProps={{ 'aria-labelledby': railwaySection.railwaySectionId }}
          />
        </ListItemSecondaryAction>
      </StyledLocationListItemButton>
      <Box ml={2}>
        {stretchLocations
          .filter(stretchLocation => stretchLocation.searchTreeMatch)
          .map(location => {
            return (
              <StyledLocationListItemButton
                isSelected={location.isPending}
                onClick={() => toggleLocation(location)}
                key={location.locationId}
              >
                {location.name}
                <ListItemSecondaryAction>
                  <Checkbox
                    edge="end"
                    onChange={() => toggleLocation(location)}
                    checked={location.isPending}
                    inputProps={{ 'aria-labelledby': location.locationId }}
                  />
                </ListItemSecondaryAction>
              </StyledLocationListItemButton>
            )
          })}
      </Box>
    </>
  )
}
