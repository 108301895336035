import type { ReactNode } from 'react'
import CircularProgress from '@mui/material/CircularProgress'

import { AssetInformationEntry } from 'src/Components/AssetCard/AssetInformationEntry'

import { useBreakpointDown } from 'src/Hooks/useBreakpoint'
import { flexibleTranslation } from 'src/Utils/language'
import { useLanguage } from 'src/Hooks/useLanguage'
import { useDaysSinceLastPointMachineAlarm } from 'src/Hooks/NetworkData/useDaysSinceLastPointMachineAlarm'

type OwnProps = {
  baneDataId: string
}

export const DaysSinceLastPointMachineAlarm = ({ baneDataId }: OwnProps) => {
  const { t } = useLanguage()
  const isMediaSM = useBreakpointDown('sm')

  const { daysSinceLastAlarm, status } = useDaysSinceLastPointMachineAlarm(baneDataId)

  let daysSinceLastAlarmContent: ReactNode
  switch (status) {
    case 'success':
      daysSinceLastAlarmContent =
        typeof daysSinceLastAlarm === 'number' ? daysSinceLastAlarm : t('switches.statusLabels.maintenance.neverFailed')
      break
    case 'error':
      daysSinceLastAlarmContent = t('switches.statusLabels.maintenance.daysSinceLastAlarmFailed')
      break
    default:
      daysSinceLastAlarmContent = <CircularProgress size={15} />
      break
  }

  const labelKey = flexibleTranslation('daysSinceLastAlarm', isMediaSM)

  return <AssetInformationEntry label={t(`switches.statusLabels.maintenance.${labelKey}`)} values={[daysSinceLastAlarmContent]} />
}
