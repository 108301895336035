import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useInitialSearchParams } from 'src/Hooks/useInitialSearchParams'
import { useStartPage } from 'src/Hooks/useStartPage'

/*
 * Hook that redirects to start page.
 */
export const useNavigateToStartPage = () => {
  const finished = useRef(false)
  const navigate = useNavigate()
  const { initialDatePeriod, initialStretchLocations } = useInitialSearchParams()

  const { startPageLink, isReady: isInitialPageReady } = useStartPage()

  useEffect(() => {
    if (finished.current) {
      return
    }

    if (initialStretchLocations?.length && initialDatePeriod) {
      finished.current = true
      return
    }

    if (!isInitialPageReady) {
      return
    }

    finished.current = true

    if (startPageLink) {
      navigate(startPageLink)
    }
  }, [initialDatePeriod, startPageLink, initialStretchLocations, isInitialPageReady, navigate])
}
