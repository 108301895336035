import * as d3 from 'd3'
import * as d3zoom from 'd3-zoom'
import { nanoid } from 'nanoid'
import { darken, alpha } from '@mui/material/styles'

import type {
  LinearNumberScale,
  Extent,
  SimpleSelection,
  ChartLabels,
  Rect,
  SVGGSelection,
  SVGRectSelection,
} from 'src/Types/ChartTypes'
import type {
  Swing,
  SwingCurveDataPoint,
  SlipCurrent,
  ReferenceCurvePoint,
  ZoomLevel,
  Phase,
  MachineSwing,
} from 'src/Types/SwingTypes'
import type { AlarmType } from 'src/Types/AlarmType'
import type { Alarm } from 'src/Types/Alarm'

import { toggleForceDomainButton, toggleWidthButton } from 'src/Components/Chart/chartStyles'
import { getTooltipMarkup } from 'src/Components/Chart/ChartRenderer'

import { formatNumber } from 'src/Utils/format'
import { clamp, roundToStep } from 'src/Utils/number'
import { getChartLineColorByIndex } from 'src/Utils/chart'

import { isUnselectedSequenceSwing } from './isUnselectedSequenceSwing'

import themeColors from 'src/theme'

type ChartProps = {
  chart: HTMLDivElement
  classes: {
    tooltip: string
    zoomButton: string
  }
  zoomLevels: ZoomLevel[]
  initialFullWidth: boolean
  onToggleFullChartWidth: () => void
  translations: {
    referenceCurve: string
    slipCurrentLabel: string
  }
  alarmTypes: AlarmType[]
}

type ReferenceCurveType = 'measurement' | 'measurementMin' | 'measurementMax'

type Band = {
  from: number
  to: number
  label?: string
}

enum MeasurementType {
  Force = 'forceMeasurements',
  Current = 'currentMeasurements',
}

const MEASUREMENT_UNIT = {
  [MeasurementType.Force]: 'W',
  [MeasurementType.Current]: 'A',
}

const formatTick = (unit: string, decimals: number, value: number) => `${formatNumber(value, decimals, false)} ${unit}`

const formatYTick = (measurementType: MeasurementType, value: number) => {
  const decimals = measurementType === MeasurementType.Force ? 0 : 2
  return formatTick(MEASUREMENT_UNIT[measurementType], decimals, value)
}

const renderSwingLine = (xScale: LinearNumberScale, yScale: LinearNumberScale) =>
  d3
    .line()
    .x(d => xScale(d[0])!)
    .y(d => yScale(d[1])!)

const mapPhaseBands = (phases: Phase[], startTimeOffset: number, language: 'en' | 'no' = 'no'): Band[] =>
  phases.map(({ start, end, name }) => {
    return { from: start + startTimeOffset, to: end + startTimeOffset, label: name?.[language] }
  })

const mapReferenceCurve = (data: ReferenceCurvePoint[], offset: number, type: ReferenceCurveType) =>
  data.map<SwingCurveDataPoint>(d => [d.timeMs + offset, d[type] || 0])

enum ElementClass {
  zeroLine = 'zeroLine',
  swingLine = 'swing-line',
  referenceCurve = 'reference-curve',
  tooltipPoint = 'tooltip-point',
  band = 'band',
  bandLabel = 'band-label',
  slipCurrent = 'slip-current',
  tooltipPoints = 'tooltip-points',
  toggleForceDomainButton = 'toggle-force-domain-button',
  toggleWidthButton = 'toggle-width-button',
}

const classSelector = (key: ElementClass) => `.${key}`

const bandBackgroundColors = themeColors.chartColors.bands.map(color => alpha(color, 0.2))
const bandLabelColors = themeColors.chartColors.bands.map(color => darken(color, 0.3))

const colorByIndexFunc = (colors: string[]) => (i: number) => colors[i % colors.length]
const getBandBackgroundColor = colorByIndexFunc(bandBackgroundColors)
const getBandLabelColor = colorByIndexFunc(bandLabelColors)

const CHART_MARGIN: Rect = { top: 5, right: 0, bottom: 20, left: 50 }
const CHART_HEIGHT = 400
const ZOOM_BUTTON_MARGIN = 10
const ZOOM_BUTTON_WIDTH = 30
const ZOOM_BUTTON_SPACING = 35
const MIN_ZOOM = 1
const MAX_ZOOM = 16
const TOOLTIP_MARGIN = 10
const TOOLTIP_POINT_RADIUS = 4

export class SwitchChartRenderer {
  chart: HTMLDivElement

  chartWidth: number

  swing: Swing | undefined

  swingCurves: SwingCurveDataPoint[][] = []

  slipCurrents: SlipCurrent[] = []

  phases: Phase[][] = []

  svgContainer: SimpleSelection<SVGSVGElement>

  xAxis: SVGGSelection

  yAxis: SVGGSelection

  yAxisGrid: SVGGSelection

  xScale: LinearNumberScale

  yScale: LinearNumberScale

  currentXScale: LinearNumberScale

  currentYScale: LinearNumberScale

  zoom: d3.ZoomBehavior<SVGSVGElement, unknown>

  zoomToggleButton: SimpleSelection<HTMLButtonElement>

  widthToggleButton: SimpleSelection<HTMLButtonElement>

  clipUrl: string

  svgClipPath: SVGRectSelection

  mouseOverlay: SVGRectSelection

  tooltip: SimpleSelection<HTMLDivElement>

  tooltipPoints: SVGGSelection

  tooltipPointReferenceCurve: SVGGSelection

  tooltipPointSlipCurrent: SVGGSelection

  inactivePointMachines: number[] = []

  activeSlipCurrent?: number

  activePhases: number[] = []

  activeAlarmPhases: number[] = []

  alarmTypes: AlarmType[]

  alarmsWithPhaseIds: Alarm[] | undefined = []

  activeReferenceCurve?: number

  fullWidthChart: boolean

  zoomLevels: ZoomLevel[] = []

  zoomLevelIndex: number = 0

  private currentLanguage: 'en' | 'no' | undefined

  private translations: { referenceCurve: string; slipCurrentLabel: string }

  constructor({ chart, classes, initialFullWidth, zoomLevels, onToggleFullChartWidth, translations, alarmTypes }: ChartProps) {
    this.chart = chart
    this.chartWidth = 0
    this.fullWidthChart = initialFullWidth
    this.zoomLevels = zoomLevels
    this.translations = translations
    this.alarmTypes = alarmTypes

    const clipId = nanoid()
    this.clipUrl = `url(#${clipId})`

    this.xScale = d3.scaleLinear()

    this.yScale = d3.scaleLinear().range([CHART_HEIGHT - CHART_MARGIN.bottom, CHART_MARGIN.top])

    this.currentXScale = this.xScale
    this.currentYScale = this.yScale

    this.zoom = d3zoom
      .zoom<SVGSVGElement, unknown>()
      .scaleExtent([MIN_ZOOM, MAX_ZOOM])
      .on('zoom', () => this.onZoom())

    // Fiks for å ikke rendre to grafer i localhost
    d3.select(this.chart).selectAll('svg').remove()
    this.svgContainer = d3.select(this.chart).append('svg').attr('height', CHART_HEIGHT)

    this.svgClipPath = this.svgContainer
      .append('clipPath')
      .attr('id', clipId)
      .append('rect')
      .attr('x', CHART_MARGIN.left + 1)
      .attr('y', CHART_MARGIN.top)
      .attr('height', CHART_HEIGHT - CHART_MARGIN.top - CHART_MARGIN.bottom)

    this.xAxis = this.svgContainer.append('g').attr('transform', `translate(0,${CHART_HEIGHT - CHART_MARGIN.bottom})`)

    this.yAxisGrid = this.svgContainer
      .append('g')
      .attr('transform', `translate(${CHART_MARGIN.left},0)`)
      .attr('color', themeColors.disabled)

    this.yAxis = this.svgContainer.append('g').attr('transform', `translate(${CHART_MARGIN.left},0)`)

    this.mouseOverlay = this.svgContainer
      .append('rect')
      .attr('x', CHART_MARGIN.left + 1)
      .attr('y', CHART_MARGIN.top)
      .attr('height', CHART_HEIGHT - CHART_MARGIN.top - CHART_MARGIN.bottom)
      .style('fill', 'none')
      .style('pointer-events', 'all')
      .on('mouseout', () => this.hideTooltip())
      .on('mousemove', event => this.mouseMove(event as MouseEvent))

    this.zoomToggleButton = d3
      .select(this.chart)
      .append('button')
      .classed(ElementClass.toggleForceDomainButton, true)
      .classed(classes.zoomButton, true)
      .style('display', 'none')
      .html(toggleForceDomainButton)
      .style('bottom', `${CHART_MARGIN.bottom + ZOOM_BUTTON_SPACING + ZOOM_BUTTON_MARGIN}px`)
      .on('click', () => {
        const newIndex = (this.zoomLevelIndex + 1) % zoomLevels.length
        this.zoomLevelIndex = newIndex
        const domain = this.getDomain()
        this.yScale.domain([domain.yMin, domain.yMax]).nice()
        this.resetZoom()
      })

    this.widthToggleButton = d3
      .select(this.chart)
      .append('button')
      .classed(ElementClass.toggleWidthButton, true)
      .classed(classes.zoomButton, true)
      .html(toggleWidthButton)
      .style('bottom', `${CHART_MARGIN.bottom + ZOOM_BUTTON_MARGIN}px`)
      .on('click', () => {
        this.toggleFullWidth()
        onToggleFullChartWidth()
      })

    this.tooltip = d3
      .select(this.chart)
      .append('div')
      .classed(classes.tooltip, true)
      .style('pointer-events', 'none')
      .style('display', 'none')

    this.tooltipPoints = this.svgContainer
      .append('g')
      .classed(ElementClass.tooltipPoints, true)
      .style('pointer-events', 'none')
      .style('display', 'none')
      .attr('clip-path', this.clipUrl)

    this.tooltipPointReferenceCurve = this.svgContainer
      .append('g')
      .classed(ElementClass.tooltipPoints, true)
      .style('pointer-events', 'none')
      .style('display', 'none')
      .attr('clip-path', this.clipUrl)

    this.tooltipPointSlipCurrent = this.svgContainer
      .append('g')
      .classed(ElementClass.tooltipPoints, true)
      .style('pointer-events', 'none')
      .style('display', 'none')
      .attr('clip-path', this.clipUrl)

    this.sizeUpdated()
  }

  isUnselectedSequenceSwing(index: number) {
    const swing = this.swing?.machineSwings[index]
    const switchBaneDataId = this.swing?.baneDataId
    return !!swing && !!switchBaneDataId && isUnselectedSequenceSwing(switchBaneDataId, swing)
  }

  getChartLineColorByIndex(index: number) {
    if (this.isUnselectedSequenceSwing(index)) {
      const pointMachineSwing = this.swing?.machineSwings[index]
      const swingIndex = this.swing?.machineSwings.findIndex(
        swing => swing.isInSequenceWithInfo?.baneDataId === pointMachineSwing?.baneDataId
      )
      return swingIndex !== undefined ? getChartLineColorByIndex(swingIndex) : themeColors.chartColors.subordinate
    }
    return getChartLineColorByIndex(index)
  }

  hideTooltip() {
    this.tooltipPoints.style('display', 'none')
    this.tooltipPointReferenceCurve.style('display', 'none')
    this.tooltipPointSlipCurrent.style('display', 'none')
    this.tooltip.style('display', 'none')
  }

  getMeasurementType() {
    if (!this.swing) {
      return MeasurementType.Current
    }

    const completeSwings = this.swing.machineSwings.filter(s => s.isComplete)

    if (!completeSwings.length || !completeSwings[0]) {
      return MeasurementType.Current
    }

    const measurements = completeSwings[0].forceMeasurements
    return measurements?.length ? MeasurementType.Force : MeasurementType.Current
  }

  getSampleFrequency() {
    if (!this.swing) {
      return 0
    }

    const completeSwings = this.swing.machineSwings.filter(swing => swing.isComplete)

    return completeSwings.length ? completeSwings[0].sampleFrequency || 0 : 0
  }

  getTimeOrigin() {
    const timeStampsOfSwings = this.swing?.machineSwings.filter(s => s.timestamp).map(s => s.timestamp!)
    const earliestTimeStamp = timeStampsOfSwings ? Math.min(...timeStampsOfSwings) : this.swing?.timestamp
    const swingStart = earliestTimeStamp || 0
    return swingStart
  }

  getSwingCurves() {
    if (!this.swing) {
      return []
    }

    const swingStart = this.getTimeOrigin()

    const measurementType = this.getMeasurementType()
    return this.swing.machineSwings.map(swing => {
      const measurements = swing[measurementType]
      if (!swing.isComplete || !measurements || !swing.timestamp) {
        return []
      }

      const pmOffset = swing.timestamp - swingStart
      return measurements.map<SwingCurveDataPoint>(dp => [pmOffset + dp[1], dp[0]])
    })
  }

  getDomain() {
    const maxCurveX = Math.max(...this.swingCurves.map(c => c.map(entry => entry[0])).flat())

    const swingStart = this.getTimeOrigin()

    const referenceCurveEndPoints = (this.swing?.machineSwings || []).map(s => {
      if (!s.referenceCurve || !s.referenceCurve.measurements.length || !s.timestamp) {
        return 0
      }

      const lastPoint = s.referenceCurve.measurements[s.referenceCurve.measurements.length - 1]
      const offset = s.timestamp - swingStart
      return lastPoint.timeMs + offset
    })
    const maxReferenceCurveX = Math.max(...referenceCurveEndPoints)

    let yMin = this.zoomLevels[this.zoomLevelIndex].zoomLow
    let yMax = this.zoomLevels[this.zoomLevelIndex].zoomHigh
    if (yMin === 0 && yMax === 0) {
      const yVals = this.swingCurves.map(c => c.map(entry => entry[1])).flat()
      const maxCurveY = Math.max(...yVals)
      const maxSlipY = Math.max(...this.slipCurrents.map((sc, _i) => sc || 0))

      const referenceCurveMaxYPoints = (this.swing?.machineSwings || []).map(s => {
        if (!s.referenceCurve || !s.referenceCurve.measurements.length) {
          return 0
        }

        return Math.max(...s.referenceCurve.measurements.map(m => m.measurement))
      })
      const maxReferenceCurveY = Math.max(...referenceCurveMaxYPoints)

      yMin = Math.min(...yVals, 0)
      yMax = Math.max(maxCurveY, maxSlipY, maxReferenceCurveY)
    }

    return {
      xMin: 0,
      xMax: Math.max(maxCurveX, maxReferenceCurveX),
      yMax,
      yMin,
    }
  }

  getTooltipPosition(tx: number, ty: number) {
    const { clientWidth: tooltipWidth, clientHeight: tooltipHeight } = this.tooltip.node()!

    let x = tx + TOOLTIP_MARGIN
    let y = ty
    if (y < TOOLTIP_MARGIN) {
      y = TOOLTIP_MARGIN
    }
    y = Math.min(y, CHART_HEIGHT - TOOLTIP_MARGIN - tooltipHeight - CHART_MARGIN.bottom)
    if (x > this.chartWidth - tooltipWidth - TOOLTIP_MARGIN) {
      x = tx - tooltipWidth - TOOLTIP_MARGIN
    }
    return { x, y }
  }

  getDataPoints(step: number, curveIndex: number, swing: Swing) {
    return this.swingCurves.flatMap((curve, i) => {
      if (!curve.length) {
        return []
      }

      const curveOffset = curve[0][0] / step
      const dataPoint = curve[curveIndex - curveOffset]

      if (!dataPoint || this.inactivePointMachines.includes(i)) {
        return []
      }

      return {
        machineName: swing.machineSwings[i].baneDataName,
        x: dataPoint[0],
        y: dataPoint[1],
        index: i,
      }
    })
  }

  getSlipCurrentPoints(timeOffset: number) {
    return this.slipCurrents.flatMap((slipCurrent, i) => {
      if (this.activeSlipCurrent !== i) {
        return []
      }

      const phases = this.phases[i]
      const lockingPhase = phases?.length ? phases[phases.length - 1] : undefined
      if (lockingPhase) {
        const startTimeOffset = this.swingCurves[i][0][0]
        if (timeOffset < lockingPhase.start + startTimeOffset || timeOffset > lockingPhase.end + startTimeOffset) {
          return []
        }
      }

      return slipCurrent
    })
  }

  getTooltipItems(dataPoints: { machineName: string; x: number; y: number; index: number }[], measurementType: MeasurementType) {
    return dataPoints.map(dp => {
      const text = `${dp.machineName}: ${formatYTick(measurementType, dp.y)}`
      const color = this.getChartLineColorByIndex(dp.index)
      return `<li style="color: ${color}"><span>${text}</span></li>`
    })
  }

  getSlipCurrentTooltipItems(slipCurrentPoints: (number | undefined)[], measurementType: MeasurementType) {
    const items: string[] = []

    slipCurrentPoints.forEach(point => {
      const index = this.slipCurrents.indexOf(point)
      if (this.activeSlipCurrent === index) {
        const text = `${this.translations.slipCurrentLabel}: ${point?.toFixed(2)} ${MEASUREMENT_UNIT[measurementType]}`
        const color = this.getChartLineColorByIndex(index)
        items.push(`<li style="color: ${color}"><span>${text}</span></li>`)
      }
    })

    return items
  }

  updateTooltip(swing: Swing, mouseX: number) {
    const xScale = this.currentXScale
    const yScale = this.currentYScale

    const overlayX = Math.round(clamp(mouseX, 0, this.chartWidth))
    const step = (1 / this.getSampleFrequency()) * 1000
    const timelineX = xScale.invert(overlayX)
    const timeOffset = Math.max(xScale.invert(CHART_MARGIN.left), roundToStep(timelineX, step))
    const curveIndex = Math.round(timeOffset / step)

    const measurementType = this.getMeasurementType()
    const dataPoints = this.getDataPoints(step, curveIndex, swing)

    const slipCurrentPoints = this.getSlipCurrentPoints(timeOffset)

    this.tooltipPoints
      .selectAll(classSelector(ElementClass.tooltipPoint))
      .attr('transform', (_curve, i) => `translate(${xScale(timeOffset)},${yScale(dataPoints[i]?.y ?? 0)})`)
      .style('display', (_curve, i) => (dataPoints[i] ? null : 'none'))

    let tooltipItems = this.getTooltipItems(dataPoints, measurementType)

    let referenceCurveValue: number | undefined
    if (typeof this.activeReferenceCurve === 'number') {
      const value = getReferenceCurveValue(
        this.swingCurves,
        this.swing?.machineSwings,
        this.activeReferenceCurve,
        step,
        curveIndex
      )

      if (value) {
        referenceCurveValue = value
        const formattedReferenceCurveValue = formatYTick(measurementType, value)
        tooltipItems.push(
          // eslint-disable-next-line max-len
          `<li style="color: ${themeColors.chartColors.referenceCurve[0]}"><span>${this.translations.referenceCurve}: ${formattedReferenceCurveValue}</span>
</li>`
        )
      }
    }

    this.tooltipPointReferenceCurve
      .selectAll(classSelector(ElementClass.tooltipPoint))
      .attr('transform', (_curve, _i) => `translate(${xScale(timeOffset)},${yScale(referenceCurveValue ?? 0)})`)
      .style('display', (_curve, _i) => (referenceCurveValue ? null : 'none'))

    this.tooltipPointSlipCurrent
      .selectAll(classSelector(ElementClass.tooltipPoint))
      .attr('transform', (_curve, i) => `translate(${xScale(timeOffset)},${yScale(slipCurrentPoints[i] ?? 0)})`)
      .style('display', (_curve, i) => (slipCurrentPoints[i] ? null : 'none'))

    tooltipItems = tooltipItems.concat(this.getSlipCurrentTooltipItems(slipCurrentPoints, measurementType))

    const tooltipText = tooltipItems.join('')

    this.tooltip.html(getTooltipMarkup(`${formatNumber(timeOffset / 1000, 2, false)} s`, tooltipText, dataPoints.length))

    const tooltipX = xScale(timeOffset)
    const tooltipY = yScale(d3.mean(dataPoints, d => d.y) || 0)

    if (tooltipX !== undefined && tooltipY !== undefined) {
      const tooltipPosition = this.getTooltipPosition(tooltipX, tooltipY)

      this.tooltip.style('left', `${tooltipPosition.x}px`).style('top', `${tooltipPosition.y}px`)
    }
  }

  mouseMove(event: MouseEvent) {
    const overlay = this.mouseOverlay.node()
    if (this.swing && overlay) {
      const mouseX = d3.pointer(event, overlay)[0]
      this.tooltipPoints.style('display', null)
      this.tooltipPointReferenceCurve.style('display', null)
      this.tooltipPointSlipCurrent.style('display', null)
      this.tooltip.style('display', null)
      this.updateTooltip(this.swing, mouseX)
    }
  }

  setData(swing: Swing, slipCurrents: SlipCurrent[]) {
    this.swing = swing
    this.swingCurves = this.getSwingCurves()
    this.phases = swing.machineSwings.map(swing => swing.swingAnalysis?.phases || [])
    this.slipCurrents = slipCurrents

    const domain = this.getDomain()
    this.xScale.domain([domain.xMin, domain.xMax])
    this.yScale.domain([domain.yMin, domain.yMax]).nice()

    d3.select(this.chart)
      .select(classSelector(ElementClass.toggleForceDomainButton))
      .style('display', this.zoomLevels.length > 1 ? 'unset' : 'none')

    this.resetZoom()
  }

  setLabels(labels: ChartLabels) {
    this.zoomToggleButton.attr('title', labels.toggleForceDomain)

    this.widthToggleButton.attr('title', labels.toggleWidth)
  }

  setLanguage(currentLanguage: string) {
    if (currentLanguage === 'no' || currentLanguage === 'en') {
      this.currentLanguage = currentLanguage
    }
  }

  private getChartWidth() {
    const parent = this.chart.parentElement
    const parentWidth = parent ? parent.clientWidth : 0

    if (this.fullWidthChart) {
      return parentWidth
    }

    const maxWidth = CHART_HEIGHT + CHART_MARGIN.top + CHART_MARGIN.bottom

    return Math.min(parentWidth, maxWidth)
  }

  toggleFullWidth() {
    this.fullWidthChart = !this.fullWidthChart
    this.sizeUpdated()
  }

  sizeUpdated() {
    const newWidth = Math.max(0, this.getChartWidth())
    if (newWidth !== this.chartWidth) {
      this.chartWidth = newWidth

      const clipWidth = this.chartWidth - CHART_MARGIN.left - CHART_MARGIN.right - 1

      this.svgContainer.attr('viewBox', [0, 0, this.chartWidth, CHART_HEIGHT].toString()).attr('width', this.chartWidth)

      this.xScale.range([CHART_MARGIN.left, this.chartWidth - CHART_MARGIN.right])

      this.svgClipPath.attr('width', this.chartWidth - CHART_MARGIN.left - CHART_MARGIN.right)

      if (this.fullWidthChart) {
        this.zoomToggleButton.style('right', `${CHART_MARGIN.right + ZOOM_BUTTON_MARGIN}px`).style('left', 'unset')
        this.widthToggleButton.style('right', `${CHART_MARGIN.right + ZOOM_BUTTON_MARGIN}px`).style('left', 'unset')
      } else {
        const left = this.getChartWidth() - CHART_MARGIN.right - ZOOM_BUTTON_WIDTH
        this.zoomToggleButton.style('left', `${left}px`).style('right', 'unset')
        this.widthToggleButton.style('left', `${left}px`).style('right', 'unset')
      }

      const extent: Extent = [
        [CHART_MARGIN.left, CHART_MARGIN.top],
        [this.chartWidth - CHART_MARGIN.right, CHART_HEIGHT - CHART_MARGIN.bottom],
      ]

      this.zoom.translateExtent(extent).extent(extent)

      this.resetZoom()

      this.mouseOverlay.attr('width', clipWidth)
    }
  }

  updatePointMachineVisibility() {
    this.svgContainer
      .selectAll(classSelector(ElementClass.swingLine))
      .attr('display', (_d, i) => (this.inactivePointMachines.includes(i) ? 'none' : null))
  }

  updateSlipCurrentVisibility() {
    this.svgContainer
      .selectAll(classSelector(ElementClass.slipCurrent))
      .attr('display', (d, i) => (this.activeSlipCurrent !== i || d === undefined ? 'none' : null))
  }

  setInactivePointMachines(inactivePointMachines: number[], render: boolean = true) {
    this.inactivePointMachines = inactivePointMachines
    if (render) {
      this.updatePointMachineVisibility()
      this.update()
    }
  }

  setActiveSlipCurrent(activeSlipCurrent: number | undefined, render: boolean = true) {
    this.activeSlipCurrent = activeSlipCurrent
    if (render) {
      this.updateSlipCurrentVisibility()
      this.update()
    }
  }

  setActivePhases(activePhases: number | undefined, render: boolean = true) {
    this.activePhases = activePhases !== undefined ? [activePhases] : []
    if (render) {
      this.renderPhaseBands()
      this.update()
    }
  }

  setActiveAlarmPhases(machineIndex: number | undefined, alarmsWithPhaseIds: Alarm[] | undefined, render: boolean = true) {
    this.activeAlarmPhases = machineIndex !== undefined ? [machineIndex] : []
    this.alarmsWithPhaseIds = alarmsWithPhaseIds

    if (render) {
      this.renderActiveAlarmPhasesBands()
      this.update()
    }
  }

  setActiveReferenceCurve(pmIndex?: number, render: boolean = true) {
    this.activeReferenceCurve = pmIndex
    if (render) {
      this.renderReferenceCurve()
      this.update()
    }
  }

  updatePhaseBands() {
    this.svgContainer
      .selectAll<SVGRectElement, Band>(classSelector(ElementClass.band))
      .attr('width', d => this.currentXScale(d.to)! - this.currentXScale(d.from)!)
      .attr('x', d => this.currentXScale(d.from)!)

    this.svgContainer
      .selectAll<SVGRectElement, Band>(classSelector(ElementClass.bandLabel))
      .attr('width', d => this.currentXScale(d.to)! - this.currentXScale(d.from)!)
      .attr('x', d => this.currentXScale(d.from)!)
  }

  update() {
    const measurementType = this.getMeasurementType()
    const xScale = this.currentXScale
    const yScale = this.currentYScale

    const domain = this.getDomain()

    this.svgContainer.selectAll<SVGPathElement, number>(classSelector(ElementClass.zeroLine)).attr('d', d => {
      const pos = `${xScale(domain.xMin)},${Math.ceil(yScale(d)!)}`
      const width = xScale(domain.xMax)! - CHART_MARGIN.right
      return `M${pos}H${width}`
    })

    this.svgContainer
      .selectAll<SVGPathElement, SwingCurveDataPoint[]>(classSelector(ElementClass.referenceCurve))
      .attr('d', renderSwingLine(xScale, yScale))

    this.svgContainer
      .selectAll<SVGPathElement, SwingCurveDataPoint[]>(classSelector(ElementClass.swingLine))
      .attr('d', d => renderSwingLine(xScale, yScale)(d))
    this.updatePhaseBands()
    // TODO Trenger en this.updateActiveAlarmPhases()?

    this.svgContainer
      .selectAll<SVGRectElement, number | undefined>(classSelector(ElementClass.slipCurrent))
      .attr('d', (slipCurrent, pointMachineIndex) => {
        const phases = this.phases[pointMachineIndex] || []
        const lockingPhase = phases.length ? phases[phases.length - 1] : undefined
        if (slipCurrent && this.activeSlipCurrent === pointMachineIndex) {
          const startTimeOffset = this.swingCurves[pointMachineIndex][0][0]

          // If the locking phase is missing, render the slip current to the end of the swing chart
          const end = lockingPhase ? lockingPhase.end + startTimeOffset : domain.xMax
          const left = lockingPhase ? lockingPhase.start + startTimeOffset : startTimeOffset
          return `M${xScale(left)},${yScale(slipCurrent)}H${xScale(end)}`
        }
        return ''
      })

    this.xAxis.call(d3.axisBottom(xScale).tickFormat(v => formatTick('s', 2, v.valueOf() / 1000)))

    this.yAxis.call(d3.axisLeft(yScale).tickFormat(v => formatYTick(measurementType, v.valueOf())))

    this.yAxisGrid.call(
      d3
        .axisLeft(yScale)
        .tickFormat(_v => '')
        .tickSize(-this.chartWidth + CHART_MARGIN.right + CHART_MARGIN.left)
    )
  }

  resetZoom() {
    this.zoom.transform(this.svgContainer.transition().duration(0), d3.zoomIdentity)
  }

  onZoom() {
    this.hideTooltip()
    this.update()
  }

  getAlarmColor(id: number | undefined, isBand: boolean = false) {
    const alarmType = this.alarmTypes.find(t => t.type.id === id)
    const classification = alarmType?.classification.id
    const color = classification === 'Alarm' ? themeColors.error : themeColors.warning
    return isBand ? alpha(color, 0.2) : darken(color, 0.3)
  }

  // eslint-disable-next-line class-methods-use-this
  getAlarmTypeId(i: number) {
    return this.alarmsWithPhaseIds?.length ? this.alarmsWithPhaseIds[i]?.alarmTypeId : undefined
  }

  addBandsToSvgContainer(bands: Band[], isAlarmPhases = false) {
    this.svgContainer
      .selectAll(classSelector(ElementClass.band))
      .data(bands)
      .join('rect')
      .classed(ElementClass.band, true)
      .attr('fill', (_, i) => (isAlarmPhases ? this.getAlarmColor(this.getAlarmTypeId(i), true) : getBandBackgroundColor(i)))
      .attr('height', CHART_HEIGHT)
      .style('pointer-events', 'none')
      .attr('clip-path', this.clipUrl)
  }

  addLabelsToSvgContainer(bands: Band[], isAlarmPhases = false) {
    this.svgContainer.selectAll(classSelector(ElementClass.bandLabel)).remove()
    const bandLabels = this.svgContainer.selectAll(classSelector(ElementClass.bandLabel)).data(bands).join('svg')
    bandLabels
      .classed(ElementClass.bandLabel, true)
      .attr('y', 4)
      .attr('dy', '.45em')
      .attr('style', (_d, _i) => 'font-size: .8em')
      .attr('fill', (_d, i) => (isAlarmPhases ? this.getAlarmColor(this.getAlarmTypeId(i)) : getBandLabelColor(i)))

    let bandTexts = bandLabels.select<SVGTextElement>('text')
    if (bandTexts.size() === 0) {
      bandTexts = bandLabels.append('text')
    }

    bandTexts.attr('transform', 'rotate(90,0,4)').text(d => `${d.label}`)
  }

  getStartTimeOffset(machineIndex: number) {
    return this.swingCurves[machineIndex]?.[0]?.[0] ?? 0
  }

  renderPhaseBands() {
    let phaseBands: Band[] = []

    // Only render phase bands if a single machine's phases are active
    const activePhases = this.phases.map((_p, i) => i).filter(i => this.activePhases.includes(i))
    if (activePhases.length === 1) {
      const pointMachineIndex = activePhases[0]
      const phases = this.phases[pointMachineIndex]
      const startTimeOffset = this.getStartTimeOffset(pointMachineIndex)

      if (phases) {
        phaseBands = mapPhaseBands(phases, startTimeOffset, this.currentLanguage)
      }
    }

    this.addBandsToSvgContainer(phaseBands)
    this.addLabelsToSvgContainer(phaseBands)
  }

  renderActiveAlarmPhasesBands() {
    let bands: Band[] = []

    const pointMachineIndex = this.activeAlarmPhases[0]
    const phases = this.phases[pointMachineIndex]
    let phasesToColor: Phase[] = []

    if (phases?.length) {
      phasesToColor = this.alarmsWithPhaseIds
        ? this.alarmsWithPhaseIds.map(a => phases.find(p => p.alarmPhaseId === a.alarmPhaseId) as Phase)
        : []
      const startTimeOffset = this.getStartTimeOffset(pointMachineIndex)
      bands = mapPhaseBands(phasesToColor, startTimeOffset, this.currentLanguage)
    }

    this.addBandsToSvgContainer(bands, true)
    this.addLabelsToSvgContainer(bands, true)
  }

  renderReferenceCurve() {
    let curves: SwingCurveDataPoint[][] = []

    if (this.activeReferenceCurve !== undefined) {
      const curveData = this.swing?.machineSwings[this.activeReferenceCurve].referenceCurve

      if (curveData) {
        const startTimeOffset = this.swingCurves[this.activeReferenceCurve][0][0]
        const curveTypes: ReferenceCurveType[] = ['measurement'] // , 'measurementMin', 'measurementMax']
        curves = curveTypes
          .filter(ct => curveData.measurements[0][ct] !== null)
          .map(key => mapReferenceCurve(curveData.measurements, startTimeOffset, key))
      }
    }

    this.svgContainer
      .selectAll(classSelector(ElementClass.referenceCurve))
      .data(curves)
      .join('path')
      .lower()
      .classed(ElementClass.referenceCurve, true)
      .attr('fill', 'none')
      .attr('stroke-width', (_d, i) => (i === 0 ? 3.0 : 1.5))
      .attr('stroke', (_d, i) => themeColors.chartColors.referenceCurve[i])
      .style('pointer-events', 'none')
      .attr('clip-path', this.clipUrl)
  }

  render() {
    const swingCurves = this.swingCurves
    const domain = this.getDomain()

    // Reference curve
    this.renderReferenceCurve()

    // Point machine switch swing lines
    this.svgContainer
      .selectAll(classSelector(ElementClass.swingLine))
      .data(swingCurves)
      .join('path')
      .classed(ElementClass.swingLine, true)
      .attr('fill', 'none')
      .attr('stroke-width', 1.5)
      .attr('stroke', (_d, i) => this.getChartLineColorByIndex(i))
      .attr('stroke-dasharray', (_d, i) => (this.isUnselectedSequenceSwing(i) ? '6 4' : ''))
      .style('pointer-events', 'none')
      .attr('clip-path', this.clipUrl)

    this.updatePointMachineVisibility()

    // Phase bands
    if (this.activePhases.length) {
      this.renderPhaseBands()
    }

    // Slip currents
    this.svgContainer
      .selectAll(classSelector(ElementClass.slipCurrent))
      .data(this.slipCurrents)
      .join('path')
      .classed(ElementClass.slipCurrent, true)
      .attr('stroke-width', 1.5)
      .attr('stroke', (_d, i) => this.getChartLineColorByIndex(i))
      .attr('stroke-dasharray', '6 4')
      .style('pointer-events', 'none')
      .attr('clip-path', this.clipUrl)

    // Tooltip
    this.tooltipPoints
      .selectAll(classSelector(ElementClass.tooltipPoint))
      .data(swingCurves)
      .join('circle')
      .classed(ElementClass.tooltipPoint, true)
      .attr('r', TOOLTIP_POINT_RADIUS)

    this.tooltipPointReferenceCurve
      .selectAll(classSelector(ElementClass.tooltipPoint))
      .data([1])
      .join('circle')
      .classed(ElementClass.tooltipPoint, true)
      .attr('r', TOOLTIP_POINT_RADIUS)

    this.tooltipPointSlipCurrent
      .selectAll(classSelector(ElementClass.tooltipPoint))
      .data([1])
      .join('circle')
      .classed(ElementClass.tooltipPoint, true)
      .attr('r', TOOLTIP_POINT_RADIUS)

    this.svgContainer
      .selectAll(classSelector(ElementClass.zeroLine))
      .data(domain.yMin < 0 ? [0] : []) // Only add zero line if the min temp is below zero
      .join('path')
      .classed(ElementClass.zeroLine, true)
      .attr('stroke', themeColors.primary)
      .attr('stroke-width', 1)
      .style('pointer-events', 'none')
      .attr('clip-path', this.clipUrl)

    this.update()
  }
}

const getReferenceCurveValue = (
  swingCurves: SwingCurveDataPoint[][],
  pointMachineSwings: MachineSwing[] | undefined,
  activeReferenceCurve: number,
  step: number,
  curveIndex: number
) => {
  if (!pointMachineSwings) {
    return undefined
  }
  const swingCurve = swingCurves[activeReferenceCurve]
  if (!swingCurve) {
    return undefined
  }
  const startTimeOffset = swingCurve[0]?.[0]
  if (typeof startTimeOffset !== 'number') {
    return undefined
  }
  const measurements = pointMachineSwings?.[activeReferenceCurve].referenceCurve?.measurements
  if (!measurements) {
    return undefined
  }
  const currReferenceCurve = mapReferenceCurve(measurements, startTimeOffset, 'measurement')
  const curveOffset = startTimeOffset / step
  return currReferenceCurve[curveIndex - curveOffset]?.[1]
}
