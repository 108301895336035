import { useMemo } from 'react'

import { StreamContainer } from 'src/Features/SignalEarthFaults/StreamView/StreamContainer'
import { BenderStreamCard } from 'src/Features/SignalEarthFaults/StreamView/BenderStream/BenderStreamCard'
import { BenderSelector } from 'src/Features/SignalEarthFaults/StreamView/BenderStream/BenderSelector'

import type { AssetWithAlarms } from 'src/Types/AssetWithAlarms'
import type { Bender } from 'src/Types/Bender'

import { useTranslation } from 'react-i18next'

type OwnProps = {
  bendersWithAlarms: AssetWithAlarms[]
}

const sortBenders = (benders: Bender[]) =>
  benders.sort((a, b) => {
    if (a.baneDataLocationId === b.baneDataLocationId) {
      return a.name.localeCompare(b.name)
    }

    return a.baneDataLocationName.localeCompare(b.baneDataLocationName)
  })

export const BenderStream = ({ bendersWithAlarms }: OwnProps) => {
  const { t } = useTranslation()

  const benders = bendersWithAlarms.map(r => r.asset) as Bender[]
  const sortedBenders = useMemo(() => sortBenders(benders), [benders])
  const validBenderIds = useMemo(() => sortedBenders.map(bender => bender.baneDataId), [sortedBenders])

  return (
    <StreamContainer
      queryStateKey="benderIds"
      noneFoundText={t('benders.noneFound')}
      validIds={validBenderIds}
      renderSelectorComponent={({ pendingIds, onSelectionUpdated }) => (
        <BenderSelector benders={sortedBenders} pendingBenderIds={pendingIds} onSelectionUpdated={onSelectionUpdated} />
      )}
      renderContentComponent={({ selectedIds, dateRange }) => (
        <BenderStreamCard
          selectedFromDate={dateRange.fromDate}
          selectedToDate={dateRange.toDate}
          selectedBenders={sortedBenders.filter(bender => selectedIds.includes(bender.baneDataId))}
        />
      )}
    />
  )
}
