import { styled } from '@mui/material/styles'
import AlertTitle from '@mui/material/AlertTitle'
import Divider from '@mui/material/Divider'

import { Alert } from 'src/Components/Alert'

import type { AlertSeverity } from 'src/Types/AlertSeverity'
import type { SystemStatusAlert } from 'src/Types/SystemStatusAlert'

import { formatLocalDateTimeShort } from 'src/Utils/format'
import { useLanguage } from 'src/Hooks/useLanguage'
import { extractInfoFromDowntimeText } from 'src/Features/SystemStatusAlerts/extractInfoFromDowntimeText'

const StyledDivider = styled(Divider)`
  margin: ${props => props.theme.spacing(1)} 0;
`

type OwnProps = {
  systemStatusAlert: SystemStatusAlert
  severity: AlertSeverity
  textOnly?: boolean
  divider?: boolean
}

export const AlertInfo = ({ systemStatusAlert, severity, textOnly = false, divider = false }: OwnProps) => {
  const { t, currentLanguage } = useLanguage()

  const fromDate = formatLocalDateTimeShort(systemStatusAlert.fromUtc, currentLanguage, true)
  const toDate = formatLocalDateTimeShort(systemStatusAlert.toUtc, currentLanguage, true)
  const { hidePeriod, textToDisplay } = extractInfoFromDowntimeText(systemStatusAlert)

  const alertText = (
    <>
      {!hidePeriod && (
        <div>
          {fromDate} - {toDate}:
        </div>
      )}
      <div>{textToDisplay}</div>
      <div>
        ({t('general.systemStatusAlert.affectedSystems')} {systemStatusAlert.affectedSystems.join(', ')})
      </div>
    </>
  )

  if (textOnly) {
    return alertText
  }

  return (
    <>
      {divider && <StyledDivider />}
      <Alert severity={severity} variant="standard" margin={0}>
        <AlertTitle>
          <b>{systemStatusAlert.title}</b>
        </AlertTitle>
        {alertText}
      </Alert>
    </>
  )
}
