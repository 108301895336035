import { styled } from '@mui/material/styles'
import { ignoreStyleProps } from 'src/Utils/style'
import themeColors from 'src/theme'

export const StyledPlainContainer = styled(
  'div',
  ignoreStyleProps('isMediaLG')
)<{ isMediaLG: boolean }>(
  props => `
  display: flex;
  margin-left: ${props.isMediaLG ? 4 : 16}px;
`
)

export const StyledPointMachine = styled(
  'div',
  ignoreStyleProps('isMediaLG')
)<{ isMediaLG: boolean }>(
  props => `
  border-right: 1px solid ${themeColors.hr};
  font-size: ${props.isMediaLG ? 10 : 12}px;
  min-height: 45px;
  margin-right: ${props.isMediaLG ? 4 : 10}px;
  padding-right: ${props.isMediaLG ? 4 : 10}px;
  &:last-child {
    border-right: none;
  }
`
)

export const StyledMissingValueText = styled('div')`
  color: ${themeColors.disabledText};
  padding: 3px;
  margin-bottom: 3px;
`

export const StyledLegendButton = styled(
  'button',
  ignoreStyleProps('active')
)<{ active: boolean; disabled?: boolean }>(
  props => `
    display: flex;
    align-items: center;
    border: ${props.active ? '2px' : '1px'} solid ${themeColors.primary};
    border-radius: 25px;
    padding-left: 10px;
    margin-bottom: ${props.active ? '3px' : '4px'};
    margin-top: ${props.active ? '-1px' : 'unset'};
    cursor: pointer;
    font-weight: ${props.active ? 'bold' : 'inherit'};
    text-align: left;
    color: ${themeColors.primary};
    background-color: #fff;
    font-size: 14px;
    width: 184px;
   
    ${props.theme.breakpoints.down('lg')} {
      font-size: 14px;
      width: 150px;
    }
    ${props.theme.breakpoints.down('md')} {
      font-size: 12px;
      width: 120px;
    }
    ${props.theme.breakpoints.down('sm')} {
      font-size: 10px;
      width: 110px;
    }
    ${
      !props.disabled
        ? `
      :hover {
        border: 2px solid ${themeColors.tertiary};
        color: ${themeColors.tertiary};
        margin-bottom: 3px;
        margin-top: -1px;
      }
    `
        : `
      cursor: unset;
    `
    }

    > span {
      display: inline-block;
      flex: 1;
      margin-left: 6px;
    }
  `
)
