import { useRef, useEffect, useState, useMemo } from 'react'
import { styled } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress'

import { Alert } from 'src/Components/Alert'
import { HeatSensorHistoryRenderer } from './heatSensorHistoryRenderer'

import type { ChartLabels } from 'src/Types/ChartTypes'
import type { DatePeriod } from 'src/Types/DatePeriod'
import type { SwitchHeatCabinet } from 'src/Types/SwitchHeatCabinet'
import { useDomainConstantsStore } from 'src/Store/domainConstants'
import { useUserProfileStore } from 'src/Store/userProfile'
import { useChartStyles } from 'src/Components/Chart/chartStyles'
import { useSwitchHeatSensorHistory } from 'src/Hooks/NetworkData/useSwitchHeatSensorHistory'
import { useLanguage } from 'src/Hooks/useLanguage'
import { getDateRange } from 'src/Features/SelectedDatePeriod/predefinedDatePeriods'
import { useSensorHistoryLegends } from 'src/Components/Chart/Legends/useSensorHistoryLegends'
import { ChartLegendsList } from 'src/Components/Chart/Legends/ChartLegendsList'
import { useSwitchHeatVersion } from 'src/Hooks/useSwitchHeatVersion'
import { useChartResizeBehaviour } from 'src/Hooks/useChartResizeBehaviour'

const StyledContainer = styled('div')(
  props => `
  margin: 16px;
  ${props.theme.breakpoints.down('md')} {
    margin: 4px;
  }
  ${props.theme.breakpoints.down('sm')} {
    margin: 2px;
  }
`
)

const StyledChart = styled('div')`
  position: relative;
`

type OwnProps = {
  switchHeatCabinet: SwitchHeatCabinet
  datePeriod: DatePeriod
  hasSetpointSwitchRodValue: boolean
}

const CODE_SYSTEM_KEYS = ['Effort', 'TempLeftTrack', 'TempRightTrack', 'TempOut', 'SetpointTrack']
const SETPOINT_SWTICH_ROD_KEY = 'SetpointSwitchRod'
const NUM_FULL_SCALE_SENSORS = 1
const DATA_POINT_THRESHOLD = 1 * 60 * 60 * 1000 // 1 hour

export const SwitchHeatChart = ({ switchHeatCabinet, datePeriod, hasSetpointSwitchRodValue }: OwnProps) => {
  const { t, currentLanguage } = useLanguage()
  const { classes: chartClasses } = useChartStyles()
  const chartRef = useRef<HTMLDivElement>(null)

  const sensorCodes = useDomainConstantsStore(state => state.sensorCodes.sensorCodes.SVV)
  const hideChartZoomButtons = useUserProfileStore(state => state.settings.settings.hideChartZoomButtons)
  const [renderer, setRenderer] = useState<HeatSensorHistoryRenderer>()
  const [inactiveSensors, setInactiveSensors] = useState<string[]>([])

  const switchHeatVersion = useSwitchHeatVersion()
  const chartCodeSystems = hasSetpointSwitchRodValue ? [...CODE_SYSTEM_KEYS, SETPOINT_SWTICH_ROD_KEY] : CODE_SYSTEM_KEYS
  const { sensorHistory, status } = useSwitchHeatSensorHistory({
    baneDataId: switchHeatCabinet.baneDataId,
    codeSystemKeys: chartCodeSystems,
    datePeriod,
    version: switchHeatVersion,
  })

  const sensorHistoryWithValues = useMemo(() => sensorHistory?.filter(h => h.values?.length), [sensorHistory])
  const sensorHistoryLegends = useSensorHistoryLegends(sensorHistory, inactiveSensors, 'SVV')

  const inactiveIdsUpdated = (inactiveIds: string[]) => {
    setInactiveSensors(inactiveIds)
    if (renderer) {
      renderer.setInactiveSensors(inactiveIds)
    }
  }

  useChartResizeBehaviour(renderer)

  const { fromDate, toDate } = useMemo(() => getDateRange(datePeriod), [datePeriod])

  const effortSensor = sensorHistoryWithValues?.find(sensor => sensor.codeSystem === 'Effort')
  const hasEffortValues = effortSensor?.values && effortSensor?.values.length > 0

  useEffect(() => {
    const chart = chartRef.current
    if (chart && sensorHistoryWithValues) {
      const labels: ChartLabels = {
        zoomIn: t('general.zoomIn'),
        zoomOut: t('general.zoomOut'),
        resetZoom: t('general.resetZoom'),
        leftYAxisLabel: t('switchHeat.chart.leftYAxisLabel'),
        rightYAxisLabel: t('switchHeat.chart.rightYAxisLabel'),
      }

      const seriesSensorCodes = sensorCodes
        .filter(uc => chartCodeSystems.includes(uc.key))
        .sort((a, b) => chartCodeSystems.indexOf(a.key) - chartCodeSystems.indexOf(b.key))

      const chartRenderer = new HeatSensorHistoryRenderer({
        chart,
        timeline: {
          start: fromDate,
          end: toDate,
        },
        numFullScaleSensors: hasEffortValues ? NUM_FULL_SCALE_SENSORS : 0,
        classes: {
          tooltip: chartClasses.tooltip,
          tooltipArrow: chartClasses.tooltipArrow,
          zoomButton: chartClasses.zoomButton,
        },
      })
      setRenderer(chartRenderer)

      chartRenderer.setData(sensorHistoryWithValues, DATA_POINT_THRESHOLD, [
        'Effort',
        'TempLeftTrack',
        'TempRightTrack',
        'TempOut',
      ])
      chartRenderer.setInactiveSensors(inactiveSensors, false)
      chartRenderer.setLabels(labels, seriesSensorCodes, currentLanguage)
      chartRenderer.setShowZoomButtons(!hideChartZoomButtons)
      chartRenderer.render()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensorHistoryWithValues, currentLanguage, chartClasses, fromDate, toDate, t])

  if (status === 'idle') {
    return null
  }

  if (status === 'error') {
    return (
      <Alert severity="error" margin={16}>
        {t('switchHeat.chart.fetchError')}
      </Alert>
    )
  }

  return (
    <>
      {status === 'loading' && (
        <StyledContainer>
          <CircularProgress color="inherit" size={15} /> {t('switchHeat.chart.loading')}
        </StyledContainer>
      )}
      {!sensorHistoryWithValues?.length && status === 'success' && (
        <StyledContainer>{t('switchHeat.chart.noneFound')}</StyledContainer>
      )}
      {!!sensorHistoryWithValues?.length && (
        <StyledContainer>
          <StyledChart ref={chartRef} />
          <ChartLegendsList
            legends={sensorHistoryLegends}
            inactiveIds={inactiveSensors}
            onInactiveIdsUpdated={inactiveIdsUpdated}
            loading={status === 'loading'}
          />
        </StyledContainer>
      )}
    </>
  )
}
