import { getPointMachineZoomLevels } from 'src/Providers/PointMachineZoomLevels'
import { useDomainConstantsStore } from 'src/Store/domainConstants'
import { useQueryWithErrorHandling } from './useQueryWithErrorHandling'
import type { PointMachineZoomLevel } from 'src/Types/SwingTypes'
import { useGenericCacheBehaviour } from 'src/Hooks/useGenericCacheBehaviour'

export const usePointMachineZoomLevelsLoader = () => {
  const setPointMachineZoomLevels = useDomainConstantsStore(state => state.pointMachineZoomLevels.setPointMachineZoomLevels)

  const { getFreshOrCachedValue } = useGenericCacheBehaviour<PointMachineZoomLevel[]>({
    partialKey: 'pointMachineZoomLevels',
    getFreshValue: () => getPointMachineZoomLevels(),
    onValueReceived: pointerMachineZoomLevels => setPointMachineZoomLevels(pointerMachineZoomLevels),
  })

  return useQueryWithErrorHandling(
    ['pointMachineZoomLevels'],
    () => getFreshOrCachedValue(),
    {
      staleTime: Infinity,
    },
    'pointMachineZoomLevels.fetchError'
  )
}
