import { GenericCardListPage } from 'src/Features/GenericCardListPage/GenericCardListPage'
import { SortOrder } from 'src/Types/SortOrder'

import { AppPage } from 'src/Types/AppPage'
import { useHideCriticality } from 'src/Hooks/useHideCriticality'

export const AlarmCardsPage = () => {
  const dynamicSortOrder = useHideCriticality() ? SortOrder.LatestAlarm : SortOrder.Criticality

  return <GenericCardListPage route={AppPage.AlarmCards} initialSortOrder={dynamicSortOrder} />
}
