import type { ReactNode } from 'react'
import { Box, Grid } from '@mui/material'

import { Definition } from 'src/Components/Definition'
import { AlarmTable } from './AlarmTable'
import { StatusWindowTable } from './StatusWindowTable'

import type { AssetWithAlarms } from 'src/Types/AssetWithAlarms'
import type { AssetStatusWindow, WindowStatus } from 'src/Types/AssetTypes'
import { WindowStatusValue, AssetType } from 'src/Types/AssetTypes'

import { getAlarms, getAlarmTypeOccurrences } from 'src/Utils/alarms'
import { useLanguage } from 'src/Hooks/useLanguage'
import { useBreakpointDown } from 'src/Hooks/useBreakpoint'
import { SubHeaderKey } from 'src/Providers/Settings/SubHeader'

import themeColors from 'src/theme'
import { useHideCriticality } from 'src/Hooks/useHideCriticality'
import { AlarmClassificationValue } from 'src/Types/AlarmClassification'
import { getRelevantWindows, statusIsVirtuallyNoContact } from 'src/Utils/asset'
import { Alert } from 'src/Components/Alert'

type OwnProps = {
  assetWithAlarms: AssetWithAlarms
  showAlarmsInAssetStatusWindows: boolean
  children: ReactNode
}

const getRelevantWindowStatuses = (
  assetStatusWindows: AssetStatusWindow[] | undefined,
  noContactWindows: AssetStatusWindow[]
): WindowStatus | 'Both' | undefined => {
  const irregularDataWindows = assetStatusWindows?.filter(w => w.status === 'IrregularData' && w.startTime < Date.now())

  if (irregularDataWindows?.length && noContactWindows.length) {
    return 'Both'
  }
  if (irregularDataWindows?.length) {
    return 'IrregularData'
  }
  if (noContactWindows.length) {
    return 'NoContact'
  }
  return undefined
}

export const DetailedAlarms = ({ assetWithAlarms, showAlarmsInAssetStatusWindows, children }: OwnProps) => {
  const { t, currentLanguage } = useLanguage()
  const isMediaSM = useBreakpointDown('sm')

  const { alarms: assetAlarms, asset } = assetWithAlarms

  const componentCriticality = asset.componentCriticalityLocal
  const totalCriticality = asset.totalCriticalityAbsolute
  const alarmCriticality = asset.alarmCriticalityAbsolute

  const alarmTypeOccurrences = getAlarmTypeOccurrences(assetAlarms)

  const alarms = getAlarms(assetAlarms, asset, AlarmClassificationValue.Alarm)
  const warnings = getAlarms(assetAlarms, asset, AlarmClassificationValue.Warning)
  const relevantWindows = getRelevantWindows(asset)

  const hasCriticalities = totalCriticality !== 0 && componentCriticality !== 0 && alarmCriticality !== 0

  const hideCriticality = useHideCriticality()

  const noContactWindows = relevantWindows.filter(window => statusIsVirtuallyNoContact(window.status))
  const irregularDataWindows = relevantWindows.filter(window => window.status === WindowStatusValue.IrregularData)

  const relevantWindowStatuses = getRelevantWindowStatuses(asset.assetStatusWindows, noContactWindows)

  return (
    <>
      <Box mt={2} mb={2} ml={isMediaSM ? 1 : 0}>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
          <Grid item xs={12} sm={12} lg={hasCriticalities ? 3 : 12}>
            <Definition term={t('general.baneDataId')} description={asset.baneDataId} />
          </Grid>
          {(asset.assetType === AssetType.SwitchMachineHeat || asset.assetType === AssetType.SwitchPointMachine) &&
            asset.switchType && (
              <Grid item xs={12} sm={12} lg={hasCriticalities ? 9 : 12}>
                <Definition
                  term={t('switches.statusLabels.maintenance.switchType')}
                  description={asset.switchType?.[currentLanguage]}
                />
              </Grid>
            )}
          {!hideCriticality && (
            <>
              {componentCriticality !== 0 && (
                <Grid item xs={12} sm={4} lg={3}>
                  <Definition term={t('general.componentCriticality')} description={componentCriticality} />
                </Grid>
              )}
              {alarmCriticality !== 0 && (
                <Grid item xs={12} sm={4} lg={3}>
                  <Definition term={t('general.alarmCriticality')} description={alarmCriticality} />
                </Grid>
              )}
              {totalCriticality !== 0 && (
                <Grid item xs={12} sm={4} lg="auto">
                  <Definition term={t('general.totalCriticality')} description={totalCriticality} />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Box>
      <div>
        {noContactWindows.length > 0 && (
          <Box mb={2}>
            <Alert severity="info" context={WindowStatusValue.NoContact} variant="standard">
              {t('switches.noContact')}
            </Alert>
          </Box>
        )}
        {irregularDataWindows.length > 0 && (
          <Box mb={2}>
            <Alert severity="info" context={WindowStatusValue.IrregularData} variant="standard">
              {t('switches.irregularData')}
            </Alert>
          </Box>
        )}
        {noContactWindows.length > 0 && (
          <StatusWindowTable
            windows={noContactWindows}
            status={WindowStatusValue.NoContact}
            chipColor={themeColors.noContact}
            subHeaderKey={SubHeaderKey.NoContactTable}
            i18nAssetStatusClassification={t('general.noContactPeriod')}
          />
        )}
        {irregularDataWindows.length > 0 && (
          <StatusWindowTable
            windows={irregularDataWindows}
            status={WindowStatusValue.IrregularData}
            chipColor={themeColors.irregularData}
            subHeaderKey={SubHeaderKey.IrregularDataTable}
            i18nAssetStatusClassification={t('statusWindow.irregularData')}
          />
        )}
        {alarms.length > 0 && (
          <AlarmTable
            alarms={alarms}
            asset={asset}
            occurrences={alarmTypeOccurrences.Alarm}
            chipColor={themeColors.error}
            showAlarmsInAssetStatusWindows={showAlarmsInAssetStatusWindows}
            relevantWindowStatuses={relevantWindowStatuses}
            expandFirstAlarmChart
            subHeaderKey={SubHeaderKey.AlarmTable}
          />
        )}
        {warnings.length > 0 && (
          <AlarmTable
            alarms={warnings}
            asset={asset}
            occurrences={alarmTypeOccurrences.Warning}
            chipColor={themeColors.warning}
            showAlarmsInAssetStatusWindows={showAlarmsInAssetStatusWindows}
            relevantWindowStatuses={relevantWindowStatuses}
            expandFirstAlarmChart={alarms.length === 0}
            subHeaderKey={SubHeaderKey.WarningTable}
          />
        )}
      </div>
      <Box mt={2}>{children}</Box>
    </>
  )
}
