import { useState, useEffect } from 'react'

// Forked from
// https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/useScrollTrigger/useScrollTrigger.js

type UseScrollTriggerOptions = {
  hideThreshold?: number
  revealThreshold?: number
}

type Direction = -1 | 0 | 1

type TriggerState = {
  direction: Direction
  initialValue: number
  current?: number
  hide: boolean
}

const initialState: TriggerState = {
  direction: 0,
  initialValue: 0,
  current: undefined,
  hide: false,
}

const getTrigger = (state: TriggerState, target: Window, options: UseScrollTriggerOptions) => {
  const { hideThreshold = 100, revealThreshold = 0 } = options
  const previous = state.current || target.scrollY
  const current = target.scrollY
  const direction = Math.sign(current - previous) as Direction
  const initial = direction !== state.direction ? current : state.initialValue

  let hide = state.hide
  if (direction === 1 && current > hideThreshold) {
    hide = true
  } else if (current < initial - revealThreshold || current <= 0) {
    hide = false
  }

  const newState: TriggerState = {
    direction,
    initialValue: initial,
    current,
    hide,
  }

  return newState
}

const target = window

/**
 * Creates a scroll trigger returning whether an element should be hidden (true) or visible (false).
 *
 * @param options.hideThreshold - Pixels from top to allow scrolling before hiding element.
 * @param options.revealThreshold - Pixels to allow scrolling up after direction changed before showing element.
 */
export const useScrollTrigger = (options: UseScrollTriggerOptions = {}) => {
  const [state, setState] = useState(() => getTrigger(initialState, target, options))

  useEffect(() => {
    const handleScroll = () => setState(state => getTrigger(state, target, options))

    handleScroll()
    target.addEventListener('scroll', handleScroll)

    return () => {
      target.removeEventListener('scroll', handleScroll)
    }
  }, [JSON.stringify(options)]) // eslint-disable-line react-hooks/exhaustive-deps

  return state.hide
}
