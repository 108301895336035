import type { MouseEvent, ReactNode } from 'react'
import { MenuItem as MaterialMenuItem, styled } from '@mui/material'

const StyledMaterialMenuItem = styled(MaterialMenuItem)`
  display: flex;
  gap: 0.5em;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')} !important;
`

type OwnProps = {
  children?: ReactNode
  onClick?: (event: MouseEvent) => void
  disabled?: boolean
  title?: string
}

export const MenuItem = ({ children, onClick, disabled, title }: OwnProps) => {
  return (
    <StyledMaterialMenuItem autoFocus onClick={disabled ? undefined : onClick} disabled={disabled} title={title}>
      {children}
    </StyledMaterialMenuItem>
  )
}

export default MenuItem
