import { getSettings } from 'src/Providers/Settings/UserSettings'
import { useUserProfileStore } from 'src/Store/userProfile'
import { useQueryWithErrorHandling } from './useQueryWithErrorHandling'

export const useRemoteUserSettings = () =>
  useQueryWithErrorHandling(
    ['userSettings'],
    () =>
      getSettings().then(({ userSettings, favorites, openedAlarms, defaultExpanded }) => {
        const state = useUserProfileStore.getState()
        state.setInitialSettings(userSettings)
        state.setInitialFavorites(favorites)
        state.setInitialOpenedAlarms(openedAlarms)
        state.setInitialSubHeaderDefaultExpanded(defaultExpanded)

        return {
          userSettings,
          favorites,
          openedAlarms,
          defaultExpanded,
        }
      }),
    {
      staleTime: Infinity,
    },
    'userSettings.fetchError'
  )
