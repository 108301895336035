import { DatePeriodParam, QueryStringStateKeys } from 'src/Store/useQueryStringState'

export const getInitialSearchParams = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const stretchLocations = urlParams.get(QueryStringStateKeys.stretchLocations)
  const datePeriod = urlParams.get(QueryStringStateKeys.datePeriod)

  const initialDatePeriod = datePeriod ? DatePeriodParam.decode(datePeriod) : undefined
  const initialStretchLocations = stretchLocations ? stretchLocations.split(',') : undefined

  return {
    initialStretchLocations,
    initialDatePeriod,
  }
}
