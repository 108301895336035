import type { Size } from 'src/Types/Geometry'

enum WindBarbType {
  fifty = 50,
  ten = 10,
  five = 5,
}

export const WIND_ICON_SIZE: Size = {
  width: 24,
  height: 24,
}

const WIND_BARB_SLANT = 3
const WIND_BARB_SPACING = 2.5
const WIND_ARROW_HEIGHT = WIND_ICON_SIZE.height - WIND_BARB_SLANT
const WIND_ARROW_POINT_WIDTH = 1.5
const WIND_ARROW_BARB_WIDTH = 7
const METERS_PER_SECOND_TO_KNOTS = 1.9438

export const getWindBarbs = (windSpeed: number) => {
  const barbs: number[] = []
  const barbTypes = [WindBarbType.fifty, WindBarbType.ten, WindBarbType.five]
  let restKnots = windSpeed * METERS_PER_SECOND_TO_KNOTS
  barbTypes.forEach(val => {
    const num = Math.floor(restKnots / val)
    for (let i = 0; i < num; i++) {
      barbs.push(val)
    }
    restKnots -= num * val
  })
  return barbs
}

export const createWindIcon = (windSpeed: number) => {
  // The base arrow, pointing straight south (wind direction 0 - from the north)
  const path = [
    `M${WIND_ICON_SIZE.width / 2},${WIND_ARROW_HEIGHT - WIND_ARROW_POINT_WIDTH}`,
    `h${-WIND_ARROW_POINT_WIDTH}`,
    `l${WIND_ARROW_POINT_WIDTH},${WIND_ARROW_POINT_WIDTH}`,
    `l${WIND_ARROW_POINT_WIDTH},${-WIND_ARROW_POINT_WIDTH}`,
    `h${-WIND_ARROW_POINT_WIDTH}`,
    `V${WIND_BARB_SLANT + 1}`,
  ]

  const barbs = getWindBarbs(windSpeed)
  barbs.forEach(barb => {
    if (barb === WindBarbType.fifty) {
      path.push(
        `l${WIND_ARROW_BARB_WIDTH - 1},${WIND_BARB_SLANT}`,
        `l${-WIND_ARROW_BARB_WIDTH + 1},${WIND_BARB_SLANT}`,
        `v${WIND_BARB_SPACING}`
      )
    } else {
      const modifier = barb === WindBarbType.ten ? 1 : 0.5
      const length = WIND_ARROW_BARB_WIDTH * modifier
      const height = WIND_BARB_SLANT * modifier

      path.push(`l${length},${-height}`, `l${-length},${height}`, `v${WIND_BARB_SPACING}`)
    }
  })

  return path.join(' ')
}
