import { useEffect } from 'react'
import type { QueryStatus } from 'react-query'
import { useQueryStringState, QueryStringStateKeys } from 'src/Store/useQueryStringState'
import { useDeepRailways } from 'src/Hooks/NetworkData/useDeepRailways'
import { getAppInsights } from 'src/telemetryService'
import { useLocation } from 'react-router-dom'

type TrackSearchEventParams = {
  status: QueryStatus
  resultCount: number
}

type RailwayResult = {
  railway: string
  locations: string[]
}

export const useTrackSearchEvent = ({ status, resultCount }: TrackSearchEventParams) => {
  const [stretchLocations] = useQueryStringState(QueryStringStateKeys.stretchLocations)
  const { data: railways } = useDeepRailways()
  const { pathname } = useLocation()

  useEffect(() => {
    if (status !== 'success') {
      return
    }

    const railwayResults: RailwayResult[] = []
    railways?.forEach(railway => {
      const locationNames = railway.railwaySections
        .flatMap(section => section.locations)
        .filter(location => stretchLocations.includes(location.locationId))
        .map(location => location.name)

      if (locationNames.length) {
        railwayResults.push({
          railway: railway.name,
          locations: locationNames,
        })
      }
    })

    if (!railwayResults.length) {
      return
    }

    const customProperties = {
      railways: railwayResults,
      resultCount,
      pageType: pathname,
    }

    getAppInsights()?.trackEvent({ name: 'app-search-success' }, customProperties)
  }, [status, stretchLocations, railways, resultCount, pathname])
}
