import type { Configuration } from '@azure/msal-browser'

import { ApiType } from 'src/Types/ApiType'

import type { Config } from './Config'

const MSAL_CONFIG: Configuration = {
  auth: {
    clientId: 'c801dfb9-0990-4bca-a098-896335b6f8f1', // bn-m40-track-monitoring-frontend-appreg-dev
    authority: 'https://login.microsoftonline.com/6ee535f2-3064-4ac9-81d8-4ceb2ff790c6',
    redirectUri: 'https://bn-m40-track-monitoring-frontend-dev.azurewebsites.net',
  },
  cache: {
    cacheLocation: 'memoryStorage',
    storeAuthStateInCookie: true, // Set this to 'true' if you are having issues on IE11 or Edge
  },
}

const config: Partial<Config> = {
  MSAL_CONFIG,
  API: {
    [ApiType.APIM]: {
      tokenRequest: {
        scopes: ['api://2a6f92b1-04d5-4932-ba9a-59a16fc0387d/frontend'],
      },
      endpoint: 'https://bn-m40-api-gateway-frontend-dev.azure-api.net/',
    },
  },
  TELEMETRY_KEY: '1c569149-b343-47c8-8f0a-f4f233fac686',
  FEATURES: {
    MANUAL_ASSET_STATUS: true,
    EXGEN_UPDATE: true,
  },
}

export default config
