import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'

import { Checkbox } from 'src/Components/Checkbox'
import { GroupedDropdown } from 'src/Components/GroupedDropdown'
import { AlarmIcon } from 'src/Components/AlarmIcon'

import { MonitoringSystem } from 'src/Types/MonitoringSystem'
import type { SystemStatus } from 'src/Types/SystemStatus'
import type { Filter, AlarmTypeIdFilter } from 'src/Types/Filter'
import { FilterType } from 'src/Types/Filter'
import type { AlarmType } from 'src/Types/AlarmType'

import { useDomainConstantsStore } from 'src/Store/domainConstants'
import { getAlarmTypeUniqueId } from 'src/Utils/alarms'
import { useBreakpointDown } from 'src/Hooks/useBreakpoint'
import { useLanguage } from 'src/Hooks/useLanguage'
import { AssetType } from 'src/Types/AssetTypes'
import { SessionFeatures } from 'src/Hooks/useSessionFeature'
import { useFeatureOverride } from 'src/Hooks/useFeatureOverride'

type OwnProps = {
  filter?: AlarmTypeIdFilter
  onFilterUpdated: (filter: Filter) => void
  assetTypeSystems?: string[]
  status?: SystemStatus[]
  includeFilter?: (filter: AlarmType) => boolean
}

const MAPPED_MONITORING_SYSTEM_BY_ASSET_TYPE = [
  {
    assetType: AssetType.TrackCircuit,
    monitoringSystem: MonitoringSystem.TrackCircuit,
  },
  {
    assetType: AssetType.SwitchPointMachine,
    monitoringSystem: MonitoringSystem.PointMachine,
  },
  {
    assetType: AssetType.SwitchMachineHeat,
    monitoringSystem: MonitoringSystem.MachineHeat,
  },
  {
    assetType: AssetType.SwitchHeat,
    monitoringSystem: MonitoringSystem.SwitchHeat,
  },
  {
    assetType: AssetType.Bender,
    monitoringSystem: MonitoringSystem.SignalEarthFault,
  },
  /* TODO: #15412 Add correct alarmtype-monitoringsystem for racks, when racks gets alarms: {
    assetType: AssetType.Rack,
    monitoringSystem: MonitoringSystem.Rack,
  }, */
]

const ALL_SYSTEMS = [
  AssetType.TrackCircuit,
  AssetType.SwitchPointMachine,
  AssetType.SwitchMachineHeat,
  AssetType.SwitchHeat,
  AssetType.Bender,
  AssetType.Rack,
]
const ALARM_STATUSES: SystemStatus[] = ['Alarm', 'Warning']

export const AlarmTypeIdFilterSelector = ({
  onFilterUpdated,
  filter,
  assetTypeSystems = ALL_SYSTEMS,
  status = ALARM_STATUSES,
  includeFilter,
}: OwnProps) => {
  const { t, currentLanguage } = useLanguage()
  const isMediaSM = useBreakpointDown('sm')
  const descriptionLength = isMediaSM ? 'short' : 'full'

  const enableAllAlarmTypes = useFeatureOverride(SessionFeatures.EnableAllAlarmTypes)

  const monitoringSystems = assetTypeSystems.map(
    assetTypeSystem =>
      MAPPED_MONITORING_SYSTEM_BY_ASSET_TYPE.find(system => system.assetType === assetTypeSystem)?.monitoringSystem
  )

  const monitoringSystemOptions = MAPPED_MONITORING_SYSTEM_BY_ASSET_TYPE.filter(s => {
    return assetTypeSystems.includes(s.assetType)
  }).map(s => ({
    id: s.monitoringSystem,
    name: t(`sideMenu.system.${s.assetType.toLowerCase()}`),
  }))

  const alarmTypes = useDomainConstantsStore(state => state.alarmTypes.alarms)
    .filter(a => monitoringSystems.includes(a.type.system))
    .filter(a => status.includes(a.classification.id))

  const alarmTypeOptionsBySystem = (monitoringSystem: MonitoringSystem) =>
    alarmTypes
      .filter(a => monitoringSystem === a.type.system)
      .sort((a, b) =>
        a.classification.id.toString() + a.type.localization.short[currentLanguage] >
        b.classification.id.toString() + b.type.localization.short[currentLanguage]
          ? 1
          : -1
      )
      .filter(at => {
        if (enableAllAlarmTypes) {
          if (!at.type.showInFilter) {
            console.log('Feature-toggle-enabled alarm type normally not enabled: ', at.type.localization.full.no)
          }
          return true
        }
        return at.type.showInFilter
      })
      .filter(at => (includeFilter ? includeFilter(at) : true))
      .map(at => ({
        uniqueId: getAlarmTypeUniqueId(at),
        label: at.type.localization[descriptionLength][currentLanguage],
        classification: at.classification,
      }))

  const selectedTypeIds = filter?.values || []

  const typeIdsUpdated = (selected: string[]) =>
    onFilterUpdated({
      type: FilterType.AlarmTypeId,
      values: selected,
    })

  const getAlarmTypeName = (uniqueId: string) => {
    const alarmType = alarmTypes.find(at => getAlarmTypeUniqueId(at) === uniqueId)
    return alarmType ? alarmType.type.localization.short[currentLanguage] : t('alarms.unknownTypeNoId')
  }

  const renderSelected = (selected: string[], isEveryValueSelected: boolean) =>
    isEveryValueSelected ? t('general.allSelected') : selected.map(getAlarmTypeName).join(', ')

  return (
    <GroupedDropdown
      id="alarm-type-id"
      label={t('sideMenu.filterLabel.alarmType')}
      groups={monitoringSystemOptions}
      values={alarmTypes}
      selectedValues={selectedTypeIds}
      idForValue={getAlarmTypeUniqueId}
      allowSelectAll={alarmTypes.length > 0}
      noValuesMessage={t('sideMenu.noFilteredAlarmTypeMatches')}
      entriesForGroupId={systemId =>
        alarmTypeOptionsBySystem(systemId as MonitoringSystem).map(option => (
          <MenuItem
            key={option.uniqueId}
            value={option.uniqueId}
            title={`${option.classification.localization.full[currentLanguage]} - ${option.label}`}
          >
            <Checkbox checked={selectedTypeIds.includes(option.uniqueId)} />
            <AlarmIcon
              classification={option.classification.id}
              size="small"
              aria-label={option.classification.localization.full[currentLanguage]}
            />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))
      }
      onUpdate={typeIdsUpdated}
      renderSelected={renderSelected}
    />
  )
}
