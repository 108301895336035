import type { TrackCircuitCurrentMeasurement, TrackCircuitCurrentsData } from 'src/Types/TrackCircuitCurrentTypes'
import type { TimeSpan } from 'src/Types/LevelOfDetails'
import { cropTimeSpan } from 'src/Utils/LevelOfDetails/timeSpan'
import { getMax } from 'src/Utils/array'

const stitchTrackCircuitCurrentMeasurements = (
  source: TrackCircuitCurrentMeasurement[] = [],
  additions: TrackCircuitCurrentMeasurement[] = []
) => {
  const maxSourceTimestamp = getMax(source.map(m => m[0]))
  return [...source, ...additions.filter(m => m[0] > maxSourceTimestamp)]
}

export const stitchMissingMeasurements = (source: TimeSpan[] = [], additions: TimeSpan[] = []) => {
  const maxSourceTimestamp = getMax(source.map(([, max]) => max))
  const adjustedAdditions = additions
    .map(([min, max]) => [Math.max(min, maxSourceTimestamp), Math.max(max, maxSourceTimestamp)] as TimeSpan)
    .filter(([min, max]) => min < max)
  return [...source, ...adjustedAdditions]
}

const cropTimeSpans = (timeSpans: TimeSpan[], limit: TimeSpan) =>
  timeSpans.reduce<TimeSpan[]>((agg, timeSpan) => {
    const cropped = cropTimeSpan(timeSpan, limit)
    if (cropped) {
      agg.push(cropped)
    }
    return agg
  }, [])

/**
 * Stitch together track-circuit currents time series.
 */
export const stitchTrackCircuitCurrents = (
  source: TrackCircuitCurrentsData[] = [],
  additions: TrackCircuitCurrentsData[] = []
): TrackCircuitCurrentsData[] =>
  source.map(sourceCurrent => {
    const additionCurrent = additions.find(s => s.trackCircuitBaneDataId === sourceCurrent.trackCircuitBaneDataId)

    if (!additionCurrent) {
      return sourceCurrent
    }

    const fcMeasurements = stitchTrackCircuitCurrentMeasurements(sourceCurrent.fcMeasurements, additionCurrent.fcMeasurements)
    const rcMeasurements = stitchTrackCircuitCurrentMeasurements(sourceCurrent.rcMeasurements, additionCurrent.rcMeasurements)
    const maxFcMeasurementsDate = getMax(sourceCurrent.fcMeasurements.map(m => m[0]))
    const maxRcMeasurementsDate = getMax(sourceCurrent.rcMeasurements.map(m => m[0]))
    const missingFcMeasurements = stitchMissingMeasurements(
      cropTimeSpans(sourceCurrent.missingFcMeasurements || [], [0, maxFcMeasurementsDate]),
      cropTimeSpans(additionCurrent.missingFcMeasurements || [], [maxFcMeasurementsDate, Infinity])
    )
    const missingRcMeasurements = stitchMissingMeasurements(
      cropTimeSpans(sourceCurrent.missingRcMeasurements || [], [0, maxRcMeasurementsDate]),
      cropTimeSpans(additionCurrent.missingRcMeasurements || [], [maxRcMeasurementsDate, Infinity])
    )

    return {
      ...sourceCurrent,
      fcMeasurements,
      rcMeasurements,
      missingFcMeasurements,
      missingRcMeasurements,
    }
  })
