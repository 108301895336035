import type { ReactNode } from 'react'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { makeStyles } from 'tss-react/mui'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'

import { ExpandCollapseButton } from 'src/Components/ExpandCollapseButton'
import { AssetCardMainContent } from 'src/Components/AssetCard/AssetCardMainContent'

import type { AssetWithAlarms } from 'src/Types/AssetWithAlarms'

import { useOpenedAlarm } from 'src/Hooks/useOpenedAlarm'
import { useCardIdFromUrl } from 'src/Hooks/useCardIdFromUrl'

import { getCardIdByAsset } from 'src/Utils/cardId'

import themeColors from 'src/theme'
import { WorkOrders } from 'src/Features/WorkOrders/WorkOrders'

const useStyles = makeStyles()(theme => ({
  card: {
    backgroundColor: '#fff',
    color: themeColors.text,
    borderRadius: 0,
    padding: 5,
    margin: 16,
    [theme.breakpoints.down('sm')]: {
      margin: '8px 4px',
    },
  },
  disabledCard: {
    border: `5px solid ${themeColors.disabledLight}`,
    backgroundColor: themeColors.disabledLight,
  },
  disabledButton: {
    color: themeColors.disabled,
  },
  expandedCard: {
    border: `5px solid ${theme.palette.primary.main}`,
    padding: 0,
  },
  unopenedAlarmCard: {
    borderLeft: `5px solid ${theme.palette.secondary.main}`,
  },
  cardContent: {
    paddingBottom: 0,
  },
  expanded: {
    [theme.breakpoints.down('sm')]: {
      padding: '8px 0',
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
  },
}))

type OwnProps = {
  assetWithAlarms: AssetWithAlarms
  expandedContent: ReactNode
  defaultExpanded?: boolean
  forceToggleExpanded?: boolean
  onExpandedStateChange?: (cardId: string, expanded: boolean) => void
}

export const ExpandableAlarmCard = ({
  assetWithAlarms,
  expandedContent,
  defaultExpanded = false,
  forceToggleExpanded,
  onExpandedStateChange,
}: OwnProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const [expanded, setExpanded] = useState(defaultExpanded)
  const { isOpenedAlarm, addOpenedAlarm, addPendingOpenedAlarm } = useOpenedAlarm(assetWithAlarms)
  const cardIdFromUrl = useCardIdFromUrl()
  const cardId = getCardIdByAsset(assetWithAlarms.asset)

  useEffect(() => {
    if (typeof forceToggleExpanded === 'boolean') {
      setExpanded(forceToggleExpanded)
    }
  }, [forceToggleExpanded])

  const toggleExpand = () => {
    if (!isOpenedAlarm) {
      if (!expanded) {
        addPendingOpenedAlarm()
      } else {
        addOpenedAlarm()
      }
    }
    setExpanded(isExpanded => !isExpanded)
    onExpandedStateChange?.(cardId, !expanded)
  }

  useEffect(() => {
    if (!expanded && cardId === cardIdFromUrl) {
      toggleExpand()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardId, cardIdFromUrl])

  const cardActions = (
    <CardActions className={classes.actions}>
      <ExpandCollapseButton
        isExpanded={expanded}
        onToggleExpand={toggleExpand}
        text={expanded ? t('general.hideCard') : undefined}
        largeIcon
        disabled={!assetWithAlarms.asset.isMonitored}
      />
    </CardActions>
  )

  return (
    <Card
      className={clsx(classes.card, {
        [classes.disabledCard]: !assetWithAlarms.asset.isMonitored,
        [classes.expandedCard]: expanded,
        [classes.unopenedAlarmCard]: !isOpenedAlarm && !expanded,
      })}
    >
      <CardContent className={classes.cardContent}>
        <AssetCardMainContent assetWithAlarms={assetWithAlarms} />
      </CardContent>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent className={classes.expanded}>
          {cardActions}
          <Divider />
          {expandedContent}
          <WorkOrders banedataId={assetWithAlarms.asset.baneDataId} />
        </CardContent>
      </Collapse>
      {cardActions}
    </Card>
  )
}
