import type { SensorCode, SensorCodeTab } from 'src/Types/SensorCode'
import type { Language } from 'src/Types/LanguageTypes'

export type TabModel = {
  sortOrder: number
  label: string
  columns: TabModelColumn[]
}

export type TabModelColumn = {
  index: number
  codeSystemKeys: string[]
}

const getTabs = (sortedSensorTwins: SensorCode[]) => {
  const tabs: Record<number, SensorCodeTab> = {}
  sortedSensorTwins.forEach(sensorCode => {
    const tab = sensorCode.tab
    if (tab) {
      tabs[tab.id] = tab
    }
  })
  return Object.values(tabs).sort((a, b) => a.sortOrder - b.sortOrder)
}

type TabWithColumnsAndSensorCodes = { tab: SensorCodeTab; columns: Record<number, SensorCode[]> }

export const getTabsWithColumnsAndSensorCodes = (sensorCodes: SensorCode[]): TabWithColumnsAndSensorCodes[] => {
  const sortedSensorTwins = [...sensorCodes].sort((a, b) => a.sortOrder - b.sortOrder)
  const tabs = getTabs(sortedSensorTwins)
  return tabs.map(tab => {
    const columns: Record<number, SensorCode[]> = {}
    const sensorCodesInTab = sortedSensorTwins.filter(sensorCode => sensorCode.tab?.id === tab.id)
    sensorCodesInTab.forEach(sensorCode => {
      const column = Math.floor(sensorCode.sortOrder / 100)
      columns[column] = columns[column] || []
      columns[column].push(sensorCode)
    })
    return { tab, columns }
  })
}

export const mapToTabsModel = (
  tabsWithColumnsAndSensorCodes: TabWithColumnsAndSensorCodes[],
  currentLanguage: Language
): TabModel[] => {
  const result: TabModel[] = []
  tabsWithColumnsAndSensorCodes.forEach(({ tab, columns }) => {
    const label = tab.localization.full[currentLanguage]
    const sortOrder = tab.sortOrder
    const tabColumns: TabModelColumn[] = []
    Object.entries(columns).forEach(([index, sensorCodes]) => {
      tabColumns.push({
        index: Number(index),
        codeSystemKeys: sensorCodes.map(sensorCode => sensorCode.key),
      })
    })
    if (tabColumns.length > 0) {
      result.push({
        sortOrder,
        label,
        columns: tabColumns,
      })
    }
  })
  return result
}

export const getTabsModel = (sensorCodes: SensorCode[], currentLanguage: Language) => {
  const tabsWithColumnsAndSensorCodes = getTabsWithColumnsAndSensorCodes(sensorCodes)
  return mapToTabsModel(tabsWithColumnsAndSensorCodes, currentLanguage)
}
