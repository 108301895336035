import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import { format } from 'date-fns'
import { isToday } from 'date-fns/isToday'

import { AlarmIndicator } from 'src/Components/AlarmIndicator'

import type { DayWithAlarmStatus } from 'src/Types/AlarmType'

import { formatLocalDateTime } from 'src/Utils/format'

const DATE_FORMAT = 'dd.MM'

const StyledContainer = styled('div')`
  display: flex;
  flex-direction: row;
`

const StyledAlarmIndicator = styled('div')`
  width: 16px;
  position: relative;
  margin-bottom: 1em;

  &:first-of-type > div:last-of-type {
    left: 0;
  }
  &:last-of-type > div:last-of-type {
    right: 0;
  }
`

const StyledText = styled('div')(
  props => `
  position: absolute;
  font-size: 14px;
  display: block;
  white-space: nowrap;

  ${props.theme.breakpoints.down('lg')} {
    font-size: 12px;
  }
`
)

type OwnProps = {
  daysWithAlarmStatus: DayWithAlarmStatus[]
}

export const SevenDayAlarmIndicator = ({ daysWithAlarmStatus }: OwnProps) => {
  const { t } = useTranslation()

  const shouldShowAlarmDate = (index: number) => index === 0 || index === daysWithAlarmStatus.length - 1

  const getIndicatorTitle = (dayWithStatus: DayWithAlarmStatus) => {
    const date = formatLocalDateTime(dayWithStatus.date, true)
    const text =
      isToday(dayWithStatus.date) && dayWithStatus.status === 'Ok'
        ? t('alarms.alarmIndicator.OkToday')
        : t(`alarms.alarmIndicator.${dayWithStatus.status}`)
    return `${date} - ${text}`
  }

  return (
    <StyledContainer>
      {daysWithAlarmStatus.map((dayWithStatus, index) => {
        const okToday = isToday(dayWithStatus.date) && dayWithStatus.status === 'Ok'
        const title = getIndicatorTitle(dayWithStatus)

        return (
          <StyledAlarmIndicator key={dayWithStatus.date.valueOf()} aria-label={title}>
            <AlarmIndicator alarmIndicator={okToday ? 'OkToday' : dayWithStatus.status} size="xsmall" title={title} />
            {shouldShowAlarmDate(index) ? <StyledText>{format(dayWithStatus.date, DATE_FORMAT)}</StyledText> : null}
          </StyledAlarmIndicator>
        )
      })}
    </StyledContainer>
  )
}
