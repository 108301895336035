import type { ReactNode } from 'react'
import { useState, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import ListItemText from '@mui/material/ListItemText'
import List from '@mui/material/List'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreOutlined'
import ExpandLessIcon from '@mui/icons-material/ExpandLessOutlined'
import ClearIcon from '@mui/icons-material/ClearOutlined'
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNewOutlined'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

import type { RailwayModel } from './types'

import { StyledLocationListItem, StyledLocationListItemButton, StyledLocationTitle } from './SelectorComponents'
import { useAppStateStore } from 'src/Store/appState'
import { Checkbox } from 'src/Components/Checkbox'
import { useLocationSelectorToggles } from 'src/Components/Header/Locations/useLocationSelectorToggles'

const StyledRegion = styled(ListItemText)`
  min-width: 250px;
`

const StyledBackIcon = styled(ArrowBackIcon)`
  margin-left: -10px;
`

type OwnProps = {
  railways: RailwayModel[]
  renderRailwaySections?: (railway: RailwayModel) => ReactNode
  isTextSearchMode?: boolean
  onListItemClicked?: (railway: RailwayModel) => void
}

export const RailwaySelector = ({ railways, renderRailwaySections, isTextSearchMode = false, onListItemClicked }: OwnProps) => {
  const { t } = useTranslation()

  const openRailwayId = useAppStateStore(state => state.locations.openRailwayId)
  const setOpenRailwayId = useAppStateStore(state => state.locations.setOpenedRailwayId)

  const { toggleRailway } = useLocationSelectorToggles()

  const searchFilteredRailways = railways.filter(railway => railway.searchTreeMatch)
  const selectedOrPendingRailways = searchFilteredRailways.filter(railway => railway.isPending || railway.isOpened)

  const [expanded, setExpanded] = useState(!openRailwayId)
  const actualExpanded = expanded || isTextSearchMode

  const resetRailwaySelection = (railway: RailwayModel) => {
    if (!expanded) {
      if (railway.railwayId === openRailwayId) {
        const otherExpanded = selectedOrPendingRailways.find(r => r.railwayId !== railway.railwayId)
        setOpenRailwayId(otherExpanded?.railwayId)
      }
      if (selectedOrPendingRailways.length <= 1) {
        setExpanded(true)
      }
    }
    toggleRailway(railway, false)
  }

  const chooseRailway = (railwayId: string) => {
    setOpenRailwayId(railwayId)
    setExpanded(false)
  }

  const goBackToRailwayList = () => {
    setOpenRailwayId(undefined)
    setExpanded(true)
  }

  const handleListItemClicked = (railway: RailwayModel) => {
    onListItemClicked?.(railway)
    if (isTextSearchMode) {
      chooseRailway(railway.railwayId)
      setExpanded(false)
      return
    }
    if (railway.isOpened) {
      goBackToRailwayList()
    } else {
      chooseRailway(railway.railwayId)
    }
  }

  const handleAddAllStretchLocations = (railway: RailwayModel) => {
    toggleRailway(railway, true)
  }

  const getRailwayListItem = (railway: RailwayModel) => (
    <Fragment key={railway.railwayId}>
      <StyledLocationListItem>
        {!isTextSearchMode && railway.isOpened && (
          <IconButton
            onClick={() => goBackToRailwayList()}
            color="primary"
            title={t('general.back')}
            aria-label={t('general.back')}
          >
            <StyledBackIcon />
          </IconButton>
        )}
        <StyledLocationListItemButton
          isSelected={isTextSearchMode ? railway.isAllPending : railway.isOpened}
          disableGutters
          onClick={() => handleListItemClicked(railway)}
        >
          {!isTextSearchMode && railway.isPending ? <strong>{railway.name}</strong> : railway.name}
        </StyledLocationListItemButton>
        {!isTextSearchMode && railway.isPending ? (
          <ListItemSecondaryAction>
            <IconButton
              onClick={() => resetRailwaySelection(railway)}
              size="small"
              color="primary"
              title={t('location.clear')}
              aria-label={t('location.clear')}
            >
              <ClearIcon />
            </IconButton>
          </ListItemSecondaryAction>
        ) : null}
        {!isTextSearchMode && !railway.isOpened && !railway.isPending ? (
          <ListItemSecondaryAction>
            <IconButton
              onClick={() => handleAddAllStretchLocations(railway)}
              size="small"
              color="primary"
              title={t('general.selectAll')}
              aria-label={t('general.selectAll')}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </ListItemSecondaryAction>
        ) : null}
        {isTextSearchMode ? (
          <ListItemSecondaryAction>
            <Checkbox
              edge="end"
              onChange={() => toggleRailway(railway)}
              checked={railway.isAllPending}
              inputProps={{ 'aria-labelledby': '' }}
            />
          </ListItemSecondaryAction>
        ) : null}
      </StyledLocationListItem>
      <div>{renderRailwaySections?.(railway)}</div>
    </Fragment>
  )

  return (
    <>
      {!isTextSearchMode && (
        <StyledLocationTitle onClick={() => setExpanded(isExpanded => !isExpanded)}>
          <StyledRegion primary={t('location.region.title')} />
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </StyledLocationTitle>
      )}
      <List disablePadding>
        {!actualExpanded && selectedOrPendingRailways.map(railway => getRailwayListItem(railway))}
        {actualExpanded && searchFilteredRailways.map(railway => getRailwayListItem(railway))}
      </List>
    </>
  )
}
