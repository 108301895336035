import { useMemo } from 'react'
import type { SensorHistory } from 'src/Types/SensorTwin'
import type { SensorCodeDomain } from 'src/Types/SensorCode'

import { useDomainConstantsStore } from 'src/Store/domainConstants'
import { useLanguage } from 'src/Hooks/useLanguage'
import type { Bender } from 'src/Types/Bender'
import { getChartLineColorByIndex } from 'src/Utils/chart'
import type { ChartLegend } from 'src/Components/Chart/Legends/ChartLegend'
import { partition } from 'src/Utils/array'

type OwnProps = {
  sensorHistory: SensorHistory[] | undefined
  inactiveSensors: number[]
  domain: SensorCodeDomain
  benders: Bender[]
  showCodeSystem?: boolean
  useBenderName: boolean
  getColorIndex?: (index: number) => number
}

export const useBenderHistoryLegends = ({
  sensorHistory,
  inactiveSensors,
  domain,
  benders,
  showCodeSystem = false,
  useBenderName,
  getColorIndex,
}: OwnProps): ChartLegend[] => {
  const { t, currentLanguage } = useLanguage()
  const sensorCodes = useDomainConstantsStore(state => state.sensorCodes.sensorCodes[domain])

  return useMemo(() => {
    const [historyWithData, historyWithoutData] = partition(sensorHistory || [], history => !!history.values?.length)
    return [...historyWithData, ...historyWithoutData].map((history, i) => {
      const { twinId, codeSystem, values, loggerBanedataId: benderId } = history
      const isActive = !inactiveSensors.includes(i)
      const titleKey = isActive ? 'benders.isolationChart.hideBender' : 'benders.isolationChart.showBender'

      const title = t(titleKey, { benderId })
      const code = sensorCodes.find(sc => sc.key === codeSystem)
      const sensorName = code ? code.localization.short[currentLanguage] : t('sensorHistory.chart.unknownType', { codeSystem })
      const benderName = benders.find(bender => bender.baneDataId === benderId)?.name || benderId
      const isEmpty = !values?.length
      const benderLabelParts = [benderName]
      if (showCodeSystem && !isEmpty) {
        benderLabelParts.push(`(${sensorName})`)
      }
      const label = useBenderName ? benderLabelParts.join(' ') : sensorName
      const id = `${twinId}_${codeSystem}`

      return {
        id,
        label,
        buttonTitle: title,
        color: getChartLineColorByIndex(getColorIndex ? getColorIndex(i) : i),
        isEmpty,
      }
    })
  }, [benders, currentLanguage, getColorIndex, inactiveSensors, sensorCodes, sensorHistory, showCodeSystem, t, useBenderName])
}
