import { styled } from '@mui/material/styles'

import { AssetInformationEntry } from 'src/Components/AssetCard/AssetInformationEntry'
import { AlarmIndicator } from 'src/Components/AlarmIndicator'
import { AlarmIcon } from 'src/Components/AlarmIcon'
import type { AlarmClassification } from 'src/Types/AlarmClassification'
import type { MachineSwing } from 'src/Types/SwingTypes'
import type { NormalizedAlarm } from 'src/Types/NormalizedAlarm'
import type { SystemStatus } from 'src/Types/SystemStatus'

import { getAlarmTypeByNormalizedAlarm } from 'src/Utils/alarms'
import { useLanguage } from 'src/Hooks/useLanguage'

type AlarmMatrixEntry = {
  alarmTypeId: number
  classification?: AlarmClassification
  description: string
  pointMachineStatus: SystemStatus[]
  baneDataNames: string[]
}

type OwnProps = {
  alarms: NormalizedAlarm[]
  pointMachineSwings: MachineSwing[]
}

const StyledAlarmContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-left: -20px;
`

export const AlarmMatrix = ({ alarms, pointMachineSwings }: OwnProps) => {
  const { t, currentLanguage } = useLanguage()

  const alarmMatrix: AlarmMatrixEntry[] = []
  alarms.forEach(alarm => {
    let entry = alarmMatrix.find(a => a.alarmTypeId === alarm.alarmTypeId)

    const alarmType = getAlarmTypeByNormalizedAlarm(alarm)
    const classification = alarmType && alarmType.classification.id

    if (!entry) {
      const description = alarmType
        ? alarmType.type.localization.short[currentLanguage]
        : t('alarms.unknownType', { alarmTypeId: alarm.alarmTypeId })

      entry = {
        alarmTypeId: alarm.alarmTypeId,
        classification,
        description,
        pointMachineStatus: pointMachineSwings.map(swing => (swing.isComplete ? 'Ok' : 'Unknown')),
        baneDataNames: pointMachineSwings.map(s => s.baneDataName),
      }

      alarmMatrix.push(entry)
    }

    const machineSwingIndex = pointMachineSwings.findIndex(s => s.baneDataId === alarm.baneDataId)
    if (machineSwingIndex !== -1) {
      entry.pointMachineStatus[machineSwingIndex] = classification === 'Alarm' ? 'Alarm' : 'Warning'
    }
  })

  return (
    <>
      {alarmMatrix.map(alarmEntry => {
        const { description, classification } = alarmEntry

        return (
          <AssetInformationEntry
            key={alarmEntry.alarmTypeId}
            label={
              <StyledAlarmContainer>
                {classification && <AlarmIcon classification={classification} size="small" />} {description}
              </StyledAlarmContainer>
            }
            values={alarmEntry.pointMachineStatus.map((alarmIndicator, i) => (
              <AlarmIndicator
                alarmIndicator={alarmIndicator}
                title={t('alarms.pointmachineAlarmInfo', {
                  pointMachineName: alarmEntry.baneDataNames[i],
                  classification: alarmIndicator === 'Ok' ? t('switches.statusLabels.alarms.noAlarms') : description,
                })}
              />
            ))}
          />
        )
      })}
    </>
  )
}
