import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { getAppInsights } from 'src/telemetryService'
import { msalAuthenticatedPromise } from 'src/msGraphClient'
import { useUserProfileStore } from 'src/Store/userProfile'
import { setTelemetryProps } from 'src/Tracking/telemetryProps'
import { QueryStringStateKeys, useQueryStringState } from 'src/Store/useQueryStringState'
import { getDateRange } from 'src/Features/SelectedDatePeriod/predefinedDatePeriods'

let lastPathname = ''
let lastSearch = ''
let lastHash = ''

export const useApplicationInsightsPageTracking = () => {
  const accessGroups = useUserProfileStore(state => state.accessGroups.accessGroups)
  const hasLoadedAccessGroups = accessGroups.length > 0
  const isBetaTest = accessGroups.map(x => x.toLowerCase()).includes('betatest')
  const location = useLocation()
  const { pathname, search, hash } = location

  const [datePeriod] = useQueryStringState(QueryStringStateKeys.datePeriod)
  const [stretchLocations] = useQueryStringState(QueryStringStateKeys.stretchLocations)

  useEffect(() => {
    let datePeriodValue: string | undefined
    if (typeof datePeriod === 'string') {
      datePeriodValue = datePeriod
    } else if (datePeriod) {
      datePeriodValue = 'range'
    }
    let dateRangeHours: number | undefined
    if (datePeriod) {
      const { fromDate, toDate } = getDateRange(datePeriod)
      dateRangeHours = Math.floor((toDate - fromDate) / 1000 / 60 / 60)
    }

    setTelemetryProps({
      searchPeriod: datePeriodValue,
      searchHours: dateRangeHours,
      locationsCount: stretchLocations.length,
    })
  }, [datePeriod, stretchLocations.length])

  useEffect(() => {
    if (!hasLoadedAccessGroups) {
      return
    }

    setTelemetryProps({
      isBetaTestUser: isBetaTest,
    })
  }, [hasLoadedAccessGroups]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (pathname === '/') {
      return
    }

    if (!hasLoadedAccessGroups) {
      return
    }

    const trackPageViewWhenAuthenticated = async () => {
      if (lastPathname === pathname && lastSearch === search && lastHash === hash) {
        return
      }
      lastPathname = pathname
      lastSearch = search
      lastHash = hash
      await msalAuthenticatedPromise
      getAppInsights()?.trackPageView({
        uri: `${pathname}${search}${hash}`,
        pageType: pathname,
        isLoggedIn: true,
      })
    }

    setTimeout(trackPageViewWhenAuthenticated)
  }, [pathname, search, hash, hasLoadedAccessGroups]) // eslint-disable-line react-hooks/exhaustive-deps
}
