import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'

import { SubHeaderWithExpandableInfo } from 'src/Components/SubHeaderWithExpandableInfo'
import { SensorDataChart } from '../SensorDataChart/SensorDataChart'
import { WeatherChart } from 'src/Features/Weather/WeatherChart/WeatherChart'
import { AlarmIcon } from 'src/Components/AlarmIcon'

import type { Asset } from 'src/Types/AssetTypes'
import type { AggregatedTrackCircuitAlarm } from 'src/Types/AggregatedTrackCircuitAlarm'
import type { DatePeriod } from 'src/Types/DatePeriod'
import { TrackCircuitCurrentType } from 'src/Types/TrackCircuitCurrentTypes'

import { useAlarmTypeByAlarm } from 'src/Hooks/useAlarmTypeByAlarm'
import { getDateRange } from 'src/Features/SelectedDatePeriod/predefinedDatePeriods'
import { useChartFocus } from 'src/Components/Chart/useChartFocus'
import { SubHeaderKey } from 'src/Providers/Settings/SubHeader'
import themeColors from 'src/theme'

type OwnProps = {
  datePeriod: DatePeriod
  asset?: Asset
  alarm?: AggregatedTrackCircuitAlarm
  expandReturnCurrentChart?: boolean
  isTrackCircuitsTab?: boolean
}

const StyledChartContainer = styled('div')`
  width: 100%;
  background-color: ${themeColors.sectionBackground};
`

const ALARM_PADDING = 2 * 60 * 60 * 1000 // 2 hours

export const ExpandableAlarmSensorData = ({
  datePeriod,
  asset,
  alarm,
  expandReturnCurrentChart = false,
  isTrackCircuitsTab = false,
}: OwnProps) => {
  const { t } = useTranslation()

  const [fromDate, toDate, endPadding] = useMemo(() => {
    if (!alarm) {
      const { fromDate, toDate } = getDateRange(datePeriod)
      return [fromDate, toDate, undefined]
    }
    const maxEndPadding = Math.min(Date.now() - alarm.lastRealToDateTime, ALARM_PADDING)

    return [alarm.firstRealFromDateTime - ALARM_PADDING, alarm.lastRealToDateTime + maxEndPadding, maxEndPadding]
  }, [alarm, datePeriod])

  const { chartFocus, zoomUpdated } = useChartFocus({ fromDate, toDate })

  const alarmType = useAlarmTypeByAlarm(alarm)

  const baneDataIds: string[] = useMemo(() => {
    if (alarm) {
      return [alarm.baneDataId]
    }
    if (asset) {
      return [asset.baneDataId]
    }
    return []
  }, [asset, alarm])

  const baneDataLocationId = asset?.baneDataLocationId || alarm?.baneDataLocationId

  if (!baneDataIds.length) {
    return null
  }

  return (
    <StyledChartContainer>
      <SubHeaderWithExpandableInfo
        title={
          <>
            {`${t('sensorData.cardHeader')} (${t('sensorData.returnCurrent')})`}
            {alarmType && <AlarmIcon classification={alarmType.classification.id} size="small" />}
          </>
        }
        hideText={`${t('sensorData.hideSensorData')} (${t('sensorData.returnCurrent')})`}
        isDefaultExpanded={expandReturnCurrentChart}
        subHeaderKey={SubHeaderKey.SensorDataReturnCurrent}
      >
        <SensorDataChart
          fromDate={fromDate}
          toDate={toDate}
          startPadding={alarm ? ALARM_PADDING : undefined}
          endPadding={alarm ? endPadding : undefined}
          baneDataIds={baneDataIds}
          currentType={TrackCircuitCurrentType.RC}
          alarm={alarm}
          showNavigationButton
          isTrackCircuitsTab={isTrackCircuitsTab}
          onZoomUpdated={zoomUpdated}
          hideLegend
          zoomSync={chartFocus}
        />
      </SubHeaderWithExpandableInfo>
      <SubHeaderWithExpandableInfo
        title={
          <>
            {`${t('sensorData.cardHeader')} (${t('sensorData.feedCurrent')})`}
            {alarmType && <AlarmIcon classification={alarmType.classification.id} size="small" />}
          </>
        }
        hideText={`${t('sensorData.hideSensorData')} (${t('sensorData.feedCurrent')})`}
        subHeaderKey={SubHeaderKey.SensorDataFeedCurrent}
      >
        <SensorDataChart
          fromDate={fromDate}
          toDate={toDate}
          startPadding={alarm ? ALARM_PADDING : undefined}
          endPadding={alarm ? endPadding : undefined}
          baneDataIds={baneDataIds}
          currentType={TrackCircuitCurrentType.FC}
          alarm={alarm}
          onZoomUpdated={zoomUpdated}
          hideLegend
          zoomSync={chartFocus}
        />
      </SubHeaderWithExpandableInfo>
      {baneDataLocationId && (
        <SubHeaderWithExpandableInfo
          title={t('weatherChart.title', { stationName: asset?.baneDataLocationName || alarm?.baneDataLocationName })}
          hideText={t('weatherChart.hideWeather')}
          isDefaultExpanded
          subHeaderKey={SubHeaderKey.WeatherChart}
        >
          <WeatherChart baneDataLocationId={baneDataLocationId} fromDate={fromDate} toDate={toDate} chartFocus={chartFocus} />
        </SubHeaderWithExpandableInfo>
      )}
    </StyledChartContainer>
  )
}
