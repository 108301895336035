import { useTranslation } from 'react-i18next'

import { AssetInfoPopper } from 'src/Components/AssetInfoPopper'

import type { Bender } from 'src/Types/Bender'
import type { PopperReferenceObject } from 'src/Types/PopperReferenceObject'

type OwnProps = {
  bender: Bender
  anchorElement: PopperReferenceObject
}

export const BenderInfoPopper = ({ bender, anchorElement }: OwnProps) => {
  const { t } = useTranslation()

  return (
    <AssetInfoPopper
      id={bender.baneDataId}
      header={bender.name}
      anchorElement={anchorElement}
      rows={[
        {
          label: t('benders.popperInfo.baneDataId'),
          value: bender.baneDataId,
        },
        {
          label: t('benders.popperInfo.location'),
          value: bender.baneDataLocationName,
        },
      ]}
    />
  )
}
