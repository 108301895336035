import type { ReactElement } from 'react'
import { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import Typography from '@mui/material/Typography'
import Slider from '@mui/material/Slider'
import Tooltip from '@mui/material/Tooltip'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import CircularProgress from '@mui/material/CircularProgress'

import { NoResult } from 'src/Components/NoResult'
import { Button } from 'src/Components/Button'
import { ConfirmDialog } from 'src/Components/ConfirmDialog'

import type { SensorTwin } from 'src/Types/SensorTwin'

import { useDomainConstantsStore } from 'src/Store/domainConstants'
import { useLanguage } from 'src/Hooks/useLanguage'
import { adjustHeatValue } from 'src/Providers/SwitchHeat'
import { useAppStateStore } from 'src/Store/appState'
import { useSwitchHeatVersion } from 'src/Hooks/useSwitchHeatVersion'

const useStyles = makeStyles()({
  container: {
    margin: 20,
  },
  sliderContainer: {
    display: 'flex',
  },
  slider: {
    maxWidth: 400,
    margin: '26px 40px 0 20px',
  },
  dialogContent: {
    display: 'flex',
    alignItems: 'baseline',
    '& > :first-child': {
      marginRight: 6,
    },
  },
})

const formatDegrees = (v: number, decimals: number = 1) => `${v.toFixed(decimals)}°C`

const createMarks = (sensor: SensorTwin) => {
  const min = sensor.requestValidation?.min
  const max = sensor.requestValidation?.max

  if (min === undefined || max === undefined || !sensor.requestValidation?.frontendControl) {
    return undefined
  }

  return [
    {
      value: min,
      label: formatDegrees(min),
    },
    {
      value: max,
      label: formatDegrees(max),
    },
  ]
}

const valuetext = (value: number) => `${value}°C`

const getSensorProps = (sensor: SensorTwin) => {
  const marks = createMarks(sensor)
  const currentValue = Number(sensor.currentValue)

  if (!sensor.currentValue || !sensor.id || !marks || Number.isNaN(currentValue)) {
    return undefined
  }

  return {
    currentValue,
    marks,
    min: marks[0].value,
    max: marks[marks.length - 1].value,
    sensorId: sensor.id,
    eTag: sensor.etag64,
  }
}

const SliderTooltip = (props: { children: ReactElement; value: number }) => (
  <Tooltip open placement="top" title={formatDegrees(props.value)} arrow>
    {props.children}
  </Tooltip>
)

type OwnProps = {
  sensor: SensorTwin
  onTemperatureUpdated: () => void
}

export const TemperatureAdjustment = ({ sensor, onTemperatureUpdated }: OwnProps) => {
  const { t, currentLanguage } = useLanguage()
  const { classes } = useStyles()
  const queueMessage = useAppStateStore(state => state.appMessages.queueMessage)
  const sensorCodes = useDomainConstantsStore(state => state.sensorCodes.sensorCodes.SVV)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const switchHeatVersion = useSwitchHeatVersion()

  const sensorProps = getSensorProps(sensor)
  const setpointLabel = sensorCodes.find(uc => uc.key === sensor.codeSystem)?.localization.full[currentLanguage]?.toLowerCase()

  const [pendingValue, setPendingValue] = useState(sensorProps?.currentValue || 0)

  if (!sensorProps || !setpointLabel) {
    return <NoResult>{t('switchHeat.heatManagement.sensorNotFound')}</NoResult>
  }

  const commitChange = () => {
    setConfirmDialogOpen(true)
  }

  const adjustmentConfirmed = (didConfirm: boolean) => {
    setConfirmDialogOpen(false)

    if (didConfirm) {
      setIsUpdating(true)
      adjustHeatValue({
        sensorId: sensorProps.sensorId,
        requestedValue: pendingValue,
        eTag: sensorProps.eTag,
        version: switchHeatVersion,
      })
        .then(res => {
          onTemperatureUpdated()
          setIsUpdating(false)
        })
        .catch(() => {
          queueMessage({
            type: 'error',
            message: 'switchHeat.heatManagement.updating.failed',
          })
          setIsUpdating(false)
        })
    }
  }

  return (
    <div className={classes.container}>
      <Typography id="temperature-slider" gutterBottom>
        {t('switchHeat.heatManagement.label', { setpointLabel })}
      </Typography>
      <div className={classes.sliderContainer}>
        <Slider
          className={classes.slider}
          defaultValue={sensorProps.currentValue}
          getAriaValueText={valuetext}
          aria-labelledby="temperature-slider"
          step={1}
          valueLabelDisplay="on"
          marks={sensorProps.marks}
          min={sensorProps.min}
          max={sensorProps.max}
          onChangeCommitted={(evt, val) => setPendingValue(typeof val === 'number' ? val : val[0])}
          components={{
            ValueLabel: SliderTooltip,
          }}
        />
        <Button
          variant="contained"
          // disabled={pendingValue === sensorProps.currentValue} // TODO: this doesn't seem to work
          onClick={commitChange}
          size="medium"
        >
          {t('switchHeat.heatManagement.commitChange')}
        </Button>
      </div>
      {confirmDialogOpen && (
        <ConfirmDialog
          title={t('switchHeat.heatManagement.confirmDialog.title')}
          text={t('switchHeat.heatManagement.confirmDialog.text')}
          type="confirm"
          onConfirm={adjustmentConfirmed}
        />
      )}
      {isUpdating && (
        <Dialog open aria-labelledby="is-updating-dialog-title" aria-describedby="is-updating-dialog-description">
          <DialogTitle id="is-updating-dialog-title">{t('switchHeat.heatManagement.updating.title')}</DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <CircularProgress size={15} />{' '}
            <DialogContentText id="is-updating-dialog-description">
              {t('switchHeat.heatManagement.updating.text')}
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
    </div>
  )
}
