import type { ReactElement, ReactNode } from 'react'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'

const StyledListItem = styled(ListItem)(
  props => `
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 20px;
  ${props.theme.breakpoints.down('sm')} {
    padding-right: 8px;
  }
`
)

const StyledStatusValue = styled(ListItemText)`
  font-weight: bold;
  text-align: right;
  font-size: 0.875rem;
  word-break: break-word;
`

type OwnProps = {
  label?: string | ReactElement
  value?: ReactNode
  longValue?: boolean
}

export const CardInformationEntry = ({ label = '', value = '', longValue = false }: OwnProps) => (
  <Grid container spacing={0}>
    <Grid item xs={12}>
      <StyledListItem dense>
        <Grid item xs={longValue ? 5 : 9} md={longValue ? 5 : 7} lg={longValue ? 5 : 8}>
          <ListItemText>{label}</ListItemText>
        </Grid>
        <Grid item xs={longValue ? 7 : 3} md={longValue ? 7 : 5} lg={longValue ? 7 : 4}>
          <StyledStatusValue disableTypography>{value}</StyledStatusValue>
        </Grid>
      </StyledListItem>
    </Grid>
  </Grid>
)
