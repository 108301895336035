import { getAffectedSystemDefinitionByRoute, getGroupedAffectedSystems } from 'src/Features/SystemStatusAlerts/routeDefinitions'

/**
 * Returns a list of affected systems that should be requested for the given route.
 * When only global affected systems should be requested, undefined is returned.
 * @param route Typically the current route (e.g. /trackcircuits-cards)
 */
export const getRelevantAffectedSystemsByRoute = (route: string) => {
  const { allGlobalAffectedSystems } = getGroupedAffectedSystems()

  const definition = getAffectedSystemDefinitionByRoute(route)

  if (definition?.isGlobalAffectedSystem) {
    return undefined
  }

  if (definition?.localSystems?.length) {
    const localSystems = definition.localSystems || []
    return [...new Set([...allGlobalAffectedSystems, ...localSystems])]
  }

  return undefined
}
