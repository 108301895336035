import type { ChartProps, TooltipEntry } from 'src/Components/Chart/ChartRenderer'
import { ChartRenderer } from 'src/Components/Chart/ChartRenderer'

import type { Rect, NumberInterval } from 'src/Types/ChartTypes'

import { maxNum, minNum } from 'src/Utils/number'
import { formatNumber } from 'src/Utils/format'
import type { PointMachineHeatChartAsset, PointMachineHeatSensorData } from 'src/Types/PointMachineHeatSensorStatus'

const OPTIMIZE_DATA = true
const Y_AXIS_UNIT = 'A'
const Y_AXIS_UNIT_SCALED = ` ${Y_AXIS_UNIT}`

const parseValueHistory = (sensorData: PointMachineHeatSensorData, timeline: NumberInterval) => {
  const innerData = sensorData.codeSystems[0]
  const mapped = innerData.values ? [...innerData.values] : []

  if (typeof innerData.currentAtFromTime === 'number') {
    mapped.unshift([timeline.start, innerData.currentAtFromTime])
  }

  if (mapped.length) {
    // Extend each line to the end of the chart by duplicating the last point
    const lastEntry = mapped.slice(-1)[0]
    mapped.push([timeline.end, lastEntry[1]])
  }

  return !OPTIMIZE_DATA
    ? mapped
    : mapped.filter((entry, i, arr) => {
        // Always return the first and last entries
        if (i === 0 || i === arr.length - 1) {
          return true
        }

        // Optimize by skipping entries with the same measurement as the next.
        // If the rendered step algorithm is changed to curveStepAfter, this should
        // compare against the previous entry instead
        return entry[1] !== arr[i - 1][1]
      })
}

const formatTick = (unit: string, decimals: number, value: number) => `${formatNumber(value, decimals, false)} ${unit}`

const formatYTick = (value: number) => formatTick(Y_AXIS_UNIT_SCALED, 2, value)

const CHART_MARGIN: Rect = { top: 5, right: 0, bottom: 20, left: 40 }
const MAX_ZOOM_MAGIC_CONSTANT = 1000000 // This magic number results in a nice max zoom resolution

export class PointMachineHeatRenderer extends ChartRenderer {
  sensorData: PointMachineHeatSensorData[] = []

  assets: PointMachineHeatChartAsset[] = []

  constructor({ chart, timeline, classes }: Omit<ChartProps, 'config'>) {
    super({
      chart,
      timeline,
      classes,
      config: {
        chartMargin: CHART_MARGIN,
        maxZoomMagicConstant: MAX_ZOOM_MAGIC_CONSTANT,
        linearYScale: true,
        useCanvas: true,
        formatYTick,
      },
    })
  }

  getDomain() {
    if (this.sensorData.length === 0) {
      return {
        xMin: this.timeline.start,
        xMax: this.timeline.end,
        yMax: 0,
        yMin: 1,
      }
    }

    const yVals = this.sensorData.flatMap(data => {
      const innerData = data.codeSystems[0]
      if (innerData.values?.length) {
        return innerData.values.map(v => v[1])
      }

      return typeof innerData.currentAtFromTime === 'number' ? [innerData.currentAtFromTime] : []
    })

    const maxValY = maxNum(yVals)
    const minValY = minNum(yVals)

    let domainMaxY = (Math.ceil(maxValY * 10) + (Math.ceil(maxValY * 10) % 2)) / 10
    if (domainMaxY < maxValY + 0.2) {
      domainMaxY += 0.2
    }
    let domainMinY = (Math.floor(minValY * 10) - (Math.floor(minValY * 10) % 2)) / 10
    if (domainMinY > maxValY - 0.2) {
      domainMinY -= 0.2
    }

    return {
      xMin: this.timeline.start,
      xMax: this.timeline.end,
      yMin: domainMinY,
      yMax: domainMaxY,
    }
  }

  getLabelForSeries(index: number) {
    const sensorData = this.sensorData[index]
    return sensorData.baneDataId || sensorData.parentBaneDataId
  }

  setAssets(pointMachines: PointMachineHeatChartAsset[]) {
    this.assets = pointMachines
  }

  getTooltipText(entry: TooltipEntry) {
    const pointMachineName = this.assets.find(a => a.id === entry.label)?.name
    return `${pointMachineName}: ${formatNumber(entry.value, 2)} A`
  }

  setData(sensorData: PointMachineHeatSensorData[]) {
    this.sensorData = sensorData

    const dataCurves = sensorData.map(data => parseValueHistory(data, this.timeline))
    this.setDataCurves(dataCurves)
  }
}
