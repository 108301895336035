import type { ReactNode } from 'react'
import Grid from '@mui/material/Grid'

export type OwnProps = {
  children?: ReactNode
  className?: string
}

export const CustomToolbar = ({ children, className }: OwnProps) => {
  return (
    <Grid container direction="row" alignContent="center" alignItems="center" wrap="nowrap" className={className}>
      {children}
    </Grid>
  )
}
