import { useState, useEffect } from 'react'
import type { Filter } from 'src/Types/Filter'
import { FilterType } from 'src/Types/Filter'
import { setDefaultSelectedFilters } from 'src/Utils/filter'
import type { SystemStatus } from 'src/Types/SystemStatus'

type FilterCleanupParams = {
  selectedStretchLocations: string[]
  selectedStatus: SystemStatus[]
  defaultSelectedStatus: SystemStatus[]
  selectedSystem: string[]
}

export const useAlarmFilters = ({
  selectedStretchLocations,
  selectedStatus,
  defaultSelectedStatus,
  selectedSystem,
}: FilterCleanupParams) => {
  const [filters, setFilters] = useState<Filter[]>(() =>
    setDefaultSelectedFilters(selectedStatus.length ? selectedStatus : defaultSelectedStatus, selectedSystem)
  )

  useEffect(() => {
    setFilters(filters.filter(f => f.type !== FilterType.AssetId))
  }, [selectedStretchLocations]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    filters,
    setFilters,
  }
}
