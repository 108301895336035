import type { TrackCircuitCurrentMeasurement, TrackCircuitCurrents } from 'src/Types/TrackCircuitCurrentTypes'
import type { LeveledMeasurement, LeveledIdMeasurement, MeasurementType } from 'src/Types/LevelOfDetails'
import { hasOverlappedTimeSpan } from './timeSpan'
import { arrayMinMax } from 'src/Utils/arrayMinMax'

export { getQueryTimeSpan } from './getQueryTimeSpan'

export const hasOverlappedMeasurement = (a: LeveledMeasurement, b: LeveledMeasurement) => {
  return hasOverlappedTimeSpan([a.minDate, a.maxDate], [b.minDate, b.maxDate])
}

export const joinMeasurements = (a: LeveledMeasurement, b: LeveledMeasurement): LeveledMeasurement => {
  const dates = Array.from(new Set([a.minDate, a.maxDate, b.minDate, b.maxDate])).sort((a, b) => (a < b ? -1 : 1))

  const allMeasurements = [a, b].map(m => m.measurements)
  const types = Array.from(new Set(allMeasurements.flatMap(m => Object.keys(m || {}) as MeasurementType[])))

  const measurements = types.reduce<Partial<Record<MeasurementType, TrackCircuitCurrentMeasurement[]>>>((agg, type) => {
    const allPoints = allMeasurements.map(m => m?.[type] || [])
    agg[type] = dates.flatMap((date, i) => {
      if (i === dates.length - 1) {
        return []
      }
      const last = i === dates.length - 2
      const from = date
      const to = dates[i + 1]

      const filtered = allPoints
        .map(m => m.filter(point => point[0] >= from && (last ? point[0] <= to : point[0] < to)))
        .filter(m => m.length)

      return filtered[!last || (last && a.maxDate > b.maxDate) ? 0 : filtered.length - 1] || []
    })
    return agg
  }, {})

  return {
    minDate: Math.min(...dates),
    maxDate: Math.max(...dates),
    measurements,
  }
}

const hasAnyOverlap = (leveledMeasurements: LeveledMeasurement[]) =>
  leveledMeasurements.some(a => leveledMeasurements.some(b => a !== b && hasOverlappedMeasurement(a, b)))

export const getConsolidatedMeasurements = (...measurements: LeveledMeasurement[][]): LeveledMeasurement[] => {
  const leveledMeasurements = measurements.filter(measurement => measurement?.length).flatMap(measurement => measurement)
  const newMeasurements: LeveledMeasurement[] = [...leveledMeasurements]
  while (hasAnyOverlap(newMeasurements)) {
    const current = newMeasurements.pop()
    if (!current) {
      break
    }
    const overlappedIndex = newMeasurements.findIndex(m => hasOverlappedMeasurement(m, current))
    if (overlappedIndex === -1) {
      newMeasurements.unshift(current)
    } else {
      const overlapped = newMeasurements[overlappedIndex]
      newMeasurements.splice(overlappedIndex, 1)
      const joined = joinMeasurements(current, overlapped)
      newMeasurements.unshift(joined)
    }
  }
  return newMeasurements.sort((a, b) => (a.minDate > b.minDate ? 1 : -1))
}

export const getJoinedMeasurements = (
  measurements: LeveledIdMeasurement = {},
  sensorData: TrackCircuitCurrents[] = []
): LeveledIdMeasurement => {
  const sensorDataMeasurements = sensorData.reduce<LeveledIdMeasurement>(
    (acc, { trackCircuitBaneDataId, rcMeasurements, fcMeasurements, missingFcMeasurements, missingRcMeasurements }) => {
      const dates = [...rcMeasurements, ...fcMeasurements].map(([date]) => date)
      if (dates.length) {
        const { min, max } = arrayMinMax(dates)
        acc[trackCircuitBaneDataId] = [
          {
            minDate: min!,
            maxDate: max!,
            measurements: {
              rc: rcMeasurements,
              fc: fcMeasurements,
              missingRc: missingRcMeasurements,
              missingFc: missingFcMeasurements,
            },
          },
        ]
      }
      return acc
    },
    {}
  )

  const baneDataIds = Array.from(new Set([...Object.keys(sensorDataMeasurements), ...Object.keys(measurements)]))

  return baneDataIds.reduce<LeveledIdMeasurement>((acc, trackCircuitBaneDataId) => {
    acc[trackCircuitBaneDataId] = getConsolidatedMeasurements(
      measurements[trackCircuitBaneDataId] || [],
      sensorDataMeasurements[trackCircuitBaneDataId] || []
    )
    return acc
  }, {})
}
