import { Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import NewReleasesIcon from '@mui/icons-material/NewReleases'

import type { TemperatureParameterDiff } from 'src/Features/SwitchHeat/Adjustment/useDetectRemoteChanges'
import { formatLocalDateTime } from 'src/Utils/format'
import { useLanguage } from 'src/Hooks/useLanguage'

const StyledRoot = styled('div')`
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  margin-left: -24px;
`

type OwnProps = {
  diff: TemperatureParameterDiff
}

export const TemperatureChangeNotification = ({ diff }: OwnProps) => {
  const { t } = useLanguage()
  const { previousValue, currentValue, currentValueTime } = diff
  const title = t('switchHeat.temperatureForm.remoteValueChanged', {
    from: previousValue,
    to: currentValue,
    time: formatLocalDateTime(currentValueTime),
  })
  return (
    <StyledRoot data-testid="TemperatureChangeNotification">
      <Tooltip title={title} placement="top" arrow>
        <NewReleasesIcon color="primary" fontSize="small" />
      </Tooltip>
    </StyledRoot>
  )
}
