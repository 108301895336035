import { ApiType } from 'src/Types/ApiType'

import type { PointMachineHeatSensorData, PointMachineHeatSensorStatus } from 'src/Types/PointMachineHeatSensorStatus'

import { callApiGet } from 'src/msGraphClient'
import config from 'src/Config/Config'

const { POINT_MACHINE_HEAT_HISTORY, POINT_MACHINE_HEAT_SENSORS } = config.API_FRAGMENTS.apim

export const getPointMachineHeatSensorData = (baneDataId: string, isSequence: boolean, fromDate: number, toDate: number) => {
  const urlParams = new URLSearchParams({
    baneDataId,
    fromDate: new Date(fromDate).toJSON(),
    toDate: new Date(toDate).toJSON(),
    codeSystemKey: isSequence ? 'sequence' : 'single',
  })

  return callApiGet<PointMachineHeatSensorData[]>(`${POINT_MACHINE_HEAT_HISTORY}?${urlParams.toString()}`, ApiType.APIM)
}

export const getPointMachineHeatSensors = (baneDataId: string) => {
  const urlParams = new URLSearchParams({ baneDataId })

  return callApiGet<PointMachineHeatSensorStatus>(`${POINT_MACHINE_HEAT_SENSORS}?${urlParams.toString()}`, ApiType.APIM)
}
