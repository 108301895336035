import Grid from '@mui/material/Grid'

import { WeatherLocationSelector } from './WeatherLocationSelector'
import { ExpandCollapseButton } from 'src/Components/ExpandCollapseButton'

import { useBreakpointUp } from 'src/Hooks/useBreakpoint'
import { useLanguage } from 'src/Hooks/useLanguage'

type OwnProps = {
  expanded: boolean
  selectedWeatherLocationId: string
  onToggleExpand: () => void
  onChangeSelectedWeatherLocationId: (locationId: string) => void
}

export const WeatherTimelineFooter = ({
  expanded,
  selectedWeatherLocationId,
  onToggleExpand,
  onChangeSelectedWeatherLocationId,
}: OwnProps) => {
  const { t } = useLanguage()
  const isMediaSM = useBreakpointUp('sm')

  let expandToggleText: string | undefined = t('weather.forecast')
  if (!isMediaSM && !expanded) {
    expandToggleText = undefined
  }

  return (
    <Grid container alignItems="center" sx={{ backgroundColor: 'white' }}>
      <Grid item sm={3} xs={0} />
      <Grid item sm={6} xs={6}>
        <Grid container justifyContent="center">
          <ExpandCollapseButton
            isExpanded={expanded}
            onToggleExpand={onToggleExpand}
            text={expandToggleText}
            largeIcon
            uppercase
          />
        </Grid>
      </Grid>
      <Grid item sm={3} xs={6}>
        <Grid container justifyContent="flex-end">
          <WeatherLocationSelector
            selectedWeatherLocationId={selectedWeatherLocationId}
            onWeatherLocationIdSelected={onChangeSelectedWeatherLocationId}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
