import { useCallback, useContext } from 'react'

import { NewAlarmSound } from 'src/Types/NewAlarmSound'

import { SoundManagerContext } from 'src/Components/SoundManager'

import trainSound from 'src/Assets/train-horn.mp3'
import cling from 'src/Assets/cling_2.wav'
import { useUserProfileStore } from 'src/Store/userProfile'

export const useNewAlarmSound = () => {
  const soundManager = useContext(SoundManagerContext)

  const updateSetting = useUserProfileStore(state => state.settings.updateSetting)
  const newAlarmSound = useUserProfileStore(state => state.settings.settings.newAlarmSound)

  const playAlarmSound = useCallback(
    (sound: string = newAlarmSound) => {
      if (sound !== NewAlarmSound.None) {
        soundManager.playSound(sound === NewAlarmSound.Train ? trainSound : cling)
      }
    },
    [newAlarmSound, soundManager]
  )

  const changeAlarmSound = (sound: NewAlarmSound) => updateSetting('newAlarmSound', sound)

  return {
    newAlarmSound,
    playAlarmSound,
    changeAlarmSound,
  }
}
