import { useState } from 'react'
import { styled } from '@mui/material/styles'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import ListItem from '@mui/material/ListItem'
import { endOfToday } from 'date-fns/endOfToday'
import { nb as noLocale } from 'date-fns/locale/nb'
import { enUS as enLocale } from 'date-fns/locale/en-US'
import { nbNO, enUS } from '@mui/x-date-pickers/locales'
import type { DateOrTimeView } from '@mui/x-date-pickers/models'

import type { DateRange } from 'src/Types/DatePeriod'

import { Button } from 'src/Components/Button'
import { useLanguage } from 'src/Hooks/useLanguage'
import { useAppStateStore } from 'src/Store/appState'

const dateToTimestamp = (date: Date | null) => (date ? new Date(date).valueOf() : undefined)

const localeMap = {
  en: enLocale,
  no: noLocale,
}

const localeTextMap = {
  en: enUS,
  no: nbNO,
}

const DATE_INPUT_FORMAT = 'dd.MM.yyyy HH:mm'

const StyledDateSelectListItem = styled(ListItem)`
  padding-right: 20px;
`

const StyledDateSelectButton = styled(Button)`
  width: 223px;
`

type OwnProps = {
  selectedDateRange: DateRange
  onDatePeriodSelected: () => void
}

export const ManualDatePeriodSelector = ({ selectedDateRange, onDatePeriodSelected }: OwnProps) => {
  const { t, currentLanguage } = useLanguage()
  const changePendingPeriodStart = useAppStateStore(state => state.selectedDatePeriod.changePendingPeriodStart)
  const changePendingPeriodEnd = useAppStateStore(state => state.selectedDatePeriod.changePendingPeriodEnd)
  const [fromDateError, setFromDateError] = useState<string | undefined>()
  const [toDateError, setToDateError] = useState<string | undefined>()

  const [pendingFromDate, setPendingFromDate] = useState(selectedDateRange ? new Date(selectedDateRange.fromDate) : null)
  const [pendingToDate, setPendingToDate] = useState(selectedDateRange ? new Date(selectedDateRange.toDate) : null)

  const datesAreInOrder =
    pendingFromDate &&
    pendingToDate &&
    !Number.isNaN(pendingFromDate.valueOf()) &&
    !Number.isNaN(pendingToDate.valueOf()) &&
    pendingFromDate <= pendingToDate

  const isValidDatePeriod =
    pendingFromDate &&
    pendingToDate &&
    !Number.isNaN(pendingFromDate.valueOf()) &&
    !Number.isNaN(pendingToDate.valueOf()) &&
    datesAreInOrder &&
    pendingToDate <= endOfToday()

  const canCommitSelectedDatePeriod = isValidDatePeriod && !fromDateError && !toDateError

  const customValidationError = !datesAreInOrder ? 'datesNotInOrder' : undefined

  const commitSelectedDates = () => {
    changePendingPeriodStart(dateToTimestamp(pendingFromDate))
    changePendingPeriodEnd(dateToTimestamp(pendingToDate))
    onDatePeriodSelected()
  }

  const getErrorText = (errorKey?: string) => {
    if (errorKey === 'disableFuture') {
      return t('general.date.invalidMaxDate')
    }

    if (errorKey === 'invalidDate') {
      return t('general.date.invalidDateFormat')
    }

    if (errorKey === 'datesNotInOrder') {
      return t('general.date.datesNotInOrder')
    }

    return undefined
  }
  const applyErrorSlotProps = (errorKey: string | undefined) =>
    errorKey
      ? {
          textField: {
            helperText: getErrorText(errorKey),
          },
          field: {
            error: true,
          } as any,
        }
      : {}

  const views: DateOrTimeView[] = ['year', 'month', 'day', 'hours', 'minutes']
  return (
    <>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={localeMap[currentLanguage]}
        localeText={localeTextMap[currentLanguage]?.components.MuiLocalizationProvider.defaultProps.localeText}
      >
        <ListItem>
          <DateTimePicker
            label={t('general.date.fromDate')}
            format={DATE_INPUT_FORMAT}
            value={pendingFromDate}
            disableFuture
            showDaysOutsideCurrentMonth
            onChange={setPendingFromDate}
            onError={errorKey => setFromDateError(errorKey ?? undefined)}
            views={views}
            slotProps={{
              openPickerButton: {
                'aria-label': t('general.date.changeFromDate'),
              },
              ...applyErrorSlotProps(fromDateError),
            }}
          />
        </ListItem>
        <ListItem>
          <DateTimePicker
            label={t('general.date.toDate')}
            format={DATE_INPUT_FORMAT}
            value={pendingToDate}
            disableFuture
            showDaysOutsideCurrentMonth
            onChange={setPendingToDate}
            onError={errorKey => setToDateError(errorKey ?? undefined)}
            views={views}
            slotProps={{
              openPickerButton: {
                'aria-label': t('general.date.changeToDate'),
              },
              ...applyErrorSlotProps(toDateError || customValidationError),
            }}
          />
        </ListItem>
      </LocalizationProvider>
      <StyledDateSelectListItem title={t('general.date.selectTimePeriod')}>
        <StyledDateSelectButton onClick={commitSelectedDates} disabled={!canCommitSelectedDatePeriod}>
          {t('general.select')}
        </StyledDateSelectButton>
      </StyledDateSelectListItem>
    </>
  )
}
