import LinkIcon from '@mui/icons-material/Link'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

import { MenuItem } from 'src/Components/UIComponents'
import type { Asset } from 'src/Types/AssetTypes'
import { QueryStringStateKeys, useQueryStringState } from 'src/Store/useQueryStringState'
import { getDateRange } from 'src/Features/SelectedDatePeriod/predefinedDatePeriods'
import { useAppStateStore } from 'src/Store/appState'
import { getPermalinkUrl } from 'src/Components/AssetCard/AssetCardActionsMenu/getPermalinkUrl'

type OwnProps = {
  asset: Asset
}

export const PermalinkMenuItem = ({ asset }: OwnProps) => {
  const { t } = useTranslation()
  const [selectedDatePeriod] = useQueryStringState(QueryStringStateKeys.datePeriod)
  const dateRange = selectedDatePeriod ? getDateRange(selectedDatePeriod) : undefined
  const { fromDate, toDate } = dateRange || {}

  const queueMessage = useAppStateStore(state => state.appMessages.queueMessage)

  const permalinkUrl = useMemo(() => getPermalinkUrl(asset, fromDate, toDate), [asset, fromDate, toDate])

  const handleClick = async () => {
    await navigator.clipboard
      .writeText(permalinkUrl.toString())
      .catch(() => queueMessage({ type: 'error', message: 'general.permalinkError' }))
      .then(() => queueMessage({ type: 'success', message: 'general.permalinkSuccess' }))
  }

  // TODO Før endringer var denne komponenten en button med href={navUrl}, usikker på hva
  // denne gjorde så legge inn her som et notat i tilfelle det må inn igjen
  // const navUrl = `${permalinkUrl.pathname}${permalinkUrl.search}${permalinkUrl.hash}`

  return (
    <MenuItem onClick={handleClick}>
      <LinkIcon />
      {t('general.permalinkLabel')}
    </MenuItem>
  )
}
