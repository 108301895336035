import { useState } from 'react'
import type { TFunction } from 'i18next'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import DateRangeIcon from '@mui/icons-material/DateRangeOutlined'

import { ButtonWithPopover } from 'src/Components/ButtonWithPopover'
import { PredefinedDatePeriodList } from 'src/Features/SelectedDatePeriod/DatePeriodSelector/PredefinedDatePeriodList'
import { ManualDatePeriodSelector } from 'src/Features/SelectedDatePeriod/DatePeriodSelector/ManualDatePeriodSelector'

import type { DatePeriodKey, DateRange } from 'src/Types/DatePeriod'
import { useAppStateStore } from 'src/Store/appState'
import { formatLocalDateDateRangeShort } from 'src/Utils/format'
import { getDatePeriodKey, getDateRange, getPendingDateRange } from '../predefinedDatePeriods'
import { useLanguage } from 'src/Hooks/useLanguage'

const formatPeriod = (datePeriodKey: DatePeriodKey, customRange: DateRange | undefined, t: TFunction, lang: string) => {
  const dateRange = customRange || getDateRange(datePeriodKey)

  return datePeriodKey === 'selectTimePeriod' && dateRange
    ? formatLocalDateDateRangeShort(dateRange, lang)
    : t(`general.date.${datePeriodKey}`)
}

const StyledDateButtonSpan = styled('span')`
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  width: inherit;
`

export const DatePeriodSelector = () => {
  const { t, currentLanguage } = useLanguage()

  const changePendingDatePeriod = useAppStateStore(state => state.selectedDatePeriod.changePendingDatePeriod)
  const pendingDatePeriod = useAppStateStore(state => state.selectedDatePeriod.pendingDatePeriod)

  const [isPopoverOpen, setOpenPopover] = useState(false)

  const clearPendingPeriod = () => {
    changePendingDatePeriod()
  }

  const onPendingDatePeriodClick = (pendingDatePeriodKey?: string) => {
    if (pendingDatePeriodKey !== 'selectTimePeriod') {
      setOpenPopover(false)
    }
  }

  const pendingDatePeriodKey = pendingDatePeriod ? getDatePeriodKey(pendingDatePeriod) : undefined
  const pendingDateRange = pendingDatePeriod ? getPendingDateRange(pendingDatePeriod) : undefined

  const title = pendingDatePeriodKey
    ? formatPeriod(pendingDatePeriodKey, pendingDateRange, t, currentLanguage)
    : t('general.date.timePeriod')

  return (
    <ButtonWithPopover
      isPopoverOpen={isPopoverOpen}
      onOpenPopover={setOpenPopover}
      buttonContent={<StyledDateButtonSpan>{title}</StyledDateButtonSpan>}
      buttonEndIcon={<DateRangeIcon />}
      popoverContent={
        <List component="nav" aria-labelledby="nested-list-date-selector">
          <Box>
            <PredefinedDatePeriodList
              onDatePeriodSelected={datePeriodKey => onPendingDatePeriodClick(datePeriodKey)}
              selectedPeriodKey={pendingDatePeriodKey}
              onClearPendingPeriod={clearPendingPeriod}
            />
            {pendingDatePeriodKey === 'selectTimePeriod' && pendingDateRange && (
              <ManualDatePeriodSelector selectedDateRange={pendingDateRange} onDatePeriodSelected={() => setOpenPopover(false)} />
            )}
          </Box>
        </List>
      }
    />
  )
}
