import { callApiGet } from 'src/msGraphClient'

import { ApiType } from 'src/Types/ApiType'
import type { AlarmType } from 'src/Types/AlarmType'

import config from 'src/Config/Config'

const { ALARM_TYPES } = config.API_FRAGMENTS.apim

export const getAlarmTypes = () => callApiGet<AlarmType[]>(`${ALARM_TYPES}`, ApiType.APIM)
