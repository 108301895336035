/**
 * Asynchronously maps over an array, limiting concurrency.
 *
 * @param arr - The array to map over.
 * @param limit - Concurrency limit.
 * @param mapIterator - The iterator to perform on each item.
 */
export const mapLimit = <T, U>(arr: T[], limit: number, mapIterator: (v: T) => Promise<U>): Promise<U[]> => {
  return new Promise((resolve, reject) => {
    const mapped: U[] = []
    let pending = 0
    let index = -1

    const next = () => {
      if (pending < limit) {
        index += 1
        if (index < arr.length) {
          pending += 1
          mapIterator(arr[index])
            .then(result => {
              mapped.push(result)
              pending -= 1
              next()
            })
            .catch(err => {
              reject(err)
            })
          next()
        } else if (pending <= 0) {
          resolve(mapped)
        }
      }
    }

    next()
  })
}

/**
 * Asynchronously loops over an array, limiting concurrency.
 *
 * @param arr - The array to loop over.
 * @param limit - Concurrency limit.
 * @param mapIterator - The iterator to perform on each item.
 */
export const eachLimit = <T>(arr: T[], limit: number, eachIterator: (v: T) => Promise<void>): Promise<void> => {
  return new Promise(resolve => {
    let pending = 0
    let index = -1

    const next = () => {
      if (pending < limit) {
        index += 1
        if (index < arr.length) {
          pending += 1
          eachIterator(arr[index]).then(() => {
            pending -= 1
            next()
          })
          next()
        } else if (pending <= 0) {
          resolve()
        }
      }
    }

    next()
  })
}
