import { ApiType } from 'src/Types/ApiType'

import { callApiGet } from 'src/msGraphClient'
import config from 'src/Config/Config'
import { mapSwingsContract } from 'src/Providers/Contracts/mapSwingsContract'
import type { SwingsContract } from 'src/Providers/Contracts/SwingsContract'
import { mapSwingContract } from 'src/Providers/Contracts/mapSwingContract'
import type { SwingContract } from 'src/Providers/Contracts/SwingContract'

const { SWINGS_DETAILS, SWINGS } = config.API_FRAGMENTS.apim

type GetSwingsParams = {
  baneDataId: string
  fromDate: number
  toDate: number
}

export const getSwings = async ({ baneDataId, fromDate, toDate }: GetSwingsParams) => {
  const urlParams = new URLSearchParams({
    baneDataId,
    fromDate: new Date(fromDate).toJSON(),
    toDate: new Date(toDate).toJSON(),
  })

  const response = await callApiGet<SwingsContract>(`${SWINGS}?${urlParams.toString()}`, ApiType.APIM, {
    parseDates: true,
  })

  return mapSwingsContract(response)
}

export const getSwingDetails = async (swingId: string) => {
  const urlParams = new URLSearchParams({
    swingId,
  })

  const switchSwing = await callApiGet<SwingContract>(`${SWINGS_DETAILS}?${urlParams.toString()}`, ApiType.APIM, {
    parseDates: true,
  })

  switchSwing.machineSwings = switchSwing.machineSwings.sort((a, b) =>
    a.isFromSequence && a.timestamp && b.timestamp
      ? a.timestamp - b.timestamp
      : a.banedataNameParsed.localeCompare(b.banedataNameParsed)
  )

  return mapSwingContract(switchSwing)
}
