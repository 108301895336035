import type { DeepRailway, DeepRailwaySection, DeepRailwayLocation } from 'src/Types/DeepRailway'
import config from 'src/Config/Config'
import { callApiGet } from 'src/msGraphClient'
import { ApiType } from 'src/Types/ApiType'

const { RAILWAYS_DEEP } = config.API_FRAGMENTS.apim

const cleanText = (text: string) => (text[0] === '(' ? text.substring(1) : text)
const cleanedLocalCompare = (a: string, b: string) => cleanText(a).localeCompare(cleanText(b), config.STRING_LOCALE)

const sortLocations = (locations: DeepRailwayLocation[]): DeepRailwayLocation[] =>
  locations.sort((a, b) => {
    if (a.fromKm && b.fromKm) {
      return a.fromKm - b.fromKm
    }
    return a.name.localeCompare(b.name, config.STRING_LOCALE)
  })

const sortRailwaySections = (railwaySections: DeepRailwaySection[]): DeepRailwaySection[] =>
  railwaySections
    .map(({ locations, ...railwaySection }) => {
      const fromKm = Math.min(...locations.map(location => location.fromKm))
      const toKm = Math.max(...locations.map(location => location.toKm))
      return {
        ...railwaySection,
        fromKm,
        toKm,
        locations: sortLocations(locations),
      }
    })
    .sort((a, b) => {
      if (a.fromKm && b.fromKm) {
        return a.fromKm - b.fromKm
      }
      return cleanedLocalCompare(a.name, b.name)
    })

const sortRailways = (deepRailways: DeepRailway[]): DeepRailway[] =>
  deepRailways
    .map(({ railwaySections, ...railway }) => ({ ...railway, railwaySections: sortRailwaySections(railwaySections) }))
    .sort((a, b) => a.name.localeCompare(b.name, config.STRING_LOCALE))

export const getDeepRailways = async () => {
  const railways = await callApiGet<DeepRailway[]>(RAILWAYS_DEEP, ApiType.APIM)
  return sortRailways(railways)
}
