import { DetailedAlarms } from './DetailedAlarms'
import { SwitchCardExpandedContent } from 'src/Features/Switches/SwitchCard/SwitchCardExpandedContent'

import type { AssetWithAlarms } from 'src/Types/AssetWithAlarms'
import type { DatePeriod } from 'src/Types/DatePeriod'

type OwnProps = {
  assetWithAlarms: AssetWithAlarms
  datePeriod: DatePeriod
  showAlarmsInAssetStatusWindows: boolean
}

export const SwitchAlarmsCard = ({ assetWithAlarms, datePeriod, showAlarmsInAssetStatusWindows }: OwnProps) => (
  <DetailedAlarms assetWithAlarms={assetWithAlarms} showAlarmsInAssetStatusWindows={showAlarmsInAssetStatusWindows}>
    <SwitchCardExpandedContent assetWithAlarms={assetWithAlarms} datePeriod={datePeriod} />
  </DetailedAlarms>
)
