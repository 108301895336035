import { useMutation } from 'react-query'
import { setSwitchHeatModus } from 'src/Providers/SwitchHeat'
import type { SensorTwinData } from 'src/Types/SensorTwin'
import { parseTwinModus } from 'src/Hooks/NetworkData/parseTwinModus'

/**
 * Hook to derive modus information from sensor twin data and get available moduses.
 */
export const useSwitchHeatModus = (sensorTwin: SensorTwinData | undefined) => {
  const modusTwin = sensorTwin?.sensors.find(sensor => sensor.codeSystem === 'CMD_MODE')
  const supportsModus = modusTwin?.requestValidation?.frontendControl
  const modusSensorId = modusTwin?.id
  const currentModus = parseTwinModus(modusTwin?.currentValue)
  const requestedModus = parseTwinModus(modusTwin?.requestedValue)
  const lastUpdated = modusTwin?.lastUpdated
  const eTag = modusTwin?.etag64

  return {
    supportsModus,
    currentModus,
    requestedModus,
    lastUpdated,
    modusSensorId,
    eTag,
  }
}

export const useSwitchHeatModusUpdate = () => {
  const { mutate, status } = useMutation(setSwitchHeatModus)
  return { mutate, status }
}
