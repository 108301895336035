import type { DeepRailway } from 'src/Types/DeepRailway'
import type { RailwayModel } from './types'

type OwnParams = {
  railways: DeepRailway[]
  pendingStretchLocationIds: string[]
  openRailwayId: string | undefined
  textSearchFilter?: string
}

export const getRailwaysViewModel = ({ railways, pendingStretchLocationIds, openRailwayId, textSearchFilter }: OwnParams) => {
  const trimmedSearchFilter = textSearchFilter?.trim().toLowerCase()

  let textSearchMatchCount = 0

  const searchInText = (text: string) => {
    if (!trimmedSearchFilter?.length) {
      return true
    }
    const isTextSearchMatch = text.toLowerCase().includes(trimmedSearchFilter)
    if (isTextSearchMatch) {
      textSearchMatchCount += 1
    }
    return isTextSearchMatch
  }

  const railwayModel = railways.map<RailwayModel>(railway => {
    const { railwaySections } = railway
    const isOpened = railway.railwayId === openRailwayId
    let isPendingRailway = false
    let isAllPendingRailway = true
    const railwaySearchMatch = searchInText(railway.name)
    let railwayTreeSearchMatch = railwaySearchMatch

    const railwaySectionModels = railwaySections.map(railwaySection => {
      const { locations } = railwaySection
      let isPendingSection = false
      let isAllPendingSection = true
      const railwaySectionSearchMatch = searchInText(railwaySection.name)
      let railwaySectionTreeSearchMatch = false
      if (railwaySectionSearchMatch) {
        railwayTreeSearchMatch = true
        railwaySectionTreeSearchMatch = true
      }

      const locationModels = locations.map(location => {
        const isPending = pendingStretchLocationIds.includes(location.locationId)
        const locationSearchMatch = searchInText(location.name)
        if (locationSearchMatch) {
          railwayTreeSearchMatch = true
          railwaySectionTreeSearchMatch = true
        }

        if (isPending) {
          isPendingSection = true
          isPendingRailway = true
        }

        if (!isPending) {
          isAllPendingSection = false
          isAllPendingRailway = false
        }

        return {
          ...location,
          isPending,
          isOpened,
          searchTreeMatch: locationSearchMatch,
          searchMatch: locationSearchMatch,
        }
      })

      return {
        ...railwaySection,
        locations: locationModels,
        isPending: isPendingSection,
        isAllPending: isAllPendingSection,
        isOpened,
        searchTreeMatch: railwaySectionTreeSearchMatch,
        searchMatch: railwaySectionSearchMatch,
      }
    })

    return {
      ...railway,
      railwaySections: railwaySectionModels,
      isPending: isPendingRailway,
      isAllPending: isAllPendingRailway,
      isOpened,
      searchTreeMatch: railwayTreeSearchMatch,
      searchMatch: railwaySearchMatch,
    }
  })

  return { railwayModel, textSearchMatchCount }
}
