import { useState } from 'react'
import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'

import { ExpandCollapseButton } from 'src/Components/ExpandCollapseButton'
import { SensorValue } from 'src/Components/AssetCard/Sensor/SensorValue'
import { CardInformationEntry } from 'src/Components/AssetCard/CardInformationEntry'
import { SubHeaderWithExpandableInfo } from 'src/Components/SubHeaderWithExpandableInfo'
import { Divider } from 'src/Components/Divider'

import type { SensorTwin } from 'src/Types/SensorTwin'
import type { Bender } from 'src/Types/Bender'

import { useBreakpointDown } from 'src/Hooks/useBreakpoint'
import { useLanguage } from 'src/Hooks/useLanguage'
import { formatLocalDateTime } from 'src/Utils/format'
import { SubHeaderKey } from 'src/Providers/Settings/SubHeader'

const leftCol = ['Device_inactive', 'Device_error']

const centerCol: string[] = []

const rightCol: string[] = []

const predefinedSensors = [...leftCol, ...centerCol, ...rightCol]

type OwnProps = {
  bender: Bender
  sensors: SensorTwin[]
}

export const BenderInfo = ({ bender, sensors }: OwnProps) => {
  const { t } = useLanguage()

  const isMediaMD = useBreakpointDown('md')

  const [expanded, setExpanded] = useState(false)

  const expandCollapseText = expanded ? t('benders.sensorInfo.hideMore') : t('benders.sensorInfo.showMore')

  const mapSensor = (codeSystem: string) => {
    const sensor = sensors.find(sensor => sensor.codeSystem === codeSystem)

    return sensor ? (
      <Grid key={codeSystem} item>
        <SensorValue codeSystem={codeSystem} sensor={sensor} domain="SEF" />
      </Grid>
    ) : undefined
  }

  return (
    <SubHeaderWithExpandableInfo title={t('benders.info')} isDefaultExpanded subHeaderKey={SubHeaderKey.BenderInfo}>
      <Grid container alignItems="flex-start">
        <Grid container direction="column" wrap="nowrap" item sm={12} md={6} lg={4}>
          {leftCol.map(mapSensor)}
        </Grid>
        <Grid container direction="column" wrap="nowrap" item xs={12} sm={12} md={6} lg={4}>
          <Grid item>
            {isMediaMD && <Divider margin={4} />}
            <CardInformationEntry
              label={t('general.installDate')}
              value={bender.installDate ? formatLocalDateTime(bender.installDate, true) : t('benders.sensorInfo.unknown')}
              longValue
            />
          </Grid>
          {rightCol.map(mapSensor)}
        </Grid>
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider margin={4} />
        <Grid container>
          {sensors
            .filter(sensor => !predefinedSensors.includes(sensor.codeSystem))
            .map(sensor => sensor.codeSystem)
            .map(codeSystem => (
              <Grid item xs={12} sm={12} md={6} lg={4} key={codeSystem}>
                {mapSensor(codeSystem)}
              </Grid>
            ))}
        </Grid>
      </Collapse>
      <Grid container justifyContent="center">
        <Grid item>
          <ExpandCollapseButton
            isExpanded={expanded}
            onToggleExpand={() => setExpanded(isExpanded => !isExpanded)}
            text={expandCollapseText}
          />
        </Grid>
      </Grid>
      <Divider margin={4} />
    </SubHeaderWithExpandableInfo>
  )
}
