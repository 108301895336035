import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import PendingIcon from '@mui/icons-material/HelpOutlined'
import TrainIcon from '@mui/icons-material/Train'

import { ignoreStyleProps } from 'src/Utils/style'
import themeColors from 'src/theme'

type AlarmSize = 'large' | 'small'

const StyledTrainIcon = styled(TrainIcon, ignoreStyleProps('iconSize'))<{ iconSize: AlarmSize }>`
  margin: ${props => (props.iconSize === 'small' ? '-10px 0 -5px 5px' : '-5px 0 -5px')};
  vertical-align: middle;
  color: ${themeColors.success};
`

const StyledPendingIcon = styled(PendingIcon, ignoreStyleProps('iconSize'))<{ iconSize: AlarmSize }>`
  color: ${themeColors.disabledText};
  vertical-align: text-bottom;
  margin-left: ${props => (props.iconSize === 'small' ? '5px' : 0)};
`

type OwnProps = {
  indicator: 'Ok' | 'Pending'
  size?: AlarmSize
}

export const NoAlarmIndicator = ({ indicator, size = 'small' }: OwnProps) => {
  const { t } = useTranslation()

  return indicator === 'Ok' ? (
    <span title={t('switches.statusLabels.alarms.noAlarms')}>
      <StyledTrainIcon iconSize={size} fontSize={size} />
    </span>
  ) : (
    <StyledPendingIcon iconSize={size} fontSize={size} titleAccess={t('switches.statusLabels.alarms.pendingAlarms')} />
  )
}
