import { useEffect, useState, useCallback, useMemo } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import { SwitchHeatChart } from './SwitchHeatChart/SwitchHeatChart'
import { SubHeaderWithExpandableInfo } from 'src/Components/SubHeaderWithExpandableInfo'
import { NoResult } from 'src/Components/NoResult'

import type { AssetWithAlarms } from 'src/Types/AssetWithAlarms'
import type { DatePeriod } from 'src/Types/DatePeriod'
import type { SwitchHeatCabinet } from 'src/Types/SwitchHeatCabinet'

import { useDomainConstantsStore } from 'src/Store/domainConstants'
import { useAccess } from 'src/Hooks/useAccess'
import { useSwitchHeatSensorData } from 'src/Hooks/NetworkData/useSwitchHeatSensorData'
import { useLanguage } from 'src/Hooks/useLanguage'
import { SwitchHeatElements } from 'src/Features/SwitchHeat/SwitchHeatElements'
import { mapToSwitchHeatElementsData } from 'src/Features/SwitchHeat/mapToSwitchHeatElementsData'
import { useSwitchHeatVersion } from 'src/Hooks/useSwitchHeatVersion'
import { SubHeaderKey } from 'src/Providers/Settings/SubHeader'
import { TemperatureFormContainer } from 'src/Features/SwitchHeat/Adjustment/TemperatureFormContainer'
import { getCardIdByAsset } from 'src/Utils/cardId'
import { SwitchHeatTab } from 'src/Features/SwitchHeat/SwitchHeatTab'
import { getTabsModel } from 'src/Features/SwitchHeat/TabModel'
import { SwitchHeatGroupInfo } from 'src/Features/SwitchHeat/SwitchHeatGroupInfo'
import { styled } from '@mui/material/styles'

type OwnProps = {
  assetWithAlarms: AssetWithAlarms
  datePeriod: DatePeriod
}

const StyledDiv = styled('div')`
  padding: 16px 16px 4px 16px;
`

const SWITCH_HEAT_POLLING_INTERVAL = 20000
const SWITCH_HEAT_POLLING_INTERVAL_FAST = 1000

export const SwitchHeatCardExpandedContent = ({ assetWithAlarms, datePeriod }: OwnProps) => {
  const { t, currentLanguage } = useLanguage()

  const sensorCodes = useDomainConstantsStore(state => state.sensorCodes.sensorCodes.SVV)
  const hasWriteAccess = useAccess('SwitchHeat.Write')

  const asset = assetWithAlarms.asset as SwitchHeatCabinet

  const version = useSwitchHeatVersion()
  const {
    switchHeatSensorData,
    status,
    refetch: refetchSwitchHeatSensorData,
  } = useSwitchHeatSensorData(asset.baneDataId, { version })

  const [isFormUpdating, setIsFormUpdating] = useState(false)

  useEffect(() => {
    const timeout = isFormUpdating ? SWITCH_HEAT_POLLING_INTERVAL_FAST : SWITCH_HEAT_POLLING_INTERVAL
    const intervalId = window.setInterval(() => refetchSwitchHeatSensorData(), timeout)

    return () => {
      if (intervalId) {
        window.clearInterval(intervalId)
      }
    }
  }, [refetchSwitchHeatSensorData, isFormUpdating])

  const handleUpdatingChange = useCallback((updating: boolean) => {
    setIsFormUpdating(updating)
  }, [])

  const parametersGroups = useMemo(() => getTabsModel(sensorCodes, currentLanguage), [currentLanguage, sensorCodes])
  const formParameterGroup = parametersGroups.find(pg => pg.sortOrder === 1)
  const otherParameterGroups = parametersGroups.filter(pg => pg.sortOrder !== 1)

  if (status === 'idle') {
    return null
  }

  if (status === 'error') {
    return <NoResult>{t('switchHeat.sensorInfo.fetchError')}</NoResult>
  }

  if (status === 'loading') {
    return (
      <Box mt={2} ml={2}>
        <CircularProgress color="inherit" size={15} /> {t('switchHeat.sensorInfo.loading')}
      </Box>
    )
  }

  const allSensors = switchHeatSensorData?.sensors || []
  if (!switchHeatSensorData || !allSensors.length) {
    return <NoResult>{t('switchHeat.sensorInfo.noneFound')}</NoResult>
  }

  const setpointSwitchRodValue = allSensors.find(s => s.codeSystem === 'SetpointSwitchRod')?.currentValue

  return (
    <>
      <StyledDiv>
        <SwitchHeatGroupInfo assetWithAlarms={assetWithAlarms} />
      </StyledDiv>
      <TemperatureFormContainer
        switchHeatSensorData={switchHeatSensorData}
        refetchSwitchHeatSensorData={refetchSwitchHeatSensorData}
        cardId={getCardIdByAsset(asset)}
        assetName={asset.name}
        onUpdatingChange={handleUpdatingChange}
        hasWriteAccess={hasWriteAccess}
        sensorCodes={sensorCodes}
        columns={formParameterGroup?.columns || []}
      />
      <SubHeaderWithExpandableInfo
        title={t('switchHeat.chart.title')}
        isDefaultExpanded
        subHeaderKey={SubHeaderKey.SwitchHeatChart}
      >
        <SwitchHeatChart
          switchHeatCabinet={asset}
          datePeriod={datePeriod}
          hasSetpointSwitchRodValue={typeof setpointSwitchRodValue === 'number'}
        />
      </SubHeaderWithExpandableInfo>
      <SwitchHeatElements tabs={mapToSwitchHeatElementsData(asset, assetWithAlarms.alarms, t, currentLanguage)} />
      {otherParameterGroups.map(parameterGroup => (
        <SwitchHeatTab
          sensors={allSensors}
          columns={parameterGroup.columns}
          title={parameterGroup.label}
          key={parameterGroup.sortOrder}
          index={parameterGroup.sortOrder}
          sensorCodes={sensorCodes}
        />
      ))}
    </>
  )
}
