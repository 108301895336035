import * as d3 from 'd3'
import * as d3zoom from 'd3-zoom'
import { nanoid } from 'nanoid'
import { format } from 'date-fns/format'
import { areIntervalsOverlapping } from 'date-fns/areIntervalsOverlapping'

import type {
  ChartFocusUpdatedEventHandler,
  ChartLabels,
  Extent,
  LinearNumberScale,
  LinearTimeScale,
  NumberInterval,
  Rect,
  SimpleSelection,
  SVGGSelection,
  SVGRectSelection,
} from 'src/Types/ChartTypes'
import type { AlarmType } from 'src/Types/AlarmType'
import type { NormalizedAlarm } from 'src/Types/NormalizedAlarm'
import type { TrackCircuitCurrentMeasurementAugmented, TrackCircuitCurrents } from 'src/Types/TrackCircuitCurrentTypes'
import { TrackCircuitCurrentType } from 'src/Types/TrackCircuitCurrentTypes'
import { Language } from 'src/Types/LanguageTypes'
import type { Point } from 'src/Types/Geometry'

import { clamp, maxNum, minNum } from 'src/Utils/number'
import { createTimeMultiFormat } from 'src/Utils/createTimeMultiFormat'
import { getD3Locale } from 'src/Language/d3Locale'
import { formatNumber } from 'src/Utils/format'
import {
  getChartLineColorByIndex,
  getTooltipConfig,
  getTooltipPosition,
  tooltipArrowBoxWidth,
  tooltipArrowPolygon,
} from 'src/Utils/chart'
import { createDiagonalStripePattern, resetZoomButton, zoomInButton, zoomOutButton } from 'src/Components/Chart/chartStyles'
import themeColors from 'src/theme'
import { getTooltipMarkup } from 'src/Components/Chart/ChartRenderer'
import { enablePageScrollForChartRenderer } from 'src/Components/Chart/enablePageScrollForChartRenderer'
import { arrayMinMax } from 'src/Utils/arrayMinMax'
import { isInsideTimeSpan } from 'src/Utils/LevelOfDetails/timeSpan'

type ChartProps = {
  chart: HTMLDivElement
  classes: {
    tooltip: string
    tooltipArrow: string
    zoomButton: string
  }
  timeline?: NumberInterval
  padding?: NumberInterval
  onZoomUpdated?: ChartFocusUpdatedEventHandler
  onChartClicked?: SensorDataChartRenderer['onChartClicked']
  doubleClickThreshold?: number
}

const OPTIMIZE_CURRENTS_DATA = true
const Y_UNIT = 'mA'
const Y_CURRENT_THRESHOLD = 0

const optimizeCurrentsData = (data: TrackCircuitCurrentMeasurementAugmented[]) =>
  data.filter((entry, i, arr) => {
    // Always return the first and last entries or if the measurement is inside a gap
    if (i === 0 || i === arr.length - 1 || entry[2]) {
      return true
    }

    // Optimize by skipping entries with similar measurements as the next.
    // If the rendered step algorithm is changed to curveStepAfter, this should
    // compare against the previous entry instead
    const nextEntry = arr[i + 1]
    const diff = Math.abs(entry[1] - nextEntry[1])
    return diff > Y_CURRENT_THRESHOLD
  })

const parseCurrentsDataParts = (
  sensorData: TrackCircuitCurrents[],
  currentsField: 'rcMeasurements' | 'fcMeasurements',
  missingCurrentsField: 'missingRcMeasurements' | 'missingFcMeasurements'
) => {
  const currentsData = sensorData.map(tcc => tcc[currentsField])
  const missingData = sensorData.map(tcc => tcc[missingCurrentsField])

  return currentsData.map((measurements, i) => {
    const gaps = missingData[i]
    const currentsDataAugmented = measurements.map<TrackCircuitCurrentMeasurementAugmented>(measurement => {
      const timestamp = measurement[0]
      const currentMeasurement = measurement[1]
      return [timestamp, currentMeasurement, isInsideGap(timestamp, gaps)]
    })
    const augmented = [[...currentsDataAugmented]]
    return !OPTIMIZE_CURRENTS_DATA ? augmented : augmented.map(optimizeCurrentsData)
  })
}

const isInsideGap = (timestamp: number, gaps: [number, number][]) => gaps.some(gap => timestamp >= gap[0] && timestamp <= gap[1])

const parseAlarms = (alarms: NormalizedAlarm[]) =>
  alarms.reduce<NormalizedAlarm[]>((acc, alarm) => {
    // Attempt to join sequential alarms
    const prev = acc[acc.length - 1]
    let add = true
    if (prev?.toDateTime === alarm.fromDateTime) {
      add = false
      prev.toDateTime = alarm.toDateTime
    }

    if (add) {
      acc.push({ ...alarm })
    }

    return acc
  }, [])

const cullData = (data: [number, number, boolean][], left: number, right: number) => {
  const firstIndex = data.findIndex((_, i) => data[i + 1] && data[i + 1][0] >= left)

  const lastIndex = data
    .slice()
    .reverse()
    .findIndex((_, i) => data[i] && data[i][0] >= right)

  // If not found, use the rest of the array
  const lastEntry = lastIndex === -1 ? data.length : lastIndex + 1

  if (firstIndex !== -1) {
    return data.slice(firstIndex, lastEntry)
  }

  return []
}

const formatTick = (unit: string, decimals: number, value: number) => `${formatNumber(value, decimals, false)} ${unit}`

const formatYTick = (value: number) => formatTick(Y_UNIT, 0, value)

enum ElementClass {
  canvas = 'canvas',
  alarm = 'alarm',
  alarmText = 'alarm-text',
  tooltipPoint = 'tooltip-point',
  tooltipArrow = 'tooltip-arrow',
  tooltipPoints = 'tooltip-points',
  zoomInButton = 'zoom-in-button',
  zoomOutButton = 'zoom-out-button',
  resetZoomButton = 'reset-zoom-button',
  paddingArea = 'padding-area',
  paddingInfo = 'padding-info',
}
const classSelector = (key: ElementClass) => `.${key}`

const CHART_MARGIN: Rect = { top: 36, right: 0, bottom: 20, left: 56 }
const CHART_HEIGHT = 400
const ZOOM_BUTTON_MARGIN = 10
const MIN_ZOOM = 1
const MAX_ZOOM_MAGIC_CONSTANT = 50000 // This magic number results in a nice max zoom resolution
const X_PIXELS_PER_TICK = 90
const PIXEL_RATIO = 1
const ALARM_TEXT_FONT_SIZE = 14
const ANNOTATION_LINE_HEIGHT = 16
const ANNOTATION_OFFSET = ANNOTATION_LINE_HEIGHT + 4
const ANNOTATION_SPACING = 4
const MIN_ALARM_WIDTH = 2
const TOOLTIP_DATE_FORMAT = 'dd.MM.yyyy HH:mm:ss'
const TOOLTIP_POINT_RADIUS = 4
const ZOOM_BUTTON_SPACING = 35

export class SensorDataChartRenderer {
  chart: HTMLDivElement

  chartWidth: number

  timeline?: NumberInterval

  padding: NumberInterval

  paddingPatternId: string

  sensorData: TrackCircuitCurrents[] = []

  currentType: TrackCircuitCurrentType = TrackCircuitCurrentType.RC

  currentsDataAugmented: TrackCircuitCurrentMeasurementAugmented[][] = []

  currentsDataPartsAugmented: TrackCircuitCurrentMeasurementAugmented[][][] = []

  alarms?: NormalizedAlarm[]

  alarmType?: AlarmType

  currentLanguage: Language = Language.en

  svgContainer: SimpleSelection<SVGSVGElement>

  canvas: SimpleSelection<HTMLCanvasElement>

  canvasContext: CanvasRenderingContext2D

  offScreenCanvas: HTMLCanvasElement

  offScreenContext: CanvasRenderingContext2D

  xScale: LinearTimeScale

  yScale: LinearNumberScale

  currentXScale: LinearTimeScale

  currentYScale: LinearNumberScale

  xAxis: SVGGSelection

  yAxis: SVGGSelection

  yAxisGrid: SVGGSelection

  zoom: d3.ZoomBehavior<SVGSVGElement, unknown>

  showZoomButtons = true

  onZoomUpdated?: ChartFocusUpdatedEventHandler

  disableNextZoomPropagation: boolean = false

  clipUrl: string

  svgClipPath: SVGRectSelection

  mouseOverlay: SVGRectSelection

  tooltip: SimpleSelection<HTMLDivElement>

  tooltipArrow: SimpleSelection<SVGSVGElement>

  tooltipPoints: SVGGSelection

  paddingText?: SimpleSelection<SVGTextElement>

  multiTimeFormat?: ReturnType<typeof createTimeMultiFormat>

  inactiveTrackCircuits: number[] = []

  onChartClicked?: (dateTime: number, mousePosition: Point) => void

  doubleClickThreshold: number

  doubleClickTimer?: number

  fromDate?: number

  toDate?: number

  constructor({
    chart,
    classes,
    timeline,
    padding = {
      start: 0,
      end: 0,
    },
    onZoomUpdated,
    onChartClicked,
    doubleClickThreshold = 0,
  }: ChartProps) {
    this.chart = chart
    this.chartWidth = 0
    this.timeline = timeline
    this.padding = padding
    this.paddingPatternId = `padding-pattern-${nanoid()}`

    this.xScale = d3.scaleTime()

    this.yScale = d3.scaleLinear().range([CHART_HEIGHT - CHART_MARGIN.bottom, CHART_MARGIN.top])

    this.currentXScale = this.xScale
    this.currentYScale = this.yScale

    this.onChartClicked = onChartClicked
    this.doubleClickThreshold = doubleClickThreshold

    this.zoom = d3zoom.zoom<SVGSVGElement, any>().on('zoom', event => this.onZoom(event))

    this.onZoomUpdated = onZoomUpdated

    const canvasHeight = Math.floor(CHART_HEIGHT * PIXEL_RATIO)

    this.canvas = d3
      .select(this.chart)
      .html(null)
      .append('canvas')
      .style('height', `${CHART_HEIGHT}px`)
      .attr('height', canvasHeight)
      .style('width', '10px')
      .attr('width', 10)
      .style('position', 'absolute')
      .style('pointer-events', 'none')
      .classed(ElementClass.canvas, true)

    this.canvasContext = this.canvas.node()!.getContext('2d')!
    this.canvasContext.scale(PIXEL_RATIO, PIXEL_RATIO)

    this.offScreenCanvas = document.createElement('canvas')
    this.offScreenCanvas.height = canvasHeight
    this.offScreenContext = this.offScreenCanvas.getContext('2d')!
    this.offScreenContext.scale(PIXEL_RATIO, PIXEL_RATIO)

    this.svgContainer = d3.select(this.chart).append('svg').attr('height', CHART_HEIGHT)

    const clipId = nanoid()
    this.clipUrl = `url(#${clipId})`

    this.svgClipPath = this.svgContainer
      .append('clipPath')
      .attr('id', clipId)
      .append('rect')
      .attr('x', CHART_MARGIN.left + 1)
      .attr('y', CHART_MARGIN.top)
      .attr('height', CHART_HEIGHT - CHART_MARGIN.top - CHART_MARGIN.bottom)

    this.xAxis = this.svgContainer.append('g').attr('transform', `translate(0,${CHART_HEIGHT - CHART_MARGIN.bottom})`)

    this.yAxisGrid = this.svgContainer
      .append('g')
      .attr('transform', `translate(${CHART_MARGIN.left},0)`)
      .attr('color', themeColors.disabled)

    this.yAxis = this.svgContainer.append('g').attr('transform', `translate(${CHART_MARGIN.left},0)`)

    this.mouseOverlay = this.svgContainer
      .append('rect')
      .attr('x', CHART_MARGIN.left + 1)
      .attr('y', CHART_MARGIN.top)
      .attr('height', CHART_HEIGHT - CHART_MARGIN.top - CHART_MARGIN.bottom)
      .style('fill', 'none')
      .style('pointer-events', 'all')
      .on('mouseout', () => this.hideTooltip())
      .on('mousemove', event => this.mouseMove(event as MouseEvent))
      .on('click', event => this.overlayClicked(event as MouseEvent))

    enablePageScrollForChartRenderer(this.svgContainer, this.zoom)

    if (onChartClicked) {
      this.mouseOverlay.style('cursor', 'context-menu')
    }

    d3.select(this.chart)
      .append('button')
      .classed(ElementClass.resetZoomButton, true)
      .classed(classes.zoomButton, true)
      .style('right', `${CHART_MARGIN.right + ZOOM_BUTTON_MARGIN}px`)
      .style('bottom', `${CHART_MARGIN.bottom + 2 * ZOOM_BUTTON_SPACING + ZOOM_BUTTON_MARGIN}px`)
      .html(resetZoomButton)
      .on('click', () => this.resetZoom(true))

    d3.select(this.chart)
      .append('button')
      .classed(ElementClass.zoomInButton, true)
      .classed(classes.zoomButton, true)
      .style('right', `${CHART_MARGIN.right + ZOOM_BUTTON_MARGIN}px`)
      .style('bottom', `${CHART_MARGIN.bottom + ZOOM_BUTTON_SPACING + ZOOM_BUTTON_MARGIN}px`)
      .html(zoomInButton)
      .on('click', () => this.zoom.scaleBy(this.svgContainer.transition().duration(250), 1.5))

    d3.select(this.chart)
      .append('button')
      .classed(ElementClass.zoomOutButton, true)
      .classed(classes.zoomButton, true)
      .style('right', `${CHART_MARGIN.right + ZOOM_BUTTON_MARGIN}px`)
      .style('bottom', `${CHART_MARGIN.bottom + ZOOM_BUTTON_MARGIN}px`)
      .html(zoomOutButton)
      .on('click', () => this.zoom.scaleBy(this.svgContainer.transition().duration(250), 0.5))

    this.tooltip = d3.select(this.chart).append('div').classed(classes.tooltip, true).style('pointer-events', 'none')

    this.tooltipPoints = this.svgContainer
      .append('g')
      .classed(ElementClass.tooltipPoints, true)
      .style('pointer-events', 'none')
      .attr('clip-path', this.clipUrl)

    this.tooltipArrow = d3
      .select(this.chart)
      .append('svg')
      .style('position', 'absolute')
      .attr('height', getTooltipConfig().arrowHeight)
      .style('pointer-events', 'none')

    this.tooltipArrow.append('polygon').classed(classes.tooltipArrow, true).classed(ElementClass.tooltipArrow, true)

    if (this.hasPadding()) {
      this.svgContainer
        .append('defs')
        .html(createDiagonalStripePattern(1, 8, themeColors.chartColors.padding, this.paddingPatternId))

      this.paddingText = this.svgContainer
        .append('text')
        .classed(ElementClass.paddingInfo, true)
        .attr('font-size', ALARM_TEXT_FONT_SIZE)
        .attr('fill', themeColors.primary)
        .attr('dominant-baseline', 'hanging')
        .attr('y', ANNOTATION_OFFSET)
    }

    this.hideTooltip()
    this.sizeUpdated()
  }

  private getDomain() {
    if (this.currentsDataAugmented.length === 0) {
      return {
        xMin: this.fromDate || Date.now(),
        xMax: this.toDate || Date.now(),
        yMax: 0,
        yMin: 0,
      }
    }

    const yMaxVals = this.currentsDataAugmented.map(tcc => maxNum(tcc.map(d => d[1])))

    let xRange = this.timeline
    if (!xRange) {
      const xMinVals = this.currentsDataAugmented.map(tcc => (tcc.length ? tcc[0][0] : 0))
      const xMaxVals = this.currentsDataAugmented.map(tcc => (tcc.length ? tcc[tcc.length - 1][0] : 0))

      if (this.fromDate && this.toDate) {
        xMinVals.push(this.fromDate)
        xMaxVals.push(this.toDate)
      }

      xRange = {
        start: minNum(xMinVals),
        end: maxNum(xMaxVals),
      }
    }

    return {
      xMin: xRange.start,
      xMax: xRange.end,
      yMax: maxNum(yMaxVals),
      yMin: 0,
    }
  }

  private hideTooltip() {
    this.tooltipPoints.style('display', 'none')
    this.tooltip.style('display', 'none')
    this.tooltipArrow.style('display', 'none')
  }

  private updateTooltip(mouseX: number) {
    const xScale = this.currentXScale
    const yScale = this.currentYScale

    const overlayX = Math.round(clamp(mouseX, 0, this.chartWidth))
    const timelineMillis = xScale.invert(overlayX).valueOf()
    const dataValues = this.currentsDataAugmented.flatMap((cd, i) => {
      const parts = this.currentsDataPartsAugmented[i]
      const hasActualValue = parts.some(measurements => {
        const { min, max } = arrayMinMax(measurements.map(m => m[0]))
        if (!min || !max) {
          return true
        }
        return isInsideTimeSpan(timelineMillis, [min, max])
      })
      if (!hasActualValue) {
        return []
      }
      const dataPoint = cd.find(([x]) => x >= timelineMillis)
      // Not found or before first entry
      if (!dataPoint || timelineMillis < cd[0][0] || this.inactiveTrackCircuits.includes(i)) {
        return []
      }

      const { baneDataLocationName, tcId } = this.sensorData[i]
      return {
        value: dataPoint[1],
        tcLabel: `${baneDataLocationName} ${tcId}`,
        index: i,
      }
    })

    const renderX = xScale(timelineMillis)
    const targetY = this.currentsDataAugmented.length > 1 ? d3.max(dataValues, d => d.value) : d3.mean(dataValues, d => d.value)
    const renderY = yScale(targetY || 0)

    if (renderX !== undefined && renderY !== undefined) {
      this.tooltipPoints
        .selectAll<SVGCircleElement, TrackCircuitCurrents>(classSelector(ElementClass.tooltipPoint))
        .attr('transform', (_, i) => `translate(${renderX},${yScale(dataValues[i]?.value || 0)})`)
        .style('display', (_, i) => (dataValues[i] ? null : 'none'))

      const tooltipText = dataValues
        .map(val => {
          const text = `${val.tcLabel}: ${val.value} ${Y_UNIT}`
          return `<li style="color: ${getChartLineColorByIndex(val.index)}"><span>${text}</span></li>`
        })
        .join('')

      this.tooltip.html(getTooltipMarkup(format(timelineMillis, TOOLTIP_DATE_FORMAT), tooltipText, dataValues.length))

      const tooltipPosition = getTooltipPosition(renderX, renderY, this.tooltip.node()!, this.chartWidth, {
        chartMargin: CHART_MARGIN,
      })

      this.tooltip.style('left', `${tooltipPosition.box.x}px`).style('top', `${tooltipPosition.box.y}px`)

      const arrowPos = tooltipPosition.arrow
      const tipOffset = arrowPos.tipX - arrowPos.x
      this.tooltipArrow
        .attr('width', tooltipArrowBoxWidth(tipOffset))
        .style('left', `${Math.min(arrowPos.x, arrowPos.tipX)}px`)
        .style('top', `${arrowPos.y}px`)
        .select(classSelector(ElementClass.tooltipArrow))
        .attr('points', tooltipArrowPolygon(tipOffset))
    }
  }

  private mouseMove(event: MouseEvent) {
    const overlay = this.mouseOverlay.node()
    if (this.currentsDataAugmented.length && overlay) {
      const mouseX = d3.pointer(event, overlay)[0]
      this.tooltipPoints.style('display', null)
      this.tooltip.style('display', null)
      this.tooltipArrow.style('display', null)
      this.updateTooltip(mouseX)
    }
  }

  private performClick(mouseX: number, mousePos: Point) {
    if (this.onChartClicked) {
      const overlayX = Math.round(clamp(mouseX, 0, this.chartWidth))
      const timelineMillis = this.currentXScale.invert(overlayX).valueOf()
      this.onChartClicked(timelineMillis, mousePos)
    }
  }

  private resetDoubleClick() {
    clearTimeout(this.doubleClickTimer)
    this.doubleClickTimer = undefined
  }

  private overlayClicked(event: MouseEvent) {
    const overlay = this.mouseOverlay.node()
    if (this.currentsDataAugmented.length && overlay && this.onChartClicked) {
      if (this.doubleClickTimer) {
        this.resetDoubleClick()
      } else {
        const mouseX = d3.pointer(event, overlay)[0]
        const globalMousePos = {
          x: event.clientX,
          y: event.clientY,
        }

        this.doubleClickTimer = window.setTimeout(() => {
          this.performClick(mouseX, globalMousePos)
          this.resetDoubleClick()
        }, this.doubleClickThreshold)
      }
    }
  }

  private getAlarmColor() {
    return this.alarmType?.classification.id === 'Alarm'
      ? themeColors.chartColors.alarms.error
      : themeColors.chartColors.alarms.warning
  }

  getAlarmTextColor() {
    return this.alarmType?.classification.id === 'Alarm'
      ? themeColors.chartColors.alarmText.error
      : themeColors.chartColors.alarmText.warning
  }

  getCurrentsField() {
    return this.currentType === TrackCircuitCurrentType.RC ? 'rcMeasurements' : 'fcMeasurements'
  }

  getMissingCurrentsField() {
    return this.currentType === TrackCircuitCurrentType.RC ? 'missingRcMeasurements' : 'missingFcMeasurements'
  }

  setData(
    sensorData: TrackCircuitCurrents[],
    currentType: TrackCircuitCurrentType,
    alarms?: NormalizedAlarm[],
    alarmType?: AlarmType
  ) {
    this.currentType = currentType
    const currentsField = this.getCurrentsField()
    this.sensorData = sensorData.filter(sd => !!sd[currentsField].length)
    this.currentsDataPartsAugmented = parseCurrentsDataParts(this.sensorData, currentsField, this.getMissingCurrentsField())
    this.currentsDataAugmented = this.currentsDataPartsAugmented.map(partials => partials.flatMap(partial => partial))

    this.alarms = alarms ? parseAlarms(alarms) : undefined
    this.alarmType = alarmType

    const domain = this.getDomain()
    if (domain.xMin !== domain.xMax) {
      this.xScale.domain([domain.xMin, domain.xMax])
      this.yScale.domain([domain.yMin, domain.yMax]).nice()
      this.zoom.scaleExtent([MIN_ZOOM, (domain.xMax - domain.xMin) / MAX_ZOOM_MAGIC_CONSTANT])
    }
  }

  setInactiveTrackCircuits(inactiveTrackCircuits: number[]) {
    this.inactiveTrackCircuits = inactiveTrackCircuits
    this.update()
  }

  setLabels(labels: ChartLabels, currentLanguage: Language) {
    d3.select(this.chart).select(classSelector(ElementClass.resetZoomButton)).attr('title', labels.resetZoom)

    d3.select(this.chart).select(classSelector(ElementClass.zoomInButton)).attr('title', labels.zoomIn)

    d3.select(this.chart).select(classSelector(ElementClass.zoomOutButton)).attr('title', labels.zoomOut)

    if (this.paddingText) {
      this.paddingText.text(labels.paddingInfo)
    }

    this.currentLanguage = currentLanguage
    this.multiTimeFormat = createTimeMultiFormat(getD3Locale(currentLanguage))
  }

  setZoom(zoom: d3.ZoomTransform) {
    this.disableNextZoomPropagation = true
    this.zoom.transform(this.svgContainer.transition().duration(0), zoom)
  }

  setShowZoomButtons(showZoomButtons: boolean) {
    this.showZoomButtons = showZoomButtons
  }

  private getChartWidth() {
    const parent = this.chart.parentElement
    return parent ? parent.clientWidth : 0
  }

  sizeUpdated() {
    const newWidth = Math.max(0, this.getChartWidth())
    if (newWidth !== this.chartWidth && newWidth > 0) {
      this.chartWidth = newWidth

      const clipWidth = this.chartWidth - CHART_MARGIN.left - CHART_MARGIN.right - 1
      const canvasWidth = Math.floor(this.chartWidth * PIXEL_RATIO)

      this.canvas.style('width', `${this.chartWidth}px`).attr('width', canvasWidth)

      this.canvasContext.beginPath()
      this.canvasContext.rect(
        CHART_MARGIN.left,
        CHART_MARGIN.top,
        this.chartWidth - CHART_MARGIN.left - CHART_MARGIN.right,
        CHART_HEIGHT - CHART_MARGIN.top - CHART_MARGIN.bottom
      )
      this.canvasContext.clip()

      this.offScreenCanvas.width = canvasWidth

      this.svgContainer.attr('viewBox', [0, 0, this.chartWidth, CHART_HEIGHT].toString()).attr('width', this.chartWidth)

      this.xScale.range([CHART_MARGIN.left, this.chartWidth - CHART_MARGIN.right])

      this.svgClipPath.attr('width', Math.max(this.chartWidth - CHART_MARGIN.left - CHART_MARGIN.right, 0))

      const extent: Extent = [
        [CHART_MARGIN.left, CHART_MARGIN.top],
        [this.chartWidth - CHART_MARGIN.right, CHART_HEIGHT - CHART_MARGIN.bottom],
      ]

      this.zoom.translateExtent(extent).extent(extent)

      this.resetZoom()

      this.mouseOverlay.attr('width', Math.max(clipWidth, 0))
    }
  }

  containedIntervalWidth(toDraw: NumberInterval, visible: NumberInterval) {
    // If the interval extends outside the current rendered interval, cut it in the relevant ends
    const xScale = this.currentXScale
    const scaledToDate = xScale(toDraw.end)!
    const scaledFromDate = xScale(toDraw.start)!
    const fullWidth = Math.max(scaledToDate - scaledFromDate, MIN_ALARM_WIDTH)
    const leftCutoff = Math.max(xScale(visible.start)! - scaledFromDate, 0)
    const rightCutoff = Math.max(scaledToDate - xScale(visible.end)!, 0)
    return fullWidth - leftCutoff - rightCutoff
  }

  private renderCurrent(data: [number, number, boolean][], xScale: LinearTimeScale, context: CanvasRenderingContext2D) {
    for (let i = 1; i < data.length; i++) {
      // Look at AB and then BC etc
      const [prevX, prevY, prevIsCircle] = data[i - 1]
      const [thisX, thisY, thisIsCircle] = data[i]

      context.beginPath()
      if (!prevIsCircle && !thisIsCircle) {
        // Draw line segment
        this.cornerStepAfter(thisX, thisY, prevX!, prevY!, xScale, context)
        context.stroke()
      } else {
        if (prevIsCircle) {
          // Draw the prev circle
          context.arc(xScale(prevX)!, this.yScale(prevY)!, 3, 0, 2 * Math.PI)
        } else if (thisIsCircle && i + 1 === data.length) {
          // Draw the last edge circle then
          context.arc(xScale(thisX)!, this.yScale(thisY)!, 3, 0, 2 * Math.PI)
        }
        context.fillStyle = context.strokeStyle
        context.fill()
      }
    }
  }

  /**
   * Makes L/corner-shaped curve between two consecutive data points
   * that has
   * the vertical line on the left side
   * followed by (on the right side)
   * the horizontal line having the current/after step's y value
   */
  private cornerStepAfter(
    thisX: number,
    thisY: number,
    prevX: number,
    prevY: number,
    xScale: LinearTimeScale,
    context: CanvasRenderingContext2D
  ) {
    // Vertical line
    context.moveTo(xScale(prevX)!, this.yScale(prevY)!)
    context.lineTo(xScale(prevX)!, this.yScale(thisY)!)

    // Horizontal line
    context.moveTo(xScale(prevX)!, this.yScale(thisY)!)
    context.lineTo(xScale(thisX)!, this.yScale(thisY)!)
  }

  private renderCurrents(left: number = -Infinity, right: number = Infinity) {
    const ctx = this.offScreenContext

    ctx.clearRect(0, 0, this.chartWidth + 1, CHART_HEIGHT)
    this.currentsDataPartsAugmented.forEach((partial, partsIndex) => {
      partial.forEach(cd => {
        if (!this.inactiveTrackCircuits.includes(partsIndex)) {
          const culledData = cullData(cd, left, right)
          ctx.beginPath()
          ctx.lineWidth = 1
          ctx.strokeStyle = getChartLineColorByIndex(partsIndex)
          this.renderCurrent(culledData, this.currentXScale, ctx)
        }
      })
    })

    this.canvasContext.clearRect(0, 0, this.chartWidth, CHART_HEIGHT)
    this.canvasContext.drawImage(this.offScreenCanvas, 0, 0)
  }

  private hasPadding() {
    return this.padding.start || this.padding.end
  }

  private getPaddingTextWidth() {
    if (!this.hasPadding()) {
      return 0
    }

    const node = this.paddingText?.node()
    return node ? node.getBBox().width : 0
  }

  updateAlarms(left: number, right: number) {
    if (this.alarms) {
      const xScale = this.currentXScale

      const visibleInterval: NumberInterval = { start: left, end: right }

      // Only render alarms within the render area, and fit them within the bounds for performance reasons.
      // Without this optimization, zooming far in cane make SVGs no longer render
      // because of the resulting extremely wide shapes
      const onScreen = this.alarms.filter(alarm =>
        areIntervalsOverlapping({ start: alarm.fromDateTime, end: alarm.toDateTime }, visibleInterval)
      )

      const alarmElements = this.svgContainer.selectAll<SVGRectElement, NormalizedAlarm>(classSelector(ElementClass.alarm))

      alarmElements
        .filter(d => areIntervalsOverlapping(visibleInterval, { start: d.fromDateTime, end: d.toDateTime }))
        .attr('width', d => this.containedIntervalWidth({ start: d.fromDateTime, end: d.toDateTime }, visibleInterval))
        .attr('x', d => xScale(Math.max(d.fromDateTime, left))!)
        .style('display', null)

      alarmElements
        .filter(d => !onScreen.includes(d))
        .filter(d => !areIntervalsOverlapping(visibleInterval, { start: d.fromDateTime, end: d.toDateTime }))
        .style('display', 'none')

      const alarmText = this.svgContainer.selectAll<SVGTextElement, NormalizedAlarm>(classSelector(ElementClass.alarmText))

      if (onScreen.length) {
        // Attach alarm text to the first visible alarm

        const targetX = xScale(onScreen[0].fromDateTime)

        const alarmTextWidth = alarmText.node()!.getBBox().width
        const paddingTextWidth = this.getPaddingTextWidth()

        if (targetX !== undefined) {
          const y =
            targetX + alarmTextWidth > this.chartWidth - paddingTextWidth - ANNOTATION_SPACING
              ? ANNOTATION_OFFSET - ANNOTATION_LINE_HEIGHT
              : ANNOTATION_OFFSET

          alarmText
            .attr('x', Math.min(Math.max(targetX, CHART_MARGIN.left), this.chartWidth - CHART_MARGIN.right - alarmTextWidth))
            .attr('y', y)
            .style('display', null)
        }
      } else {
        alarmText.style('display', 'none')
      }
    }
  }

  updatePadding(left: number, right: number) {
    if (this.hasPadding()) {
      const xScale = this.currentXScale

      const visibleInterval: NumberInterval = { start: left, end: right }

      const paddingElements = this.svgContainer.selectAll<SVGRectElement, NumberInterval>(classSelector(ElementClass.paddingArea))

      paddingElements
        .filter(d => areIntervalsOverlapping(visibleInterval, d))
        .attr('width', d => this.containedIntervalWidth(d, visibleInterval))
        .attr('x', d => xScale(Math.max(d.start.valueOf(), left))!)
        .style('display', null)

      paddingElements.filter(d => !areIntervalsOverlapping(visibleInterval, d)).style('display', 'none')

      if (this.paddingText) {
        const paddingWidth = this.getPaddingTextWidth()
        this.paddingText.attr('x', this.chartWidth - paddingWidth)
      }
    }
  }

  update() {
    const xScale = this.currentXScale
    const left = xScale.invert(CHART_MARGIN.left).valueOf()
    const right = xScale.invert(this.chartWidth).valueOf()

    this.renderCurrents(left, right)
    this.updateAlarms(left, right)
    this.updatePadding(left, right)

    let axisBottom = d3.axisBottom(xScale).ticks(Math.floor(this.chartWidth / X_PIXELS_PER_TICK))
    const timeFormat = this.multiTimeFormat
    if (timeFormat) {
      axisBottom = axisBottom.tickFormat(v => timeFormat(v.valueOf()))
    }

    this.xAxis.call(axisBottom)

    d3.select(this.chart)
      .selectAll(classSelector(ElementClass.zoomInButton))
      .style('display', this.showZoomButtons ? 'inherit' : 'none')
    d3.select(this.chart)
      .selectAll(classSelector(ElementClass.zoomOutButton))
      .style('display', this.showZoomButtons ? 'inherit' : 'none')
    d3.select(this.chart)
      .selectAll(classSelector(ElementClass.resetZoomButton))
      .style('display', this.showZoomButtons ? 'inherit' : 'none')
  }

  resetZoom(propagate: boolean = false) {
    if (!propagate) {
      this.disableNextZoomPropagation = true
    }

    this.zoom.transform(this.svgContainer.transition().duration(0), d3.zoomIdentity)
  }

  private onZoom(event: d3.D3ZoomEvent<SVGSVGElement, any>) {
    const transform: d3.ZoomTransform = event.transform
    this.currentXScale = transform.rescaleX(this.xScale)

    this.hideTooltip()
    this.update()

    if (this.disableNextZoomPropagation) {
      this.disableNextZoomPropagation = false
      return
    }

    if (this.onZoomUpdated) {
      const fromDate = this.currentXScale.invert(CHART_MARGIN.left).valueOf()
      const toDate = this.currentXScale.invert(this.chartWidth).valueOf()
      this.onZoomUpdated(fromDate, toDate, transform)
    }
  }

  render() {
    this.svgContainer.selectAll(classSelector(ElementClass.paddingArea)).remove()

    if (this.hasPadding()) {
      const domain = this.getDomain()

      this.svgContainer
        .selectAll(classSelector(ElementClass.paddingArea))
        .data([
          { start: domain.xMin, end: domain.xMin + this.padding.start },
          { start: domain.xMax - this.padding.end, end: domain.xMax },
        ])
        .enter()
        .insert('rect', 'g')
        .classed(ElementClass.paddingArea, true)
        .attr('fill', `url(#${this.paddingPatternId})`)
        .attr('height', CHART_HEIGHT - CHART_MARGIN.top - CHART_MARGIN.bottom)
        .attr('y', CHART_MARGIN.top)
        .style('pointer-events', 'none')
        .attr('clip-path', this.clipUrl)
    }

    this.svgContainer.selectAll(classSelector(ElementClass.alarm)).remove()

    this.svgContainer.selectAll(classSelector(ElementClass.alarmText)).remove()

    if (this.alarms?.length && this.alarmType) {
      this.svgContainer
        .selectAll(classSelector(ElementClass.alarm))
        .data(this.alarms)
        .enter()
        .insert('rect', 'g')
        .classed(ElementClass.alarm, true)
        .attr('fill', this.getAlarmColor())
        .attr('height', CHART_HEIGHT - CHART_MARGIN.top - CHART_MARGIN.bottom)
        .attr('y', CHART_MARGIN.top)
        .style('pointer-events', 'none')
        .attr('clip-path', this.clipUrl)

      this.svgContainer
        .selectAll(classSelector(ElementClass.alarmText))
        .data([this.alarmType])
        .join('text')
        .classed(ElementClass.alarmText, true)
        .attr('font-size', ALARM_TEXT_FONT_SIZE)
        .attr('fill', this.getAlarmTextColor())
        .attr('dominant-baseline', 'hanging')
        .attr('y', ANNOTATION_OFFSET)
        .text(this.alarmType.type.localization.full[this.currentLanguage])
    }

    this.yAxis.call(d3.axisLeft(this.currentYScale).tickFormat(v => formatYTick(v.valueOf())))

    this.yAxisGrid.call(
      d3
        .axisLeft(this.currentYScale)
        .tickFormat(_ => '')
        .tickSize(-this.chartWidth + CHART_MARGIN.right + CHART_MARGIN.left)
    )

    // Tooltip
    this.tooltipPoints
      .selectAll(classSelector(ElementClass.tooltipPoint))
      .data(this.sensorData)
      .join('circle')
      .classed(ElementClass.tooltipPoint, true)
      .attr('r', TOOLTIP_POINT_RADIUS)

    this.update()
  }

  setTimeRange(fromDate: number, toDate: number) {
    this.fromDate = fromDate
    this.toDate = toDate
  }
}
