import type { AppPage } from 'src/Types/AppPage'
import { ALL_APP_PAGES } from 'src/Types/AppPage'
import { QueryStringStateKeys } from 'src/Store/useQueryStringState'

export const isAppPage = (path: unknown): path is AppPage => typeof path === 'string' && ALL_APP_PAGES.includes(path as AppPage)

/**
 * Get link to a page with the page and params.
 * Params are copied from the current URL.
 */
export const createPageLink = (page: AppPage, params: { [key: string]: string } = {}) => {
  const currentParams = new URLSearchParams(document.location.search)
  const linkParams = new URLSearchParams(params)
  const copyParams = [QueryStringStateKeys.datePeriod, QueryStringStateKeys.stretchLocations, QueryStringStateKeys.railway]
  copyParams.forEach(p => {
    const val = currentParams.get(p)
    if (val) {
      linkParams.set(p, val)
    }
  })

  return `/${page}?${linkParams.toString()}`
}

/**
 * Get link to a page.
 */
export const createPurePageLink = (page: AppPage, params: { [key: string]: string } = {}) => {
  const linkParams = new URLSearchParams(params)
  return `/${page}?${linkParams.toString()}`
}
