import { useTranslation } from 'react-i18next'
import CircularProgress from '@mui/material/CircularProgress'

import { useConnectedSwitches } from 'src/Hooks/NetworkData/useConnectedSwitches'

type OwnProps = {
  switchHeatCabinetBaneDataId: string
}

export const ConnectedSwitches = ({ switchHeatCabinetBaneDataId }: OwnProps) => {
  const { t } = useTranslation()

  const { connectedSwitches, status } = useConnectedSwitches(switchHeatCabinetBaneDataId)

  const getContent = () => {
    if (status === 'loading' || status === 'idle') {
      return <CircularProgress color="inherit" size={15} />
    }

    if (status === 'error' || !connectedSwitches) {
      return t('switchHeat.connectedSwitches.fetchError')
    }

    return <b>{connectedSwitches.map(s => s.switchBaneDataName).join(', ')}</b>
  }

  return (
    <>
      {t('switchHeat.connectedSwitches.label')}: {getContent()}
    </>
  )
}
