import { ApiType } from 'src/Types/ApiType'
import type { SensorHistory, SensorTwinData } from 'src/Types/SensorTwin'

import { callApiGet, callApiPost } from 'src/msGraphClient'
import config from 'src/Config/Config'

const { CURRENT_BENDER_VALUES, BENDER_HISTORY } = config.API_FRAGMENTS.apim

export const getSensorDataForBender = (baneDataId: string) => {
  const urlParams = new URLSearchParams({ baneDataId })

  return callApiGet<SensorTwinData>(`${CURRENT_BENDER_VALUES}?${urlParams.toString()}`, ApiType.APIM, { parseDates: true })
}

type BenderHistoryParams = {
  baneDataIds: string[]
  codeSystemKeys: string[]
  fromDate: number
  toDate: number
}

export const getBenderSensorHistory = ({ baneDataIds, codeSystemKeys, fromDate, toDate }: BenderHistoryParams) => {
  const urlParams = new URLSearchParams({
    fromDate: new Date(fromDate).toJSON(),
    toDate: new Date(toDate).toJSON(),
  })

  const body = {
    baneDataIds,
    codeSystemKeys,
  }

  return callApiPost<SensorHistory[]>(`${BENDER_HISTORY}?${urlParams.toString()}`, ApiType.APIM, JSON.stringify(body))
}
