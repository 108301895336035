import { useRef, useEffect, useState, useMemo, useCallback } from 'react'
import { styled } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress'

import { Alert } from 'src/Components/Alert'
import { BenderVoltageHistoryRenderer } from './benderVoltageHistoryRenderer'

import type { Bender } from 'src/Types/Bender'
import type { ChartLabels, ChartFocusUpdatedEventHandler } from 'src/Types/ChartTypes'
import type { DatePeriod } from 'src/Types/DatePeriod'
import { useDomainConstantsStore } from 'src/Store/domainConstants'
import { useUserProfileStore } from 'src/Store/userProfile'
import { useChartStyles } from 'src/Components/Chart/chartStyles'
import { useBenderSensorHistory } from 'src/Hooks/NetworkData/useBenderSensorHistory'
import { useLanguage } from 'src/Hooks/useLanguage'
import { getDateRange } from 'src/Features/SelectedDatePeriod/predefinedDatePeriods'
import { ChartLegendsList } from 'src/Components/Chart/Legends/ChartLegendsList'
import { useBenderHistoryLegends } from 'src/Components/Chart/Legends/useBenderHistoryLegends'
import { useChartResizeBehaviour } from 'src/Hooks/useChartResizeBehaviour'

const StyledContainer = styled('div')(
  props => `
  margin: 16px;

  ${props.theme.breakpoints.down('md')} {
    margin: 4px;
  }
`
)

const StyledChart = styled('div')`
  position: relative;
`

type OwnProps = {
  selectedBenders: Bender[]
  datePeriod: DatePeriod
  codeSystemKeys: string[]
  groupedByBender: boolean
  onZoomUpdated?: ChartFocusUpdatedEventHandler
}

export const BenderVoltageChart = ({ selectedBenders, datePeriod, codeSystemKeys, groupedByBender, onZoomUpdated }: OwnProps) => {
  const { t, currentLanguage } = useLanguage()
  const { classes: chartClasses } = useChartStyles()
  const chartRef = useRef<HTMLDivElement>(null)

  const sensorCodes = useDomainConstantsStore(state => state.sensorCodes.sensorCodes.SEF)
  const hideChartZoomButtons = useUserProfileStore(state => state.settings.settings.hideChartZoomButtons)
  const [renderer, setRenderer] = useState<BenderVoltageHistoryRenderer>()
  const [inactiveSensors, setInactiveSensors] = useState<number[]>([])

  const selectedBenderIds = useMemo(() => selectedBenders.map(rs => rs.baneDataId), [selectedBenders])

  const { sensorHistory: unsortedSensorsHistory, status } = useBenderSensorHistory({
    baneDataIds: selectedBenderIds,
    codeSystemKeys,
    datePeriod,
  })

  const sensorHistory = useMemo(
    () => unsortedSensorsHistory?.sort((a, b) => a.twinId.localeCompare(b.twinId)),
    [unsortedSensorsHistory]
  )

  const visibleSensorHistory = useMemo(() => sensorHistory?.filter(sh => sh.values?.length), [sensorHistory])

  const inactiveSensorsUpdated = (inactiveSensors: number[]) => {
    setInactiveSensors(inactiveSensors)
    if (renderer) {
      renderer.setInactiveSeries(inactiveSensors)
    }
  }

  const getColorIndex = useCallback(
    (index: number) => {
      if (!groupedByBender) {
        return index
      }
      return Math.floor(index / codeSystemKeys.length)
    },
    [codeSystemKeys.length, groupedByBender]
  )

  useChartResizeBehaviour(renderer)

  const { fromDate, toDate } = useMemo(() => getDateRange(datePeriod), [datePeriod])

  useEffect(() => {
    const chart = chartRef.current
    if (chart && visibleSensorHistory) {
      const labels: ChartLabels = {
        zoomIn: t('general.zoomIn'),
        zoomOut: t('general.zoomOut'),
        resetZoom: t('general.resetZoom'),
        // leftYAxisLabel: t('benders.isolationChart.leftYAxisLabel'),
        // rightYAxisLabel: t('benders.isolationChart.rightYAxisLabel'),
      }

      const seriesSensorCodes = sensorCodes.filter(uc => codeSystemKeys.includes(uc.key))

      const chartRenderer = new BenderVoltageHistoryRenderer({
        chart,
        timeline: {
          start: fromDate,
          end: toDate,
        },
        classes: {
          tooltip: chartClasses.tooltip,
          tooltipArrow: chartClasses.tooltipArrow,
          zoomButton: chartClasses.zoomButton,
        },
        getColorIndex,
        onZoomUpdated,
      })
      setRenderer(chartRenderer)

      chartRenderer.setData(visibleSensorHistory)
      chartRenderer.setBenders(selectedBenders)
      chartRenderer.setInactiveSeries(inactiveSensors, false)
      chartRenderer.setLabels(labels, currentLanguage)
      chartRenderer.setSeriesSensorCodes(seriesSensorCodes)
      chartRenderer.setShowZoomButtons(!hideChartZoomButtons)
      chartRenderer.render()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensorHistory, currentLanguage, chartClasses, fromDate, toDate, sensorCodes, t])

  const legends = useBenderHistoryLegends({
    sensorHistory,
    inactiveSensors,
    domain: 'SEF',
    benders: selectedBenders,
    showCodeSystem: true,
    getColorIndex,
    useBenderName: groupedByBender,
  })

  if (status === 'idle') {
    return null
  }

  if (status === 'error') {
    return (
      <Alert severity="error" margin={16}>
        {t('benders.historyFetchError')}
      </Alert>
    )
  }

  return (
    <>
      {status === 'loading' && (
        <StyledContainer>
          <CircularProgress color="inherit" size={15} /> {t('benders.voltageChart.loading')}
        </StyledContainer>
      )}
      {(!sensorHistory || !sensorHistory.length) && status === 'success' && (
        <StyledContainer>{t('benders.voltageChart.noneFound')}</StyledContainer>
      )}
      {sensorHistory && !!sensorHistory.length && (
        <StyledContainer>
          <StyledChart ref={chartRef} />
          <ChartLegendsList
            legends={legends}
            inactiveIndexes={inactiveSensors}
            onInactiveIndexesUpdated={inactiveSensorsUpdated}
            emptyLegendsLabel={t('benders.isolationChart.noData')}
            loading={status === 'loading'}
          />
        </StyledContainer>
      )}
    </>
  )
}
