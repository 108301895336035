import { differenceInMinutes } from 'date-fns'
import type { TrackCircuitCurrentsData } from 'src/Types/TrackCircuitCurrentTypes'
import { arrayMinMax } from 'src/Utils/arrayMinMax'
import { getMax } from 'src/Utils/array'

/**
 * Gets a list of track-circuits that have missing end measurements.
 * Measurements with compression rate > 0 are populated later than compression rate 0,
 * so there is a gap at the end that can be filled with compression rate 0.
 */
export const getTrackCircuitsWithMissingEndMeasurements = (measurements: TrackCircuitCurrentsData[]) => {
  let minDate: number | undefined
  const baneDataIdsMissingEndMeasurements: string[] = []

  if (measurements.length === 0) {
    return undefined
  }

  const requestedToDate = getMax(measurements.map(m => m.toDate))

  measurements
    .filter(m => m.compressionRate > 0)
    .forEach(({ trackCircuitBaneDataId, fcMeasurements, rcMeasurements }) => {
      const fcDates = fcMeasurements.map(x => x[0])
      const rcDates = rcMeasurements.map(x => x[0])
      const { min: measurementMinDate, max: measurementMaxDate } = arrayMinMax([...fcDates, ...rcDates])
      if (!measurementMinDate || !measurementMaxDate) {
        return
      }

      const diffInMinutes = differenceInMinutes(requestedToDate, measurementMaxDate)
      if (diffInMinutes < 5) {
        return
      }

      if (!minDate || measurementMinDate < minDate) {
        minDate = measurementMaxDate
      }

      baneDataIdsMissingEndMeasurements.push(trackCircuitBaneDataId)
    })

  if (!minDate) {
    return undefined
  }

  return {
    missingFromDate: minDate,
    baneDataIdsMissingEndMeasurements,
  }
}
