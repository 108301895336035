import { FilterDropdown } from 'src/Components/SidePanelMenu/FilterDropdown'

import type { Filter, StatusFilter } from 'src/Types/Filter'
import { FilterType } from 'src/Types/Filter'
import type { SystemStatus } from 'src/Types/SystemStatus'

import { useDomainConstantsStore } from 'src/Store/domainConstants'
import { useLanguage } from 'src/Hooks/useLanguage'

type OwnProps = {
  statusOptions: SystemStatus[]
  filter?: StatusFilter
  isAlarmView?: boolean
  onFilterUpdated: (filter: Filter) => void
}

export const StatusFilterSelector = ({ statusOptions, filter, isAlarmView = false, onFilterUpdated }: OwnProps) => {
  const { t, currentLanguage } = useLanguage()
  const statusDefinitions = useDomainConstantsStore(state => state.alarmTypes.classifications)

  const statusLabels = statusOptions.map(status => {
    const localization = statusDefinitions[status]
    return localization
      ? localization.localization.full[currentLanguage]
      : t(`sideMenu.status.${status.toLowerCase()}${isAlarmView ? 'Alarm' : ''}`)
  })

  const selectedStatuses = filter?.values || []
  const statusesUpdated = (values: string[]) =>
    onFilterUpdated({
      type: FilterType.Status,
      values: values as SystemStatus[],
    })

  return (
    <FilterDropdown
      id="classification-filter"
      label={t('sideMenu.filterLabel.status')}
      selectedValues={selectedStatuses}
      options={statusOptions}
      optionLabels={statusLabels}
      onUpdate={statusesUpdated}
    />
  )
}
