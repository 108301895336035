import { getBenderDetails } from 'src/Providers/OrchestratedBender'
import { useQueryWithErrorHandling } from './useQueryWithErrorHandling'

export const useBenderDetails = (baneDataId: string) => {
  const query = useQueryWithErrorHandling(
    ['benderDetails', { baneDataId }],
    () => getBenderDetails(baneDataId),
    {
      staleTime: 5 * 60 * 1000,
    },
    'benders.fetchError'
  )

  return {
    ...query,
    benderDetails: query.data,
  }
}
