import { styled } from '@mui/material/styles'
import Chip from '@mui/material/Chip'

import themeColors from 'src/theme'

const StyledChip = styled(Chip)`
  margin-left: 8px;
  color: white;
  background-color: ${themeColors.error};
`

type OwnProps = {
  alarmCount: number
}

export const AlarmCountChip = ({ alarmCount }: OwnProps) => {
  if (alarmCount <= 0) {
    return null
  }

  return <StyledChip label={<b>{alarmCount}</b>} size="small" />
}
