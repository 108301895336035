import type { AssetWithAlarms } from 'src/Types/AssetWithAlarms'
import type { Filter } from 'src/Types/Filter'
import { FilterType } from 'src/Types/Filter'
import { filterAlarms } from 'src/Features/Alarms/AlarmList/AlarmFilters/filterAlarms'
import { filterAsset } from 'src/Features/Alarms/AlarmList/AlarmFilters/filterAsset'
import { filterRealAlarms } from 'src/Features/Alarms/AlarmList/AlarmFilters/filterRealAlarms'

/**
 * Gives you filtered assets, but if onlyWithAlarms, with the condition
 * that they also have non-zero number of alarms as well.
 * If not onlyWithAlarms, then the same but not on that additional condition.
 * @param filters The filters
 * @param assetsWithAlarms Array of { Asset, Alarm[] } elements
 * @param onlyWithAlarms Asking only for those assets having non-zero length of alarms array
 * @param endOfSearchPeriod The end of the search period used for out of order periods filtering
 */
export const filterAssetsWithAlarms = (
  filters: Filter[],
  assetsWithAlarms: AssetWithAlarms[],
  onlyWithAlarms: boolean,
  endOfSearchPeriod?: number
) =>
  assetsWithAlarms
    .map(({ asset, alarms }) => {
      let filteredAlarms = filterAlarms(filters, alarms, asset)
      if (!onlyWithAlarms) {
        filteredAlarms = filterRealAlarms(filters, filteredAlarms, asset)
      }
      return {
        asset,
        alarms: filteredAlarms,
      }
    })
    .filter(({ asset }) =>
      filterAsset(
        filters.filter(filter => (onlyWithAlarms ? filter.type !== FilterType.Status : true)),
        asset,
        endOfSearchPeriod
      )
    )
    .filter(({ alarms }) => (onlyWithAlarms ? alarms.length > 0 : true))
