import type { GetOrchestratedAssetsParams } from 'src/Types/GetOrchestratedAssetsParams'
import type { Asset, WindowStatus } from 'src/Types/AssetTypes'
import { WindowStatusValue } from 'src/Types/AssetTypes'
import {
  AssetStatusEntryDomain,
  AssetStatusEntityStatus,
  AssetStatusEntityStatusId,
  AssetStatusEntryDomainId,
} from 'src/Types/AssetStatusEntry'
import type { SystemStatus } from 'src/Types/SystemStatus'

export const getOrchestratedAssetIdParams = (idParams: GetOrchestratedAssetsParams) => {
  if ('baneDataIds' in idParams) {
    return { idKey: 'baneDataIds', value: idParams.baneDataIds.join(',') }
  }

  if ('locationIds' in idParams) {
    return { idKey: 'locationIds', value: idParams.locationIds.join(',') }
  }

  return { idKey: 'railwayId', value: idParams.railwayId }
}

export const getAllPossibleCurrentWindowStatuses = (): WindowStatus[] => {
  const statuses = getAllPossibleWindowStatuses()

  return statuses.filter(status => status !== 'Maintenance' && status !== 'Out_of_Order')
}

export const getAllPossibleWindowStatuses = (): WindowStatus[] => {
  return Object.keys(WindowStatusValue) as WindowStatus[]
}

export const isWindowStatus = (thing: any): thing is WindowStatus => {
  return Object.values(WindowStatusValue).includes(thing)
}

export const includesWindowStatus = (arrayOfThings: any[]): boolean => {
  return arrayOfThings?.some(value => isWindowStatus(value)) ?? false
}

export const getRelevantWindows = (asset: Asset) =>
  asset.assetStatusWindows?.filter(window => window.startTime < Date.now()) || []

export const statusIsVirtuallyNoContact = (status: WindowStatus | SystemStatus) =>
  status === WindowStatusValue.Maintenance || status === WindowStatusValue.Out_of_Order || status === WindowStatusValue.NoContact

const AssetStatusEntryDomainByIdMap: { [key: number]: AssetStatusEntryDomain } = Object.fromEntries(
  Object.entries(AssetStatusEntryDomainId).map(([key, value]) => [value, key as AssetStatusEntryDomain])
)

export const getAssetStatusEntryDomainId = (domain: AssetStatusEntryDomain): number => AssetStatusEntryDomainId[domain]

export const isAssetStatusEntryDomain = (value: any): value is AssetStatusEntryDomain =>
  Object.values(AssetStatusEntryDomain).includes(value)

export const getAssetStatusEntryDomain = (
  id: AssetStatusEntryDomain | AssetStatusEntryDomainId
): AssetStatusEntryDomain | undefined => (isAssetStatusEntryDomain(id) ? id : AssetStatusEntryDomainByIdMap[id])

const AssetStatusEntityStatusIdByKeyMap: { [key in keyof typeof AssetStatusEntityStatusId]: number } = {
  IrregularData: AssetStatusEntityStatusId.IrregularData,
  NoContact: AssetStatusEntityStatusId.NoContact,
}

const AssetStatusEntityStatusByKeyMap: { [key in keyof typeof AssetStatusEntityStatusId]: AssetStatusEntityStatus } = {
  IrregularData: AssetStatusEntityStatus.IrregularData,
  NoContact: AssetStatusEntityStatus.NoContact,
}

const AssetStatusEntityStatusByIdMap: { [key: number]: AssetStatusEntityStatus } = Object.fromEntries(
  Object.entries(AssetStatusEntityStatusByKeyMap).map(([key, value]) => [
    AssetStatusEntityStatusIdByKeyMap[key as keyof typeof AssetStatusEntityStatusId],
    value,
  ])
)

export const getAssetStatusEntityStatusId = (status: keyof typeof AssetStatusEntityStatusId): number => {
  return AssetStatusEntityStatusIdByKeyMap[status]
}

export const isAssetStatusEntityStatus = (value: any): value is AssetStatusEntityStatus =>
  Object.values(AssetStatusEntityStatus).includes(value)

export const getAssetStatusEntityStatus = (
  id: AssetStatusEntityStatus | AssetStatusEntityStatusId
): AssetStatusEntityStatus | undefined => (isAssetStatusEntityStatus(id) ? id : AssetStatusEntityStatusByIdMap[id])

export const getOngoingWindows = (asset: Asset) => asset.assetStatusWindows?.filter(window => window.endTime > Date.now()) || []
