import { AppPage } from 'src/Types/AppPage'
import { AffectedSystem } from 'src/Types/SystemStatusAlert'

type AffectedSystemsForRouteDefinition = {
  localSystems?: AffectedSystem[]
  isGlobalAffectedSystem?: boolean
}

const routeDefinitions: Record<AppPage, AffectedSystemsForRouteDefinition> = Object.freeze({
  [AppPage.AlarmCards]: { localSystems: [AffectedSystem.Alarms], isGlobalAffectedSystem: true },
  [AppPage.SwitchesAndHeatCards]: { localSystems: [AffectedSystem.SwitchHeat, AffectedSystem.Switch] },
  [AppPage.TrackCircuitsCards]: { localSystems: [AffectedSystem.TrackCircuit] },
  [AppPage.TrackCircuitsStream]: { localSystems: [AffectedSystem.TrackCircuit] },
  [AppPage.SignalEarthCards]: { localSystems: [AffectedSystem.SignalEarthFaults] },
  [AppPage.SignalEarthStream]: { localSystems: [AffectedSystem.SignalEarthFaults] },
  [AppPage.Favorites]: {},
})

export const getAffectedSystemDefinitionByRoute = (route: string) =>
  Object.entries(routeDefinitions).find(([appPage]) => `/${appPage}` === route)?.[1]

const getAffectedSystemDefinitionBySystem = (affectedSystem: AffectedSystem) =>
  Object.entries(routeDefinitions).find(([, definition]) => definition.localSystems?.includes(affectedSystem))?.[1]

export const getGroupedAffectedSystems = () => {
  const allLocalAffectedSystems = [
    ...new Set(
      Object.values(routeDefinitions)
        .map(def => def.localSystems || [])
        .flat()
    ),
  ]
  const allAffectedSystems = Object.values(AffectedSystem)
  const allGlobalAffectedSystems = allAffectedSystems.filter(
    system => !allLocalAffectedSystems.includes(system) || getAffectedSystemDefinitionBySystem(system)?.isGlobalAffectedSystem
  )

  return {
    /**
     * All local systems, i.e. systems that are mapped to a route.
     */
    allLocalAffectedSystems,
    /**
     * All systems that are not mapped to a route, and [Alarms].
     */
    allGlobalAffectedSystems,
  }
}
