import type { LanguageResources } from 'src/Types/LanguageTypes'
import { Language } from 'src/Types/LanguageTypes'

/**
 * Returns the first supported language found.
 *
 * @param resources - i18n resources object.
 * @param languages - Currently active languages, typically from the i18n object.
 * @param defaultLang - Fallback language if no supported languages are found.
 */
export const findSupportedLanguage = (resources: LanguageResources, languages: string[], defaultLang: Language) =>
  languages.find(l => l in resources) || defaultLang

/**
 * Returns the proper language string based on an input language.
 * Returns 'en' for 'en', and 'no' otherwise.
 *
 * @param lang - Current language.
 */
export const getLanguage = (lang: string) => (lang === Language.en ? Language.en : Language.no)

/**
 * Returns the provided translation key unless isShort is true,
 * in which case '_short' is appended to the key.
 *
 * @param key - A translation key.
 * @param isShort - Whether _short should be appended to the key.
 */
export const flexibleTranslation = (key: string, isShort: boolean) => (isShort ? `${key}_short` : key)
