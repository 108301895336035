import type { SwitchHeatApiVersions } from 'src/Providers/SwitchHeat'
import { useFeatureOverride } from 'src/Hooks/useFeatureOverride'
import { SessionFeatures } from 'src/Hooks/useSessionFeature'

export const useSwitchHeatVersion = (): SwitchHeatApiVersions => {
  const hasSwitchHeatV2FeatureOverride = useFeatureOverride(SessionFeatures.ExGenFeatureOn)
  return hasSwitchHeatV2FeatureOverride ? 'v2' : 'v1'
}

export const useExGenToggledOn = () => useSwitchHeatVersion() === 'v2'
