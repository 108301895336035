import type { SxProps } from '@mui/system'
import type { Theme } from '@mui/material/styles'
import CancelIcon from '@mui/icons-material/CancelOutlined'
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutlined'
import PendingIcon from '@mui/icons-material/HelpOutlined'
import UnknownIcon from '@mui/icons-material/RadioButtonUncheckedOutlined'

import type { SystemStatus } from 'src/Types/SystemStatus'
import { WindowStatusValue } from 'src/Types/AssetTypes'

import themeColors from 'src/theme'

type OwnProps = {
  alarmIndicator: SystemStatus | 'OkToday'
  size?: 'small' | 'large' | 'xsmall' | 'medium'
}

const getIndicatorIcon = (alarmIndicator: SystemStatus | 'OkToday') => {
  switch (alarmIndicator) {
    case 'Pending':
      return PendingIcon

    case 'Unknown':
      return UnknownIcon

    case 'Ok':
    case 'OkToday':
      return CheckCircleIcon

    case 'Alarm':
    case 'Warning':
    case WindowStatusValue.Maintenance:
    case WindowStatusValue.Out_of_Order:
    case WindowStatusValue.IrregularData:
    case WindowStatusValue.NoContact:
    default:
      return CancelIcon
  }
}

const getColor = (alarmIndicator: SystemStatus | 'OkToday') => {
  switch (alarmIndicator) {
    case 'Alarm':
      return themeColors.error

    case 'Warning':
      return themeColors.warning

    case 'OkToday':
      return themeColors.successText

    case 'Pending':
      return themeColors.disabledText

    case 'IrregularData':
      return themeColors.irregularData

    case 'Unknown':
    case 'Ok':
      return themeColors.success

    case 'Maintenance':
    case 'Out_of_Order':
    case 'NoContact':
      return themeColors.noContactSemiLight

    default:
      return undefined
  }
}

export const AlarmIndicatorIcon = ({ alarmIndicator, size = 'medium' }: OwnProps) => {
  const IndicatorIcon = getIndicatorIcon(alarmIndicator)
  const color = getColor(alarmIndicator)
  const sx: SxProps<Theme> = { color }
  if (size === 'xsmall') {
    sx.fontSize = 16
  }
  return <IndicatorIcon aria-hidden="true" fontSize={size !== 'xsmall' ? size : undefined} sx={sx} />
}
