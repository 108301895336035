import type { PageFilters } from 'src/Types/PageFilters'
import { getPageFilters, storePageFilters } from 'src/Providers/Settings/PageFilters'
import { useCallback } from 'react'
import { useQueryWithErrorHandling } from 'src/Hooks/NetworkData/useQueryWithErrorHandling'
import { useAppStateStore } from 'src/Store/appState'
import { useLanguage } from 'src/Hooks/useLanguage'
import { isDatePeriod } from 'src/Providers/Settings/parsePageFilters'

type SavePageFiltersParam = Pick<PageFilters, 'path' | 'sortOrder' | 'filters'>

export const usePageFilters = () => {
  const { t } = useLanguage()
  const queueMessage = useAppStateStore(state => state.appMessages.queueMessage)
  const openRailwayId = useAppStateStore(state => state.locations.openRailwayId)
  const pendingStretchLocationIds = useAppStateStore(state => state.locations.pendingStretchLocationIds)
  const pendingDatePeriod = useAppStateStore(state => state.selectedDatePeriod.pendingDatePeriod)

  const {
    data: pageFilters,
    refetch,
    ...rest
  } = useQueryWithErrorHandling(
    ['pageFilters'],
    () => getPageFilters(),
    {
      staleTime: Infinity,
    },
    'pageFilters.fetchError'
  )

  const saveFilters = useCallback(
    async (filters: SavePageFiltersParam) => {
      if (!pendingDatePeriod || !isDatePeriod(pendingDatePeriod)) {
        return
      }

      const newFilters: PageFilters = {
        ...filters,
        stretchLocations: pendingStretchLocationIds,
        datePeriod: pendingDatePeriod,
      }
      if (openRailwayId) {
        newFilters.railway = openRailwayId
      }
      try {
        await storePageFilters([newFilters])
      } catch (e) {
        queueMessage({
          type: 'error',
          raw: true,
          message: t('pageFilters.savedError'),
        })
        return
      }
      queueMessage({
        type: 'success',
        raw: true,
        message: t('pageFilters.savedSuccess'),
      })
      await refetch()
    },
    [pendingStretchLocationIds, pendingDatePeriod, openRailwayId, queueMessage, refetch, t]
  )

  const canSaveFilters = pendingStretchLocationIds.length > 0 && !!pendingDatePeriod && isDatePeriod(pendingDatePeriod)

  return {
    pageFilters,
    saveFilters,
    canSaveFilters,
    ...rest,
  }
}
