import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'

import type { DateRange } from 'src/Types/DatePeriod'

import { useAppStateStore } from 'src/Store/appState'
import { useUserProfileStore } from 'src/Store/userProfile'
import { QueryStringStateKeys, useQueryStringState } from 'src/Store/useQueryStringState'

import themeColors from 'src/theme'

const StyledPanel = styled('div')`
  position: fixed;
  bottom: 0;
  right: 0;
  border-width: 1px 0 0 1px;
  border-color: ${themeColors.primary};
  border-style: solid;
  border-top-left-radius: 6px;
  font-size: 12px;
  display: flex;
  z-index: 1500;
  background-color: ${themeColors.body};
`

const StyledContent = styled('div')`
  padding: 2px;
  margin-left: 4px;
`

const StyledToggle = styled('div')`
  text-align: center;
  border-right: 1px solid ${themeColors.disabledText};
  padding: 2px;
  display: flex;
  background-color: ${themeColors.disabledLight};
  border-top-left-radius: inherit;

  & > button {
    background: none;
    border: none;
    padding: 0;
    outline: none;
    cursor: pointer;

    & > span {
      writing-mode: vertical-lr;
      transform: rotate(180deg);
      color: ${themeColors.disabledText};
      font-size: 10px;
    }
  }
`

export const DebugPanel = () => {
  const { t } = useTranslation()
  const changePendingDatePeriod = useAppStateStore(state => state.selectedDatePeriod.changePendingDatePeriod)
  const [, setSelectedDatePeriod] = useQueryStringState(QueryStringStateKeys.datePeriod)
  const [, setOpenRailwayId] = useQueryStringState(QueryStringStateKeys.railway)
  const [, setSelectedStretchLocations] = useQueryStringState(QueryStringStateKeys.stretchLocations)

  const updateSetting = useUserProfileStore(state => state.settings.updateSetting)
  const debugPanelDefaultExpanded = useUserProfileStore(state => state.settings.settings.debugPanelDefaultExpanded)
  const [expanded, setExpanded] = useState(debugPanelDefaultExpanded)

  const selectToday = () => {
    changePendingDatePeriod('today')
    setSelectedDatePeriod('today')
  }

  const selectMay1st2020 = () => {
    const dateRange: DateRange = {
      fromDate: new Date('2020-05-01T00:00:00').valueOf(),
      toDate: new Date('2020-05-01T23:59:59').valueOf(),
    }

    changePendingDatePeriod(dateRange)
    setSelectedDatePeriod(dateRange)
  }

  const selectAsker = () => {
    setOpenRailwayId('B30')
    setSelectedStretchLocations(['1414-14130'])
  }

  const selectSkoyen = () => {
    setOpenRailwayId('B20')
    setSelectedStretchLocations(['1400-14020'])
  }

  const toggleExpanded = () => {
    updateSetting('debugPanelDefaultExpanded', !expanded)
    setExpanded(!expanded)
  }

  return (
    <StyledPanel>
      <StyledToggle>
        <button type="button" onClick={toggleExpanded}>
          <span>DEBUG</span>
        </button>
      </StyledToggle>
      {expanded && (
        <StyledContent>
          <div>
            {t('debug.location.label')}:
            <Button variant="text" color="primary" onClick={selectSkoyen} size="small" id="debug-skoyen">
              Skøyen (TC)
            </Button>
            <Button variant="text" color="primary" onClick={selectAsker} size="small" id="debug-asker">
              Asker (SPV)
            </Button>
          </div>
          <div>
            {t('debug.time.label')}:
            <Button variant="text" color="primary" onClick={selectToday} size="small" id="debug-today">
              {t('debug.time.today')}
            </Button>
            <Button variant="text" color="primary" onClick={selectMay1st2020} size="small" id="debug-may-1st-2020">
              {t('debug.time.may1st2020')}
            </Button>
          </div>
        </StyledContent>
      )}
    </StyledPanel>
  )
}
