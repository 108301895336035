import { callApiGet } from 'src/msGraphClient'

import { ApiType } from 'src/Types/ApiType'
import type { PointMachineZoomLevel } from 'src/Types/SwingTypes'

import config from 'src/Config/Config'

const { POINT_MACHINE_ZOOM_LEVELS } = config.API_FRAGMENTS.apim

export const getPointMachineZoomLevels = () => callApiGet<PointMachineZoomLevel[]>(`${POINT_MACHINE_ZOOM_LEVELS}`, ApiType.APIM)
