import { MenuItem } from 'src/Components/UIComponents'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import type { MouseEvent } from 'react'
import { useLanguage } from 'src/Hooks/useLanguage'

type OwnProps = {
  onClick?: (event: MouseEvent) => void
}

export const CriticalityResetMenuItemButton = ({ onClick }: OwnProps) => {
  const { t } = useLanguage()

  return (
    <MenuItem onClick={onClick}>
      <NotificationsNoneIcon />
      {t('assets.criticalityReset.description')}
    </MenuItem>
  )
}
