import { ApiType } from 'src/Types/ApiType'
import { AssetType } from 'src/Types/AssetTypes'

import type { DateRange } from 'src/Types/DatePeriod'
import type { AssetWithAlarms } from 'src/Types/AssetWithAlarms'
import { InternalAlarmSystem } from 'src/Types/AlarmSystem'
import type { OrchestratedTrackCircuit, NewTrackCircuit } from 'src/Types/NewTrackCircuit'
import type { GetOrchestratedAssetsParams } from 'src/Types/GetOrchestratedAssetsParams'

import { callApiGet } from 'src/msGraphClient'
import { getCriticalityValue } from 'src/Utils/criticality'
import { getOrchestratedAssetIdParams } from 'src/Utils/asset'
import config from 'src/Config/Config'

const { ORCHESTRATED_TRACK_CIRCUITS } = config.API_FRAGMENTS.apim

export const getOrchestratedTrackCircuits = (params: GetOrchestratedAssetsParams): Promise<AssetWithAlarms[]> => {
  const urlParams = new URLSearchParams({
    fromDate: new Date(params.dateRange.fromDate).toJSON(),
    toDate: new Date(params.dateRange.toDate).toJSON(),
    onlyWithAlarms: params.onlyWithAlarms.toString(),
    feFilterEnabled: (!params.enableAllAlarmTypes).toString(),
  })

  const { idKey, value } = getOrchestratedAssetIdParams(params)
  urlParams.set(idKey, value)

  return callApiGet<OrchestratedTrackCircuit[]>(`${ORCHESTRATED_TRACK_CIRCUITS}?${urlParams.toString()}`, ApiType.APIM, {
    parseDates: true,
  })
    .then(res =>
      res.map<AssetWithAlarms>(entry => {
        const asset: NewTrackCircuit = {
          assetType: AssetType.TrackCircuit,
          baneDataId: entry.assetData.baneDataId,
          name: entry.assetData.name,
          baneDataLocationName: entry.assetData.locationName,
          baneDataLocationId: entry.assetData.locationId,
          fromKm: entry.trackCircuitAssetData.fromKm,
          toKm: entry.trackCircuitAssetData.toKm,
          isMonitored: entry.status.isMonitored,
          componentCriticalityLocal: getCriticalityValue(entry.assetData, 'ComponentCriticalityLocal'),
          alarmCriticalityAbsolute: getCriticalityValue(entry.assetData, 'AlarmCriticalityAbsolute'),
          totalCriticalityAbsolute: getCriticalityValue(entry.assetData, 'TotalCriticalityAbsolute'),
          statusWithoutAssetStatusWindows: entry.status.statusWithoutAssetStatusWindows,
          statusWithAssetStatusWindows: entry.status.statusWithAssetStatusWindows,
          showCriticality: entry.status.showCriticality,
          dailyStatus: entry.status.dailyStatus,
          assetStatusWindows: entry.status.assetStatusWindows,
          trackCircuitAssetData: entry.trackCircuitAssetData,
        }

        return {
          asset,
          alarms: entry.aggregateAlarms.map(alarm => ({
            ...alarm,
            internalAlarmSystem: InternalAlarmSystem.AggregatedTrackCircuitAlarm,
          })),
        }
      })
    )
    .then(result => result.sort((a, b) => a.asset.name.localeCompare(b.asset.name, config.STRING_LOCALE)))
}

export const getTrackCircuitsAtLocations = (
  dateRange: DateRange,
  locationIds: string[],
  onlyWithAlarms: boolean,
  enableAllAlarmTypes: boolean
) => getOrchestratedTrackCircuits({ dateRange, locationIds, onlyWithAlarms, enableAllAlarmTypes })

export const getTrackCircuitsWithIds = (
  dateRange: DateRange,
  baneDataIds: string[],
  onlyWithAlarms: boolean,
  enableAllAlarmTypes: boolean = true
) => getOrchestratedTrackCircuits({ dateRange, baneDataIds, onlyWithAlarms, enableAllAlarmTypes })
