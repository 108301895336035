import { styled } from '@mui/material/styles'
import type { CheckboxProps } from '@mui/material/Checkbox'
import MUICheckbox from '@mui/material/Checkbox'

import themeColors from 'src/theme'

const StyledCheckbox = styled(MUICheckbox)`
  padding: 0 5px;
  border-radius: 0;
  &:disabled {
    color: ${themeColors.disabled};
  }
  &:hover {
    background-color: transparent;
    color: ${themeColors.tertiary};
  }
`

export const Checkbox = (props: CheckboxProps) => (
  <StyledCheckbox
    {...props} // eslint-disable-line react/jsx-props-no-spreading
    color={props.color || 'primary'}
  />
)
