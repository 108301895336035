import { useState, useEffect, useCallback } from 'react'
import type { TemperatureParameter } from 'src/Features/SwitchHeat/Adjustment/TemperatureForm'

export type TemperatureParameterDiff = {
  sensorId: string
  previousValue: number
  currentValue: number
  currentValueTime: number
}

/** Hook to detect remotely changed values in temperature parameters */
export const useDetectRemoteChanges = (temperatureParameters: TemperatureParameter[]) => {
  const [previousTemperatureParameters, setPreviousTemperatureParameters] = useState(() => temperatureParameters)
  const [remoteChanges, setRemoteChanges] = useState<TemperatureParameterDiff[]>([])

  useEffect(() => {
    setPreviousTemperatureParameters(temperatureParameters)
    const diff: TemperatureParameterDiff[] = []
    temperatureParameters.forEach(temperatureParameter => {
      const previousParameter = previousTemperatureParameters.find(prev => prev.sensorId === temperatureParameter.sensorId)
      if (!previousParameter) {
        return
      }

      if (previousParameter.value !== temperatureParameter.value) {
        diff.push({
          sensorId: temperatureParameter.sensorId,
          previousValue: previousParameter.value,
          currentValue: temperatureParameter.value,
          currentValueTime: temperatureParameter.currentValueTime,
        })
      }
    })

    const diffIds = diff.map(diff => diff.sensorId)
    const changedValuesToKeep = remoteChanges.filter(changedValue => !diffIds.includes(changedValue.sensorId))

    setRemoteChanges([...diff, ...changedValuesToKeep])
  }, [temperatureParameters]) // eslint-disable-line react-hooks/exhaustive-deps

  const removeRemoteChange = useCallback(
    (sensorId: string) => {
      const filtered = remoteChanges.filter(changedValue => changedValue.sensorId !== sensorId)
      if (filtered.length !== remoteChanges.length) {
        setRemoteChanges(filtered)
      }
    },
    [remoteChanges]
  )

  return { remoteChanges, removeRemoteChange }
}
