import type * as d3 from 'd3'
import type { SimpleSelection } from 'src/Types/ChartTypes'
import { GestureDirectionDetector } from 'src/Components/Chart/GestureDirectionDetector'

export const enablePageScrollForChartRenderer = (
  svgContainer: SimpleSelection<SVGSVGElement>,
  zoom: d3.ZoomBehavior<SVGSVGElement, unknown>
) => {
  const detector = new GestureDirectionDetector()
  let verticalDetected = false

  const handleScrollDetection = (event: UIEvent) => {
    if (event instanceof TouchEvent) {
      const isMultipleFingerTouch = event.changedTouches.length !== 1
      if (isMultipleFingerTouch) {
        zoom.touchPreventDefault(true)
        detector.reset()
        return
      }

      if (event.type === 'touchend' || event.type === 'touchcancel') {
        verticalDetected = false
        detector.reset()
      }

      const touch = event.changedTouches[0]
      const { clientX, clientY } = touch

      detector.addPoint([clientX, clientY])

      const isVerticalMove = detector.isVerticalMove()

      if (isVerticalMove === true) {
        verticalDetected = true
        zoom.touchPreventDefault(false)
      }

      if (isVerticalMove === false && !verticalDetected) {
        zoom.touchPreventDefault(true)
      }
    }
  }

  svgContainer.call(zoom)

  const element = svgContainer.node()!
  const options = { passive: true, capture: true }
  element.addEventListener('touchstart', handleScrollDetection, options)
  element.addEventListener('touchend', handleScrollDetection, options)
  element.addEventListener('touchmove', handleScrollDetection, options)
}
