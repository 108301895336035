import { getAccessGroups } from 'src/Providers/AccessControl'
import { useUserProfileStore } from 'src/Store/userProfile'
import { useQueryWithErrorHandling } from './useQueryWithErrorHandling'

export const useAccessGroups = () =>
  useQueryWithErrorHandling(
    ['accessGroups'],
    () =>
      getAccessGroups().then(accessGroups => {
        useUserProfileStore.getState().accessGroups.setAccessGroups(accessGroups)

        return accessGroups
      }),
    {
      staleTime: Infinity,
    },
    'general.accessGroupsFetchError'
  )
