import type { ReactNode } from 'react'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'

const StyledContainer = styled(Grid)`
  font-size: 12px;
`

const StyledValueContainer = styled(Grid)`
  white-space: nowrap;
`

type OwnProps = {
  icon: ReactNode
  iconTitle?: string
  value: ReactNode
}

export const CardSection = ({ icon, iconTitle, value }: OwnProps) => (
  <StyledContainer container spacing={1} direction="column" justifyContent="center" alignItems="center">
    <Grid item xs={12} title={iconTitle}>
      {icon}
    </Grid>
    <StyledValueContainer item xs={12}>
      {value}
    </StyledValueContainer>
  </StyledContainer>
)
