import { useEffect } from 'react'
import { useStartPage } from 'src/Hooks/useStartPage'
import { QueryStringStateKeys, useQueryStringState } from 'src/Store/useQueryStringState'
import type { SortOrder } from 'src/Types/SortOrder'
import type { Filter } from 'src/Types/Filter'

type OwnParameters = {
  setSortOrder: (sortOrder: SortOrder) => void
  setFilters: (filters: Filter[]) => void
  onFilterLoaded?: () => void
}

export const useApplyStartPageFilter = ({ setSortOrder, setFilters, onFilterLoaded }: OwnParameters) => {
  const [loaded, setLoaded] = useQueryStringState(QueryStringStateKeys.loaded)

  const { startPageFilter, isReady: isPageFilterReady } = useStartPage()

  useEffect(() => {
    if (!isPageFilterReady || !loaded) {
      return
    }

    if (startPageFilter) {
      if (startPageFilter.sortOrder) {
        setSortOrder(startPageFilter.sortOrder)
      }
      if (startPageFilter.filters) {
        setFilters(startPageFilter.filters)
      }
      onFilterLoaded?.()
    }

    setLoaded(undefined)
  }, [isPageFilterReady, startPageFilter, loaded]) // eslint-disable-line react-hooks/exhaustive-deps
}
