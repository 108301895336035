import merge from 'deepmerge'
import type { Config } from 'src/Config/Config'

type DeepPartial<T> = {
  [K in keyof T]?: T[K] extends object ? DeepPartial<T[K]> : T[K]
}

/**
 * Helper function to merge configs with typechecking.
 * @param config Source config
 * @param overrides Config to override
 */
export const mergeConfig = (config: DeepPartial<Config>, overrides: DeepPartial<Config>) => {
  return merge(config, overrides) as Partial<Config>
}
