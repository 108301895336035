import type { ReactNode } from 'react'
import { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import MUIButton from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Switch from '@mui/material/Switch'
import UserIcon from '@mui/icons-material/PersonOutlined'

import { Button } from 'src/Components/Button'
import { NewAlarmSound } from 'src/Types/NewAlarmSound'
import type { RecentAlarmPeriod } from 'src/Types/OpenedAlarm'
import type { SystemStatus } from 'src/Types/SystemStatus'

import { useDomainConstantsStore } from 'src/Store/domainConstants'
import { useUserProfileStore } from 'src/Store/userProfile'
import { useBreakpointDown } from 'src/Hooks/useBreakpoint'
import { useNewAlarmSound } from 'src/Hooks/useNewAlarmSound'
import { getAccount } from 'src/msGraphClient'
import { useLanguage } from 'src/Hooks/useLanguage'
import themeColors from 'src/theme'
import { VersionInfoPanel } from 'src/Components/VersionInfoPanel'
import { ALL_CURRENT_STATUS_OPTIONS, ALL_SYSTEM_OPTIONS } from 'src/Features/UserSettings/UserSettingConstants'
import config from 'src/Config/Config'
import { useSessionFeatures, SessionFeatures } from 'src/Hooks/useSessionFeature'
import { useAccess } from 'src/Hooks/useAccess'
import { useFeatureOverride } from 'src/Hooks/useFeatureOverride'
import { useLocation } from 'react-router-dom'
import { isBranchDeployed } from 'src/Utils/environment'

type Setting = {
  key: string
  label: string
  value: string | ReactNode
  clearButton: {
    onClick: () => void
    disabled: boolean
  }
}

type UserSetting = {
  header: string
  settings: Setting[]
}

const useStyles = makeStyles()(theme => ({
  paper: {
    padding: 16,
  },
  headerInfo: {
    padding: 0,
  },
  h3: {
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 6,
    },
  },
  description: {
    marginTop: 6,
  },
  button: {
    padding: 0,
    marginLeft: -8,
    marginRight: -8,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      marginLeft: -16,
      marginRight: -20,
    },
  },
  header: {
    backgroundColor: themeColors.sectionHeader,
  },
  entry: {
    paddingLeft: 20,
    paddingRight: 8,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  value: {
    textAlign: 'right',
    fontSize: '0.875rem',
    paddingRight: 16,
  },
  list: {
    padding: 0,
  },
  listItem: {
    padding: '2px 0',
    justifyContent: 'flex-end',
  },
  alternateRow: {
    backgroundColor: themeColors.sectionAlternateRow,
  },
  border: {
    border: `2px solid ${themeColors.hr}`,
    marginBottom: 8,
  },
}))

export const UserProfile = () => {
  const { t, currentLanguage } = useLanguage()
  const { classes } = useStyles()
  const isMediaSM = useBreakpointDown('sm')

  const [dialogOpen, setDialogOpen] = useState(false)

  const updateSetting = useUserProfileStore(state => state.settings.updateSetting)
  const settings = useUserProfileStore(state => state.settings.settings)
  const { defaultSelectedStatusFilter, defaultSelectedSystemFilter, hideChartZoomButtons } = settings
  const { features, toggleFeature } = useSessionFeatures()

  const user = getAccount()

  const favorites = useUserProfileStore(state => state.favorites.favorites)
  const setFavorites = useUserProfileStore(state => state.favorites.setFavorites)
  const openedAlarms = useUserProfileStore(state => state.openedAlarms.openedAlarms)
  const setOpenedAlarms = useUserProfileStore(state => state.openedAlarms.setOpenedAlarms)
  const recentAlarmPeriod = useUserProfileStore(state => state.settings.settings.recentAlarmPeriod)
  const defaultExpanded = useUserProfileStore(state => state.subHeader.defaultExpanded)
  const clearDefaultExpanded = useUserProfileStore(state => state.subHeader.clearDefaultExpanded)
  const classificationDefinitions = useDomainConstantsStore(state => state.alarmTypes.classifications)
  const hasBetaTestAccess = useAccess('BetaTest', 'Betatest')
  const forceFeatureToggle = useFeatureOverride(SessionFeatures.ForceFeatureToggle)
  const enableAllAlarmTypes = useFeatureOverride(SessionFeatures.EnableAllAlarmTypes)
  const location = useLocation()
  const defaultExpandedCount = Object.keys(defaultExpanded).length

  const statusLabel = (status: SystemStatus) => {
    const localization = classificationDefinitions[status]
    return localization ? localization.localization.full[currentLanguage] : t(`sideMenu.status.${status.toLowerCase()}`)
  }
  const uu = (a: URLSearchParams) => {
    const contrastCheck = Array.from(a.entries()).some(
      ([key, value]) => key.toLowerCase() === 'contrast' && value.toLowerCase() === 'high'
    )
    return contrastCheck || isBranchDeployed(window.location.pathname)
  }

  const { newAlarmSound, playAlarmSound, changeAlarmSound } = useNewAlarmSound()

  const handleSelectStatus = (options: SystemStatus[]) => updateSetting('defaultSelectedStatusFilter', options as SystemStatus[])

  const handleSelectSystem = (options: string[]) => updateSetting('defaultSelectedSystemFilter', options)

  const handleChangeNewAlarmSound = (sound: NewAlarmSound) => {
    changeAlarmSound(sound)
    playAlarmSound(sound)
  }

  const userSettings: UserSetting[] = [
    {
      header: t('userSettings.viewSettings'),
      settings: [
        {
          key: 'status',
          label: t('userSettings.status'),
          value: (
            <FormControl className={classes.formControl}>
              <Select
                value={defaultSelectedStatusFilter}
                onChange={event => handleSelectStatus(event.target.value as SystemStatus[])}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
                renderValue={(selected: unknown) =>
                  (selected as string[]).length > 0
                    ? (selected as SystemStatus[]).map(s => statusLabel(s)).join(', ')
                    : t('userSettings.notSelected')
                }
                displayEmpty
                multiple
              >
                {ALL_CURRENT_STATUS_OPTIONS.map(option => (
                  <MenuItem key={option} value={option}>
                    <Checkbox checked={defaultSelectedStatusFilter.includes(option)} />
                    {statusLabel(option)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ),
          clearButton: {
            onClick: () => updateSetting('defaultSelectedStatusFilter', []),
            disabled: !defaultSelectedStatusFilter.length,
          },
        },
        {
          key: 'system',
          label: t('userSettings.system'),
          value: (
            <FormControl className={classes.formControl}>
              <Select
                value={defaultSelectedSystemFilter}
                onChange={event => handleSelectSystem(event.target.value as string[])}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
                renderValue={(selected: unknown) =>
                  (selected as string[]).length > 0
                    ? (selected as string[]).map(s => t(`sideMenu.system.${s.toLowerCase()}`)).join(', ')
                    : t('userSettings.notSelected')
                }
                displayEmpty
                multiple
              >
                {ALL_SYSTEM_OPTIONS.map(option => (
                  <MenuItem key={option} value={option}>
                    <Checkbox checked={defaultSelectedSystemFilter.includes(option)} />
                    {t(`sideMenu.system.${option.toLowerCase()}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ),
          clearButton: {
            onClick: () => updateSetting('defaultSelectedSystemFilter', []),
            disabled: !defaultSelectedSystemFilter.length,
          },
        },
      ],
    },
    {
      header: t('userSettings.appSettings'),
      settings: [
        {
          key: 'newAlarmSound',
          label: t('userSettings.newAlarmSound'),
          value: (
            <FormControl className={classes.formControl}>
              <Select
                value={newAlarmSound}
                onChange={event => handleChangeNewAlarmSound(event.target.value as NewAlarmSound)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
              >
                <MenuItem key={NewAlarmSound.None} value={NewAlarmSound.None}>
                  {t(`userSettings.sound.${NewAlarmSound.None}`)}
                </MenuItem>
                <MenuItem key={NewAlarmSound.Default} value={NewAlarmSound.Default}>
                  {t(`userSettings.sound.${NewAlarmSound.Default}`)}
                </MenuItem>
                <MenuItem key={NewAlarmSound.Train} value={NewAlarmSound.Train}>
                  {t(`userSettings.sound.${NewAlarmSound.Train}`)}
                </MenuItem>
              </Select>
            </FormControl>
          ),
          clearButton: {
            onClick: () => updateSetting('newAlarmSound', NewAlarmSound.None),
            disabled: newAlarmSound === NewAlarmSound.None,
          },
        },
        {
          key: 'recentAlarmPeriod',
          label: t('userSettings.recentAlarmPeriod'),
          value: (
            <FormControl className={classes.formControl}>
              <Select
                id="recentAlarmPeriod-select"
                value={recentAlarmPeriod}
                onChange={event => updateSetting('recentAlarmPeriod', event.target.value as RecentAlarmPeriod)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
              >
                <MenuItem key="week" value="week">
                  {t('userSettings.week')}
                </MenuItem>
                <MenuItem key="workweek" value="workweek">
                  {t('userSettings.workweek')}
                </MenuItem>
              </Select>
            </FormControl>
          ),
          clearButton: {
            onClick: () => updateSetting('recentAlarmPeriod', 'week'),
            disabled: recentAlarmPeriod === 'week',
          },
        },
        {
          key: 'hideChartZoomButtons',
          label: t('userSettings.hideChartZoomButtons'),
          value: (
            <>
              {t('general.no')}
              <Switch
                checked={hideChartZoomButtons}
                onChange={() => updateSetting('hideChartZoomButtons', !hideChartZoomButtons)}
                color="primary"
                name="hideChartZoomButtons"
                inputProps={{ 'aria-label': t('userSettings.hideChartZoomButtons') }}
              />
              {t('general.yes')}
            </>
          ),
          clearButton: {
            onClick: () => updateSetting('hideChartZoomButtons', false),
            disabled: !hideChartZoomButtons,
          },
        },
      ],
    },
    {
      header: t('userSettings.savedElements'),
      settings: [
        {
          key: 'openedAlarms',
          label: t('userSettings.openedAlarms'),
          value: openedAlarms.length,
          clearButton: {
            onClick: () => setOpenedAlarms([]),
            disabled: openedAlarms.length === 0,
          },
        },
        {
          key: 'favorites',
          label: t('userSettings.favorites'),
          value: favorites.length,
          clearButton: {
            onClick: () => setFavorites([]),
            disabled: favorites.length === 0,
          },
        },
        {
          key: 'defaultExpanded',
          label: t('userSettings.defaultExpanded'),
          value: defaultExpandedCount,
          clearButton: {
            onClick: () => clearDefaultExpanded(),
            disabled: defaultExpandedCount === 0,
          },
        },
      ],
    },
  ]

  if (hasBetaTestAccess || forceFeatureToggle) {
    const enableExGenUpdate = config.FEATURES?.EXGEN_UPDATE
    const betaFeatures = enableExGenUpdate
      ? [
          {
            label: t('userSettings.exGenFeatureOn'),
            feature: SessionFeatures.ExGenFeatureOn,
          },
        ]
      : []
    const developerFeatures = [
      {
        label: t('userSettings.debugLod'),
        feature: SessionFeatures.LodPanel,
      },
      {
        label: t('userSettings.disableLazyLoad'),
        feature: SessionFeatures.DisableLazyLoad,
      },
      {
        label: t('userSettings.newNavigation'),
        feature: SessionFeatures.UseRewrittenNavigation,
      },
      {
        label: t('userSettings.logReactParams'),
        feature: SessionFeatures.LogReactParams,
      },
      {
        label: t('userSettings.enableStubbedSystemStatusAlerts'),
        feature: SessionFeatures.EnableStubbedSystemStatusAlerts,
      },
      {
        label: t('userSettings.enableAllAlarmTypes'),
        feature: SessionFeatures.EnableAllAlarmTypes,
      },
    ]

    if (!uu(new URLSearchParams(location.search))) {
      developerFeatures.pop()
    }

    userSettings.push({
      header: t('userSettings.featureToggles'),
      settings: betaFeatures.map(({ label, feature }) => ({
        key: feature,
        label,
        value: (
          <>
            {t('general.no')}
            <Switch checked={features.includes(feature)} onChange={() => toggleFeature(feature)} color="primary" name={feature} />
            {t('general.yes')}
          </>
        ),
        clearButton: {
          onClick: () => toggleFeature(feature, false),
          disabled: !features.includes(feature),
        },
      })),
    })
    userSettings.push({
      header: t('userSettings.developerSettings'),
      settings: developerFeatures.map(({ label, feature }) => ({
        key: feature,
        label,
        value: (
          <>
            {t('general.no')}
            <Switch checked={features.includes(feature)} onChange={() => toggleFeature(feature)} color="primary" name={feature} />
            {t('general.yes')}
          </>
        ),
        clearButton: {
          onClick: () => toggleFeature(feature, false),
          disabled: !features.includes(feature),
        },
      })),
    })
  }

  return (
    <>
      <MUIButton
        onClick={() => setDialogOpen(true)}
        color="inherit"
        title={t('general.language.toggle')}
        className={classes.button}
      >
        <UserIcon titleAccess={t('userSettings.title')} color={enableAllAlarmTypes ? 'info' : 'inherit'} />
      </MUIButton>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div className={classes.paper}>
          <DialogTitle id="modal-title" className={classes.headerInfo}>
            {t('userSettings.title')}
            {user?.name && ` - ${user.name}`}
          </DialogTitle>
          <ListItem dense className={classes.headerInfo}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <ListItemText>
                  <b>{t('userSettings.user')}:</b>
                </ListItemText>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ListItemText disableTypography>{user?.username}</ListItemText>
              </Grid>
            </Grid>
          </ListItem>
          {
            // TODO: Add contract area, story #3375
          }
          <h3 className={classes.h3}>{t('userSettings.userSettings')}</h3>
          <p id="modal-description" className={classes.description}>
            <span>{t('userSettings.description')}</span>{' '}
            <span>
              {t('userSettings.privacyText')}{' '}
              <a href={config.PRIVACY_INFO_URL} target="_blank" rel="noreferrer">
                {t('userSettings.privacyLink')}
              </a>
              .
            </span>
          </p>
          {userSettings.map(userSetting => (
            <div key={userSetting.header} className={classes.border}>
              <Grid className={classes.header}>
                <ListItem dense className={classes.entry}>
                  <ListItemText>
                    <b>{userSetting.header}</b>
                  </ListItemText>
                </ListItem>
              </Grid>
              {userSetting.settings.map((setting, index) => (
                <Grid key={setting.key} container className={index % 2 ? classes.alternateRow : ''}>
                  <Grid item xs={12}>
                    <ListItem dense className={classes.entry}>
                      <Grid container>
                        <Grid item xs={12} sm={4}>
                          <ListItemText>
                            <b>{setting.label}:</b>
                          </ListItemText>
                        </Grid>
                        <Grid item xs={8} sm={5}>
                          <ListItemText disableTypography className={classes.value}>
                            {setting.value}
                          </ListItemText>
                        </Grid>
                        <Grid item xs={3} sm={3}>
                          <Button
                            variant="outlined"
                            onClick={setting.clearButton.onClick}
                            size={isMediaSM ? 'small' : 'medium'}
                            disabled={setting.clearButton.disabled}
                          >
                            {t('general.clear')}
                          </Button>
                        </Grid>
                      </Grid>
                    </ListItem>
                  </Grid>
                </Grid>
              ))}
            </div>
          ))}
          <VersionInfoPanel />
        </div>
      </Dialog>
    </>
  )
}
