import { useQueryWithErrorHandling } from './useQueryWithErrorHandling'
import { getDeepRailways } from 'src/Providers/DeepRailways'
import type { DeepRailway } from 'src/Types/DeepRailway'
import { useGenericCacheBehaviour } from 'src/Hooks/useGenericCacheBehaviour'

export const useDeepRailways = () => {
  const { getFreshOrCachedValue } = useGenericCacheBehaviour<DeepRailway[]>({
    partialKey: 'railwaysCache',
    getFreshValue: () => getDeepRailways(),
  })

  return useQueryWithErrorHandling(
    ['deeprailways'],
    () => getFreshOrCachedValue(),
    {
      staleTime: Infinity,
    },
    'location.region.fetchError'
  )
}
