import type { Configuration } from '@azure/msal-browser'

import { ApiType } from 'src/Types/ApiType'

import type { Config } from './Config'

const MSAL_CONFIG: Configuration = {
  auth: {
    clientId: 'b2095f8f-c451-40b1-8336-9784ca7d9059', // bn-m40-track-monitoring-frontend-appreg-prod
    authority: 'https://login.microsoftonline.com/6ee535f2-3064-4ac9-81d8-4ceb2ff790c6',
    redirectUri: 'https://sporovervaking.banenor.no',
  },
  cache: {
    cacheLocation: 'memoryStorage',
    storeAuthStateInCookie: true, // Set this to 'true' if you are having issues on IE11 or Edge
  },
}

const config: Partial<Config> = {
  MSAL_CONFIG,
  API: {
    [ApiType.APIM]: {
      tokenRequest: {
        scopes: ['api://62e340ba-2653-4d49-aef1-49dfaccc97e8/frontend'],
      },
      endpoint: 'https://bn-m40-api-gateway-frontend-prod.azure-api.net/',
    },
  },
  TELEMETRY_KEY: '83be7f07-f963-420d-a3bd-6b60cde3839d',
  FEATURES: {
    MANUAL_ASSET_STATUS: true,
    EXGEN_UPDATE: true,
  },
}

export default config
