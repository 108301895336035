import { useEffect } from 'react'
import type { Filter } from 'src/Types/Filter'
import { FilterType } from 'src/Types/Filter'
import type { Asset } from 'src/Types/AssetTypes'

/**
 * This is a custom hook that will sync the id filter with the available assets.
 */
export const useSyncIdFilter = (filters: Filter[], idFilterAssets: Asset[], setFilters: (filters: Filter[]) => void) => {
  useEffect(() => {
    const assetIdFilter = filters.find(f => f.type === FilterType.AssetId)
    if (assetIdFilter) {
      const currentIds = assetIdFilter.values
      const availableIds = idFilterAssets.map(a => `${a.baneDataId}-${a.assetType}`)
      const intersection = currentIds.filter(id => availableIds.includes(id))
      if (intersection.length !== currentIds.length) {
        const filtersWithoutAssetId = filters.filter(f => f.type !== FilterType.AssetId)
        if (intersection.length > 0) {
          filtersWithoutAssetId.push({ type: FilterType.AssetId, values: intersection })
        }
        setFilters(filtersWithoutAssetId)
      }
    }
  }, [filters, idFilterAssets, setFilters])
}
