import { getLeastAdvancedQueryStatus } from 'src/Utils/network'
import { useRemoteUserSettings } from './useRemoteUserSettings'
import { useAccessGroups } from './useAccessGroups'

export const useUserProfile = () => {
  const settingsQuery = useRemoteUserSettings()
  const { status: accessGroupsStatus } = useAccessGroups()

  const queryStatus = getLeastAdvancedQueryStatus([settingsQuery.status, accessGroupsStatus])

  return {
    settings: settingsQuery,
    status: queryStatus,
  }
}
