import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import LanguageIcon from '@mui/icons-material/LanguageOutlined'
import { useNavigate } from 'react-router-dom'

import { Language } from 'src/Types/LanguageTypes'

const StyledLanguageIcon = styled(LanguageIcon)`
  padding-left: 4px;
`

const StyledButton = styled(Button)`
  ${props => props.theme.breakpoints.down('sm')} {
    padding: 0;
    margin-right: -20px;
  }
`

export const LanguageSelector = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  /* Triggers safe re-render with new language. Related to bindI18n config in i18n.ts. */
  const triggerRouterReRender = () => {
    const { pathname, search, hash } = window.location
    navigate(pathname + search + hash, { replace: true })
  }

  const toggleLanguage = async () => {
    const newLang = i18n.language === Language.en ? Language.no : Language.en
    await i18n.changeLanguage(newLang)
    triggerRouterReRender()
  }

  return (
    <StyledButton onClick={toggleLanguage} color="inherit" title={t('general.language.toggle')} data-test="LanguageSelector">
      {i18n.language}
      <StyledLanguageIcon titleAccess={t('general.language.toggle')} />
    </StyledButton>
  )
}
