import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { SubHeaderWithExpandableInfo } from 'src/Components/SubHeaderWithExpandableInfo'
import { AssetInformationEntry } from 'src/Components/AssetCard/AssetInformationEntry'
import { Divider } from 'src/Components/Divider'
import { ExpandableAlarmSensorData } from './ExpandableAlarmSensorData'

import { StyledContent } from 'src/Features/Switches/SwitchCard/SwingInfo/elements'

import type { AssetWithAlarms } from 'src/Types/AssetWithAlarms'
import type { DatePeriod } from 'src/Types/DatePeriod'
import type { TrackCircuit } from 'src/Types/TrackCircuitTypes'

import { getTrackCircuitInfo } from 'src/Utils/trackCircuit'

import { SubHeaderKey } from 'src/Providers/Settings/SubHeader'
import { getTrackCircuitWithNeighbors } from 'src/Providers/TrackCircuit'

type OwnProps = {
  assetWithAlarms: AssetWithAlarms
  datePeriod: DatePeriod
}

export const TrackCircuitCardExpandedContent = ({ assetWithAlarms, datePeriod }: OwnProps) => {
  const { t } = useTranslation()

  const asset = assetWithAlarms.asset
  const [trackCircuit, setTrackCircuit] = useState<TrackCircuit>()

  useEffect(() => {
    getTrackCircuitWithNeighbors(asset.baneDataId).then(setTrackCircuit)
  }, [asset.baneDataId])

  if (!trackCircuit) {
    return null
  }
  const trackCircuitInfo = getTrackCircuitInfo(trackCircuit)

  return (
    <>
      {!assetWithAlarms.alarms.length && (
        <ExpandableAlarmSensorData datePeriod={datePeriod} asset={asset} expandReturnCurrentChart isTrackCircuitsTab />
      )}
      <SubHeaderWithExpandableInfo
        title={t('trackCircuits.infoHeader', { trackCircuitName: trackCircuit.m40TrackCircuit.tcId })}
        isDefaultExpanded
        subHeaderKey={SubHeaderKey.TrackCircuitsInfo}
      >
        <StyledContent>
          {Object.entries(trackCircuitInfo).map(([key, value]) => (
            <AssetInformationEntry key={key} label={t(`trackCircuits.chart.trackCircuitInfo.${key}`)} values={[value]} />
          ))}
        </StyledContent>
        <Divider />
      </SubHeaderWithExpandableInfo>
    </>
  )
}
