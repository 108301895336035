import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Alert } from 'src/Components/Alert'
import { AssetCardSkeleton } from 'src/Components/AssetCard/AssetCardSkeleton'
import { NoResult } from 'src/Components/NoResult'
import { FullWidthLoader } from 'src/Components/FullWidthLoader'
import { SidePanelMenu } from 'src/Components/SidePanelMenu/SidePanelMenu'
import { SystemStatusAlerts } from 'src/Features/SystemStatusAlerts/SystemStatusAlerts'
import { AssetsWithAlarms } from 'src/Features/Alarms/AlarmList/AssetsWithAlarms'

import { AssetType } from 'src/Types/AssetTypes'
import type { Favorite } from 'src/Types/Favorite'
import type { DatePeriod } from 'src/Types/DatePeriod'

import { useUserProfileStore } from 'src/Store/userProfile'
import { getLeastAdvancedQueryStatus } from 'src/Utils/network'

import { usePointMachineSwitchesWithAlarms } from 'src/Hooks/NetworkData/usePointMachineSwitchesWithAlarms'
import { useMachineHeatSwitchesWithAlarms } from 'src/Hooks/NetworkData/useMachineHeatSwitchesWithAlarms'
import { useTrackCircuitsWithAlarms } from 'src/Hooks/NetworkData/useTrackCircuitsWithAlarms'
import { useSwitchHeatCabinetsWithAlarms } from 'src/Hooks/NetworkData/useSwitchHeatCabinetsWithAlarms'
import { useBendersWithAlarms } from 'src/Hooks/NetworkData/useBendersWithAlarms'
import { useRacksWithAlarms } from 'src/Hooks/NetworkData/useRacksWithAlarms'
import { MainContentWithFilterMenu } from 'src/Components/MainContentWithFilterMenu'
import { useFeatureOverride } from 'src/Hooks/useFeatureOverride'
import { SessionFeatures } from 'src/Hooks/useSessionFeature'

const getFavoritesOfType = (favorites: Favorite[], assetType: AssetType) =>
  favorites.filter(f => f.assetType === assetType).map(f => f.baneDataId)

const FavoriteListComponent = () => {
  const { t } = useTranslation()

  const favorites = useUserProfileStore(state => state.favorites.favorites)
  const selectedDatePeriod: DatePeriod = 'last24'

  const enableAllAlarmTypes = useFeatureOverride(SessionFeatures.EnableAllAlarmTypes)

  const favoritePointMachineSwitches = useMemo(() => getFavoritesOfType(favorites, AssetType.SwitchPointMachine), [favorites])

  const favoriteMachineHeatSwitches = useMemo(() => getFavoritesOfType(favorites, AssetType.SwitchMachineHeat), [favorites])

  const favoriteTrackCircuits = useMemo(() => getFavoritesOfType(favorites, AssetType.TrackCircuit), [favorites])

  const favoriteSwitchHeatCabinets = useMemo(() => getFavoritesOfType(favorites, AssetType.SwitchHeat), [favorites])

  const favoriteBenders = useMemo(() => getFavoritesOfType(favorites, AssetType.Bender), [favorites])

  const favoriteRacks = useMemo(() => getFavoritesOfType(favorites, AssetType.Rack), [favorites])

  const { pointMachineSwitchesWithAlarms = [], status: pointMachineSwitchesStatus } = usePointMachineSwitchesWithAlarms({
    datePeriod: selectedDatePeriod,
    idType: 'baneDataIds',
    ids: favoritePointMachineSwitches,
    enableAllAlarmTypes,
  })

  const { machineHeatSwitchesWithAlarms = [], status: machineHeatSwitchesStatus } = useMachineHeatSwitchesWithAlarms({
    datePeriod: selectedDatePeriod,
    idType: 'baneDataIds',
    ids: favoriteMachineHeatSwitches,
    enableAllAlarmTypes,
  })

  const { trackCircuitsWithAlarms = [], status: trackCircuitsQueryStatus } = useTrackCircuitsWithAlarms({
    datePeriod: selectedDatePeriod,
    idType: 'baneDataIds',
    ids: favoriteTrackCircuits,
    enableAllAlarmTypes,
  })

  const { switchHeatCabinetsWithAlarms = [], status: switchHeatQueryStatus } = useSwitchHeatCabinetsWithAlarms({
    datePeriod: selectedDatePeriod,
    idType: 'baneDataIds',
    ids: favoriteSwitchHeatCabinets,
    enableAllAlarmTypes,
  })

  const { bendersWithAlarms = [], status: bendersQueryStatus } = useBendersWithAlarms({
    datePeriod: selectedDatePeriod,
    idType: 'baneDataIds',
    ids: favoriteBenders,
    enableAllAlarmTypes,
  })

  const { racksWithAlarms = [], status: racksQueryStatus } = useRacksWithAlarms({
    datePeriod: selectedDatePeriod,
    idType: 'baneDataIds',
    ids: favoriteRacks,
    enableAllAlarmTypes,
  })

  const allStatuses = [
    pointMachineSwitchesStatus,
    machineHeatSwitchesStatus,
    trackCircuitsQueryStatus,
    switchHeatQueryStatus,
    bendersQueryStatus,
    racksQueryStatus,
  ]

  const overallStatus = getLeastAdvancedQueryStatus(allStatuses, true)

  const incompleteMessage = (
    tcComplete: boolean,
    pointMachineSwitchesComplete: boolean,
    machineHeatSwitchesComplete: boolean,
    switchHeatComplete: boolean,
    bendersComplete: boolean,
    racksComplete: boolean
  ) => {
    const missing = []
    if (!tcComplete) {
      missing.push(t('alarms.trackCircuit'))
    }
    if (!pointMachineSwitchesComplete) {
      missing.push(t('alarms.switch'))
    }
    if (!machineHeatSwitchesComplete) {
      missing.push(t('alarms.machineHeat'))
    }
    if (!switchHeatComplete) {
      missing.push(t('alarms.switchHeat'))
    }
    if (!bendersComplete) {
      missing.push(t('alarms.bender'))
    }
    if (!racksComplete) {
      missing.push(t('alarms.rack'))
    }

    return t('favorites.incompleteFetchError', { missing: missing.join(', ').toLowerCase() })
  }

  const assetsWithAlarms = useMemo(
    () => [
      ...trackCircuitsWithAlarms,
      ...pointMachineSwitchesWithAlarms,
      ...machineHeatSwitchesWithAlarms,
      ...switchHeatCabinetsWithAlarms,
      ...bendersWithAlarms,
      ...racksWithAlarms,
    ],
    [
      trackCircuitsWithAlarms,
      pointMachineSwitchesWithAlarms,
      machineHeatSwitchesWithAlarms,
      switchHeatCabinetsWithAlarms,
      bendersWithAlarms,
      racksWithAlarms,
    ]
  )

  if (favorites.length === 0) {
    return <NoResult>{t('favorites.noneSaved')}</NoResult>
  }

  if (overallStatus === 'idle') {
    return null
  }

  if (allStatuses.every(s => s === 'error')) {
    return <NoResult>{t('favorites.fetchError')}</NoResult>
  }

  if (overallStatus === 'loading') {
    return (
      <>
        <FullWidthLoader />
        {[...Array(favorites.length).keys()].map(i => (
          <AssetCardSkeleton key={i} />
        ))}
      </>
    )
  }

  return (
    <>
      {overallStatus === 'error' && (
        <Alert severity="error" margin={16}>
          {incompleteMessage(
            trackCircuitsWithAlarms.length === favoriteTrackCircuits.length,
            pointMachineSwitchesWithAlarms.length === favoritePointMachineSwitches.length,
            machineHeatSwitchesWithAlarms.length === favoriteMachineHeatSwitches.length,
            switchHeatCabinetsWithAlarms.length === favoriteSwitchHeatCabinets.length,
            bendersWithAlarms.length === favoriteBenders.length,
            racksWithAlarms.length === favoriteRacks.length
          )}
        </Alert>
      )}
      <Alert severity="info" margin={16}>
        {t('favorites.info')}
      </Alert>
      <AssetsWithAlarms assetsWithAlarms={assetsWithAlarms} datePeriod={selectedDatePeriod} />
    </>
  )
}

export const FavoriteListPage = () => {
  return (
    <MainContentWithFilterMenu filterMenu={<SidePanelMenu />} contentHeader={<SystemStatusAlerts />}>
      <FavoriteListComponent />
    </MainContentWithFilterMenu>
  )
}
