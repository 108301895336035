import config from 'src/Config/Config'
import { getDeployedAppMetadata } from 'src/Utils/metadata'

const getVersion = async () => {
  const metadata = await getDeployedAppMetadata()
  return metadata.version
}

/**
 * Checks whether the application is running the latest version.
 */
export const isLatestVersion = async () => {
  try {
    const latest = await getVersion()
    return !config.version || !latest || config.version === latest
  } catch (e) {
    return true
  }
}
