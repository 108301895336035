import { getDaysSinceLastAlarm } from 'src/Providers/PointMachineAlarms'
import { useQueryWithErrorHandling } from './useQueryWithErrorHandling'

export const useDaysSinceLastPointMachineAlarm = (baneDataId: string) => {
  const query = useQueryWithErrorHandling(
    ['daysSinceLastAlarm', baneDataId],
    () => getDaysSinceLastAlarm(baneDataId),
    {
      cacheTime: 5 * 60 * 1000,
    },
    'alarms.fetchLastPointMachineAlarmFailed'
  )

  return {
    ...query,
    daysSinceLastAlarm: query.data,
  }
}
