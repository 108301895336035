import type { Asset } from 'src/Types/AssetTypes'
import { getCardIdByAsset } from 'src/Utils/cardId'
import type { DateRange } from 'src/Types/DatePeriod'
import { DatePeriodParam, QueryStringStateKeys } from 'src/Store/useQueryStringState'

export const getPermalinkUrl = (asset: Asset, fromDate?: number, toDate?: number) => {
  const cardId = getCardIdByAsset(asset)
  const url = new URL(window.location.href)
  url.hash = `#${cardId}`
  if (fromDate && toDate) {
    const dateRange: DateRange = { fromDate, toDate }
    const encodedDateRange = DatePeriodParam.encode(dateRange)
    if (encodedDateRange) {
      url.searchParams.set(QueryStringStateKeys.datePeriod, encodedDateRange)
    }
  }
  return url
}
