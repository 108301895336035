import { styled } from '@mui/material/styles'
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant'

import type { AlarmClassification } from 'src/Types/AlarmClassification'

import { useLanguage } from 'src/Hooks/useLanguage'
import { useDomainConstantsStore } from 'src/Store/domainConstants'
import themeColors from 'src/theme'

const StyledAlarmIcon = styled(NotificationImportantIcon)`
  color: ${themeColors.error};
  vertical-align: middle;
`

const StyledWarningIcon = styled(NotificationImportantIcon)`
  color: ${themeColors.warning};
  vertical-align: middle;
`

type OwnProps = {
  classification: AlarmClassification
  size?: 'large' | 'small'
}

export const AlarmIcon = ({ classification, size = 'large' }: OwnProps) => {
  const { t, currentLanguage } = useLanguage()

  const classificationDefinitions = useDomainConstantsStore(state => state.alarmTypes.classifications)

  const localization = classificationDefinitions[classification]?.localization.full
  const translatedClassification = localization ? localization[currentLanguage] : t('alarms.unknownClassification')

  return classification === 'Alarm' ? (
    <StyledAlarmIcon fontSize={size} titleAccess={translatedClassification} />
  ) : (
    <StyledWarningIcon fontSize={size} titleAccess={translatedClassification} />
  )
}
