import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import ListItem from '@mui/material/ListItem'

import { AssetsWithAlarms } from 'src/Features/Alarms/AlarmList/AssetsWithAlarms'
import { SidePanelMenu } from 'src/Components/SidePanelMenu/SidePanelMenu'
import { getDateRange } from 'src/Features/SelectedDatePeriod/predefinedDatePeriods'

import { FilterType } from 'src/Types/Filter'
import type { AssetType } from 'src/Types/AssetTypes'
import type { SortOrder } from 'src/Types/SortOrder'

import { useAppStateStore } from 'src/Store/appState'
import { useUserProfileStore } from 'src/Store/userProfile'
import { useCardIdFromUrl } from 'src/Hooks/useCardIdFromUrl'
import { QueryStringStateKeys, useQueryStringState } from 'src/Store/useQueryStringState'
import { useAlarmFilters } from 'src/Features/Alarms/AlarmList/AlarmFilters/useAlarmFilters'
import { getCardIdByAsset } from 'src/Utils/cardId'
import { StyledActionButton } from 'src/Components/SidePanelMenu/StyledActionButton'
import { isDatePeriodIncludingToday } from 'src/Utils/date'
import { useAddCardFromUrl } from 'src/Hooks/useAddCardFromUrl'
import { usePageFilters } from 'src/Hooks/usePageFilters'
import { useApplyStartPageFilter } from 'src/Hooks/useApplyStartPageFilter'

import { useSyncIdFilter } from 'src/Features/Alarms/AlarmList/AlarmFilters/useSyncIdFilter'
import { useAggregatedAssets } from 'src/Hooks/NetworkData/useAggregatedAssets'
import { AssetListResultMessage } from 'src/Features/Alarms/AlarmList/AssetListResultMessage'
import { AssetListFilters } from 'src/Features/Alarms/AlarmList/AssetListFilters'
import { ViewSelectorButton } from 'src/Components/SidePanelMenu/ViewSelectorButton'
import { useNavigate } from 'react-router-dom'
import { createPageLink } from 'src/Utils/pageLink'
import { useFeatureOverride } from 'src/Hooks/useFeatureOverride'
import { SessionFeatures } from 'src/Hooks/useSessionFeature'
import { AppPage } from 'src/Types/AppPage'
import { SystemStatusAlerts } from 'src/Features/SystemStatusAlerts/SystemStatusAlerts'
import { MainContentWithFilterMenu } from 'src/Components/MainContentWithFilterMenu'
import { getFilteredAndAssetsForIdFiltering } from 'src/Features/Alarms/AlarmList/AlarmFilters/getFilteredAndAssetsForIdFiltering'
import { AutoRefreshToggle } from 'src/Components/AutoRefreshToggle'
import { composeAutoRefreshToggleHelpText } from 'src/Utils/autoRefreshToggle'
import { GENERIC_CARD_PAGE_CONFIG } from 'src/Features/GenericCardListPage/Config'
import type { GenericCardListRoutes } from 'src/Features/GenericCardListPage/Config'
import { getHideCriticality } from 'src/Hooks/getHideCriticality'
import { statusFilterIncludesAssetStatus } from 'src/Utils/filter'

type OwnProps = {
  route: GenericCardListRoutes
  initialSortOrder: SortOrder
}

let autoUpdateTimer: number | undefined
const AUTO_UPDATE_INTERVAL_IN_MINUTES = 5

const StyledFilterStats = styled('div')`
  font-size: 13px;
`

/**
 * GenericCardListPage
 * @param route
 * @param initialSortOrder if it changes, then sortOrder will change also,
 * so it is not merely the initial sort order
 * @constructor
 */
export const GenericCardListPage = ({ route, initialSortOrder }: OwnProps) => {
  const { DEFAULT_SYSTEM_OPTIONS, AVAILABLE_STATUS_OPTIONS, DEFAULT_SELECTED_STATUS, queryLimitOnlyAlarms, enableAutoUpdate } =
    GENERIC_CARD_PAGE_CONFIG[route]

  const { t } = useTranslation()
  const navigate = useNavigate()
  const isRewrittenNavigation = useFeatureOverride(SessionFeatures.UseRewrittenNavigation)

  const [selectedStretchLocations] = useQueryStringState(QueryStringStateKeys.stretchLocations)
  const [datePeriod] = useQueryStringState(QueryStringStateKeys.datePeriod)

  const [sortOrder, setSortOrder] = useState(initialSortOrder)
  const [autoRefresh, setAutoRefresh] = useState(false)
  const [forceToggleAllAlarms, setForceToggleAllAlarms] = useState<boolean>()
  const [allAlarmsOpened, setAllAlarmsOpened] = useState<boolean>(false)
  const [expandedCardIds, setExpandedCardIds] = useState<string[]>([])

  const lastUpdated = useAppStateStore(state => state.selectedDatePeriod.lastUpdated)
  const openedAlarms = useUserProfileStore(state => state.openedAlarms.openedAlarms)
  const commitPendingOpenedAlarms = useUserProfileStore(state => state.openedAlarms.commitPendingOpenedAlarms)
  const selectedStatus = useUserProfileStore(state => state.settings.settings.defaultSelectedStatusFilter).filter(s =>
    AVAILABLE_STATUS_OPTIONS.includes(s)
  )
  const selectedSystem = useUserProfileStore(state => state.settings.settings.defaultSelectedSystemFilter).filter(s =>
    DEFAULT_SYSTEM_OPTIONS.includes(s as AssetType)
  )

  const { filters, setFilters } = useAlarmFilters({
    selectedStretchLocations,
    selectedStatus,
    defaultSelectedStatus: DEFAULT_SELECTED_STATUS,
    selectedSystem,
  })

  useApplyStartPageFilter({ setSortOrder, setFilters })
  const { saveFilters, canSaveFilters } = usePageFilters()
  const enableAllAlarmTypes = useFeatureOverride(SessionFeatures.EnableAllAlarmTypes)

  const {
    assetsWithAlarms,
    status: alarmsQueryStatus,
    statuses,
    refetch: refreshAlarms,
  } = useAggregatedAssets({
    idType: 'baneDataLocationIds',
    ids: selectedStretchLocations,
    datePeriod,
    alertOnNew: autoRefresh,
    assetTypes: DEFAULT_SYSTEM_OPTIONS,
    onlyWithAlarms: queryLimitOnlyAlarms,
    enableAllAlarmTypes,
  })

  useEffect(() => {
    setForceToggleAllAlarms(undefined)
  }, [datePeriod, selectedStretchLocations])

  useEffect(() => {
    if (initialSortOrder) {
      setSortOrder(initialSortOrder)
    }
  }, [initialSortOrder])

  const cardIdFromUrlExists: boolean = !!useCardIdFromUrl()

  useEffect(() => {
    if (!cardIdFromUrlExists && autoRefresh && isDatePeriodIncludingToday(datePeriod)) {
      autoUpdateTimer = window.setInterval(() => refreshAlarms(), AUTO_UPDATE_INTERVAL_IN_MINUTES * 60 * 1000)
    }

    return () => {
      clearInterval(autoUpdateTimer)
    }
  }, [refreshAlarms, datePeriod, autoRefresh, cardIdFromUrlExists])

  useEffect(() => {
    return () => {
      commitPendingOpenedAlarms()
    }
  }, [lastUpdated, commitPendingOpenedAlarms])

  const addCardFromUrl = useAddCardFromUrl()

  const { filteredAssetsWithAlarms, assetsForIdFiltering } = getFilteredAndAssetsForIdFiltering(
    assetsWithAlarms,
    sortOrder,
    openedAlarms,
    filters,
    queryLimitOnlyAlarms,
    datePeriod ? getDateRange(datePeriod).toDate : undefined
  )
  useSyncIdFilter(filters, assetsForIdFiltering, setFilters)

  addCardFromUrl(filteredAssetsWithAlarms, assetsWithAlarms)

  const alarmCardIds = useMemo(
    () =>
      filteredAssetsWithAlarms
        .map(({ asset }) => asset)
        .filter(a => a.statusWithAssetStatusWindows === 'Alarm')
        .map(a => getCardIdByAsset(a)),
    [filteredAssetsWithAlarms]
  )

  useEffect(() => {
    if (alarmsQueryStatus === 'success') {
      setAllAlarmsOpened(!!alarmCardIds.length && alarmCardIds.every(id => expandedCardIds.includes(id)))
    }
  }, [alarmCardIds, alarmsQueryStatus, expandedCardIds])

  const handleExpandedCardIdsChanged = (expandedCardIds: string[], userTriggered: boolean) => {
    setExpandedCardIds(expandedCardIds)
    if (userTriggered) {
      setForceToggleAllAlarms(undefined)
    }
  }

  const clearFilters = () =>
    setFilters([
      {
        type: FilterType.Status,
        values: DEFAULT_SELECTED_STATUS,
      },
    ])

  const handleSaveSearchClicked = () =>
    saveFilters({
      path: route,
      sortOrder,
      filters,
    })

  const handleToggleOpenAllAlarms = () => {
    setForceToggleAllAlarms(!allAlarmsOpened)
  }

  const helpTextForAutoRefreshToggle = composeAutoRefreshToggleHelpText(
    'alarms.autoRefreshInfo',
    AUTO_UPDATE_INTERVAL_IN_MINUTES,
    'general.minutes_plural',
    t
  )

  const filterMenuFooter = (
    <ListItem>
      <StyledActionButton
        variant="outlined"
        onClick={handleToggleOpenAllAlarms}
        disabled={!alarmCardIds.length || alarmsQueryStatus !== 'success'}
      >
        {allAlarmsOpened ? t('sideMenu.closeAllAlarmsButton') : t('sideMenu.openAllAlarmsButton')}
      </StyledActionButton>
    </ListItem>
  )

  const filterMenu = (
    <SidePanelMenu
      footer={datePeriod ? filterMenuFooter : null}
      onClearFilters={clearFilters}
      hideClearButton={!datePeriod}
      onSaveSearch={handleSaveSearchClicked}
      hideSaveButton={!datePeriod || !canSaveFilters}
    >
      {!isRewrittenNavigation && route === AppPage.SignalEarthCards && (
        <ListItem>
          <ViewSelectorButton
            isCardView
            onCardViewUpdated={() => {
              navigate(createPageLink(AppPage.SignalEarthStream))
            }}
          />
        </ListItem>
      )}
      {!isRewrittenNavigation && route === AppPage.TrackCircuitsCards && (
        <ListItem>
          <ViewSelectorButton
            isCardView
            onCardViewUpdated={() => {
              navigate(createPageLink(AppPage.TrackCircuitsStream))
            }}
          />
        </ListItem>
      )}
      {assetsForIdFiltering && datePeriod && (
        <AssetListFilters
          route={route}
          assets={assetsForIdFiltering}
          filters={filters}
          sortOrder={sortOrder}
          disableCriticalitySort={getHideCriticality(datePeriod)}
          statusOptions={AVAILABLE_STATUS_OPTIONS}
          systemOptions={DEFAULT_SYSTEM_OPTIONS}
          onFiltersUpdated={setFilters}
          onSortOrderUpdated={setSortOrder}
        />
      )}
    </SidePanelMenu>
  )

  const getContent = () => {
    return datePeriod ? (
      <>
        <Grid container justifyContent="space-between" alignItems="flex-end">
          <Grid item>
            {!cardIdFromUrlExists && filteredAssetsWithAlarms.length !== assetsWithAlarms.length && (
              <Box ml={2}>
                <StyledFilterStats>
                  {t('sideMenu.filterStats', { showing: filteredAssetsWithAlarms.length, total: assetsWithAlarms.length })}
                </StyledFilterStats>
              </Box>
            )}
          </Grid>
          {!cardIdFromUrlExists && enableAutoUpdate && (
            <AutoRefreshToggle
              autoRefresh={autoRefresh}
              setAutoRefresh={setAutoRefresh}
              isAutoRefreshDisabled={!enableAutoUpdate}
              helpText={helpTextForAutoRefreshToggle}
            />
          )}
        </Grid>

        <AssetListResultMessage
          allStatuses={statuses}
          hasAssets={assetsWithAlarms.length > 0}
          hasFilteredAssets={filteredAssetsWithAlarms.length > 0}
          onlyWithAlarms={queryLimitOnlyAlarms}
        />

        <AssetsWithAlarms
          assetsWithAlarms={filteredAssetsWithAlarms}
          datePeriod={datePeriod}
          showAlarmsInAssetStatusWindows={statusFilterIncludesAssetStatus(filters)}
          forceExpandCardIds={forceToggleAllAlarms === true ? alarmCardIds : undefined}
          forceCollapseCardIds={forceToggleAllAlarms === false ? alarmCardIds : undefined}
          onExpandedCardIdsChanged={handleExpandedCardIdsChanged}
        />
      </>
    ) : (
      <Box ml={2}>{t('sideMenu.info')}</Box>
    )
  }

  return (
    <MainContentWithFilterMenu filterMenu={filterMenu} contentHeader={<SystemStatusAlerts />}>
      {getContent()}
    </MainContentWithFilterMenu>
  )
}
