import { useTranslation } from 'react-i18next'

import { SubHeaderWithExpandableInfo } from 'src/Components/SubHeaderWithExpandableInfo'
import { SwingInfoStatistics } from './SwingInfoStatistics'
import { StyledContainer } from './elements'

import type { SwingStatistics } from 'src/Types/SwingStatistics'
import type { SwitchObject } from 'src/Types/SwitchObject'
import type { MachineInSwitch } from 'src/Types/SwingEntry'

import { SubHeaderKey } from 'src/Providers/Settings/SubHeader'
import { MachinesBasicInfo } from './MachinesBasicInfo'

type OwnProps = {
  asset: SwitchObject
  swingStatistics: SwingStatistics[]
  machinesInSwitch: MachineInSwitch[] | undefined
  isLoading: boolean
}

export const SwitchInfoMinimal = ({ asset, swingStatistics, machinesInSwitch, isLoading }: OwnProps) => {
  const { t } = useTranslation()

  const currentStatistics = swingStatistics[0]

  const renderMachines = () => {
    if (!machinesInSwitch?.length) {
      return null
    }

    const machineBaneDataIds = machinesInSwitch.map(machine => machine.baneDataId)
    const title = t('switches.infoPointMachines', { pointMachineNames: machineBaneDataIds?.join(',') })

    return (
      <SubHeaderWithExpandableInfo title={title} isDefaultExpanded subHeaderKey={SubHeaderKey.SwitchInfoMinimal}>
        <MachinesBasicInfo machines={machinesInSwitch} />
      </SubHeaderWithExpandableInfo>
    )
  }

  return (
    <StyledContainer>
      {renderMachines()}
      {!!currentStatistics && (
        <SwingInfoStatistics baneDataId={asset.baneDataId} swingStatistics={currentStatistics} isLoading={isLoading} />
      )}
    </StyledContainer>
  )
}
