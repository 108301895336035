import { getTrackCircuits } from 'src/Providers/TrackCircuit'
import { useQueryWithErrorHandling } from './useQueryWithErrorHandling'

export const useTrackCircuits = (baneDataIds: string[]) => {
  const query = useQueryWithErrorHandling(
    ['trackCircuits', { baneDataIds }],
    () => getTrackCircuits(baneDataIds),
    {
      staleTime: Infinity,
      enabled: baneDataIds.length > 0,
    },
    'trackCircuits.fetchError'
  )

  return {
    ...query,
    trackCircuits: query.data,
  }
}
