import { getDateRange } from 'src/Features/SelectedDatePeriod/predefinedDatePeriods'

import type { DatePeriod } from 'src/Types/DatePeriod'

import { getBenderSensorHistory } from 'src/Providers/Benders'
import { useAppStateStore } from 'src/Store/appState'
import { useQueryWithErrorHandling } from './useQueryWithErrorHandling'

type UseBenderSensorHistoryParams = {
  baneDataIds: string[]
  codeSystemKeys: string[]
  datePeriod: DatePeriod
}

export const useBenderSensorHistory = ({ baneDataIds, codeSystemKeys, datePeriod }: UseBenderSensorHistoryParams) => {
  const lastUpdated = useAppStateStore(state => state.selectedDatePeriod.lastUpdated)

  const query = useQueryWithErrorHandling(
    ['benderSensorHistory', { baneDataIds, codeSystemKeys, datePeriod, lastUpdated }],
    () => {
      const { fromDate, toDate } = getDateRange(datePeriod)

      return getBenderSensorHistory({
        baneDataIds,
        codeSystemKeys,
        fromDate,
        toDate,
      }).then(res => res.sort((a, b) => codeSystemKeys.indexOf(a.codeSystem) - codeSystemKeys.indexOf(b.codeSystem)))
    },
    {},
    'benders.historyFetchError'
  )

  return {
    ...query,
    sensorHistory: query.data,
  }
}
