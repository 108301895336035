export const envValues = {
  VITE_ENV: import.meta.env.VITE_ENV,
  VITE_IS_LOCAL: import.meta.env.VITE_IS_LOCAL,
  BASE_URL: import.meta.env.BASE_URL,
  VITE_APP_VERSION: import.meta.env.VITE_APP_VERSION,
  VITE_SOURCE_DATE: import.meta.env.VITE_SOURCE_DATE,
  VITE_SOURCE_HASH: import.meta.env.VITE_SOURCE_HASH,
  VITE_BUILD_ID: import.meta.env.VITE_BUILD_ID,
  VITE_BUILD_NUMBER: import.meta.env.VITE_BUILD_NUMBER,
  VITE_BUILD_TIMESTAMP: import.meta.env.VITE_BUILD_TIMESTAMP,
}

/** The react environment we're running in. Either 'prod' or 'dev' */
export const reactAppEnv: string = envValues.VITE_ENV

/** Whether or not the app is running in production mode. */
export const isProd = reactAppEnv === 'prod'

/** For testing build on localhost. */
export const isTestEnv = reactAppEnv === 'test'

/** Whether or not the app is running in local mode. */
export const isLocal = envValues.VITE_IS_LOCAL === 'true'

/**
 * The current version of the app (based on the latest commited git hash.)
 * Currently not available when running locally.
 */
export const version: string = envValues.VITE_APP_VERSION

export const publicUrl: string | undefined = envValues.BASE_URL
