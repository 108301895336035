import { getConnectedSwitches } from 'src/Providers/SwitchHeat'
import { useQueryWithErrorHandling } from './useQueryWithErrorHandling'

export const useConnectedSwitches = (switchHeatCabinetBaneDataId: string) => {
  const query = useQueryWithErrorHandling(
    ['connectedSwitches', { switchHeatCabinetBaneDataId }],
    () => getConnectedSwitches(switchHeatCabinetBaneDataId),
    {
      staleTime: Infinity,
    },
    'switchHeat.connectedSwitches.fetchError'
  )

  return {
    ...query,
    connectedSwitches: query.data,
  }
}
