import { useUserProfileStore } from 'src/Store/userProfile'

type AccessGroup =
  | 'SwitchHeat.Write'
  | 'SwitchHeat.Read'
  | 'TrackCurcuit.Read'
  | 'Assets.Read'
  | 'Switch.Read'
  | 'Alarms.Read'
  | 'Statistics.Read'
  | 'AssetStatus-NoContact.Write'
  | 'AssetStatus-IrregularData.Write'
  | 'ReferenceCurve.Write'
  | 'BetaTest'
  | 'Betatest'

export const useAccess = (...groups: AccessGroup[]) => {
  const accessGroups = useUserProfileStore(state => state.accessGroups.accessGroups)

  return groups.some(group => accessGroups.includes(group))
}
