import { getDateRange } from 'src/Features/SelectedDatePeriod/predefinedDatePeriods'

import type { DatePeriod } from 'src/Types/DatePeriod'

import { getRackSectionSensorHistory } from 'src/Providers/Racks'
import { useAppStateStore } from 'src/Store/appState'
import { useQueryWithErrorHandling } from './useQueryWithErrorHandling'

type UseRackSectionHistoryParams = {
  sensorIds: string[]
  datePeriod: DatePeriod
}

export const useRackSectionHistory = ({ sensorIds, datePeriod }: UseRackSectionHistoryParams) => {
  const lastUpdated = useAppStateStore(state => state.selectedDatePeriod.lastUpdated)

  const query = useQueryWithErrorHandling(
    ['rackSectionHistory', { sensorIds, datePeriod, lastUpdated }],
    () => {
      const { fromDate, toDate } = getDateRange(datePeriod)

      return getRackSectionSensorHistory({
        sensorIds,
        fromDate,
        toDate,
      })
    },
    {},
    'racks.historyFetchError'
  )

  return {
    ...query,
    sensorHistory: query.data,
  }
}
