import { useTranslation } from 'react-i18next'

import { AssetInfoPopper } from 'src/Components/AssetInfoPopper'

import type { TrackCircuit } from 'src/Types/TrackCircuitTypes'
import type { PopperReferenceObject } from 'src/Types/PopperReferenceObject'

import { getTrackCircuitInfo } from 'src/Utils/trackCircuit'

type OwnProps = {
  trackCircuit: TrackCircuit
  anchorElement: PopperReferenceObject
}

export const TrackCircuitInfoPopper = ({ trackCircuit, anchorElement }: OwnProps) => {
  const { t } = useTranslation()

  const trackCircuitInfo = getTrackCircuitInfo(trackCircuit)

  return (
    <AssetInfoPopper
      id={trackCircuit.baneDataId}
      header={`${trackCircuit.baneDataLocationName}: ${trackCircuit.m40TrackCircuit.tcId}`}
      anchorElement={anchorElement}
      rows={Object.entries(trackCircuitInfo).map(([key, value]) => ({
        label: t(`trackCircuits.chart.trackCircuitInfo.${key}`),
        value,
      }))}
    />
  )
}
