import { isPlainObject } from '@mui/utils'
import { AssetType } from 'src/Types/AssetTypes'
import type { Favorite } from 'src/Types/Favorite'

const isAssetType = (value: unknown): value is AssetType => {
  return Object.values(AssetType).includes(value as any)
}

export const parseFavorite = (data: unknown): Favorite | undefined => {
  if (!isPlainObject(data)) {
    return undefined
  }

  const { assetType, baneDataId } = data

  if (!isAssetType(assetType)) {
    return undefined
  }

  if (typeof baneDataId !== 'string') {
    return undefined
  }

  return {
    assetType,
    baneDataId,
  }
}

export const parseFavorites = (data: unknown) => {
  if (!Array.isArray(data)) {
    return []
  }
  return data.reduce<Favorite[]>((agg, x) => {
    const parsed = parseFavorite(x)
    if (parsed) {
      agg.push(parsed)
    }
    return agg
  }, [])
}
