import { useCallback } from 'react'
import { trackException } from 'src/Tracking/trackException'

const ONE_DAY_MILLISECONDS = 86400000

type OwnPrams = {
  partialKey: string
  retentionInMs?: number
}

export const useGenericCache = <T>({ partialKey, retentionInMs = ONE_DAY_MILLISECONDS }: OwnPrams) => {
  const fullStorageKey = `trackMonitoring.cache.${partialKey}`
  const storageTimestampKey = `${fullStorageKey}.timestamp`

  const isOutdatedCache = useCallback(() => {
    const timestampValue = localStorage.getItem(storageTimestampKey)
    if (!timestampValue) {
      return true
    }
    const timestamp = parseInt(timestampValue, 10)
    if (!timestamp) {
      return true
    }
    const age = Math.abs(Date.now() - timestamp)
    return age > retentionInMs
  }, [retentionInMs, storageTimestampKey])

  const getCachedValue = useCallback((): T | undefined => {
    const storedCache = localStorage.getItem(fullStorageKey)

    if (!storedCache) {
      return undefined
    }

    try {
      const cache = JSON.parse(storedCache)
      return cache
    } catch (err) {
      trackException(err)
      return undefined
    }
  }, [fullStorageKey])

  const storeValue = useCallback(
    (value: T) => {
      localStorage.setItem(fullStorageKey, JSON.stringify(value))
      localStorage.setItem(storageTimestampKey, Date.now().toString())
    },
    [fullStorageKey, storageTimestampKey]
  )

  return { isOutdatedCache, getCachedValue, storeValue }
}
