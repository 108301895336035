import { memo } from 'react'
import { styled } from '@mui/material/styles'

import { AssetTypeIcon } from './AssetTypeIcon'
import { AssetCardMainIcon } from './AssetCardMainIcon'
import { AssetCardActionsMenu } from './AssetCardActionsMenu'
import type { AssetWithAlarms } from 'src/Types/AssetWithAlarms'
import type { SystemStatus } from 'src/Types/SystemStatus'

import { getRelevantAlarm } from 'src/Utils/alarms'
import { AssetCardMainAlarmDetails } from 'src/Components/AssetCard/AssetCardMainAlarmDetails'

import themeColors from 'src/theme'
import { Box } from '@mui/material'
import { useLogReactParams } from 'src/Hooks/useLogReactParams'

const StyledContainer = styled('div')(
  props => `
    display: flex;
    gap: 10px;

    ${props.theme.breakpoints.up('lg')} {
      gap: 20px;
    }
  `
)

const StyledIconContainer = styled('div')(
  props => `
    flex: 0;
    margin-right: 10px;
    ${props.theme.breakpoints.up('lg')} {
      margin-right: 0px;
    }
  `
)

const StyledIconWrapper = styled('div')(
  props => `
    width: 0;

    ${props.theme.breakpoints.down('sm')} {
      min-width: 3rem;
    }
    ${props.theme.breakpoints.up('sm')} {
      min-width: 3rem;
    }

    ${props.theme.breakpoints.down('xs')} {
      min-width: 0rem;
    }
  `
)

const StyledContentContainer = styled('div')`
  flex: 1;
`

const StyledAssetTypeAndActionsContainer = styled('div')`
  flex: 0;
  display: flex;
  flex-wrap: wrap;
  align-content: start;

  :last-of-type {
    justify-content: center;
  }
`

type OwnProps = {
  assetWithAlarms: AssetWithAlarms
}

export const AssetCardMainContent = memo(({ assetWithAlarms }: OwnProps) => {
  useLogReactParams('AssetCardMainContent', { assetWithAlarms })

  const { asset, alarms } = assetWithAlarms
  let cardStatus: SystemStatus = asset.statusWithAssetStatusWindows
  const prioritizeWarning = asset.showCriticality && cardStatus === 'Warning'
  const relevantAlarm = getRelevantAlarm(alarms, prioritizeWarning)

  if (!cardStatus) {
    cardStatus = relevantAlarm?.classification ? relevantAlarm.classification : 'Unknown'
  }

  return (
    <StyledContainer>
      <StyledIconContainer>
        <StyledIconWrapper>
          <AssetCardMainIcon asset={asset} cardStatus={cardStatus} />
        </StyledIconWrapper>
      </StyledIconContainer>
      <StyledContentContainer>
        <AssetCardMainAlarmDetails asset={asset} alarms={alarms} hideAlarmInfo={cardStatus === 'NotMonitored'} />
      </StyledContentContainer>
      <StyledAssetTypeAndActionsContainer>
        <Box style={{ color: themeColors.tertiary }}>
          <AssetTypeIcon assetType={asset.assetType} />
        </Box>
        <AssetCardActionsMenu asset={asset} />
      </StyledAssetTypeAndActionsContainer>
    </StyledContainer>
  )
})
