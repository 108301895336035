import type { Weather, WindType, Precipitation } from 'src/Types/WeatherTypes'

/**
 * Returns wind type based on wind speed and wind gust.
 *
 * @param windSpeed - Wind speed value.
 * @param windGust - Wind gust value.
 */
export const getWindType = (windSpeed: number, windGust: number): WindType => {
  if (windSpeed > 30 || windGust > 40) {
    return 'storm'
  }
  if (windSpeed > 15 || windGust > 20) {
    return 'gale'
  }
  return 'low'
}

/**
 * Evaluate whether the provided wind speed and wind gust values qualify as windy weather.
 *
 * @param weather - The weather to evaluate.
 */
export const isWindyWeather = (weather: Weather) => weather.windSpeed > 10 || weather.windGust > 15

/**
 * Is the provided weather at night?
 *
 * @param weather - The weather to evaluate.
 */
export const isWeatherAtNight = (weather: Weather) => {
  const hour = new Date(weather.toTime).getHours()
  return hour < 6 || hour > 18
}

/**
 * Returns precipitation type based on weather.
 *
 * @param weather - The weather to evaluate.
 */
export const getPrecipitation = (weather: Weather): Precipitation => {
  const { precipitation, temperature } = weather

  if (precipitation < 0.2) {
    return 'none'
  }
  if (temperature <= -0.5) {
    return 'snow'
  }
  if (temperature <= 0.5) {
    return 'sleet'
  }
  if (precipitation < 0.5) {
    return 'sprinkle'
  }
  if (precipitation < 1) {
    return 'showers'
  }
  return 'rainy'
}
