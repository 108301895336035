import type { SwingDirection } from 'src/Types/SwingDirectionTypes'
import type {
  SwingContract,
  MachineSwingContract,
  SwingAnalysisContract,
  SwingTrendContract,
} from 'src/Providers/Contracts/SwingContract'
import type { Swing, MachineSwing, SwingAnalysis, SwingTrend } from 'src/Types/SwingTypes'

function mapSwingTrend(swingTrend: SwingTrendContract | null): SwingTrend | undefined {
  if (!swingTrend) {
    return undefined
  }

  const { timeOfLastSlip, lastSlipCurrentValue, swingMargin, lockingMargin } = swingTrend

  return {
    timeOfLastSlip,
    lastSlipCurrentValue: lastSlipCurrentValue ?? undefined,
    lockingMargin,
    swingMargin,
  }
}

const mapSwingAnalysis = (swingAnalysis: SwingAnalysisContract | null): SwingAnalysis => {
  const { swingTrend, phases, alarms } = swingAnalysis || {}

  return {
    isRealTime: false,
    phases,
    swingTrend: swingTrend ? mapSwingTrend(swingTrend) : undefined,
    alarms,
  }
}

const mapMachineSwings = (machineSwing: MachineSwingContract): MachineSwing => {
  const {
    isFromSequence,
    parentBanedataId,
    isComplete,
    banedataId,
    machineSwingId,
    duration,
    sampleFrequency,
    machineType,
    machineTypeId,
    timestamp,
    banedataName,
    banedataNameParsed,
    direction,
    installDate,
    swingAnalysis,
    referenceCurve,
    isInSequenceWithInfo,
    currentMeasurements,
    forceMeasurements,
  } = machineSwing

  return {
    isFromSequence,
    isInSequenceWithInfo: isInSequenceWithInfo || undefined,
    baneDataId: banedataId,
    baneDataName: banedataName,
    baneDataNameParsed: banedataNameParsed,
    machineSwingId,
    switchBaneDataId: parentBanedataId,
    machineType,
    sampleFrequency,
    isComplete,
    direction,
    machineTypeId,
    duration,
    currentMeasurements: currentMeasurements || undefined,
    forceMeasurements,
    swingAnalysis: mapSwingAnalysis(swingAnalysis),
    timestamp,
    installDate,
    referenceCurve,
  }
}

export const mapSwingContract = (swing: SwingContract): Swing => {
  const {
    banedataId,
    banedataName,
    banedataLocationId,
    banedataLocationName,
    switchType,
    direction,
    swingId,
    timestamp,
    machineSwings,
    isCurrent,
  } = swing

  return {
    baneDataId: banedataId,
    baneDataLocationId: banedataLocationId,
    baneDataLocationName: banedataLocationName,
    baneDataName: banedataName,
    swingId,
    direction: direction as SwingDirection,
    machineSwings: machineSwings.map(mapMachineSwings),
    timestamp,
    switchType,
    isCurrent,
  }
}
