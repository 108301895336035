import { AlertMessage } from './AlertMessage'

import { useSystemStatusAlerts } from 'src/Hooks/NetworkData/useSystemStatusAlerts'
import { useLocation } from 'react-router-dom'
import { getRelevantAffectedSystemsByRoute } from 'src/Features/SystemStatusAlerts/getRelevantAffectedSystemsByRoute'
import { useFeatureOverride } from 'src/Hooks/useFeatureOverride'
import { SessionFeatures } from 'src/Hooks/useSessionFeature'
import type { AffectedSystem } from 'src/Types/SystemStatusAlert'
import { aLocalSystemForRouteIsAmongAffected } from 'src/Features/SystemStatusAlerts/aLocalSystemForRouteIsAmongAffected'
import { extractInfoFromDowntimeText } from 'src/Features/SystemStatusAlerts/extractInfoFromDowntimeText'

/**
 * If the alert is meant to be hidden on other pages than its local system
 * (e.g. /trackcircuit has TrackCircuit as an affected system not found (excluded) on other pages' affected systems)
 * AND the current route does not have one of those local systems, then we definitely don't show that alert.
 * @param hideFromOtherPages
 * @param locationPathName
 * @param affectedSystems
 */
const hideAlertInfo = (hideFromOtherPages: boolean, locationPathName: string, affectedSystems: AffectedSystem[]) => {
  return hideFromOtherPages && !aLocalSystemForRouteIsAmongAffected(locationPathName, affectedSystems)
}

/**
 * Gives you the SystemStatusAlerts component for the current route.
 */
export const SystemStatusAlerts = () => {
  const { pathname } = useLocation()
  const { systemStatusAlerts } = useSystemStatusAlerts({
    includeStubbedAlerts: useFeatureOverride(SessionFeatures.EnableStubbedSystemStatusAlerts),
    affectedSystems: getRelevantAffectedSystemsByRoute(pathname),
  })

  const systemStatusAlertsForThisContext = systemStatusAlerts.filter(alert => {
    const { hideFromOtherPages } = extractInfoFromDowntimeText(alert)
    return !hideAlertInfo(hideFromOtherPages, pathname, alert.affectedSystems)
  })

  if (!systemStatusAlertsForThisContext?.length) {
    return null
  }

  return <AlertMessage systemStatusAlerts={systemStatusAlertsForThisContext} />
}
