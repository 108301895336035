import { formatLocalDateTime } from 'src/Utils/format'
import { Trans } from 'react-i18next'
import { YesOrNoDialog } from 'src/Components/YesOrNoDialog'

type OwnProps = {
  baneDataName: string
  newCurveDate: number | undefined
  currentCurveDate: number
  onAccept: () => void
  onReject: () => void
}

export const SwingSetReferenceCurveDialog = ({ baneDataName, newCurveDate, currentCurveDate, onAccept, onReject }: OwnProps) => (
  <YesOrNoDialog open onAccept={onAccept} onReject={onReject} onClose={onReject}>
    <Trans
      i18nKey="switches.setReferenceCurve.verificationQuestion"
      values={{ date: formatLocalDateTime(currentCurveDate, false), baneDataName }}
    />{' '}
    {newCurveDate && (
      <Trans
        i18nKey="switches.setReferenceCurve.currentSwingNotice"
        values={{ date: formatLocalDateTime(newCurveDate, false) }}
      />
    )}
  </YesOrNoDialog>
)
