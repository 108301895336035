import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import Popover from '@mui/material/Popover'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'

import { Button } from 'src/Components/Button'

import type { OccupiedTrackCircuit } from 'src/Types/TrackCircuitTypes'
import type { Point } from 'src/Types/Geometry'

type OwnProps = {
  position: Point
  dateTime: number
  trackCircuits?: OccupiedTrackCircuit[]
  onClose: () => void
  onAddOccupiedTrackCircuits?: (baneDataIds: string[]) => void
}

const OCCUPATION_DATE_FORMAT = 'dd.MM.yyyy HH:mm:ss'

export const OccupiedTrackCircuits = ({
  position,
  dateTime,
  trackCircuits,
  onClose,
  onAddOccupiedTrackCircuits = () => {},
}: OwnProps) => {
  const { t } = useTranslation()

  return (
    <Popover
      anchorReference="anchorPosition"
      anchorPosition={{
        left: position.x,
        top: position.y,
      }}
      open
      onClose={onClose}
    >
      <List dense>
        <ListItem>
          <Typography variant="h6">{t('trackCircuits.occupiedTrackCircuits')}</Typography>
        </ListItem>
        <ListItem>
          <Typography variant="subtitle2">{format(dateTime, OCCUPATION_DATE_FORMAT)}</Typography>
        </ListItem>
        {!trackCircuits ? (
          <ListItem>
            <CircularProgress size={15} />
          </ListItem>
        ) : (
          <>
            {trackCircuits.map(tc => (
              <ListItem key={tc.baneDataId} dense>
                <ListItemText primary={`${tc.baneDataLocationName} ${tc.tcId}`} />
              </ListItem>
            ))}
            {trackCircuits.length > 0 && (
              <ListItem>
                <Button
                  variant="outlined"
                  onClick={() => {
                    onAddOccupiedTrackCircuits(trackCircuits.map(tc => tc.baneDataId))
                    onClose()
                  }}
                >
                  {t('trackCircuits.addAllOccupied')}
                </Button>
              </ListItem>
            )}
            {!trackCircuits.length && (
              <ListItem dense>
                <ListItemText primary={t('trackCircuits.noOccupation')} />
              </ListItem>
            )}
          </>
        )}
      </List>
    </Popover>
  )
}
