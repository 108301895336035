import { getCurrentCompressedMeasurements } from 'src/Providers/CurrentsCompressed'
import { getCurrentMeasurements } from 'src/Providers/Currents'
import { useQueryWithErrorHandling } from 'src/Hooks/NetworkData/useQueryWithErrorHandling'
import type {
  TrackCircuitCurrentsData,
  TrackCircuitCurrentType,
  TrackCircuitCompressionRates,
} from 'src/Types/TrackCircuitCurrentTypes'
import { stitchTrackCircuitCurrents } from 'src/Hooks/NetworkData/SensorData/stitchTrackCircuitCurrents'
// eslint-disable-next-line max-len
import { getTrackCircuitsWithMissingEndMeasurements } from 'src/Hooks/NetworkData/SensorData/getTrackCircuitsWithMissingEndMeasurements'

const warn = (...args: any[]) => {
  if (typeof process === 'object') {
    return
  }
  console.warn(...args)
}

type UseSensorDataParams = {
  trackCircuitBaneDataIds: string[]
  fromDate?: number
  toDate?: number
  currentType: TrackCircuitCurrentType | 'All'
  compressionRate?: TrackCircuitCompressionRates
  isQueryCloseToCurrentTime?: boolean
}

const getMissingTrackCircuitBaneDataIds = (
  trackCircuitBaneDataIds: string[],
  compressedMeasurements: TrackCircuitCurrentsData[]
) =>
  trackCircuitBaneDataIds.filter(trackCircuitBaneDataId => {
    const compressedMeasurement = compressedMeasurements.find(m => m.trackCircuitBaneDataId === trackCircuitBaneDataId)
    return (
      !compressedMeasurement ||
      (compressedMeasurement.fcMeasurements.length === 0 && compressedMeasurement.rcMeasurements.length === 0)
    )
  })

export const generateInjectedGetSensorData =
  (
    getCurrentMeasurementsImpl: typeof getCurrentMeasurements,
    getCurrentCompressedMeasurementsImpl: typeof getCurrentCompressedMeasurements
  ) =>
  async (params: UseSensorDataParams) => {
    const { trackCircuitBaneDataIds, fromDate, toDate, currentType, compressionRate, isQueryCloseToCurrentTime } = params
    if (!fromDate || !toDate || fromDate === toDate) {
      return []
    }
    const newParams = {
      trackCircuitBaneDataIds,
      fromDate,
      toDate,
      currentType,
    }
    if (compressionRate === 0) {
      return getCurrentMeasurementsImpl(newParams)
    }
    let compressedMeasurements = await getCurrentCompressedMeasurementsImpl({
      ...newParams,
      compressionRate,
    })
    if (compressedMeasurements[0]?.compressionRate === 0) {
      return compressedMeasurements
    }
    const missingTrackCircuitBaneDataIds = getMissingTrackCircuitBaneDataIds(trackCircuitBaneDataIds, compressedMeasurements)
    if (missingTrackCircuitBaneDataIds.length) {
      warn(`Missing sensor data for baneDataIds level ${compressionRate}, fallback to level 0`)
      warn('Missing trackCircuitBaneDataIds', missingTrackCircuitBaneDataIds)
      const missingMeasurements = await getCurrentMeasurementsImpl({
        ...newParams,
        trackCircuitBaneDataIds: missingTrackCircuitBaneDataIds,
      })
      const filteredMeasurements = compressedMeasurements.filter(
        m => !missingTrackCircuitBaneDataIds.includes(m.trackCircuitBaneDataId)
      )
      compressedMeasurements = [...filteredMeasurements, ...missingMeasurements]
    }

    if (!isQueryCloseToCurrentTime) {
      return compressedMeasurements
    }

    const trackCircuitsMissingEndMeasurements = getTrackCircuitsWithMissingEndMeasurements(compressedMeasurements)
    if (!trackCircuitsMissingEndMeasurements) {
      return compressedMeasurements
    }

    warn('Missing end measurements detected', trackCircuitsMissingEndMeasurements)
    const { missingFromDate, baneDataIdsMissingEndMeasurements } = trackCircuitsMissingEndMeasurements
    const missingEndMeasurements = await getCurrentMeasurementsImpl({
      ...newParams,
      trackCircuitBaneDataIds: baneDataIdsMissingEndMeasurements,
      fromDate: missingFromDate,
      toDate,
    })

    return stitchTrackCircuitCurrents(compressedMeasurements, missingEndMeasurements)
  }

const getSensorData = generateInjectedGetSensorData(getCurrentMeasurements, getCurrentCompressedMeasurements)

export const useSensorData = (params: UseSensorDataParams) => {
  const query = useQueryWithErrorHandling(
    ['sensorData', params],
    async () => {
      return getSensorData(params)
    },
    {},
    'sensorData.fetchError'
  )

  const sensorData: TrackCircuitCurrentsData[] | undefined = query.data
  return {
    ...query,
    sensorData,
  }
}
