import { getMachineHeatSwitchesAtLocations, getMachineHeatSwitchesForBaneDataIds } from 'src/Providers/MachineHeatSwitches'
import { getDateRange } from 'src/Features/SelectedDatePeriod/predefinedDatePeriods'
import { useAppStateStore } from 'src/Store/appState'
import { useQueryWithErrorHandling } from './useQueryWithErrorHandling'
import type { AggregatedAssetParams } from 'src/Hooks/NetworkData/useAggregatedAssets'

export const useMachineHeatSwitchesWithAlarms = ({
  datePeriod,
  idType,
  ids,
  onlyWithAlarms = false,
  enableAllAlarmTypes = false,
  enabled = true,
}: AggregatedAssetParams) => {
  const lastUpdated = useAppStateStore(state => state.selectedDatePeriod.lastUpdated)

  const query = useQueryWithErrorHandling(
    ['machineHeatSwitchesWithAlarms', { idType, ids, datePeriod, onlyWithAlarms, enableAllAlarmTypes, lastUpdated, enabled }],
    () => {
      if (!datePeriod || !enabled) {
        return []
      }
      const dateRange = getDateRange(datePeriod)
      if (idType === 'baneDataLocationIds') {
        return getMachineHeatSwitchesAtLocations(dateRange, ids, onlyWithAlarms, enableAllAlarmTypes)
      }

      return getMachineHeatSwitchesForBaneDataIds(dateRange, ids, onlyWithAlarms, enableAllAlarmTypes)
    },
    {
      enabled: ids.length > 0,
    },
    'pointMachineHeat.fetchError'
  )

  return {
    ...query,
    machineHeatSwitchesWithAlarms: query.data,
  }
}
