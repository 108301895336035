import { useQueryWithErrorHandling } from 'src/Hooks/NetworkData/useQueryWithErrorHandling'
import { getAssetStatus, postEnterAssetStatus, postExitAssetStatus } from 'src/Providers/AssetStatus'
import { useMutationWithErrorHandling } from 'src/Hooks/NetworkData/useMutationWithErrorHandling'
import type { AssetStatusEntityStatus } from 'src/Types/AssetStatusEntry'
import { camelCase } from 'src/Utils/string'

export type AssetStatusEntriesParams = Parameters<typeof getAssetStatus>[0]

export const useAssetStatusEntries = (params: AssetStatusEntriesParams) => {
  const query = useQueryWithErrorHandling(
    ['assetStatusEntries', params],
    () => getAssetStatus(params),
    {
      staleTime: 5 * 60 * 1000,
    },
    `manualAssetStatus.fetchError.${camelCase(params.status)}`
  )

  return {
    ...query,
    assetStatusEntries: query.data,
  }
}

export const useAssetStatusEnter = (status: AssetStatusEntityStatus) =>
  useMutationWithErrorHandling<void, Parameters<typeof postEnterAssetStatus>[0]>(
    postEnterAssetStatus,
    {},
    `manualAssetStatus.mutationError.${camelCase(status)}`,
    `manualAssetStatus.mutationSuccess.${camelCase(status)}`
  )

export const useAssetStatusExit = (status: AssetStatusEntityStatus) =>
  useMutationWithErrorHandling<void, Parameters<typeof postExitAssetStatus>[0]>(
    postExitAssetStatus,
    {},
    `manualAssetStatus.mutationError.${camelCase(status)}`,
    `manualAssetStatus.mutationSuccess.${camelCase(status)}`
  )
/*

import { useQueryWithErrorHandling } from 'src/Hooks/NetworkData/useQueryWithErrorHandling'
import type { AssetStatusEnterParams } from 'src/Providers/AssetStatus'
import { getAssetStatus, postAssetStatusEnter, postAssetStatusExit } from 'src/Providers/AssetStatus'
import { useMutationWithErrorHandling } from 'src/Hooks/NetworkData/useMutationWithErrorHandling'
import type { AssetStatusEntityStatus } from 'src/Types/AssetStatusEntry'
import { camelCase } from 'src/Utils/string'

export type AssetStatusEntriesParams = Parameters<typeof getAssetStatus>[0]

export const useAssetStatusEntries = (params: AssetStatusEntriesParams, status: AssetStatusEntityStatus) => {
  const query = useQueryWithErrorHandling(
    ['assetStatusEntries', params],
    () => getAssetStatus(params),
    {
      staleTime: 5 * 60 * 1000,
    },
    `manualAssetStatus.fetchError.${camelCase(status)}`
  )

  return {
    ...query,
    assetStatusEntries: query.data,
  }
}

type AssetStatusEnterParamsWithoutStatus = Omit<Parameters<typeof postAssetStatusEnter>[0], 'status'>

type AssetStatusExitParamsWithoutStatus = Omit<Parameters<typeof postAssetStatusExit>[0], 'status'>

export const useAssetStatusEnter = (status: AssetStatusEntityStatus) =>
  useMutationWithErrorHandling<void, AssetStatusEnterParamsWithoutStatus>(
    async params => postAssetStatusEnter({ ...params, status }),
    {},
    `manualAssetStatus.mutationError.${camelCase(status)}`,
    `manualAssetStatus.mutationSuccess.${camelCase(status)}`
  )

export const useAssetStatusExit = (status: AssetStatusEntityStatus) =>
  useMutationWithErrorHandling<void, AssetStatusExitParamsWithoutStatus>(
    async params => postAssetStatusExit({ ...params, status }),
    {},
    `manualAssetStatus.mutationError.${camelCase(status)}`,
    `manualAssetStatus.mutationSuccess.${camelCase(status)}`
  )
*/
