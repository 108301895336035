import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

import type { AlarmDefinition, AlarmType } from 'src/Types/AlarmType'
import type { AlarmClassification } from 'src/Types/AlarmClassification'
import type { AlarmCategory } from 'src/Types/AlarmCategory'
import type { PointMachineZoomLevel } from 'src/Types/SwingTypes'
import type { SensorCode, SensorCodeDomain } from 'src/Types/SensorCode'

type AlarmDefinitions<T> = {
  [key: string]: AlarmDefinition<T>
}

export interface DomainConstants {
  alarmTypes: {
    alarms: AlarmType[]
    classifications: AlarmDefinitions<AlarmClassification>
    categories: AlarmDefinitions<AlarmCategory>
    setAlarmTypes: (alarmTypes: AlarmType[]) => void
  }

  sensorCodes: {
    sensorCodes: {
      [domain in SensorCodeDomain]: SensorCode[]
    }
    setSensorCodes: (sensorCodes: SensorCode[], domain: SensorCodeDomain) => void
  }

  pointMachineZoomLevels: {
    pointMachineZoomLevels: PointMachineZoomLevel[]
    setPointMachineZoomLevels: (pointMachineZoomLevels: PointMachineZoomLevel[]) => void
  }
}

export const useDomainConstantsStore = create<DomainConstants>()(
  immer(set => ({
    alarmTypes: {
      alarms: [],
      classifications: {},
      categories: {},
      setAlarmTypes: alarmTypes =>
        set(state => {
          state.alarmTypes.alarms = alarmTypes

          state.alarmTypes.classifications = alarmTypes.reduce<DomainConstants['alarmTypes']['classifications']>((acc, alarm) => {
            acc[alarm.classification.id] = alarm.classification
            return acc
          }, {})

          state.alarmTypes.categories = alarmTypes.reduce<DomainConstants['alarmTypes']['categories']>((acc, alarm) => {
            acc[alarm.category.id] = alarm.category
            return acc
          }, {})
        }),
    },

    sensorCodes: {
      sensorCodes: {
        SVV: [],
        SEF: [],
      },
      setSensorCodes: (sensorCodes, domain) =>
        set(state => {
          state.sensorCodes.sensorCodes[domain] = sensorCodes
        }),
    },

    pointMachineZoomLevels: {
      pointMachineZoomLevels: [],
      setPointMachineZoomLevels: pointMachineZoomLevels =>
        set(state => {
          state.pointMachineZoomLevels.pointMachineZoomLevels = pointMachineZoomLevels
        }),
    },
  }))
)
