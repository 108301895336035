import React, { useEffect, useState } from 'react'

import { FilterDropdown } from 'src/Components/SidePanelMenu/FilterDropdown'
import { WorkOrderCountChip } from './WorkOrderCountChip'

import type { WorkOrderCount, WorkOrderFilter } from 'src/Types/WorkOrderTypes'

import { useLanguage } from 'src/Hooks/useLanguage'
import { useBreakpointDown } from 'src/Hooks/useBreakpoint'

type OwnProps = {
  counts?: WorkOrderCount[]
  filter: WorkOrderFilter
  options: string[]
  onFilterUpdated: (filter: WorkOrderFilter) => void
}

export const WorkOrderFilterDropdown = ({ counts, filter, options, onFilterUpdated }: OwnProps) => {
  const { t } = useLanguage()
  const isSmall = useBreakpointDown('sm')
  const [labels, setLabels] = useState<React.ReactElement[]>([])
  const [selectedValues, setSelectedValues] = useState<string[]>([])

  useEffect(() => {
    if (filter) {
      setSelectedValues(filter.values)
    }
  }, [filter])

  useEffect(() => {
    if (options.length) {
      setLabels(getLabelsWithCounts())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  const filtersUpdated = (values: string[]) => {
    return onFilterUpdated({
      type: filter.type,
      values,
    })
  }

  const getLabelsWithCounts = () => {
    return options.map(option => {
      const count = counts?.find(count => count.id.toLowerCase() === option.toLowerCase())
      return (
        <>
          <span>{isSmall ? option.toUpperCase() : t(`workOrders.${filter.type}Options.${option}`)}</span>
          {count && <WorkOrderCountChip number={count.count} />}
        </>
      )
    })
  }

  const getRenderValues = () =>
    selectedValues
      .map(value => {
        return value.toUpperCase()
      })
      .join(', ')

  return (
    <FilterDropdown
      id={`work-order-${filter.type}-filter`}
      label={t(`workOrders.${filter.type}`)}
      selectedValues={selectedValues}
      options={options}
      optionLabels={labels}
      onRenderValue={getRenderValues}
      onUpdate={filtersUpdated}
    />
  )
}
