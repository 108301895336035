import { useCallback, useEffect, useState } from 'react'

const EVENT_TYPE_NAME = 'session-storage'

export const useSessionStorage = <T>(key: string, initialValue: T): [T, (value: T) => void] => {
  const readValue = useCallback((): T => {
    if (typeof window === 'undefined') {
      return initialValue
    }

    try {
      const item = window.sessionStorage.getItem(key)
      return item ? (parseJSON(item) as T) : initialValue
    } catch (error) {
      console.warn(`Error reading sessionStorage key “${key}”:`, error)
      return initialValue
    }
  }, [initialValue, key])

  const [storedValue, setStoredValue] = useState<T>(readValue)

  const setValue = useCallback(
    (value: T) => {
      if (JSON.stringify(value) === JSON.stringify(storedValue)) {
        return
      }

      if (typeof window === 'undefined') {
        console.warn(`Tried setting sessionStorage key “${key}” even though environment is not a client`)
      }

      try {
        const newValue = value instanceof Function ? value(storedValue) : value
        window.sessionStorage.setItem(key, JSON.stringify(newValue))
        setStoredValue(newValue)
        window.dispatchEvent(new Event(EVENT_TYPE_NAME))
      } catch (error) {
        console.warn(`Error setting sessionStorage key “${key}”:`, error)
      }
    },
    [key, storedValue]
  )

  useEffect(() => {
    setStoredValue(readValue())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleStorageChange = useCallback(
    (event: any) => {
      if ((event as StorageEvent)?.key && (event as StorageEvent).key !== key) {
        return
      }
      setStoredValue(readValue())
    },
    [key, readValue]
  )

  useEffect(() => {
    window.addEventListener(EVENT_TYPE_NAME, handleStorageChange)
    return () => {
      window.removeEventListener(EVENT_TYPE_NAME, handleStorageChange)
    }
  })

  return [storedValue, setValue]
}

function parseJSON<T>(value: string | null): T | undefined {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? '')
  } catch {
    console.log('parsing error on', { value })
    return undefined
  }
}
