import type { TabModelColumn } from 'src/Features/SwitchHeat/TabModel'
import type { TemperatureParameter } from 'src/Features/SwitchHeat/Adjustment/TemperatureForm'

export type ColumnGroupedParameters = Record<number, TemperatureParameter[]>

export const getColumnGroupedParameters = (
  temperatureParameters: TemperatureParameter[],
  columns: TabModelColumn[],
  isMediaLG: boolean
): ColumnGroupedParameters => {
  const groupedParameters: ColumnGroupedParameters = {}

  columns.forEach(column => {
    const index = isMediaLG ? 1 : column.index
    const parametersInGroup = column.codeSystemKeys
      .map(codeSystem => temperatureParameters.find(p => p.codeSystem === codeSystem)!)
      .filter(Boolean)
    if (parametersInGroup.length > 0) {
      groupedParameters[index] = [...(groupedParameters[index] || []), ...parametersInGroup]
    }
  })

  return groupedParameters
}
