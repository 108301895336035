import devConfig from './config.development'
import type { Config } from './Config'
import { mergeConfig } from 'src/Config/mergeConfig'

const config: Partial<Config> = mergeConfig(devConfig, {
  MSAL_CONFIG: {
    auth: {
      redirectUri: 'http://localhost:3000',
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false, // Set this to 'true' if you are having issues on IE11 or Edge
    },
  },
  FEATURES: {
    MANUAL_ASSET_STATUS: true,
    EXGEN_UPDATE: true,
  },
})

export default config
