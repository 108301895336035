import { getDateRange } from 'src/Features/SelectedDatePeriod/predefinedDatePeriods'

import type { DatePeriod } from 'src/Types/DatePeriod'

import { getPointMachineHeatSensorData } from 'src/Providers/PointMachineHeat'
import { useAppStateStore } from 'src/Store/appState'
import { useQueryWithErrorHandling } from './useQueryWithErrorHandling'

export const usePointMachineHeatSensorData = (baneDataId: string, isSequence: boolean, datePeriod: DatePeriod) => {
  const lastUpdated = useAppStateStore(state => state.selectedDatePeriod.lastUpdated)

  const query = useQueryWithErrorHandling(
    ['pointMachineHeatSensorData', { baneDataId, datePeriod, lastUpdated, isSequence }],
    () => {
      const { fromDate, toDate } = getDateRange(datePeriod)

      return getPointMachineHeatSensorData(baneDataId, isSequence, fromDate, toDate)
    },
    {},
    'switches.pointMachineHeat.chart.fetchError'
  )

  return {
    ...query,
    sensorData: query.data,
  }
}
