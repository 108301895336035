import { makeStyles } from 'tss-react/mui'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeftOutlined'
import ChevronRightIcon from '@mui/icons-material/ChevronRightOutlined'
import CircularProgress from '@mui/material/CircularProgress'

import { Button } from 'src/Components/Button'

import { useBreakpointDown } from 'src/Hooks/useBreakpoint'
import themeColors from 'src/theme'

type OwnProps = {
  text: string
  direction: 'left' | 'right'
  disabled?: boolean
  isRefetching?: boolean
  onClick: () => void
}

type StyleProps = Pick<OwnProps, 'direction' | 'disabled'>

const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  arrowButton: {
    padding: props.direction === 'left' ? '0 16px 0 2px' : '0 2px 0 16px',
    marginLeft: props.direction === 'left' ? 'auto' : '10px',
    marginRight: props.direction === 'left' ? '10px' : 'auto',
    [theme.breakpoints.down('lg')]: {
      fontSize: '10px',
    },
    [theme.breakpoints.down('xl')]: {
      fontSize: '12px',
    },
    '&:disabled': {
      '& span > span > svg': {
        backgroundColor: themeColors.disabled,
        border: `2px solid ${themeColors.disabled}`,
        color: themeColors.disabledText,
      },
    },
    '&:hover': {
      '& span > span > svg': {
        backgroundColor: themeColors.tertiary,
        border: `2px solid ${themeColors.tertiary}`,
      },
    },
    '&:active': {
      '& span > span > svg': {
        border: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  arrowIcon: {
    padding: 8,
    borderRadius: 50,
    color: props.disabled ? themeColors.disabledText : '#fff',
    backgroundColor: props.disabled ? themeColors.disabled : theme.palette.secondary.main,
    border: `2px solid ${props.disabled ? themeColors.disabled : theme.palette.secondary.main}`,
  },
  mobileSpinner: {
    color: themeColors.disabledText,
  },
}))

export const ArrowButtonWithText = ({ text, direction, disabled = false, isRefetching = false, onClick }: OwnProps) => {
  const { classes } = useStyles({ direction, disabled })
  const isMediaMD = useBreakpointDown('md')

  const getRightIcon = () => {
    const spinnerClassName = isMediaMD ? classes.mobileSpinner : classes.arrowIcon
    return isRefetching && disabled ? (
      <CircularProgress className={spinnerClassName} size={isMediaMD ? 24 : 20} />
    ) : (
      <ChevronRightIcon className={isMediaMD ? undefined : classes.arrowIcon} />
    )
  }

  return isMediaMD ? (
    <IconButton aria-label={direction} disabled={disabled} onClick={onClick} className={classes.arrowIcon}>
      {direction === 'left' ? <ChevronLeftIcon /> : getRightIcon()}
    </IconButton>
  ) : (
    <Button
      variant="outlined"
      buttonClass={classes.arrowButton}
      startIcon={direction === 'left' ? <ChevronLeftIcon className={classes.arrowIcon} /> : undefined}
      endIcon={direction === 'right' ? getRightIcon() : undefined}
      disabled={disabled}
      onClick={onClick}
    >
      {text}
    </Button>
  )
}
