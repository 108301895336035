import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import Snackbar from '@mui/material/Snackbar'
import Button from '@mui/material/Button'

import { Alert } from 'src/Components/Alert'

import { useAppStateStore } from 'src/Store/appState'

const StyledAlert = styled(Alert)`
  border-radius: 25px;
`

const DEFAULT_DURATION = 3000

export const AppMessagesController = () => {
  const { t, i18n } = useTranslation()
  const dismissCurrentMessage = useAppStateStore(state => state.appMessages.dismissCurrentMessage)

  const messages = useAppStateStore(state => state.appMessages.messages)
  const message = messages[0]

  if (!message) {
    return null
  }

  const dismissMessage = () => dismissCurrentMessage()

  let duration = message.type === 'error' ? null : DEFAULT_DURATION

  if (typeof message.duration !== 'undefined') {
    duration = message.duration
  }

  let messageText = message.message
  if (message.raw !== true) {
    messageText = i18n.exists(message.message)
      ? t(message.message, message.translationValues!)
      : t('general.errorMessages.default')
  }

  return (
    <Snackbar
      open={!!message}
      autoHideDuration={duration}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      onClose={dismissMessage}
    >
      <div>
        <StyledAlert
          severity={message.type}
          onClose={dismissMessage}
          action={
            message.href && (
              <Button color="inherit" size="small" href={message.href}>
                {t('general.navigateTo')}
              </Button>
            )
          }
        >
          {messageText}
        </StyledAlert>
      </div>
    </Snackbar>
  )
}
