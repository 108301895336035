import { isToday } from 'date-fns'

import type { DatePeriod } from 'src/Types/DatePeriod'
import { getDateRange } from 'src/Features/SelectedDatePeriod/predefinedDatePeriods'
import { useQueryWithErrorHandling } from './useQueryWithErrorHandling'
import type { SwingStatistics } from 'src/Types/SwingStatistics'
import { getSwings } from 'src/Providers/Swing'
import type { SwingStatisticsContract } from 'src/Providers/SwingStatisticsContract'

type SwingsParams = {
  baneDataId: string
  datePeriod: DatePeriod
}

const applyDateRange = (stat: SwingStatisticsContract, fromDate: number, toDate: number): SwingStatistics => ({
  ...stat,
  fromDate,
  toDate,
})

const SWINGS_REFETCH_INTERVAL = 30 * 1000

export const useSwings = (params: SwingsParams) => {
  // Auto-refresh swings for time periods including the current day. Earlier periods won't get new swings.
  const { baneDataId } = params
  const { fromDate, toDate } = getDateRange(params.datePeriod)
  const refetchInterval = isToday(toDate) ? SWINGS_REFETCH_INTERVAL : undefined

  const query = useQueryWithErrorHandling(
    ['swings', params],
    () =>
      getSwings({
        baneDataId,
        fromDate,
        toDate,
      }),
    {
      refetchInterval,
    },
    'switches.swings.fetchError'
  )

  const { swings, machinesInSwitch, isSwingsGrouped, statistics } = query.data || {}

  const validSwingStatistics: SwingStatisticsContract[] = []
  const assetStat = statistics?.asset
  if (assetStat) {
    validSwingStatistics.push(assetStat)
  }
  if (statistics?.machines) {
    Object.values(statistics?.machines).forEach(stat => {
      if (stat) {
        validSwingStatistics.push(stat)
      }
    })
  }
  const swingStatistics = validSwingStatistics.map(stat => applyDateRange(stat, fromDate, toDate))

  return {
    ...query,
    swings,
    swingStatistics,
    machinesInSwitch,
    isSwingsGrouped,
  }
}
