import { useState } from 'react'
import { styled } from '@mui/material/styles'
import { getBuildTimeAppMetadata } from 'src/Utils/metadata'
import type { AppMetadata } from 'src/Types/AppMetadata'
import { Grid, Typography, Tooltip } from '@mui/material'
import { isProd } from 'src/reactAppEnv'

const StyledPanel = styled('div')`
  font-size: 12px;
  text-align: center;
`

const StyledVersion = styled('div')`
  padding: 2px;
  display: inline-block;

  & > span {
    font-size: 12px;
    padding: 4px 8px;
    display: inline-block;
  }
`

const StyledTooltipContent = styled('div')`
  padding: 2px;
  font-size: 12px;

  .MuiTypography-root {
    color: #fff;
  }

  & > dl {
    display: flex;
    flex-direction: column;

    & > div {
      & > dt {
        font-weight: bold;
      }
      & > dt,
      & > dd {
        font-size: 12px;
      }
    }
  }
`

const getTableData = (appMetadata: AppMetadata | undefined) => {
  if (!appMetadata) {
    return []
  }
  const labels: [string, string][] = []
  if (appMetadata.version) {
    labels.push(['Git hash', appMetadata.version])
  }
  if (appMetadata.buildNumber) {
    labels.push(['Build nr', appMetadata.buildNumber])
  }
  if (appMetadata.buildId) {
    labels.push(['Build id', appMetadata.buildId])
  }
  if (appMetadata.sourceDate) {
    labels.push(['Commit time', new Date(appMetadata.sourceDate).toLocaleString()])
  }
  if (appMetadata.buildTimestamp) {
    labels.push(['Build time', new Date(appMetadata.buildTimestamp).toLocaleString()])
  }
  return labels
}

export const VersionInfoPanel = () => {
  const [appMetadata] = useState<AppMetadata | undefined>(() => getBuildTimeAppMetadata())

  if (!appMetadata) {
    return null
  }

  const grid = (
    <StyledTooltipContent>
      <Grid container component="dl" spacing={0.5}>
        {getTableData(appMetadata).map(([key, val]) => (
          <Grid item key={key}>
            <Typography component="dt">{key}</Typography>
            <Typography component="dd">{val}</Typography>
          </Grid>
        ))}
      </Grid>
    </StyledTooltipContent>
  )

  const versionElement = <span>v{appMetadata?.buildNumber}</span>

  return (
    <StyledPanel>
      <StyledVersion>
        {isProd ? (
          versionElement
        ) : (
          <Tooltip title={grid} placement="top" arrow>
            {versionElement}
          </Tooltip>
        )}
      </StyledVersion>
    </StyledPanel>
  )
}
