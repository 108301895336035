import type { FeatureKey } from 'src/Hooks/useSessionFeature'
import { useSessionFeatures } from 'src/Hooks/useSessionFeature'

/**
 * Checks if a feature flag has been set at the url.
 *
 * Example: ?features=switchheatv2
 */
export const useFeatureOverride = (featureKey: FeatureKey) => {
  const { features } = useSessionFeatures()
  return features.includes(featureKey)
}
