import { startOfHour } from 'date-fns/startOfHour'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress'

import { Alert } from 'src/Components/Alert'
import { AssetInformationEntry } from 'src/Components/AssetCard/AssetInformationEntry'
import { SubHeaderWithExpandableInfo } from 'src/Components/SubHeaderWithExpandableInfo'
import { WindIcon } from 'src/Features/Weather/WindIcon/WindIcon'

import type { SwingWeatherKey } from 'src/Types/SwingTypes'

import { useWeather } from 'src/Hooks/NetworkData/useWeather'
import { useHistoricSwitchHeatSensorData } from 'src/Hooks/NetworkData/useHistoricSwitchHeatSensorData'
import { useSwitchHeatVersion } from 'src/Hooks/useSwitchHeatVersion'

import { getLeastAdvancedQueryStatus } from 'src/Utils/network'
import { formatNumber } from 'src/Utils/format'
import { StyledContent } from './elements'
import { SubHeaderKey } from 'src/Providers/Settings/SubHeader'

const StyledAlert = styled(Alert)`
  width: max-content;
  margin-left: auto;
`

const WEATHER_SUFFIXES: Record<SwingWeatherKey, string> = {
  precipitation: ' mm',
  wind: ' m/s',
  temperature: '°C',
  tempRightTrack: '°C',
  tempLeftTrack: '°C',
}

type OwnProps = {
  swingTimestamp: number
  locationId: string
  baneDataId: string
}

export const WeatherStatus = ({ swingTimestamp, locationId, baneDataId }: OwnProps) => {
  const { t } = useTranslation()

  const { weather, status: weatherStatus } = useWeather({
    baneDataLocationId: locationId,
    fromDate: startOfHour(swingTimestamp).valueOf(),
    toDate: swingTimestamp,
  })

  const switchHeatVersion = useSwitchHeatVersion()
  const { switchHeatSensorData, status: heatStatus } = useHistoricSwitchHeatSensorData(
    baneDataId,
    ['TempLeftTrack', 'TempRightTrack', 'TempOut'],
    swingTimestamp,
    switchHeatVersion
  )

  const queryStatus = getLeastAdvancedQueryStatus([weatherStatus, heatStatus])

  const renderContent = () => {
    if (queryStatus === 'idle' || !weather) {
      return null
    }

    if (queryStatus === 'loading') {
      return (
        <AssetInformationEntry
          label={t('switches.statusLabels.weather.loadingLabel')}
          values={[<CircularProgress size={15} />]}
        />
      )
    }

    if (!weather.length || queryStatus === 'error') {
      return (
        <AssetInformationEntry
          label={t('switches.statusLabels.weather.title')}
          values={[<StyledAlert severity="info">{t('switches.statusLabels.weather.notFound')}</StyledAlert>]}
        />
      )
    }

    const tempRightTrack = switchHeatSensorData?.find(s => s.codeSystem === 'TempRightTrack')?.currentAtFromTime
    const tempLeftTrack = switchHeatSensorData?.find(s => s.codeSystem === 'TempLeftTrack')?.currentAtFromTime
    const heatSensorTempOut = switchHeatSensorData?.find(s => s.codeSystem === 'TempOut')?.currentAtFromTime

    const { precipitation, windSpeed, windDirection, temperature: weatherTempOut } = weather[0]

    const weatherEntries = {
      precipitation,
      wind: (
        <>
          <WindIcon windDegrees={windDirection} windSpeed={windSpeed} />
          {windSpeed} {WEATHER_SUFFIXES.wind}
        </>
      ),
      temperature: typeof heatSensorTempOut === 'number' ? heatSensorTempOut : weatherTempOut,
      tempRightTrack: typeof tempRightTrack === 'number' ? tempRightTrack : undefined,
      tempLeftTrack: typeof tempLeftTrack === 'number' ? tempLeftTrack : undefined,
    }

    return (
      <StyledContent>
        {Object.entries(weatherEntries)
          .filter(e => e[1] !== undefined)
          .map(([key, entry], i) => {
            const value =
              typeof entry === 'number' ? `${formatNumber(entry, 1)}${WEATHER_SUFFIXES[key as SwingWeatherKey]}` : entry

            return <AssetInformationEntry key={key} label={t(`switches.statusLabels.weather.${key}`)} values={[value]} />
          })}
      </StyledContent>
    )
  }

  return (
    <SubHeaderWithExpandableInfo
      title={t('switches.statusLabels.weather.title')}
      isDefaultExpanded={false}
      isLoading={queryStatus === 'loading'}
      subHeaderKey={SubHeaderKey.SwingWeatherStatus}
    >
      {renderContent()}
    </SubHeaderWithExpandableInfo>
  )
}
