import type { ComponentProps } from 'react'
import { FormControl, RadioGroup, FormControlLabel, Radio, Grid, Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import type { RadioGroupProps } from '@mui/material/RadioGroup/RadioGroup'

import type { SensorTwinModus } from 'src/Types/SensorTwin'
import { Button } from 'src/Components/Button'
import { useLanguage } from 'src/Hooks/useLanguage'
import { ModusDebugPanel } from 'src/Features/SwitchHeat/Adjustment/ModusDebugPanel'

type OwnProps = {
  isWritable: boolean
  currentModus: SensorTwinModus | undefined
  requestedModus: SensorTwinModus | undefined
  changedModus: SensorTwinModus | undefined
  updatingModus: SensorTwinModus | undefined
  availableModuses: SensorTwinModus[] | undefined
  lastUpdated: number | undefined
  onModusInputChange?: (modus: SensorTwinModus | undefined) => unknown
  onModusSubmitClicked?: (modus: SensorTwinModus | undefined) => unknown
  isUpdating?: boolean
}

const ALL_MODUSES: SensorTwinModus[] = ['off', 'auto', 'met', 'max']

const parseModus = (value: string | undefined): SensorTwinModus | undefined => {
  switch (value) {
    case 'off':
    case 'auto':
    case 'max':
    case 'met':
      return value

    default:
      return undefined
  }
}

const ONE_MINUTE = 60000

const isMoreThanAMinuteAgo = (time: number) => Date.now() - time > ONE_MINUTE

export const TemperatureFormModus = ({
  isWritable,
  currentModus,
  requestedModus,
  changedModus,
  updatingModus,
  availableModuses,
  onModusInputChange,
  onModusSubmitClicked,
  isUpdating,
  lastUpdated,
}: OwnProps) => {
  const { t } = useLanguage()
  const readOnly = !isWritable

  function callModusChange(value: string) {
    const modus = parseModus(value)
    if (modus !== undefined) {
      onModusInputChange?.(modus)
    }
  }

  const handleModusChange: RadioGroupProps['onChange'] = (event, value) => {
    callModusChange(value)
  }

  const handleSubmitClicked = () => {
    onModusSubmitClicked?.(changedModus)
  }

  const handleModusClick: ComponentProps<typeof FormControlLabel>['onClick'] = e => {
    if (e.target instanceof HTMLInputElement) {
      callModusChange(e.target.value)
    }
  }

  const isModusStale = lastUpdated ? isMoreThanAMinuteAgo(lastUpdated) : false
  const isInModusTransition = requestedModus !== currentModus
  const isInModusTransitionButNotStale = isInModusTransition && !isModusStale
  const disabled = readOnly || isUpdating
  const disabledRadio = disabled || isInModusTransitionButNotStale
  const disabledSubmitButton = disabled || changedModus === undefined || isInModusTransitionButNotStale

  return (
    <Grid container direction="column" sx={{ position: 'relative' }}>
      <Grid item>
        <ModusDebugPanel
          changedModus={changedModus}
          requestedModus={requestedModus}
          currentModus={currentModus}
          updatingModus={updatingModus}
          lastUpdated={lastUpdated}
        />
        <FormControl>
          <Box data-testid="TemperatureFormModus_Current">
            <span>{t('switchHeat.temperatureForm.modusInOperation')}:</span>{' '}
            <strong data-testid="TemperatureFormModus_Current_Value">
              {t(currentModus ? `switchHeat.temperatureForm.modus.${currentModus}` : 'general.unknown')}
            </strong>
          </Box>
          {updatingModus ? (
            <Box data-testid="TemperatureFormModus_Updating">
              <span>
                <CircularProgress size={14} /> {t('switchHeat.temperatureForm.settingModus')}:
              </span>{' '}
              <strong data-testid="TemperatureFormModus_Updating_Value">
                {t(updatingModus ? `switchHeat.temperatureForm.modus.${updatingModus}` : 'general.unknown')}
              </strong>
            </Box>
          ) : null}
          <RadioGroup row onChange={handleModusChange} aria-disabled={disabledRadio} data-testid="TemperatureFormModus_Select">
            {ALL_MODUSES.filter(mod => availableModuses?.includes(mod)).map(mod => (
              <FormControlLabel
                key={mod}
                value={mod}
                control={<Radio checked={changedModus === mod} disabled={disabledRadio} />}
                label={t(`switchHeat.temperatureForm.modus.${mod}`)}
                onClick={handleModusClick}
                data-testid="TemperatureFormModus_Option"
                data-value={mod}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item>
        <Button onClick={handleSubmitClicked} disabled={disabledSubmitButton} data-testid="TemperatureFormModus_Submit">
          {t('switchHeat.temperatureForm.confirmModusButton')}
        </Button>
      </Grid>
    </Grid>
  )
}
