import type { ReactElement, ReactNode } from 'react'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'

const StyledEntryListItem = styled(ListItem)(
  props => `
  padding-left: 20px;
  ${props.theme.breakpoints.down('sm')} {
    padding-left: 8px;
    padding-right: ${props.theme.spacing(1)};
  }
`
)

const StyledValueListItemText = styled(ListItemText)`
  font-weight: bold;
  text-align: right;
  font-size: 0.875rem;
`

type OwnProps = {
  label?: string | ReactElement
  values?: ReactNode[]
}

type WidthUnit = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

const getColWidths = (cols: number): { val: WidthUnit; label: WidthUnit } => {
  switch (cols) {
    case 1:
      return { val: 6, label: 6 }
    case 2:
      return { val: 3, label: 6 }
    case 3:
      return { val: 3, label: 4 }
    case 4:
      return { val: 6, label: 6 }
    case 5:
      return { val: 7, label: 5 }
    default:
      return { val: 2, label: (12 - cols) as WidthUnit }
  }
}

export const AssetInformationEntry = ({ label = '', values = [] }: OwnProps) => {
  const widths = getColWidths(values.length)

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={values.length > 3 ? 12 : 10}>
        <StyledEntryListItem dense>
          <Grid item xs={widths.label}>
            <ListItemText>{label}</ListItemText>
          </Grid>
          {values.map((value, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid item xs={widths.val} key={i}>
              <StyledValueListItemText disableTypography>{value}</StyledValueListItemText>
            </Grid>
          ))}
        </StyledEntryListItem>
      </Grid>
    </Grid>
  )
}
