import { callApiGet } from 'src/msGraphClient'
import { ApiType } from 'src/Types/ApiType'
import config from 'src/Config/Config'
import { getAssetStatusEntityStatus, getAssetStatusEntryDomain } from 'src/Utils/asset'
import type {
  AssetStatusEntityStatus,
  AssetStatusEntityStatusId,
  AssetStatusEntryCause,
  AssetStatusEntryDomain,
  AssetStatusEntryDomainId,
} from 'src/Types/AssetStatusEntry'

const { ASSET_STATUS_CAUSES } = config.API_FRAGMENTS.apim

type AssetStatusEntryCauseContract = {
  statusId: AssetStatusEntityStatus | AssetStatusEntityStatusId
  causeId: number
  name_No: string
  name_En: string
  domainId: AssetStatusEntryDomain | AssetStatusEntryDomainId
}

export const getAssetStatusEntryCauses = async () => {
  const result = await callApiGet<AssetStatusEntryCauseContract[]>(ASSET_STATUS_CAUSES, ApiType.APIM)

  /* eslint-disable camelcase */
  return result.map<AssetStatusEntryCause>(({ statusId, causeId, name_No, name_En, domainId }) => ({
    status: getAssetStatusEntityStatus(statusId),
    id: causeId,
    name: {
      no: name_No,
      en: name_En,
    },
    domain: getAssetStatusEntryDomain(domainId),
  }))
  /* eslint-enable camelcase */
}
