import { getTrackCircuitsAtLocations, getTrackCircuitsWithIds } from 'src/Providers/OrchestratedTrackCircuit'
import { getDateRange } from 'src/Features/SelectedDatePeriod/predefinedDatePeriods'
import { useAppStateStore } from 'src/Store/appState'
import { useQueryWithErrorHandling } from './useQueryWithErrorHandling'
import type { AggregatedAssetParams } from 'src/Hooks/NetworkData/useAggregatedAssets'

export const useTrackCircuitsWithAlarms = ({
  datePeriod,
  idType,
  ids,
  onlyWithAlarms = false,
  enableAllAlarmTypes = false,
  enabled = true,
}: AggregatedAssetParams) => {
  const lastUpdated = useAppStateStore(state => state.selectedDatePeriod.lastUpdated)

  const query = useQueryWithErrorHandling(
    ['trackCircuitsWithAlarms', { idType, ids, datePeriod, onlyWithAlarms, enableAllAlarmTypes, lastUpdated, enabled }],
    () => {
      if (!datePeriod || !enabled) {
        return []
      }
      const dateRange = getDateRange(datePeriod)
      if (idType === 'baneDataLocationIds') {
        return getTrackCircuitsAtLocations(dateRange, ids, onlyWithAlarms, enableAllAlarmTypes)
      }

      return getTrackCircuitsWithIds(dateRange, ids, onlyWithAlarms, enableAllAlarmTypes)
    },
    {
      enabled: ids.length > 0,
    },
    'alarms.fetchError'
  )

  return {
    ...query,
    trackCircuitsWithAlarms: query.data,
  }
}
