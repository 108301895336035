import { predefinedDatePeriodKeys } from 'src/Features/SelectedDatePeriod/predefinedDatePeriods'
import { ALL_CURRENT_STATUS_OPTIONS, ALL_SYSTEM_OPTIONS } from './UserSettingConstants'

import type { DatePeriodKey } from 'src/Types/DatePeriod'
import { NewAlarmSound } from 'src/Types/NewAlarmSound'
import type { SystemStatus } from 'src/Types/SystemStatus'
import type { UserSettings } from 'src/Types/UserSettings'
import type { AssetType } from 'src/Types/AssetTypes'

const stringSettings = ['openRailwayId']

const boolSettings = [
  'isMenuOpen',
  'weatherDefaultExpanded',
  'tcWeatherDefaultExpanded',
  'switchChartFullWidth',
  'hideChartZoomButtons',
  'alarmInformationDefaultExpanded',
  'fcChartOnAlarmsDefaultExpanded',
  'infoUnderChartsDefaultExpanded',
  'weatherChartDefaultExpanded',
]

type RawSettings = Record<any, any>

const isSettings = (settings: unknown): settings is RawSettings => typeof settings === 'object' && settings !== null

const validateDatePeriod = (settings: RawSettings) => {
  let validPeriod = false

  const datePeriod = settings.selectedDatePeriod
  if (datePeriod) {
    if (typeof datePeriod === 'string') {
      validPeriod = predefinedDatePeriodKeys.includes(datePeriod as DatePeriodKey)
    } else if (typeof datePeriod === 'object') {
      validPeriod =
        typeof datePeriod.fromDate === 'number' &&
        typeof datePeriod.toDate === 'number' &&
        datePeriod.fromDate < datePeriod.toDate
    }
  }

  if (!validPeriod) {
    delete settings.selectedDatePeriod
  }
}

const validateSelectedStretchLocations = (settings: RawSettings) => {
  if (Array.isArray(settings.selectedStretchLocations)) {
    settings.selectedStretchLocations = settings.selectedStretchLocations.filter((l: any) => typeof l === 'string')
  } else {
    delete settings.selectedStretchLocations
  }
}

const validateDefaultSelectedStatusFilter = (settings: RawSettings) => {
  if (
    Array.isArray(settings.defaultSelectedStatusFilter) &&
    settings.defaultSelectedStatusFilter.every((s: SystemStatus) => ALL_CURRENT_STATUS_OPTIONS.includes(s))
  ) {
    settings.defaultSelectedStatusFilter = settings.defaultSelectedStatusFilter.filter((l: any) => typeof l === 'string')
  } else {
    delete settings.defaultSelectedStatusFilter
  }
}

const validateDefaultSelectedSystemFilter = (settings: RawSettings) => {
  if (
    Array.isArray(settings.defaultSelectedSystemFilter) &&
    settings.defaultSelectedSystemFilter.every((s: string) => ALL_SYSTEM_OPTIONS.includes(s as AssetType))
  ) {
    settings.defaultSelectedSystemFilter = settings.defaultSelectedSystemFilter.filter((l: any) => typeof l === 'string')
  } else {
    delete settings.defaultSelectedSystemFilter
  }
}

const validateSettingsOfType = (settings: RawSettings, fields: string[], type: 'string' | 'boolean') => {
  fields.forEach(settingKey => {
    const t = typeof settings[settingKey]
    if (t !== type) {
      delete settings[settingKey]
    }
  })
}

export const validateSettings = (settings: unknown): UserSettings | undefined => {
  if (!isSettings(settings)) {
    return undefined
  }

  validateDatePeriod(settings)
  validateSelectedStretchLocations(settings)
  validateDefaultSelectedSystemFilter(settings)

  validateSettingsOfType(settings, stringSettings, 'string')
  validateSettingsOfType(settings, boolSettings, 'boolean')

  if ('tcWeatherDefaultExpanded' in settings && !('weatherChartDefaultExpanded' in settings)) {
    settings.weatherChartDefaultExpanded = settings.tcWeatherDefaultExpanded
    delete settings.tcWeatherDefaultExpanded
  }

  if ('defaultAlarmClassificationFilter' in settings && !('defaultStatusFilter' in settings)) {
    settings.defaultStatusFilter = [settings.defaultAlarmClassificationFilter]
    delete settings.defaultAlarmClassificationFilter
  }
  validateDefaultSelectedStatusFilter(settings)

  if (![NewAlarmSound.None, NewAlarmSound.Default, NewAlarmSound.Train].includes(settings.newAlarmSound)) {
    delete settings.newAlarmSound
  }

  return settings as UserSettings
}
