import { useUserProfileStore } from 'src/Store/userProfile'
import { useBreakpointDown } from 'src/Hooks/useBreakpoint'
import { useAppStateStore } from 'src/Store/appState'

const BANNER_HEIGHT = 46
const WEATHER_BANNER_HEIGHT = 35
const WEATHER_EXPANDED_HEIGHT = 180

export const useHeaderHeight = () => {
  const selectedWeatherLocationId = useAppStateStore(state => state.locations.selectedWeatherLocationId)
  const weatherExpanded = useUserProfileStore(state => state.settings.settings.weatherDefaultExpanded)
  const isSmall = useBreakpointDown('md')

  if (isSmall) {
    return 0
  }

  let headerHeight = BANNER_HEIGHT
  if (selectedWeatherLocationId) {
    headerHeight += WEATHER_BANNER_HEIGHT
  }
  if (weatherExpanded) {
    headerHeight += WEATHER_EXPANDED_HEIGHT
  }

  return headerHeight
}
