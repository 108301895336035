export const arrayMinMax = (arr: number[]) => {
  if (!arr.length) {
    return {
      min: undefined,
      max: undefined,
    }
  }
  const [min, max] = arr.reduce<[number, number]>(
    ([min, max], val) => [Math.min(min, val), Math.max(max, val)],
    [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY]
  )
  return { min, max }
}
