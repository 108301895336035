import { ApiType } from 'src/Types/ApiType'
import { AssetType } from 'src/Types/AssetTypes'

import type { DateRange } from 'src/Types/DatePeriod'
import type { AssetWithAlarms } from 'src/Types/AssetWithAlarms'
import { InternalAlarmSystem } from 'src/Types/AlarmSystem'
import type { GetOrchestratedAssetsParams } from 'src/Types/GetOrchestratedAssetsParams'
import type { OrchestratedBender, Bender } from 'src/Types/Bender'

import { callApiGet } from 'src/msGraphClient'
import { getCriticalityValue } from 'src/Utils/criticality'
import { getOrchestratedAssetIdParams } from 'src/Utils/asset'
import config from 'src/Config/Config'

const { BENDERS, BENDER_DETAILS } = config.API_FRAGMENTS.apim

export const getOrchestratedBenders = (params: GetOrchestratedAssetsParams): Promise<AssetWithAlarms[]> => {
  const urlParams = new URLSearchParams({
    fromDate: new Date(params.dateRange.fromDate).toJSON(),
    toDate: new Date(params.dateRange.toDate).toJSON(),
    onlyWithAlarms: params.onlyWithAlarms.toString(),
    feFilterEnabled: (!params.enableAllAlarmTypes).toString(),
  })

  const { idKey, value } = getOrchestratedAssetIdParams(params)
  urlParams.set(idKey, value)

  return callApiGet<OrchestratedBender[]>(`${BENDERS}?${urlParams.toString()}`, ApiType.APIM, { parseDates: true })
    .then(res =>
      res.map<AssetWithAlarms>(entry => {
        const asset: Bender = {
          assetType: AssetType.Bender,
          baneDataId: entry.assetData.baneDataId,
          name: entry.assetData.name,
          baneDataLocationName: entry.assetData.locationName,
          baneDataLocationId: entry.assetData.locationId,
          isMonitored: entry.status.isMonitored,
          componentCriticalityLocal: getCriticalityValue(entry.assetData, 'ComponentCriticalityLocal'),
          alarmCriticalityAbsolute: getCriticalityValue(entry.assetData, 'AlarmCriticalityAbsolute'),
          totalCriticalityAbsolute: getCriticalityValue(entry.assetData, 'TotalCriticalityAbsolute'),
          statusWithoutAssetStatusWindows: entry.status.statusWithoutAssetStatusWindows,
          statusWithAssetStatusWindows: entry.status.statusWithAssetStatusWindows,
          showCriticality: entry.status.showCriticality,
          dailyStatus: entry.status.dailyStatus,
          assetStatusWindows: entry.status.assetStatusWindows,
          benderAssetData: entry.benderAssetData,
          fromKm: entry.assetData.fromKm,
          toKm: entry.assetData.toKm,
        }

        return {
          asset,
          alarms: entry.aggregateAlarms.map(alarm => ({
            ...alarm,
            internalAlarmSystem: InternalAlarmSystem.AggregatedBenderAlarm,
          })),
        }
      })
    )
    .then(result => result.sort((a, b) => a.asset.name.localeCompare(b.asset.name, config.STRING_LOCALE)))
}

export const getOrchestratedBendersAtLocations = (
  dateRange: DateRange,
  locationIds: string[],
  onlyWithAlarms: boolean,
  enableAllAlarmTypes: boolean
) => getOrchestratedBenders({ dateRange, locationIds, onlyWithAlarms, enableAllAlarmTypes })

export const getOrchestratedBendersWithIds = (
  dateRange: DateRange,
  baneDataIds: string[],
  onlyWithAlarms: boolean,
  enableAllAlarmTypes: boolean
) => getOrchestratedBenders({ dateRange, baneDataIds, onlyWithAlarms, enableAllAlarmTypes })

// TODO: this mapping is pretty ugly
enum OrchestratedAssetType {
  Unknown = 'Unknown',
  Switch = 'Switch',
  SwitchHeatCabinet = 'SwitchHeatCabinet',
  TrackCircuit = 'TrackCircuit',
  Bender = 'Bender',
}

type ApiOrchestratedBender = Omit<OrchestratedBender, 'inCircuit'> & {
  inCircuit?: {
    baneDataId: string
    name: string
    assetType: OrchestratedAssetType
  }[]
}
//

const mapAssetType = (assetType: OrchestratedAssetType): AssetType => {
  switch (assetType) {
    case OrchestratedAssetType.Bender:
      return AssetType.Bender
    case OrchestratedAssetType.Switch:
      return AssetType.SwitchPointMachine
    case OrchestratedAssetType.SwitchHeatCabinet:
      return AssetType.SwitchHeat
    case OrchestratedAssetType.TrackCircuit:
      return AssetType.TrackCircuit
    default:
      return AssetType.Unknown
  }
}

export const getBenderDetails = (baneDataId: string): Promise<OrchestratedBender> => {
  const urlParams = new URLSearchParams({
    baneDataId,
  })

  return callApiGet<ApiOrchestratedBender>(`${BENDER_DETAILS}?${urlParams.toString()}`, ApiType.APIM, { parseDates: true }).then(
    bender => ({
      ...bender,
      inCircuit:
        bender.inCircuit
          ?.map(asset => ({
            ...asset,
            assetType: mapAssetType(asset.assetType),
          }))
          .sort((a, b) => a.name.localeCompare(b.name)) || [],
    })
  )
}
