import { Grid, CircularProgress, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import type { ManualAssetStatusBehaviourHook } from 'src/Features/ManualAssetStatus/ManualAssetStatusBehaviourHook'
import type { ChangeManualAssetStatusEvent } from 'src/Features/ManualAssetStatus/types'
import { ChangeManualAssetStatusEventType } from 'src/Features/ManualAssetStatus/types'
import type { AssetStatusEntityStatus, AssetStatusEntryDomain } from 'src/Types/AssetStatusEntry'
import { ManualAssetStatusLog } from 'src/Features/ManualAssetStatus/ManualAssetStatusLog'
import { ManualAssetStatusForm } from 'src/Features/ManualAssetStatus/ManualAssetStatusForm'

const StyledLocalBackdrop = styled(Box)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`

export type ManualAssetStatusPanelProps = {
  status: AssetStatusEntityStatus
  baneDataId: string
  domain: AssetStatusEntryDomain
  fromDate: number
  toDate: number
  useManualAssetStatusBehaviour: ManualAssetStatusBehaviourHook
  onSubmit?: (event: ChangeManualAssetStatusEvent) => void
  onCancel?: () => void
  onSubmitSuccess?: (event: ChangeManualAssetStatusEvent) => void
}

export const ManualAssetStatusPanel = ({
  status,
  onSubmit,
  onCancel,
  baneDataId,
  domain,
  fromDate,
  toDate,
  useManualAssetStatusBehaviour,
  onSubmitSuccess,
}: ManualAssetStatusPanelProps) => {
  const { assetStatusEntries, allCauses, formDisabled, formMode, enterAssetStatus, exitAssetStatus } =
    useManualAssetStatusBehaviour({
      status,
      baneDataId,
      domain,
      fromDate,
      toDate,
    })
  const availableCauses = allCauses.filter(cause => cause.status === status && cause.domain === domain)

  const handleSubmit = async (event: ChangeManualAssetStatusEvent) => {
    onSubmit?.(event)
    switch (event.type) {
      case ChangeManualAssetStatusEventType.Enter:
        await enterAssetStatus(event)
        onSubmitSuccess?.(event)
        break

      case ChangeManualAssetStatusEventType.Exit:
        await exitAssetStatus(event)
        onSubmitSuccess?.(event)
        break

      default:
        break
    }
  }

  return (
    <div>
      {formDisabled && (
        <StyledLocalBackdrop>
          <CircularProgress color="inherit" />
        </StyledLocalBackdrop>
      )}
      <Grid container direction="column" gap={2}>
        <Grid>
          <ManualAssetStatusForm
            availableCauses={availableCauses}
            onSubmit={handleSubmit}
            onCancel={() => onCancel?.()}
            formMode={formMode}
            formDisabled={formDisabled}
            status={status}
          />
        </Grid>
        <Grid>
          <ManualAssetStatusLog assetStatusLog={assetStatusEntries} availableCauses={availableCauses} status={status} />
        </Grid>
      </Grid>
    </div>
  )
}
