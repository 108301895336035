import type { PageFilters } from 'src/Types/PageFilters'
import { isPlainObject } from '@mui/utils'
import { isAppPage } from 'src/Utils/pageLink'
import { SortOrder } from 'src/Types/SortOrder'
import type { Filter } from 'src/Types/Filter'
import type { DatePeriod, DatePeriodKey } from 'src/Types/DatePeriod'
import { predefinedDatePeriodKeys } from 'src/Features/SelectedDatePeriod/predefinedDatePeriods'

export const isDatePeriod = (datePeriod: unknown): datePeriod is DatePeriod => {
  if (typeof datePeriod === 'string' && predefinedDatePeriodKeys.includes(datePeriod as DatePeriodKey)) {
    return true
  }
  return (
    isPlainObject(datePeriod) &&
    typeof datePeriod.fromDate === 'number' &&
    typeof datePeriod.toDate === 'number' &&
    datePeriod.fromDate < datePeriod.toDate
  )
}

export const isSortOrder = (sortOrder: unknown): sortOrder is SortOrder => {
  return [SortOrder.Alphabetical, SortOrder.Criticality, SortOrder.Kilometer, SortOrder.LatestAlarm, SortOrder.Unopened].includes(
    sortOrder as SortOrder
  )
}

export const isFilter = (filter: unknown): filter is Filter => {
  if (!isPlainObject(filter)) {
    return false
  }

  const { type, values } = filter
  return !(typeof type !== 'string' || !Array.isArray(values) || !values.every(v => typeof v === 'string'))
}

const parseFilters = (filters: unknown) => (Array.isArray(filters) ? filters.filter(isFilter) : undefined)

export const parsePageFilters = (pageFilters: unknown): PageFilters[] | undefined => {
  if (!Array.isArray(pageFilters)) {
    return undefined
  }

  const verifiedPageFilters: PageFilters[] = []
  pageFilters.forEach(pageFilter => {
    if (!isPlainObject(pageFilter)) {
      return
    }

    const { path, stretchLocations, datePeriod, sortOrder, filters } = pageFilter
    const parsedDatePeriod = isDatePeriod(datePeriod) ? datePeriod : undefined
    const parsedPath = isAppPage(path) ? path : undefined
    const parsedStretchLocations = Array.isArray(stretchLocations)
      ? stretchLocations.filter(s => typeof s === 'string').map(s => s as string)
      : undefined

    if (!parsedPath || !parsedDatePeriod || !parsedStretchLocations) {
      return
    }

    const newPageFilter: PageFilters = {
      path: parsedPath,
      stretchLocations: parsedStretchLocations,
      datePeriod: parsedDatePeriod,
    }
    if (isSortOrder(sortOrder)) {
      newPageFilter.sortOrder = sortOrder
    }

    const parsedFilters = parseFilters(filters)
    if (parsedFilters?.length) {
      newPageFilter.filters = parsedFilters
    }

    if (typeof pageFilter.railway === 'string') {
      newPageFilter.railway = pageFilter.railway
    }

    verifiedPageFilters.push(newPageFilter)
  })

  return verifiedPageFilters?.length ? verifiedPageFilters : undefined
}
