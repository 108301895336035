import type { SwitchHeatApiVersions } from 'src/Providers/SwitchHeat'
import { getSwitchHeatSensorDataForCabinet } from 'src/Providers/SwitchHeat'
import { useQueryWithErrorHandling } from './useQueryWithErrorHandling'

type SwitchHeatSensorDataOptions = {
  version: SwitchHeatApiVersions
}

export const useSwitchHeatSensorData = (heatCabinetId: string, { version }: SwitchHeatSensorDataOptions) => {
  const query = useQueryWithErrorHandling(
    ['switchHeatSensorData', { id: heatCabinetId, version }],
    () => getSwitchHeatSensorDataForCabinet(heatCabinetId, version),
    {},
    'switchHeat.sensorInfo.fetchError'
  )

  return {
    ...query,
    switchHeatSensorData: query.data,
  }
}
