import { isPlainObject } from '@mui/utils'
import { setHours } from 'date-fns/setHours'
import { startOfWeek } from 'date-fns/startOfWeek'
import { subDays } from 'date-fns/subDays'

import type { OpenedAlarm, RecentAlarmPeriod } from 'src/Types/OpenedAlarm'

import * as LocalSettings from 'src/Providers/Settings/LocalSettings'

const isOpenedAlarm = (alarm: unknown): alarm is OpenedAlarm => {
  return isPlainObject(alarm) && typeof alarm.baneDataId === 'string' && typeof alarm.latestAlarm === 'number'
}

const parseStoredAlarms = (alarms: unknown) => {
  if (!Array.isArray(alarms)) {
    return []
  }
  return alarms.reduce<OpenedAlarm[]>((agg, alarm) => {
    if (isOpenedAlarm(alarm)) {
      agg.push(alarm)
    }
    return agg
  }, [])
}

const getRecentAlarmDate = (recentAlarmPeriod: RecentAlarmPeriod) => {
  if (recentAlarmPeriod === 'workweek') {
    let workWeek = setHours(startOfWeek(new Date(), { weekStartsOn: 5 }), 16)
    if (workWeek > new Date()) {
      workWeek = subDays(workWeek, 7)
    }
    return workWeek.valueOf()
  }

  return startOfWeek(new Date(), { weekStartsOn: 1 }).valueOf()
}

export const isRecentAlarm = (recentAlarmPeriod: RecentAlarmPeriod, latestAlarm?: number) =>
  latestAlarm !== undefined && latestAlarm >= getRecentAlarmDate(recentAlarmPeriod)

export const alarmExists = (alarm: OpenedAlarm, array: OpenedAlarm[]) =>
  array.some(oa => oa.latestAlarm === alarm.latestAlarm && oa.baneDataId === alarm.baneDataId)

export const getOpenedAlarms = () => {
  const storedOpenedAlarms = LocalSettings.getLocalOpenedAlarms()
  const storedPendingOpenedAlarms = LocalSettings.getLocalPendingOpenedAlarms()

  const openedAlarms = storedOpenedAlarms ? parseStoredAlarms(JSON.parse(storedOpenedAlarms)) : []
  const pendingAlarms = storedPendingOpenedAlarms ? parseStoredAlarms(JSON.parse(storedPendingOpenedAlarms)) : []

  return [...openedAlarms, ...pendingAlarms.filter(pa => !alarmExists(pa, openedAlarms))]
}

export const purgeOldAlarms = (openedAlarms: OpenedAlarm[], recentPeriod: RecentAlarmPeriod) =>
  openedAlarms.filter(oa => isRecentAlarm(recentPeriod, oa.latestAlarm))

export const storeOpenedAlarms = (opened: OpenedAlarm[], pending: OpenedAlarm[]) => {
  LocalSettings.storeLocalOpenedAlarms(opened)
  LocalSettings.storeLocalOpenedPendingAlarms(pending)
}
