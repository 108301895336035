import CircularProgress from '@mui/material/CircularProgress'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import ErrorIcon from '@mui/icons-material/Error'
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { styled } from '@mui/material/styles'

import type { TemperatureUpdate } from 'src/Features/SwitchHeat/Adjustment/types'
import { TemperatureUpdateState } from 'src/Features/SwitchHeat/Adjustment/types'
import { useLanguage } from 'src/Hooks/useLanguage'

const StyledSmallCircularProgress = styled(CircularProgress)`
  width: 20px;
  height: 20px;
`

const StyledChip = styled(Chip)`
  height: auto;
  min-height: 32px;

  .MuiChip-label {
    white-space: break-spaces;
    line-height: 1.2;
  }
`

export const getUpdateStateIcon = (state: TemperatureUpdateState) => {
  switch (state) {
    case TemperatureUpdateState.Idle:
      return <PanoramaFishEyeIcon fontSize="small" />
    case TemperatureUpdateState.Updating:
      return <StyledSmallCircularProgress disableShrink size="20px" />
    case TemperatureUpdateState.Success:
      return <CheckCircleOutlineIcon color="success" fontSize="small" />
    case TemperatureUpdateState.Failed:
      return <ErrorIcon color="error" fontSize="small" />
    default:
      return undefined
  }
}

type OwnProps = {
  updates: TemperatureUpdate[]
}

export const TemperatureUpdateList = ({ updates }: OwnProps) => {
  const { t } = useLanguage()

  return (
    <Stack direction="row" spacing={0.5} useFlexGap flexWrap="wrap" data-testid="TemperatureUpdates_List">
      {updates.map(({ sensorId, label, state, newValue, originalValue }) => {
        return (
          <StyledChip
            variant="outlined"
            key={sensorId}
            data-testid="TemperatureUpdates_ListItem"
            data-value={TemperatureUpdateState[state]?.toLowerCase()}
            icon={getUpdateStateIcon(state)}
            label={
              <>
                <span>{label}</span>
                {', '}
                <span>{t('switchHeat.temperatureForm.changedFromTo', { from: originalValue, to: newValue })}</span>
              </>
            }
          />
        )
      })}
    </Stack>
  )
}
