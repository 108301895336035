import type { QueryStatus } from 'react-query'
import type { MonitoringSystem } from 'src/Types/MonitoringSystem'
import { FullWidthLoader } from 'src/Components/FullWidthLoader'
import { AssetCardSkeleton } from 'src/Components/AssetCard/AssetCardSkeleton'
import { Alert } from 'src/Components/Alert'
import { NoResult } from 'src/Components/NoResult'
import { useTranslation } from 'react-i18next'
import { getLeastAdvancedQueryStatus } from 'src/Utils/network'

type OwnProps = {
  allStatuses: Record<MonitoringSystem, QueryStatus>
  hasAssets: boolean
  hasFilteredAssets: boolean
  onlyWithAlarms: boolean
}

export const AssetListResultMessage = ({ allStatuses, hasAssets, hasFilteredAssets, onlyWithAlarms }: OwnProps) => {
  const { t } = useTranslation()

  const statusList = Object.values(allStatuses)
  const status = getLeastAdvancedQueryStatus(statusList)

  if (status === 'loading') {
    return (
      <>
        <FullWidthLoader />
        <AssetCardSkeleton />
      </>
    )
  }

  const target = onlyWithAlarms ? t('assetList.alarms') : t('assetList.assets')

  if (status === 'error') {
    const missing = Object.entries(allStatuses)
      .filter(([, status]) => status === 'error')
      .map(([monitoringSystem]) => monitoringSystem)
      .map(monitoringSystem => t(`sideMenu.system.${monitoringSystem.toLowerCase()}`))
      .join(', ')
      .toLowerCase()

    return (
      <Alert severity="error" margin={16}>
        {t('assetList.incompleteFetchError', { target, missing })}
      </Alert>
    )
  }

  if (!hasAssets) {
    return <NoResult>{t('assetList.noneFound', { target })}</NoResult>
  }

  if (!hasFilteredAssets) {
    return <NoResult>{t('assetList.noFilteredMatches', { target })}</NoResult>
  }

  return null
}
