import { useEffect } from 'react'
import type { UseQueryResult } from 'react-query'

import type { QueryError } from 'src/Types/QueryError'
import { Language } from 'src/Types/LanguageTypes'

import { useAppStateStore } from 'src/Store/appState'
import { useLanguage } from './useLanguage'
import type { AppMessage } from 'src/Types/AppMessage'

export const getCustomErrorMessage = (error: QueryError | Error) => {
  if ('name' in error && error.name === 'AxiosError') {
    const message = 'response' in error ? error.response.data?.message : undefined

    return message && typeof message === 'object' && Language.no in message && Language.en in message ? message : undefined
  }

  return undefined
}

export const useAppErrorMessage = <T extends UseQueryResult<any, QueryError>>(query: T, errorKey: string) => {
  const { currentLanguage } = useLanguage()

  useEffect(() => {
    if (query.isError) {
      let message: AppMessage = {
        type: 'error',
        message: errorKey,
      }

      const customMessage = query.error ? getCustomErrorMessage(query.error) : undefined

      if (customMessage) {
        message = {
          type: 'error',
          message: customMessage[currentLanguage],
          raw: true,
        }
      }

      useAppStateStore.getState().appMessages.queueMessage(message)
    }
  }, [query.isError]) // eslint-disable-line react-hooks/exhaustive-deps
}
