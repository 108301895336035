import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import { HeaderWithExpandableInfo } from 'src/Components/HeaderWithExpandableInfo'
import { NoResult } from 'src/Components/NoResult'
import { Alert } from 'src/Components/Alert'
import { SubHeaderWithExpandableInfo } from 'src/Components/SubHeaderWithExpandableInfo'
import { PointMachineHeatChart } from './PointMachineHeatChart'
import { CardInformationEntry } from 'src/Components/AssetCard/CardInformationEntry'

import type { DatePeriod } from 'src/Types/DatePeriod'

import { usePointMachineHeatSensorStatus } from 'src/Hooks/NetworkData/usePointMachineHeatSensorStatus'
import themeColors from 'src/theme'
import { SubHeaderKey } from 'src/Providers/Settings/SubHeader'

const StyledInfoContainer = styled('div')`
  width: 600px;
  & > div:nth-of-type(even) {
    background-color: ${themeColors.sectionAlternateRow};
  }
`

type OwnProps = {
  switchBaneDataId: string
  datePeriod: DatePeriod
  isDefaultExpanded: boolean
}

export const PointMachineHeatStatus = ({ switchBaneDataId, datePeriod, isDefaultExpanded }: OwnProps) => {
  const { t } = useTranslation()
  const { sensorStatus, status } = usePointMachineHeatSensorStatus(switchBaneDataId)

  if (status === 'idle') {
    return null
  }

  if (status === 'loading') {
    return (
      <Box mt={2}>
        <CircularProgress color="inherit" size={15} /> {t('switches.pointMachineHeat.loading')}
      </Box>
    )
  }

  if (status === 'error') {
    return (
      <Box mt={2}>
        <Alert severity="error">{t('switches.pointMachineHeat.sensorsFetchError')}</Alert>
      </Box>
    )
  }

  const firstAssetWithSensor = sensorStatus?.assets.find(a => a.sensors.length > 0)
  const hasSensors = !!firstAssetWithSensor

  if (!sensorStatus || !hasSensors) {
    return (
      <Box mt={2}>
        <NoResult>{t('switches.pointMachineHeat.noSensors')}</NoResult>
      </Box>
    )
  }

  const firstSensor = firstAssetWithSensor.sensors[0]
  const isSequence = firstSensor.codeSystem === 'sequence'

  return (
    <Box mt={2}>
      <HeaderWithExpandableInfo title={t('switches.pointMachineHeat.title')} isDefaultExpanded={isDefaultExpanded}>
        <SubHeaderWithExpandableInfo
          title={t('switches.pointMachineHeat.chart.title')}
          isDefaultExpanded
          subHeaderKey={SubHeaderKey.PointMachineHeatChart}
        >
          <PointMachineHeatChart
            switchBaneDataId={switchBaneDataId}
            isSequence={isSequence}
            sensorStatus={sensorStatus}
            datePeriod={datePeriod}
          />
        </SubHeaderWithExpandableInfo>
        <StyledInfoContainer>
          <CardInformationEntry
            label={t('switches.pointMachineHeat.info.type.label')}
            value={
              isSequence ? t('switches.pointMachineHeat.info.type.sequence') : t('switches.pointMachineHeat.info.type.single')
            }
          />
          <CardInformationEntry label={t('switches.pointMachineHeat.info.additionalId')} value={firstSensor.additionalId} />
        </StyledInfoContainer>
      </HeaderWithExpandableInfo>
    </Box>
  )
}
