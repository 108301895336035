import { useReducer, useEffect, useRef } from 'react'
import type { SensorTwinModus } from 'src/Types/SensorTwin'

type OwnParams = {
  updateSwitchHeatModusStatus: UpdateStatus
  currentModus: SensorTwinModus | undefined
  requestedModus: SensorTwinModus | undefined
  eTag: string | undefined
  modusUpdateTimeout?: number
}

export type UpdateStatus = 'idle' | 'loading' | 'success' | 'error'

export enum ActionKind {
  MODUS_UPDATE_STATUS = 'MODUS_UPDATE_STATUS',
  CURRENT_MODUS = 'CURRENT_MODUS',
  REQUESTED_MODUS = 'REQUESTED_MODUS',
  TIMEOUT = 'TIMEOUT',
}

type Action =
  | {
      type: ActionKind.MODUS_UPDATE_STATUS
      payload: UpdateStatus
    }
  | {
      type: ActionKind.CURRENT_MODUS
      payload: SensorTwinModus | undefined
    }
  | {
      type: ActionKind.REQUESTED_MODUS
      payload: SensorTwinModus | undefined
    }
  | {
      type: ActionKind.TIMEOUT
      payload?: undefined
    }

export type State = {
  detecting: boolean
  updateStatus?: UpdateStatus
  finalResult?: 'success' | 'error' | 'timeout'
  requestedModus?: SensorTwinModus
  currentModus?: SensorTwinModus
}

const detectEnded = (newState: State): State => {
  if (
    newState.detecting &&
    newState.updateStatus === 'success' &&
    newState.currentModus &&
    newState.requestedModus &&
    newState.currentModus === newState.requestedModus
  ) {
    return {
      ...newState,
      detecting: false,
      finalResult: 'success',
    }
  }
  return newState
}

export const detectFinalizedModusUpdateReducer = (state: State, action: Action): State => {
  const { type, payload } = action

  switch (type) {
    case ActionKind.MODUS_UPDATE_STATUS: {
      switch (payload) {
        case 'loading':
          return {
            updateStatus: payload,
            detecting: true,
          }

        case 'error':
          return {
            updateStatus: payload,
            detecting: false,
            finalResult: 'error',
          }

        case 'idle':
          return {
            updateStatus: payload,
            detecting: false,
          }

        case 'success':
          return {
            updateStatus: payload,
            detecting: true,
          }

        default:
          return state
      }
    }

    case ActionKind.REQUESTED_MODUS: {
      return detectEnded({
        ...state,
        requestedModus: payload,
      })
    }

    case ActionKind.CURRENT_MODUS: {
      return detectEnded({
        ...state,
        currentModus: payload,
      })
    }

    case ActionKind.TIMEOUT: {
      if (state.detecting && state.updateStatus === 'success') {
        return {
          detecting: false,
          finalResult: 'timeout',
        }
      }
      return state
    }

    default:
      return state
  }
}

const ONE_MINUTE_IN_MS = 60000

/**
 * This hook detects if the modus change process has finalized.
 * The modus has successfully finalized when:
 * - the modus update request has been successful
 * - the current modus and the requested modus are the same
 * Updated current or requested modus are detected by a changing eTag.
 * The detection process aborts after one minute.
 */
export const useDetectFinalizedModusUpdate = ({
  updateSwitchHeatModusStatus,
  currentModus,
  requestedModus,
  eTag,
  modusUpdateTimeout = ONE_MINUTE_IN_MS,
}: OwnParams): Pick<State, 'finalResult' | 'detecting'> => {
  const [state, dispatch] = useReducer(detectFinalizedModusUpdateReducer, { detecting: false })
  const timeoutId = useRef<number>()

  useEffect(() => {
    dispatch({ type: ActionKind.MODUS_UPDATE_STATUS, payload: updateSwitchHeatModusStatus })
  }, [updateSwitchHeatModusStatus])

  useEffect(() => {
    dispatch({ type: ActionKind.CURRENT_MODUS, payload: currentModus })
    dispatch({ type: ActionKind.REQUESTED_MODUS, payload: requestedModus })
  }, [eTag]) // eslint-disable-line react-hooks/exhaustive-deps

  // Abort detection after one minute
  useEffect(() => {
    window.clearTimeout(timeoutId.current)
    if (updateSwitchHeatModusStatus === 'success') {
      timeoutId.current = window.setTimeout(() => {
        dispatch({ type: ActionKind.TIMEOUT })
      }, modusUpdateTimeout)
    }

    return () => window.clearTimeout(timeoutId.current)
  }, [updateSwitchHeatModusStatus]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    finalResult: state.finalResult,
    detecting: state.detecting,
  }
}
