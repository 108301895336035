import type { SystemStatus } from './SystemStatus'

export enum FilterType {
  AssetId = 'assetId',
  Status = 'status',
  System = 'system',
  AlarmTypeId = 'alarmTypeId',
}

export type AssetIdFilter = {
  type: FilterType.AssetId
  values: string[]
}

export type StatusFilter = {
  type: FilterType.Status
  values: SystemStatus[]
}

export type SystemFilter = {
  type: FilterType.System
  values: string[]
}

export type AlarmTypeIdFilter = {
  type: FilterType.AlarmTypeId
  values: string[]
}

export type Filter = AssetIdFilter | SystemFilter | AlarmTypeIdFilter | StatusFilter
