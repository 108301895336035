import type { TrackCircuit } from 'src/Types/TrackCircuitTypes'

import { formatNumber } from './format'

/**
 * Creates an info object with key fields from a track circuit.
 *
 * @param trackCircuit - Track circuit to extract info from.
 */
export const getTrackCircuitInfo = (trackCircuit: TrackCircuit) => ({
  km: `${formatNumber(trackCircuit.fromKm, 3)} - ${formatNumber(trackCircuit.toKm, 3)}`,
  length: trackCircuit.length,
  neighbors: trackCircuit.neighbors
    .map(n => n.m40TrackCircuit?.tcId)
    .filter(id => !!id)
    .join(', '),
  baneDataId: trackCircuit.baneDataId,
  relayType: trackCircuit.relayType,
  trackCircuitType: trackCircuit.trackCircuitType,
})
