import { styled } from '@mui/material/styles'
import Popper from '@mui/material/Popper'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Fade from '@mui/material/Fade'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

import type { PopperReferenceObject } from 'src/Types/PopperReferenceObject'

export type PopperInfoRow = {
  label: string
  value?: string | number
}

const StyledPopper = styled(Popper)`
  padding: 10px;
  z-index: 10000;
  max-width: 500px;
`

const StyledPaper = styled(Paper)`
  padding: 10px;
`

const StyledHeader = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 15px;
`

const StyledTableRow = styled(TableRow)`
  height: 25px;
`
const StyledTableCellLabel = styled(TableCell)`
  font-weight: bold;
`

const StyledTableCellVaue = styled(TableCell)`
  padding-left: 4px;
`

type OwnProps = {
  id: string
  header: string
  skipFalsy?: boolean
  anchorElement: PopperReferenceObject
  rows: PopperInfoRow[]
}

export const AssetInfoPopper = ({ id, header, anchorElement, skipFalsy = true, rows }: OwnProps) => {
  const rowsToShow = rows.filter(row => !skipFalsy || row.value)

  return (
    <StyledPopper id={id} anchorEl={anchorElement} placement="top" open transition>
      {({ TransitionProps }) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Fade {...TransitionProps} timeout={350}>
          <StyledPaper>
            <>
              <StyledHeader variant="h2">{header}</StyledHeader>
              <Table padding="none">
                {rowsToShow.map(row => (
                  <TableBody key={row.label}>
                    <StyledTableRow>
                      <StyledTableCellLabel component="th" scope="row">
                        {row.label}:
                      </StyledTableCellLabel>
                      <StyledTableCellVaue>{row.value}</StyledTableCellVaue>
                    </StyledTableRow>
                  </TableBody>
                ))}
              </Table>
            </>
          </StyledPaper>
        </Fade>
      )}
    </StyledPopper>
  )
}
