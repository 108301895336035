import type { ChangeEvent } from 'react'
import { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import type { SelectChangeEvent } from '@mui/material/Select'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import FormControl from '@mui/material/FormControl'
import Box from '@mui/material/Box'

import type { Filter } from 'src/Types/Filter'
import { FilterType } from 'src/Types/Filter'
import type { SwingEntry } from 'src/Types/SwingEntry'
import type { Asset } from 'src/Types/AssetTypes'
import { AssetType } from 'src/Types/AssetTypes'
import type { SwingStatistics } from 'src/Types/SwingStatistics'
import { SwingDirectionFilter, SwingDirection } from 'src/Types/SwingDirectionTypes'

import { getClosestTimestamp } from 'src/Utils/alarms'
import { NoResult } from 'src/Components/NoResult'
import { SwingSelector } from 'src/Features/Switches/SwitchCard/SwingSelector'
import { SwitchAndSwingInfo } from 'src/Features/Switches/SwitchCard/SwingInfo/SwitchAndSwingInfo'
import { AlarmTypeIdFilterSelector } from 'src/Components/SidePanelMenu/FilterSelectors/AlarmTypeIdFilterSelector'
import { MonitoringSystem } from 'src/Types/MonitoringSystem'
import { useBreakpointDown } from 'src/Hooks/useBreakpoint'

const swingDirectionOptions = [SwingDirectionFilter.All, SwingDirectionFilter.Left, SwingDirectionFilter.Right]

type OwnProps = {
  machineBaneDataIds: string[]
  swings: SwingEntry[]
  isFetching: boolean
  isLoaded: boolean
  asset: Asset
  swingStatistics: SwingStatistics[]
}

export const SwitchAlarmsAndSwing = ({ machineBaneDataIds, swings, isFetching, isLoaded, asset, swingStatistics }: OwnProps) => {
  const { t } = useTranslation()
  const isMediaLG = useBreakpointDown('lg')

  const [showOnlyAlarmSwings, setShowOnlyAlarmSwings] = useState(false)
  const [selectedSwingDirection, setSelectedSwingDirection] = useState(SwingDirectionFilter.All)
  const [selectedSwingTimestamp, setSelectedSwingTimestamp] = useState<number>()
  const [alarmFilters, setAlarmFilters] = useState<string[]>([])

  const allAlarmTypeIds = useMemo(() => {
    const ids = swings.flatMap(swing => swing.alarmTypeIds)
    return [...new Set(ids)]
  }, [swings])

  const filteredSwings = useMemo(
    () =>
      (swings ?? [])
        .filter(swing => (showOnlyAlarmSwings ? swing.status === 'Alarm' || swing.status === 'Warning' : true))
        .filter(swing => {
          if (!alarmFilters?.length || !swing.alarmTypeIds?.length) {
            return true
          }
          const filterIds = swing.alarmTypeIds.map(alarmTypeId => `${MonitoringSystem.PointMachine}-${alarmTypeId}`)
          return filterIds.some(filterId => alarmFilters.includes(filterId))
        })
        .filter(
          swing =>
            selectedSwingDirection === SwingDirectionFilter.All ||
            (selectedSwingDirection === SwingDirectionFilter.Right && swing.direction === SwingDirection.Right) ||
            (selectedSwingDirection === SwingDirectionFilter.Left && swing.direction === SwingDirection.Left)
        ),
    [swings, showOnlyAlarmSwings, alarmFilters, selectedSwingDirection]
  )

  useEffect(() => {
    let bestTimestamp: number | undefined
    if (filteredSwings.length) {
      if (selectedSwingTimestamp) {
        bestTimestamp = getClosestTimestamp(filteredSwings, selectedSwingTimestamp)
      } else {
        const lastSwingWithAlarm = [...filteredSwings].reverse().find(s => s.status === 'Alarm' || s.status === 'Warning')
        bestTimestamp = lastSwingWithAlarm ? lastSwingWithAlarm.timestamp : filteredSwings[filteredSwings.length - 1].timestamp
      }
    }
    setSelectedSwingTimestamp(bestTimestamp)
  }, [filteredSwings, selectedSwingTimestamp])

  const directionChanged = (event: SelectChangeEvent) => {
    const direction = event.target.value as SwingDirectionFilter
    setSelectedSwingDirection(direction)
  }

  const currentSwing = filteredSwings?.find(swing => swing.timestamp === selectedSwingTimestamp)

  const handleAlarmTypeIdFilterChange = ({ values }: Filter) => {
    setAlarmFilters(values)
    setShowOnlyAlarmSwings(!!values.length)
  }

  const handleShowOnlyAlarmSwingsChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShowOnlyAlarmSwings(event.target.checked)
    if (!event.target.checked) {
      setAlarmFilters([])
    }
  }
  return (
    <>
      <Box mt={2} ml={2} mr={2}>
        <Grid container direction={isMediaLG ? 'column' : 'row'} mb={1} mt={1} rowGap={1}>
          <Grid item sm={6}>
            <Box mr={2} maxWidth={480}>
              {allAlarmTypeIds.length ? (
                <AlarmTypeIdFilterSelector
                  key="typeId"
                  filter={{ type: FilterType.AlarmTypeId, values: alarmFilters }}
                  onFilterUpdated={handleAlarmTypeIdFilterChange}
                  assetTypeSystems={[AssetType.SwitchPointMachine]}
                  includeFilter={type => allAlarmTypeIds.includes(type.type.id)}
                />
              ) : null}
            </Box>
          </Grid>
          <Grid item container sm={6} justifyContent={!isMediaLG ? 'flex-end' : ''}>
            <Grid item container maxWidth={550}>
              <Grid item sm={6} xs={12}>
                <Box mr={2}>
                  <FormControl fullWidth>
                    <InputLabel id="swingDirectionFilter">{t('switches.statusLabels.direction.label')}</InputLabel>
                    <Select labelId="swingDirectionFilter" value={selectedSwingDirection} onChange={directionChanged} fullWidth>
                      {swingDirectionOptions.map(direction => (
                        <MenuItem key={direction} value={direction}>
                          {t(`switches.statusLabels.direction.${direction}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormControlLabel
                  control={<Switch checked={showOnlyAlarmSwings} onChange={handleShowOnlyAlarmSwingsChange} color="primary" />}
                  label={t('switches.hideSwingsWithoutAlarm')}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {selectedSwingTimestamp && (
        <Box m={2}>
          <SwingSelector
            swings={filteredSwings}
            selectedSwingTimestamp={selectedSwingTimestamp}
            isRefetching={isFetching && !isLoaded}
            statusWindows={asset.assetStatusWindows}
            onSwingChanged={setSelectedSwingTimestamp}
          />
        </Box>
      )}
      {!filteredSwings?.length && (
        <NoResult>
          {t('switches.swings.noneFound')}
          <br />
          {t('switches.statusLabels.pointMachineName')}: {machineBaneDataIds.join(',')}
        </NoResult>
      )}
      {currentSwing && (
        <SwitchAndSwingInfo
          asset={asset}
          swingId={currentSwing.swingId}
          swingStatus={currentSwing.status}
          swingStatistics={swingStatistics}
        />
      )}
    </>
  )
}
