import type { SensorCodeDomain, SensorCode } from 'src/Types/SensorCode'

import { getSensorCodes } from 'src/Providers/SensorCodes'
import { useDomainConstantsStore } from 'src/Store/domainConstants'
import { useQueryWithErrorHandling } from './useQueryWithErrorHandling'
import { useGenericCacheBehaviour } from 'src/Hooks/useGenericCacheBehaviour'

export const useSensorCodesLoader = (domain: SensorCodeDomain) => {
  const setSensorCodes = useDomainConstantsStore(state => state.sensorCodes.setSensorCodes)

  const { getFreshOrCachedValue } = useGenericCacheBehaviour<SensorCode[]>({
    partialKey: `sensorCodes.${domain}`,
    getFreshValue: () => getSensorCodes(domain),
    onValueReceived: sensorCodes => setSensorCodes(sensorCodes, domain),
  })

  return useQueryWithErrorHandling(
    ['sensorCodes', { domain }],
    () => getFreshOrCachedValue(),
    {
      staleTime: Infinity,
    },
    'sensorCodes.codesFetchError'
  )
}
