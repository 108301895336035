import type { SyntheticEvent } from 'react'
import { useState, Fragment } from 'react'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

import type { SystemStatus } from 'src/Types/SystemStatus'

import { CardTabs } from 'src/Components/CardTabs'
import { SubHeaderWithExpandableInfo } from 'src/Components/SubHeaderWithExpandableInfo'
import themeColors from 'src/theme'
import { AlarmIndicatorWithLabel } from 'src/Components/AssetCard/Sensor/AlarmIndicatorWithLabel'
import { AlarmIndicatorIcon } from 'src/Components/AlarmIndicatorIcon'
import { SubHeaderKey } from 'src/Providers/Settings/SubHeader'
import { CardTab } from 'src/Components/CardTab'

export type ElementTab = {
  label: string
  systemStatus: SystemStatus
  opcAddressSwitchError?: string
}

export type ElementDefinition = {
  label: string
  value: string
  systemStatus?: SystemStatus
}

export type ElementPanel = {
  elements: {
    definitions: ElementDefinition[]
  }[]
}

export type ElementTabAndPanel = { tab: ElementTab; panel: ElementPanel }

type OwnProps = {
  tabs: ElementTabAndPanel[]
}

const StyledElements = styled(Box)(
  ({ theme }) => `
  position: relative;
  padding: 0;
  row-gap: ${theme.spacing(2)};
  column-gap: ${theme.spacing(5)};
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  ${theme.breakpoints.up('md')} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${theme.breakpoints.up('lg')} {
    grid-template-columns: repeat(3, 1fr);
  }

  :before {
    content: '';
    display: block;
    position: absolute;
    background-color: #fff;
    height: 1px;
    width: 100%;
    z-index: 1;
    top: 0;
  }
`
)

const StyledElement = styled(Box)(
  ({ theme }) => `
  border-top: solid 1px ${themeColors.sectionHeader};
  padding: ${theme.spacing(2)} 0 0 0;
`
)

const StyledDefinitionList = styled('dl')(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${theme.spacing(1)} 0;
  font-size: 0.875rem;
  padding: 0;
  margin: 0;

  > dd,
  > dt {
    display: block;
    width: 50%;
    padding: 0;
    margin: 0;
  }
`
)

const StyledDefinitionValue = styled('div')`
  text-align: right;
  font-weight: bold;
`

export const SwitchHeatElements = ({ tabs }: OwnProps) => {
  const { t } = useTranslation()
  const [tabIndex, setTabIndex] = useState(0)

  const handleTabChange = (event: SyntheticEvent, value: number) => {
    setTabIndex(value)
  }

  const tabAndPanel = tabs[tabIndex]
  const panel = tabAndPanel ? tabAndPanel.panel : undefined
  const tab = tabAndPanel ? tabAndPanel.tab : undefined

  return (
    <Box>
      <SubHeaderWithExpandableInfo
        title={t('switchHeat.switchHeatElements.title')}
        subHeaderKey={SubHeaderKey.SwitchHeatElements}
      >
        <CardTabs value={tabIndex} onChange={handleTabChange}>
          {tabs
            .map(t => t.tab)
            .map(({ label, systemStatus }, i) => (
              <CardTab
                key={label}
                label={label}
                value={i}
                icon={<AlarmIndicatorIcon alarmIndicator={systemStatus} size="small" />}
              />
            ))}
        </CardTabs>
        {tab?.opcAddressSwitchError && (
          <Box p={2}>
            <p>
              {t('switchHeat.opcAddressSwitchError')}: <b>{tab.opcAddressSwitchError}</b>
            </p>
          </Box>
        )}
        <Box p={2}>
          <StyledElements>
            {panel?.elements.map(({ definitions }, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <StyledElement key={i}>
                <StyledDefinitionList>
                  {definitions.map(({ label, value, systemStatus }) => (
                    <Fragment key={label}>
                      <dt>{label}</dt>
                      <dd>
                        <StyledDefinitionValue>
                          {typeof systemStatus !== 'undefined' ? (
                            <AlarmIndicatorWithLabel alarmIndicator={systemStatus} value={value} />
                          ) : (
                            <strong>{value}</strong>
                          )}
                        </StyledDefinitionValue>
                      </dd>
                    </Fragment>
                  ))}
                </StyledDefinitionList>
              </StyledElement>
            ))}
          </StyledElements>
        </Box>
      </SubHeaderWithExpandableInfo>
    </Box>
  )
} //
