import type { TabModelColumn } from 'src/Features/SwitchHeat/TabModel'
import type { SensorTwin } from 'src/Types/SensorTwin'

export type ColumnGroupedSensors = Record<number, SensorTwin[]>

export const getColumnGroupedSensors = (sensors: SensorTwin[], columns: TabModelColumn[]): ColumnGroupedSensors => {
  const groupedSensors: ColumnGroupedSensors = {}
  const columnNumber = columns.map(column => column.index)
  const maxColumn = Math.min(Math.max(...columnNumber), 3)
  for (let i = 1; i <= maxColumn; i++) {
    const column = columns.find(column => column.index === i)
    const sensorsInColumn = column?.codeSystemKeys.map(key => sensors.find(sensor => sensor.codeSystem === key)!).filter(Boolean)
    groupedSensors[i] = sensorsInColumn || []
  }
  return groupedSensors
}
