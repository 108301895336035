import type { SwitchHeatCabinet, HeatElement, SwitchHeatElements } from 'src/Types/SwitchHeatCabinet'
import type { ElementTabAndPanel, ElementDefinition, ElementTab } from 'src/Features/SwitchHeat/SwitchHeatElements'
import type { Alarm } from 'src/Types/Alarm'
import type { SystemStatus } from 'src/Types/SystemStatus'
import type { AlarmType } from 'src/Types/AlarmType'
import type { Language } from 'src/Types/LanguageTypes'
import type { TFunction } from 'i18next'

import { getAlarmTypesFromAlarms } from 'src/Utils/alarms'
import { formatLocalDateTime } from 'src/Utils/format'

type TranslationFunction = TFunction

const getSystemStatusByAlarmTypes = (alarmTypes: AlarmType[]) => {
  let systemStatus: SystemStatus = 'Ok'
  if (alarmTypes.length) {
    systemStatus = alarmTypes.some(at => at.classification.id === 'Alarm') ? 'Alarm' : 'Warning'
  }
  return systemStatus
}

export const getElementDefinitions = (
  heatElement: HeatElement,
  alarms: Alarm[],
  t: TranslationFunction,
  currentLanguage: Language
): ElementDefinition[] => {
  const elementAlarms = alarms.filter(alarm => alarm.baneDataId === heatElement.baneDataId)
  const alarmTypes = getAlarmTypesFromAlarms(elementAlarms)
  const systemStatus = getSystemStatusByAlarmTypes(alarmTypes)
  const alarmText = alarmTypes.map(at => at.type.localization.short[currentLanguage])
  return [
    {
      label: t('switchHeat.switchHeatElements.alarm'),
      value: alarmText.length ? alarmText.join(',') : t('general.ok'),
      systemStatus,
    },
    {
      label: t('switchHeat.switchHeatElements.placement'),
      value: heatElement.placement ?? heatElement.name,
    },
    {
      label: t('switchHeat.switchHeatElements.baneDataId'),
      value: heatElement.baneDataId,
    },
    {
      label: t('switchHeat.switchHeatElements.installDate'),
      value: heatElement.installDate ? formatLocalDateTime(heatElement.installDate, true) : t('general.unknown'),
    },
    {
      label: t('switchHeat.switchHeatElements.type'),
      value: heatElement.type,
    },
    {
      label: t('switchHeat.switchHeatElements.manufacturer'),
      value: heatElement.manufacturer,
    },
    {
      label: t('switchHeat.switchHeatElements.length'),
      value: `${heatElement.length} mm`,
    },
    {
      label: t('switchHeat.switchHeatElements.voltage'),
      value: `${heatElement.voltage} V`,
    },
    {
      label: t('switchHeat.switchHeatElements.wattage'),
      value: `${heatElement.wattage} W`,
    },
    {
      label: t('switchHeat.switchHeatElements.opcAddressError'),
      value: `${heatElement.opcAddressError ?? t('switchHeat.sensorInfo.unknown')}`,
    },
    {
      label: t('switchHeat.switchHeatElements.opcAddressSsr'),
      value: `${heatElement.opcAddressSsr ?? t('switchHeat.sensorInfo.unknown')}`,
    },
  ]
}

const getTabLabel = ({ switchName, connectedToSwitchName, name }: SwitchHeatElements) => {
  if (switchName?.length) {
    return switchName
  }
  if (connectedToSwitchName?.length) {
    return connectedToSwitchName
  }
  return name
}

const getTabAndPanel = (
  switchHeatElement: SwitchHeatElements,
  alarms: Alarm[],
  t: TranslationFunction,
  currentLanguage: Language
): ElementTabAndPanel => {
  const { switchBaneDataId, baneDataId, heatElements } = switchHeatElement
  const heatElementBaneDataIds = heatElements?.map(({ baneDataId }) => baneDataId) || []
  const baneDataIds = [...heatElementBaneDataIds, switchBaneDataId, baneDataId]
  const switchAlarms = alarms.filter(alarm => baneDataIds.includes(alarm.baneDataId))
  const alarmTypes = getAlarmTypesFromAlarms(switchAlarms)
  const systemStatus = getSystemStatusByAlarmTypes(alarmTypes)
  const opcAddressSwitchError = switchHeatElement.opcAddressSwitchError
  const elements =
    heatElements?.map(heatElement => ({ definitions: getElementDefinitions(heatElement, alarms, t, currentLanguage) })) || []

  const tab: ElementTab = {
    label: getTabLabel(switchHeatElement),
    systemStatus,
    opcAddressSwitchError,
  }

  return {
    tab,
    panel: {
      elements,
    },
  }
}

export const mapToSwitchHeatElementsData = (
  asset: SwitchHeatCabinet,
  alarms: Alarm[],
  t: TranslationFunction,
  currentLanguage: Language
) => {
  const tabs =
    asset.switchHeatElements?.map(switchHeatElement => getTabAndPanel(switchHeatElement, alarms, t, currentLanguage)) || []

  return tabs
}
