import type { IMetricTelemetry } from '@microsoft/applicationinsights-common'
import type { ICustomProperties } from '@microsoft/applicationinsights-core-js'
import { getAppInsights } from 'src/telemetryService'
import { scriptEvalTime, sessionScriptLoadDuration } from 'src/Tracking/trackingValues'

const trackedNames: string[] = []

/**
 * Track a metric with the given name and duration.
 */
export const trackMetric = (
  name: string,
  duration: number,
  metric: IMetricTelemetry | undefined,
  customProperties: ICustomProperties | undefined
) => {
  if (trackedNames.includes(name)) {
    return
  }
  const appInsights = getAppInsights()
  appInsights?.trackMetric(
    {
      ...metric,
      name,
      average: duration,
    },
    customProperties
  )
  trackedNames.push(name)
}

/**
 * Track the time it took to load the javascript as metric named 'app-script-load'.
 */
export const trackScriptLoadTime = (metric?: IMetricTelemetry, customProperties?: ICustomProperties) => {
  const duration = sessionScriptLoadDuration.get()
  if (!duration) {
    return
  }
  trackMetric('app-script-load', duration, metric, customProperties)
  sessionScriptLoadDuration.delete()
}

/**
 * Track a metric with duration since the script was evaluated/loaded.
 */
export const trackDurationSinceScriptEval = (name: string, metric?: IMetricTelemetry, customProperties?: ICustomProperties) =>
  trackMetric(name, Date.now() - scriptEvalTime, metric, customProperties)
