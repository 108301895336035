import { getGenericMachineAtLocations, getGenericMachineForBaneDataIds } from 'src/Providers/GenericMachine'
import { AssetType } from 'src/Types/AssetTypes'
import type { DateRange } from 'src/Types/DatePeriod'

export const getDerailerMachinesAtLocations = (
  dateRange: DateRange,
  locationIds: string[],
  onlyWithAlarms: boolean,
  enableAllAlarmTypes: boolean
) => getGenericMachineAtLocations(dateRange, locationIds, onlyWithAlarms, enableAllAlarmTypes, AssetType.DerailerMachine)

export const getDerailerMachinesForBaneDataIds = (
  dateRange: DateRange,
  baneDataIds: string[],
  onlyWithAlarms: boolean,
  enableAllAlarmTypes: boolean
) => getGenericMachineForBaneDataIds(dateRange, baneDataIds, onlyWithAlarms, enableAllAlarmTypes, AssetType.DerailerMachine)
