import { AssetInformationEntry } from 'src/Components/AssetCard/AssetInformationEntry'
import { useLanguage } from 'src/Hooks/useLanguage'

type OwnProps = {
  pointMachineTypes: string[]
}

export const PointMachineInfo = ({ pointMachineTypes }: OwnProps) => {
  const { t } = useLanguage()

  return <AssetInformationEntry label={t('switches.statusLabels.maintenance.pointMachineType')} values={pointMachineTypes} />
}
