import type { ComponentProps } from 'react'
import { styled } from '@mui/material/styles'
import { Tab } from '@mui/material'

export const StyledCardTab = styled(Tab)`
  padding: 6px 16px;
  font-size: 20px;
  max-height: unset;
  text-transform: unset;
  opacity: 1;
  min-height: auto;
`

export const CardTab = ({ iconPosition, ...rest }: ComponentProps<typeof StyledCardTab>) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <StyledCardTab iconPosition={iconPosition || 'end'} {...rest} />
}
