import { useAppStateStore } from 'src/Store/appState'
import { QueryStringStateKeys, useQueryStringState } from 'src/Store/useQueryStringState'
import { getPendingDateRange } from 'src/Features/SelectedDatePeriod/predefinedDatePeriods'
import { useDeepRailways } from 'src/Hooks/NetworkData/useDeepRailways'

import type { DatePeriod } from 'src/Types/DatePeriod'

export const useLocationsAndDatePeriodSearch = () => {
  const selectedWeatherLocationId = useAppStateStore(state => state.locations.selectedWeatherLocationId)
  const { data: railways } = useDeepRailways()
  const locations = railways?.flatMap(railway => railway.railwaySections).flatMap(section => section.locations) ?? []
  const setSelectedWeatherLocationId = useAppStateStore(state => state.locations.setSelectedWeatherLocationId)

  const pendingDatePeriod = useAppStateStore(state => state.selectedDatePeriod.pendingDatePeriod)
  const setLastUpdated = useAppStateStore(state => state.selectedDatePeriod.setLastUpdated)
  const openRailwayId = useAppStateStore(state => state.locations.openRailwayId)
  const pendingStretchLocationIds = useAppStateStore(state => state.locations.pendingStretchLocationIds)

  const [, setOpenRailwayId] = useQueryStringState(QueryStringStateKeys.railway)
  const [, setSelectedStretchLocations] = useQueryStringState(QueryStringStateKeys.stretchLocations)
  const [, setSelectedDatePeriod] = useQueryStringState(QueryStringStateKeys.datePeriod)

  const pendingDateRange = pendingDatePeriod ? getPendingDateRange(pendingDatePeriod) : undefined
  const isValidDatePeriod = pendingDateRange && pendingDateRange.fromDate < pendingDateRange.toDate
  const searchDisabled = pendingStretchLocationIds.length === 0 || !isValidDatePeriod

  const triggerSearch = () => {
    if (isValidDatePeriod && pendingDatePeriod) {
      const selectedDatePeriod = pendingDatePeriod as DatePeriod

      setLastUpdated(Date.now())
      setSelectedDatePeriod(selectedDatePeriod)
      setOpenRailwayId(openRailwayId)
      setSelectedStretchLocations(pendingStretchLocationIds)

      const firstLocationIndex = pendingStretchLocationIds.findIndex(sl => locations.find(l => sl === l.locationId))
      const weatherLocationToSelect =
        selectedWeatherLocationId && pendingStretchLocationIds.some(loc => loc === selectedWeatherLocationId)
          ? selectedWeatherLocationId
          : pendingStretchLocationIds[firstLocationIndex]
      setSelectedWeatherLocationId(weatherLocationToSelect)
    }
  }

  return { searchDisabled, triggerSearch }
}
