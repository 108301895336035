import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/ClearOutlined'
import FormControl from '@mui/material/FormControl'
import { styled } from '@mui/material/styles'
import OutlinedInput from '@mui/material/OutlinedInput'
import SearchIcon from '@mui/icons-material/SearchOutlined'
import { useTranslation } from 'react-i18next'

const StyledOutlinedInput = styled(OutlinedInput)`
  border-radius: 0;
  min-width: 290px;
`

const StyledSearchIcon = styled(SearchIcon)`
  vertical-align: middle;
  margin-left: 8px;
`

type OwnProps = {
  locationSearchFilter: string
  setLocationSearchFilter: (searchFilter: string) => void
}

export const LocationSearchInput = ({ locationSearchFilter, setLocationSearchFilter }: OwnProps) => {
  const { t } = useTranslation()

  return (
    <FormControl sx={{ m: 1, width: '95%' }} variant="outlined">
      <InputLabel htmlFor="search">{t('general.search')}</InputLabel>
      <StyledOutlinedInput
        id="search"
        value={locationSearchFilter}
        onChange={event => setLocationSearchFilter(event.target.value)}
        endAdornment={
          locationSearchFilter.length ? (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setLocationSearchFilter('')}
                size="small"
                color="primary"
                title={t('location.clear')}
                aria-label={t('location.clear')}
              >
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ) : (
            <StyledSearchIcon />
          )
        }
        label={`${t('general.search')} ${t('location.stretchLocation.title')}`}
      />
    </FormControl>
  )
}
