import { useTranslation } from 'react-i18next'

import { IconWithText } from 'src/Components/IconWithText'
import { NoAlarmIndicator } from 'src/Components/NoAlarmIndicator'
import { NotMonitoredIcon } from './NotMonitoredIcon'
import { NoContactIcon } from 'src/Components/AssetCard/NoContactIcon'
import { AssetCardAlarmIcon } from './AssetCardAlarmIcon'

import type { Asset } from 'src/Types/AssetTypes'
import type { AlarmClassification } from 'src/Types/AlarmClassification'
import type { SystemStatus } from 'src/Types/SystemStatus'
import { UnknownIcon } from 'src/Components/AssetCard/UnknownIcon'
import { IrregularDataIcon } from 'src/Components/AssetCard/IrregularDataIcon'

type OwnProps = {
  asset: Asset
  cardStatus: SystemStatus
}

export const AssetCardMainIcon = ({ asset, cardStatus }: OwnProps) => {
  const { t } = useTranslation()
  const totalCriticality = asset.totalCriticalityAbsolute

  if (cardStatus === 'NotMonitored') {
    return <NotMonitoredIcon />
  }

  if (cardStatus === 'NoContact') {
    return <NoContactIcon />
  }

  if (cardStatus === 'IrregularData') {
    return <IrregularDataIcon />
  }

  if (cardStatus === 'Unknown') {
    return <UnknownIcon />
  }

  if (['Alarm', 'Warning'].includes(cardStatus)) {
    if (totalCriticality && asset.showCriticality) {
      return <AssetCardAlarmIcon classification={cardStatus as AlarmClassification} criticalityValue={totalCriticality} />
    }
    return <AssetCardAlarmIcon classification={cardStatus as AlarmClassification} />
  }

  if (cardStatus === 'Ok') {
    return (
      <IconWithText
        aria-label={t('switches.statusLabels.alarms.noAlarms')}
        title={t('switches.statusLabels.alarms.noAlarms')}
        icon={<NoAlarmIndicator indicator="Ok" size="large" />}
        text=""
        gap={0.75}
        isUpperCase
      />
    )
  }

  if (cardStatus === 'Pending') {
    return (
      <IconWithText
        aria-label={t('switches.statusLabels.alarms.pendingAlarms')}
        title={t('switches.statusLabels.alarms.pendingAlarms')}
        icon={<NoAlarmIndicator indicator="Pending" size="large" />}
        text=""
        gap={0.75}
        isUpperCase
      />
    )
  }

  return null
}
