import type { ReactNode } from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ConfirmDialog } from 'src/Components/ConfirmDialog'

import { isLatestVersion } from 'src/Utils/version'
import { getCacheBusterPageReloadTime, setCacheBusterPageReloadTime } from 'src/Providers/SessionSettings'
import config from 'src/Config/Config'

const VERSION_CHECK_INTERVAL = 60 * 60 * 1000 // 1 hour
const ALERT_BEFORE_RELOAD = true

const reloadedWithinLastMinute = () => {
  const cacheBusterPageReloadTime = getCacheBusterPageReloadTime()
  if (!cacheBusterPageReloadTime) {
    return false
  }
  const duration = Date.now() - cacheBusterPageReloadTime
  return duration < 60000
}

const reload = async () => {
  if (window.caches) {
    const keys = await window.caches.keys()
    await Promise.all(keys.map(key => window.caches.delete(key)))
  }
  setCacheBusterPageReloadTime(Date.now())
  window.location.reload()
}

let checkInterval: number

export const CacheBuster = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation()

  const [confirmReload, setConfirmReload] = useState(false)

  const handleConfirmation = (shouldReload: boolean) => {
    if (shouldReload) {
      reload()
    } else {
      setConfirmReload(false)
    }
  }

  const checkVersion = async (initial: boolean) => {
    const isLatest = await isLatestVersion()

    if (!isLatest) {
      clearInterval(checkInterval)
      if (initial || !ALERT_BEFORE_RELOAD) {
        if (!reloadedWithinLastMinute()) {
          reload()
        }
      } else {
        setConfirmReload(true)
      }
    }
  }

  useEffect(() => {
    if (!config.isLocal) {
      checkVersion(true)

      checkInterval = window.setInterval(() => checkVersion(false), VERSION_CHECK_INTERVAL)
    }
  }, [])

  return (
    <>
      {confirmReload && (
        <ConfirmDialog
          title={t('versionMismatch.confirmTitle')}
          text={t('versionMismatch.confirmText')}
          onConfirm={handleConfirmation}
          type="confirm"
        />
      )}

      {children}
    </>
  )
}
