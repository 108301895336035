import { getSensorDataForBender } from 'src/Providers/Benders'
import { useQueryWithErrorHandling } from './useQueryWithErrorHandling'

export const useBenderSensorData = (benderBaneDataId: string) => {
  const query = useQueryWithErrorHandling(
    ['benderSensorData', { id: benderBaneDataId }],
    () => getSensorDataForBender(benderBaneDataId),
    {},
    'benders.sensorInfo.fetchError'
  )

  return {
    ...query,
    benderSensorData: query.data,
  }
}
