import { useTranslation } from 'react-i18next'

import { FilterDropdown } from 'src/Components/SidePanelMenu/FilterDropdown'

import type { Filter, SystemFilter } from 'src/Types/Filter'
import { FilterType } from 'src/Types/Filter'

type OwnProps = {
  systemOptions: string[]
  filter?: SystemFilter
  onFilterUpdated: (filter: Filter) => void
}

export const SystemFilterSelector = ({ systemOptions, onFilterUpdated, filter }: OwnProps) => {
  const { t } = useTranslation()
  const systemLabels = systemOptions.map(system => t(`sideMenu.system.${system.toLowerCase()}`))

  const selectedSystems = systemOptions.filter(a => filter?.values.includes(a)) || []
  const systemsUpdated = (values: string[]) =>
    onFilterUpdated({
      type: FilterType.System,
      values,
    })

  return (
    <FilterDropdown
      id="system-filter"
      label={t('sideMenu.filterLabel.system')}
      selectedValues={selectedSystems}
      options={systemOptions}
      optionLabels={systemLabels}
      onUpdate={systemsUpdated}
    />
  )
}
