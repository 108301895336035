import { useEffect } from 'react'

import { useAppStateStore } from 'src/Store/appState'
import { useAlarmTypesLoader } from './NetworkData/useAlarmTypesLoader'
import { useSensorCodesLoader } from './NetworkData/useSensorCodesLoader'
import { usePointMachineZoomLevelsLoader } from './NetworkData/usePointMachineZoomLevelsLoader'
import { getLeastAdvancedQueryStatus } from 'src/Utils/network'
import { useDomainConstantsStore } from 'src/Store/domainConstants'

export const useDomainConstantsLoader = () => {
  const queueMessage = useAppStateStore(state => state.appMessages.queueMessage)

  const alarmTypes = useDomainConstantsStore(state => state.alarmTypes.alarms)
  const pointMachineZoomLevels = useDomainConstantsStore(state => state.pointMachineZoomLevels.pointMachineZoomLevels)
  const sensorCodes = useDomainConstantsStore(state => state.sensorCodes.sensorCodes)

  const { status: alarmTypesStatus } = useAlarmTypesLoader()
  const { status: pointMachineZoomLevelsStatus } = usePointMachineZoomLevelsLoader()
  const { status: heatSensorCodesStatus } = useSensorCodesLoader('SVV')
  const { status: benderCodesStatus } = useSensorCodesLoader('SEF')

  // Remove old cache key
  useEffect(() => {
    const DEPRECATED_CACHE_KEY = 'trackMonitoring.domainConstantsCache'
    localStorage.removeItem(DEPRECATED_CACHE_KEY)
  }, [])

  const queryStatus = getLeastAdvancedQueryStatus([
    alarmTypesStatus,
    pointMachineZoomLevelsStatus,
    heatSensorCodesStatus,
    benderCodesStatus,
  ])

  const domainConstantsLoaded = [
    alarmTypes.length,
    pointMachineZoomLevels.length,
    sensorCodes.SEF.length,
    sensorCodes.SVV.length,
  ].every(Boolean)

  useEffect(() => {
    if (queryStatus === 'error') {
      if (domainConstantsLoaded) {
        queueMessage({
          type: 'error',
          message: 'general.domainConstantsFetchErrorCached',
        })
      } else {
        queueMessage({
          type: 'error',
          message: 'general.domainConstantsFetchErrorNotCached',
        })
      }
    }
  }, [queryStatus, queueMessage, domainConstantsLoaded])

  return {
    domainConstantsLoaded,
    status: queryStatus,
  }
}
