import { StrictMode } from 'react'

import { createRoot } from 'react-dom/client'

import { Root } from './Root'

import './Language/i18n'

import './root.css'

import { initializeTelemetryService } from 'src/telemetryService'
import { initTelemetryMetrics } from 'src/Tracking/initTelemetryMetrics'

window.setTimeout(() => {
  initializeTelemetryService()
  initTelemetryMetrics()
})

const container = document.getElementById('root')!
const root = createRoot(container)

root.render(
  <StrictMode>
    <Root />
  </StrictMode>
)
