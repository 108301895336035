import { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box, CircularProgress, Typography } from '@mui/material'

import type { WorkOrder, WorkOrderFilter, WorkOrderCount } from 'src/Types/WorkOrderTypes'
import { WorkOrderFilterType } from 'src/Types/WorkOrderTypes'

import { HeaderWithExpandableInfo } from 'src/Components/HeaderWithExpandableInfo'
import { filterWorkOrders } from 'src/Features/WorkOrders/WorkOrderFilters/filterWorkOrders'
import { WorkOrdersTable } from './WorkOrdersTable/WorkOrdersTable'
import { WorkOrderFilters } from './WorkOrderFilters/WorkOrderFilters'

import { Alert } from 'src/Components/Alert'
import { useNonCompletedWorkOrders, useCompletedWorkOrders } from 'src/Hooks/NetworkData/useWorkOrders'
import { useLanguage } from 'src/Hooks/useLanguage'

import themeColors from 'src/theme'

const StyledContent = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 1rem;
`

const StyledTypography = styled(Typography)`
  font-size: 1rem;
  font-weight: 700;
`

const NoWorkOrdersTypography = styled(Typography)`
  color: ${themeColors.disabledText};
`

type OwnProps = {
  banedataId: string
}

const getWorkOrderCounts = (workOrders: WorkOrder[], key: WorkOrderFilterType): WorkOrderCount[] => {
  return [...new Set(workOrders.map(wo => wo[key]!).filter(d => !!d))].map(value => ({
    id: `${value}`,
    count: workOrders.filter(wo => wo[key] === value).length,
  }))
}

const getAllCounts = (workOrders: WorkOrder[]): Record<WorkOrderFilterType, WorkOrderCount[]> => ({
  [WorkOrderFilterType.Status]: getWorkOrderCounts(workOrders, WorkOrderFilterType.Status),
  [WorkOrderFilterType.WorkType]: getWorkOrderCounts(workOrders, WorkOrderFilterType.WorkType),
  [WorkOrderFilterType.Discipline]: getWorkOrderCounts(workOrders, WorkOrderFilterType.Discipline),
})

export const WorkOrders = ({ banedataId }: OwnProps) => {
  const { t } = useLanguage()
  const [nonCompletedWorkOrdersFilters, setNonCompletedWorkOrdersFilters] = useState<WorkOrderFilter[]>([])
  const [completedWorkOrdersFilters, setCompletedWorkOrdersFilters] = useState<WorkOrderFilter[]>([])

  const { workOrders: nonCompletedWorkOrders, status: statusNonCompletedWO } = useNonCompletedWorkOrders(banedataId)
  const { workOrders: completedWorkOrders, status: statusCompletedWO } = useCompletedWorkOrders(banedataId)

  const [filteredNonCompletedWorkOrders, setFilteredNonCompletedWorkOrders] = useState<WorkOrder[]>([])
  const [filteredCompletedWorkOrders, setFilteredCompletedWorkOrders] = useState<WorkOrder[]>([])

  useEffect(() => {
    if (nonCompletedWorkOrders) {
      setFilteredNonCompletedWorkOrders(nonCompletedWorkOrders)
    }
  }, [nonCompletedWorkOrders])

  useEffect(() => {
    if (completedWorkOrders) {
      setFilteredCompletedWorkOrders(completedWorkOrders)
    }
  }, [completedWorkOrders])

  if (statusNonCompletedWO === 'idle' || statusCompletedWO === 'idle') {
    return null
  }

  if (statusNonCompletedWO === 'loading' || statusCompletedWO === 'loading') {
    return (
      <Box mt={2}>
        <CircularProgress color="inherit" size={15} /> {t('workOrders.loading')}
      </Box>
    )
  }

  if (statusNonCompletedWO === 'error' || statusCompletedWO === 'error') {
    return (
      <Box mt={2}>
        <Alert severity="error">{t('workOrders.fetchError')}</Alert>
      </Box>
    )
  }

  const completedWorkOrderFiltersUpdated = (filters: WorkOrderFilter[]) => {
    setCompletedWorkOrdersFilters(filters)
    if (completedWorkOrders) {
      const filtered = filterWorkOrders(completedWorkOrders, filters)
      setFilteredCompletedWorkOrders(filtered)
    }
  }

  const nonCompleteWorkOrderFiltersUpdated = (filters: WorkOrderFilter[]) => {
    setNonCompletedWorkOrdersFilters(filters)
    if (nonCompletedWorkOrders) {
      const filtered = filterWorkOrders(nonCompletedWorkOrders, filters)
      setFilteredNonCompletedWorkOrders(filtered)
    }
  }

  const renderNonCompletedWorkOrders = () => {
    if (!nonCompletedWorkOrders?.length) {
      return <NoWorkOrdersTypography>{t('workOrders.noWorkOrders')}</NoWorkOrdersTypography>
    }

    return (
      <>
        <WorkOrderFilters
          filters={nonCompletedWorkOrdersFilters}
          counts={getAllCounts(nonCompletedWorkOrders)}
          onFiltersUpdated={nonCompleteWorkOrderFiltersUpdated}
        />
        <WorkOrdersTable workOrders={filteredNonCompletedWorkOrders} label="new-work-orders-table" />
      </>
    )
  }

  const renderCompletedWorkOrders = () => {
    if (!completedWorkOrders?.length) {
      return <NoWorkOrdersTypography>{t('workOrders.noWorkOrders')}</NoWorkOrdersTypography>
    }

    return (
      <>
        <WorkOrderFilters
          filters={completedWorkOrdersFilters}
          counts={getAllCounts(completedWorkOrders)}
          onFiltersUpdated={completedWorkOrderFiltersUpdated}
          completed
        />
        <WorkOrdersTable workOrders={filteredCompletedWorkOrders} label="started-work-orders-table" isComplete />
      </>
    )
  }

  return (
    <Box mt={2}>
      <HeaderWithExpandableInfo title={t('workOrders.title')}>
        <StyledContent>
          <div>
            <StyledTypography>{t('workOrders.notCompleted')}</StyledTypography>
            {renderNonCompletedWorkOrders()}
          </div>
          <div>
            <StyledTypography>{t('workOrders.completed')}</StyledTypography>
            {renderCompletedWorkOrders()}
          </div>
        </StyledContent>
      </HeaderWithExpandableInfo>
    </Box>
  )
}
