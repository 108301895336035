import { useState, useCallback } from 'react'
import { useUserProfileStore } from 'src/Store/userProfile'
import type { SubHeaderKey } from 'src/Providers/Settings/SubHeader'

type OwnParams = {
  subHeaderKey?: SubHeaderKey
  isDefaultExpanded: boolean
  forcedClosed?: boolean
}

export const useSubHeaderExpandedState = ({ subHeaderKey, isDefaultExpanded, forcedClosed }: OwnParams) => {
  const expandedState = useUserProfileStore(state => state.subHeader.defaultExpanded)
  const setDefaultExpanded = useUserProfileStore(state => state.subHeader.setDefaultExpanded)
  const personalizedDefaultExpanded = subHeaderKey ? expandedState[subHeaderKey] : undefined

  const [expanded, setExpanded] = useState(() => {
    if (forcedClosed) {
      return false
    }
    return typeof personalizedDefaultExpanded === 'boolean' ? personalizedDefaultExpanded : isDefaultExpanded
  })

  const saveDefaultExpanded = useCallback(
    (expanded: boolean | undefined) => {
      if (subHeaderKey) {
        setDefaultExpanded(subHeaderKey, expanded)
      }
    },
    [setDefaultExpanded, subHeaderKey]
  )

  return { personalizedDefaultExpanded, saveDefaultExpanded, expanded, setExpanded }
}
