import { styled } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import { AlarmIndicator } from 'src/Components/AlarmIndicator'
import { SwitchHeatTemperatureInfo } from './SwitchHeatTemperatureInfo'
import { CardInformationEntry } from 'src/Components/AssetCard/CardInformationEntry'
import { SwitchHeatChart } from './SwitchHeatChart/SwitchHeatChart'
import { TemperatureAdjustment } from 'src/Features/SwitchHeat/Adjustment/TemperatureAdjustment'
import { SubHeaderWithExpandableInfo } from 'src/Components/SubHeaderWithExpandableInfo'
import { NoResult } from 'src/Components/NoResult'
import { AlarmCountChip } from 'src/Components/AlarmCountChip'

import { SwitchHeatGroupInfo } from 'src/Features/SwitchHeat/SwitchHeatGroupInfo'

import type { AssetWithAlarms } from 'src/Types/AssetWithAlarms'
import type { DatePeriod } from 'src/Types/DatePeriod'
import type { SwitchHeatCabinet } from 'src/Types/SwitchHeatCabinet'

import { partition } from 'src/Utils/array'

import { useDomainConstantsStore } from 'src/Store/domainConstants'
import { SubHeaderKey } from 'src/Providers/Settings/SubHeader'

import { useAccess } from 'src/Hooks/useAccess'
import { useBreakpointDown } from 'src/Hooks/useBreakpoint'
import { useSwitchHeatSensorData } from 'src/Hooks/NetworkData/useSwitchHeatSensorData'
import { useLanguage } from 'src/Hooks/useLanguage'
import { useSwitchHeatVersion } from 'src/Hooks/useSwitchHeatVersion'

const StyledTitleSpan = styled('span')`
  vertical-align: super;
  margin-right: 8px;
`

type OwnProps = {
  assetWithAlarms: AssetWithAlarms
  datePeriod: DatePeriod
}

export const SwitchHeatCardExpandedContentV1 = ({ assetWithAlarms, datePeriod }: OwnProps) => {
  const { t, currentLanguage } = useLanguage()
  const isMediaSM = useBreakpointDown('sm')
  const descriptionLength = isMediaSM ? 'short' : 'full'

  const sensorCodes = useDomainConstantsStore(state => state.sensorCodes.sensorCodes.SVV)
  const hasWriteAccess = useAccess('SwitchHeat.Write')

  const asset = assetWithAlarms.asset as SwitchHeatCabinet

  const version = useSwitchHeatVersion()
  const {
    switchHeatSensorData,
    status,
    refetch: refetchSwitchHeatSensorData,
  } = useSwitchHeatSensorData(asset.baneDataId, { version })

  if (status === 'idle') {
    return null
  }

  if (status === 'error') {
    return <NoResult>{t('switchHeat.sensorInfo.fetchError')}</NoResult>
  }

  if (status === 'loading') {
    return (
      <Box mt={2} ml={2}>
        <CircularProgress color="inherit" size={15} /> {t('switchHeat.sensorInfo.loading')}
      </Box>
    )
  }

  if (!switchHeatSensorData || !switchHeatSensorData.sensors.length) {
    return <NoResult>{t('switchHeat.sensorInfo.noneFound')}</NoResult>
  }

  const [alarmSensors, otherSensors] = partition(switchHeatSensorData.sensors, sensor =>
    sensorCodes.some(c => c.key === sensor.codeSystem && c.category.name === 'Alarm')
  )

  const getSensorLabel = (codeSystem: string) => {
    const sensorCode = sensorCodes.find(sc => sc.key === codeSystem)
    return sensorCode && sensorCode.localization[descriptionLength][currentLanguage]
  }

  const numSensorAlarms = alarmSensors.filter(tsa => tsa.currentValue === true).length

  const getAlarmIndicator = (alarm: boolean) => {
    const title = alarm ? t('general.alarm') : t('general.ok')
    return (
      <>
        <StyledTitleSpan>{title}</StyledTitleSpan>
        <AlarmIndicator alarmIndicator={alarm ? 'Alarm' : 'Ok'} size="small" title={title} />
      </>
    )
  }

  const temperatureAdjusted = () => {
    refetchSwitchHeatSensorData()
  }

  const setpointTrackSensor = switchHeatSensorData.sensors.find(s => s.codeSystem === 'SetpointTrack')
  const setpointSwitchRodValue = switchHeatSensorData.sensors.find(s => s.codeSystem === 'SetpointSwitchRod')?.currentValue

  return (
    <>
      <SwitchHeatGroupInfo assetWithAlarms={assetWithAlarms} />
      <SwitchHeatTemperatureInfo switchHeatCabinet={asset} sensors={otherSensors} />
      <SubHeaderWithExpandableInfo
        title={t('switchHeat.chart.title')}
        isDefaultExpanded
        subHeaderKey={SubHeaderKey.SwitchHeatChart}
      >
        <SwitchHeatChart
          switchHeatCabinet={asset}
          datePeriod={datePeriod}
          hasSetpointSwitchRodValue={typeof setpointSwitchRodValue === 'number'}
        />
      </SubHeaderWithExpandableInfo>
      <SubHeaderWithExpandableInfo
        title={
          <>
            {t('switchHeat.status')} <AlarmCountChip alarmCount={numSensorAlarms} />
          </>
        }
        hideText={`${t('alarms.hide', {
          classification: '',
        })} ${t('switchHeat.status').toLowerCase()}`}
        subHeaderKey={SubHeaderKey.SwitchHeatStatus}
      >
        <Grid container>
          {alarmSensors.map(alarm => (
            <Grid item xs={12} md={6} lg={4} key={alarm.id}>
              <CardInformationEntry
                label={getSensorLabel(alarm.codeSystem)}
                value={
                  typeof alarm.currentValue === 'boolean'
                    ? getAlarmIndicator(alarm.currentValue)
                    : t('switchHeat.sensorInfo.unknown')
                }
              />
            </Grid>
          ))}
        </Grid>
      </SubHeaderWithExpandableInfo>
      {hasWriteAccess && (
        <SubHeaderWithExpandableInfo title={t('switchHeat.heatManagement.header')} isDefaultExpanded={false}>
          {setpointTrackSensor ? (
            <TemperatureAdjustment sensor={setpointTrackSensor} onTemperatureUpdated={temperatureAdjusted} />
          ) : (
            <NoResult>{t('switchHeat.heatManagement.sensorNotFound')}</NoResult>
          )}
        </SubHeaderWithExpandableInfo>
      )}
    </>
  )
}
