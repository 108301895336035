import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreOutlined'
import ExpandLessIcon from '@mui/icons-material/ExpandLessOutlined'

import { Checkbox } from 'src/Components/Checkbox'
import { StretchLocationsSelector } from './StretchLocationsSelector'

import type { RailwayModel } from './types'

import { StyledLocationListItemButton, StyledLocationTitle } from './SelectorComponents'
import { useLocationSelectorToggles } from 'src/Components/Header/Locations/useLocationSelectorToggles'

const ALL_LOCATIONS_SELECTED = 'ALL_LOCATIONS_SELECTED'

type OwnProps = {
  railway: RailwayModel
}
export const RailwaySectionSelector = ({ railway }: OwnProps) => {
  const { t } = useTranslation()

  const { railwaySections } = railway
  const { toggleRailway } = useLocationSelectorToggles()

  const [expandedLocations, setExpandedLocations] = useState(true)

  const filteredRailwaySections = railwaySections.filter(railwaySection => railwaySection.searchTreeMatch)

  return (
    <>
      <Box mt={1}>
        <Divider />
      </Box>
      <StyledLocationTitle onClick={() => setExpandedLocations(!expandedLocations)}>
        <ListItemText primary={t('location.stretchLocation.title')} />
        {expandedLocations ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </StyledLocationTitle>
      {railwaySections && (
        <Collapse in={expandedLocations} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <>
              <StyledLocationListItemButton
                isSelected={railway.isAllPending}
                onClick={() => toggleRailway(railway)}
                key={ALL_LOCATIONS_SELECTED}
              >
                <b>{t('general.selectAll')}</b>
                <ListItemSecondaryAction>
                  <Checkbox
                    edge="end"
                    onChange={() => toggleRailway(railway)}
                    checked={railway.isAllPending}
                    inputProps={{ 'aria-labelledby': ALL_LOCATIONS_SELECTED }}
                  />
                </ListItemSecondaryAction>
              </StyledLocationListItemButton>
              <Divider />
            </>
            {filteredRailwaySections.map(railwaySection => (
              <StretchLocationsSelector
                railwaySection={railwaySection}
                stretchLocations={railwaySection.locations}
                key={railwaySection.railwaySectionId}
              />
            ))}
            {!filteredRailwaySections.length && (
              <Box m={2}>
                <i>{t('general.noMatches')}</i>
              </Box>
            )}
          </List>
        </Collapse>
      )}
    </>
  )
}
