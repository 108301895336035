import type { ReactNode, RefObject, MouseEvent as ReactMouseEvent, ComponentProps } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { styled } from '@mui/material/styles'
import MUIButton from '@mui/material/Button'

import { ignoreStyleProps } from 'src/Utils/style'
import themeColors from 'src/theme'

type StyleProps = {
  isOutlined?: boolean
}

const StyledButton = styled(
  MUIButton,
  ignoreStyleProps('isOutlined')
)<StyleProps>(
  props => `
  border: 1px solid ${themeColors.secondary};
  border-radius: 25px;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
`
)

const StyledLink = styled(Link)`
  text-decoration: none;
`

type ButtonProps = ComponentProps<typeof StyledButton>

type OwnProps = {
  children: ReactNode
  href?: string
  buttonClass?: string
  variant?: 'contained' | 'outlined' | 'text'
  disabled?: boolean
  size?: 'small' | 'medium' | 'large'
  startIcon?: ReactNode
  endIcon?: ReactNode
  openInNewWindow?: boolean
  ref?: ((instance: HTMLButtonElement | null) => void) | RefObject<HTMLButtonElement> | null | undefined
  className?: string
  onClick?: (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void
} & ButtonProps

/* eslint-disable react/jsx-props-no-spreading */
export const Button = ({
  children,
  href,
  variant = 'contained',
  buttonClass,
  disabled = false,
  size,
  startIcon,
  endIcon,
  openInNewWindow = false,
  ref = null,
  className,
  onClick,
  ...rest
}: OwnProps) => {
  return (
    <StyledButton
      {...rest}
      isOutlined={variant === 'outlined'}
      onClick={onClick}
      className={clsx(buttonClass, className)}
      variant={variant}
      color="secondary"
      disabled={disabled}
      size={size}
      startIcon={startIcon}
      endIcon={endIcon}
      ref={ref}
    >
      {href ? (
        <StyledLink
          to={href}
          target={openInNewWindow ? '_blank' : undefined}
          rel={openInNewWindow ? 'noopener noreferrer' : undefined}
        >
          {children}
        </StyledLink>
      ) : (
        children
      )}
    </StyledButton>
  )
}
