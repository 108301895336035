import TableCell from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'

export const TableCellPeriod = styled(TableCell)(
  props => `
  min-width: 150px;
  ${props.theme.breakpoints.down('sm')} {
    min-width: 140px;
  }
`
)
