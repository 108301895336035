import { callApiPost } from 'src/msGraphClient'
import { ApiType } from 'src/Types/ApiType'
import config from 'src/Config/Config'

const { REFERENCE_CURVE } = config.API_FRAGMENTS.apim

type SaveReferenceCurveResponse = {
  baneDataId: string
  date: number
  direction: string
  entityId: string
  machineSwingId: string
  measurements: { timeMs: number; measurement: number }[]
}

export const saveReferenceCurve = async (baneDataId: string, machineSwingId: string) => {
  const result = await callApiPost<SaveReferenceCurveResponse>(
    `${REFERENCE_CURVE}/${baneDataId}`,
    ApiType.APIM,
    JSON.stringify({ machineSwingId })
  )
  return result
}
