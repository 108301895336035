import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'

import type { Weather } from 'src/Types/WeatherTypes'

import { formatNumber } from 'src/Utils/format'
import { ignoreStyleProps } from 'src/Utils/style'
import themeColors from 'src/theme'

const StyledContainer = styled(Grid)`
  margin-bottom: 4px;
`

const StyledGridWithBorder = styled(Grid)`
  border-right: 1px solid ${themeColors.info};
`

const StyledDegrees = styled('span', ignoreStyleProps('temperature', 'isToday'))<{ temperature: number; isToday: boolean }>`
  font-size: 16px;
  padding: 6px;

  ${props =>
    props.isToday
      ? `
    color:  ${props.temperature > 0 ? themeColors.weather.plusDegreesToday : themeColors.weather.minusDegreesToday};
    font-weight: bold;
  `
      : `
    color: ${props.temperature > 0 ? themeColors.weather.plusDegrees : themeColors.weather.minusDegrees};
  `}
`

type OwnProps = {
  isToday: boolean
  weather: Weather[]
}

export const Temperature = ({ isToday, weather }: OwnProps) => {
  const maxTemperature = Math.max(...weather.map(w => w.temperature))
  const minTemperature = Math.min(...weather.map(w => w.temperature))

  return (
    <StyledContainer container>
      <StyledGridWithBorder item xs={6}>
        <StyledDegrees temperature={minTemperature} isToday={isToday}>
          {formatNumber(minTemperature)}°
        </StyledDegrees>
      </StyledGridWithBorder>
      <Grid item xs={6}>
        <StyledDegrees temperature={maxTemperature} isToday={isToday}>
          {formatNumber(maxTemperature)}°
        </StyledDegrees>
      </Grid>
    </StyledContainer>
  )
}
