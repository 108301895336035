import { getDateRange } from 'src/Features/SelectedDatePeriod/predefinedDatePeriods'

import type { DatePeriod } from 'src/Types/DatePeriod'

import type { SwitchHeatApiVersions } from 'src/Providers/SwitchHeat'
import { getSwitchHeatSensorHistory } from 'src/Providers/SwitchHeat'
import { useAppStateStore } from 'src/Store/appState'
import { useQueryWithErrorHandling } from './useQueryWithErrorHandling'

type UseSwitchHeatSensorHistoryParams = {
  baneDataId: string
  codeSystemKeys: string[]
  datePeriod: DatePeriod
  version: SwitchHeatApiVersions
}

export const useSwitchHeatSensorHistory = ({
  baneDataId,
  codeSystemKeys,
  datePeriod,
  version,
}: UseSwitchHeatSensorHistoryParams) => {
  const lastUpdated = useAppStateStore(state => state.selectedDatePeriod.lastUpdated)

  const query = useQueryWithErrorHandling(
    ['switchHeatSensorHistory', { baneDataId, codeSystemKeys, datePeriod, lastUpdated, version }],
    () => {
      const { fromDate, toDate } = getDateRange(datePeriod)

      return getSwitchHeatSensorHistory({
        baneDataId,
        codeSystemKeys,
        fromDate,
        toDate,
        version,
      }).then(res => res.sort((a, b) => codeSystemKeys.indexOf(a.codeSystem) - codeSystemKeys.indexOf(b.codeSystem)))
    },
    {},
    'switchHeat.chart.fetchError'
  )

  return {
    ...query,
    sensorHistory: query.data,
  }
}
