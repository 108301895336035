import type { ReactNode } from 'react'
import { useState, useEffect } from 'react'
import type { AssetWithAlarms } from 'src/Types/AssetWithAlarms'
import { LazyLoadedAsset } from 'src/Features/Alarms/AlarmList/LazyLoadedAsset'
import { getCardIdByAsset } from 'src/Utils/cardId'

const DEBOUNCE_TIMEOUT = 50

type OwnProps = {
  assetsWithAlarms: AssetWithAlarms[]
  expandedCardIds: string[]
  renderItem: (item: AssetWithAlarms, index: number) => ReactNode
}

export const LazyLoadedAssets = ({ assetsWithAlarms, expandedCardIds, renderItem }: OwnProps) => {
  const [minHeight, setMinHeight] = useState<number>()
  const [scrollTop, setScrollTop] = useState(() => document.documentElement.scrollTop)
  const [innerHeight, setInnerHeight] = useState(() => window.innerHeight)
  const [innerWidth, setInnerWidth] = useState(() => window.innerWidth)

  useEffect(() => {
    let timeout: number | undefined
    const onScroll = () => {
      window.clearTimeout(timeout)
      timeout = window.setTimeout(() => setScrollTop(document.documentElement.scrollTop), DEBOUNCE_TIMEOUT)
    }
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
      window.clearTimeout(timeout)
    }
  }, [])

  useEffect(() => {
    let timeout: number | undefined
    const onResize = () => {
      window.clearTimeout(timeout)
      timeout = window.setTimeout(() => {
        setInnerHeight(window.innerHeight)
        setInnerWidth(window.innerWidth)
      }, DEBOUNCE_TIMEOUT)
    }
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
      window.clearTimeout(timeout)
    }
  }, [])

  const handleCalculatedItemHeight = (height: number) => {
    if (height > 0 && (typeof minHeight === 'undefined' || height < minHeight)) {
      setMinHeight(height)
    }
  }

  return (
    <>
      {assetsWithAlarms.map((assetWithAlarms, index) => {
        const cardId = getCardIdByAsset(assetWithAlarms.asset)
        return (
          <LazyLoadedAsset
            key={cardId}
            cardId={cardId}
            onCalculatedItemHeight={handleCalculatedItemHeight}
            minHeight={minHeight}
            scrollTop={scrollTop}
            innerHeight={innerHeight}
            innerWidth={innerWidth}
            count={assetsWithAlarms.length}
            index={index}
            expanded={expandedCardIds.includes(cardId)}
          >
            {renderItem(assetWithAlarms, index)}
          </LazyLoadedAsset>
        )
      })}
    </>
  )
}
