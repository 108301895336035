import type { ReactElement, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import type { SelectChangeEvent } from '@mui/material/Select'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'

import { Checkbox } from 'src/Components/Checkbox'

const StyledFormControl = styled(FormControl)(
  props => `
  margin: ${props.theme.spacing(1)};
`
)

const StyledListSubheader = styled(ListSubheader)`
  pointer-events: none;
`

type OwnProps<ValueType, GroupIdType> = {
  id: string
  label: string
  groups: {
    id: GroupIdType
    name: string
  }[]
  values: ValueType[]
  selectedValues: string[]
  idForValue: (value: ValueType) => string
  entriesForGroupId: (groupId: GroupIdType) => ReactNode[]
  onUpdate: (values: string[]) => void
  renderSelected?: (selected: string[], isEveryValueSelected: boolean) => ReactNode
  allowSelectAll?: boolean
  noValuesMessage?: string
}

const ALL_SELECTED = 'ALL_SELECTED'

export const GroupedDropdown: <T, U>(props: OwnProps<T, U>) => ReactElement<OwnProps<T, U>> = ({
  id,
  label,
  groups,
  values,
  selectedValues,
  idForValue,
  entriesForGroupId,
  renderSelected,
  onUpdate,
  allowSelectAll = true,
  noValuesMessage,
}) => {
  const { t } = useTranslation()

  const isEveryValueSelected = selectedValues.length >= values.length

  const valuesUpdated = (event: SelectChangeEvent<string[]>) => {
    let selectedValues = event.target.value as string[]
    if (selectedValues.includes(ALL_SELECTED)) {
      selectedValues = isEveryValueSelected ? [] : values.map(idForValue)
    }
    onUpdate(selectedValues)
  }

  return (
    <StyledFormControl fullWidth>
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        labelId={id}
        multiple
        value={selectedValues}
        onChange={valuesUpdated}
        renderValue={value => (renderSelected ? renderSelected(value as string[], isEveryValueSelected) : undefined)}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        {allowSelectAll && (
          <MenuItem key={ALL_SELECTED} value={ALL_SELECTED} title={t('general.selectAll')}>
            <Checkbox checked={isEveryValueSelected} />
            <ListItemText primary={t('general.selectAll')} />
          </MenuItem>
        )}
        {groups.map(group => [<StyledListSubheader>{group.name}</StyledListSubheader>, entriesForGroupId(group.id)])}
        {values.length === 0 && <MenuItem disabled>{noValuesMessage || t('general.noValues')}</MenuItem>}
      </Select>
    </StyledFormControl>
  )
}
