import type { SubHeaderDefaultExpanded } from 'src/Providers/Settings/SubHeader'
import type { UserSettings } from 'src/Types/UserSettings'

export const migrateToSubHeaderDefaultExpanded = (
  subHeaderDefaultExpanded: SubHeaderDefaultExpanded | undefined,
  userSettings: UserSettings
): SubHeaderDefaultExpanded => {
  if (subHeaderDefaultExpanded) {
    return subHeaderDefaultExpanded
  }

  const newState: SubHeaderDefaultExpanded = {}
  if ('alarmInformationDefaultExpanded' in userSettings) {
    newState.AlarmTable = userSettings.alarmInformationDefaultExpanded
    newState.WarningTable = userSettings.alarmInformationDefaultExpanded
  }
  if ('weatherChartDefaultExpanded' in userSettings) {
    newState.WeatherChart = userSettings.weatherChartDefaultExpanded
  }
  if ('fcChartOnAlarmsDefaultExpanded' in userSettings) {
    newState.SensorDataFeedCurrent = userSettings.fcChartOnAlarmsDefaultExpanded
  }
  if ('infoUnderChartsDefaultExpanded' in userSettings) {
    newState.TrackCircuitsInfo = userSettings.infoUnderChartsDefaultExpanded
    newState.SwingInfo = userSettings.infoUnderChartsDefaultExpanded
    newState.SwitchStatistics = userSettings.infoUnderChartsDefaultExpanded
    newState.PointMachinesInfo = userSettings.infoUnderChartsDefaultExpanded
  }
  return newState
}
