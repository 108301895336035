const TELEMETRY_REQUEST_TRACKING_DISABLED_KEY = 'trackMonitoring.telemetryRequestTrackingDisabled'
const CACHE_BUSTER_PAGE_RELOAD_TIME_KEY = 'trackMonitoring.cacheBusterPageReloadTime'
const REDIRECT_PATH_KEY = 'trackMonitoring.redirectPath'

export const getTelemetryRequestTrackingDisabled = () =>
  sessionStorage.getItem(TELEMETRY_REQUEST_TRACKING_DISABLED_KEY) === 'true'

export const setTelemetryRequestTrackingDisabled = (value: boolean = true) =>
  sessionStorage.setItem(TELEMETRY_REQUEST_TRACKING_DISABLED_KEY, `${value}`)

export const getCacheBusterPageReloadTime = () => {
  const storedValue = sessionStorage.getItem(CACHE_BUSTER_PAGE_RELOAD_TIME_KEY)
  const cacheBusterPageReloadTime = storedValue ? parseInt(storedValue, 10) : undefined
  return !Number.isNaN(cacheBusterPageReloadTime) ? cacheBusterPageReloadTime : undefined
}

export const setCacheBusterPageReloadTime = (value: number) =>
  sessionStorage.setItem(CACHE_BUSTER_PAGE_RELOAD_TIME_KEY, `${value}`)

/** Store the redirect path used in the callback page for the MSAL authentication flow. */
export const storeRedirectPath = (path: string) => sessionStorage.setItem(REDIRECT_PATH_KEY, `${path}`)
