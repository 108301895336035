import type { TFunction } from 'i18next'
import type { TemperatureParameter } from 'src/Features/SwitchHeat/Adjustment/TemperatureForm'
import type { SensorCode } from 'src/Types/SensorCode'
import type { SensorTwin } from 'src/Types/SensorTwin'
import type { Language } from 'src/Types/LanguageTypes'

export const mapToTemperatureParameters = (
  sensors: SensorTwin[],
  sensorCodes: SensorCode[],
  currentLanguage: Language,
  t: TFunction<string>
): TemperatureParameter[] => {
  const paramSensors = sensors
    .filter(sensor => sensor.requestValidation?.frontendControl)
    .sort((a, b) => a.name.localeCompare(b.name))
  const firstColRowCount = Math.ceil(paramSensors.length / 2)
  return paramSensors.map(({ id, codeSystem, currentValue, requestValidation, etag64, currentValueTime }, i) => {
    const sensorCode = sensorCodes.find(c => c.key === codeSystem)
    const value = typeof currentValue === 'number' ? currentValue : 0
    const param: TemperatureParameter = {
      sensorId: id!,
      column: i < firstColRowCount ? 1 : 2,
      label:
        sensorCode?.localization.full[currentLanguage] || t('switchHeat.temperatureForm.missingCodeSystemKey', { codeSystem }),
      value,
      metricDisplayName: sensorCode?.metric.displayName,
      minValue: requestValidation?.min,
      maxValue: requestValidation?.max,
      eTag: etag64,
      currentValueTime,
      codeSystem,
    }
    return param
  })
}
