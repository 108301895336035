import { useAppStateStore } from 'src/Store/appState'
import type { RailwayLocationModel, RailwaySectionModel, RailwayModel } from 'src/Components/Header/Locations/types'
import { useCallback } from 'react'

export const useLocationSelectorToggles = () => {
  const addPendingStretchLocationId = useAppStateStore(state => state.locations.addPendingStretchLocationId)
  const removePendingStretchLocationId = useAppStateStore(state => state.locations.removePendingStretchLocationId)

  const toggleLocation = useCallback(
    (location: RailwayLocationModel, toggle?: boolean) => {
      const shouldAdd = typeof toggle === 'boolean' ? toggle : !location.isPending
      if (shouldAdd) {
        addPendingStretchLocationId(location.locationId)
      } else {
        removePendingStretchLocationId(location.locationId)
      }
    },
    [addPendingStretchLocationId, removePendingStretchLocationId]
  )

  const toggleRailwaySection = useCallback(
    (railwaySection: RailwaySectionModel, toggle?: boolean) => {
      const locationIds = railwaySection.locations.map(l => l.locationId)
      const shouldAdd = typeof toggle === 'boolean' ? toggle : !railwaySection.isAllPending
      if (shouldAdd) {
        addPendingStretchLocationId(...locationIds)
      } else {
        removePendingStretchLocationId(...locationIds)
      }
    },
    [addPendingStretchLocationId, removePendingStretchLocationId]
  )

  const toggleRailway = useCallback(
    (railway: RailwayModel, toggle?: boolean) => {
      const locationIds = railway.railwaySections
        .flatMap(railwaySection => railwaySection.locations)
        .map(location => location.locationId)
      const shouldAdd = typeof toggle === 'boolean' ? toggle : !railway.isAllPending
      if (shouldAdd) {
        addPendingStretchLocationId(...locationIds)
      } else {
        removePendingStretchLocationId(...locationIds)
      }
    },
    [addPendingStretchLocationId, removePendingStretchLocationId]
  )

  return {
    toggleRailwaySection,
    toggleLocation,
    toggleRailway,
  }
}
