import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import ArrowDownIcon from '@mui/icons-material/ArrowDownwardOutlined'

import { BenderInfoPopper } from './BenderInfoPopper'

import type { Bender } from 'src/Types/Bender'
import type { PopperReferenceObject } from 'src/Types/PopperReferenceObject'

import themeColors from 'src/theme'

type HoveredBender = {
  element: PopperReferenceObject
  bender: Bender
}

const useStyles = makeStyles()(theme => ({
  chipContainer: {
    display: 'flex',
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
    },
    '& .MuiIconButton-root': {
      marginTop: -5,
    },
  },
  arrowDownIcon: {
    borderRadius: 25,
    padding: 5,
    color: '#fff',
    backgroundColor: themeColors.secondary,
    '&:disabled': {
      backgroundColor: themeColors.disabled,
      color: themeColors.disabledText,
    },
    '&:hover': {
      backgroundColor: themeColors.tertiary,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  dropdown: {
    width: '100%',
  },
  dropdownOption: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
}))

type OwnProps = {
  benders: Bender[]
  pendingBenderIds: string[]
  onSelectionUpdated: (selected: string[]) => void
}

export const BenderSelector = ({ benders, pendingBenderIds, onSelectionUpdated }: OwnProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const [hoveredBender, setHoveredBender] = useState<HoveredBender>()

  const removeBender = (baneDataId: string) => onSelectionUpdated(pendingBenderIds.filter(otherId => otherId !== baneDataId))

  const showBenderInfo = (baneDataId: string, element: PopperReferenceObject) => {
    const bender = benders.find(bender => bender.baneDataId === baneDataId)
    if (bender) {
      setHoveredBender({
        element,
        bender,
      })
    }
  }

  const hideBenderInfo = () => setHoveredBender(undefined)

  const toggleBenderInfo = (baneDataId: string, element: PopperReferenceObject) => {
    if (hoveredBender?.bender.baneDataId === baneDataId) {
      hideBenderInfo()
    } else {
      showBenderInfo(baneDataId, element)
    }
  }

  const renderTags = (selected: string[]) =>
    selected.map(baneDataId => {
      const bender = benders.find(bender => bender.baneDataId === baneDataId)

      if (!bender) {
        return <span key={baneDataId} />
      }

      return (
        <span key={baneDataId} className={classes.chipContainer}>
          <Chip
            label={bender.name}
            size="small"
            icon={
              <InfoIcon
                color="primary"
                onMouseEnter={event => {
                  showBenderInfo(bender.baneDataId, event.currentTarget)
                }}
                onMouseLeave={hideBenderInfo}
                onClick={event => {
                  event.stopPropagation()
                  toggleBenderInfo(bender.baneDataId, event.currentTarget)
                }}
              />
            }
            onDelete={() => removeBender(baneDataId)}
          />
        </span>
      )
    })

  const filterOptions = (options: string[], { inputValue }: { inputValue?: string }) => {
    if (options.length === 0 || inputValue) {
      return inputValue
        ? options.filter(baneDataId => {
            const bender = benders.find(bender => bender.baneDataId === baneDataId)
            return (
              !bender ||
              bender.name.toLowerCase().includes(inputValue.toLowerCase()) ||
              bender.baneDataLocationName.toLowerCase().includes(inputValue.toLowerCase())
            )
          })
        : options
    }
    return [t('general.selectAll'), ...options]
  }

  return (
    <>
      <Autocomplete
        id="benders-dropdown"
        className={classes.dropdown}
        multiple
        disableCloseOnSelect
        autoComplete
        autoHighlight
        options={benders.map(bender => bender.baneDataId)}
        filterOptions={filterOptions}
        groupBy={option => benders.find(bender => bender.baneDataId === option)?.baneDataLocationName || ''}
        value={pendingBenderIds}
        onChange={(event, values) => {
          const isSelectAll = values.some(baneDataId => baneDataId === t('general.selectAll'))
          if (isSelectAll) {
            if (benders.length === pendingBenderIds.length) {
              onSelectionUpdated([])
            } else {
              onSelectionUpdated(benders.map(bender => bender.baneDataId))
            }
          } else {
            onSelectionUpdated(values)
          }
        }}
        popupIcon={<ArrowDownIcon className={classes.arrowDownIcon} />}
        renderTags={renderTags}
        renderOption={(props, option, { selected }) => {
          const isSelectAll = option === t('general.selectAll')
          const bender = benders.find(t => t.baneDataId === option)
          const checked = selected || (isSelectAll && benders.length === pendingBenderIds.length)

          return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <li {...props}>
              <span className={classes.dropdownOption}>
                <Checkbox checked={checked} />
                {isSelectAll ? t('general.selectAll') : bender?.name}
              </span>
            </li>
          )
        }}
        renderInput={params => (
          <TextField
            {...params} // eslint-disable-line react/jsx-props-no-spreading
            placeholder={t('general.searchPlaceholder')}
            label={t('benders.bendersDropdown')}
            className={classes.textField}
            variant="outlined"
          />
        )}
      />
      {hoveredBender && <BenderInfoPopper bender={hoveredBender.bender} anchorElement={hoveredBender.element} />}
    </>
  )
}
