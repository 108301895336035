import { useState, useEffect } from 'react'
import { QueryStringStateKeys, useQueryStringState } from 'src/Store/useQueryStringState'
import { getHideCriticality } from 'src/Hooks/getHideCriticality'

export const useHideCriticality = () => {
  const [querySelectedDatePeriod] = useQueryStringState(QueryStringStateKeys.datePeriod)
  const [hideCriticality, setHideCriticality] = useState(getHideCriticality(querySelectedDatePeriod))

  useEffect(() => {
    setHideCriticality(getHideCriticality(querySelectedDatePeriod))
  }, [querySelectedDatePeriod])

  return hideCriticality
}
