import type { Asset } from 'src/Types/AssetTypes'

import { useUserProfileStore } from 'src/Store/userProfile'

export const useFavorite = (asset: Asset) => {
  const { baneDataId, assetType } = asset
  const favorites = useUserProfileStore(state => state.favorites.favorites)
  const addFavorite = useUserProfileStore(state => state.favorites.addFavorite)
  const removeFavorite = useUserProfileStore(state => state.favorites.removeFavorite)

  const isFavorite = favorites.some(fav => fav.assetType === assetType && fav.baneDataId === baneDataId)

  const onToggleFavorite = () => {
    const favorite = {
      assetType,
      baneDataId,
    }

    if (isFavorite) {
      removeFavorite(favorite)
    } else {
      addFavorite(favorite)
    }
  }

  return {
    isFavorite,
    toggleFavorite: onToggleFavorite,
  }
}
