import { useState, useEffect, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'

import { Button } from 'src/Components/Button'
import { TrackCircuitsStreamCard } from 'src/Features/TrackCircuits/StreamView/TrackCircuitsStreamCard'
import { TrackCircuitSelector } from 'src/Features/TrackCircuits/StreamView/TrackCircuitSelector'

import type { TrackCircuit } from 'src/Types/TrackCircuitTypes'

import { QueryStringStateKeys, useQueryStringState } from 'src/Store/useQueryStringState'
import { useTrackCircuits } from 'src/Hooks/NetworkData/useTrackCircuits'
import { useSelectedDateRange } from 'src/Hooks/useSelectedDateRange'
import { getTrackCircuitWithNeighbors } from 'src/Providers/TrackCircuit'
import { partition } from 'src/Utils/array'

import { useAppStateStore } from 'src/Store/appState'
import { StreamViewSelectorContainer } from 'src/Components/StreamView/StreamViewSelectorContainer'
import { AutoRefreshToggle } from 'src/Components/AutoRefreshToggle'
import { composeAutoRefreshToggleHelpText } from 'src/Utils/autoRefreshToggle'

const getTrackCircuits = async (tcBaneDataIds: string[], existing: TrackCircuit[]) => {
  const [foundTrackCircuits, missingTrackCircuits] = partition(tcBaneDataIds, tcId => existing.some(o => o.baneDataId === tcId))

  const toAdd = foundTrackCircuits.flatMap(tcId => {
    const foundTrackCircuits = existing.find(tc => tc.baneDataId === tcId)
    return foundTrackCircuits || []
  })

  if (missingTrackCircuits.length) {
    // TODO: Consider showing a spinner. This is currently very fast, but might not always be.
    const trackCircuits = await Promise.all(missingTrackCircuits.map(getTrackCircuitWithNeighbors))
    const filteredTrackCircuits = trackCircuits.filter(tc => !!tc)
    if (filteredTrackCircuits.length > 0) {
      toAdd.push(...filteredTrackCircuits)
    }
  }

  return toAdd
}

let updateTimer: number | undefined
const AUTO_UPDATE_INTERVAL_IN_SECONDS = 15 // 15 seconds

const useStyles = makeStyles()({
  fetchButton: {
    width: '100%',
  },
})

const sortTrackCircuits = (trackCircuits: TrackCircuit[]) =>
  trackCircuits.sort((a, b) => {
    if (a.baneDataLocationId === b.baneDataLocationId) {
      return a.m40TrackCircuit.tcId.localeCompare(b.m40TrackCircuit.tcId)
    }

    return a.baneDataLocationName.localeCompare(b.baneDataLocationName)
  })

export const StreamView = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const [selectedStretchLocations, setSelectedStretchLocations] = useQueryStringState(QueryStringStateKeys.stretchLocations)
  const [selectedBaneDataIds, setSelectedBaneDataIds] = useQueryStringState(QueryStringStateKeys.tcBaneDataIds)
  const [selectedDatePeriod] = useQueryStringState(QueryStringStateKeys.datePeriod)

  const pendingStretchLocationIds = useAppStateStore(state => state.locations.pendingStretchLocationIds)
  const setPendingStretchLocationIds = useAppStateStore(state => state.locations.setPendingStretchLocationIds)

  const [autoRefresh, setAutoRefresh] = useState(false)
  const [updateTimestamp, setUpdateTimestamp] = useState(() => Date.now())

  const [pendingTrackCircuitIds, setPendingTrackCircuitIds] = useState<string[]>(() => selectedBaneDataIds)
  const [additionalTrackCircuits, setAdditionalTrackCircuits] = useState<TrackCircuit[]>([])

  const selectedDateRange = useSelectedDateRange([updateTimestamp])

  const { trackCircuits = [], status } = useTrackCircuits(selectedStretchLocations)

  const updateChart = useCallback(() => setUpdateTimestamp(Date.now()), [])

  const allTrackCircuits = useMemo(
    () => [...trackCircuits, ...additionalTrackCircuits.filter(p => !trackCircuits.some(tc => p.baneDataId === tc.baneDataId))],
    [trackCircuits, additionalTrackCircuits]
  )

  useEffect(() => {
    const filteredAdditionalTCs = additionalTrackCircuits.filter(tc => selectedStretchLocations.includes(tc.baneDataLocationId))
    if (filteredAdditionalTCs.length !== additionalTrackCircuits.length) {
      setAdditionalTrackCircuits(filteredAdditionalTCs)

      const baneDataIds = filteredAdditionalTCs.map(tc => tc.baneDataId)
      if (baneDataIds.length) {
        setSelectedBaneDataIds(baneDataIds)
        setPendingTrackCircuitIds(baneDataIds)
      }
    }
  }, [selectedStretchLocations]) // eslint-disable-line react-hooks/exhaustive-deps

  const sortedTrackCircuits = useMemo(() => sortTrackCircuits(allTrackCircuits), [allTrackCircuits])

  const pendingSelectionUpdated = useCallback(
    (tcBaneDataIds: string[]) => {
      const unique = [...new Set(tcBaneDataIds)]
      const additionalToKeep = additionalTrackCircuits.filter(tc => unique.includes(tc.baneDataId))
      if (additionalToKeep.length !== additionalTrackCircuits.length) {
        setAdditionalTrackCircuits(additionalToKeep)
      }
      setPendingTrackCircuitIds(unique)
    },
    [additionalTrackCircuits]
  )

  const addTrackCircuits = useCallback(
    async (tcBaneDataIds: string[]) => {
      const newTCs = tcBaneDataIds.filter(tc => !additionalTrackCircuits.some(existing => existing.baneDataId === tc))
      const tcObjects = await getTrackCircuits(newTCs, allTrackCircuits)

      setAdditionalTrackCircuits([...additionalTrackCircuits, ...tcObjects])
      pendingSelectionUpdated([...pendingTrackCircuitIds, ...tcBaneDataIds])
    },
    [allTrackCircuits, additionalTrackCircuits, pendingSelectionUpdated, pendingTrackCircuitIds]
  )

  useEffect(() => {
    if (selectedBaneDataIds.length) {
      addTrackCircuits(selectedBaneDataIds)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const isAutoRefreshDisabled =
    selectedDatePeriod !== 'lastHour' || selectedBaneDataIds.length === 0 || selectedBaneDataIds.length > 4

  useEffect(() => {
    if (isAutoRefreshDisabled) {
      setAutoRefresh(false)
    }
  }, [isAutoRefreshDisabled])

  useEffect(() => {
    if (autoRefresh) {
      updateTimer = window.setInterval(() => {
        updateChart()
      }, AUTO_UPDATE_INTERVAL_IN_SECONDS * 1000)
    }

    return () => {
      clearInterval(updateTimer)
    }
  }, [updateChart, autoRefresh])

  const selectedTrackCircuits = useMemo(
    () =>
      sortTrackCircuits(selectedBaneDataIds.flatMap(baneDataId => trackCircuits.find(tc => tc.baneDataId === baneDataId) || [])),
    [trackCircuits, selectedBaneDataIds]
  )

  if (status === 'idle') {
    return null
  }

  const pendingChanged = additionalTrackCircuits.some(p => !selectedBaneDataIds.some(sel => sel === p.baneDataId))
  const fetchButtonText =
    selectedBaneDataIds.length === 0 || pendingChanged ? t('general.loadChartButton') : t('general.refreshChartButton')

  const handleFetchButtonClick = () => {
    if (selectedBaneDataIds.sort().toString() !== pendingTrackCircuitIds.sort().toString()) {
      setSelectedBaneDataIds(pendingTrackCircuitIds)

      // Add missing stretch locations
      const locationIds = allTrackCircuits
        .filter(tc => pendingTrackCircuitIds.includes(tc.baneDataId))
        .map(tc => tc.baneDataLocationId)
      const missingLocationIds = locationIds.filter(id => !pendingStretchLocationIds.includes(id))
      if (missingLocationIds) {
        const stretchLocationIds = [...new Set([...selectedStretchLocations, ...missingLocationIds])]
        setPendingStretchLocationIds(stretchLocationIds)
        setSelectedStretchLocations(stretchLocationIds)
      }
    } else {
      updateChart()
    }
  }

  const helpTextForAutoRefreshToggle = composeAutoRefreshToggleHelpText(
    'trackCircuits.autoRefreshInfo',
    AUTO_UPDATE_INTERVAL_IN_SECONDS,
    'general.seconds_plural',
    t
  )

  return (
    <>
      {sortedTrackCircuits && (
        <>
          <AutoRefreshToggle
            autoRefresh={autoRefresh}
            setAutoRefresh={setAutoRefresh}
            isAutoRefreshDisabled={isAutoRefreshDisabled}
            helpText={helpTextForAutoRefreshToggle}
          />
          <StreamViewSelectorContainer
            selector={
              <TrackCircuitSelector
                trackCircuits={sortedTrackCircuits}
                pendingTrackCircuitIds={pendingTrackCircuitIds}
                onSelectionUpdated={pendingSelectionUpdated}
                onAddTrackCircuits={addTrackCircuits}
                loading={status === 'loading'}
              />
            }
            button={
              <Button
                buttonClass={classes.fetchButton}
                onClick={handleFetchButtonClick}
                disabled={pendingTrackCircuitIds.length === 0}
              >
                {fetchButtonText}
              </Button>
            }
          />
        </>
      )}
      {selectedDateRange && (
        <TrackCircuitsStreamCard
          selectedFromDate={selectedDateRange.fromDate}
          selectedToDate={selectedDateRange.toDate}
          selectedTrackCircuits={selectedTrackCircuits}
          onAddTrackCircuits={addTrackCircuits}
        />
      )}
    </>
  )
}
