import { useEffect, useMemo, useRef, useState } from 'react'
import { format } from 'date-fns'
import { styled } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { SensorDataChartRenderer } from './sensorDataChartRenderer'
import { OccupiedTrackCircuits } from './OccupiedTrackCircuits'

import type { AggregatedTrackCircuitAlarm } from 'src/Types/AggregatedTrackCircuitAlarm'
import type { TrackCircuitCompressionRates, TrackCircuitCurrentType } from 'src/Types/TrackCircuitCurrentTypes'
import type { OccupiedTrackCircuit, TrackCircuit } from 'src/Types/TrackCircuitTypes'
import type { Point } from 'src/Types/Geometry'
import type { ChartFocus, ChartFocusUpdatedEventHandler } from 'src/Types/ChartTypes'
import type { TimeSpan } from 'src/Types/LevelOfDetails'

import { useUserProfileStore } from 'src/Store/userProfile'
import { useTrackCircuitAlarms } from 'src/Hooks/NetworkData/useTrackCircuitAlarms'
import { useChartStyles } from 'src/Components/Chart/chartStyles'
import { useAlarmTypeByAlarm } from 'src/Hooks/useAlarmTypeByAlarm'
import { getLeastAdvancedQueryStatus } from 'src/Utils/network'
import { getOccupiedTrackCircuits } from 'src/Providers/TrackCircuit'
import { useAppStateStore } from 'src/Store/appState'
import { useLanguage } from 'src/Hooks/useLanguage'
import { createPageLink } from 'src/Utils/pageLink'
import { useCompressedSensorData } from 'src/Hooks/NetworkData/SensorData/useCompressedSensorData'
import { CompressedChartDebugger } from 'src/Features/TrackCircuits/SensorDataChart/CompressedChartDebugger'
import { useTrackCircuitLegends } from 'src/Components/Chart/Legends/useTrackCircuitLegends'
import { ChartLegendsList } from 'src/Components/Chart/Legends/ChartLegendsList'
import { useFeatureOverride } from 'src/Hooks/useFeatureOverride'
import { SessionFeatures } from 'src/Hooks/useSessionFeature'
import { useChartResizeBehaviour } from 'src/Hooks/useChartResizeBehaviour'
import { LinkButton } from 'src/Components/LinkButton'
import { AppPage } from 'src/Types/AppPage'

type ContextMenuProps = {
  position: Point
  dateTime: number
}

type OccupationState =
  | {
      queryStatus: 'idle' | 'error'
    }
  | {
      queryStatus: 'loading' | 'success'
      position: Point
      dateTime: number
      occupiedTrackCircuits?: OccupiedTrackCircuit[]
    }

type OwnProps = {
  fromDate: number
  toDate: number
  startPadding?: number
  endPadding?: number
  baneDataIds: string[]
  selectedTrackCircuits?: TrackCircuit[]
  currentType: TrackCircuitCurrentType
  alarm?: AggregatedTrackCircuitAlarm
  hideLegend?: boolean
  showNavigationButton?: boolean
  isTrackCircuitsTab?: boolean
  zoomSync?: ChartFocus
  onZoomUpdated?: ChartFocusUpdatedEventHandler
  onAddOccupiedTrackCircuits?: (baneDataIds: string[]) => void
}

const StyledContainer = styled('div')(
  props => `
  margin: 8px 16px 16px;
  min-height: 50px;

  ${props.theme.breakpoints.down('md')} {
    margin: 8px 4px 4px;
  }
  ${props.theme.breakpoints.down('sm')} {
    margin: 8px 2px 2px;
  }
`
)

const StyledProgress = styled(Box)`
  position: absolute;
`

const StyledChartDiv = styled('div')`
  position: relative;
`

const StyledNoDataLegend = styled('div')`
  font-size: 14px;
  margin: 8px 0;
`

const OCCUPATION_DATE_FORMAT = 'dd.MM.yyyy HH:mm:ss'
const CONTEXT_MENU_DOUBLE_CLICK_THRESHOLD = 500

export const SensorDataChart = ({
  fromDate,
  toDate,
  startPadding = 0,
  endPadding = 0,
  baneDataIds,
  selectedTrackCircuits = [],
  currentType,
  alarm,
  hideLegend = false,
  showNavigationButton = false,
  isTrackCircuitsTab = false,
  zoomSync,
  onZoomUpdated,
  onAddOccupiedTrackCircuits,
}: OwnProps) => {
  const { t, currentLanguage } = useLanguage()
  const { classes: chartClasses } = useChartStyles()
  const queueMessage = useAppStateStore(state => state.appMessages.queueMessage)
  const hideChartZoomButtons = useUserProfileStore(state => state.settings.settings.hideChartZoomButtons)

  const chartRef = useRef<HTMLDivElement>(null)
  const [renderer, setRenderer] = useState<SensorDataChartRenderer>()
  const [inactiveTrackCircuits, setInactiveTrackCircuits] = useState<number[]>([])
  const [contextMenu, setContextMenu] = useState<ContextMenuProps>()
  const [occupationState, setOccupationState] = useState<OccupationState>({
    queryStatus: 'idle',
  })
  const [forcedCompressionRate, setForcedCompressionRate] = useState<TrackCircuitCompressionRates>()
  const alarmType = useAlarmTypeByAlarm(alarm)
  const zoomFromDate = zoomSync?.fromDate
  const zoomToDate = zoomSync?.toDate
  const lastDateRange = useRef<TimeSpan>()

  // Show debugger by adding features=lod-panel to querystring
  const showDebugger = useFeatureOverride(SessionFeatures.LodPanel)

  const inactiveTrackCircuitsUpdated = (inactive: number[]) => {
    setInactiveTrackCircuits(inactive)
    if (renderer) {
      renderer.setInactiveTrackCircuits(inactive)
    }
  }

  const {
    sensorData,
    status: currentsQueryStatus,
    usedCompressionRate,
    calculatedCompressionRate,
    cachedSections,
    requestedSections,
    viewFromDate,
    viewToDate,
  } = useCompressedSensorData({
    trackCircuitBaneDataIds: baneDataIds,
    fromDate,
    toDate,
    currentType,
    zoomFromDate,
    zoomToDate,
    forcedCompressionRate,
  })

  const { alarms, status: alarmsQueryStatus } = useTrackCircuitAlarms(alarm)

  useChartResizeBehaviour(renderer)

  const sortedSensorData = useMemo(
    () =>
      sensorData
        ? [...sensorData]
            .sort((a, b) => a.tcId.localeCompare(b.tcId))
            .sort((a, b) => a.baneDataLocationId.localeCompare(b.baneDataLocationId))
        : [],
    [sensorData]
  )

  useEffect(() => {
    if (renderer) {
      renderer.setData([], currentType, [], alarmType)
      renderer.resetZoom()
      renderer.render()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate])

  useEffect(() => {
    const chart = chartRef.current
    if (chart && sortedSensorData) {
      const labels = {
        zoomIn: t('general.zoomIn'),
        zoomOut: t('general.zoomOut'),
        resetZoom: t('general.resetZoom'),
        paddingInfo: t('trackCircuits.chart.paddingInfo'),
      }

      let chartRenderer = renderer!
      const lastFromDate = lastDateRange.current?.[0]
      const lastToDate = lastDateRange.current?.[1]
      const isNewDateRange = fromDate !== lastFromDate || toDate !== lastToDate
      if (!renderer || isNewDateRange) {
        chartRenderer = new SensorDataChartRenderer({
          chart,
          classes: {
            tooltip: chartClasses.tooltip,
            tooltipArrow: chartClasses.tooltipArrow,
            zoomButton: chartClasses.zoomButton,
          },
          padding: {
            start: startPadding,
            end: endPadding,
          },
          timeline: {
            start: fromDate,
            end: toDate,
          },
          onZoomUpdated,
          onChartClicked: onAddOccupiedTrackCircuits ? onChartClicked : undefined,
          doubleClickThreshold: CONTEXT_MENU_DOUBLE_CLICK_THRESHOLD,
        })
        setRenderer(chartRenderer)
        lastDateRange.current = [fromDate, toDate]

        setInactiveTrackCircuits([])
        chartRenderer.setInactiveTrackCircuits([])
      }

      chartRenderer.setTimeRange(fromDate, toDate)
      chartRenderer.setData(sortedSensorData, currentType, alarms, alarmType)
      chartRenderer.setLabels(labels, currentLanguage)
      chartRenderer.setShowZoomButtons(!hideChartZoomButtons)
      chartRenderer.render()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedSensorData, alarms, alarmType, currentLanguage, t])

  useEffect(() => {
    if (zoomSync && renderer) {
      renderer.setZoom(zoomSync.transform)
    }
  }, [zoomSync, renderer])

  const queryStatus = alarm ? getLeastAdvancedQueryStatus([currentsQueryStatus, alarmsQueryStatus]) : currentsQueryStatus

  const sensorHistoryLegends = useTrackCircuitLegends(
    sortedSensorData,
    inactiveTrackCircuits,
    currentType,
    selectedTrackCircuits,
    baneDataIds
  )
  const legendsWithoutData = sensorHistoryLegends.filter(legend => legend.isEmpty)

  if (queryStatus === 'idle' || queryStatus === 'error') {
    return null
  }

  const fetchOccupation = (dateTime: number) => {
    if (contextMenu) {
      setOccupationState({
        queryStatus: 'loading',
        position: contextMenu.position,
        dateTime,
      })
      setContextMenu(undefined)

      getOccupiedTrackCircuits(baneDataIds[0], dateTime)
        .then(occupiedTrackCircuits => {
          setOccupationState({
            queryStatus: 'success',
            position: contextMenu.position,
            dateTime,
            occupiedTrackCircuits,
          })
        })
        .catch(() => {
          setOccupationState({
            queryStatus: 'error',
          })

          queueMessage({
            type: 'error',
            message: 'trackCircuits.occupationFetchError',
          })
        })
    }
  }

  const onChartClicked = (dateTime: number, mousePosition: Point) => {
    if (onAddOccupiedTrackCircuits) {
      setContextMenu({
        position: mousePosition,
        dateTime,
      })
    }
  }

  const closeContextMenu = () => setContextMenu(undefined)

  const closeOccupationPopup = () =>
    setOccupationState({
      queryStatus: 'idle',
    })

  let loadingMessage = ''
  if (queryStatus === 'loading') {
    loadingMessage = alarm ? 'sensorData.loading.both' : 'sensorData.loading.currents'
    if (currentsQueryStatus === 'success') {
      loadingMessage = 'sensorData.loading.alarms'
    } else if (alarmsQueryStatus === 'success') {
      loadingMessage = 'sensorData.loading.currents'
    }
  }

  return (
    <>
      {showDebugger && (
        <CompressedChartDebugger
          fromDate={fromDate}
          toDate={toDate}
          usedCompressionRate={usedCompressionRate}
          calculatedCompressionRate={calculatedCompressionRate}
          viewFromDate={viewFromDate}
          viewToDate={viewToDate}
          sensorData={sortedSensorData}
          cachedSections={cachedSections || {}}
          requestedSections={requestedSections || {}}
          onCompressionRateChanged={setForcedCompressionRate}
        />
      )}

      {loadingMessage && (
        <StyledProgress m={2}>
          <CircularProgress color="inherit" size={15} /> {t(loadingMessage)}
        </StyledProgress>
      )}
      <StyledContainer>
        {showNavigationButton && queryStatus === 'success' && sortedSensorData?.[0] && (
          <Box textAlign="right">
            <LinkButton
              href={createPageLink(AppPage.TrackCircuitsStream, {
                tcBaneDataIds: sortedSensorData[0].trackCircuitBaneDataId || '',
              })}
            >
              {isTrackCircuitsTab
                ? t('general.openStreamView')
                : `${t('general.goTo', { value: t('assetName.trackCircuit').toLowerCase() })} ${sortedSensorData[0].tcId}`}
            </LinkButton>
          </Box>
        )}
        <StyledChartDiv ref={chartRef} />
        {contextMenu && (
          <Menu
            open
            onClose={closeContextMenu}
            anchorReference="anchorPosition"
            anchorPosition={{
              left: contextMenu.position.x,
              top: contextMenu.position.y,
            }}
          >
            <MenuItem onClick={() => fetchOccupation(contextMenu.dateTime)}>
              {t('trackCircuits.showOccupation', {
                dateTime: format(contextMenu.dateTime, OCCUPATION_DATE_FORMAT),
              })}
            </MenuItem>
          </Menu>
        )}
        {(occupationState.queryStatus === 'loading' || occupationState.queryStatus === 'success') && (
          <OccupiedTrackCircuits
            position={occupationState.position}
            dateTime={occupationState.dateTime}
            trackCircuits={occupationState.occupiedTrackCircuits}
            onClose={closeOccupationPopup}
            onAddOccupiedTrackCircuits={onAddOccupiedTrackCircuits}
          />
        )}
        {!hideLegend && sortedSensorData && (
          <ChartLegendsList
            legends={sensorHistoryLegends}
            inactiveIndexes={inactiveTrackCircuits}
            onInactiveIndexesUpdated={inactiveTrackCircuitsUpdated}
            emptyLegendsLabel={t('trackCircuits.chart.noData')}
            header={`${t('trackCircuits.chart.trackCircuit')}:`}
            loading={queryStatus === 'loading'}
          />
        )}
        {queryStatus === 'success' && !!legendsWithoutData.length && hideLegend && (
          <StyledNoDataLegend>
            <span>{t('trackCircuits.chart.noData')}:</span> {legendsWithoutData.map(legend => legend.label).join(', ')}
          </StyledNoDataLegend>
        )}
      </StyledContainer>
    </>
  )
}
