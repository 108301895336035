export enum InternalAlarmSystem {
  AggregatedPointMachineAlarm = 'aggregatedPointMachineAlarm',
  AggregatedMachineHeatAlarm = 'aggregatedMachineHeatAlarm',
  AggregatedTrackCircuitAlarm = 'aggregatedTrackCircuitAlarm',
  AggregatedSwitchHeatAlarm = 'aggregatedSwitchHeatAlarm',
  AggregatedBenderAlarm = 'aggregatedBenderAlarm',
  AggregatedRackAlarm = 'aggregatedRackAlarm',
}

export type NewAlarmSystem =
  | 'TrackCircuit'
  | 'PointMachine'
  | 'SwitchHeat'
  | 'SignalEarthFault'
  | 'Vibration'
  | 'SurveillanceSystem'
  | 'DerailerMachine'
  | 'MachineHeat'
