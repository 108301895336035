import { useTranslation } from 'react-i18next'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import ForwardOutlinedIcon from '@mui/icons-material/ForwardOutlined'
import { LegendButton } from 'src/Components/Chart/Legends/LegendButton'
import { LegendsContainer } from 'src/Components/Chart/Legends/LegendsContainer'
import { useLogReactParams } from 'src/Hooks/useLogReactParams'

type OwnProps = {
  allDataIndexes: number[]
  inactiveDataIndexes: number[]
  onInactiveDataIndexesUpdated: (inactive: number[]) => void
}

export const ChartLegendControllers = ({ allDataIndexes, inactiveDataIndexes, onInactiveDataIndexesUpdated }: OwnProps) => {
  useLogReactParams('ChartLegendControllers', { allDataIndexes, inactiveDataIndexes, onInactiveDataIndexesUpdated })

  const { t } = useTranslation()

  const showAll = () => onInactiveDataIndexesUpdated([])

  const hideAll = () => onInactiveDataIndexesUpdated(allDataIndexes)

  const prevDataIndex = () => onInactiveDataIndexesUpdated(moveInactiveDataIndexesBackward(allDataIndexes, inactiveDataIndexes))

  const nextDataIndex = () => onInactiveDataIndexesUpdated(moveInactiveDataIndexesForward(allDataIndexes, inactiveDataIndexes))

  const disableNavigation = inactiveDataIndexes.length === 0 || inactiveDataIndexes.length === allDataIndexes.length

  return (
    <LegendsContainer>
      <LegendButton
        key="show-all"
        active={inactiveDataIndexes.length === 0}
        onClick={() => showAll()}
        title={t('general.showAllGraphs')}
      >
        <VisibilityOutlinedIcon fontSize="small" />
        <span>{t('general.showAll')}</span>
      </LegendButton>
      <LegendButton
        active={inactiveDataIndexes.length === allDataIndexes.length}
        onClick={() => hideAll()}
        title={t('general.hideAllGraphs')}
      >
        <VisibilityOffOutlinedIcon fontSize="small" />
        <span>{t('general.hideAll')}</span>
      </LegendButton>
      <LegendButton disabled={disableNavigation} onClick={() => prevDataIndex()} title={t('chartLegend.previousTitleAttribute')}>
        <ForwardOutlinedIcon fontSize="small" sx={{ transform: 'rotate(180deg)' }} />
        <span>{t('general.previous')}</span>
      </LegendButton>
      <LegendButton disabled={disableNavigation} onClick={() => nextDataIndex()} title={t('chartLegend.nextTitleAttribute')}>
        <ForwardOutlinedIcon fontSize="small" />
        <span>{t('general.next')}</span>
      </LegendButton>
    </LegendsContainer>
  )
}

export const getLongestGap = (allDataIndexes: number[], inactiveDataIndexes: number[]) => {
  if (!inactiveDataIndexes.length || allDataIndexes.length === inactiveDataIndexes.length) {
    return undefined
  }

  const inactiveState = allDataIndexes.map(index => ({
    index,
    isInactive: inactiveDataIndexes.includes(index),
  }))
  const groupedIndexes: number[][] = []
  let groupIndex = 0
  inactiveState.reduce((prevIsInactive, { index, isInactive }) => {
    if (isInactive) {
      groupedIndexes[groupIndex] = groupedIndexes[groupIndex] || []
      groupedIndexes[groupIndex].push(index)
    }
    if (prevIsInactive && !isInactive) {
      groupIndex += 1
    }
    return isInactive
  }, false)

  if (inactiveState[0].isInactive && inactiveState[inactiveState.length - 1].isInactive) {
    groupedIndexes[0] = [...(groupedIndexes.pop() || []), ...groupedIndexes[0]]
  }

  const sorted = groupedIndexes.sort((a, b) => a.length - b.length)
  const longest = sorted[sorted.length - 1]
  const start = longest[0]
  const stop = longest[longest.length - 1]
  return { start, stop }
}

const deriveInactiveIndexes = (allDataIndexes: number[], inactiveDataIndexes: number[], direction: -1 | 1) => {
  const count = allDataIndexes.length
  const activeCount = count - inactiveDataIndexes.length
  const longestGap = getLongestGap(allDataIndexes, inactiveDataIndexes)
  if (!longestGap) {
    return []
  }
  const { start, stop } = longestGap
  const origin = direction === 1 ? start : stop
  const active = [...new Array(activeCount)].map((_, i) => (count + origin + i * direction) % count)
  return allDataIndexes.filter(index => !active.includes(index))
}

export const moveInactiveDataIndexesBackward = (allDataIndexes: number[], inactiveDataIndexes: number[]) =>
  deriveInactiveIndexes(allDataIndexes, inactiveDataIndexes, -1)

export const moveInactiveDataIndexesForward = (allDataIndexes: number[], inactiveDataIndexes: number[]) =>
  deriveInactiveIndexes(allDataIndexes, inactiveDataIndexes, 1)
