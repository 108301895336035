import { useRef, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress'

import { WeatherChartRenderer } from 'src/Features/Weather/WeatherChart/weatherChartRenderer'
import { WeatherLegend } from 'src/Features/Weather/WeatherChart/WeatherLegend'
import { Alert } from 'src/Components/Alert'

import type { ChartFocus } from 'src/Types/ChartTypes'
import { useChartStyles } from 'src/Components/Chart/chartStyles'
import { useWeather } from 'src/Hooks/NetworkData/useWeather'
import { useLanguage } from 'src/Hooks/useLanguage'
import { useChartResizeBehaviour } from 'src/Hooks/useChartResizeBehaviour'

const StyledContainer = styled('div')(
  props => `
  margin: ${props.theme.spacing(2)};

  ${props.theme.breakpoints.down('md')} {
    margin: 4px;
  }
  ${props.theme.breakpoints.down('sm')} {
    margin: 2px;
  }
`
)

const StyledChart = styled('div')`
  position: relative;
`

type OwnProps = {
  fromDate: number
  toDate: number
  baneDataLocationId: string
  chartFocus: ChartFocus
}

export const WeatherChart = ({ fromDate, toDate, baneDataLocationId, chartFocus }: OwnProps) => {
  const { t, currentLanguage } = useLanguage()
  const { classes: chartClasses } = useChartStyles()

  const chartRef = useRef<HTMLDivElement>(null)
  const [renderer, setRenderer] = useState<WeatherChartRenderer>()

  const { weather, status } = useWeather({
    baneDataLocationId,
    fromDate,
    toDate,
  })

  useChartResizeBehaviour(renderer)

  useEffect(() => {
    const chart = chartRef.current
    if (chart && weather) {
      const labels = {
        temperature: t('weatherChart.tooltipTemperature'),
        windSpeed: t('weatherChart.tooltipWindSpeed'),
        precipitation: t('weatherChart.tooltipPrecipitation'),
      }

      const chartRenderer = new WeatherChartRenderer({
        chart,
        classes: {
          tooltip: chartClasses.tooltip,
          tooltipArrow: chartClasses.tooltipArrow,
          zoomButton: chartClasses.zoomButton,
        },
        timeline: {
          start: fromDate,
          end: toDate,
        },
      })
      setRenderer(chartRenderer)

      chartRenderer.setData(weather)
      chartRenderer.setFocus(chartFocus)
      chartRenderer.setLabels(labels, currentLanguage)
      chartRenderer.render()
    }
  }, [weather, currentLanguage, chartClasses, t]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (renderer) {
      renderer.setFocus(chartFocus)
    }
  }, [chartFocus, renderer])

  if (status === 'idle') {
    return null
  }

  if (status === 'error') {
    return (
      <Alert severity="error" margin={16}>
        {t('weather.fetchError')}
      </Alert>
    )
  }

  return (
    <>
      {status === 'loading' && (
        <StyledContainer>
          <CircularProgress color="inherit" size={15} /> {t('weatherChart.loading')}
        </StyledContainer>
      )}
      {!weather && status === 'success' && <StyledContainer>{t('weather.noneFound')}</StyledContainer>}
      {weather && (
        <>
          <StyledContainer>
            <StyledChart ref={chartRef} />
          </StyledContainer>
          <WeatherLegend />
        </>
      )}
    </>
  )
}
