import type { Filter, StatusFilter } from 'src/Types/Filter'
import { FilterType } from 'src/Types/Filter'
import type { SystemStatus } from 'src/Types/SystemStatus'
import { includesWindowStatus } from 'src/Utils/asset'

/**
 * Sets default selected system and status filter
 *
 * @param selectedStatus - Selected status to set
 * @param selectedSystem - Selected system to set
 */
export const setDefaultSelectedFilters = (selectedStatus: SystemStatus[], selectedSystem: string[]): Filter[] => {
  const filters: Filter[] = []
  if (selectedStatus.length > 0) {
    filters.push({
      type: FilterType.Status,
      values: selectedStatus,
    })
  }
  if (selectedSystem.length > 0) {
    filters.push({
      type: FilterType.System,
      values: selectedSystem,
    })
  }

  return filters
}

export const statusFilterIncludesAssetStatus = (filters: Filter[]) => {
  const statusFilter: StatusFilter | undefined = filters.flatMap(f => (f.type === FilterType.Status ? f : []))[0]

  return includesWindowStatus(statusFilter?.values)
}
