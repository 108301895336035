import type { ComponentProps } from 'react'
import { useState, useEffect, useMemo } from 'react'
import { styled } from '@mui/material/styles'
import { Grid, Typography } from '@mui/material'

import { TemperatureInput } from 'src/Features/SwitchHeat/Adjustment/TemperatureInput'
import {
  validateTemperatureValue,
  TemperatureValueValidationRule,
} from 'src/Features/SwitchHeat/Adjustment/validateTemperatureValue'
import { TemperatureValueNotification } from 'src/Features/SwitchHeat/Adjustment/TemperatureValueNotification'
import { Button } from 'src/Components/Button'
import themeColors from 'src/theme'
import type { UpdatingTemperatureState } from 'src/Features/SwitchHeat/Adjustment/useTemperatureAdjustmentBehaviour'
import { useTemperatureAdjustmentBehaviour } from 'src/Features/SwitchHeat/Adjustment/useTemperatureAdjustmentBehaviour'
import { TemperatureUpdateList } from 'src/Features/SwitchHeat/Adjustment/TemperatureUpdateList'
import { useLanguage } from 'src/Hooks/useLanguage'
import { useBreakpointDown } from 'src/Hooks/useBreakpoint'
import { adjustHeatValueMock } from 'src/Features/SwitchHeat/Adjustment/fakes/adjustHeatValueMock'
import { adjustHeatValue } from 'src/Providers/SwitchHeat'
import type { TemperatureUpdate } from 'src/Features/SwitchHeat/Adjustment/types'
import { TemperatureUpdateState } from 'src/Features/SwitchHeat/Adjustment/types'
import { useDetectRemoteChanges } from 'src/Features/SwitchHeat/Adjustment/useDetectRemoteChanges'
import { TemperatureChangeNotification } from 'src/Features/SwitchHeat/Adjustment/TemperatureChangeNotification'
import type { TabModelColumn } from 'src/Features/SwitchHeat/TabModel'
import { getColumnGroupedParameters } from 'src/Features/SwitchHeat/Adjustment/getColumnGroupedParameters'
import { ignoreStyleProps } from 'src/Utils/style'

export type Column = 1 | 2

export type TemperatureParameter = {
  sensorId: string
  column: Column
  label: string
  value: number
  metricDisplayName?: string
  minValue?: number
  maxValue?: number
  eTag: string
  currentValueTime: number
  codeSystem: string
}

type OwnProps = {
  temperatureParameters: TemperatureParameter[]
  columns: TabModelColumn[]
  isWritable: boolean
  hasWriteAccess: boolean
  onSubmitted?: (updateState: UpdatingTemperatureState[]) => void
  mockedUpdates?:
    | boolean
    | {
        forcedResult?: boolean
        timeout?: number
      }
}

const StyledFormItems = styled('table')`
  width: 100%;
`

const StyledFormRow = styled(
  'tr',
  ignoreStyleProps('writeable')
)<{ writeable?: boolean }>(
  ({ writeable }) => `
  height: ${writeable ? 40 : 25}px;
`
)

const StyledFormHeaderRow = styled('tr')`
  height: 25px;
`

const StyledFormHead = styled('thead')``

const StyledFormBody = styled('tbody')`
  font-size: 0.875rem;
  line-height: 1.43;
`

const StyledFormCell = styled('td')`
  padding-bottom: 1px;
  padding-top: 1px;
`

const StyledFormLabel = styled(StyledFormCell)``

const StyledFormValue = styled(StyledFormCell)`
  padding-left: 8px;
  text-align: right;
  width: 10%;
`

const StyledFormInput = styled(StyledFormCell)`
  position: relative;
  padding-left: 8px;
  text-align: right;
  width: 10%;
`

const StyledFormHeader = styled(StyledFormCell)`
  white-space: nowrap;
`

const StyledFormValueHeader = styled(StyledFormHeader)`
  text-align: right;
  padding-left: 8px;
`

const StyledWarningMessage = styled(Typography)`
  color: ${themeColors.errorText};
  font-size: 14px;
`

const StyledHeaderLabel = styled('span')`
  display: inline-block;
`

const StyledParameterLabel = StyledHeaderLabel

enum TemperatureValueValidations {
  BlockByBackendUpdatedValue = 'BlockByBackendUpdatedValue',
}

export type TemperatureFormState = Record<string, number>

const mapToUpdates = (
  temperatureParameters: TemperatureParameter[],
  updateStates: UpdatingTemperatureState[]
): TemperatureUpdate[] =>
  temperatureParameters.reduce<TemperatureUpdate[]>((agg, { sensorId, label }) => {
    const updateState = updateStates.find(s => s.sensorId === sensorId)
    if (updateState) {
      agg.push({
        sensorId,
        label,
        state: updateState.state,
        newValue: updateState.value,
        originalValue: updateState.originalValue,
      })
    }
    return agg
  }, [])

const filterUnsuccessfulUpdateValues = (values: TemperatureFormState, updateState: UpdatingTemperatureState[]) =>
  Object.entries(values).reduce<TemperatureFormState>((agg, [sensorId, value]) => {
    const update = updateState.find(s => s.sensorId === sensorId)
    if (update && update.state !== TemperatureUpdateState.Success) {
      agg[sensorId] = value
    }
    return agg
  }, {})

export const TemperatureForm = ({
  isWritable,
  hasWriteAccess,
  temperatureParameters = [],
  columns = [],
  onSubmitted,
  mockedUpdates,
}: OwnProps) => {
  const { t } = useLanguage()
  const isMediaLG = useBreakpointDown('lg')

  const [values, setValues] = useState<TemperatureFormState>({})
  const [showTemperatureUpdates, setShowTemperatureUpdates] = useState(false)
  const [previousUpdateState, setPreviousUpdateState] = useState<UpdatingTemperatureState[]>([])
  const [retryUpdateState, setRetryUpdateState] = useState<UpdatingTemperatureState[]>([])

  const adjustHeatValueFunc = mockedUpdates
    ? adjustHeatValueMock(typeof mockedUpdates === 'object' ? mockedUpdates : {})
    : adjustHeatValue
  const { isUpdating, updateState, submitValues } = useTemperatureAdjustmentBehaviour({
    adjustHeatValueFunc,
  })

  const { remoteChanges, removeRemoteChange } = useDetectRemoteChanges(temperatureParameters)

  useEffect(() => {
    if (!updateState.length) {
      return
    }

    if (isUpdating) {
      setShowTemperatureUpdates(true)
    } else {
      setShowTemperatureUpdates(false)
      setPreviousUpdateState(updateState)
      setRetryUpdateState(updateState.filter(s => s.state === TemperatureUpdateState.Failed))
      setValues(filterUnsuccessfulUpdateValues(values, updateState))

      onSubmitted?.(updateState)
    }
  }, [isUpdating]) // eslint-disable-line react-hooks/exhaustive-deps

  const hasChangedInBackendAndRequireReEnteringBeforeSubmitting = (sensorId: string) => {
    const remoteChange = remoteChanges.find(value => value.sensorId === sensorId)
    if (remoteChange) {
      return true
    }
    const retryValue = retryUpdateState.find(s => s.sensorId === sensorId)
    return retryValue?.hasOutdatedETag
  }

  const verifySubmittableValue = (sensorId: string, changedValue: number) => {
    const param = temperatureParameters.find(p => p.sensorId === sensorId)
    if (!param) {
      return []
    }
    if (hasChangedInBackendAndRequireReEnteringBeforeSubmitting(sensorId)) {
      return [{ type: TemperatureValueValidations.BlockByBackendUpdatedValue }]
    }
    const { minValue, maxValue, value: currentValue } = param
    return validateTemperatureValue({
      changedValue,
      minValue,
      maxValue,
      currentValue,
    })
  }

  const hasUpdates = Object.keys(values).length > 0
  const isAllValuesSubmittable = Object.entries(values).every(([id, value]) => !verifySubmittableValue(id, value).length)
  const isSubmittable = isWritable && hasUpdates && isAllValuesSubmittable && !isUpdating

  const handleValueChange: ComponentProps<typeof TemperatureInput>['onChange'] = (e, sensorId, value) => {
    const newState = { ...values }
    const param = temperatureParameters.find(p => p.sensorId === sensorId)
    if (typeof value === 'number' && param) {
      newState[sensorId] = value
    } else {
      delete newState[sensorId]
    }
    setValues(newState)
    setRetryUpdateState(retryUpdateState.filter(state => state.sensorId !== sensorId))
    removeRemoteChange(sensorId)
  }

  const handleConfirmationSubmit = async () => {
    const valuesToUpdate = Object.entries(values).map(([sensorId, value]) => {
      const param = temperatureParameters.find(p => p.sensorId === sensorId)!
      return { sensorId, eTag: param?.eTag!, value, originalValue: param?.value }
    })
    submitValues(valuesToUpdate)
    setRetryUpdateState([])
    setPreviousUpdateState([])
  }

  const mapViolationsToValidationMessages = (violations: ReturnType<typeof verifySubmittableValue>) =>
    violations.map(violation => {
      switch (violation.type) {
        case TemperatureValueValidationRule.ExceedsMaxValue:
          return t('switchHeat.temperatureForm.validation.maxValue', { maxValue: violation.maxValue })

        case TemperatureValueValidationRule.ExceedsMinValue:
          return t('switchHeat.temperatureForm.validation.minValue', { minValue: violation.minValue })

        case TemperatureValueValidationRule.SameValue:
          return t('switchHeat.temperatureForm.validation.sameValue', { currentValue: violation.currentValue })

        case TemperatureValueValidations.BlockByBackendUpdatedValue:
          return t('switchHeat.temperatureForm.blockedByRemote')

        default:
          return t('switchHeat.temperatureForm.validation.invalid')
      }
    })

  const renderTemperatureParameter = ({ label, value, metricDisplayName, sensorId, codeSystem }: TemperatureParameter) => {
    const formValue = sensorId in values ? values[sensorId] : undefined

    const remoteChange = remoteChanges.find(value => value.sensorId === sensorId)

    const violations = typeof formValue === 'number' ? verifySubmittableValue(sensorId, formValue) : []
    const isValid = !violations.length
    const validationMessages = mapViolationsToValidationMessages(violations)

    const retry = retryUpdateState.find(s => s.sensorId === sensorId)
    if (retry) {
      validationMessages.push(t('switchHeat.temperatureForm.submitFailed'))
    }

    const param = temperatureParameters.find(p => p.sensorId === sensorId)!
    const { minValue, maxValue } = param

    return (
      <StyledFormRow key={label} data-code-system={codeSystem} writeable={isWritable}>
        <StyledFormLabel>
          <StyledParameterLabel>{label}</StyledParameterLabel>
        </StyledFormLabel>
        <StyledFormValue>
          {remoteChange ? <TemperatureChangeNotification diff={remoteChange} /> : null}
          {value} {metricDisplayName}
        </StyledFormValue>
        {isWritable && (
          <StyledFormInput>
            <TemperatureInput
              sensorId={sensorId}
              value={formValue}
              isValid={isValid}
              onChange={handleValueChange}
              disabled={isUpdating}
              minValue={minValue}
              maxValue={maxValue}
            />
            {validationMessages.length ? <TemperatureValueNotification messages={validationMessages} /> : null}
          </StyledFormInput>
        )}
      </StyledFormRow>
    )
  }

  const groupedParameters = useMemo(
    () => getColumnGroupedParameters(temperatureParameters, columns, isMediaLG),
    [isMediaLG, columns, temperatureParameters]
  )

  const displaySummary = () => {
    if (showTemperatureUpdates) {
      const temperatureUpdates = mapToUpdates(temperatureParameters, updateState)
      return (
        <Grid item>
          <TemperatureUpdateList updates={temperatureUpdates} />
        </Grid>
      )
    }

    if (!previousUpdateState.length) {
      return null
    }

    const updateSummary = mapToUpdates(temperatureParameters, previousUpdateState)
    return (
      <Grid item>
        <TemperatureUpdateList updates={updateSummary} />
      </Grid>
    )
  }

  const renderParameterGroups = () => {
    return (
      <Grid container columnGap={4} flexWrap="nowrap" direction={isMediaLG ? 'column' : 'row'}>
        {Object.entries(groupedParameters).map(([column, paramGroup]) => (
          <Grid item flexGrow={1} key={column}>
            <StyledFormItems>
              <StyledFormHead>
                <StyledFormHeaderRow>
                  <StyledFormHeader>
                    <StyledHeaderLabel>{t('switchHeat.temperatureForm.labels.parameters')}</StyledHeaderLabel>
                  </StyledFormHeader>
                  <StyledFormValueHeader>
                    <StyledHeaderLabel>{t('switchHeat.temperatureForm.labels.current')}</StyledHeaderLabel>
                  </StyledFormValueHeader>
                  {isWritable && (
                    <StyledFormValueHeader>
                      <StyledHeaderLabel>{t('switchHeat.temperatureForm.labels.newValue')}</StyledHeaderLabel>
                    </StyledFormValueHeader>
                  )}
                </StyledFormHeaderRow>
              </StyledFormHead>
              <StyledFormBody>{paramGroup.map(renderTemperatureParameter)}</StyledFormBody>
            </StyledFormItems>
          </Grid>
        ))}
      </Grid>
    )
  }

  return (
    <Grid container direction="column" gap={1} data-testid="TemperatureForm">
      <Grid item>{renderParameterGroups()}</Grid>
      <Grid item>
        <Button onClick={handleConfirmationSubmit} disabled={!isSubmittable} data-testid="TemperatureForm_Submit">
          {t('switchHeat.temperatureForm.confirmButton')}
        </Button>
      </Grid>
      {displaySummary()}
      {!hasWriteAccess && (
        <Grid item>
          <StyledWarningMessage data-testid="TemperatureForm_NoAccessMessage">
            {t('switchHeat.temperatureForm.missingWriteAccessMessage')}
          </StyledWarningMessage>
        </Grid>
      )}
    </Grid>
  )
}
