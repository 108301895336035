import type { HistoricSensorHistoryValue } from 'src/Types/SensorTwin'

type AdjustHeatValueMockParams = {
  forcedResult?: boolean
  timeout?: number
}

export const adjustHeatValueMock =
  ({ forcedResult, timeout }: AdjustHeatValueMockParams = {}) =>
  () => {
    return new Promise<HistoricSensorHistoryValue[]>((resolve, reject) => {
      setTimeout(
        () => {
          const isSuccess = typeof forcedResult === 'boolean' ? forcedResult : Math.random() < 0.7
          if (isSuccess) {
            resolve([])
          } else {
            reject(new Error('Failed to update'))
          }
        },
        typeof timeout === 'number' ? timeout : 2000 + Math.random() * 3000
      )
    })
  }
