import type { ReactNode } from 'react'
import Grid from '@mui/material/Grid'
import { ValueDisplay } from './ValueDisplay'
import { CardInformationEntry } from 'src/Components/AssetCard/CardInformationEntry'

import type { SensorTwin } from 'src/Types/SensorTwin'
import type { SystemStatus } from 'src/Types/SystemStatus'
import type { CategorySeverity, SensorCode } from 'src/Types/SensorCode'

import { useDomainConstantsStore } from 'src/Store/domainConstants'
import { useBreakpointDown } from 'src/Hooks/useBreakpoint'
import { useLanguage } from 'src/Hooks/useLanguage'
import { AlarmIndicatorWithLabel } from 'src/Components/AssetCard/Sensor/AlarmIndicatorWithLabel'
import { useLogReactParams } from 'src/Hooks/useLogReactParams'

const SEVERITY_MAP: { [key in CategorySeverity]: SystemStatus } = {
  High: 'Alarm',
  Attention: 'Warning',
  Low: 'Ok',
  None: 'Unknown',
}

const findCategory = (code: SensorCode, value: SensorTwin['currentValue']) =>
  code.category.values.find(valueProps => {
    switch (valueProps.operator) {
      case 'Equal':
        return valueProps.value.toUpperCase() === value?.toString().toUpperCase()
      case 'GreaterThan': {
        const numericValue = value ? +value : 0
        return !Number.isNaN(numericValue) && numericValue > +valueProps.value
      }
      default:
        return false
    }
  })

export type SensorValueProps = {
  codeSystem: string
  sensor: SensorTwin
  domain: 'SVV' | 'SEF'
}

export const SensorValue = ({ codeSystem, sensor, domain }: SensorValueProps) => {
  useLogReactParams('SensorValue', { codeSystem, sensor, domain })
  const { currentLanguage } = useLanguage()
  const isMediaSM = useBreakpointDown('sm')
  const isMediaLG = useBreakpointDown('lg')

  const descriptionLength = isMediaLG && !isMediaSM ? 'short' : 'full'

  const sensorCodes = useDomainConstantsStore(state => state.sensorCodes.sensorCodes[domain])

  const code = sensorCodes.find(code => code.key === codeSystem)

  let label = ''
  let valueElement: ReactNode | undefined

  if (code) {
    label = code.localization[descriptionLength][currentLanguage]

    if (code.metric.unit === 'NA') {
      const valueLocalization = findCategory(code, sensor.currentValue)

      const value = valueLocalization ? valueLocalization[currentLanguage] : sensor.currentValue?.toString()
      const indicator = valueLocalization ? SEVERITY_MAP[valueLocalization.severity] : 'Unknown'

      if (indicator !== 'Unknown') {
        valueElement = <AlarmIndicatorWithLabel alarmIndicator={indicator} value={value} />
      } else {
        valueElement = value
      }
    } else {
      valueElement = <ValueDisplay value={sensor.currentValue} code={code} />
    }
  }

  return (
    <Grid item key={codeSystem} data-code-system-key={codeSystem}>
      <CardInformationEntry label={label} value={valueElement} />
    </Grid>
  )
}
