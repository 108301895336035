import { subDays, startOfToday } from 'date-fns'

import type { DatePeriod, DateRange } from 'src/Types/DatePeriod'

/**
 * Checks if a date range is within the last week from todays date.
 *
 * @param dateRange - DateRange to check.
 */
export const isDateWithinLastWeek = (dateRange: DateRange) => {
  const startOfTodayLastWeek = () => subDays(startOfToday(), 7).valueOf()
  return dateRange.toDate > startOfTodayLastWeek()
}

/**
 * Checks if a date range is fully within today or the last 7 days
 * (if the start of the date range is 1 minute before the start of the day
 * before the first day in that 7 day indicator,
 * it is not fully included in it).
 * @param dateRange - DateRange to check
 */
export const isDateFullyIncludedInLastWeek = (dateRange: DateRange) => {
  const startOfTodayLastWeek = () => subDays(startOfToday(), 7).valueOf()
  return dateRange.fromDate >= startOfTodayLastWeek()
}

/**
 * Checks if a date range is within today
 * @param dateRange - DateRange to check
 */
export const isDateRangeIncludingToday = (dateRange: DateRange) => dateRange.toDate > startOfToday().valueOf()

/**
 * Checks if the given (symbolic) date period has any value that includes now
 * @param datePeriod - DatePeriod to check
 */
export const isDatePeriodIncludingToday = (datePeriod: DatePeriod | undefined) =>
  datePeriod === 'lastHour' ||
  datePeriod === 'today' ||
  datePeriod === 'last24' ||
  datePeriod === 'lastDays' ||
  datePeriod === 'lastWeek' ||
  datePeriod === 'lastMonth'
