import type { ChangeEvent } from 'react'
import { useState, type ReactNode, useMemo, useEffect } from 'react'
import { Table as MuiTable, TableBody, TableCell, TableContainer, TablePagination, TableRow } from '@mui/material'

import type { Order, TableHead as TableHeadType } from 'src/Types/TableTypes'

import { TableHead } from './TableHead'

import { useLanguage } from 'src/Hooks/useLanguage'

import themeColors from 'src/theme'

type TableProps = {
  label: string
  tableHeads: TableHeadType[]
  canSortColumns?: boolean
  children: ReactNode[]
  expandable?: boolean
  noMatchesTextKey?: string
  numberOfRowsPerPage?: number
  order?: Order
  orderBy?: string
  size?: 'small' | 'medium'
  usePagination?: boolean
  handleSort: (id: string) => void
}

export const Table = ({
  label,
  tableHeads,
  canSortColumns = false,
  children,
  expandable = true,
  noMatchesTextKey = 'sideMenu.noFilteredMatches',
  numberOfRowsPerPage = 4,
  order,
  orderBy,
  size = 'small',
  usePagination = false,
  handleSort,
}: TableProps) => {
  const { t } = useLanguage()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(numberOfRowsPerPage)

  useEffect(() => {
    setPage(0)
  }, [children.length])

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const visibleRows = useMemo(() => {
    return usePagination ? children.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : children
  }, [children, page, rowsPerPage, usePagination])

  return (
    <>
      <TableContainer>
        <MuiTable size={size} aria-label={label}>
          <TableHead
            tableHeads={tableHeads}
            canSortColumns={canSortColumns}
            expandable={expandable}
            order={order}
            orderBy={orderBy}
            onSort={handleSort}
          />

          <TableBody>
            {children.length ? (
              visibleRows
            ) : (
              <TableRow>
                <TableCell
                  colSpan={tableHeads.length + (usePagination ? 1 : 0)}
                  sx={{ padding: '16px', color: themeColors.disabledText, fontSize: '16px' }}
                >
                  {t(noMatchesTextKey)}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>

      {usePagination && (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          sx={{ display: 'flex' }}
          count={children.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}-${to} ${t('general.of')} ${count}`
          }}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      )}
    </>
  )
}
