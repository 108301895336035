import {
  WiDaySprinkle,
  WiDayShowers,
  WiRain,
  WiRainWind,
  WiSleet,
  WiSnow,
  WiNA,
  WiNightAltShowers,
  WiNightSprinkle,
} from 'weather-icons-react'

import type { Weather } from 'src/Types/WeatherTypes'

import { isWindyWeather, isWeatherAtNight, getPrecipitation } from 'src/Features/Weather/utils'
import themeColors from 'src/theme'

const iconStyle = {
  verticalAlign: 'middle',
}

const getPrecipitationIcon = (weather: Weather) => {
  const isNight = isWeatherAtNight(weather)
  const isWindy = isWindyWeather(weather)

  switch (getPrecipitation(weather)) {
    case 'snow':
      return <WiSnow />
    case 'sleet':
      return <WiSleet />
    case 'rainy':
      return isWindy ? <WiRainWind /> : <WiRain />
    case 'showers':
      return isNight ? <WiNightAltShowers /> : <WiDayShowers />
    case 'sprinkle':
      return isNight ? <WiNightSprinkle /> : <WiDaySprinkle />
    default:
      return undefined
  }
}

type OwnProps = {
  weather: Weather
  onWhiteBackground?: boolean
  small?: boolean
}

export const RainIcon = ({ weather, onWhiteBackground = false, small = false }: OwnProps) => {
  const iconColor = onWhiteBackground ? themeColors.primary : '#fff'

  const iconSize = small ? 24 : 50

  const PrecipitationIcon = getPrecipitationIcon(weather)
  if (PrecipitationIcon) {
    return (
      <PrecipitationIcon.type // eslint-disable-line react/jsx-pascal-case
        size={iconSize}
        color={iconColor}
        style={iconStyle}
      />
    )
  }
  return <WiNA size={iconSize} color={iconColor} style={iconStyle} />
}
