import type { StackFrame } from 'error-stack-parser'
import errorStackParser from 'error-stack-parser'

/**
 * Create a new Error object with stacktrace.
 * @param message The error message
 * @param skipStackFrame {number} [1] The number of stack-frames to skip
 */
export const deriveError = (message: string, skipStackFrame = 1) => {
  try {
    throw new Error(`${message}`)
  } catch (e) {
    const error = new DerivedError(message)
    error.stack = deriveStackTrace(e as Error, skipStackFrame)
    return error
  }
}

export const deriveStackTrace = (err: Error, skipStackFrame = 0) => {
  try {
    const stack: StackFrame[] = errorStackParser.parse(err).filter((_, i) => i >= skipStackFrame)
    return getStacktraceText(err, stack)
  } catch (e) {
    // Failed to extract stacktrace
    return ['Error extracting stack trace: ', e, '\n', err.toString()].join('')
  }
}

export class DerivedError extends Error {
  private privateStack: string | undefined

  constructor(message: string) {
    super(message)
    this.name = DerivedError.name
    this.message = message
    // restore prototype chain
    const actualProto = new.target.prototype
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(this, actualProto)
    } else {
      const untyped = this as any
      // eslint-disable-next-line no-proto
      untyped.__proto__ = actualProto
    }
  }

  get stack() {
    return this.privateStack
  }

  set stack(stack) {
    this.privateStack = stack
  }
}

type SimplifiedStackFrame = Pick<StackFrame, 'getFunctionName' | 'getFileName' | 'getLineNumber' | 'getColumnNumber'>

const getFrameLine = (frame: SimplifiedStackFrame) => {
  return [
    '    at ',
    frame.getFunctionName() || '<anonymous>',
    ' (',
    frame.getFileName(),
    ':',
    frame.getLineNumber(),
    ':',
    frame.getColumnNumber(),
    ')',
  ].join('')
}

export const getStacktraceText = (error: Error, stacktrace: SimplifiedStackFrame[]) =>
  [error.toString(), ...stacktrace.map(getFrameLine)].join('\n')

export const cloneError = (error: Error) => {
  const clone = new Error()
  clone.constructor = error.constructor
  clone.name = error.name
  clone.stack = error.stack
  clone.message = error.message
  clone.cause = error.cause
  return clone
}
