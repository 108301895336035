import { Box } from '@mui/material'
import type { SensorTwinModus } from 'src/Types/SensorTwin'

export const ModusDebugPanel = (props: {
  changedModus: SensorTwinModus | undefined
  requestedModus: SensorTwinModus | undefined
  currentModus: SensorTwinModus | undefined
  updatingModus: SensorTwinModus | undefined
  lastUpdated: number | undefined
}) => (
  <Box sx={{ position: 'absolute', right: 0, fontSize: '14px' }}>
    <div>
      requested: <strong>{props.requestedModus}</strong>
    </div>
    <div>
      current: <strong>{props.currentModus}</strong>
    </div>
    <div>
      user: <strong>{props.changedModus}</strong>
    </div>
    <div>
      updating: <strong>{props.updatingModus}</strong>
    </div>
    <div>
      time: <strong>{props.lastUpdated && new Date(props.lastUpdated).toLocaleTimeString()}</strong>
    </div>
  </Box>
)
