import type { MutableRefObject, ReactNode } from 'react'
import { useRef, useState, useEffect, useCallback, useMemo, useLayoutEffect } from 'react'
import { styled } from '@mui/material/styles'
import { useHeaderHeight } from 'src/Hooks/useHeaderHeight'
import { useCardIdFromUrl } from 'src/Hooks/useCardIdFromUrl'

const MINIMUM_TOP_ITEMS_TO_DISPLAY = 10
const PRELOAD_THRESHOLD_FACTOR = 1

const StyledOuterDiv = styled('div', { shouldForwardProp: (prop: string) => prop !== 'display' })<{ display: boolean }>(props =>
  props.display
    ? {
        background: 'transparent',
      }
    : {
        background: '#fff',
        margin: '16px',
      }
)

const StyledAnchor = styled('a', { shouldForwardProp: (prop: string) => prop !== 'headerHeight' })<{ headerHeight: number }>(
  ({ headerHeight }) => `
  position: absolute;
  height: 0;
  width: 0;
  overflow: hidden;
  margin-top: -${headerHeight}px;
`
)

type OwnProps = {
  children: ReactNode
  minHeight: number | undefined
  scrollTop: number
  innerHeight: number
  innerWidth: number
  count: number
  index: number
  expanded: boolean
  cardId: string
  onCalculatedItemHeight: (height: number) => void
}

const now = () => new Date().getTime()

export const LazyLoadedAsset = ({
  children,
  minHeight,
  scrollTop,
  innerHeight,
  innerWidth,
  count,
  index,
  expanded,
  cardId,
  onCalculatedItemHeight,
}: OwnProps) => {
  const outerTarget: MutableRefObject<null | HTMLDivElement> = useRef(null)
  const innerTarget: MutableRefObject<null | HTMLDivElement> = useRef(null)
  const anchorTarget: MutableRefObject<null | HTMLAnchorElement> = useRef(null)
  const [height, setHeight] = useState<number | undefined>()
  const [top, setTop] = useState<number | undefined>()
  const [bottom, setBottom] = useState<number | undefined>()
  const [display, setDisplay] = useState(false)
  const [outerHeight, setOuterHeight] = useState<number | undefined>()
  const effectCount = useRef(0)
  const collapsingTime = useRef<number>()
  const expandedCount = useRef(0)
  const cardIdFromUrl = useCardIdFromUrl()
  const requestRef = useRef<number>()

  const headerHeight = useHeaderHeight()

  const scrollStep = useMemo(() => Math.floor(scrollTop / 100), [scrollTop])

  useEffect(() => {
    setHeight(undefined)
  }, [innerWidth])

  useEffect(() => {
    if (expanded) {
      expandedCount.current += 1
      collapsingTime.current = undefined
    } else {
      collapsingTime.current = now()
    }
  }, [expanded])

  const isCardCollapsing = () =>
    !expanded && expandedCount.current && collapsingTime.current && now() - collapsingTime.current < 100

  useEffect(() => {
    // Avoid setting height while card is expanded or collapsing
    if (expanded || isCardCollapsing()) {
      return
    }
    if (!outerHeight) {
      const outerRect = outerTarget.current?.getBoundingClientRect()
      if (outerRect?.height) {
        setOuterHeight(outerRect.height)
      }
    }
    const innerRect = innerTarget.current?.getBoundingClientRect()
    if (innerRect) {
      effectCount.current += 1
      const rectHeight = innerRect.height
      setTop(innerRect.top)
      setBottom(innerRect.bottom)
      if (rectHeight) {
        setHeight(rectHeight)
        onCalculatedItemHeight(rectHeight)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollStep, innerHeight, count, index, height, outerHeight, expanded, display])

  // Navigate to card referred in url
  useLayoutEffect(() => {
    if (cardIdFromUrl === cardId) {
      requestRef.current = window.setTimeout(() => {
        anchorTarget.current?.scrollIntoView(true)
      }, 100)
    }

    return () => {
      if (requestRef.current) {
        window.clearTimeout(requestRef.current)
      }
    }
  }, [cardId, cardIdFromUrl])

  const isInViewport = useCallback(() => {
    const threshold = Math.floor(innerHeight * PRELOAD_THRESHOLD_FACTOR)
    const topThreshold = -threshold
    const bottomThreshold = innerHeight + threshold
    return Boolean(
      top && bottom && ((top > topThreshold && top < bottomThreshold) || (bottom > topThreshold && bottom < bottomThreshold))
    )
  }, [innerHeight, top, bottom])

  useEffect(() => {
    const isInView = isInViewport()
    const isInFirst = index < MINIMUM_TOP_ITEMS_TO_DISPLAY
    const shouldDisplay = Boolean(expanded || isInFirst || (isInView && outerHeight))
    if (display !== shouldDisplay) {
      setDisplay(shouldDisplay)
    }
  }, [isInViewport, index, outerHeight, expanded, display])

  return (
    <StyledOuterDiv ref={outerTarget} style={{ minHeight: `${height || minHeight}px` }} display={display}>
      <StyledAnchor ref={anchorTarget} id={cardId} headerHeight={headerHeight} />
      <div ref={innerTarget}>{display ? children : null}</div>
    </StyledOuterDiv>
  )
}
