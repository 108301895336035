import type { MouseEvent } from 'react'
import { useState, useMemo } from 'react'
import { formatDistanceStrict } from 'date-fns'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@mui/material/ToggleButton'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'

import type { TrackCircuitCompressionRates, TrackCircuitCurrents } from 'src/Types/TrackCircuitCurrentTypes'
import type { LeveledSectionState, TimeSpan } from 'src/Types/LevelOfDetails'

import { Definition } from 'src/Components/Definition'

const StyledDetailsGrid = styled(Grid)`
  background-color: #eee;
`

const StyledCacheLevel = styled('div')`
  position: relative;
  height: 5px;
  border-bottom: solid 1px rgb(255, 170, 170);

  &:first-of-type {
    border-top: solid 1px rgb(255, 170, 170);
  }
`

const StyledSection = styled('div')`
  position: absolute;
  height: 5px;
  overflow: hidden;
  box-sizing: border-box;
`

const StyledCacheSection = styled(StyledSection)`
  background-color: rgba(255, 100, 100, 0.2);
  border-left: solid 1px rgba(255, 100, 100, 0.2);
  border-right: solid 1px rgba(255, 100, 100, 0.2);
`
const StyledRequestSection = styled(StyledSection)`
  background-color: rgba(100, 100, 255, 0.1);
  border-left: solid 1px rgba(100, 100, 255, 0.2);
  border-right: solid 1px rgba(100, 100, 255, 0.2);
`

const StyledCacheActive = styled(StyledSection)`
  background-color: rgba(255, 100, 100, 0.5);
  border: solid 1px rgba(255, 100, 100, 1);
`

type OwnProps = {
  fromDate: number
  toDate: number
  usedCompressionRate?: TrackCircuitCompressionRates
  calculatedCompressionRate?: TrackCircuitCompressionRates
  viewFromDate: number
  viewToDate: number
  sensorData: TrackCircuitCurrents[] | undefined
  cachedSections: LeveledSectionState
  requestedSections: LeveledSectionState
  onCompressionRateChanged: (number: TrackCircuitCompressionRates | undefined) => void
}

type CompressionRateSelectValue = TrackCircuitCompressionRates | ''

const availableCompressionRate: CompressionRateSelectValue[] = [0, 1, 2, 3, '']

export const CompressedChartDebugger = ({
  fromDate,
  toDate,
  usedCompressionRate,
  calculatedCompressionRate,
  viewFromDate,
  viewToDate,
  sensorData,
  cachedSections,
  requestedSections,
  onCompressionRateChanged,
}: OwnProps) => {
  const [compressionRate, setCompressionRate] = useState<CompressionRateSelectValue>('')

  const handleChange = (event: MouseEvent<HTMLElement>, value: CompressionRateSelectValue) => {
    const newCompressionRate = availableCompressionRate.includes(value) ? value : ''
    setCompressionRate(newCompressionRate)
    onCompressionRateChanged(typeof value === 'number' ? value : undefined)
  }

  const firstSensorData = sensorData?.[0]
  const { rcMeasurements, fcMeasurements } = firstSensorData || {}

  const measurements = useMemo(
    () => [rcMeasurements, fcMeasurements].find(m => m?.length) || [],
    [fcMeasurements, rcMeasurements]
  )

  const pointsCount = measurements.length

  const pointsInView = useMemo(() => {
    return measurements.filter(m => m[0] > viewFromDate && m[0] < viewToDate)?.length
  }, [measurements, viewFromDate, viewToDate])

  const levels = [...Array(4)]
    .map((_, i) => (3 - i) as TrackCircuitCompressionRates)
    .map(i => ({ compressionRate: i, cachedSections: cachedSections[i] || [], requestedSections: requestedSections[i] || [] }))

  return (
    <div>
      <StyledDetailsGrid container p={1}>
        <Grid item sm={9}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Grid item xs={12} sm={6} lg={2}>
              <Definition term="Level" description={`${usedCompressionRate} (${calculatedCompressionRate})`} />
            </Grid>
            <Grid item xs={12} sm={6} lg={2}>
              <Definition term="Loaded points" description={pointsCount || 0} />
            </Grid>
            <Grid item xs={12} sm={6} lg={2}>
              <Definition term="Points in view" description={pointsInView || 0} />
            </Grid>
            <Grid item xs={12} sm={6} lg={2}>
              <Definition term="Zoom" description={formatTimeSpan([viewFromDate, viewToDate])} />
            </Grid>
            <Grid item xs={12} sm={6} lg={2}>
              <Definition term="Date range" description={formatTimeSpan([fromDate, toDate])} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={3} textAlign="right">
          <ToggleButtonGroup exclusive size="small" value={compressionRate} onChange={handleChange}>
            {availableCompressionRate.map(cr => (
              <ToggleButton key={cr} value={cr}>
                {typeof cr === 'number' ? cr : 'Default'}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Grid>
      </StyledDetailsGrid>
      <div>
        {levels.map(({ compressionRate, cachedSections, requestedSections }) => {
          const duration = toDate - fromDate
          const left = roundDecimals(((viewFromDate - fromDate) / duration) * 100)
          const right = roundDecimals(((toDate - viewToDate) / duration) * 100)

          const displayActive = usedCompressionRate === compressionRate
          const levelKey = `${compressionRate}`
          return (
            <StyledCacheLevel key={levelKey} title={levelKey}>
              {requestedSections.map(([from, to]) => {
                const left = roundDecimals(((from - fromDate) / duration) * 100)
                const right = roundDecimals(((toDate - to) / duration) * 100)

                return (
                  <StyledRequestSection
                    key={`${from}_${to}`}
                    style={{
                      left: `${left}%`,
                      right: `${right}%`,
                    }}
                  />
                )
              })}
              {cachedSections.map(([from, to]) => {
                const left = roundDecimals(((from - fromDate) / duration) * 100)
                const right = roundDecimals(((toDate - to) / duration) * 100)

                return (
                  <StyledCacheSection
                    key={`${from}_${to}`}
                    style={{
                      left: `${left}%`,
                      right: `${right}%`,
                    }}
                  />
                )
              })}
              {displayActive && (
                <StyledCacheActive
                  key={`${left}_${right}`}
                  style={{
                    left: `${left}%`,
                    right: `${right}%`,
                  }}
                />
              )}
            </StyledCacheLevel>
          )
        })}
      </div>
    </div>
  )
}

const formatTimeSpan = ([from, to]: TimeSpan) => formatDistanceStrict(new Date(from), new Date(to), { roundingMethod: 'floor' })

const roundDecimals = (value: number, decimals = 2) => {
  const factor = !decimals ? 1 : 10 * decimals
  return Math.round(value * factor) / factor
}
