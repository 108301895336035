/* eslint react/jsx-props-no-spreading:0 */
import type { ComponentProps } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import Chip from '@mui/material/Chip'
import NewReleasesIcon from '@mui/icons-material/NewReleases'
import themeColors from 'src/theme'

const BACKGROUND_COLOR = themeColors.info
const FOREGROUND_COLOR = themeColors.primary

type OwnProps = Pick<ComponentProps<typeof Chip>, 'sx' | 'className' | 'style' | 'ref'>

const StyledChip = styled(Chip)`
  background-color: ${BACKGROUND_COLOR};
  color: ${FOREGROUND_COLOR};
  zoom: 120%;
`

export const BetaChip = ({ ...rest }: OwnProps) => {
  const { t } = useTranslation()
  const label = t('general.betaLabel')

  return <StyledChip label={label} size="medium" {...rest} icon={<NewReleasesIcon sx={{ fill: FOREGROUND_COLOR }} />} />
}
