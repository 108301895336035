import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { makeStyles } from 'tss-react/mui'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import PlaceIcon from '@mui/icons-material/PlaceOutlined'

import { ButtonWithPopover } from 'src/Components/ButtonWithPopover'
import { QueryStringStateKeys, useQueryStringState } from 'src/Store/useQueryStringState'
import { useDeepRailways } from 'src/Hooks/NetworkData/useDeepRailways'

import themeColors from 'src/theme'

const useStyles = makeStyles()(theme => ({
  locationBtn: {
    lineHeight: 'normal',
    padding: '5px 10px',
    marginRight: '10px',
    minWidth: 150,
    maxWidth: 150,
    [theme.breakpoints.down('sm')]: {
      padding: '1px 8px 4px 8px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 200,
      fontSize: 12,
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: 300,
    },
  },
  locationBtnText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
    whiteSpace: 'nowrap',
    minWidth: 110,
    maxWidth: 110,
    fontSize: 12,
    color: themeColors.text,
    [theme.breakpoints.up('md')]: {
      maxWidth: 155,
      fontSize: 12,
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: 255,
    },
  },
  locationList: {
    fontSize: 14,
    padding: theme.spacing(1),
  },
  locationListItem: {
    padding: '5px 16px',
  },
  selected: {
    fontWeight: 'bold',
  },
  icon: {
    verticalAlign: 'bottom',
  },
}))

type OwnProps = {
  selectedWeatherLocationId: string
  onWeatherLocationIdSelected: (baneDataLocationId: string) => void
}

export const WeatherLocationSelector = ({ selectedWeatherLocationId, onWeatherLocationIdSelected }: OwnProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const [isPopoverOpen, setOpenPopover] = useState(false)

  const { data: railways } = useDeepRailways()
  const locations = railways?.flatMap(railway => railway.railwaySections).flatMap(section => section.locations) ?? []

  const [selectedStretchLocations] = useQueryStringState(QueryStringStateKeys.stretchLocations)

  const selectLocation = (locationId: string) => {
    onWeatherLocationIdSelected(locationId)
    setOpenPopover(false)
  }

  return (
    <ButtonWithPopover
      isPopoverOpen={isPopoverOpen}
      onOpenPopover={setOpenPopover}
      disabled={selectedStretchLocations.length === 1}
      buttonContent={
        <span className={classes.locationBtnText} title={t('weather.locationInfo')}>
          {locations.find(l => l.locationId === selectedWeatherLocationId)?.name || selectedWeatherLocationId}
        </span>
      }
      buttonEndIcon={<PlaceIcon fontSize="small" className={classes.icon} color="inherit" />}
      buttonClass={classes.locationBtn}
      popoverContent={
        <List component="nav" className={classes.locationList}>
          {selectedStretchLocations.map(locationId => (
            <ListItemButton
              onClick={() => selectLocation(locationId)}
              key={locationId}
              className={clsx(classes.locationListItem, {
                [classes.selected]: selectedWeatherLocationId === locationId,
              })}
            >
              {locations.find(l => l.locationId === locationId)?.name || locationId}
            </ListItemButton>
          ))}
        </List>
      }
    />
  )
}
