import { useEffect, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

import { Alert } from 'src/Components/Alert'
import { Header } from './Components/Header/Header'
import { DebugPanel } from 'src/Components/DebugPanel'
import { RoutingBody } from './Components/RoutingBody'
import { TelemetryProvider } from './TelemetryProvider'
import { InProgressComponent, AppTimedOutComponent } from './Auth'
import { useDomainConstantsLoader } from './Hooks/useDomainConstantsLoader'
import { useUserProfile } from './Hooks/NetworkData/useUserProfile'
import { getLeastAdvancedQueryStatus } from 'src/Utils/network'
import { isLocal } from 'src/reactAppEnv'
import { basePath } from 'src/Utils/basePath'
import { useApplicationInsightsPageTracking } from 'src/Hooks/useApplicationInsightsPageTracking'
import { useNavigateToStartPage } from 'src/Hooks/useNavigateToStartPage'
import { useUserProfileStore } from './Store/userProfile'
import { useBreakpointDown } from 'src/Hooks/useBreakpoint'
import { useDeepRailways } from './Hooks/NetworkData/useDeepRailways'
import { useReflectStateFromUrl } from 'src/Hooks/useReflectStateFromUrl'
import { QueryStringStateKeys, useQueryStringState } from 'src/Store/useQueryStringState'
import { trackDurationSinceScriptEval, trackScriptLoadTime } from 'src/Tracking/trackMetric'
import { getAppInsights } from 'src/telemetryService'

const AppLoader = () => {
  const isSmall = useBreakpointDown('md')
  const updateSetting = useUserProfileStore(state => state.settings.updateSetting)

  const { status: railwaysStatus } = useDeepRailways()
  const { status: userProfileStatus } = useUserProfile()
  const [timedOut, setTimedOut] = useState(false)
  const [datePeriod] = useQueryStringState(QueryStringStateKeys.datePeriod)

  const queryStatus = getLeastAdvancedQueryStatus([railwaysStatus, userProfileStatus])

  const { domainConstantsLoaded, status: domainConstantsStatus } = useDomainConstantsLoader()

  useNavigateToStartPage()

  useReflectStateFromUrl()

  useApplicationInsightsPageTracking()

  useEffect(() => {
    if (queryStatus === 'success' && domainConstantsLoaded) {
      trackDurationSinceScriptEval('app-ready')
      trackScriptLoadTime()
    }
  }, [queryStatus, domainConstantsLoaded])

  useEffect(() => {
    let isMenuOpen = !isSmall
    if (!datePeriod) {
      isMenuOpen = true
    }
    updateSetting('isMenuOpen', isMenuOpen)
  }, [isSmall, datePeriod, updateSetting])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!timedOut && (queryStatus !== 'success' || !domainConstantsLoaded)) {
        getAppInsights()?.trackEvent(
          { name: 'app-ready-timed-out' },
          {
            railwaysStatus,
            userProfileStatus,
            domainConstantsLoaded,
          }
        )
        setTimedOut(true)
      }
    }, 8000)

    return () => {
      clearTimeout(timeout)
    }
  })

  if (queryStatus === 'error' || (!domainConstantsLoaded && domainConstantsStatus === 'error')) {
    return (
      <Alert severity="error">
        Beklager! En eller flere grunnleggende funksjoner som kreves for at denne applikasjonen skal fungerere feilet.
        <br />
        Prøv igjen senere eller ta kontakt med support.
      </Alert>
    )
  }

  if (queryStatus === 'loading' || !domainConstantsLoaded) {
    if (timedOut) {
      return <AppTimedOutComponent />
    }

    return <InProgressComponent />
  }

  return (
    <>
      <Header />
      <RoutingBody />
      {isLocal && <DebugPanel />}
    </>
  )
}

export const App = () => {
  return (
    <Router basename={basePath}>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <TelemetryProvider>
          <AppLoader />
        </TelemetryProvider>
      </QueryParamProvider>
    </Router>
  )
}
