import { Navigate, Route, Routes } from 'react-router-dom'
import Grid from '@mui/material/Grid'

import { FavoriteListPage } from 'src/Pages/FavoriteListPage'
import { useStartPage } from 'src/Hooks/useStartPage'
import { AlarmCardsPage } from 'src/Pages/AlarmCardsPage'
import { SwitchesAndHeatCardsPage } from 'src/Pages/SwitchesAndHeatCardsPage'
import { SignalEarthCardsPage } from 'src/Pages/SignalEarthCardsPage'
// eslint-disable-next-line max-len
import { SignalEarthStreamPage } from 'src/Pages/SignalEarthStreamPage'
import { TrackCircuitCardsPage } from 'src/Pages/TrackCircuitCardsPage'
import { TrackCircuitStreamPage } from 'src/Pages/TrackCircuitStreamPage'

import { AppPage } from 'src/Types/AppPage'

export const RoutingBody = () => {
  const { startPageLink, isReady } = useStartPage()

  const startPage = startPageLink ?? '/alarms'

  return (
    <Grid container justifyContent="space-around" alignItems="center">
      <Grid item xs={12}>
        <Routes>
          <Route index path={`/${AppPage.AlarmCards}`} element={<AlarmCardsPage />} />
          <Route path={`/${AppPage.SwitchesAndHeatCards}`} element={<SwitchesAndHeatCardsPage />} />
          <Route path={`/${AppPage.TrackCircuitsStream}`} element={<TrackCircuitStreamPage />} />
          <Route path={`/${AppPage.TrackCircuitsCards}`} element={<TrackCircuitCardsPage />} />
          <Route path={`/${AppPage.SignalEarthCards}`} element={<SignalEarthCardsPage />} />
          <Route path={`/${AppPage.SignalEarthStream}`} element={<SignalEarthStreamPage />} />
          <Route path={`/${AppPage.Favorites}`} element={<FavoriteListPage />} />
          {isReady && (
            <>
              <Route path="/" element={<Navigate replace to={startPage} />} />
              <Route path="*" element={<Navigate replace to={startPage} />} />
            </>
          )}
        </Routes>
      </Grid>
    </Grid>
  )
}
