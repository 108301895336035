import type { CriticalitiesStatisticsType, OrchestratedAssetData } from 'src/Types/OrchestratedAssetData'

/**
 * Finds criticality value based on criticality type
 *
 * @param assetData - Asset data to find a specific criticality value from.
 * @param criticalitiesType - Criticality type to find
 */
export const getCriticalityValue = (assetData: OrchestratedAssetData, criticalitiesType: CriticalitiesStatisticsType) => {
  const criticalityEntry = assetData.criticalities && assetData.criticalities.find(c => c.statisticsType === criticalitiesType)
  return criticalityEntry?.statisticsValue || 0
}
