import type { ReactNode } from 'react'
import { styled } from '@mui/material/styles'
import { ignoreStyleProps } from 'src/Utils/style'
import themeColors from 'src/theme'
import { useHeaderHeight } from 'src/Hooks/useHeaderHeight'

type StyledDrawerContainerProps = {
  marginTop: number
}

const StyledDrawerContainer = styled(
  'div',
  ignoreStyleProps('marginTop')
)<StyledDrawerContainerProps>(({ theme, marginTop }) => {
  return `
    display: flex;
    align-items: center;
    padding: ${theme.spacing(0, 1)};
    justify-content: end;
    margin-top: ${marginTop}px;
    ${theme.breakpoints.down('md')} {
      background-color: ${themeColors.primary};
    }
    `
})

type OwnProps = {
  children?: ReactNode
}

export const DrawerHeaderContainer = ({ children }: OwnProps) => {
  const headerHeight = useHeaderHeight()

  return <StyledDrawerContainer marginTop={headerHeight}>{children}</StyledDrawerContainer>
}
