import Grid from '@mui/material/Grid'

import type { AssetWithAlarms } from 'src/Types/AssetWithAlarms'
import type { SwitchHeatCabinet } from 'src/Types/SwitchHeatCabinet'

import { useLanguage } from 'src/Hooks/useLanguage'
import { ConnectedSwitches } from 'src/Features/SwitchHeat/ConnectedSwitches'
import { useBreakpointDown } from 'src/Hooks/useBreakpoint'
import { formatLocalDateTime } from 'src/Utils/format'
import { useExGenToggledOn } from 'src/Hooks/useSwitchHeatVersion'
import { useConnectedHeatCabinet } from 'src/Hooks/NetworkData/useConnectedHeatCabinet'
import CircularProgress from '@mui/material/CircularProgress'

type OwnProps = {
  assetWithAlarms: AssetWithAlarms
}

export const SwitchHeatGroupInfo = ({ assetWithAlarms }: OwnProps) => {
  const { t } = useLanguage()
  const isMediaSM = useBreakpointDown('sm')
  const asset = assetWithAlarms.asset as SwitchHeatCabinet
  const isExGen = useExGenToggledOn()

  const baneDataId =
    asset.switchHeatElements && asset.switchHeatElements.length > 0
      ? asset.switchHeatElements[0].switchBaneDataId
      : asset.baneDataId
  const { connectedHeatCabinet, status } = useConnectedHeatCabinet(baneDataId)

  const getContent = (prop: 'plcVersion' | 'ipAddress') => {
    if (status === 'loading' || status === 'idle') {
      return <CircularProgress color="inherit" size={15} />
    }

    if (status === 'error' || !connectedHeatCabinet) {
      return <b>{(prop === 'plcVersion' ? asset.plcVersion : asset.ipAddress) ?? t('switchHeat.sensorInfo.unknown')}</b>
    }

    return (
      <b>
        {(prop === 'plcVersion' ? connectedHeatCabinet.plcVersion : connectedHeatCabinet.ipAddress) ??
          t('switchHeat.sensorInfo.unknown')}
      </b>
    )
  }

  return (
    <Grid container gap={2} mb={2} ml={isMediaSM ? 1 : 0} direction="column">
      <Grid item>
        {t('general.name')}: <b>{asset.name}</b>
      </Grid>
      {!isExGen && (
        <Grid item>
          <ConnectedSwitches switchHeatCabinetBaneDataId={asset.baneDataId} />
        </Grid>
      )}
      {isExGen && (
        <>
          <Grid item>
            {t('switchHeat.sensorInfo.producer')}:{' '}
            <b>{asset.cabinetClassName ? asset.cabinetClassName : t('switchHeat.sensorInfo.unknown')}</b>
          </Grid>
          <Grid item>
            {t('general.installDate')}:{' '}
            <b>{asset.installDate ? formatLocalDateTime(asset.installDate, true) : t('switchHeat.sensorInfo.unknown')}</b>
          </Grid>
        </>
      )}
      <Grid item>
        {t('switches.switchHeat.plcVersion')}: <b>{getContent('plcVersion')}</b>
      </Grid>
      <Grid item>
        {t('switches.switchHeat.ipAddress')}: <b>{getContent('ipAddress')}</b>
      </Grid>
    </Grid>
  )
}
