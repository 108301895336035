import { Collapse, TableCell, TableRow, Typography, styled } from '@mui/material'
import LaunchIcon from '@mui/icons-material/Launch'

import type { WorkOrder } from 'src/Types/WorkOrderTypes'

import { PurifiedHtml } from 'src/Components/PurifiedHtml'

import { useLanguage } from 'src/Hooks/useLanguage'

import { formatLocalDateTime } from 'src/Utils/format'

import themeColors from 'src/theme'
import { useLogReactParams } from 'src/Hooks/useLogReactParams'

const StyledCollapse = styled(Collapse)`
  padding: 0.5rem 64px 1rem 50px;
  background-color: ${themeColors.tableCollapseBackground};

  .MuiTypography-root {
    font-size: 14px;
  }
`

const StyledCollapseContent = styled('div')`
  display: flex;
  flex-direction: column;
`

const StyledInfoItem = styled('div')`
  display: flex;
  gap: 8px;
  margin-bottom: 0.5rem;
`

const StyledLabel = styled(Typography)`
  font-weight: bold;
`

const StyledNoDescription = styled(Typography)`
  font-style: italic;
`

type OwnProps = {
  isComplete: boolean
  open: boolean
  workOrder: WorkOrder
}

export const WorkOrdersTableCollapse = ({ isComplete, open, workOrder }: OwnProps) => {
  useLogReactParams('WorkOrdersTableCollapse', { isComplete, open, workOrder })
  const { t } = useLanguage()
  const maximoUrlPrefix = 'http://max.banedata.no/maximo/ui/login?event=loadapp&value=wotrack&uniqueid='

  return (
    <TableRow>
      <TableCell style={{ padding: 0, margin: 0 }} colSpan={8}>
        <StyledCollapse in={open} timeout="auto" unmountOnExit>
          <StyledCollapseContent>
            <div style={{ display: 'flex', gap: '1rem' }}>
              <div>
                {workOrder.objectName && (
                  <StyledInfoItem>
                    <StyledLabel>{t('workOrders.regardsObject')}: </StyledLabel>
                    <Typography>
                      {workOrder.objectName.toUpperCase()}{' '}
                      <a
                        href={`${maximoUrlPrefix}${workOrder.workOrderId}`}
                        title={t('workOrders.maximoLinkTitle')}
                        target="_blank"
                        rel="bookmark noreferrer"
                      >
                        ({workOrder.banedataId} <LaunchIcon fontSize="inherit" />)
                      </a>
                    </Typography>
                  </StyledInfoItem>
                )}
                {isComplete && workOrder.timeOfCompletion && (
                  <StyledInfoItem>
                    <StyledLabel>{t('workOrders.timeOfCompletion')}: </StyledLabel>
                    <Typography>{formatLocalDateTime(new Date(workOrder.timeOfCompletion), true)}</Typography>
                  </StyledInfoItem>
                )}
                {!isComplete && workOrder.plannedTimeOfExecution && (
                  <StyledInfoItem>
                    <StyledLabel>{t('workOrders.plannedTimeOfExecution')}: </StyledLabel>
                    <Typography>{formatLocalDateTime(new Date(workOrder.plannedTimeOfExecution), true)}</Typography>
                  </StyledInfoItem>
                )}
                <StyledInfoItem>
                  <StyledLabel>{t('workOrders.changedBy')}: </StyledLabel>
                  <Typography>{workOrder.changeBy}</Typography>
                </StyledInfoItem>
              </div>
            </div>

            {workOrder.commentLongDescription ? (
              <Typography>
                <PurifiedHtml rawHTML={workOrder.commentLongDescription} />
              </Typography>
            ) : (
              <StyledNoDescription sx={{ textDecoration: 'cursive' }}>{t('workOrders.noDescription')}</StyledNoDescription>
            )}
          </StyledCollapseContent>
        </StyledCollapse>
      </TableCell>
    </TableRow>
  )
}
