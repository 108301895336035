import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import { WiRaindrops, WiStrongWind, WiGaleWarning, WiHurricaneWarning } from 'weather-icons-react'
import Grid from '@mui/material/Grid'

import { CardSection } from './CardSection'

import type { Weather, WindType } from 'src/Types/WeatherTypes'

import { getWindType } from 'src/Features/Weather/utils'
import { formatNumber } from 'src/Utils/format'
import themeColors from 'src/theme'

const StyledGridWithVerticalBorder = styled(Grid)`
  margin-top: 4px;
  border-right: 1px solid ${themeColors.info};
`

const StyledRainIcon = styled(WiRaindrops)`
  vertical-align: middle;
  margin: -5px -16px -16px -16px;
`

const windIconStyle = {
  verticalAlign: 'middle',
  margin: -10,
}

const getWindIcon = (windType: WindType) => {
  if (windType === 'storm') {
    return <WiHurricaneWarning size={25} color={themeColors.error} style={windIconStyle} />
  }
  if (windType === 'gale') {
    return <WiGaleWarning size={25} color={themeColors.warning} style={windIconStyle} />
  }

  return <WiStrongWind size={25} style={windIconStyle} />
}

type OwnProps = {
  weather: Weather[]
}

export const RainWind = ({ weather }: OwnProps) => {
  const { t } = useTranslation()

  if (weather.length === 0) {
    return null
  }

  const totalPrecipitation = weather.reduce((w, { precipitation }) => w + precipitation, 0)
  const minWindSpeed = Math.round(Math.min(...weather.map(w => w.windSpeed)))
  const maxWindSpeed = Math.round(Math.max(...weather.map(w => w.windSpeed)))
  const maxWindGust = Math.round(Math.max(...weather.map(w => w.windGust)))

  const windType = getWindType(maxWindSpeed, maxWindGust)
  let windAlertText = ''
  if (windType === 'storm') {
    windAlertText = t('weather.stormWarning')
  } else if (windType === 'gale') {
    windAlertText = t('weather.galeWarning')
  }

  return (
    <Grid container alignItems="flex-end">
      <StyledGridWithVerticalBorder item xs={6}>
        <CardSection
          icon={<StyledRainIcon size={40} />}
          iconTitle={windAlertText}
          value={`${formatNumber(totalPrecipitation)} ${t('weather.mm')}`}
        />
      </StyledGridWithVerticalBorder>
      <Grid item xs={6}>
        <CardSection icon={getWindIcon(windType)} value={`${minWindSpeed}-${maxWindSpeed} ${t('weather.metersPerSecond')}`} />
      </Grid>
    </Grid>
  )
}
