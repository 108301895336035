import type { AggregatedTrackCircuitAlarm } from 'src/Types/AggregatedTrackCircuitAlarm'

import { getTrackCircuitAlarms } from 'src/Providers/TrackCircuitAlarms'
import { useQueryWithErrorHandling } from './useQueryWithErrorHandling'

export const useTrackCircuitAlarms = (aggregatedAlarm?: AggregatedTrackCircuitAlarm) => {
  const query = useQueryWithErrorHandling(
    ['trackCircuitAlarms', aggregatedAlarm],
    () =>
      aggregatedAlarm
        ? getTrackCircuitAlarms({
            baneDataIds: [aggregatedAlarm.baneDataId],
            alarmTypeId: aggregatedAlarm.alarmTypeId,
            fromDate: aggregatedAlarm.firstRealFromDateTime,
            toDate: aggregatedAlarm.lastRealToDateTime,
          })
        : undefined,
    {
      enabled: !!aggregatedAlarm,
    },
    'sensorData.alarmsFetchError'
  )

  return {
    ...query,
    alarms: query.data,
  }
}
