export type WorkOrder = {
  banedataId: string
  changeBy: string
  changedateInBanedata: string
  commentLongDescription: string | null
  createdBy: string
  description: string
  eventLogId: string
  id: string
  lead: number | string | null
  plannedTimeOfExecution: string | null
  status: WorkOrderStatus
  supervisor: string | null
  timeOfCompletion: string | null
  timeOfCreation: string
  timeOfLastRowChange: string
  objectName: string | null
  workOrderId: number
  workType: WorkOrderType
  discipline: WorkOrderDiscipline | null
}

export type WorkOrderCount = {
  count: number
  id: string
}

export enum WorkOrderFilterType {
  Status = 'status',
  WorkType = 'workType',
  Discipline = 'discipline',
}

export type WorkOrderFilter = {
  type: WorkOrderFilterType
  values: string[]
}

export enum CompletedWorkOrderStatus {
  Avbrutt = 'AVBRUTT',
  Endrhist = 'ENDRHIST',
  Hist = 'HIST',
  Lukket = 'LUKKET',
  Sluttf = 'SLUTTF',
}

export enum NonCompletedWorkOrderStatus {
  Godkj = 'GODKJ',
  Godkjent = 'GODKJENT',
  Kvplan = 'KVPLAN',
  Matrlev = 'MATRLEV',
  Planl = 'PLANL',
  Reg = 'REG',
  Startet = 'STARTET',
  Tilgodkj = 'TILGODKJ',
  Utsatt = 'UTSATT',
  Venter = 'VENTER',
  Vmatrl = 'VMATRL',
  Vplan = 'VPLAN',
}

export type WorkOrderStatus = CompletedWorkOrderStatus | NonCompletedWorkOrderStatus

export enum WorkOrderType {
  AFV = 'AFV',
  AKV = 'AKV',
  FVK = 'FVK',
  FVP = 'FVP',
  KVH = 'KVH',
  PRS = 'PRS',
  UKV = 'UKV',
}

export enum WorkOrderDiscipline {
  SA = 'SA',
  EL = 'EL',
  KO = 'KO',
}
