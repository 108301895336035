import { callApiPost } from 'src/msGraphClient'
import { ApiType } from 'src/Types/ApiType'
import config from 'src/Config/Config'
import type { CriticalityComponentTypeEnum } from 'src/Types/AlarmCriticality'

const { CRITICALITY_RESET } = config.API_FRAGMENTS.apim

/**
 * Having void as response body since it should be empty when response code probably will be 202 Accepted
 * @param baneDataId The ID for the asset (having alarm criticality considering its alarms/warnings)
 * @param componentTypeId The criticality component type ID (int)
 */
export const saveCriticalityReset = async (baneDataId: string, componentTypeId: CriticalityComponentTypeEnum) => {
  await callApiPost<void>(
    `${CRITICALITY_RESET}?baneDataId=${baneDataId}&componentTypeId=${componentTypeId}`,
    ApiType.APIM,
    JSON.stringify({})
  )
}
