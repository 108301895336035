import { useMemo } from 'react'
import { useLanguage } from 'src/Hooks/useLanguage'
import type { RackSensorHistory } from 'src/Types/RackSensorHistory'
import { partition } from 'src/Utils/array'
import type { RackSectionSensor } from 'src/Types/Rack'
import type { ChartLegend } from 'src/Components/Chart/Legends/ChartLegend'

export const useRackEarthCurrentLegends = (
  sensorHistory: RackSensorHistory[] | undefined,
  selectedRackSections: RackSectionSensor[],
  inactiveSensors: number[]
): ChartLegend[] => {
  const { t } = useLanguage()

  return useMemo(() => {
    const [rackSectionsWithData, rackSectionsWithoutData] = partition(sensorHistory || [], history => !!history.values?.length)
    return [...rackSectionsWithData, ...rackSectionsWithoutData].map((history, i) => {
      const baneDataId = history.twinId
      const isActive = !inactiveSensors.includes(i)
      const titleKey = isActive ? 'racks.chart.hideRack' : 'racks.chart.showRack'
      const title = t(titleKey, { rsId: history.twinId })
      const label = selectedRackSections.find(rs => rs.baneDataId === baneDataId)?.name || baneDataId
      const isEmpty = !history.values?.length

      return {
        id: baneDataId,
        label,
        buttonTitle: title,
        isEmpty,
      }
    })
  }, [inactiveSensors, selectedRackSections, sensorHistory, t])
}
