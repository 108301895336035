import { styled } from '@mui/material/styles'

import { ignoreStyleProps } from 'src/Utils/style'

const StyledSvg = styled('svg', ignoreStyleProps('inactive'))<{ inactive: boolean }>`
  vertical-align: middle;
  filter: ${props => (props.inactive ? 'grayscale(100%)' : 'none')};
`

type OwnProps = {
  bands: string[]
  inactive?: boolean
}

const HEIGHT = 10
const WIDTH = 16

export const LegendPhasesIcon = ({ bands, inactive = false }: OwnProps) => {
  const bandWidth = WIDTH / bands.length

  return (
    <StyledSvg height={HEIGHT} width={WIDTH} inactive={inactive}>
      {bands.map((b, i) => (
        <rect key={b} x={i * bandWidth} y="0" width={bandWidth} height={HEIGHT} fill={b} />
      ))}
    </StyledSvg>
  )
}
