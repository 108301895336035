import { useTranslation } from 'react-i18next'
import { isToday } from 'date-fns/isToday'
import { subDays } from 'date-fns/subDays'
import { subMonths } from 'date-fns/subMonths'
import { subYears } from 'date-fns/subYears'
import CircularProgress from '@mui/material/CircularProgress'

import { Divider } from 'src/Components/Divider'
import { SubHeaderWithExpandableInfo } from 'src/Components/SubHeaderWithExpandableInfo'
import { AssetInformationEntry } from 'src/Components/AssetCard/AssetInformationEntry'

import type { SwingStatistics } from 'src/Types/SwingStatistics'
import { formatLocalDateTime } from 'src/Utils/format'
import { DaysSinceLastPointMachineAlarm } from './Maintenance/DaysSinceLastPointMachineAlarm'
import { StyledContent } from './elements'
import { SubHeaderKey } from 'src/Providers/Settings/SubHeader'

type OwnProps = {
  baneDataId: string
  swingStatistics?: SwingStatistics
  isLoading: boolean
}

export const SwingInfoStatistics = ({ baneDataId, swingStatistics, isLoading }: OwnProps) => {
  const { t } = useTranslation()

  const renderContent = () => {
    if (!swingStatistics) {
      return <AssetInformationEntry label={<CircularProgress size={15} />} />
    }

    const statisticsEntry = (valueKey: keyof SwingStatistics, from: number | Date, to: number) => {
      const period = isToday(swingStatistics.toDate) ? 'today' : 'past'
      const value = swingStatistics[valueKey] ?? t('switches.statusLabels.switchStatistics.unknown')

      const fromDate = formatLocalDateTime(from, true)
      const toDate = formatLocalDateTime(to, true)

      return (
        <AssetInformationEntry
          key={valueKey}
          label={t(`switches.statusLabels.switchStatistics.${period}.${valueKey}`, { fromDate, toDate })}
          values={[value]}
        />
      )
    }

    return (
      <StyledContent>
        <DaysSinceLastPointMachineAlarm baneDataId={baneDataId} />
        <Divider />
        {statisticsEntry('sumInPeriod', swingStatistics.fromDate, swingStatistics.toDate)}
        {statisticsEntry('lastDaySum', swingStatistics.toDate, swingStatistics.toDate)}
        {statisticsEntry('lastWeekSum', subDays(swingStatistics.toDate, 7), swingStatistics.toDate)}
        {statisticsEntry('lastMonthSum', subMonths(swingStatistics.toDate, 1), swingStatistics.toDate)}
        {statisticsEntry('lastYearSum', subYears(swingStatistics.toDate, 1), swingStatistics.toDate)}
      </StyledContent>
    )
  }

  return (
    <SubHeaderWithExpandableInfo
      title={t('switches.switchStatistics')}
      isDefaultExpanded
      isLoading={isLoading}
      subHeaderKey={SubHeaderKey.SwitchStatistics}
    >
      {renderContent()}
    </SubHeaderWithExpandableInfo>
  )
}
