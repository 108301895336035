import type { ICustomProperties } from '@microsoft/applicationinsights-web'

const telemetryProps: ICustomProperties = {}

export const setTelemetryProps = (props: ICustomProperties) => {
  Object.assign(telemetryProps, props)
  Object.entries(props).forEach(([key, value]) => {
    if (value === undefined) {
      delete telemetryProps[key]
    }
  })
}

export const getTelemetryProps = () => telemetryProps
