import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'

import { CustomIcon } from 'src/Components/CustomIcon'

import { createWindIcon } from 'src/Features/Weather/WindIcon/createWindIcon'

type StyleProps = {
  windDegrees: number
}

type OwnProps = StyleProps & {
  windSpeed: number
  color?: string
}

const StyledIcon = styled(CustomIcon)<StyleProps>`
  vertical-align: middle;
  transform: ${props => `rotate(${props.windDegrees}deg)`};
`

export const WindIcon = ({ windDegrees, windSpeed, color = 'currentColor' }: OwnProps) => {
  const { t } = useTranslation()

  return (
    <StyledIcon title={t('weather.windDirection')} color={color} windDegrees={windDegrees}>
      <path d={createWindIcon(windSpeed)} stroke={color} strokeWidth="1.5" />
    </StyledIcon>
  )
}
