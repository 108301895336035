import { styled } from '@mui/material/styles'
import MuiDivider from '@mui/material/Divider'

import { ignoreStyleProps } from 'src/Utils/style'
import themeColors from 'src/theme'

const StyledDivider = styled(MuiDivider, ignoreStyleProps('margin'))<{ margin: number }>`
  background-color: ${themeColors.hr};
  ${props =>
    props.margin &&
    `
    margin-top: ${props.margin}px;
    margin-bottom: ${props.margin}px;
  `}
`

type OwnProps = {
  margin?: number
}

export const Divider = ({ margin = 0 }: OwnProps) => <StyledDivider margin={margin} />
