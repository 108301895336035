import type { GetState, SetState, StateCreator, StoreApi } from 'zustand'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

import type { Favorite } from 'src/Types/Favorite'
import type { UserSettings } from 'src/Types/UserSettings'
import type { OpenedAlarm } from 'src/Types/OpenedAlarm'

import { storeUserSettings } from 'src/Providers/Settings/UserSettings'
import { defaultUserSettings } from 'src/Providers/Settings/defaultUserSettings'
import { storeFavorites } from 'src/Providers/Settings/Favorites'
import { storeOpenedAlarms, alarmExists } from 'src/Providers/Settings/Alarms'
import type { SubHeaderDefaultExpanded, SubHeaderKey } from 'src/Providers/Settings/SubHeader'
import { storeSubHeaderDefaultExpanded } from 'src/Providers/Settings/SubHeader'

interface UserProfileState {
  /** Indicating that state should not be saved back to remote storage */
  initial: boolean
  hasLoadedSettings: boolean
  hasLoadedFavorites: boolean
  setInitialSettings: (settings: UserSettings) => void
  setInitialFavorites: (favorites: Favorite[]) => void
  setInitialOpenedAlarms: (openedAlarms: OpenedAlarm[]) => void
  setInitialSubHeaderDefaultExpanded: (defaultExpanded: SubHeaderDefaultExpanded) => void

  accessGroups: {
    accessGroups: string[]
    setAccessGroups: (accessGroups: string[]) => void
  }

  settings: {
    settings: UserSettings
    setSettings: (settings: UserSettings) => void
    updateSetting: <T extends keyof UserSettings>(key: T, value: UserSettings[T]) => void
    /**
     * updateBatch is used when updating multiple settings.
     * This will prevent race conditions when persisting the profile to backend.
     * TODO: Solve this issue
     */
    updateBatch: (settings: Partial<UserSettings>) => void
  }

  favorites: {
    favorites: Favorite[]
    setFavorites: (favorites: Favorite[]) => void
    addFavorite: (favorite: Favorite) => void
    removeFavorite: (favorite: Favorite) => void
  }

  openedAlarms: {
    openedAlarms: OpenedAlarm[]
    pendingOpenedAlarms: OpenedAlarm[]
    setOpenedAlarms: (openedAlarms: OpenedAlarm[]) => void
    addOpenedAlarm: (openedAlarm: OpenedAlarm) => void
    addPendingOpenedAlarm: (openedAlarm: OpenedAlarm) => void
    commitPendingOpenedAlarms: () => void
  }

  subHeader: {
    defaultExpanded: SubHeaderDefaultExpanded
    setDefaultExpanded: (subHeaderKey: SubHeaderKey, value: boolean | undefined) => void
    clearDefaultExpanded: () => void
  }
}

const persistSettings =
  (config: StateCreator<UserProfileState, [], [['zustand/immer', never]], UserProfileState>) =>
  (set: SetState<UserProfileState>, get: GetState<UserProfileState>, api: StoreApi<UserProfileState>) =>
    config(
      args => {
        const oldState = get()
        set(args)

        const newState = get()

        if (newState.initial) {
          set({
            initial: false,
          })
          return
        }

        const newSettings = newState.settings.settings
        const oldSettings = oldState.settings.settings
        if (oldSettings !== newSettings && newState.hasLoadedSettings) {
          storeUserSettings(newSettings)
        }

        const newFavorites = newState.favorites.favorites
        const oldFavorites = oldState.favorites.favorites
        if (newFavorites !== oldFavorites && newState.hasLoadedFavorites) {
          storeFavorites(newFavorites)
        }

        const newOpenedAlarms = newState.openedAlarms
        const oldOpenedAlarms = oldState.openedAlarms
        if (oldOpenedAlarms !== newOpenedAlarms) {
          storeOpenedAlarms(newOpenedAlarms.openedAlarms, newOpenedAlarms.pendingOpenedAlarms)
        }

        const newSubHeaderExpanded = newState.subHeader.defaultExpanded
        const oldSubHeaderExpanded = oldState.subHeader.defaultExpanded
        if (oldSubHeaderExpanded !== newSubHeaderExpanded) {
          storeSubHeaderDefaultExpanded(newSubHeaderExpanded)
        }
      },
      get,
      api
    )

export const useUserProfileStore = create<UserProfileState>()(
  persistSettings(
    immer(set => ({
      initial: true,
      hasLoadedSettings: false,
      hasLoadedFavorites: false,
      setInitialSettings: settings =>
        set(state => {
          state.initial = true
          state.hasLoadedSettings = true
          state.settings.settings = settings
        }),
      setInitialFavorites: favorites =>
        set(state => {
          state.initial = true
          state.hasLoadedFavorites = true
          state.favorites.favorites = favorites
        }),
      setInitialOpenedAlarms: openedAlarms =>
        set(state => {
          state.initial = true
          state.openedAlarms.openedAlarms = openedAlarms
        }),
      setInitialSubHeaderDefaultExpanded: defaultExpanded => {
        set(state => {
          state.initial = true
          state.subHeader.defaultExpanded = defaultExpanded
        })
      },

      accessGroups: {
        accessGroups: [],
        setAccessGroups: accessGroups =>
          set(state => {
            state.accessGroups.accessGroups = accessGroups
          }),
      },

      settings: {
        settings: defaultUserSettings,
        setSettings: settings =>
          set(state => {
            state.settings.settings = settings
          }),
        updateSetting: (key, value) =>
          set(state => {
            state.settings.settings[key] = value
          }),
        updateBatch: settings =>
          set(state => {
            const writableSettings = state.settings.settings as any
            Object.entries(settings).forEach(([key, val]) => {
              writableSettings[key] = val
            })
          }),
      },

      favorites: {
        favorites: [],
        setFavorites: favorites =>
          set(state => {
            state.favorites.favorites = favorites
          }),
        addFavorite: (favorite: Favorite) =>
          set(state => {
            state.favorites.favorites.push(favorite)
          }),
        removeFavorite: (toRemove: Favorite) =>
          set(state => {
            state.favorites.favorites = state.favorites.favorites.filter(
              f => !(f.baneDataId === toRemove.baneDataId && (!f.assetType || f.assetType === toRemove.assetType))
            )
          }),
      },

      openedAlarms: {
        openedAlarms: [],
        pendingOpenedAlarms: [],
        setOpenedAlarms: openedAlarms =>
          set(state => {
            state.openedAlarms.openedAlarms = openedAlarms
          }),
        addOpenedAlarm: openedAlarm =>
          set(state => {
            state.openedAlarms.openedAlarms.push(openedAlarm)
          }),
        addPendingOpenedAlarm: openedAlarm =>
          set(state => {
            state.openedAlarms.pendingOpenedAlarms.push(openedAlarm)
          }),
        commitPendingOpenedAlarms: () =>
          set(state => {
            const opened = state.openedAlarms.openedAlarms
            const pending = state.openedAlarms.pendingOpenedAlarms
            opened.push(...pending.filter(pa => !alarmExists(pa, opened)))

            state.openedAlarms.openedAlarms = opened
            state.openedAlarms.pendingOpenedAlarms = []
          }),
      },

      subHeader: {
        defaultExpanded: {},
        setDefaultExpanded: (subHeaderKey, value) =>
          set(state => {
            if (typeof value === 'boolean') {
              state.subHeader.defaultExpanded[subHeaderKey] = value
            } else {
              delete state.subHeader.defaultExpanded[subHeaderKey]
            }
          }),
        clearDefaultExpanded: () => {
          set(state => {
            state.subHeader.defaultExpanded = {}
          })
        },
      },
    }))
  )
)
