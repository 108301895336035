import type { ChartProps, TooltipEntry } from 'src/Components/Chart/ChartRenderer'
import { ChartRenderer } from 'src/Components/Chart/ChartRenderer'

import type { Rect, NumberInterval } from 'src/Types/ChartTypes'
import type { Bender } from 'src/Types/Bender'
import type { SensorHistory } from 'src/Types/SensorTwin'
import type { SensorCode } from 'src/Types/SensorCode'

import { maxNum } from 'src/Utils/number'
import { formatNumber } from 'src/Utils/format'

const OPTIMIZE_DATA = true
const Y_AXIS_UNIT = 'V'
const Y_AXIS_UNIT_SCALED = ` ${Y_AXIS_UNIT}`

const parseValueHistory = (sensorHistory: SensorHistory, timeline: NumberInterval) => {
  const mapped = sensorHistory.values ? [...sensorHistory.values] : []

  if (typeof sensorHistory.currentAtFromTime === 'number') {
    mapped.unshift([timeline.start, sensorHistory.currentAtFromTime])
  }

  if (mapped.length) {
    // Extend each line to the end of the chart by duplicating the last point
    const lastEntry = mapped.slice(-1)[0]
    mapped.push([timeline.end, lastEntry[1]])
  }

  return !OPTIMIZE_DATA
    ? mapped
    : mapped.filter((entry, i, arr) => {
        // Always return the first and last entries
        if (i === 0 || i === arr.length - 1) {
          return true
        }

        // Optimize by skipping entries with the same measurement as the next.
        // If the rendered step algorithm is changed to curveStepAfter, this should
        // compare against the previous entry instead
        return entry[1] !== arr[i - 1][1]
      })
}

const formatTick = (unit: string, decimals: number, value: number) => `${formatNumber(value, decimals, false)} ${unit}`

const formatYTick = (value: number) => formatTick(Y_AXIS_UNIT_SCALED, 0, value)

const CHART_MARGIN: Rect = { top: 5, right: 0, bottom: 20, left: 40 }
const MAX_ZOOM_MAGIC_CONSTANT = 1000000 // This magic number results in a nice max zoom resolution

type BenderVoltageHistoryRendererProps = Omit<ChartProps, 'config'> & {
  getColorIndex?: (index: number) => number
}

export class BenderVoltageHistoryRenderer extends ChartRenderer {
  sensorHistory: SensorHistory[] = []

  seriesSensorCodes: SensorCode[] = []

  benders: Bender[] = []

  isMultipleBenderView: boolean = false

  getColorIndexFunc?: (index: number) => number

  constructor({ chart, timeline, classes, getColorIndex, onZoomUpdated }: BenderVoltageHistoryRendererProps) {
    super({
      chart,
      timeline,
      classes,
      config: {
        chartMargin: CHART_MARGIN,
        maxZoomMagicConstant: MAX_ZOOM_MAGIC_CONSTANT,
        linearYScale: true,
        useCanvas: false,
        formatYTick,
      },
      onZoomUpdated,
    })
    this.getColorIndexFunc = getColorIndex
  }

  getColorIndex(index: number) {
    if (typeof this.getColorIndexFunc === 'function') {
      return this.getColorIndexFunc(index)
    }
    return super.getColorIndex(index)
  }

  getDomain() {
    if (this.sensorHistory.length === 0) {
      return {
        xMin: this.timeline.start,
        xMax: this.timeline.end,
        yMax: 0,
        yMin: 1,
      }
    }

    const yVals = this.sensorHistory.flatMap(history => {
      if (history.values?.length) {
        return history.values.map(v => v[1])
      }

      return typeof history.currentAtFromTime === 'number' ? [history.currentAtFromTime] : []
    })

    return {
      xMin: this.timeline.start,
      xMax: this.timeline.end,
      yMax: maxNum(yVals),
      yMin: 0,
    }
  }

  getLabelForSeries(index: number) {
    return this.isMultipleBenderView ? this.sensorHistory[index].twinId : this.sensorHistory[index].codeSystem
  }

  setSeriesSensorCodes(seriesSensorCodes: SensorCode[]) {
    this.seriesSensorCodes = seriesSensorCodes
  }

  getTooltipText(entry: TooltipEntry) {
    const sensorCodeKey = this.isMultipleBenderView ? entry.label.split('.')[1] : entry.label
    const code = this.seriesSensorCodes.find(uc => uc.key === sensorCodeKey)
    const sensorName = this.benders.find(b => b.baneDataId === entry.label.split('.')[0])?.name
    const textLabel =
      this.isMultipleBenderView && sensorName
        ? `${sensorName} (${code?.localization.full[this.currentLanguage]})`
        : code?.localization.full[this.currentLanguage]

    return textLabel && code
      ? `${textLabel}: ${entry.value.toLocaleString('nb-NO')} ${code.metric.displayName}`
      : entry.value.toLocaleString('nb-NO')
  }

  setData(sensorHistory: SensorHistory[]) {
    this.sensorHistory = sensorHistory

    const dataCurves = sensorHistory.map(history => parseValueHistory(history, this.timeline))
    this.setDataCurves(dataCurves)
  }

  setBenders(benders: Bender[]) {
    this.benders = benders
    this.isMultipleBenderView = benders.length > 1
  }
}
