import type { AssetWithAlarms } from 'src/Types/AssetWithAlarms'
import type { SortOrder } from 'src/Types/SortOrder'
import type { OpenedAlarm } from 'src/Types/OpenedAlarm'
import type { Filter } from 'src/Types/Filter'
import { FilterType } from 'src/Types/Filter'
import { sortAssetsWithAlarms } from 'src/Components/SidePanelMenu/sortAssetsWithAlarms'
import { filterAssetsWithAlarms } from 'src/Features/Alarms/AlarmList/AlarmFilters/filterAssetsWithAlarms'

/**
 * Get filtered assets with alarms, plus the assets to show in the object filtering dropdown
 * (which are the assets that match the filters but definitively
 * disregard AssetId filtering - in case it is being used).
 * @param assetsWithAlarms Assets with alarms (AssetWithAlarms[] aka { Asset, Alarm[] })
 * @param sortOrder Current sort order
 * @param openedAlarms Opened alarms
 * @param filters Current filters
 * @param queryLimitOnlyAlarms Whether to limit the query to only assets with alarms
 * @param endOfSearchPeriod The end of the search period used for out of order periods filtering
 */
export const getFilteredAndAssetsForIdFiltering = (
  assetsWithAlarms: AssetWithAlarms[],
  sortOrder: SortOrder,
  openedAlarms: OpenedAlarm[],
  filters: Filter[],
  queryLimitOnlyAlarms: boolean,
  endOfSearchPeriod?: number
) => {
  const sortedAssetsWithAlarms = sortAssetsWithAlarms(assetsWithAlarms, sortOrder, openedAlarms)
  const filtersWithoutAssetId = filters.filter(f => f.type !== FilterType.AssetId)

  return {
    filteredAssetsWithAlarms: filterAssetsWithAlarms(filters, sortedAssetsWithAlarms, queryLimitOnlyAlarms, endOfSearchPeriod),
    assetsForIdFiltering: filterAssetsWithAlarms(
      filtersWithoutAssetId,
      sortedAssetsWithAlarms,
      queryLimitOnlyAlarms,
      endOfSearchPeriod
    ).map(a => a.asset),
  }
}
