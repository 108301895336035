import type { QueryStatus } from 'react-query'
import { useTranslation } from 'react-i18next'
import { lightFormat } from 'date-fns/lightFormat'
import { makeStyles } from 'tss-react/mui'
import Grid from '@mui/material/Grid'
import ClockIcon from '@mui/icons-material/ScheduleOutlined'
import { WiRaindrops, WiGaleWarning, WiHurricaneWarning } from 'weather-icons-react'
import CircularProgress from '@mui/material/CircularProgress'
import { isSameHour } from 'date-fns/isSameHour'

import { NoResult } from 'src/Components/NoResult'
import { WeatherIcon } from 'src/Features/Weather/WeatherIcon/WeatherIcon'
import { WindIcon } from 'src/Features/Weather/WindIcon/WindIcon'

import type { Weather } from 'src/Types/WeatherTypes'

import { getWindType } from 'src/Features/Weather/utils'
import { formatNumber } from 'src/Utils/format'
import { useBreakpointUp } from 'src/Hooks/useBreakpoint'
import themeColors from 'src/theme'

const useStyles = makeStyles()(theme => ({
  weather: {
    color: '#fff',
    padding: '2px 0 0',
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  clockIcon: {
    paddingRight: 4,
    verticalAlign: 'middle',
  },
  weatherGroup: {
    paddingLeft: 16,
    verticalAlign: 'middle',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 8,
    },
  },
  weatherIcon: {
    paddingLeft: 16,
    paddingRight: 4,
    verticalAlign: 'middle',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 4,
    },
  },
  rainIcon: {
    color: themeColors.weather.rain,
    verticalAlign: 'middle',
    margin: '-16px -10px -16px -16px',
  },
  windIcon: {
    verticalAlign: 'middle',
    margin: '-16px -16px -16px -6px',
  },
}))

type OwnProps = {
  weather?: Weather[]
  queryStatus: QueryStatus
}

export const TodaysWeather = ({ weather, queryStatus }: OwnProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const isMediaMD = useBreakpointUp('md')

  if (queryStatus === 'loading') {
    return <CircularProgress color="inherit" size={24} title={t('weather.loading')} />
  }

  if (!weather?.length && isMediaMD && ['success', 'error'].includes(queryStatus)) {
    return <NoResult>{t('weather.noneFound')}</NoResult>
  }

  const weatherNow = weather?.find(w => isSameHour(w.fromTime, Date.now()))

  if (queryStatus === 'idle' || !weatherNow) {
    return null
  }

  const windType = getWindType(weatherNow.windSpeed, weatherNow.windGust)
  const weatherNowTime = `${lightFormat(weatherNow.fromTime, 'H')} - ${lightFormat(weatherNow.toTime, 'H')}`

  return (
    <Grid item className={classes.weather}>
      {isMediaMD && (
        <>
          <ClockIcon className={classes.clockIcon} />
          {weatherNowTime}
        </>
      )}
      <span className={classes.weatherIcon}>
        <WeatherIcon weather={weatherNow} small />
      </span>
      {formatNumber(weatherNow.temperature)}°
      {isMediaMD && (
        <>
          <span className={classes.weatherGroup}>
            <WiRaindrops size={40} className={classes.rainIcon} />
            {formatNumber(weatherNow.precipitation)} {t('weather.mm')}
          </span>
          <span className={classes.weatherGroup}>
            <WindIcon windDegrees={weatherNow.windDirection} windSpeed={weatherNow.windSpeed} />{' '}
            {formatNumber(weatherNow.windSpeed)} {t('weather.metersPerSecond')}
            {windType === 'storm' && (
              <span title={t('weather.stormWarning')}>
                <WiHurricaneWarning size={40} color={themeColors.error} className={classes.windIcon} />
              </span>
            )}
            {windType === 'gale' && (
              <span title={t('weather.galeWarning')}>
                <WiGaleWarning size={40} color={themeColors.warning} className={classes.windIcon} />
              </span>
            )}
          </span>
        </>
      )}
    </Grid>
  )
}
