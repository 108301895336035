import { useTranslation } from 'react-i18next'
import CircularProgress from '@mui/material/CircularProgress'

import { AssetInformationEntry } from 'src/Components/AssetCard/AssetInformationEntry'
import { AlarmIndicator } from 'src/Components/AlarmIndicator'
import { AlarmMatrix } from './AlarmMatrix'
import type { MachineSwing } from 'src/Types/SwingTypes'
import type { NormalizedAlarm } from 'src/Types/NormalizedAlarm'
import type { SystemStatus } from 'src/Types/SystemStatus'

type OwnProps = {
  alarms: NormalizedAlarm[]
  pointMachineSwings: MachineSwing[]
  isLoading: boolean
}

export const SwingAlarmStatus = ({ alarms, pointMachineSwings, isLoading }: OwnProps) => {
  const { t } = useTranslation()

  const isRealTime = pointMachineSwings.some(swing => swing.swingAnalysis && swing.swingAnalysis.isRealTime)

  if (isLoading) {
    return (
      <AssetInformationEntry
        label={
          <>
            <CircularProgress color="inherit" size={15} /> {t('switches.statusLabels.alarms.loading')}
          </>
        }
      />
    )
  }

  if (alarms.length === 0) {
    const cardStatus = isRealTime ? 'Pending' : 'Ok'
    const pointMachineStatus: SystemStatus[] = pointMachineSwings.map(swing => (swing.isComplete ? cardStatus : 'Unknown'))

    const alarmText = isRealTime ? t('switches.statusLabels.alarms.pendingAlarms') : t('switches.statusLabels.alarms.noAlarms')

    return (
      <AssetInformationEntry
        label={alarmText}
        values={pointMachineStatus.map((alarmIndicator, i) => (
          <AlarmIndicator
            alarmIndicator={alarmIndicator}
            title={t('alarms.pointmachineAlarmInfo', {
              pointMachineName: pointMachineSwings[i].baneDataName,
              classification: alarmIndicator !== 'Unknown' ? alarmText : t('switches.statusLabels.alarms.unknown'),
            })}
          />
        ))}
      />
    )
  }

  return <AlarmMatrix alarms={alarms} pointMachineSwings={pointMachineSwings} />
}
