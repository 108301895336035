import { useEffect, useMemo, useReducer } from 'react'

import type {
  TrackCircuitCurrents,
  TrackCircuitCompressionRates,
  TrackCircuitCurrentType,
} from 'src/Types/TrackCircuitCurrentTypes'
import type { TimeSpan } from 'src/Types/LevelOfDetails'

import { useSensorData } from 'src/Hooks/NetworkData/SensorData/useSensorData'
import { hasOverlappedTimeSpan } from 'src/Utils/LevelOfDetails/timeSpan'
import { lodReducer, LodActionKind } from 'src/Hooks/NetworkData/SensorData/lodReducer'

type UseCompressedSensorDataProps = {
  trackCircuitBaneDataIds: string[]
  fromDate: number
  toDate: number
  currentType: TrackCircuitCurrentType | 'All'
  forcedCompressionRate?: TrackCircuitCompressionRates
  zoomFromDate: number | undefined
  zoomToDate: number | undefined
}

export const useCompressedSensorData = ({
  trackCircuitBaneDataIds,
  fromDate,
  toDate,
  currentType,
  zoomFromDate,
  zoomToDate,
  forcedCompressionRate,
}: UseCompressedSensorDataProps) => {
  const viewFromDate = zoomFromDate ? Math.min(Math.max(zoomFromDate, fromDate), toDate) : fromDate
  const viewToDate = zoomToDate ? Math.max(Math.min(zoomToDate, toDate), fromDate) : toDate

  const [lodStore, dispatch] = useReducer(lodReducer, {
    fromDate,
    toDate,
  })

  const {
    requestedSections,
    cachedSections,
    leveledMeasurements,
    sensorMetadata,
    queryCompressionRate,
    queryFromDate,
    queryToDate,
    requestedCompressionRate,
    calculatedCompressionRate,
    isQueryCloseToCurrentTime,
  } = lodStore

  const { sensorData, status } = useSensorData({
    trackCircuitBaneDataIds,
    fromDate: queryFromDate,
    toDate: queryToDate,
    currentType,
    compressionRate: queryCompressionRate,
    isQueryCloseToCurrentTime,
  })

  useEffect(() => {
    dispatch({ type: LodActionKind.DATE_RANGE, payload: { fromDate, toDate } })
  }, [fromDate, toDate, trackCircuitBaneDataIds])

  useEffect(() => {
    dispatch({ type: LodActionKind.FORCED_COMPRESSION_RATE, payload: { forcedCompressionRate } })
  }, [forcedCompressionRate])

  useEffect(() => {
    dispatch({ type: LodActionKind.NEW_SENSOR_DATA, payload: { sensorData, status } })
  }, [sensorData, status])

  useEffect(() => {
    dispatch({ type: LodActionKind.ZOOM, payload: { viewFromDate, viewToDate } })
  }, [viewFromDate, viewToDate])

  const usedCompressionRate = useMemo(() => {
    const fixedRequestedCompressionRate = requestedCompressionRate ?? 0
    const prioritizedCompressionRates = [...new Array(4 - fixedRequestedCompressionRate)].map(
      (_, i) => (i + fixedRequestedCompressionRate) as TrackCircuitCompressionRates
    )
    if (!prioritizedCompressionRates.includes(0)) {
      prioritizedCompressionRates.push(0)
    }
    if (typeof forcedCompressionRate !== 'undefined') {
      prioritizedCompressionRates.unshift(forcedCompressionRate)
    }
    const viewDateSpan: TimeSpan = [viewFromDate, viewToDate]
    const bestCompressionRate = prioritizedCompressionRates.find(prioritizedCompressionRate => {
      const leveledMeasurementElement = leveledMeasurements?.[prioritizedCompressionRate]
      const measurements = Object.values(leveledMeasurementElement || {})[0] || []
      return measurements.some(({ minDate, maxDate }) => hasOverlappedTimeSpan([minDate, maxDate], viewDateSpan))
    })
    if (typeof bestCompressionRate !== 'undefined') {
      return bestCompressionRate
    }
    return fixedRequestedCompressionRate
  }, [viewFromDate, viewToDate, forcedCompressionRate, leveledMeasurements, requestedCompressionRate])

  const mergedSensorData = useMemo<TrackCircuitCurrents[] | undefined>(() => {
    if (!sensorMetadata) {
      return undefined
    }
    const levelMeasurements = leveledMeasurements?.[usedCompressionRate]
    if (!levelMeasurements) {
      return undefined
    }
    return Object.entries(levelMeasurements)
      .filter(([trackCircuitBaneDataId]) => trackCircuitBaneDataId in sensorMetadata)
      .map(([trackCircuitBaneDataId, value]) => {
        const metadata = sensorMetadata?.[trackCircuitBaneDataId]!
        return {
          compressionRate: usedCompressionRate,
          baneDataLocationId: metadata.baneDataLocationId,
          baneDataLocationName: metadata.baneDataLocationName,
          tcId: metadata.tcId,
          trackCircuitBaneDataId: metadata.trackCircuitBaneDataId,
          station: metadata.station,
          rcMeasurements: value.flatMap(x => x.measurements?.rc || []),
          fcMeasurements: value.flatMap(x => x.measurements?.fc || []),
          missingRcMeasurements: value.flatMap(x => x.measurements?.missingRc || []),
          missingFcMeasurements: value.flatMap(x => x.measurements?.missingFc || []),
        }
      })
  }, [leveledMeasurements, sensorMetadata, usedCompressionRate])

  return {
    sensorData: mergedSensorData,
    status,
    calculatedCompressionRate,
    requestedCompressionRate,
    usedCompressionRate,
    queryCompressionRate,
    queryFromDate,
    queryToDate,
    viewFromDate,
    viewToDate,
    requestedSections,
    cachedSections,
  }
}
