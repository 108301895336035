export enum StorageValueType {
  String = 'string',
  Boolean = 'boolean',
  Number = 'number',
}

type StorageValueTypeMap = {
  [StorageValueType.String]: string
  [StorageValueType.Boolean]: boolean
  [StorageValueType.Number]: number
}

/**
 * Create a safe storage value that can be used to store and retrieve a value from localStorage or sessionStorage.
 */
export class SafeStorageValue<K extends StorageValueType, V extends StorageValueTypeMap[K]> {
  private readonly storage: Storage

  private readonly key: string

  private readonly type: K

  constructor(storage: Storage, key: string, type: K) {
    this.storage = storage
    this.key = key
    this.type = type
  }

  hasValue() {
    try {
      return typeof this.get() !== 'undefined'
    } catch (e) {
      return false
    }
  }

  get(): V | undefined {
    try {
      const value = this.storage.getItem(this.key)
      if (value === null) {
        return undefined
      }
      switch (this.type) {
        case StorageValueType.String: {
          return value as V
        }
        case StorageValueType.Boolean: {
          const boolVal = [true, false].find(boolVal => value === `${boolVal}`)
          return boolVal as V | undefined
        }
        case StorageValueType.Number: {
          const numberValue = parseInt(value, 10)
          return Number.isNaN(numberValue) ? undefined : (numberValue as V)
        }
        default: {
          return undefined
        }
      }
    } catch (e) {
      return undefined
    }
  }

  set(value: V) {
    try {
      this.storage.setItem(this.key, `${value}`)
    } catch (e) {
      // Ignore
    }
  }

  delete() {
    try {
      this.storage.removeItem(this.key)
    } catch (e) {
      // Ignore
    }
  }
}
