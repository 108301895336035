import type { TimeSpan } from 'src/Types/LevelOfDetails'

export const getQueryTimeSpan = ({
  cache,
  fromDate,
  toDate,
  zoomFromDate,
  zoomToDate,
}: {
  cache: TimeSpan[]
  fromDate: number
  toDate: number
  zoomFromDate: number
  zoomToDate: number
}) => {
  const duration = zoomToDate - zoomFromDate
  const extraStretch = Math.floor(duration / 2)
  const initialMinDate = zoomFromDate - extraStretch
  const initialMaxDate = zoomToDate + extraStretch
  let minDate = initialMinDate
  let maxDate = initialMaxDate
  let hasSplit = false

  cache?.forEach(([min, max]) => {
    if (hasSplit) {
      return
    }
    const minInside = min > minDate && min < maxDate
    const maxInside = max > minDate && max < maxDate
    if (minInside && maxInside) {
      minDate = initialMinDate
      maxDate = initialMaxDate
      hasSplit = true
      return
    }
    if (minInside) {
      maxDate = min
    }
    if (maxInside) {
      minDate = max
    }
  })

  minDate = Math.max(minDate, fromDate)
  maxDate = Math.min(maxDate, toDate)

  return {
    fromDate: minDate,
    toDate: maxDate,
  }
}
