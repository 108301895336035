import { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import { Alert } from 'src/Components/Alert'
import { BenderIsolationHistoryRenderer } from './benderIsolationHistoryRenderer'

import type { Bender } from 'src/Types/Bender'
import type { ChartFocusUpdatedEventHandler, ChartLabels } from 'src/Types/ChartTypes'
import type { DatePeriod } from 'src/Types/DatePeriod'
import { MonitoringSystem } from 'src/Types/MonitoringSystem'
import { useDomainConstantsStore } from 'src/Store/domainConstants'
import { useUserProfileStore } from 'src/Store/userProfile'
import { useChartStyles } from 'src/Components/Chart/chartStyles'
import { useBenderSensorHistory } from 'src/Hooks/NetworkData/useBenderSensorHistory'
import { useLanguage } from 'src/Hooks/useLanguage'
import { getDateRange } from 'src/Features/SelectedDatePeriod/predefinedDatePeriods'
import { ChartLegendsList } from 'src/Components/Chart/Legends/ChartLegendsList'
import { useBenderHistoryLegends } from 'src/Components/Chart/Legends/useBenderHistoryLegends'
import { useChartResizeBehaviour } from 'src/Hooks/useChartResizeBehaviour'
import { createPageLink } from 'src/Utils/pageLink'
import { LinkButton } from 'src/Components/LinkButton'
import { AppPage } from 'src/Types/AppPage'

const useStyles = makeStyles()(theme => ({
  container: {
    margin: 16,
    [theme.breakpoints.down('md')]: {
      margin: 4,
    },
    [theme.breakpoints.down('sm')]: {
      margin: 2,
    },
  },
  chart: {
    position: 'relative',
  },
}))

type OwnProps = {
  selectedBenders: Bender[]
  datePeriod: DatePeriod
  codeSystemKeys: string[]
  onZoomUpdated?: ChartFocusUpdatedEventHandler
  view: 'stream' | 'card'
}

const NUM_FULL_SCALE_SENSORS = 0
const DATA_POINT_THRESHOLD = 5 * 60 * 1000 // 5 minutes

export const BenderIsolationChart = ({ selectedBenders, datePeriod, codeSystemKeys, onZoomUpdated, view }: OwnProps) => {
  const { t, currentLanguage } = useLanguage()
  const { classes } = useStyles()
  const { classes: chartClasses } = useChartStyles()
  const chartRef = useRef<HTMLDivElement>(null)
  const location = useLocation()
  const isSignalEarthPage = location.pathname.startsWith('/signalearth')

  const sensorCodes = useDomainConstantsStore(state => state.sensorCodes.sensorCodes.SEF)
  const hideChartZoomButtons = useUserProfileStore(state => state.settings.settings.hideChartZoomButtons)
  const alarmTypes = useDomainConstantsStore(state => state.alarmTypes.alarms).filter(
    a => a.type.system === MonitoringSystem.SignalEarthFault
  )
  const [renderer, setRenderer] = useState<BenderIsolationHistoryRenderer>()
  const [inactiveSensors, setInactiveSensors] = useState<number[]>([])

  const selectedBenderIds = useMemo(() => selectedBenders.map(rs => rs.baneDataId), [selectedBenders])

  const { sensorHistory, status } = useBenderSensorHistory({
    baneDataIds: selectedBenderIds,
    codeSystemKeys,
    datePeriod,
  })

  const inactiveSensorsUpdated = (inactiveSensors: number[]) => {
    setInactiveSensors(inactiveSensors)
    if (renderer) {
      renderer.setInactiveSensors(inactiveSensors)
    }
  }

  useChartResizeBehaviour(renderer)

  const { fromDate, toDate } = useMemo(() => getDateRange(datePeriod), [datePeriod])

  useEffect(() => {
    const chart = chartRef.current
    if (chart && sensorHistory) {
      const labels: ChartLabels = {
        zoomIn: t('general.zoomIn'),
        zoomOut: t('general.zoomOut'),
        resetZoom: t('general.resetZoom'),
        // leftYAxisLabel: t('benders.isolationChart.leftYAxisLabel'),
        // rightYAxisLabel: t('benders.isolationChart.rightYAxisLabel'),
      }

      const seriesSensorCodes = sensorCodes.filter(uc => codeSystemKeys.includes(uc.key))

      const chartRenderer = new BenderIsolationHistoryRenderer({
        chart,
        timeline: {
          start: fromDate,
          end: toDate,
        },
        numFullScaleSensors: NUM_FULL_SCALE_SENSORS,
        classes: {
          tooltip: chartClasses.tooltip,
          tooltipArrow: chartClasses.tooltipArrow,
          zoomButton: chartClasses.zoomButton,
        },
        alarmTypes,
        onZoomUpdated,
      })
      setRenderer(chartRenderer)

      chartRenderer.setData(sensorHistory, [], DATA_POINT_THRESHOLD, ['Insulation_resistance'])
      chartRenderer.setInactiveSensors(inactiveSensors, false)
      chartRenderer.setBenders(selectedBenders)
      chartRenderer.setLabels(labels, seriesSensorCodes, currentLanguage)
      chartRenderer.setShowZoomButtons(!hideChartZoomButtons)
      chartRenderer.render()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensorHistory, currentLanguage, chartClasses, fromDate, toDate, sensorCodes, t])

  const legends = useBenderHistoryLegends({
    sensorHistory,
    inactiveSensors,
    domain: 'SEF',
    benders: selectedBenders,
    useBenderName: selectedBenders.length > 1,
  })

  if (status === 'idle') {
    return null
  }

  if (status === 'error') {
    return (
      <Alert severity="error" margin={16}>
        {t('benders.historyFetchError')}
      </Alert>
    )
  }

  return (
    <>
      {status === 'loading' && (
        <div className={classes.container}>
          <CircularProgress color="inherit" size={15} /> {t('benders.isolationChart.loading')}
        </div>
      )}
      {!sensorHistory?.length && status === 'success' && (
        <div className={classes.container}>{t('benders.isolationChart.noneFound')}</div>
      )}
      {!!sensorHistory?.length && (
        <div className={classes.container}>
          {view === 'card' && (
            <Box textAlign="right">
              <LinkButton
                href={createPageLink(AppPage.SignalEarthStream, { benderIds: sensorHistory[0].loggerBanedataId || '' })}
              >
                {isSignalEarthPage
                  ? t('general.openStreamView')
                  : `${t('general.goTo', { value: t('assetName.bender').toLowerCase() })} ${sensorHistory[0].loggerBanedataId}`}
              </LinkButton>
            </Box>
          )}
          <div className={classes.chart} ref={chartRef} />
          <ChartLegendsList
            legends={legends}
            inactiveIndexes={inactiveSensors}
            onInactiveIndexesUpdated={inactiveSensorsUpdated}
            emptyLegendsLabel={t('benders.isolationChart.noData')}
            loading={status === 'loading'}
          />
        </div>
      )}
    </>
  )
}
