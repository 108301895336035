import type { ReactNode } from 'react'
import { styled } from '@mui/material/styles'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

const StyledContainer = styled('div')`
  width: 100%;
`

const responsive = {
  xxlargedesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 20,
    slidesToSlide: 8,
  },
  xlargedesktop: {
    breakpoint: { max: 3000, min: 2450 },
    items: 19,
    slidesToSlide: 8,
  },
  largedesktop: {
    breakpoint: { max: 2450, min: 1920 },
    items: 15,
    slidesToSlide: 6,
  },
  desktop: {
    breakpoint: { max: 1920, min: 1500 },
    items: 11,
    slidesToSlide: 5,
  },
  smalldesktop: {
    breakpoint: { max: 1500, min: 1100 },
    items: 8,
    slidesToSlide: 5,
  },
  tablet: {
    breakpoint: { max: 1104, min: 960 },
    items: 7,
    slidesToSlide: 4,
  },
  smalltablet: {
    breakpoint: { max: 960, min: 800 },
    items: 5,
    slidesToSlide: 4,
  },
  xsmalltablet: {
    breakpoint: { max: 80, min: 500 },
    items: 3,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 500, min: 0 },
    items: 2,
    slidesToSlide: 2,
  },
}

export const MultiCarousel = ({ children }: { children: ReactNode }) => (
  <StyledContainer>
    <Carousel responsive={responsive} centerMode focusOnSelect={false}>
      {children}
    </Carousel>
  </StyledContainer>
)
