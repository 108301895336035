import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import AlertTitle from '@mui/material/AlertTitle'
import Collapse from '@mui/material/Collapse'

import { Alert } from 'src/Components/Alert'
import { ExpandCollapseButton } from 'src/Components/ExpandCollapseButton'
import { AlertInfo } from './AlertInfo'

import type { AlertSeverity } from 'src/Types/AlertSeverity'
import type { MuiAlertVariant } from 'src/Types/MuiTypes'
import type { SystemStatusAlert, SystemStatusSeverity } from 'src/Types/SystemStatusAlert'
import { useLogReactParams } from 'src/Hooks/useLogReactParams'

const SEVERITY_MAP: Record<SystemStatusSeverity, AlertSeverity> = {
  Fatal: 'error',
  Error: 'error',
  Warning: 'warning',
  Info: 'info',
}
const SEVERITY_ORDER = ['error', 'warning', 'info']

const getAlertSeverity = (alert: SystemStatusAlert) => SEVERITY_MAP[alert.severity]

const getHighestAlertSeverity = (systemStatusAlerts: SystemStatusAlert[]) => {
  const severities = systemStatusAlerts.map(getAlertSeverity)
  return severities.sort((a, b) => SEVERITY_ORDER.indexOf(a) - SEVERITY_ORDER.indexOf(b))[0]
}

type OwnProps = {
  systemStatusAlerts: SystemStatusAlert[]
}

export const AlertMessage = ({ systemStatusAlerts }: OwnProps) => {
  useLogReactParams('AlertMessage', { systemStatusAlerts })
  const { t } = useTranslation()
  const type: MuiAlertVariant = systemStatusAlerts.length === 1 ? 'standard' : 'outlined'

  let isDefaultExpanded = systemStatusAlerts.length > 1
  let isCollapsible = systemStatusAlerts.length > 1

  if (systemStatusAlerts.length === 1) {
    isDefaultExpanded = systemStatusAlerts[0].isDefaultExpanded
    isCollapsible = systemStatusAlerts[0].isCollapsible
  }

  const [expanded, setExpanded] = useState(isDefaultExpanded)

  return (
    <Alert
      severity={getHighestAlertSeverity(systemStatusAlerts)}
      variant={type}
      margin={16}
      action={
        isCollapsible && (
          <ExpandCollapseButton isExpanded={expanded} onToggleExpand={() => setExpanded(isExpanded => !isExpanded)} largeIcon />
        )
      }
    >
      <AlertTitle>
        <b>{systemStatusAlerts.length === 1 ? systemStatusAlerts[0].title : t('general.systemStatusAlert.title')}</b>
      </AlertTitle>
      <Collapse in={expanded} timeout="auto">
        {systemStatusAlerts.map((systemStatusAlert, index) => (
          <AlertInfo
            systemStatusAlert={systemStatusAlert}
            divider={index > 0 && index < systemStatusAlerts.length}
            textOnly={systemStatusAlerts.length === 1}
            severity={getAlertSeverity(systemStatusAlert)}
            key={`${systemStatusAlert.fromUtc}-${systemStatusAlert.toUtc}-${systemStatusAlert.title}`}
          />
        ))}
      </Collapse>
    </Alert>
  )
}
