import { useCallback, useMemo } from 'react'
import type { QueryStatus } from 'react-query'

import { FilterType } from 'src/Types/Filter'
import type { AssetWithAlarms } from 'src/Types/AssetWithAlarms'
import type { DatePeriod } from 'src/Types/DatePeriod'
import { MonitoringSystem } from 'src/Types/MonitoringSystem'

import { useTrackCircuitsWithAlarms } from './useTrackCircuitsWithAlarms'
import { usePointMachineSwitchesWithAlarms } from './usePointMachineSwitchesWithAlarms'
import { useMachineHeatSwitchesWithAlarms } from './useMachineHeatSwitchesWithAlarms'
import { useSwitchHeatCabinetsWithAlarms } from './useSwitchHeatCabinetsWithAlarms'
import { useBendersWithAlarms } from './useBendersWithAlarms'
import { useRacksWithAlarms } from './useRacksWithAlarms'
import { useNewAlarmSound } from 'src/Hooks/useNewAlarmSound'
import { getLeastAdvancedQueryStatus, hasAnySuccessfulQueryStatus } from 'src/Utils/network'
import { assetsWithAlarmsAreEqual } from 'src/Utils/alarms'
import { AssetType } from 'src/Types/AssetTypes'
import { useDerailerMachinesWithAlarms } from 'src/Hooks/NetworkData/useDerailerMachinesWithAlarms'
import { filterAssetsWithAlarms } from 'src/Features/Alarms/AlarmList/AlarmFilters/filterAssetsWithAlarms'
import { useTrackSearchEvent } from 'src/Hooks/NetworkData/useTrackSearchEvent'

/**
 * Filters using FilterType.Status the assetsWithAlarm
 * (which is an array with elements of type AssetWithAlarms
 * which is { Asset, Alarm[] }), and returns only
 * the AssetWithAlarms elements that have non-zero number of alarms.
 * @param assetsWithAlarms To be filtered
 */
const filterAlarms = (assetsWithAlarms: AssetWithAlarms[]) =>
  filterAssetsWithAlarms(
    [
      {
        type: FilterType.Status,
        values: ['Alarm'],
      },
    ],
    assetsWithAlarms,
    true
  )

const compareAlarms = (a: AssetWithAlarms[], b: AssetWithAlarms[]) => !assetsWithAlarmsAreEqual(filterAlarms(a), filterAlarms(b))

export type AggregatedAssetParams = {
  datePeriod?: DatePeriod
  idType: 'baneDataLocationIds' | 'baneDataIds'
  ids: string[]
  onlyWithAlarms?: boolean
  enabled?: boolean
  enableAllAlarmTypes: boolean
}

type UseAggregatedAssetsParams = AggregatedAssetParams & {
  alertOnNew: boolean
  assetTypes: AssetType[]
  onlyWithAlarms: boolean
}

export const useAggregatedAssets = ({ alertOnNew, assetTypes, onlyWithAlarms, ...params }: UseAggregatedAssetsParams) => {
  const { playAlarmSound } = useNewAlarmSound()

  const paramsWithAlarms = {
    ...params,
    onlyWithAlarms,
  }

  const {
    trackCircuitsWithAlarms = [],
    status: trackCircuitsQueryStatus,
    isFetching: isFetchingTrackCircuits,
    refetch: refreshTrackCircuits,
  } = useTrackCircuitsWithAlarms({
    ...paramsWithAlarms,
    enabled: assetTypes.includes(AssetType.TrackCircuit),
  })

  const {
    pointMachineSwitchesWithAlarms = [],
    status: pointMachineSwitchesQueryStatus,
    isFetching: isFetchingPointMachineSwitches,
    refetch: refreshPointMachineSwitches,
  } = usePointMachineSwitchesWithAlarms({
    ...paramsWithAlarms,
    enabled: assetTypes.includes(AssetType.SwitchPointMachine),
  })

  const {
    machineHeatSwitchesWithAlarms = [],
    status: machineHeatSwitchesQueryStatus,
    isFetching: isFetchingMachineHeatSwitches,
    refetch: refreshMachineHeatSwitches,
  } = useMachineHeatSwitchesWithAlarms({
    ...paramsWithAlarms,
    enabled: assetTypes.includes(AssetType.SwitchMachineHeat),
  })

  const {
    switchHeatCabinetsWithAlarms = [],
    status: cabinetsQueryStatus,
    isFetching: isFetchingCabinets,
    refetch: refreshCabinets,
  } = useSwitchHeatCabinetsWithAlarms({
    ...paramsWithAlarms,
    enabled: assetTypes.includes(AssetType.SwitchHeat),
  })

  const {
    bendersWithAlarms = [],
    status: bendersQueryStatus,
    isFetching: isFetchingBenders,
    refetch: refreshBenders,
  } = useBendersWithAlarms({
    ...paramsWithAlarms,
    enabled: assetTypes.includes(AssetType.Bender),
  })

  const {
    racksWithAlarms = [],
    status: racksQueryStatus,
    isFetching: isFetchingRacks,
    refetch: refreshRacks,
  } = useRacksWithAlarms({
    ...paramsWithAlarms,
    enabled: assetTypes.includes(AssetType.Rack),
  })

  const {
    derailersWithAlarms = [],
    status: derailersQueryStatus,
    isFetching: isFetchingDerailers,
    refetch: refreshDerailers,
  } = useDerailerMachinesWithAlarms({
    ...paramsWithAlarms,
    enabled: assetTypes.includes(AssetType.DerailerMachine),
  })

  const refetch = useCallback(() => {
    Promise.all([
      refreshTrackCircuits(),
      refreshPointMachineSwitches(),
      refreshMachineHeatSwitches(),
      refreshCabinets(),
      refreshBenders(),
      refreshRacks(),
      refreshDerailers(),
    ]).then(([trackCircuits, pointMachineSwitches, machineHeatSwitches, cabinets, benders, racks, derailers]) => {
      if (alertOnNew) {
        const alarmsChanged = [
          compareAlarms(trackCircuits.data || [], trackCircuitsWithAlarms),
          compareAlarms(pointMachineSwitches.data || [], pointMachineSwitchesWithAlarms),
          compareAlarms(machineHeatSwitches.data || [], machineHeatSwitchesWithAlarms),
          compareAlarms(cabinets.data || [], switchHeatCabinetsWithAlarms),
          compareAlarms(benders.data || [], bendersWithAlarms),
          compareAlarms(racks.data || [], racksWithAlarms),
          compareAlarms(derailers.data || [], derailersWithAlarms),
        ]

        if (alarmsChanged.includes(true)) {
          playAlarmSound()
        }
      }
    })
  }, [
    refreshTrackCircuits,
    refreshPointMachineSwitches,
    refreshMachineHeatSwitches,
    refreshCabinets,
    refreshBenders,
    refreshRacks,
    refreshDerailers,
    trackCircuitsWithAlarms,
    pointMachineSwitchesWithAlarms,
    machineHeatSwitchesWithAlarms,
    switchHeatCabinetsWithAlarms,
    bendersWithAlarms,
    racksWithAlarms,
    derailersWithAlarms,
    alertOnNew,
    playAlarmSound,
  ])

  const assetsWithAlarms = useMemo(
    () => [
      ...trackCircuitsWithAlarms,
      ...pointMachineSwitchesWithAlarms,
      ...machineHeatSwitchesWithAlarms,
      ...switchHeatCabinetsWithAlarms,
      ...bendersWithAlarms,
      ...racksWithAlarms,
      ...derailersWithAlarms,
    ],
    [
      pointMachineSwitchesWithAlarms,
      machineHeatSwitchesWithAlarms,
      trackCircuitsWithAlarms,
      switchHeatCabinetsWithAlarms,
      bendersWithAlarms,
      racksWithAlarms,
      derailersWithAlarms,
    ]
  )

  const statuses: Record<MonitoringSystem, QueryStatus> = {
    [MonitoringSystem.TrackCircuit]: trackCircuitsQueryStatus,
    [MonitoringSystem.PointMachine]: pointMachineSwitchesQueryStatus,
    [MonitoringSystem.MachineHeat]: machineHeatSwitchesQueryStatus,
    [MonitoringSystem.SwitchHeat]: cabinetsQueryStatus,
    [MonitoringSystem.SignalEarthFault]: getLeastAdvancedQueryStatus([bendersQueryStatus, racksQueryStatus]),
  }
  const statusList = [
    trackCircuitsQueryStatus,
    pointMachineSwitchesQueryStatus,
    machineHeatSwitchesQueryStatus,
    cabinetsQueryStatus,
    bendersQueryStatus,
    racksQueryStatus,
    derailersQueryStatus,
  ]
  const status = getLeastAdvancedQueryStatus(statusList)
  const anySuccessful = hasAnySuccessfulQueryStatus(statusList)

  useTrackSearchEvent({ status, resultCount: assetsWithAlarms.length })

  const fetchStatus = [
    isFetchingTrackCircuits,
    isFetchingPointMachineSwitches,
    isFetchingMachineHeatSwitches,
    isFetchingCabinets,
    isFetchingBenders,
    isFetchingRacks,
    isFetchingDerailers,
  ]

  return {
    status,
    assetsWithAlarms,
    isFetching: fetchStatus.some(Boolean),
    anySuccessful,
    statuses,
    refetch,
  }
}
