import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/MenuOutlined'
import { useUserProfileStore } from 'src/Store/userProfile'

const StyledIconButton = styled(IconButton)(
  ({ theme }) => `
  margin-right: ${theme.spacing(2)};
  padding: 0 5px;
  ${theme.breakpoints.up('md')} {
    margin-left: 16px;
  }
  .MuiSvgIcon-root {
    fill: #fff;
  }
`
)

export const MobileSideMenuButton = () => {
  const updateSetting = useUserProfileStore(state => state.settings.updateSetting)
  const isMenuOpen = useUserProfileStore(state => state.settings.settings.isMenuOpen)
  const toggleDrawer = () => updateSetting('isMenuOpen', !isMenuOpen)

  return (
    <StyledIconButton aria-label="Open menu" edge="start" onClick={toggleDrawer}>
      <MenuIcon fontSize="large" />
    </StyledIconButton>
  )
}
