import { FormGroup } from '@mui/material'
import { styled } from '@mui/system'

import type { WorkOrderFilter, WorkOrderCount } from 'src/Types/WorkOrderTypes'
import {
  WorkOrderDiscipline,
  CompletedWorkOrderStatus,
  WorkOrderFilterType,
  NonCompletedWorkOrderStatus,
  WorkOrderType,
} from 'src/Types/WorkOrderTypes'
import { WorkOrderFilterDropdown } from './WorkOrderFilterDropdown'

const StyledFormGroup = styled(FormGroup)`
  display: flex;
  flex-direction: column;
`

const StyledFiltersContainer = styled('div')`
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  flex-wrap: wrap;
`

const StyledFilterContainer = styled('div')`
  min-width: 100px;
  max-width: 150px;
  flex: auto;
`

type OwnProps = {
  filters: WorkOrderFilter[]
  counts: Record<WorkOrderFilterType, WorkOrderCount[]> | undefined
  completed?: boolean
  onFiltersUpdated: (filters: WorkOrderFilter[]) => void
}

export const WorkOrderFilters = ({ filters, counts, onFiltersUpdated, completed = false }: OwnProps) => {
  const filterUpdated = (filter: WorkOrderFilter) => {
    const updatedFilters = filters.filter(f => f.type !== filter.type)
    updatedFilters.push(filter)
    onFiltersUpdated(updatedFilters)
  }

  const getFilter = (type: string): WorkOrderFilter => {
    const filter = filters.find(f => f.type === type)
    return filter || ({ type, values: [] } as WorkOrderFilter)
  }

  const typeFilter = getFilter(WorkOrderFilterType.WorkType)
  const statusFilter = getFilter(WorkOrderFilterType.Status)
  const disciplineFilter = getFilter(WorkOrderFilterType.Discipline)

  return (
    <StyledFormGroup>
      <StyledFiltersContainer>
        <StyledFilterContainer>
          <WorkOrderFilterDropdown
            counts={counts?.workType}
            filter={typeFilter}
            options={Object.keys(WorkOrderType)}
            onFilterUpdated={filterUpdated}
          />
        </StyledFilterContainer>
        <StyledFilterContainer>
          <WorkOrderFilterDropdown
            counts={counts?.status}
            filter={statusFilter}
            options={Object.keys(completed ? CompletedWorkOrderStatus : NonCompletedWorkOrderStatus)}
            onFilterUpdated={filterUpdated}
          />
        </StyledFilterContainer>
        <StyledFilterContainer>
          <WorkOrderFilterDropdown
            counts={counts?.discipline}
            filter={disciplineFilter}
            options={Object.keys(WorkOrderDiscipline)}
            onFilterUpdated={filterUpdated}
          />
        </StyledFilterContainer>
      </StyledFiltersContainer>
    </StyledFormGroup>
  )
}
