import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import { isToday } from 'date-fns/isToday'
import { isSameDay } from 'date-fns/isSameDay'
import { isSameHour } from 'date-fns/isSameHour'
import { setHours } from 'date-fns/setHours'

import { WeatherIcon } from 'src/Features/Weather/WeatherIcon/WeatherIcon'
import { Temperature } from './Temperature'
import { RainWind } from './RainWind'

import type { Weather } from 'src/Types/WeatherTypes'

import { formatLocalWeekdayAndDate } from 'src/Utils/format'
import { ignoreStyleProps } from 'src/Utils/style'
import { useLanguage } from 'src/Hooks/useLanguage'
import themeColors from 'src/theme'

const StyledCard = styled('div', ignoreStyleProps('isToday'))<{ isToday: boolean }>`
  ${props =>
    props.isToday
      ? `
    background-color: #fff;
    color: ${themeColors.primary};
  `
      : `
    background-color: ${themeColors.primary};
    color: #fff;
  `}
  padding: 5px;
  border: 2px solid ${themeColors.primary};
  min-width: 120px;
  min-height: 165px;
`

const StyledRainWindContainer = styled(Grid)`
  width: 100%;
`
const StyledGridWithBorder = styled(Grid)`
  border-bottom: 1px solid ${themeColors.info};
`

type OwnProps = {
  weather: Weather[]
  day: Date
}

export const WeatherTimelineCard = ({ weather, day }: OwnProps) => {
  const { currentLanguage } = useLanguage()

  const weatherOnDay = weather.filter(w => isSameDay(w.fromTime, day))

  if (!weatherOnDay.length) {
    return null
  }

  const weatherAtNoon = weatherOnDay.find(w => isSameHour(w.fromTime, setHours(day, 12))) || weatherOnDay[0]

  const getIsToday = () => {
    return isToday(weatherOnDay[0].fromTime)
  }

  return (
    <StyledCard isToday={getIsToday()}>
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid item mb={1}>
          {formatLocalWeekdayAndDate(weatherOnDay[0].toTime, currentLanguage)}
        </Grid>
        <Grid item mb={1}>
          <WeatherIcon weather={weatherAtNoon} onWhiteBackground={isToday(weatherAtNoon.fromTime)} />
        </Grid>
        <StyledGridWithBorder item>
          <Temperature weather={weatherOnDay} isToday={getIsToday()} />
        </StyledGridWithBorder>
        <StyledRainWindContainer item>
          <RainWind weather={weatherOnDay} />
        </StyledRainWindContainer>
      </Grid>
    </StyledCard>
  )
}
