import type { Asset, AssetType } from 'src/Types/AssetTypes'

/**
 * CardId is the unique string used in the url as direct link to a specific card.
 * Format example: card_KO-SPV-005467-switch-MachineHeat
 */

export const getCardId = (baneDataId: string, assetType: AssetType) => {
  const parts = [baneDataId, assetType]
  return `card_${parts.join('-')}`
}

export const getCardIdByAsset = (asset: Asset) => getCardId(asset.baneDataId, asset.assetType)
