import { useEffect } from 'react'
import { useFeatureOverride } from 'src/Hooks/useFeatureOverride'
import { SessionFeatures } from 'src/Hooks/useSessionFeature'

const debugLogReactParams = false

const logReactParams = (reactContext: string, yourArgs: Record<string, any>, logReactParamsEnabled: boolean): void => {
  if (!logReactParamsEnabled) {
    return
  }
  if (!yourArgs) {
    if (!debugLogReactParams) {
      return
    }
    console.log('Usage: You must pass 2(-3) arguments to useLogReactParams')
  }
  console.log(`dbg-${reactContext}`, yourArgs)
}

export const useLogReactParams = (reactContext: string, yourArgs: Record<string, any>, forceEnable: boolean = false) => {
  const logReactParamsEnabled = useFeatureOverride(SessionFeatures.LogReactParams) || forceEnable
  useEffect(() => {
    logReactParams(reactContext, yourArgs, logReactParamsEnabled)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reactContext, ...Object.keys(yourArgs), ...Object.values(yourArgs), logReactParamsEnabled])
}
