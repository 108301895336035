import * as RemoteSettings from 'src/Providers/Settings/RemoteSettings'
import { trackException } from 'src/Tracking/trackException'
import type { PageFilters } from 'src/Types/PageFilters'

export const getPageFilters = async () => {
  let remoteFavorites: PageFilters[] | undefined
  try {
    remoteFavorites = await RemoteSettings.getPageFilters()
  } catch (e) {
    trackException(e)
    remoteFavorites = undefined
  }

  return remoteFavorites || []
}

export const storePageFilters = async (filters: PageFilters[]) => {
  try {
    await RemoteSettings.storePageFilters(filters)
  } catch (e) {
    trackException(e)
    throw e
  }
}
