import { styled } from '@mui/material/styles'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import { CellEntry } from 'src/Components/CellEntry'

import { formatLocalDateTime } from 'src/Utils/format'

import { useBreakpointDown } from 'src/Hooks/useBreakpoint'
import { useLanguage } from 'src/Hooks/useLanguage'

import themeColors from 'src/theme'

import type { WindowStatus } from 'src/Types/AssetTypes'

const StyledDescriptionLink = styled('a')`
  font-size: 14px;
  color: ${themeColors.linkText};
`

type OwnProps = {
  startTime: number
  endTime: number
  cause: string
  status: WindowStatus
  url?: string | null
}

const getFallbackUrl = (status: WindowStatus) => {
  switch (status) {
    case 'Maintenance':
    case 'Out_of_Order':
    case 'NoContact':
      return 'https://banenor.sharepoint.com/sites/DT_Sviktmodebibliotek/SitePages/ustand.aspx'

    case 'IrregularData':
      return 'https://banenor.sharepoint.com/sites/DT_Sviktmodebibliotek/SitePages/Irregul%C3%A6rData.aspx'

    default:
      return 'https://banenor.sharepoint.com/sites/DT_Sviktmodebibliotek'
  }
}

export const StatusWindowRow = ({ startTime, endTime, cause, status, url }: OwnProps) => {
  const { t } = useLanguage()
  const isMediaSM = useBreakpointDown('sm')

  return (
    <TableRow>
      {isMediaSM ? (
        <TableCell padding="checkbox">
          {startTime && CellEntry(`${formatLocalDateTime(startTime)} -`)}
          {endTime < Date.now() ? CellEntry(formatLocalDateTime(endTime)) : t('statusWindow.noEndDate')}
        </TableCell>
      ) : (
        <>
          <TableCell padding="checkbox">{startTime && CellEntry(formatLocalDateTime(startTime))}</TableCell>
          <TableCell padding="checkbox">
            {CellEntry(endTime < Date.now() ? formatLocalDateTime(endTime) : t('statusWindow.noEndDate'))}
          </TableCell>
        </>
      )}
      <TableCell padding="checkbox">{CellEntry(cause, t('statusWindow.causeHeader'))}</TableCell>
      <TableCell padding="checkbox" title={t('statusWindow.descriptionAndActionsUrl.long')}>
        <StyledDescriptionLink href={url ?? getFallbackUrl(status)} target="_blank" rel="noopener noreferrer">
          <OpenInNewIcon fontSize="small" />
        </StyledDescriptionLink>
      </TableCell>
    </TableRow>
  )
}
