import { useMemo } from 'react'

import { StreamContainer } from 'src/Features/SignalEarthFaults/StreamView/StreamContainer'
import { RackSectionSelector } from 'src/Features/SignalEarthFaults/StreamView/RackSectionStream/RackSectionSelector'
import { RackSectionStreamCard } from 'src/Features/SignalEarthFaults/StreamView/RackSectionStream/RackSectionStreamCard'

import type { AssetWithAlarms } from 'src/Types/AssetWithAlarms'
import type { Rack, RackSectionSensor } from 'src/Types/Rack'

import { useTranslation } from 'react-i18next'

type OwnProps = {
  racksWithAlarms: AssetWithAlarms[]
}

const sortRackSections = (rackSections: RackSectionSensor[]) =>
  rackSections.sort((a, b) => a.parentId.localeCompare(b.parentId) || a.name.localeCompare(b.name))

export const RackSectionStream = ({ racksWithAlarms }: OwnProps) => {
  const { t } = useTranslation()

  const racks = racksWithAlarms.map(r => r.asset) as Rack[]
  const sortedRackSections = useMemo(() => sortRackSections(racks.flatMap(r => r.sectionSensors)), [racks])
  const validRackSectionIds = useMemo(() => sortedRackSections.map(section => section.baneDataId), [sortedRackSections])

  return (
    <StreamContainer
      queryStateKey="rsIds"
      noneFoundText={t('racks.noneFound')}
      validIds={validRackSectionIds}
      renderSelectorComponent={({ pendingIds, onSelectionUpdated }) => (
        <RackSectionSelector
          rackSections={sortedRackSections}
          pendingRackSectionIds={pendingIds}
          onSelectionUpdated={onSelectionUpdated}
        />
      )}
      renderContentComponent={({ selectedIds, dateRange }) => (
        <RackSectionStreamCard
          selectedFromDate={dateRange.fromDate}
          selectedToDate={dateRange.toDate}
          selectedRackSections={sortedRackSections.filter(section => selectedIds.includes(section.baneDataId))}
        />
      )}
    />
  )
}
