import { styled } from '@mui/material/styles'
import InputLabel from '@mui/material/InputLabel'
import { ListItemText, MenuItem, FormControl, Select } from '@mui/material'
import type { SelectChangeEvent } from '@mui/material/Select'
import type { ManualAssetStatusCause } from 'src/Features/ManualAssetStatus/types'
import { useLanguage } from 'src/Hooks/useLanguage'
import type { AssetStatusEntityStatus } from 'src/Types/AssetStatusEntry'

const StyledFormControl = styled(FormControl)`
  width: 250px;

  .MuiSelect-select {
    display: block;
    padding-right: 2em;
  }
`

const StyledInputLabel = styled(InputLabel)`
  padding-left: 1em;
`

type OwnProps = {
  status: AssetStatusEntityStatus
  availableCauses: ManualAssetStatusCause[]
  onChange: (cause: number | undefined) => void
  selectedCause: number | undefined
  disabled: boolean
}

export const ManualAssetStatusCauseSelector = ({ status, availableCauses, selectedCause, onChange, disabled }: OwnProps) => {
  const { t } = useLanguage()

  const handleChange = (event: SelectChangeEvent<number>) => {
    const { value } = event.target
    onChange(typeof value === 'number' ? value : undefined)
  }

  const renderValue = (selected: number) => availableCauses.find(r => selected === r.id)?.name

  return (
    <StyledFormControl>
      <StyledInputLabel>{t('manualAssetStatus.cause')}</StyledInputLabel>
      <Select
        value={typeof selectedCause === 'number' ? selectedCause : ''}
        label={t('manualAssetStatus.cause')}
        onChange={handleChange}
        renderValue={renderValue}
        disabled={disabled}
        variant="outlined"
      >
        {availableCauses.map(({ id, name }) => {
          return (
            <MenuItem value={id} key={`${status}-${id}`}>
              <ListItemText primary={name} />
            </MenuItem>
          )
        })}
      </Select>
    </StyledFormControl>
  )
}
