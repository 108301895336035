import type { SwingsContract, SwingContract } from 'src/Providers/Contracts/SwingsContract'
import type { SwingsWithStatistics } from 'src/Types/SwingEntry'

const mapSwingContract = (swing: SwingContract) => {
  const { machineBanedataIds, direction, swingId, timestamp, status, alarmTypeIds } = swing

  return {
    machineBaneDataIds: machineBanedataIds || [],
    direction,
    swingId,
    timestamp,
    status,
    alarmTypeIds,
  }
}

export const mapSwingsContract = (swingsWithStatistics: SwingsContract): SwingsWithStatistics => {
  const { isSwingsGrouped, swings, machinesInSwitch, statistics } = swingsWithStatistics
  return {
    isSwingsGrouped,
    swings: swings.map(mapSwingContract),
    statistics,
    machinesInSwitch: machinesInSwitch.map(({ banedataId, name, machineType, installDate }) => ({
      baneDataId: banedataId,
      name,
      machineType,
      installDate,
    })),
  }
}
