import type { WorkOrder, WorkOrderFilter } from 'src/Types/WorkOrderTypes'
import { WorkOrderFilterType } from 'src/Types/WorkOrderTypes'

const compareStrings = (a: string, b: string): boolean => {
  return a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0
}

export const filterWorkOrders = (workOrders: WorkOrder[], filters: WorkOrderFilter[]) => {
  const filtered: WorkOrder[] = workOrders.filter(wo => {
    return filters.every(filter => {
      if (filter.values.length) {
        switch (filter.type) {
          case WorkOrderFilterType.Status: {
            return filter.values.find(f => compareStrings(f, wo.status))
          }
          case WorkOrderFilterType.WorkType: {
            return filter.values.find(f => compareStrings(f, wo.workType))
          }
          case WorkOrderFilterType.Discipline: {
            return filter.values.find(f => wo.discipline && compareStrings(f, wo.discipline))
          }
          default:
            return false
        }
      } else {
        return true
      }
    })
  })

  return filtered
}
