import { getPointMachineAlarmsForSwing } from 'src/Providers/PointMachineAlarms'
import { useQueryWithErrorHandling } from './useQueryWithErrorHandling'

export const usePointMachineAlarms = (swingId: string) => {
  const query = useQueryWithErrorHandling(
    ['pointMachineAlarms', { swingId }],
    () => getPointMachineAlarmsForSwing(swingId),
    {},
    'alarms.fetchError'
  )

  return {
    ...query,
    alarms: query.data,
  }
}
