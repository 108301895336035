import type { Favorite } from 'src/Types/Favorite'
import * as RemoteSettings from 'src/Providers/Settings/RemoteSettings'
import { trackException } from 'src/Tracking/trackException'

export const getFavorites = async () => {
  let remoteFavorites: Favorite[] | undefined
  try {
    remoteFavorites = await RemoteSettings.getRemoteFavorites()
  } catch (e) {
    trackException(e)
    remoteFavorites = undefined
  }

  return remoteFavorites || []
}

export const storeFavorites = async (favorites: Favorite[]) => {
  try {
    await RemoteSettings.storeRemoteFavorites(favorites)
  } catch (e) {
    trackException(e)
  }
}
