import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { makeStyles } from 'tss-react/mui'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import ArrowDownIcon from '@mui/icons-material/ArrowDownwardOutlined'

import { Button } from 'src/Components/Button'
import { Checkbox } from 'src/Components/Checkbox'
import { NeighborsIcon } from 'src/Components/NeighborsIcon'
import { RackSectionInfoPopper } from './RackSectionInfoPopper'

import type { RackSectionSensor } from 'src/Types/Rack'
import type { PopperReferenceObject } from 'src/Types/PopperReferenceObject'

import { useBreakpointDown } from 'src/Hooks/useBreakpoint'
import { flexibleTranslation } from 'src/Utils/language'
import themeColors from 'src/theme'

type HoveredRackSection = {
  element: PopperReferenceObject
  rackSection: RackSectionSensor
}

const useStyles = makeStyles()(theme => ({
  chipContainer: {
    display: 'flex',
  },
  neighborsButton: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 8,
      padding: 2,
    },
  },
  neighborsIcon: {
    marginLeft: 4,
    cursor: 'pointer',
    '& .disabled': {
      cursor: 'inherit',
    },
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
    },
    '& .MuiIconButton-root': {
      marginTop: -5,
    },
  },
  arrowDownIcon: {
    borderRadius: 25,
    padding: 5,
    color: '#fff',
    backgroundColor: themeColors.secondary,
    '&:disabled': {
      backgroundColor: themeColors.disabled,
      color: themeColors.disabledText,
    },
    '&:hover': {
      backgroundColor: themeColors.tertiary,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  dropdown: {
    width: '100%',
  },
  dropdownOption: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
}))

type OwnProps = {
  rackSections: RackSectionSensor[]
  pendingRackSectionIds: string[]
  onSelectionUpdated: (selected: string[]) => void
}

export const RackSectionSelector = ({ rackSections, pendingRackSectionIds, onSelectionUpdated }: OwnProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const isMediaSM = useBreakpointDown('sm')

  const [hoveredRackSection, setHoveredRackSection] = useState<HoveredRackSection>()

  const removeRackSection = (rsBaneDataId: string) =>
    onSelectionUpdated(pendingRackSectionIds.filter(otherId => otherId !== rsBaneDataId))

  const showRackSectionInfo = (rsBaneDataId: string, element: PopperReferenceObject) => {
    const rackSection = rackSections.find(rs => rs.baneDataId === rsBaneDataId)
    if (rackSection) {
      setHoveredRackSection({
        element,
        rackSection,
      })
    }
  }

  const hideRackSectionInfo = () => setHoveredRackSection(undefined)

  const toggleRackSectionInfo = (rsBaneDataId: string, element: PopperReferenceObject) => {
    if (hoveredRackSection?.rackSection.baneDataId === rsBaneDataId) {
      hideRackSectionInfo()
    } else {
      showRackSectionInfo(rsBaneDataId, element)
    }
  }

  const onAddRackSections = (ids: string[]) => {
    onSelectionUpdated([...pendingRackSectionIds, ...ids])
  }

  const neighborsIconButton = (rackSection: RackSectionSensor, showText: boolean = false) => {
    const neighborIds = rackSection.neighbors.map(n => n.baneDataId)
    const hasNeighbors = neighborIds.length > 0
    const canAddNeighbors = neighborIds.some(neighborId => !pendingRackSectionIds.includes(neighborId))

    const disabledText = hasNeighbors ? t('racks.allNeighborsAlreadyAdded') : t('racks.chart.noNeighbors')

    const icon = (
      <NeighborsIcon
        className={clsx(classes.neighborsIcon, {
          disabled: !canAddNeighbors,
        })}
        title={canAddNeighbors ? t('racks.addNeighbors') : disabledText}
        color={canAddNeighbors ? themeColors.primary : themeColors.disabled}
        onClick={() => {
          if (canAddNeighbors) {
            onAddRackSections(neighborIds)
          }
        }}
      />
    )

    if (!showText) {
      return icon
    }

    let neighborsButtonText = disabledText
    if (canAddNeighbors) {
      neighborsButtonText = t(flexibleTranslation('racks.addNeighbors', isMediaSM))

      const selfAlreadyAdded = pendingRackSectionIds.includes(rackSection.baneDataId)
      if (!selfAlreadyAdded) {
        neighborsButtonText += t('racks.andRack', { rack: rackSection.name })
      }
    }

    return (
      <Button
        variant="outlined"
        buttonClass={classes.neighborsButton}
        size={isMediaSM ? 'small' : 'medium'}
        disabled={!canAddNeighbors}
        onClick={event => {
          if (canAddNeighbors) {
            event.stopPropagation()
            const idsToAdd = neighborIds.concat(rackSection.baneDataId)
            onAddRackSections(idsToAdd)
          }
        }}
      >
        {neighborsButtonText}
        {icon}
      </Button>
    )
  }

  const renderTags = (selected: string[]) =>
    selected.map(baneDataId => {
      const rs = rackSections.find(rs => rs.baneDataId === baneDataId)

      if (!rs) {
        return <span key={baneDataId} />
      }

      return (
        <span key={baneDataId} className={classes.chipContainer}>
          <Chip
            label={rs.name}
            size="small"
            icon={
              <InfoIcon
                color="primary"
                onMouseEnter={event => {
                  showRackSectionInfo(rs.baneDataId, event.currentTarget)
                }}
                onMouseLeave={hideRackSectionInfo}
                onClick={event => {
                  event.stopPropagation()
                  toggleRackSectionInfo(rs.baneDataId, event.currentTarget)
                }}
              />
            }
            onDelete={() => removeRackSection(baneDataId)}
          />
          {neighborsIconButton(rs)}
        </span>
      )
    })

  const filterOptions = (options: string[], { inputValue }: { inputValue?: string }) => {
    if (options.length === 0 || inputValue) {
      return inputValue
        ? options.filter(baneDataId => {
            const rs = rackSections.find(rs => rs.baneDataId === baneDataId)
            return !rs || rs.name.toLowerCase().includes(inputValue.toLowerCase())
          })
        : options
    }
    return [t('general.selectAll'), ...options]
  }

  return (
    <>
      <Autocomplete
        id="racks-dropdown"
        className={classes.dropdown}
        multiple
        disableCloseOnSelect
        autoComplete
        autoHighlight
        options={rackSections.map(rs => rs.baneDataId)}
        filterOptions={filterOptions}
        groupBy={option => rackSections.find(rs => rs.baneDataId === option)?.parentId || ''}
        value={pendingRackSectionIds}
        onChange={(event, values) => {
          const isSelectAll = values.some(baneDataId => baneDataId === t('general.selectAll'))
          if (isSelectAll) {
            if (rackSections.length === pendingRackSectionIds.length) {
              onSelectionUpdated([])
            } else {
              onSelectionUpdated(rackSections.map(rs => rs.baneDataId))
            }
          } else {
            onSelectionUpdated(values)
          }
        }}
        popupIcon={<ArrowDownIcon className={classes.arrowDownIcon} />}
        renderTags={renderTags}
        renderOption={(props, option, { selected }) => {
          const isSelectAll = option === t('general.selectAll')
          const rs = rackSections.find(t => t.baneDataId === option)
          const checked = selected || (isSelectAll && rackSections.length === pendingRackSectionIds.length)

          return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <li {...props}>
              <span className={classes.dropdownOption}>
                <Checkbox checked={checked} />
                {isSelectAll ? t('general.selectAll') : rs?.name}
              </span>
              {!!rs?.neighbors.length && neighborsIconButton(rs, true)}
            </li>
          )
        }}
        renderInput={params => (
          <TextField
            {...params} // eslint-disable-line react/jsx-props-no-spreading
            placeholder={t('general.searchPlaceholder')}
            label={t('racks.racksDropdown')}
            className={classes.textField}
            variant="outlined"
          />
        )}
      />
      {hoveredRackSection && (
        <RackSectionInfoPopper rackSection={hoveredRackSection.rackSection} anchorElement={hoveredRackSection.element} />
      )}
    </>
  )
}
