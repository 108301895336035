import { useTranslation } from 'react-i18next'
import { AssetInformationEntry } from 'src/Components/AssetCard/AssetInformationEntry'
import { PointMachineInfo } from 'src/Features/Switches/SwitchCard/SwingInfo/Maintenance/PointMachineInfo'
import { formatLocalDateTime } from 'src/Utils/format'
import type { MachineSwing } from 'src/Types/SwingTypes'
import type { MachineInSwitch } from 'src/Types/SwingEntry'

type OwnProps = {
  machines: (MachineInSwitch | MachineSwing)[] | undefined
}

export const MachinesBasicInfo = ({ machines }: OwnProps) => {
  const { t } = useTranslation()

  if (!machines?.length) {
    return null
  }

  return (
    <>
      <PointMachineInfo pointMachineTypes={machines.map(s => (s.machineType ? s.machineType : t('general.unknown')))} />
      <AssetInformationEntry label={t('general.baneDataId')} values={machines.map(s => s.baneDataId)} />
      <AssetInformationEntry
        label={t('general.installDate')}
        values={machines.map(s => (s.installDate ? formatLocalDateTime(s.installDate, true) : t('general.unknown')))}
      />
    </>
  )
}
