import React, { useEffect, useState } from 'react'
import { MsalProvider, MsalAuthenticationTemplate } from '@azure/msal-react'
import type { PublicClientApplication } from '@azure/msal-browser'
import { InteractionType } from '@azure/msal-browser'
import { styled } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import { Button } from 'src/Components/Button'

import { App } from './App'

import { createInstance } from './msGraphClient'
import { useTranslation } from 'react-i18next'
import { setTelemetryRequestTrackingDisabled, storeRedirectPath } from 'src/Providers/SessionSettings'
import { basePath } from 'src/Utils/basePath'
import { trackDurationSinceScriptEval } from 'src/Tracking/trackMetric'

const StyledSpinner = styled('div')`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

export const AppTimedOutComponent = () => {
  const { t } = useTranslation()

  const reloadApp = () => {
    setTelemetryRequestTrackingDisabled()
    document.location.reload()
  }

  return (
    <StyledSpinner>
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid item>
          <CircularProgress disableShrink />
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={reloadApp}>
            {t('reloadOnTimedOutButton')}
          </Button>
        </Grid>
      </Grid>
    </StyledSpinner>
  )
}

export const InProgressComponent = () => (
  <StyledSpinner>
    <Grid container direction="column" justifyContent="center" alignItems="center">
      <Grid item>
        <CircularProgress disableShrink />
      </Grid>
    </Grid>
  </StyledSpinner>
)

const AppWrapper = ({ msalInstance, loadingText }: { msalInstance: PublicClientApplication; loadingText: string }) => {
  const [loggedIn, setLoggedIn] = useState(false)
  useEffect(() => {
    const waitForLoginRedirect = async () => {
      await msalInstance.handleRedirectPromise()
      setLoggedIn(true)

      trackDurationSinceScriptEval('app-authenticated')
    }

    waitForLoginRedirect()
  }, [msalInstance])

  return loggedIn ? <App /> : <InProgressComponent />
}

const ErrorComponent = ({ error }: any) => {
  const { t } = useTranslation()

  return (
    <h5>
      {t('authError')}: <strong>{error.errorCode}</strong>
    </h5>
  )
}

export const Auth = () => {
  const msalInstance = createInstance()
  const { t } = useTranslation()

  if (!msalInstance) {
    return null
  }

  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        loadingComponent={InProgressComponent}
        errorComponent={ErrorComponent}
      >
        <AppWrapper msalInstance={msalInstance} loadingText={t('loadingApp')} />
      </MsalAuthenticationTemplate>
    </MsalProvider>
  )
}

storeRedirectPath(basePath)
