import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreOutlined'
import ExpandLessIcon from '@mui/icons-material/ExpandLessOutlined'

import { ignoreStyleProps } from 'src/Utils/style'
import themeColors from 'src/theme'

type StyleProps = {
  invertColor?: boolean
  uppercase?: boolean
}

const StyledIconButton = styled(IconButton, ignoreStyleProps('invertColor', 'uppercase'))<StyleProps>`
  border-radius: 25px;
  padding: 0 ${props => props.theme.spacing(1)};
  margin: 0;
  font-size: 12px;
  color: ${props => (props.invertColor ? '#fff' : themeColors.text)};
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
`

type OwnProps = StyleProps & {
  isExpanded: boolean
  onToggleExpand: () => void
  largeIcon?: boolean
  text?: string
  disabled?: boolean
}

export const ExpandCollapseButton = ({
  isExpanded,
  onToggleExpand,
  largeIcon = false,
  invertColor = false,
  text,
  disabled = false,
  uppercase = false,
}: OwnProps) => {
  const { t } = useTranslation()

  const fontSize = largeIcon ? 'large' : 'medium'

  return (
    <StyledIconButton
      key={isExpanded.toString()}
      onClick={onToggleExpand}
      aria-expanded={isExpanded}
      title={isExpanded ? t('general.hide') : t('general.showMore')}
      aria-label={isExpanded ? t('general.hide') : t('general.showMore')}
      disabled={disabled}
      invertColor={invertColor}
      uppercase={uppercase}
    >
      {text}
      {isExpanded ? (
        <ExpandLessIcon color="inherit" fontSize={fontSize} />
      ) : (
        <ExpandMoreIcon color="inherit" fontSize={fontSize} />
      )}
    </StyledIconButton>
  )
}
