import { makeStyles } from 'tss-react/mui'
import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import { ListItemButton } from '@mui/material'

import { useAppStateStore } from 'src/Store/appState'
import { useLanguage } from 'src/Hooks/useLanguage'
import { formatLocalDateDateRangeShort } from 'src/Utils/format'

import type { DatePeriod, DatePeriodKey } from 'src/Types/DatePeriod'

import { ClearPendingDatePeriodButton } from 'src/Features/SelectedDatePeriod/DatePeriodSelector/ClearPendingDatePeriodButton'
import { getDateRange, getDateRangeForKey, predefinedDatePeriodKeys } from 'src/Features/SelectedDatePeriod/predefinedDatePeriods'

const useStyles = makeStyles()({
  listItem: {
    padding: '6px 16px',
    minWidth: '250px',
  },
  selected: {
    fontWeight: 'bold',
  },
})

type OwnProps = {
  onDatePeriodSelected: (datePeriodKey: string) => void
  selectedPeriodKey?: string
  onClearPendingPeriod: () => void
}

export const PredefinedDatePeriodList = ({ onDatePeriodSelected, selectedPeriodKey, onClearPendingPeriod }: OwnProps) => {
  const { t, currentLanguage } = useLanguage()
  const { classes } = useStyles()
  const changePendingDatePeriod = useAppStateStore(state => state.selectedDatePeriod.changePendingDatePeriod)

  const handleDatePeriodClick = (datePeriodKey: DatePeriodKey) => {
    if (selectedPeriodKey === datePeriodKey) {
      onClearPendingPeriod()
    } else {
      const datePeriod: DatePeriod = datePeriodKey === 'selectTimePeriod' ? getDateRangeForKey('selectTimePeriod') : datePeriodKey
      changePendingDatePeriod(datePeriod)
      onDatePeriodSelected(datePeriodKey)
    }
  }

  const datePeriodText = (datePeriodKey: DatePeriodKey) => {
    const dateRange = getDateRange(datePeriodKey)
    const text = formatLocalDateDateRangeShort(dateRange, currentLanguage)
    return `(${text})`
  }

  return (
    <List component="div" disablePadding>
      {predefinedDatePeriodKeys.map(datePeriodKey => (
        <ListItemButton onClick={() => handleDatePeriodClick(datePeriodKey)} key={datePeriodKey} className={classes.listItem}>
          <ListItemText
            primary={
              <span className={selectedPeriodKey === datePeriodKey ? classes.selected : undefined}>
                {t(`general.date.${datePeriodKey}`)}
              </span>
            }
            secondary={selectedPeriodKey === datePeriodKey ? datePeriodText(datePeriodKey) : undefined}
          />
          {selectedPeriodKey === datePeriodKey && <ClearPendingDatePeriodButton onClearPendingPeriod={onClearPendingPeriod} />}
        </ListItemButton>
      ))}
    </List>
  )
}
