import { styled } from '@mui/material/styles'
import themeColors from 'src/theme'

export const StyledCircleIcon = styled('div')`
  color: white;
  margin-bottom: 2px;
  svg {
    margin-bottom: -2px;
    padding: 2px 3px;
    border-radius: 50%;
    background-color: ${themeColors.secondary};
  }
`
