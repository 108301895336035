import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import { SwitchHeatCardExpandedContent } from 'src/Features/SwitchHeat/SwitchHeatCardExpandedContent'
import { HeaderWithExpandableInfo } from 'src/Components/HeaderWithExpandableInfo'
import { NoResult } from 'src/Components/NoResult'
import { Alert } from 'src/Components/Alert'

import type { DatePeriod } from 'src/Types/DatePeriod'
import { useConnectedHeatCabinet } from 'src/Hooks/NetworkData/useConnectedHeatCabinet'

type OwnProps = {
  baneDataId: string
  datePeriod: DatePeriod
}

export const SwitchCardSwitchHeatStatus = ({ baneDataId, datePeriod }: OwnProps) => {
  const { t } = useTranslation()

  const { connectedHeatCabinet, status } = useConnectedHeatCabinet(baneDataId)

  if (status === 'idle') {
    return null
  }

  if (status === 'loading') {
    return (
      <Box mt={2}>
        <CircularProgress color="inherit" size={15} /> {t('switchHeat.connectedCabinet.loading')}
      </Box>
    )
  }

  if (status === 'error') {
    return (
      <Box mt={2}>
        <Alert severity="error">{t('switchHeat.connectedCabinet.fetchError')}</Alert>
      </Box>
    )
  }

  if (!connectedHeatCabinet) {
    return (
      <Box mt={2}>
        <NoResult>{t('switchHeat.connectedCabinet.notFound')}</NoResult>
      </Box>
    )
  }

  return (
    <Box mt={2}>
      <HeaderWithExpandableInfo title={t('switches.switchHeat.title')}>
        <SwitchHeatCardExpandedContent
          assetWithAlarms={{
            asset: connectedHeatCabinet,
            alarms: [],
          }}
          datePeriod={datePeriod}
        />
      </HeaderWithExpandableInfo>
    </Box>
  )
}
