import { useEffect, useMemo } from 'react'
import { useAppStateStore } from 'src/Store/appState'
import { QueryStringStateKeys, useQueryStringState } from 'src/Store/useQueryStringState'
import { useDeepRailways } from 'src/Hooks/NetworkData/useDeepRailways'

export const useReflectStateFromUrl = () => {
  const setOpenRailwayId = useAppStateStore(state => state.locations.setOpenedRailwayId)
  const setPendingStretchLocationIds = useAppStateStore(state => state.locations.setPendingStretchLocationIds)
  const setSelectedWeatherLocationId = useAppStateStore(state => state.locations.setSelectedWeatherLocationId)
  const changePendingDatePeriod = useAppStateStore(state => state.selectedDatePeriod.changePendingDatePeriod)

  const [querySelectedRailway] = useQueryStringState(QueryStringStateKeys.railway)
  const [querySelectedStretchLocations] = useQueryStringState(QueryStringStateKeys.stretchLocations)
  const [querySelectedDatePeriod] = useQueryStringState(QueryStringStateKeys.datePeriod)
  const [loaded] = useQueryStringState(QueryStringStateKeys.loaded)

  const { data: railways } = useDeepRailways()
  const filteredSelectedStretchLocations = useMemo(() => {
    const locationIds = railways
      ?.flatMap(railway => railway.railwaySections)
      ?.flatMap(railwaySection => railwaySection.locations)
      .map(l => l.locationId)
    return querySelectedStretchLocations.filter(id => locationIds?.includes(id))
  }, [querySelectedStretchLocations, railways])

  useEffect(() => {
    setOpenRailwayId(querySelectedRailway)

    if (filteredSelectedStretchLocations?.length) {
      setPendingStretchLocationIds(filteredSelectedStretchLocations)
      setSelectedWeatherLocationId(filteredSelectedStretchLocations[0])
    }

    if (querySelectedDatePeriod) {
      changePendingDatePeriod(querySelectedDatePeriod)
    }
  }, [
    changePendingDatePeriod,
    querySelectedDatePeriod,
    querySelectedRailway,
    filteredSelectedStretchLocations,
    setOpenRailwayId,
    setPendingStretchLocationIds,
    setSelectedWeatherLocationId,
    loaded,
  ])
}
