import { getConnectedCabinet } from 'src/Providers/SwitchHeat'
import { useQueryWithErrorHandling } from './useQueryWithErrorHandling'

export const useConnectedHeatCabinet = (baneDataId: string) => {
  const query = useQueryWithErrorHandling(
    ['connectedCabinet', { baneDataId }],
    () => getConnectedCabinet(baneDataId),
    {
      staleTime: Infinity,
    },
    'switchHeat.connectedCabinet.fetchError'
  )

  return {
    ...query,
    connectedHeatCabinet: query.data ? query.data[0] : undefined,
  }
}
