import { useEffect } from 'react'

// eslint-disable-next-line max-len
import type { BenderIsolationHistoryRenderer } from 'src/Features/SignalEarthFaults/BenderIsolationChart/benderIsolationHistoryRenderer'
import type { ChartRenderer } from 'src/Components/Chart/ChartRenderer'
import type { SwitchChartRenderer } from 'src/Features/Switches/SwitchCard/SwingInfo/SwitchSwingChart/switchChartRenderer'
import type { HeatSensorHistoryRenderer } from 'src/Features/SwitchHeat/SwitchHeatChart/heatSensorHistoryRenderer'
import type { SensorDataChartRenderer } from 'src/Features/TrackCircuits/SensorDataChart/sensorDataChartRenderer'
import type { WeatherChartRenderer } from 'src/Features/Weather/WeatherChart/weatherChartRenderer'

import { useUserProfileStore } from 'src/Store/userProfile'
import { useDelayedWindowResize } from 'src/Hooks/useDelayedWindowResize'

type AllChartRenderers =
  | BenderIsolationHistoryRenderer
  | ChartRenderer
  | SwitchChartRenderer
  | HeatSensorHistoryRenderer
  | SensorDataChartRenderer
  | WeatherChartRenderer

export const useChartResizeBehaviour = (renderer: AllChartRenderers | undefined) => {
  const isMenuOpen = useUserProfileStore(state => state.settings.settings.isMenuOpen)

  useEffect(() => {
    if (renderer) {
      renderer.sizeUpdated()
      renderer.render()
    }
  }, [renderer, isMenuOpen])

  useDelayedWindowResize(() => {
    if (renderer) {
      renderer.sizeUpdated()
      renderer.render()
    }
  }, [renderer])
}
