import { ApiType } from 'src/Types/ApiType'
import type { RackSensorHistory } from 'src/Types/RackSensorHistory'

import { callApiPost } from 'src/msGraphClient'
import config from 'src/Config/Config'

const { RACK_SECTIONS_HISTORY } = config.API_FRAGMENTS.apim

type RackSectionHistoryParams = {
  sensorIds: string[]
  fromDate: number
  toDate: number
}

export const getRackSectionSensorHistory = ({ sensorIds, fromDate, toDate }: RackSectionHistoryParams) => {
  const urlParams = new URLSearchParams({
    fromDate: new Date(fromDate).toJSON(),
    toDate: new Date(toDate).toJSON(),
  })

  return callApiPost<RackSensorHistory[]>(
    `${RACK_SECTIONS_HISTORY}?${urlParams.toString()}`,
    ApiType.APIM,
    JSON.stringify(sensorIds)
  )
}
