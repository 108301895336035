export enum TemperatureValueValidationRule {
  ExceedsMinValue = 'ExceedsMinValue',
  ExceedsMaxValue = 'ExceedsMaxValue',
  SameValue = 'SameValue',
}

type TemperatureValidationViolation =
  | {
      type: TemperatureValueValidationRule.ExceedsMaxValue
      value: number
      maxValue: number
    }
  | {
      type: TemperatureValueValidationRule.ExceedsMinValue
      value: number
      minValue: number
    }
  | {
      type: TemperatureValueValidationRule.SameValue
      value: number
      currentValue: number
    }

type TemperatureValidateParams = {
  changedValue: number
  minValue?: number
  maxValue?: number
  currentValue: number
}

export const validateTemperatureValue = ({
  changedValue,
  minValue,
  maxValue,
  currentValue,
}: TemperatureValidateParams): TemperatureValidationViolation[] => {
  const validation: TemperatureValidationViolation[] = []
  if (typeof minValue === 'number' && changedValue < minValue) {
    validation.push({
      type: TemperatureValueValidationRule.ExceedsMinValue,
      value: changedValue,
      minValue,
    })
  }
  if (typeof maxValue === 'number' && changedValue > maxValue) {
    validation.push({
      type: TemperatureValueValidationRule.ExceedsMaxValue,
      value: changedValue,
      maxValue,
    })
  }
  if (currentValue === changedValue) {
    validation.push({
      type: TemperatureValueValidationRule.SameValue,
      value: changedValue,
      currentValue,
    })
  }
  return validation
}
