import { styled } from '@mui/material/styles'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'

import { ignoreStyleProps } from 'src/Utils/style'

export const StyledLocationTitle = styled(ListItemButton)`
  font-size: 14px;
  padding: ${props => props.theme.spacing(1)};
`
export const StyledLocationListItem = styled(ListItem)`
  padding: 0;
`

export const StyledLocationListItemButton = styled(ListItemButton, ignoreStyleProps('isSelected'))<{ isSelected: boolean }>`
  padding: 5px ${props => props.theme.spacing(2)};
  ${props => props.isSelected && 'font-weight: bold;'}
`
