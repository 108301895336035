import type { SensorTwinModus } from 'src/Types/SensorTwin'

export const parseTwinModus = (value: string | number | boolean | null | undefined): SensorTwinModus | undefined => {
  switch (value) {
    case 1:
      return 'off'

    case 2:
      return 'auto'

    case 8:
      return 'max'

    case 16:
      return 'met'

    default:
      return undefined
  }
}
