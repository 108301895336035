import type { Asset } from 'src/Types/AssetTypes'
import { AssetType } from 'src/Types/AssetTypes'
import { AssetStatusEntryDomain } from 'src/Types/AssetStatusEntry'

export const getAssetStatusDomainByAsset = (asset: Pick<Asset, 'assetType'>) => {
  const { assetType } = asset
  let domain: AssetStatusEntryDomain | undefined
  switch (assetType) {
    case AssetType.SwitchHeat:
      domain = AssetStatusEntryDomain.SwitchHeat
      break

    case AssetType.SwitchMachineHeat:
      domain = AssetStatusEntryDomain.MachineHeat
      break

    case AssetType.SwitchPointMachine:
      domain = AssetStatusEntryDomain.PointMachine
      break

    case AssetType.TrackCircuit:
      domain = AssetStatusEntryDomain.TrackCircuit
      break

    case AssetType.Rack:
    case AssetType.Bender:
      domain = AssetStatusEntryDomain.SignalEarthFault
      break

    default:
      domain = undefined
      break
  }
  return domain
}
