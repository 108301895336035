import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import WifiOffOutlinedIcon from '@mui/icons-material/WifiOffOutlined'

import { IconWithText } from 'src/Components/IconWithText'

import themeColors from 'src/theme'

const StyledIconContainer = styled('div')`
  color: ${themeColors.noContact};
`

export const NoContactIcon = () => {
  const { t } = useTranslation()

  return (
    <StyledIconContainer>
      <IconWithText
        aria-label={t('alarms.alarmIndicator.NoContact')}
        title={t('alarms.alarmIndicator.NoContact')}
        icon={<WifiOffOutlinedIcon fontSize="large" />}
        text=""
        isUpperCase
      />
    </StyledIconContainer>
  )
}
