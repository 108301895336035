import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import type { LanguageResources } from 'src/Types/LanguageTypes'
import { Language } from 'src/Types/LanguageTypes'

import { findSupportedLanguage } from 'src/Utils/language'

import translationNO from './locales/no/common.json'
import translationEN from './locales/en/common.json'

const resources: LanguageResources = {
  [Language.en]: { translation: translationEN },
  [Language.no]: { translation: translationNO },
}

const languagePriority = [Language.no, Language.en]

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: {
      /* eslint-disable quote-props */
      nb: languagePriority,
      'nn-NO': languagePriority,
      'nb-NO': languagePriority,
      nn: languagePriority,
      default: [Language.en],
      /* eslint-enable quote-props */
    },
    debug: false,
    nsSeparator: '>',

    interpolation: {
      escapeValue: false,
    },

    react: {
      /* Set bindI18n to empty string to avoid automatic re-render in React.
       * Otherwise MSAL configured with cacheLocation: 'memoryStorage' will break when changing language. */
      bindI18n: '',
    },
  })

const lang = findSupportedLanguage(resources, [...i18n.languages], Language.en)
if (i18n.language !== lang) {
  i18n.changeLanguage(lang)
}

export default i18n

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false
  }
}
