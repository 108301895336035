import { useMemo } from 'react'
import type { SensorHistory } from 'src/Types/SensorTwin'
import type { SensorCodeDomain } from 'src/Types/SensorCode'

import { useDomainConstantsStore } from 'src/Store/domainConstants'
import { useLanguage } from 'src/Hooks/useLanguage'
import type { ChartLegend } from 'src/Components/Chart/Legends/ChartLegend'

export const useSensorHistoryLegends = (
  sensorHistory: SensorHistory[] | undefined,
  inactiveSensors: string[] | number[],
  domain: SensorCodeDomain
): ChartLegend[] => {
  const { t, currentLanguage } = useLanguage()
  const sensorCodes = useDomainConstantsStore(state => state.sensorCodes.sensorCodes[domain])

  return useMemo(
    () =>
      (sensorHistory || []).map((history, i) => {
        const codeSystem = history.codeSystem
        const inactiveSensorIds = typeof inactiveSensors[0] === 'string' ? (inactiveSensors as string[]) : undefined
        const inactiveSensorIndexes = typeof inactiveSensors[0] === 'number' ? (inactiveSensors as number[]) : undefined
        let isActive = false
        if (inactiveSensorIds) {
          isActive = !inactiveSensorIds.includes(codeSystem)
        }
        if (inactiveSensorIndexes) {
          isActive = !inactiveSensorIndexes.includes(i)
        }
        const title = isActive ? t('sensorHistory.chart.hideSensor') : t('sensorHistory.chart.showSensor')
        const code = sensorCodes.find(sc => sc.key === codeSystem)
        const sensorName = code ? code.localization.short[currentLanguage] : t('sensorHistory.chart.unknownType', { codeSystem })
        const isEmpty = !history.values?.length

        return {
          id: codeSystem,
          label: sensorName,
          buttonTitle: title,
          isEmpty,
        }
      }),
    [currentLanguage, inactiveSensors, sensorCodes, sensorHistory, t]
  )
}
