export enum TemperatureUpdateState {
  Idle,
  Updating,
  Success,
  Failed,
}

export type TemperatureUpdate = {
  sensorId: string
  label: string
  state: TemperatureUpdateState
  originalValue: number | undefined
  newValue: number
}
