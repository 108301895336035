import type { ErrorInfo } from 'react'
import React, { useState } from 'react'
import { styled, Paper, Collapse, IconButton, Box } from '@mui/material'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import { useTranslation } from 'react-i18next'
import { Alert } from 'src/Components/Alert'

const StyledErrorDiv = styled(Paper)`
  padding: 1em;
  margin-bottom: 1em;
`

const StyledStackTrace = styled(Collapse)`
  font-size: 12px;
  width: 100%;
  overflow: auto;
`

const KeyboardArrowUpOutlinedIcon = styled(KeyboardArrowDownOutlinedIcon)`
  transform: rotate(180deg);
`

const StyledIconButton = styled(IconButton)`
  position: absolute;
  margin-top: -8px;
`

type OwnProps = {
  error?: Error
  errorInfo?: ErrorInfo
}

export const ErrorPanel = ({ error, errorInfo }: OwnProps) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const hasStacktrace = Boolean(error?.stack || errorInfo?.componentStack)

  return (
    <StyledErrorDiv>
      <Alert severity="error" variant="outlined">
        <Box pr={1}>
          {t('general.errorMessages.default')}
          {hasStacktrace ? (
            <StyledIconButton color="primary" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpOutlinedIcon /> : <KeyboardArrowDownOutlinedIcon />}
            </StyledIconButton>
          ) : null}
        </Box>
      </Alert>
      {hasStacktrace ? (
        <StyledStackTrace in={open}>
          <pre>{error?.stack}</pre>
          <pre>{errorInfo?.componentStack}</pre>
        </StyledStackTrace>
      ) : null}
    </StyledErrorDiv>
  )
}
