import { format } from 'date-fns'
import { nb, enUS } from 'date-fns/locale'

import type { DateRange } from 'src/Types/DatePeriod'

const DATE_FORMAT = 'dd.MM.yyyy'
const TIME_FORMAT = 'HH:mm:ss'
const WEEK_DATE_FORMAT = 'E d.MM'
const DATE_TIME_FORMAT = 'd. MMM yyyy HH:mm'
const SHORT_DATE_TIME_FORMAT = 'd. MMM HH:mm'

const ISO_8601_DATE = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i

/**
 * Formats a timestamp to european week and date format ('E d.MMM').
 *
 * @param timestamp - Timestamp to format.
 */
export const formatLocalWeekdayAndDate = (timestamp: number, language: string) =>
  format(timestamp, WEEK_DATE_FORMAT, { locale: language === 'no' ? nb : enUS })

/**
 * Formats a timestamp to european date and time format ('dd.MM.yyyy HH:mm:ss').
 *
 * @param timestamp - Timestamp to format.
 * @param short - Only format date part.
 */
export const formatLocalDateTime = (timestamp: number | Date, short: boolean = false) =>
  format(timestamp, `${DATE_FORMAT}${short ? '' : ` ${TIME_FORMAT}`}`)

/**
 * Formats a timestamp to european date and time format ('d. MMM yyyy HH:mm').
 *
 * @param timestamp - Timestamp to format.
 * @param short - Format without year.
 */
export const formatLocalDateTimeShort = (timestamp: number | Date, language: string, short: boolean = false) =>
  format(timestamp, short ? SHORT_DATE_TIME_FORMAT : DATE_TIME_FORMAT, { locale: language === 'no' ? nb : enUS })

/**
 * Formats a date range to readable european date and time format ('d. MMM HH:mm') - ('d. MMM HH:mm').
 *
 * @param dateRange - Date range to format.
 * @param language - Output language.
 */
export const formatLocalDateDateRangeShort = (dateRange: DateRange | undefined, language: string) => {
  if (!dateRange) {
    return undefined
  }

  return [formatLocalDateTimeShort(dateRange.fromDate, language), '-', formatLocalDateTimeShort(dateRange.toDate, language)].join(
    ' '
  )
}

/**
 * Formats a number string to a decimal number in norwegian format.
 * TODO: consider supporting english number format.
 *
 * @param num - Number to format.
 * @param decimals - Number of decimals. Default 1.
 * @param ignoreDecimalsForZero - Exclude format on 0 number.
 */
export const formatNumber = (num: number, decimals: number = 1, ignoreDecimalsForZero: boolean = true) => {
  if (ignoreDecimalsForZero && num === 0) {
    return '0'
  }
  return (Math.round(num * 100) / 100).toFixed(decimals).replace('.', ',')
}

/**
 * Converts hours to months, days, hours and minutes.
 *
 * @param num - Number in hours to format.
 */
export const hoursToTimeSpan = (totalHours: number) => {
  let remainingHours = totalHours

  const weeks = Math.floor(remainingHours / 7 / 24)
  remainingHours -= weeks * 7 * 24

  const days = Math.floor(remainingHours / 24)

  const hours = remainingHours - days * 24

  return {
    weeks,
    days,
    hours,
  }
}

/**
 * Parses ISO 8601 dates from strings to timestamps.
 * Note: Currently only parses dates found as values in POJOs, not loose values.
 *
 * @param o - Object to parse dates within.
 */
export const parseDates = (o: any[] | { [key: string]: any }) => {
  if (Array.isArray(o)) {
    o.forEach(item => parseDates(item))
  } else if (!!o && typeof o === 'object') {
    Object.keys(o).forEach(k => {
      const val = o[k]
      if (typeof val === 'string' && ISO_8601_DATE.test(val)) {
        o[k] = new Date(val).valueOf()
      } else if (Array.isArray(val)) {
        parseDates(val)
      } else if (typeof val === 'object') {
        parseDates(val)
      }
    })
  }
}

export const toLocalISO8601 = (date: Date) => {
  const tzo = -date.getTimezoneOffset()
  const dif = tzo >= 0 ? '+' : '-'
  const pad = (num: number) => (num < 10 ? '0' : '') + num
  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(
    date.getMinutes()
  )}:${pad(date.getSeconds())}${dif}${pad(Math.floor(Math.abs(tzo) / 60))}:${pad(Math.abs(tzo) % 60)}`
}
