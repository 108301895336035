import { useMemo } from 'react'
import { styled } from '@mui/material/styles'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import { CellEntry } from 'src/Components/CellEntry'
import { ExpandableAlarmSensorData } from 'src/Features/TrackCircuits/TrackCircuitCard/ExpandableAlarmSensorData'

import type { Alarm } from 'src/Types/Alarm'
import type { AssetType, WindowStatus } from 'src/Types/AssetTypes'
import { InternalAlarmSystem } from 'src/Types/AlarmSystem'

import { formatLocalDateTime } from 'src/Utils/format'
import { getAlarmStart, getAlarmEnd, isSwitchHeatAlarmWithHeatElements } from 'src/Utils/alarms'

import { useBreakpointDown } from 'src/Hooks/useBreakpoint'
import { useAlarmTypeByAlarm } from 'src/Hooks/useAlarmTypeByAlarm'
import { useLanguage } from 'src/Hooks/useLanguage'
import themeColors from 'src/theme'
import Box from '@mui/material/Box'

const StyledDescriptionLink = styled('a')`
  font-size: 14px;
  color: ${themeColors.linkText};
`
const StyledBox = styled(Box)`
  width: 100%;
  display: table;
  table-layout: fixed;
`

type OwnProps = {
  alarm: Alarm
  assetType: AssetType
  showAlarmsInAssetStatusWindows: boolean
  showExtraAlarmsInfo: boolean
  activeWindowStatuses?: WindowStatus | 'Both'
  expandAlarmChart?: boolean
}

export const AlarmEntry = ({
  alarm,
  assetType,
  showAlarmsInAssetStatusWindows,
  showExtraAlarmsInfo,
  activeWindowStatuses,
  expandAlarmChart = false,
}: OwnProps) => {
  const { t, currentLanguage } = useLanguage()
  const isMediaSM = useBreakpointDown('sm')

  const alarmType = useAlarmTypeByAlarm(alarm)
  const descriptionLength = isMediaSM ? 'short' : 'full'
  const alarmDescription = alarmType
    ? alarmType.type.localization[descriptionLength][currentLanguage]
    : t('alarms.unknownType', { alarmTypeId: alarm.alarmTypeId })

  let realAlarmOccurrences = alarm.nrOfAlarms
  let alarmsInAssetStatusWindows = 0

  if ('nrOfRealAlarms' in alarm) {
    realAlarmOccurrences = alarm.nrOfRealAlarms
    alarmsInAssetStatusWindows = alarm.nrOfAlarms - realAlarmOccurrences
  }

  const alarmStart = getAlarmStart(alarm)
  const alarmEnd = getAlarmEnd(alarm)

  const isSwitchAlarm =
    alarm.internalAlarmSystem === InternalAlarmSystem.AggregatedPointMachineAlarm ||
    alarm.internalAlarmSystem === InternalAlarmSystem.AggregatedMachineHeatAlarm

  let activeWindowStatusLabelKey = 'alarms.alarmEntry.occurrencesWhileNoContact'
  if (activeWindowStatuses === 'IrregularData') {
    activeWindowStatusLabelKey = 'alarms.alarmEntry.occurrencesWhileIrregularData'
  } else if (activeWindowStatuses === 'Both') {
    activeWindowStatusLabelKey = 'alarms.alarmEntry.occurrencesWhileNoContactOrIrregularData'
  }

  const alarmDetails = (
    <TableRow>
      <TableCell padding="checkbox">
        {CellEntry(alarmDescription, t('alarms.alarmEntry.alarmCode', { alarmTypeId: alarm.alarmTypeId }))}
      </TableCell>
      <TableCell padding="checkbox">
        {showAlarmsInAssetStatusWindows && alarmsInAssetStatusWindows > 0
          ? CellEntry(`${realAlarmOccurrences} (${alarmsInAssetStatusWindows})`, t(activeWindowStatusLabelKey))
          : CellEntry(realAlarmOccurrences)}
      </TableCell>
      {isMediaSM ? (
        <TableCell padding="checkbox">
          {alarmStart && CellEntry(`${formatLocalDateTime(alarmStart)} -`)}
          {alarmEnd && CellEntry(formatLocalDateTime(alarmEnd))}
        </TableCell>
      ) : (
        <>
          <TableCell padding="checkbox">{alarmStart && CellEntry(formatLocalDateTime(alarmStart))}</TableCell>
          <TableCell padding="checkbox">{alarmEnd && CellEntry(formatLocalDateTime(alarmEnd))}</TableCell>
        </>
      )}
      {showExtraAlarmsInfo && (
        <TableCell padding="checkbox">
          {isSwitchAlarm && alarm.baneDataName && CellEntry(alarm.baneDataName)}
          {alarm.internalAlarmSystem === InternalAlarmSystem.AggregatedSwitchHeatAlarm &&
            isSwitchHeatAlarmWithHeatElements(alarm) && (
              <>
                {alarm.switchName && CellEntry(alarm.switchName)}
                {!alarm.switchName && alarm.switchHeatElementName && CellEntry(alarm.switchHeatElementName)}
                {alarm.additionalInfo && CellEntry(alarm.additionalInfo)}
              </>
            )}
        </TableCell>
      )}
      <TableCell padding="checkbox" title={t('alarms.alarmDescriptionsUrl.long')}>
        <StyledDescriptionLink href={alarmType?.type?.description_URL} target="_blank" rel="noopener noreferrer">
          <OpenInNewIcon fontSize="small" />
        </StyledDescriptionLink>
      </TableCell>
    </TableRow>
  )

  const datePeriod = useMemo(
    () => ({
      fromDate: alarmStart,
      toDate: alarmEnd,
    }),
    [alarmStart, alarmEnd]
  )

  return (
    <>
      {alarmDetails}
      {alarm.internalAlarmSystem === InternalAlarmSystem.AggregatedTrackCircuitAlarm && (
        <TableRow>
          <TableCell padding="none" colSpan={isMediaSM ? 4 : 5}>
            <StyledBox>
              <ExpandableAlarmSensorData alarm={alarm} datePeriod={datePeriod} expandReturnCurrentChart={expandAlarmChart} />
            </StyledBox>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}
