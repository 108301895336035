import { ApiType } from 'src/Types/ApiType'

import { callApiGet, callApiPost } from 'src/msGraphClient'
import config from 'src/Config/Config'

const { FRONTEND_SETTINGS } = config.API_FRAGMENTS.apim

export const loadFrontendSettings = async (area: string) => {
  const result = await callApiGet<unknown>(`${FRONTEND_SETTINGS}/${area}`, ApiType.APIM)
  return result
}

export const saveFrontendSettings = async (area: string, content: string) => {
  const result = await callApiPost<unknown>(`${FRONTEND_SETTINGS}/${area}`, ApiType.APIM, content)
  return result
}
