import { useCallback } from 'react'
import { useQueryParams, StringParam, withDefault } from 'use-query-params'
import { decodeDelimitedArray, encodeDelimitedArray } from 'serialize-query-params'

import type { DatePeriod, DatePeriodKey } from 'src/Types/DatePeriod'

import { predefinedDatePeriodKeys } from 'src/Features/SelectedDatePeriod/predefinedDatePeriods'

const CommaArrayParamWithEmptyFallback = {
  encode: (array: string[] | undefined): string | null | undefined => encodeDelimitedArray(array, ','),

  decode: (arrayStr: string | (string | null)[] | null | undefined): string[] => {
    const decoded = decodeDelimitedArray(arrayStr, ',')
    if (!decoded) {
      return []
    }
    return decoded.flatMap(v => v ?? [])
  },
}

export const DatePeriodParam = {
  encode: (datePeriod: DatePeriod): string | null | undefined => {
    if (typeof datePeriod === 'string') {
      return datePeriod
    }

    return `${datePeriod.fromDate}-${datePeriod.toDate}`
  },

  decode: (datePeriod: string | (string | null)[] | null | undefined): DatePeriod | undefined => {
    if (!datePeriod || Array.isArray(datePeriod)) {
      return undefined
    }

    const [, fromDate, toDate] = datePeriod?.match(/(\d+)-(\d+)/) || []
    if (fromDate && toDate) {
      return {
        fromDate: Number(fromDate),
        toDate: Number(toDate),
      }
    }

    const datePeriodKey = datePeriod as DatePeriodKey

    return predefinedDatePeriodKeys.includes(datePeriodKey) ? datePeriodKey : undefined
  },
}

const paramConfig = {
  railway: withDefault(StringParam, undefined),
  stretchLocations: CommaArrayParamWithEmptyFallback,
  datePeriod: DatePeriodParam,
  tcBaneDataIds: CommaArrayParamWithEmptyFallback,
  benderIds: CommaArrayParamWithEmptyFallback,
  rsIds: CommaArrayParamWithEmptyFallback,
  loaded: withDefault(StringParam, undefined),
}

export const useQueryStringState = <T extends keyof typeof paramConfig, U extends (typeof paramConfig)[T]>(key: T) => {
  const [query, setQuery] = useQueryParams(paramConfig)

  const setter = useCallback(
    (val?: Parameters<U['encode']>[0]) => {
      setQuery({
        [key]: val,
      })
    },
    [key, setQuery]
  )

  return [query[key], setter] as const
}

export const QueryStringStateKeys = {
  railway: 'railway',
  stretchLocations: 'stretchLocations',
  datePeriod: 'datePeriod',
  tcBaneDataIds: 'tcBaneDataIds',
  benderIds: 'benderIds',
  rsIds: 'rsIds',
  loaded: 'loaded',
} as const
