import type { QueryStatus } from 'react-query'
import { subMinutes } from 'date-fns'
import type {
  TrackCircuitCurrentsData,
  TrackCircuitCompressionRates,
  TrackCircuitCurrents,
} from 'src/Types/TrackCircuitCurrentTypes'
import type { LeveledSectionState, LeveledMeasurementState } from 'src/Types/LevelOfDetails'
import { unionTimeSpans, getTimeSpansString, appendTimespan, hasGaps } from 'src/Utils/LevelOfDetails/timeSpan'
import { getCalculatedCompressionRate } from 'src/Hooks/NetworkData/SensorData/getCalculatedCompressionRate'
import { getQueryTimeSpan } from 'src/Utils/LevelOfDetails/getQueryTimeSpan'
import { getJoinedMeasurements } from 'src/Utils/LevelOfDetails'

export type LodStore = {
  queryStatus?: QueryStatus
  queryFromDate?: number
  queryToDate?: number
  queryCompressionRate?: TrackCircuitCompressionRates
  viewFromDate?: number
  viewToDate?: number
  fromDate: number
  toDate: number
  forcedCompressionRate?: TrackCircuitCompressionRates
  calculatedCompressionRate?: TrackCircuitCompressionRates
  requestedCompressionRate?: TrackCircuitCompressionRates
  requestedSections?: LeveledSectionState
  cachedSections?: LeveledSectionState
  sensorMetadata?: SensorMetaDataState
  leveledMeasurements?: LeveledMeasurementState
  isQueryCloseToCurrentTime?: boolean
}

export enum LodActionKind {
  NEW_SENSOR_DATA = 'NEW_SENSOR_DATA',
  ZOOM = 'ZOOM',
  FORCED_COMPRESSION_RATE = 'FORCED_COMPRESSION_RATE',
  DATE_RANGE = 'DATE_RANGE',
}

export type LodNewSensorDataAction = {
  type: LodActionKind.NEW_SENSOR_DATA
  payload: {
    sensorData: TrackCircuitCurrentsData[] | undefined
    status: QueryStatus
  }
}

export type LodSetZoomAction = {
  type: LodActionKind.ZOOM
  payload: {
    viewFromDate: number
    viewToDate: number
  }
}

export type LodSetForcedCompressionRateAction = {
  type: LodActionKind.FORCED_COMPRESSION_RATE
  payload: {
    forcedCompressionRate?: TrackCircuitCompressionRates
  }
}

export type LodSetDateRangeAction = {
  type: LodActionKind.DATE_RANGE
  payload: {
    fromDate: number
    toDate: number
  }
}

type LodAction = LodNewSensorDataAction | LodSetZoomAction | LodSetForcedCompressionRateAction | LodSetDateRangeAction

const getStateByZoom = (state: LodStore, { viewFromDate, viewToDate }: { viewFromDate: number; viewToDate: number }) => {
  const { requestedSections, forcedCompressionRate, cachedSections, fromDate, toDate } = state
  const calculatedCompressionRate = getCalculatedCompressionRate(viewFromDate, viewToDate)
  const compressionRate = forcedCompressionRate ?? calculatedCompressionRate
  const cache = cachedSections?.[compressionRate] ?? []
  const requested = requestedSections?.[compressionRate] ?? []
  const hasAnyGap = hasGaps(cache, [viewFromDate, viewToDate])

  let newState = {
    ...state,
    viewFromDate,
    viewToDate,
    calculatedCompressionRate,
    requestedCompressionRate: compressionRate,
  }

  if (hasAnyGap) {
    const { fromDate: calculatedFromDate, toDate: calculatedToDate } = getQueryTimeSpan({
      cache,
      zoomFromDate: viewFromDate,
      zoomToDate: viewToDate,
      fromDate,
      toDate,
    })
    const joinedSections = unionTimeSpans([...requested, [calculatedFromDate, calculatedToDate]])
    let newRequestedSections = requestedSections
    if (getTimeSpansString(requested) !== getTimeSpansString(joinedSections)) {
      newRequestedSections = {
        ...requestedSections,
        [compressionRate]: joinedSections,
      }
    }

    newState = {
      ...newState,
      queryCompressionRate: compressionRate,
      queryFromDate: calculatedFromDate,
      queryToDate: calculatedToDate,
      requestedSections: newRequestedSections,
      isQueryCloseToCurrentTime: calculatedToDate > subMinutes(Date.now(), 5).getTime(),
    }
  }
  return newState
}

export const lodReducer = (state: LodStore, action: LodAction): LodStore => {
  const { type, payload } = action

  switch (type) {
    case LodActionKind.NEW_SENSOR_DATA: {
      const { status, sensorData } = payload
      const newState = {
        ...state,
        queryStatus: status,
      }

      switch (status) {
        case 'success': {
          const { queryFromDate, queryToDate, sensorMetadata, leveledMeasurements, cachedSections } = state
          if (!sensorData) {
            return newState
          }
          const firstSensorData = sensorData[0]
          if (!firstSensorData) {
            return newState
          }
          const compRate = firstSensorData.compressionRate
          if (typeof compRate !== 'number') {
            return newState
          }
          if (firstSensorData.fromDate !== queryFromDate || firstSensorData.toDate !== queryToDate) {
            return newState
          }
          const levelMeasurements = leveledMeasurements?.[compRate]
          return {
            ...newState,
            sensorMetadata: {
              ...sensorMetadata,
              ...getSensorMetadata(sensorData),
            },
            leveledMeasurements: {
              ...leveledMeasurements,
              [compRate]: getJoinedMeasurements(levelMeasurements, sensorData),
            },
            cachedSections: {
              ...cachedSections,
              [compRate]: appendTimespan(cachedSections?.[compRate] || [], [firstSensorData.fromDate, firstSensorData.toDate]),
            },
          }
        }

        default: {
          const { viewFromDate, viewToDate } = state
          if (viewFromDate && viewToDate) {
            return getStateByZoom(newState, { viewFromDate, viewToDate })
          }
          return newState
        }
      }
    }

    case LodActionKind.ZOOM: {
      const { viewFromDate, viewToDate } = payload
      const { queryStatus } = state
      const newState = {
        ...state,
        viewFromDate,
        viewToDate,
      }
      if (queryStatus === 'loading') {
        return newState
      }
      return getStateByZoom(newState, { viewFromDate, viewToDate })
    }

    case LodActionKind.FORCED_COMPRESSION_RATE: {
      const { forcedCompressionRate } = payload
      const { viewFromDate, viewToDate } = state
      const newState = {
        ...state,
        forcedCompressionRate,
      }
      if (viewFromDate && viewToDate) {
        return getStateByZoom(newState, { viewFromDate, viewToDate })
      }
      return newState
    }

    case LodActionKind.DATE_RANGE: {
      const { fromDate, toDate } = payload
      return getStateByZoom(
        {
          fromDate,
          toDate,
        },
        { viewFromDate: fromDate, viewToDate: toDate }
      )
    }

    default:
      return state
  }
}

type SensorMetaData = {
  baneDataLocationId: string
  baneDataLocationName: string
  station: string
  tcId: string
  trackCircuitBaneDataId: string
}

type SensorMetaDataState = Record<string, SensorMetaData | undefined>

const getSensorMetadata = (trackCircuitCurrents: TrackCircuitCurrents[]) => {
  return trackCircuitCurrents.reduce<SensorMetaDataState>(
    (acc, { baneDataLocationId, baneDataLocationName, station, tcId, trackCircuitBaneDataId }) => {
      acc[trackCircuitBaneDataId] = {
        baneDataLocationId,
        baneDataLocationName,
        station,
        tcId,
        trackCircuitBaneDataId,
      }
      return acc
    },
    {}
  )
}
