import type { Filter } from 'src/Types/Filter'
import { FilterType } from 'src/Types/Filter'
import { getAlarmTypeByAlarm, getAlarmTypeUniqueId } from 'src/Utils/alarms'
import type { Alarm } from 'src/Types/Alarm'
import type { Asset } from 'src/Types/AssetTypes'
import { filterAlarmsByStatus } from 'src/Features/Alarms/AlarmList/AlarmFilters/filterAlarmsByStatus'

/**
 * Filters the given asset's alarms (the length of alarms array might be zero),
 * in which for each alarm, the alarm can be excluded only IF
 * both any of the filter types are Status or AlarmTypeId
 * AND mismatch in one of those filters.
 * (Both filterAlarms and filterAssets are used by filterAssetsWithAlarm)
 * @param filters The filters
 * @param alarms The alarms on that asset
 * @param asset The asset (having alarms array that might be of zero length)
 */
export const filterAlarms = (filters: Filter[], alarms: Alarm[], asset: Asset) =>
  alarms.filter(alarm =>
    filters.every(filter => {
      if (filter.type === FilterType.Status || filter.type === FilterType.AlarmTypeId) {
        const alarmType = getAlarmTypeByAlarm(alarm)

        if (filter.type === FilterType.Status) {
          return filterAlarmsByStatus(filter.values, asset.statusWithAssetStatusWindows, alarmType?.classification?.id)
        }
        return alarmType ? filter.values.includes(getAlarmTypeUniqueId(alarmType)) : false
      }

      return true
    })
  )
