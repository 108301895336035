import merge from 'deepmerge'

import devConfig from './config.development'
import type { Config } from './Config'

const config: Partial<Config> = merge(devConfig, {
  MSAL_CONFIG: {
    auth: {
      redirectUri: 'http://localhost:3000',
    },
  },
})

export default config
