import { useMemo } from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'

import { SubHeaderWithExpandableInfo } from 'src/Components/SubHeaderWithExpandableInfo'
import { NoResult } from 'src/Components/NoResult'
import { SensorValue } from 'src/Components/AssetCard/Sensor/SensorValue'
import { AssetTypeIcon } from 'src/Components/AssetCard/AssetTypeIcon'
import { Alert } from 'src/Components/Alert'
import { BenderInfo } from './BenderInfo'
import { AlarmCountChip } from 'src/Components/AlarmCountChip'
import { useChartFocus } from 'src/Components/Chart/useChartFocus'

import { BenderIsolationChart } from 'src/Features/SignalEarthFaults/BenderIsolationChart/BenderIsolationChart'
import { BenderVoltageChart } from 'src/Features/SignalEarthFaults/BenderVoltageChart/BenderVoltageChart'
import { WeatherChart } from 'src/Features/Weather/WeatherChart/WeatherChart'
import { getDateRange } from 'src/Features/SelectedDatePeriod/predefinedDatePeriods'

import type { AssetWithAlarms } from 'src/Types/AssetWithAlarms'
import type { DatePeriod } from 'src/Types/DatePeriod'
import type { Bender } from 'src/Types/Bender'

import { partition } from 'src/Utils/array'
import { getLeastAdvancedQueryStatus } from 'src/Utils/network'

import { useBenderDetails } from 'src/Hooks/NetworkData/useBenderDetails'
import { useBenderSensorData } from 'src/Hooks/NetworkData/useBenderSensorData'
import { useLanguage } from 'src/Hooks/useLanguage'

import { useDomainConstantsStore } from 'src/Store/domainConstants'

import { SubHeaderKey } from 'src/Providers/Settings/SubHeader'

const StyledAreaAssetContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-left: 14px;
`

const CODE_SYSTEM_KEYS_ISOLATION = [
  'Insulation_resistance',
  'Minimum_insulation_resistance',
  'Insulation_resistance.Warning',
  'Insulation_resistance.SignalEarthFault',
  'Insulation_resistance.SignalEarthFaultCrit',
  'Insulation_resistance.SignalStopEnd',
  'Insulation_resistance.SignalStopStart',
]

const CODE_SYSTEM_KEYS_VOLTAGE = ['Voltage_L1-L3', 'Voltage_L1-PE', 'Voltage_L3-PE']

type OwnProps = {
  assetWithAlarms: AssetWithAlarms
  datePeriod: DatePeriod
}

export const BenderCardExpandedContent = ({ assetWithAlarms, datePeriod }: OwnProps) => {
  const { t } = useLanguage()

  const benderCodes = useDomainConstantsStore(state => state.sensorCodes.sensorCodes.SEF)

  const asset = assetWithAlarms.asset as Bender

  const { benderSensorData, status: sensorStatus } = useBenderSensorData(asset.baneDataId)

  const { benderDetails, status: benderDataStatus } = useBenderDetails(asset.baneDataId)

  const queryStatus = getLeastAdvancedQueryStatus([sensorStatus, benderDataStatus])

  const { fromDate, toDate } = useMemo(() => getDateRange(datePeriod), [datePeriod])
  const { chartFocus, zoomUpdated } = useChartFocus({ fromDate, toDate })

  if (queryStatus === 'idle') {
    return null
  }

  if (queryStatus === 'error') {
    return <NoResult>{t('benders.sensorInfo.fetchError')}</NoResult>
  }

  if (queryStatus === 'loading') {
    return (
      <Box mt={2}>
        <CircularProgress color="inherit" size={15} /> {t('benders.sensorInfo.loading')}
      </Box>
    )
  }

  if (!benderSensorData || !benderSensorData.sensors.length) {
    return <NoResult>{t('benders.sensorInfo.noneFound')}</NoResult>
  }

  const [alarmSensors, otherSensors] = partition(benderSensorData.sensors, sensor =>
    benderCodes.some(c => c.key === sensor.codeSystem && ['Signal', 'System'].includes(c.category.name))
  )

  const numSensorAlarms = alarmSensors.filter(tsa => tsa.currentValue === true || tsa.currentValue === 1).length

  return (
    <>
      <BenderInfo bender={asset} sensors={otherSensors} />
      <SubHeaderWithExpandableInfo
        title={t('benders.isolationChart.title')}
        isDefaultExpanded
        subHeaderKey={SubHeaderKey.BendersIsolationChart}
      >
        <BenderIsolationChart
          selectedBenders={[asset]}
          datePeriod={datePeriod}
          codeSystemKeys={CODE_SYSTEM_KEYS_ISOLATION}
          onZoomUpdated={zoomUpdated}
          view="card"
        />
      </SubHeaderWithExpandableInfo>
      <SubHeaderWithExpandableInfo
        title={t('benders.voltageChart.title')}
        isDefaultExpanded
        subHeaderKey={SubHeaderKey.BenderVoltageChart}
      >
        <BenderVoltageChart
          selectedBenders={[asset]}
          datePeriod={datePeriod}
          codeSystemKeys={CODE_SYSTEM_KEYS_VOLTAGE}
          groupedByBender={false}
          onZoomUpdated={zoomUpdated}
        />
      </SubHeaderWithExpandableInfo>
      <SubHeaderWithExpandableInfo
        title={t('weatherChart.title', { stationName: asset.baneDataLocationName })}
        hideText={t('weatherChart.hideWeather')}
        isDefaultExpanded
        subHeaderKey={SubHeaderKey.WeatherChart}
      >
        <WeatherChart baneDataLocationId={asset.baneDataLocationId} fromDate={fromDate} toDate={toDate} chartFocus={chartFocus} />
      </SubHeaderWithExpandableInfo>
      <SubHeaderWithExpandableInfo
        title={
          <>
            {t('benders.status')} <AlarmCountChip alarmCount={numSensorAlarms} />
          </>
        }
        hideText={`${t('general.hide')} ${t('benders.status').toLowerCase()}`}
        subHeaderKey={SubHeaderKey.BenderStatus}
      >
        <Grid container>
          {alarmSensors.map(sensor => (
            <Grid item xs={12} md={6} lg={4} key={sensor.id}>
              <SensorValue codeSystem={sensor.codeSystem} sensor={sensor} domain="SEF" />
            </Grid>
          ))}
        </Grid>
      </SubHeaderWithExpandableInfo>
      {benderDetails?.inCircuit && (
        <SubHeaderWithExpandableInfo
          title={`${t('benders.inCircuit')} (${benderDetails.inCircuit.length})`}
          hideText={`${t('general.hide')} ${t('benders.inCircuit').toLowerCase()}`}
          subHeaderKey={SubHeaderKey.BenderInCircuit}
        >
          <Grid container>
            {benderDetails.inCircuit.length ? (
              benderDetails.inCircuit.map(areaAsset => (
                <Grid item xs={12} key={areaAsset.baneDataId}>
                  <StyledAreaAssetContainer>
                    <AssetTypeIcon assetType={areaAsset.assetType} /> {areaAsset.name}
                  </StyledAreaAssetContainer>
                </Grid>
              ))
            ) : (
              <Alert severity="info" margin={16} fullWidth>
                {t('benders.noObjectsInCircuit')}
              </Alert>
            )}
          </Grid>
        </SubHeaderWithExpandableInfo>
      )}
    </>
  )
}
