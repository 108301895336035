import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import TroubleshootOutlinedIcon from '@mui/icons-material/TroubleshootOutlined'

import { IconWithText } from 'src/Components/IconWithText'
import { ignoreStyleProps } from 'src/Utils/style'
import themeColors from 'src/theme'

type AlarmSize = 'large' | 'small'

const StyledIconContainer = styled('div')`
  color: ${themeColors.irregularData};
`

const StyledIrregularDataIcon = styled(TroubleshootOutlinedIcon, ignoreStyleProps('iconSize'))<{ iconSize: AlarmSize }>`
  vertical-align: text-bottom;
  margin-left: ${props => (props.iconSize === 'small' ? '5px' : 0)};
`

export const IrregularDataIcon = () => {
  const { t } = useTranslation()

  return (
    <StyledIconContainer>
      <IconWithText
        aria-label={t('alarms.alarmIndicator.IrregularData')}
        title={t('alarms.alarmIndicator.IrregularData')}
        icon={<StyledIrregularDataIcon fontSize="large" iconSize="large" />}
        text=""
        isUpperCase
        gap={0.42}
      />
    </StyledIconContainer>
  )
}
