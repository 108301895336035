import * as d3 from 'd3'
import * as d3zoom from 'd3-zoom'
import { nanoid } from 'nanoid'
import { format } from 'date-fns/format'

import type {
  SimpleSelection,
  LinearTimeScale,
  LinearNumberScale,
  Extent,
  ChartLabels,
  Rect,
  ChartFocus,
  SVGGSelection,
  SVGRectSelection,
  NumberInterval,
} from 'src/Types/ChartTypes'
import type { Language } from 'src/Types/LanguageTypes'
import type { Weather } from 'src/Types/WeatherTypes'

import { minNum, maxNum, clamp, roundToStep } from 'src/Utils/number'
import { createTimeMultiFormat } from 'src/Utils/createTimeMultiFormat'
import { getD3Locale } from 'src/Language/d3Locale'
import { formatNumber } from 'src/Utils/format'
import { getTooltipPosition, tooltipArrowBoxWidth, tooltipArrowPolygon, getTooltipConfig } from 'src/Utils/chart'
import { createWindIcon, WIND_ICON_SIZE } from 'src/Features/Weather/WindIcon/createWindIcon'
import themeColors from 'src/theme'
import { enablePageScrollForChartRenderer } from 'src/Components/Chart/enablePageScrollForChartRenderer'

type ChartProps = {
  chart: HTMLDivElement
  classes: {
    tooltip: string
    tooltipArrow: string
    zoomButton: string
  }
  timeline?: NumberInterval
  enableZoom?: boolean
}

const parseTemperature = (weather: Weather[]) => {
  const mapped = weather.map<[number, number]>(w => [w.fromTime, w.temperature])
  if (weather.length) {
    // Because each entry has a from and to date, the chart needs an extra point at the end to display correctly.
    const lastEntry = weather.slice(-1)[0]
    mapped.push([Math.min(lastEntry.toTime, Date.now()), lastEntry.temperature])
  }
  return mapped
}

const parsePrecipitation = (weather: Weather[]) => weather.map<[number, number]>(w => [w.fromTime, w.precipitation])

// The chart renders partially towards the next point, but since the data isn't actually fetched,
// we draw a virtual future point using the same y value as the last point.
const parseWindSpeed = (weather: Weather[]): [number, number][] => {
  if (weather.length > 0) {
    return [...weather.map<[number, number]>(w => [w.fromTime, w.windSpeed]), [Date.now(), weather[weather.length - 1].windSpeed]]
  }

  return []
}

const parseWindDirection = (weather: Weather[]) => weather.map<[number, number]>(w => [w.fromTime, w.windDirection])

const renderTemperatureLine = (xScale: LinearTimeScale, yScale: LinearNumberScale) =>
  d3
    .line()
    .curve(d3.curveStepAfter)
    .x(d => xScale(d[0])!)
    .y(d => yScale(d[1])!)

const renderWindSpeedLine = (xScale: LinearTimeScale, yScale: LinearNumberScale) =>
  d3
    .line()
    .curve(d3.curveLinear)
    .x(d => xScale(d[0])!)
    .y(d => yScale(d[1])!)

enum ElementClass {
  zeroLine = 'zeroLine',
  temperature = 'temperature',
  precipitationContainer = 'precipitationContainer',
  precipitation = 'precipitation',
  windSpeed = 'windSpeed',
  windArrowContainer = 'windArrowContainer',
  windArrow = 'windArrow',
  focus = 'focus',
  tooltipPoint = 'tooltip-point',
  tooltipArrow = 'tooltip-arrow',
  tooltipPoints = 'tooltip-points',
  zoomInButton = 'zoom-in-button',
  zoomOutButton = 'zoom-out-button',
}
const classSelector = (key: ElementClass) => `.${key}`

const CHART_MARGIN: Rect = { top: 5, right: 0, bottom: 20, left: 36 }
const CHART_HEIGHT = 400
const ZOOM_BUTTON_MARGIN = 10
const MIN_ZOOM = 1
const MAX_ZOOM_MAGIC_CONSTANT = 20000000 // This magic number results in a nice max zoom resolution
const X_PIXELS_PER_TICK = 90
const PRECIPITATION_WIDTH = 20 * 60 * 1000 // 20 minutes
const WIND_ARROW_OFFSET = -WIND_ICON_SIZE.height - 4
const TOOLTIP_DATE_FORMAT = 'dd.MM.yyyy HH:mm'
const TOOLTIP_POINT_RADIUS = 4

export class WeatherChartRenderer {
  chart: HTMLDivElement

  chartWidth: number

  timeline?: NumberInterval

  weather: Weather[] = []

  temperature: [number, number][] = []

  precipitation: [number, number][] = []

  windSpeed: [number, number][] = []

  windDirection: [number, number][] = []

  svgContainer: SimpleSelection<SVGSVGElement>

  xScale: LinearTimeScale

  yScale: LinearNumberScale

  currentXScale: LinearTimeScale

  currentYScale: LinearNumberScale

  xAxis: SVGGSelection

  yAxis: SVGGSelection

  yAxisGrid: SVGGSelection

  zoom: d3.ZoomBehavior<SVGSVGElement, unknown>

  chartFocus: ChartFocus = { fromDate: 0, toDate: 0, transform: d3.zoomIdentity }

  clipUrl: string

  svgClipPath: SVGRectSelection

  mouseOverlay: SVGRectSelection

  tooltip: SimpleSelection<HTMLDivElement>

  tooltipArrow: SimpleSelection<SVGSVGElement>

  tooltipPoints: SVGGSelection

  precipitationContainer: SVGGSelection

  windArrowContainer: SVGGSelection

  multiTimeFormat?: ReturnType<typeof createTimeMultiFormat>

  labels: ChartLabels = {}

  constructor({ chart, classes, timeline, enableZoom = false }: ChartProps) {
    this.chart = chart
    this.chartWidth = 0
    this.timeline = timeline

    this.xScale = d3.scaleTime()

    this.yScale = d3.scaleLinear().range([CHART_HEIGHT - CHART_MARGIN.bottom, CHART_MARGIN.top])

    this.currentXScale = this.xScale
    this.currentYScale = this.yScale

    this.zoom = d3zoom.zoom<SVGSVGElement, any>().on('zoom', event => this.onZoom(event))

    this.svgContainer = d3.select(this.chart).html(null).append('svg').attr('height', CHART_HEIGHT)

    const clipId = nanoid()
    this.clipUrl = `url(#${clipId})`

    this.svgClipPath = this.svgContainer
      .append('clipPath')
      .attr('id', clipId)
      .append('rect')
      .attr('x', CHART_MARGIN.left + 1)
      .attr('y', CHART_MARGIN.top)
      .attr('height', CHART_HEIGHT - CHART_MARGIN.top - CHART_MARGIN.bottom)

    this.xAxis = this.svgContainer.append('g').attr('transform', `translate(0,${CHART_HEIGHT - CHART_MARGIN.bottom})`)

    this.yAxisGrid = this.svgContainer
      .append('g')
      .attr('transform', `translate(${CHART_MARGIN.left},0)`)
      .attr('color', themeColors.disabled)

    this.yAxis = this.svgContainer.append('g').attr('transform', `translate(${CHART_MARGIN.left},0)`)

    this.mouseOverlay = this.svgContainer
      .append('rect')
      .attr('x', CHART_MARGIN.left + 1)
      .attr('y', CHART_MARGIN.top)
      .attr('height', CHART_HEIGHT - CHART_MARGIN.top - CHART_MARGIN.bottom)
      .style('fill', 'none')
      .style('pointer-events', 'all')
      .on('mouseout', () => this.hideTooltip())
      .on('mousemove', event => this.mouseMove(event as MouseEvent))

    if (enableZoom) {
      enablePageScrollForChartRenderer(this.svgContainer, this.zoom)
    }

    if (enableZoom) {
      d3.select(this.chart)
        .append('button')
        .classed(ElementClass.zoomInButton, true)
        .classed(classes.zoomButton, true)
        .style('right', `${CHART_MARGIN.right + ZOOM_BUTTON_MARGIN}px`)
        .style('bottom', `${CHART_MARGIN.bottom + 30 + ZOOM_BUTTON_MARGIN}px`)
        .text('+')
        .on('click', () => this.zoom.scaleBy(this.svgContainer.transition().duration(250), 1.5))

      d3.select(this.chart)
        .append('button')
        .classed(ElementClass.zoomOutButton, true)
        .classed(classes.zoomButton, true)
        .style('right', `${CHART_MARGIN.right + ZOOM_BUTTON_MARGIN}px`)
        .style('bottom', `${CHART_MARGIN.bottom + ZOOM_BUTTON_MARGIN}px`)
        .text('-')
        .on('click', () => this.zoom.scaleBy(this.svgContainer.transition().duration(250), 0.5))
    }

    this.precipitationContainer = this.svgContainer
      .append('g')
      .classed(ElementClass.precipitationContainer, true)
      .style('pointer-events', 'none')
      .attr('fill', themeColors.weather.rain)
      .attr('clip-path', this.clipUrl)

    this.windArrowContainer = this.svgContainer
      .append('g')
      .classed(ElementClass.windArrowContainer, true)
      .style('pointer-events', 'none')
      .attr('fill', themeColors.chartColors.wind)
      .attr('stroke', themeColors.chartColors.wind)
      .attr('stroke-width', 1.5)
      .attr('clip-path', this.clipUrl)

    this.tooltip = d3.select(this.chart).append('div').classed(classes.tooltip, true).style('pointer-events', 'none')

    this.tooltipPoints = this.svgContainer
      .append('g')
      .classed(ElementClass.tooltipPoints, true)
      .style('pointer-events', 'none')
      .attr('clip-path', this.clipUrl)

    this.tooltipArrow = d3
      .select(this.chart)
      .append('svg')
      .style('position', 'absolute')
      .attr('height', getTooltipConfig().arrowHeight)
      .style('pointer-events', 'none')

    this.tooltipArrow.append('polygon').classed(classes.tooltipArrow, true).classed(ElementClass.tooltipArrow, true)

    this.hideTooltip()
    this.sizeUpdated()
  }

  getDomain() {
    if (this.weather.length === 0) {
      return {
        xMin: Date.now(),
        xMax: Date.now(),
        yMax: 0,
        yMin: 0,
      }
    }

    const yVals = this.weather.map(weather => [weather.temperature, weather.precipitation, weather.windSpeed]).flat()

    let xRange = this.timeline
    if (!xRange) {
      const xMinVals = this.weather.map(weather => weather.fromTime)
      const xMaxVals = this.weather.map(weather => weather.toTime)
      xRange = {
        start: minNum(xMinVals),
        end: maxNum(xMaxVals),
      }
    }

    return {
      xMin: xRange.start,
      xMax: xRange.end,
      yMax: maxNum([...yVals, 0]),
      yMin: minNum([...yVals, 0]),
    }
  }

  hideTooltip() {
    this.tooltipPoints.style('display', 'none')
    this.tooltip.style('display', 'none')
    this.tooltipArrow.style('display', 'none')
  }

  updateTooltip(mouseX: number) {
    const xScale = this.currentXScale
    const yScale = this.currentYScale

    const tooltipConfig = {
      chartMargin: CHART_MARGIN,
      chartWidth: this.chartWidth,
    }

    const overlayX = Math.round(clamp(mouseX, 0, this.chartWidth))
    const timelineDate = xScale.invert(overlayX)
    const timelineMillis = Math.min(
      roundToStep(timelineDate.valueOf(), 60 * 60 * 1000),
      this.weather[this.weather.length - 1].fromTime
    )
    const temperatureEntry = this.temperature.find(([x]) => x >= timelineMillis)
    const windSpeedEntry = this.windSpeed.find(([x]) => x >= timelineMillis)
    const precipitationEntry = this.precipitation.find(([x]) => x >= timelineMillis)
    const temperature = temperatureEntry ? temperatureEntry[1] : 0
    const windSpeed = windSpeedEntry ? windSpeedEntry[1] : 0
    const precipitation = precipitationEntry ? precipitationEntry[1] : 0

    const renderX = xScale(timelineMillis)
    const targetY = Math.max(temperature, precipitation)
    const renderY = yScale(targetY || 0)

    if (renderX !== undefined && renderY !== undefined) {
      const pointYValues = [temperature, windSpeed, precipitation]

      this.tooltipPoints
        .selectAll(classSelector(ElementClass.tooltipPoint))
        .attr('transform', (_, i) => `translate(${renderX},${yScale(pointYValues[i])})`)

      const tempText = `${this.labels.temperature}: ${formatNumber(temperature, 1)} °C`
      const windText = `${this.labels.windSpeed}: ${formatNumber(windSpeed, 1)} m/s`
      const rainText = `${this.labels.precipitation}: ${formatNumber(precipitation, 1)} mm`

      const tooltipText = [
        `<li style="color: ${themeColors.primary}"><span>${tempText}</span></li>`,
        `<li style="color: ${themeColors.chartColors.wind}"><span>${windText}</span></li>`,
        `<li style="color: ${themeColors.weather.rain}"><span>${rainText}</span></li>`,
      ].join('')

      this.tooltip.html(`<b>${format(timelineMillis, TOOLTIP_DATE_FORMAT)}</b><ul>${tooltipText}</ul>`)

      const tooltipPosition = getTooltipPosition(renderX, renderY, this.tooltip.node()!, this.chartWidth, {
        chartMargin: CHART_MARGIN,
      })

      this.tooltip.style('left', `${tooltipPosition.box.x}px`).style('top', `${tooltipPosition.box.y}px`)

      const arrowPos = tooltipPosition.arrow
      const tipOffset = arrowPos.tipX - arrowPos.x
      this.tooltipArrow
        .attr('width', tooltipArrowBoxWidth(tipOffset, tooltipConfig))
        .style('left', `${Math.min(arrowPos.x, arrowPos.tipX)}px`)
        .style('top', `${arrowPos.y}px`)
        .select(classSelector(ElementClass.tooltipArrow))
        .attr('points', tooltipArrowPolygon(tipOffset, tooltipConfig))
    }
  }

  mouseMove(event: MouseEvent) {
    const overlay = this.mouseOverlay.node()
    if (this.weather.length && overlay) {
      const mouseX = d3.pointer(event, overlay)[0]
      this.tooltipPoints.style('display', null)
      this.tooltip.style('display', null)
      this.tooltipArrow.style('display', null)
      this.updateTooltip(mouseX)
    }
  }

  setData(weather: Weather[]) {
    this.weather = weather
    this.temperature = parseTemperature(weather)
    this.precipitation = parsePrecipitation(weather)
    this.windSpeed = parseWindSpeed(weather)
    this.windDirection = parseWindDirection(weather)

    const domain = this.getDomain()
    this.xScale.domain([domain.xMin, domain.xMax])
    this.yScale.domain([domain.yMin, domain.yMax]).nice()
    this.zoom.scaleExtent([MIN_ZOOM, (domain.xMax - domain.xMin) / MAX_ZOOM_MAGIC_CONSTANT])
    this.resetZoom()
  }

  setFocus(focus: ChartFocus) {
    this.chartFocus = focus
    this.updateFocus()
  }

  setLabels(labels: ChartLabels, currentLanguage: Language) {
    this.labels = labels

    d3.select(this.chart).select(classSelector(ElementClass.zoomInButton)).attr('title', labels.zoomIn)

    d3.select(this.chart).select(classSelector(ElementClass.zoomOutButton)).attr('title', labels.zoomOut)

    this.multiTimeFormat = createTimeMultiFormat(getD3Locale(currentLanguage))
  }

  getChartWidth() {
    const parent = this.chart.parentElement
    return parent ? parent.clientWidth : 0
  }

  sizeUpdated() {
    const newWidth = Math.max(0, this.getChartWidth())
    if (newWidth !== this.chartWidth && newWidth > 0) {
      this.chartWidth = newWidth

      const clipWidth = this.chartWidth - CHART_MARGIN.left - CHART_MARGIN.right - 1

      this.svgContainer.attr('viewBox', [0, 0, this.chartWidth, CHART_HEIGHT].toString()).attr('width', this.chartWidth)

      this.xScale.range([CHART_MARGIN.left, this.chartWidth - CHART_MARGIN.right])

      this.svgClipPath.attr('width', this.chartWidth - CHART_MARGIN.left - CHART_MARGIN.right)

      const extent: Extent = [
        [CHART_MARGIN.left, CHART_MARGIN.top],
        [this.chartWidth - CHART_MARGIN.right, CHART_HEIGHT - CHART_MARGIN.bottom],
      ]

      this.zoom.translateExtent(extent).extent(extent)

      this.resetZoom()

      this.mouseOverlay.attr('width', clipWidth)
    }
  }

  updateFocus() {
    const xScale = this.currentXScale
    this.svgContainer
      .selectAll(classSelector(ElementClass.focus))
      .attr('width', Math.max(2, xScale(this.chartFocus.toDate)! - xScale(this.chartFocus.fromDate)!))
      .attr('x', xScale(this.chartFocus.fromDate)!)
  }

  update() {
    const xScale = this.currentXScale
    const yScale = this.currentYScale

    const domain = this.getDomain()

    this.svgContainer.selectAll<SVGPathElement, number>(classSelector(ElementClass.zeroLine)).attr('d', d => {
      const pos = `${xScale(domain.xMin)},${Math.ceil(yScale(d)!) + 0.5}`
      const width = xScale(domain.xMax)! - CHART_MARGIN.right
      return `M${pos}H${width}`
    })

    this.svgContainer
      .selectAll<SVGRectElement, [number, number][]>(classSelector(ElementClass.temperature))
      .attr('d', d => renderTemperatureLine(xScale, yScale)(d))

    this.svgContainer
      .selectAll<SVGRectElement, [number, number][]>(classSelector(ElementClass.windSpeed))
      .attr('d', d => renderWindSpeedLine(xScale, yScale)(d))

    this.windArrowContainer
      .selectAll<SVGPathElement, [number, number]>(classSelector(ElementClass.windArrow))
      .attr('d', (_, i) => createWindIcon(this.windSpeed[i][1]))
      .attr('transform', (d, i) => {
        const windSpeed = this.windSpeed[i][1]
        const x = xScale(d[0])! - WIND_ICON_SIZE.width / 2
        const y = yScale(windSpeed)! - CHART_MARGIN.top + WIND_ARROW_OFFSET
        return `translate(${x},${y}) rotate(${d[1]} ${WIND_ICON_SIZE.width / 2} ${WIND_ICON_SIZE.height / 2})`
      })

    this.precipitationContainer
      .selectAll<SVGRectElement, [number, number]>(classSelector(ElementClass.precipitation))
      .attr('width', d => Math.max(xScale(d[0] + PRECIPITATION_WIDTH)! - xScale(d[0])!, 0))
      .attr('x', d => xScale(d[0] - PRECIPITATION_WIDTH / 2)!)

    this.updateFocus()

    let axisBottom = d3.axisBottom(xScale).ticks(Math.floor(this.chartWidth / X_PIXELS_PER_TICK))
    const timeFormat = this.multiTimeFormat
    if (timeFormat) {
      axisBottom = axisBottom.tickFormat(v => timeFormat(v.valueOf()))
    }

    this.xAxis.call(axisBottom)
  }

  resetZoom() {
    this.zoom.transform(this.svgContainer.transition().duration(0), d3.zoomIdentity)
  }

  onZoom(event: d3.D3ZoomEvent<SVGSVGElement, unknown>) {
    const transform: d3.ZoomTransform = event.transform
    this.currentXScale = transform.rescaleX(this.xScale)

    this.hideTooltip()
    this.update()
  }

  render() {
    const yScale = this.currentYScale

    this.yAxis.call(d3.axisLeft(yScale))

    this.yAxisGrid.call(
      d3
        .axisLeft(yScale)
        .tickFormat(_ => '')
        .tickSize(-this.chartWidth + CHART_MARGIN.right + CHART_MARGIN.left)
    )

    const domain = this.getDomain()

    this.svgContainer
      .selectAll(classSelector(ElementClass.focus))
      .data([this.chartFocus])
      .join('rect')
      .classed(ElementClass.focus, true)
      .attr('fill', themeColors.chartColors.weatherFocus)
      .attr('y', CHART_MARGIN.top)
      .attr('height', CHART_HEIGHT - CHART_MARGIN.top - CHART_MARGIN.bottom)
      .style('pointer-events', 'none')
      .attr('clip-path', this.clipUrl)

    this.precipitationContainer
      .selectAll(classSelector(ElementClass.precipitation))
      .data(this.precipitation.filter(p => p[1] > 0))
      .join('rect')
      .classed(ElementClass.precipitation, true)
      .attr('height', d => yScale(0)! - yScale(d[1])!)
      .attr('y', d => yScale(d[1])!)

    this.svgContainer
      .selectAll(classSelector(ElementClass.temperature))
      .data([this.temperature])
      .join('path')
      .classed(ElementClass.temperature, true)
      .attr('fill', 'none')
      .attr('stroke-width', 2)
      .attr('stroke', themeColors.primary)
      .style('pointer-events', 'none')
      .attr('clip-path', this.clipUrl)

    this.svgContainer
      .selectAll(classSelector(ElementClass.windSpeed))
      .data([this.windSpeed])
      .join('path')
      .classed(ElementClass.windSpeed, true)
      .attr('fill', 'none')
      .attr('stroke-width', 2)
      .attr('stroke', themeColors.chartColors.wind)
      .style('pointer-events', 'none')
      .attr('clip-path', this.clipUrl)

    this.windArrowContainer
      .selectAll(classSelector(ElementClass.windArrow))
      .data(this.windDirection)
      .join('path')
      .classed(ElementClass.windArrow, true)

    // Tooltip
    this.tooltipPoints
      .raise()
      .selectAll(classSelector(ElementClass.tooltipPoint))
      .data([this.temperature, this.precipitation, this.windSpeed])
      .join('circle')
      .classed(ElementClass.tooltipPoint, true)
      .attr('r', TOOLTIP_POINT_RADIUS)

    this.svgContainer
      .selectAll(classSelector(ElementClass.zeroLine))
      .data(domain.yMin < 0 ? [0] : []) // Only add zero line if the min temp is below zero
      .join('path')
      .classed(ElementClass.zeroLine, true)
      .attr('stroke', themeColors.primary)
      .attr('stroke-width', 1)

    this.update()
  }
}
