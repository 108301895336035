import { useCardIdFromUrl } from 'src/Hooks/useCardIdFromUrl'
import type { AssetWithAlarms } from 'src/Types/AssetWithAlarms'
import { getCardIdByAsset } from 'src/Utils/cardId'

/**
 * This hook adds the card from the direct link (#card_...) to the top of the list if it is not already there.
 */
export const useAddCardFromUrl = () => {
  const cardIdFromUrl = useCardIdFromUrl()

  return (filteredAlarms: AssetWithAlarms[], assetsWithAlarms: AssetWithAlarms[]) => {
    if (cardIdFromUrl) {
      const hasCardIdFromUrlInFilteredAlarms = filteredAlarms.some(a => getCardIdByAsset(a.asset) === cardIdFromUrl)
      if (!hasCardIdFromUrlInFilteredAlarms) {
        const assetFromCardId = assetsWithAlarms.find(a => getCardIdByAsset(a.asset) === cardIdFromUrl)
        if (assetFromCardId) {
          filteredAlarms.unshift(assetFromCardId)
        }
      }
    }

    return filteredAlarms
  }
}
