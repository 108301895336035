import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import IconButton from '@mui/material/IconButton'
import ArrowLeftIcon from '@mui/icons-material/ArrowBackOutlined'
import ArrowRightIcon from '@mui/icons-material/ArrowForwardOutlined'
import Divider from '@mui/material/Divider'

import { DrawerHeaderContainer } from 'src/Components/DrawerHeaderContainer'
import { IconWithText } from 'src/Components/IconWithText'
import { LocationsAndDatePeriodSelector } from 'src/Components/Header/LocationsAndDatePeriodSelector'
import { StyledActionButton } from 'src/Components/SidePanelMenu/StyledActionButton'
import { StyledCircleIcon } from 'src/Components/SidePanelMenu/StyledCircleIcon'
import { PageSelector } from './PageSelector'
import Logo from 'src/Assets/banenor-logo.svg'
import { useBreakpointDown } from 'src/Hooks/useBreakpoint'
import { useUserProfileStore } from 'src/Store/userProfile'
import themeColors from 'src/theme'
import { useCardIdFromUrl } from 'src/Hooks/useCardIdFromUrl'

const drawerOpenWidth = 300
const drawerClosedWidth = 95

const StyledDrawer = styled(Drawer)`
  z-index: 1300;
`

const StyledLogo = styled('span')`
  padding: 16px;
`

const StyledFilterContainer = styled('div')`
  margin-top: 10px;
`

const StyledCloseIconButton = styled(IconButton)`
  font-size: 14px;
  padding-top: 0;
  margin-top: 10px;
  margin-left: 10px;
  ${props => props.theme.breakpoints.down('lg')} {
    margin-left: 16px;
  }
  ${props => props.theme.breakpoints.down('md')} {
    color: white;
    margin-left: 0px;
    div > svg {
      color: ${themeColors.secondary};
      background-color: white;
    }
  }
`

const StyledDrawerContentContainer = styled('div')`
  width: ${drawerOpenWidth}px;
`

const StyledClosedMenuContainer = styled('div')`
  width: ${drawerClosedWidth}px;
`

type OwnProps = {
  onClearFilters?: () => void
  hideClearButton?: boolean
  onSaveSearch?: () => void
  hideSaveButton?: boolean
  children?: ReactNode
  footer?: ReactNode
}

export const SidePanelMenu = ({
  onClearFilters,
  hideClearButton = false,
  onSaveSearch,
  hideSaveButton = false,
  children,
  footer,
}: OwnProps) => {
  const { t } = useTranslation()

  const isMedium = useBreakpointDown('md')
  const isMediaSM = useBreakpointDown('sm')
  const fontSize = isMediaSM ? 'small' : 'medium'

  const updateSetting = useUserProfileStore(state => state.settings.updateSetting)
  const isMenuOpen = useUserProfileStore(state => state.settings.settings.isMenuOpen)
  const toggleMenuDrawer = () => updateSetting('isMenuOpen', !isMenuOpen)

  if (useCardIdFromUrl()) {
    return null
  }

  const drawerContent = (
    <StyledDrawerContentContainer>
      <DrawerHeaderContainer>
        {isMedium && (
          <StyledLogo>
            <img src={Logo} alt="Bane NOR logo" />
          </StyledLogo>
        )}
        <StyledCloseIconButton onClick={toggleMenuDrawer}>
          <IconWithText
            icon={
              <StyledCircleIcon>
                <ArrowLeftIcon fontSize={fontSize} />
              </StyledCircleIcon>
            }
            text={t('sideMenu.hideMenu')}
          />
        </StyledCloseIconButton>
      </DrawerHeaderContainer>
      <List>
        <LocationsAndDatePeriodSelector />
        <ListItem>
          <PageSelector />
        </ListItem>
      </List>
      <Divider />
      <List>
        {children}
        {!hideClearButton && !!onClearFilters && (
          <ListItem>
            <StyledActionButton variant="outlined" onClick={onClearFilters}>
              {t('general.reset')}
            </StyledActionButton>
          </ListItem>
        )}
        {!hideSaveButton && !!onSaveSearch && (
          <ListItem>
            <StyledActionButton variant="outlined" onClick={onSaveSearch} title={t('pageFilters.saveStartPageTitle')}>
              {t('pageFilters.saveStartPage')}
            </StyledActionButton>
          </ListItem>
        )}
        {footer}
      </List>
    </StyledDrawerContentContainer>
  )

  const renderOpenMenuDrawer = () => {
    return (
      <>
        <StyledDrawer
          variant="temporary"
          open={isMedium ? isMenuOpen : false}
          onClose={toggleMenuDrawer}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: isMedium ? 'block' : 'none',
          }}
        >
          {drawerContent}
        </StyledDrawer>
        <StyledDrawer
          variant="persistent"
          open={isMenuOpen}
          onClose={toggleMenuDrawer}
          sx={{
            display: isMedium ? 'none' : 'block',
          }}
        >
          {drawerContent}
        </StyledDrawer>
      </>
    )
  }

  return (
    <StyledFilterContainer>
      {!isMenuOpen && !isMedium && (
        <StyledDrawer
          variant="persistent"
          open={!isMenuOpen}
          onClose={toggleMenuDrawer}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <StyledClosedMenuContainer>
            <DrawerHeaderContainer />
            <List>
              <StyledCloseIconButton onClick={toggleMenuDrawer}>
                <IconWithText
                  icon={
                    <StyledCircleIcon>
                      <ArrowRightIcon fontSize={fontSize} />
                    </StyledCircleIcon>
                  }
                  text={t('sideMenu.showMenu')}
                />
              </StyledCloseIconButton>
              <PageSelector isButtonView />
            </List>
          </StyledClosedMenuContainer>
        </StyledDrawer>
      )}
      {renderOpenMenuDrawer()}
    </StyledFilterContainer>
  )
}
