import { Chip, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useEffect, useRef, useState } from 'react'

const StyledButton = styled('span')`
  overflow: hidden;
  display: block;
  white-space: nowrap;
  width: inherit;
  display: flex;
`

const StyledContent = styled('span')`
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledChip = styled(Chip)`
  margin-left: 8px;
`

type OwnProps = { buttonContent: string; numSelected: number }

export const LocationButton = (props: OwnProps) => {
  const contentElement = useRef<HTMLElement | null>(null)
  const [isEllipsisActive, setIsEllipsisActive] = useState(false)

  useEffect(() => {
    if (contentElement?.current) {
      setIsEllipsisActive(contentElement.current.offsetWidth < contentElement.current.scrollWidth)
    }
  }, [contentElement, props.buttonContent])

  return (
    <Tooltip title={isEllipsisActive ? `${props.buttonContent}` : ''} placement="bottom" arrow>
      <StyledButton>
        <StyledContent ref={contentElement}>{props.buttonContent}</StyledContent>
        {props.numSelected > 1 && <StyledChip label={`+${props.numSelected - 1}`} size="small" />}
      </StyledButton>
    </Tooltip>
  )
}
