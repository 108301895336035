import { styled } from '@mui/material/styles'
import Skeleton from '@mui/material/Skeleton'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

const StyledCard = styled(Card)(
  props => `
  margin: 16px;
  border: 5px solid transparent;
  border-radius: 0;
  ${props.theme.breakpoints.down('md')} {
    margin: 16px 8px;
  }
  ${props.theme.breakpoints.down('sm')} {
    margin: 8px 4px;
  }
`
)

const StyledCardContent = styled(CardContent)`
  padding-bottom: 0;
`

const StyledFavoriteSkeleton = styled(Skeleton)`
  margin-right: 10px;
`

export const AssetCardSkeleton = () => (
  <StyledCard>
    <StyledCardContent>
      <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item xs={3} sm={2} md={1} />
        <Grid item xs={8} sm={9} md={10}>
          <Grid container>
            <Grid item xs={8}>
              <Skeleton variant="text" animation="wave" height={34} width="20%" />
              <Skeleton variant="text" animation="wave" height={24} width="50%" />
            </Grid>
            <Grid item xs={4}>
              <Grid container justifyContent="flex-end">
                <StyledFavoriteSkeleton variant="circular" animation="wave" height={24} width={24} />
                <Skeleton variant="text" animation="wave" height={24} width={160} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={1} justifyContent="flex-end">
          <Skeleton variant="rectangular" animation="wave" height={20} width={20} />
        </Grid>
      </Grid>
    </StyledCardContent>
  </StyledCard>
)
