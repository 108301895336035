import { useEffect } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'

import { CacheBuster } from './Components/CacheBuster'
import { AppMessagesController } from './Features/AppMessages/AppMessagesController'
import { SoundManager } from './Components/SoundManager'
import { Auth } from './Auth'
import { StyledEngineProvider } from '@mui/material'
import { muiTheme } from 'src/muiTheme'
import { ErrorBoundary } from 'src/Components/ErrorBoundary'
import type { FeatureKey } from 'src/Hooks/useSessionFeature'
import { useSessionFeatures } from 'src/Hooks/useSessionFeature'

// These are based on modernizr's checks
const testStorageSupport = (storage: Storage) => {
  const val = 'test'
  try {
    storage.setItem(val, val)
    storage.removeItem(val)
    return true
  } catch (e) {
    return false
  }
}

const testCookieSupport = () => {
  if (navigator.cookieEnabled) {
    return true
  }

  document.cookie = 'cookietest=1'
  const ret = document.cookie.indexOf('cookietest=') !== -1
  document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT'
  return ret
}
// end browser tests

const queryClient = new QueryClient()

export const Root = () => {
  const { setFeatures } = useSessionFeatures()

  useEffect(() => {
    const featuresParam = new URLSearchParams(window.location.search).get('features')
    if (!featuresParam?.length) {
      return
    }
    const features = featuresParam?.split(',') || []
    setFeatures(features as FeatureKey[])
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!testStorageSupport(localStorage)) {
      console.error('Feil: ingen tilgang til localStorage')
    }
    if (!testStorageSupport(sessionStorage)) {
      console.error('Feil: ingen tilgang til sessionStorage')
    }
    if (!testCookieSupport()) {
      console.error('Feil: ingen tilgang til cookies')
    }
  }, [])

  return (
    <ErrorBoundary>
      <SoundManager>
        <QueryClientProvider client={queryClient}>
          <CacheBuster>
            <StyledEngineProvider injectFirst>
              <MuiThemeProvider theme={muiTheme}>
                <Auth />
                <AppMessagesController />
              </MuiThemeProvider>
            </StyledEngineProvider>
          </CacheBuster>
        </QueryClientProvider>
      </SoundManager>
    </ErrorBoundary>
  )
}
