import type { ComponentProps } from 'react'
import Tooltip from '@mui/material/Tooltip'
import visuallyHidden from '@mui/utils/visuallyHidden'
import { AlarmIndicatorIcon } from 'src/Components/AlarmIndicatorIcon'

type OwnProps = {
  title?: string
} & ComponentProps<typeof AlarmIndicatorIcon>

export const AlarmIndicator = ({ alarmIndicator, title = '', size = 'medium' }: OwnProps) => {
  return (
    <Tooltip title={title} placement="top" arrow>
      <span>
        <span style={visuallyHidden}>{title}</span>
        <AlarmIndicatorIcon alarmIndicator={alarmIndicator} size={size} />
      </span>
    </Tooltip>
  )
}
