import type { CustomIconProps } from 'src/Components/CustomIcon'
import { CustomIcon } from 'src/Components/CustomIcon'

export const NeighborsIcon = (props: CustomIconProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <CustomIcon {...props}>
    <path d="M3,13l4,-4v8z" />
    <path d="M9,12h6v2h-6z" />
    <path d="M17,9l4,4l-4,4z" />
    <path d="M9,6h6h-3v-3v6v-3" strokeWidth="2" stroke={props.color} />
  </CustomIcon>
)
