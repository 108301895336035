import { useTranslation } from 'react-i18next'

import type { RackSectionSensor } from 'src/Types/Rack'
import type { PopperReferenceObject } from 'src/Types/PopperReferenceObject'
import { AssetInfoPopper } from 'src/Components/AssetInfoPopper'

type OwnProps = {
  rackSection: RackSectionSensor
  anchorElement: PopperReferenceObject
}

export const RackSectionInfoPopper = ({ rackSection, anchorElement }: OwnProps) => {
  const { t } = useTranslation()

  return (
    <AssetInfoPopper
      id={rackSection.baneDataId}
      header={rackSection.name}
      anchorElement={anchorElement}
      rows={[
        {
          label: t('racks.chart.info.baneDataId'),
          value: rackSection.baneDataId,
        },
        {
          label: t('racks.chart.info.location'),
          value: rackSection.baneDataLocationName,
        },
        {
          label: t('racks.chart.info.parentId'),
          value: rackSection.parentId,
        },
        {
          label: t('racks.chart.info.additionalId'),
          value: rackSection.additionalId,
        },
        {
          label: t('racks.chart.info.neighbors'),
          value: rackSection.neighbors.map(n => n.name).join(', '),
        },
      ]}
    />
  )
}
