import useTheme from '@mui/material/styles/useTheme'
import useMediaQuery from '@mui/material/useMediaQuery/'
import type { Breakpoint } from '@mui/material/styles/'

export const useBreakpoint = (direction: 'up' | 'down', breakpoint: Breakpoint | number) => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints[direction](breakpoint))
}

export const useBreakpointDown = useBreakpoint.bind(null, 'down')
export const useBreakpointUp = useBreakpoint.bind(null, 'up')
