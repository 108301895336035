import { useTranslation } from 'react-i18next'
import FavoriteOutlineIcon from '@mui/icons-material/StarBorderOutlined'
import FavoriteIcon from '@mui/icons-material/StarOutlined'

import type { Asset } from 'src/Types/AssetTypes'
import { useFavorite } from 'src/Hooks/useFavorite'
import { MenuItem } from 'src/Components/UIComponents'

type OwnProps = {
  asset: Asset
}

export const FavoriteMenuItem = ({ asset }: OwnProps) => {
  const { t } = useTranslation()
  const { isFavorite, toggleFavorite } = useFavorite(asset)

  const favoriteText = t(isFavorite ? 'favorites.removeFavorite' : 'favorites.addFavorite')

  return (
    <MenuItem onClick={toggleFavorite}>
      {isFavorite ? <FavoriteIcon /> : <FavoriteOutlineIcon />}
      {favoriteText}
    </MenuItem>
  )
}
