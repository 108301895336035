import { makeStyles } from 'tss-react/mui'

import themeColors from 'src/theme'

export const useChartStyles = makeStyles()({
  tooltip: {
    position: 'absolute',
    backgroundColor: themeColors.body,
    padding: '4px 6px',
    borderRadius: 6,
    fontSize: 12,
    lineHeight: '16px',
    pointerEvents: 'none',
    whiteSpace: 'nowrap',
    '& > ul': {
      listStyleType: 'none',
      display: 'grid',
      gridTemplateColumns: 'repeat(1, auto)',
      justifyContent: 'space-around',
      gap: '0 20px',
      margin: 0,
      padding: 0,
      '& > li': {
        '& > span': {
          color: themeColors.text,
        },
        '&::before': {
          content: '""',
          width: 8,
          height: 8,
          display: 'inline-block',
          marginRight: 4,
          backgroundColor: 'currentColor',
        },
      },
    },
  },
  tooltipArrow: {
    fill: themeColors.body,
    stroke: 'none',
  },
  zoomButton: {
    border: `1px solid ${themeColors.secondary}`,
    borderRadius: 25,
    padding: 0,
    backgroundColor: 'white',
    color: themeColors.secondary,
    fontWeight: 'bold',
    cursor: 'pointer',
    width: 30,
    height: 30,
    position: 'absolute',
    '&:hover': {
      border: `1px solid ${themeColors.tertiary}`,
      color: themeColors.tertiary,
    },
    '& > svg': {
      verticalAlign: 'middle',
    },
  },
})

/* eslint-disable max-len */
export const toggleForceDomainButton = `
  <svg width="20" height="20" viewbox="0 0 24 24">
    <path d="M13 6.99h3L12 3 8 6.99h3v10.02H8L12 21l4-3.99h-3z" />
  </svg>`

export const toggleWidthButton = `
  <svg width="20" height="20" viewbox="0 0 24 24" style="transform: rotate(90deg);">
    <path d="M13 6.99h3L12 3 8 6.99h3v10.02H8L12 21l4-3.99h-3z" />
  </svg>`

export const resetZoomButton = `
  <svg width="20" height="20" viewbox="0 0 24 24">
    <path d="M15 3l2.3 2.3-2.89 2.87 1.42 1.42L18.7 6.7 21 9V3h-6zM3 9l2.3-2.3 2.87 2.89 1.42-1.42L6.7 5.3 9 3H3v6zm6 12l-2.3-2.3 2.89-2.87-1.42-1.42L5.3 17.3 3 15v6h6zm12-6l-2.3 2.3-2.87-2.89-1.42 1.42 2.89 2.87L15 21h6v-6z" />
  </svg>`

export const zoomInButton = `
  <svg width="16" height="16" viewbox="0 0 24 24">
    <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
  </svg>`

export const zoomOutButton = `
  <svg width="16" height="16" viewbox="0 0 24 24">
  <path d="M19 13H5v-2h14v2z" />
  </svg>`

export const createDiagonalStripePattern = (thickness: number, height: number, color: string, id: string) => `
  <pattern
    id="${id}"
    x="0"
    y="0"
    width="${height}"
    height="8"
    patternUnits="userSpaceOnUse"
    patternTransform="rotate(45)"
  >
    <rect x="0" y="0" width="${thickness}" height="${height}" style="stroke: none; fill: ${color};" />
  </pattern>
`
