import type { AssetWithAlarms } from 'src/Types/AssetWithAlarms'

import { getLatestAlarmTime } from 'src/Utils/alarms'
import { useUserProfileStore } from 'src/Store/userProfile'
import { alarmExists, isRecentAlarm } from 'src/Providers/Settings/Alarms'

export const useOpenedAlarm = ({ asset, alarms }: AssetWithAlarms) => {
  const recentAlarmPeriod = useUserProfileStore(state => state.settings.settings.recentAlarmPeriod)
  const openedAlarms = useUserProfileStore(state => state.openedAlarms.openedAlarms)
  const addOpenedAlarm = useUserProfileStore(state => state.openedAlarms.addOpenedAlarm)
  const addPendingOpenedAlarm = useUserProfileStore(state => state.openedAlarms.addPendingOpenedAlarm)

  const baneDataId = asset.baneDataId
  const latestAlarm = getLatestAlarmTime(alarms)
  const isTrackedAlarm = isRecentAlarm(recentAlarmPeriod, latestAlarm)
  const isOpenedAlarm = !!latestAlarm && alarmExists({ latestAlarm, baneDataId }, openedAlarms)

  const onAddOpenedAlarm = () => {
    if (latestAlarm && isTrackedAlarm && !isOpenedAlarm) {
      addOpenedAlarm({
        latestAlarm,
        baneDataId,
      })
    }
  }

  const onAddPendingOpenedAlarm = () => {
    if (latestAlarm && isTrackedAlarm && !isOpenedAlarm) {
      addPendingOpenedAlarm({
        latestAlarm,
        baneDataId,
      })
    }
  }

  return {
    isOpenedAlarm: !isTrackedAlarm || isOpenedAlarm,
    addOpenedAlarm: onAddOpenedAlarm,
    addPendingOpenedAlarm: onAddPendingOpenedAlarm,
  }
}
