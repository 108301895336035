import createTheme from '@mui/material/styles/createTheme'
import themeColors from 'src/theme'

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: themeColors.primary,
    },
    secondary: {
      main: themeColors.secondary,
    },
    error: {
      light: themeColors.error,
      main: themeColors.errorText,
      contrastText: '#000',
    },
    warning: {
      main: themeColors.warning,
      contrastText: '#000',
    },
    info: {
      main: themeColors.info,
      contrastText: '#000',
    },
    success: {
      light: themeColors.success,
      main: themeColors.successText,
      contrastText: '#000',
    },
  },
  typography: {
    fontFamily: ['"Myriad Pro"', '"Myriad"', 'Arial', 'Helvetica', 'sans-serif'].join(','),
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  components: {
    MuiFormControl: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          color: themeColors.text,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: themeColors.text,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: themeColors.text,
        },
      },
    },
    MuiCollapse: {
      styleOverrides: {
        root: {
          color: themeColors.text,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: themeColors.text,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          color: themeColors.text,
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          color: themeColors.text,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: themeColors.text,
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
      styleOverrides: {
        root: {
          color: themeColors.text,
        },
        standard: {
          display: 'flex',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: themeColors.text,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: themeColors.text,
        },
      },
    },
  },
})
