import { useMemo } from 'react'
import { useLanguage } from 'src/Hooks/useLanguage'
import type { TrackCircuitCurrents } from 'src/Types/TrackCircuitCurrentTypes'
import { TrackCircuitCurrentType } from 'src/Types/TrackCircuitCurrentTypes'
import { partition } from 'src/Utils/array'
import type { ChartLegend } from 'src/Components/Chart/Legends/ChartLegend'
import type { TrackCircuit } from 'src/Types/TrackCircuitTypes'

const SHOW_LOCATION_NAME_THRESHOLD = 22

export const useTrackCircuitLegends = (
  allCurrents: TrackCircuitCurrents[] | undefined,
  inactiveTrackCircuits: number[],
  currentType: TrackCircuitCurrentType,
  selectedTrackCircuits: TrackCircuit[],
  baneDataIds: string[]
): ChartLegend[] => {
  const { t } = useLanguage()

  return useMemo(() => {
    const currentsField = currentType === TrackCircuitCurrentType.RC ? 'rcMeasurements' : 'fcMeasurements'
    const [tracksWithData] = partition(allCurrents || [], current => !!current[currentsField].length)
    const showLocationName = tracksWithData.length < SHOW_LOCATION_NAME_THRESHOLD

    // Keep the order from currents
    const allBaneDataIds = [...new Set([...(tracksWithData.map(c => c.trackCircuitBaneDataId) || []), ...baneDataIds])]

    return allBaneDataIds.map((baneDataId, index) => {
      const trackCircuit = selectedTrackCircuits.find(tc => tc.baneDataId === baneDataId)
      const currents = allCurrents?.find(c => c.trackCircuitBaneDataId === baneDataId)
      const isActive = !inactiveTrackCircuits.includes(index)
      const baneDataLocationName = trackCircuit?.baneDataLocationName || currents?.baneDataLocationName
      const tcId = trackCircuit?.m40TrackCircuit.tcId || currents?.tcId
      const hasData = !!currents?.[currentsField].length
      const titleKey = isActive ? 'trackCircuits.chart.hideTrackCircuit' : 'trackCircuits.chart.showTrackCircuit'
      const title = t(titleKey, { tcId: `${baneDataLocationName} ${tcId}` })
      const labelParts = tcId ? [tcId] : [baneDataId]
      if (showLocationName && baneDataLocationName) {
        labelParts.unshift(baneDataLocationName)
      }
      const isEmpty = !hasData

      return {
        id: baneDataId,
        label: labelParts.join(' '),
        buttonTitle: title,
        isEmpty,
      }
    })
  }, [baneDataIds, currentType, allCurrents, inactiveTrackCircuits, selectedTrackCircuits, t])
}
