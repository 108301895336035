import { useEffect, useState } from 'react'
import { DatePeriodParam } from 'src/Store/useQueryStringState'
import { usePageFilters } from 'src/Hooks/usePageFilters'
import { createPurePageLink } from 'src/Utils/pageLink'
import type { PageFilters } from 'src/Types/PageFilters'

const getPageLinkFromPageFilters = (pageFilter: PageFilters) => {
  const params: Record<string, string> = {}
  if (pageFilter.datePeriod) {
    const datePeriod = DatePeriodParam.encode(pageFilter.datePeriod)
    if (datePeriod) {
      params.datePeriod = datePeriod
    }
  }
  if (pageFilter.stretchLocations?.length) {
    params.stretchLocations = pageFilter.stretchLocations.join(',')
  }
  if (pageFilter.railway) {
    params.railway = pageFilter.railway
  }
  params.loaded = 'true'
  return createPurePageLink(pageFilter.path, params)
}

/**
 * Returns the initial page link and page filter for the start page.
 */
export const useStartPage = () => {
  const { pageFilters, isSuccess, isLoading } = usePageFilters()

  const [startPageLink, setStartPageLink] = useState<string | null>()
  const [startPageFilter, setStartPageFilter] = useState<PageFilters | null>()

  useEffect(() => {
    if (isLoading) {
      return
    }

    if (isSuccess && pageFilters?.length) {
      const pageFilter = pageFilters[pageFilters.length - 1]
      const startUrl = getPageLinkFromPageFilters(pageFilter)
      setStartPageLink(startUrl)
      setStartPageFilter(pageFilter)
    } else {
      setStartPageLink(null)
      setStartPageFilter(null)
    }
  }, [isLoading, isSuccess, pageFilters])

  return { startPageLink, startPageFilter, isReady: typeof startPageLink !== 'undefined' }
}
