import type { OpenedAlarm } from 'src/Types/OpenedAlarm'
import type { SubHeaderDefaultExpanded } from 'src/Providers/Settings/SubHeader'

const OPENED_ALARMS_KEY = 'trackMonitoring.openedAlarms'
const PENDING_OPENED_ALARMS_KEY = 'trackMonitoring.pendingOpenedAlarms'
const SUB_HEADER_DEFAULT_EXPANDED_KEY = 'trackMonitoring.subHeaderDefaultExpanded'

export const getLocalOpenedAlarms = () => {
  return localStorage.getItem(OPENED_ALARMS_KEY)
}

export const getLocalPendingOpenedAlarms = () => {
  return localStorage.getItem(PENDING_OPENED_ALARMS_KEY)
}

export const storeLocalOpenedAlarms = (opened: OpenedAlarm[]) => {
  localStorage.setItem(OPENED_ALARMS_KEY, JSON.stringify(opened))
}

export const storeLocalOpenedPendingAlarms = (pending: OpenedAlarm[]) => {
  localStorage.setItem(PENDING_OPENED_ALARMS_KEY, JSON.stringify(pending))
}

export const getLocalSubHeaderDefaultExpanded = () => {
  return localStorage.getItem(SUB_HEADER_DEFAULT_EXPANDED_KEY)
}

export const storeLocalSubHeaderDefaultExpanded = (state: SubHeaderDefaultExpanded) => {
  localStorage.setItem(SUB_HEADER_DEFAULT_EXPANDED_KEY, JSON.stringify(state))
}
