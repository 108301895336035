/**
 * Clones any object or value. Nested objects will be cloned deeply with new references.
 *
 * @param obj - The object to clone.
 */
export const deepClone = <T>(o: T): T => JSON.parse(JSON.stringify(o))

/**
 * Updates an object at the specified path. Creates or replaces the existing value if any,
 * and creates any missing parts of the path.
 *
 * @param obj - The object to update.
 * @param path - Path to update using dot notation, i.e. 'person.address.street'.
 * @param value - The new value.
 */
export const updateObject = <T>(obj: T, path: string, value: any): T => {
  const cloned = deepClone(obj) as any

  let o = cloned
  const parts = path.split('.')
  parts.forEach((part, i) => {
    if (i === parts.length - 1) {
      o[part] = value
    } else {
      if (!o[part]) {
        o[part] = {}
      }
      o = o[part]
    }
  })

  return cloned as T
}

/**
 * Recursively removes any values that are empty strings, undefined or null.
 * Any objects that become empty as a result will also be removed.
 *
 * @param obj - The object to clone.
 */
export const removeEmptyValues = (obj: any) =>
  Object.entries(obj).reduce<any>((acc, [key, value]) => {
    if (value !== null && typeof value === 'object') {
      const subObj = removeEmptyValues(value)
      if (Object.keys(subObj).length > 0) {
        acc[key] = subObj
      }
    } else if (value !== '' && value !== undefined && value !== null) {
      acc[key] = value
    }

    return acc
  }, {})

/* eslint-disable no-bitwise */
const hashFnv32a = (str: string) => {
  let hval = 0x811c9dc5

  for (let i = 0; i < str.length; i++) {
    hval ^= str.charCodeAt(i)
    hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24)
  }
  return `0000000${(hval >>> 0).toString(16)}`.substr(-8)
}
/* eslint-enable no-bitwise */

/**
 * Create a one-way hash from a string. Shouldn't be used for anything secure.
 * Based on: https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
 *
 * @param str - The string to hash.
 */
export const hash64 = (str: string) => {
  const h1 = hashFnv32a(str)
  return h1 + hashFnv32a(h1 + str)
}
