import type { TooltipEntry, ChartProps } from 'src/Components/Chart/ChartRenderer'
import { ChartRenderer } from 'src/Components/Chart/ChartRenderer'

import type { Rect, NumberInterval, ChartEntry, ChartFocusUpdatedEventHandler } from 'src/Types/ChartTypes'
import type { RackSensorHistory } from 'src/Types/RackSensorHistory'
import type { RackSectionSensor } from 'src/Types/Rack'

import { maxNum } from 'src/Utils/number'
import { formatNumber } from 'src/Utils/format'

type RackEarthCurrentRendererProps = Omit<ChartProps, 'config'> & {
  getColorIndex?: (index: number) => number
  onZoomUpdated?: ChartFocusUpdatedEventHandler
}

const OPTIMIZE_DATA = true
const Y_AXIS_UNIT = 'mA'
const DATA_POINT_THRESHOLD = 0

const parseValueHistory = (
  sensorHistory: RackSensorHistory,
  timeline: NumberInterval,
  dataPointThreshold: number
): ChartEntry[] => {
  const mapped = sensorHistory?.values ? [...sensorHistory.values] : []

  if (typeof sensorHistory.currentAtFromTime === 'number') {
    mapped.unshift([timeline.start, sensorHistory.currentAtFromTime])
  }

  if (mapped.length) {
    // Extend each line to the end of the chart by duplicating the last point unless it's too old
    const lastEntry = mapped.slice(-1)[0]

    if (dataPointThreshold <= 0 || timeline.end - lastEntry[0] < dataPointThreshold) {
      mapped.push([timeline.end, lastEntry[1]])
    }
  }

  if (!OPTIMIZE_DATA) {
    return mapped
  }

  return mapped
    .flatMap<ChartEntry>((entry, i, arr) => {
      const next = arr[i + 1]
      if (next === undefined || dataPointThreshold <= 0 || next[0] - entry[0] < dataPointThreshold) {
        return [entry]
      }
      return [entry, [entry[0], null]]
    })
    .filter((entry, i, arr) => {
      // Always return the first and last entries
      if (i === 0 || i === arr.length - 1) {
        return true
      }

      // Optimize by skipping entries with the same measurement as the next.
      // If the rendered step algorithm is changed to curveStepAfter, this should
      // compare against the previous entry instead
      return entry[1] !== arr[i - 1][1]
    })
}

const formatTick = (unit: string, decimals: number, value: number) => `${formatNumber(value, decimals, false)} ${unit}`

const formatYTick = (value: number) => formatTick(Y_AXIS_UNIT, 0, value)

const CHART_MARGIN: Rect = { top: 36, right: 0, bottom: 20, left: 60 }
const MAX_ZOOM_MAGIC_CONSTANT = 1000000 // This magic number results in a nice max zoom resolution

export class RackEarthCurrentRenderer extends ChartRenderer {
  sensorHistory: RackSensorHistory[] = []

  rackSections: RackSectionSensor[] = []

  inactiveSensors: number[] = []

  isMultipleBenderView: boolean = false

  getColorIndexFunc?: (index: number) => number

  constructor({ chart, timeline, classes, getColorIndex, onZoomUpdated }: RackEarthCurrentRendererProps) {
    super({
      chart,
      timeline,
      classes,
      config: {
        chartMargin: CHART_MARGIN,
        maxZoomMagicConstant: MAX_ZOOM_MAGIC_CONSTANT,
        linearYScale: true,
        useCanvas: false,
        formatYTick,
      },
      onZoomUpdated,
    })
    this.getColorIndexFunc = getColorIndex
  }

  getColorIndex(index: number) {
    if (typeof this.getColorIndexFunc === 'function') {
      return this.getColorIndexFunc(index)
    }
    return super.getColorIndex(index)
  }

  getDomain() {
    if (this.sensorHistory.length === 0) {
      return {
        xMin: this.timeline.start,
        xMax: this.timeline.end,
        yMax: 0,
        yMin: 1,
      }
    }

    const yVals = this.sensorHistory.flatMap(history => {
      if (history.values?.length) {
        return history.values.map(v => v[1])
      }

      return typeof history.currentAtFromTime === 'number' ? [history.currentAtFromTime] : []
    })

    return {
      xMin: this.timeline.start,
      xMax: this.timeline.end,
      yMax: maxNum(yVals),
      yMin: 0,
    }
  }

  getTooltipText({ index, value }: TooltipEntry) {
    const sensorHistory = this.sensorHistory[index]
    const rackSection = this.rackSections.find(s => s.baneDataId === sensorHistory.twinId)
    const sensorName = rackSection?.name

    return sensorName
      ? `${sensorName}: ${value.toLocaleString('nb-NO')} ${Y_AXIS_UNIT}`
      : `${value.toLocaleString('nb-NO')} ${Y_AXIS_UNIT}`
  }

  setData(sensorHistory: RackSensorHistory[], rackSections: RackSectionSensor[]) {
    this.sensorHistory = sensorHistory
    this.rackSections = rackSections

    const dataCurves = this.sensorHistory.map(history => parseValueHistory(history, this.timeline, DATA_POINT_THRESHOLD))

    this.setDataCurves(dataCurves)
  }

  setInactiveSensors(inactiveSensors: number[]) {
    this.inactiveSensors = inactiveSensors
    this.setInactiveSeries(this.inactiveSensors, true)
  }
}
