import { AssetType } from 'src/Types/AssetTypes'

import type { DateRange } from 'src/Types/DatePeriod'
import { getGenericMachineAtLocations, getGenericMachineForBaneDataIds } from 'src/Providers/GenericMachine'

// Point machine switches
export const getPointMachineSwitchesAtLocations = (
  dateRange: DateRange,
  locationIds: string[],
  onlyWithAlarms: boolean,
  enableAllAlarmTypes: boolean
) => getGenericMachineAtLocations(dateRange, locationIds, onlyWithAlarms, enableAllAlarmTypes, AssetType.SwitchPointMachine)

export const getPointMachineSwitchesForBaneDataIds = (
  dateRange: DateRange,
  baneDataIds: string[],
  onlyWithAlarms: boolean,
  enableAllAlarmTypes: boolean
) => getGenericMachineForBaneDataIds(dateRange, baneDataIds, onlyWithAlarms, enableAllAlarmTypes, AssetType.SwitchPointMachine)
