import type { TrackCircuit, OccupiedTrackCircuit } from 'src/Types/TrackCircuitTypes'
import { ApiType } from 'src/Types/ApiType'

import { callApiGet } from 'src/msGraphClient'
import { mapLimit } from 'src/Utils/async'
import config from 'src/Config/Config'

const { TRACK_CIRCUITS, OCCUPIED_TRACK_CIRCUITS } = config.API_FRAGMENTS.apim

const MAX_CONCURRENT_FETCHES = 3

export const getTrackCircuit = (baneDataId: string) => callApiGet<TrackCircuit>(`${TRACK_CIRCUITS}/${baneDataId}`, ApiType.APIM)

export const getTrackCircuitWithNeighbors = (baneDataId: string) => {
  const urlParams = new URLSearchParams({
    baneDataId,
    includeNeighbors: 'true',
  })

  return callApiGet<TrackCircuit[]>(`${TRACK_CIRCUITS}?${urlParams.toString()}`, ApiType.APIM).then(res => res[0])
}

export const getTrackCircuitsAtLocation = (baneDataLocationId: string) => {
  const urlParams = new URLSearchParams({
    baneDataLocationId,
    includeNeighbors: 'true',
    excludeNotMonitored: 'true',
  })

  return callApiGet<TrackCircuit[]>(`${TRACK_CIRCUITS}?${urlParams.toString()}`, ApiType.APIM)
}

export const getTrackCircuits = (baneDataLocationIds: string[]) =>
  mapLimit(baneDataLocationIds, MAX_CONCURRENT_FETCHES, getTrackCircuitsAtLocation).then(res =>
    res.flat().sort((a, b) => a.m40TrackCircuit.tcId.localeCompare(b.m40TrackCircuit.tcId, config.STRING_LOCALE))
  )

export const getOccupiedTrackCircuits = (trackCircuitBaneDataId: string, timestamp: number) => {
  const urlParams = new URLSearchParams({
    trackCircuitBaneDataId,
    dateTime: new Date(timestamp).toJSON(),
  })

  return callApiGet<OccupiedTrackCircuit[]>(`${OCCUPIED_TRACK_CIRCUITS}?${urlParams.toString()}`, ApiType.APIM)
}
