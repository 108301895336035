import type { SetStateAction, Dispatch } from 'react'
import { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import Fade from '@mui/material/Fade'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import Switch from '@mui/material/Switch'
import HelpIcon from '@mui/icons-material/HelpOutlined'

const useStyles = makeStyles()({
  refresh: {
    marginTop: 8,
    paddingRight: 16,
  },
  switchLabel: {
    marginRight: 0,
  },
  helpIcon: {
    paddingBottom: 8,
    paddingLeft: 5,
  },
  popper: {
    padding: 10,
    zIndex: 10000,
  },
  paper: {
    padding: 10,
    maxWidth: 400,
  },
})

type OwnProps = {
  autoRefresh: boolean
  setAutoRefresh: Dispatch<SetStateAction<boolean>>
  isAutoRefreshDisabled: boolean
  helpText?: string | undefined
}

export const AutoRefreshToggle = ({ autoRefresh, setAutoRefresh, isAutoRefreshDisabled, helpText }: OwnProps) => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const [showHelpPopup, setShowHelpPopup] = useState(false)
  const helpButtonRef = useRef(null)

  return (
    <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" className={classes.refresh}>
      <FormControlLabel
        control={
          <Switch
            checked={autoRefresh}
            onChange={event => setAutoRefresh(event.target.checked)}
            disabled={isAutoRefreshDisabled}
            name="autoRefresh"
            color="primary"
          />
        }
        label={t('general.autoRefresh')}
        className={classes.switchLabel}
      />
      {typeof helpText !== 'undefined' && helpText !== '' && (
        <>
          <HelpIcon
            className={classes.helpIcon}
            onMouseEnter={() => setShowHelpPopup(true)}
            onMouseLeave={() => setShowHelpPopup(false)}
            ref={helpButtonRef}
          />
          {showHelpPopup && (
            <Popper
              id="auto-refresh-help-popper"
              anchorEl={helpButtonRef.current}
              placement="top"
              className={classes.popper}
              open
              transition
            >
              {({ TransitionProps }) => (
                <Fade
                  {...TransitionProps} // eslint-disable-line react/jsx-props-no-spreading
                  timeout={350}
                >
                  <Paper className={classes.paper}>{helpText}</Paper>
                </Fade>
              )}
            </Popper>
          )}
        </>
      )}
    </Grid>
  )
}
