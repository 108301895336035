import type {
  TrackCircuitCurrentType,
  TrackCircuitCompressionRates,
  TrackCircuitCurrentsData,
  TrackCircuitCurrentsContract,
} from 'src/Types/TrackCircuitCurrentTypes'
import { ApiType } from 'src/Types/ApiType'

import { callApiGet } from 'src/msGraphClient'
import config from 'src/Config/Config'

const { CURRENTS_COMPRESSED } = config.API_FRAGMENTS.apim

export type GetCurrentCompressedMeasurementsParams = {
  trackCircuitBaneDataIds: string[]
  fromDate: number
  toDate: number
  currentType: TrackCircuitCurrentType | 'All'
  compressionRate?: TrackCircuitCompressionRates
}

export const getCurrentCompressedMeasurements = async ({
  trackCircuitBaneDataIds,
  fromDate,
  toDate,
  currentType = 'All',
  compressionRate = 1,
}: GetCurrentCompressedMeasurementsParams) => {
  const urlParams = new URLSearchParams({
    trackCircuitBaneDataIds: trackCircuitBaneDataIds.join(','),
    fromDate: new Date(fromDate).toJSON(),
    toDate: new Date(toDate).toJSON(),
    currentType,
    compRate: `${compressionRate}`,
  })

  const trackCircuitCurrents = await callApiGet<TrackCircuitCurrentsContract[]>(
    `${CURRENTS_COMPRESSED}?${urlParams.toString()}`,
    ApiType.APIM,
    {
      parseDates: true,
    }
  )

  return trackCircuitCurrents.map<TrackCircuitCurrentsData>(
    ({ missingFcMeasurements = [], missingRcMeasurements = [], ...current }) => {
      return {
        ...current,
        missingFcMeasurements,
        missingRcMeasurements,
        fromDate,
        toDate,
      }
    }
  )
}
