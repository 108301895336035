import { getPointMachineHeatSensors } from 'src/Providers/PointMachineHeat'
import { useQueryWithErrorHandling } from './useQueryWithErrorHandling'

export const usePointMachineHeatSensorStatus = (baneDataId: string) => {
  const query = useQueryWithErrorHandling(
    ['pointMachineHeatSensors', { baneDataId }],
    () => getPointMachineHeatSensors(baneDataId),
    {},
    'switches.pointMachineHeat.sensorsFetchError'
  )

  return {
    ...query,
    sensorStatus: query.data,
  }
}
