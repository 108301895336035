import { styled } from '@mui/material/styles'
import CloudIcon from '@mui/icons-material/WbCloudyOutlined'
import NightOvercastIcon from '@mui/icons-material/NightsStayOutlined'
import SunIcon from '@mui/icons-material/WbSunnyOutlined'
import MoonIcon from '@mui/icons-material/Brightness2Outlined'
import {
  WiHot,
  WiDayWindy,
  WiDayCloudyGusts,
  WiCloudyGusts,
  WiDaySunnyOvercast,
  WiDayCloudy,
  WiCloud,
  WiNA,
  WiNightAltCloudyGusts,
  WiNightAltCloudy,
} from 'weather-icons-react'

import type { Weather, Cloudiness } from 'src/Types/WeatherTypes'

import { isWindyWeather, isWeatherAtNight } from 'src/Features/Weather/utils'
import themeColors from 'src/theme'

const StyledCloudIcon = styled(CloudIcon)`
  padding-top: 10px;
  padding-bottom: 5px;
`

const clearSkyIconStyle = {
  color: themeColors.weather.sun,
  paddingTop: 6,
  paddingBottom: 5,
}

const iconStyle = {
  verticalAlign: 'middle',
}

const getCloudiness = ({ cloudiness }: Weather): Cloudiness => {
  if (cloudiness >= 70) {
    return 'cloudy'
  }
  if (cloudiness >= 50) {
    return 'partlyCloudy'
  }
  if (cloudiness >= 25) {
    return 'overcast'
  }
  return 'clear'
}

type OwnProps = {
  weather: Weather
  onWhiteBackground?: boolean
  small?: boolean
}

export const CloudinessIcon = ({ weather, onWhiteBackground = false, small = false }: OwnProps) => {
  const iconColor = onWhiteBackground ? themeColors.primary : '#fff'

  const isNight = isWeatherAtNight(weather)
  const isWindy = isWindyWeather(weather)
  const cloudiness = getCloudiness(weather)

  const iconSize = small ? 24 : 50

  switch (cloudiness) {
    case 'clear': {
      if (isNight) {
        return <MoonIcon style={clearSkyIconStyle} fontSize={small ? 'medium' : 'large'} />
      }
      if (isWindy) {
        return <WiDayWindy size={iconSize} color={themeColors.weather.sun} style={iconStyle} />
      }
      return weather.temperature >= 30 ? (
        <WiHot size={iconSize} color={themeColors.weather.sun} />
      ) : (
        <SunIcon style={clearSkyIconStyle} fontSize={small ? 'medium' : 'large'} />
      )
    }
    case 'overcast': {
      if (isNight) {
        return <NightOvercastIcon fontSize={small ? 'medium' : 'large'} color={onWhiteBackground ? 'primary' : 'inherit'} />
      }
      return <WiDaySunnyOvercast size={iconSize} color={themeColors.weather.sun} style={iconStyle} />
    }
    case 'partlyCloudy': {
      if (isNight) {
        return isWindy ? (
          <WiNightAltCloudyGusts size={iconSize} color={iconColor} style={iconStyle} />
        ) : (
          <WiNightAltCloudy size={iconSize} color={iconColor} style={iconStyle} />
        )
      }
      return isWindy ? (
        <WiDayCloudyGusts size={iconSize} color={iconColor} style={iconStyle} />
      ) : (
        <WiDayCloudy size={iconSize} color={iconColor} style={iconStyle} />
      )
    }
    case 'cloudy': {
      if (isWindy) {
        return <WiCloudyGusts size={iconSize} color={iconColor} style={iconStyle} />
      }
      return onWhiteBackground ? (
        <WiCloud size={iconSize} color={themeColors.primary} style={iconStyle} />
      ) : (
        <StyledCloudIcon fontSize={small ? 'medium' : 'large'} />
      )
    }
    default:
      return <WiNA size={iconSize} color={iconColor} style={iconStyle} />
  }
}
