import type { TFunction } from 'i18next'

import type { Asset } from 'src/Types/AssetTypes'
import { AssetType } from 'src/Types/AssetTypes'

export const assetName = (asset: Asset, t: TFunction) => {
  const name = asset.name.toUpperCase()
  switch (asset.assetType) {
    case AssetType.SwitchMachineHeat:
      return `${name} (${t('switches.pointMachineHeat.cardSubType')})`

    case AssetType.DerailerMachine:
      return `${name} (${t('sideMenu.system.derailermachine')?.toLowerCase()})`

    default:
      return name
  }
}
