import { useTranslation } from 'react-i18next'

import IconButton from '@mui/material/IconButton'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ClearIcon from '@mui/icons-material/ClearOutlined'

type OwnProps = {
  onClearPendingPeriod: () => void
}

export const ClearPendingDatePeriodButton = ({ onClearPendingPeriod }: OwnProps) => {
  const { t } = useTranslation()

  return (
    <ListItemSecondaryAction>
      <IconButton
        onClick={onClearPendingPeriod}
        size="small"
        color="primary"
        title={t('general.date.clear')}
        aria-label={t('general.date.clear')}
      >
        <ClearIcon />
      </IconButton>
    </ListItemSecondaryAction>
  )
}
