import { useGenericCache } from 'src/Hooks/useGenericCache'
import { useCallback, useState } from 'react'

type OwnPrams<T> = {
  partialKey: string
  retentionInMs?: number
  getFreshValue: () => Promise<T>
  onValueReceived?: (value: T, isFresh?: boolean) => void
}

const TEN_SECONDS = 10000

/**
 * Hook used to cache value in local storage and only update when needed.
 * @param getValue Callback to get the fresh value
 * @param onValueReceived Called when received fresh or cached value
 */
export const useGenericCacheBehaviour = <T>({ getFreshValue, onValueReceived, ...params }: OwnPrams<T>) => {
  const { getCachedValue, storeValue } = useGenericCache<T>(params)
  const [refreshedRequested, setRefreshedRequested] = useState(false)

  /** Gets the new value and returns it. Then stores the value in localStorage. */
  const getValueAndUpdateCache = useCallback(() => {
    const promise = getFreshValue()
    promise.then(value => {
      onValueReceived?.(value, true)
      storeValue(value)
    })
    return promise
  }, [getFreshValue, onValueReceived, storeValue])

  /**
   * If the cached value exists, returns the value.
   * After 10 seconds gets the fresh value and updates the cache.
   * If the cached value does not exist, get the fresh value and updates cache.
   */
  const getFreshOrCachedValue = useCallback(() => {
    const value = getCachedValue()
    if (value) {
      if (!refreshedRequested) {
        setTimeout(() => {
          getValueAndUpdateCache().catch(console.error)
        }, TEN_SECONDS)
        setRefreshedRequested(true)
      }

      onValueReceived?.(value, false)
      return value
    }

    return getValueAndUpdateCache()
  }, [getCachedValue, getValueAndUpdateCache, onValueReceived, refreshedRequested])

  return {
    getFreshOrCachedValue,
  }
}
