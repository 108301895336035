import type { GetOrchestratedAssetsParams } from 'src/Types/GetOrchestratedAssetsParams'
import type { AssetWithAlarms } from 'src/Types/AssetWithAlarms'
import { getOrchestratedAssetIdParams } from 'src/Utils/asset'
import { callApiGet } from 'src/msGraphClient'
import type { OrchestratedMachineHeatSwitch, SwitchObject } from 'src/Types/SwitchObject'
import { ApiType } from 'src/Types/ApiType'
import { InternalAlarmSystem } from 'src/Types/AlarmSystem'
import config from 'src/Config/Config'
import type { DateRange } from 'src/Types/DatePeriod'
import { getCriticalityValue } from 'src/Utils/criticality'
import { AssetType } from 'src/Types/AssetTypes'

const { PM_HEAT_SWITCHES } = config.API_FRAGMENTS.apim

// Machine heat switches
const creatMachineHeatSwitchAsset = (
  assetData: OrchestratedMachineHeatSwitch['assetData'],
  status: OrchestratedMachineHeatSwitch['status']
): SwitchObject => {
  return {
    assetType: AssetType.SwitchMachineHeat,
    baneDataId: assetData.baneDataId,
    name: assetData.name,
    baneDataLocationId: assetData.locationId,
    baneDataLocationName: assetData.locationName,
    componentCriticalityLocal: getCriticalityValue(assetData, 'ComponentCriticalityLocal'),
    alarmCriticalityAbsolute: getCriticalityValue(assetData, 'AlarmCriticalityAbsolute'),
    totalCriticalityAbsolute: getCriticalityValue(assetData, 'TotalCriticalityAbsolute'),
    assetStatusWindows: status.assetStatusWindows,
    isMonitored: status.isMonitored,
    statusWithoutAssetStatusWindows: status.statusWithoutAssetStatusWindows,
    statusWithAssetStatusWindows: status.statusWithAssetStatusWindows,
    showCriticality: status.showCriticality,
    dailyStatus: status.dailyStatus,
    fromKm: assetData.fromKm,
    toKm: assetData.toKm,
  }
}

const getMachineHeatSwitches = (params: GetOrchestratedAssetsParams): Promise<AssetWithAlarms[]> => {
  const urlParams = new URLSearchParams({
    fromDate: new Date(params.dateRange.fromDate).toJSON(),
    toDate: new Date(params.dateRange.toDate).toJSON(),
    onlyWithAlarms: params.onlyWithAlarms.toString(),
    feFilterEnabled: (!params.enableAllAlarmTypes).toString(),
  })

  const { idKey, value } = getOrchestratedAssetIdParams(params)
  urlParams.set(idKey, value)

  return callApiGet<OrchestratedMachineHeatSwitch[]>(`${PM_HEAT_SWITCHES}?${urlParams.toString()}`, ApiType.APIM, {
    parseDates: true,
  })
    .then(res =>
      res.map<AssetWithAlarms>(({ assetData, aggregateAlarms, status }) => ({
        asset: creatMachineHeatSwitchAsset(assetData, status),
        alarms: aggregateAlarms.map(alarm => ({
          ...alarm,
          internalAlarmSystem: InternalAlarmSystem.AggregatedMachineHeatAlarm,
        })),
      }))
    )
    .then(result => result.sort((a, b) => a.asset.name.localeCompare(b.asset.name, config.STRING_LOCALE)))
}

export const getMachineHeatSwitchesAtLocations = (
  dateRange: DateRange,
  locationIds: string[],
  onlyWithAlarms: boolean,
  enableAllAlarmTypes: boolean
) => getMachineHeatSwitches({ dateRange, locationIds, onlyWithAlarms, enableAllAlarmTypes })

export const getMachineHeatSwitchesForBaneDataIds = (
  dateRange: DateRange,
  baneDataIds: string[],
  onlyWithAlarms: boolean,
  enableAllAlarmTypes: boolean
) => getMachineHeatSwitches({ dateRange, baneDataIds, onlyWithAlarms, enableAllAlarmTypes })
