import type { TimeSpan } from 'src/Types/LevelOfDetails'

export const isInsideTimeSpan = (value: number, [min, max]: TimeSpan) => value >= min && value <= max

export const hasOverlappedTimeSpan = (a: TimeSpan, b: TimeSpan) => {
  return isInsideTimeSpan(a[0], b) || isInsideTimeSpan(a[1], b) || isInsideTimeSpan(b[0], a) || isInsideTimeSpan(b[1], a)
}

const joinSpans = (a: TimeSpan, b: TimeSpan): TimeSpan => {
  const dates = Array.from(new Set([a[0], a[1], b[0], b[1]]))
  return [Math.min(...dates), Math.max(...dates)]
}

const hasAnyOverlap = (timeSpans: TimeSpan[]) => timeSpans.some(a => timeSpans.some(b => a !== b && hasOverlappedTimeSpan(a, b)))

export const unionTimeSpans = (...timeSpans: TimeSpan[][]): TimeSpan[] => {
  const flattened = timeSpans.filter(s => s?.length).flatMap(s => s)
  const result: TimeSpan[] = [...flattened]
  while (hasAnyOverlap(result)) {
    const current = result.pop()
    if (!current) {
      break
    }
    const overlappedIndex = result.findIndex(m => hasOverlappedTimeSpan(m, current))
    if (overlappedIndex === -1) {
      result.unshift(current)
    } else {
      const overlapped = result[overlappedIndex]
      result.splice(overlappedIndex, 1)
      const joined = joinSpans(current, overlapped)
      result.unshift(joined)
    }
  }
  return result
}

export const appendTimespan = (timeSpans: TimeSpan[], [fromDate, toDate]: TimeSpan): TimeSpan[] =>
  timeSpans.some(([from, to]) => from === fromDate && to === toDate) ? timeSpans : [...timeSpans, [fromDate, toDate]]

export const getTimeSpansString = (timeSpans: TimeSpan[]) => timeSpans.map(s => s.join('x')).join(',')

export const hasGaps = (timeSpans: TimeSpan[], [fromDate, toDate]: TimeSpan) =>
  !unionTimeSpans(timeSpans).some(s => isInsideTimeSpan(fromDate, s) && isInsideTimeSpan(toDate, s))

export const cropTimeSpan = (timeSpan: TimeSpan, limit: TimeSpan) => {
  const newTimeSpan: TimeSpan = [Math.max(timeSpan[0], limit[0]), Math.min(timeSpan[1], limit[1])]
  if (newTimeSpan[0] >= newTimeSpan[1]) {
    return undefined
  }
  return newTimeSpan
}
