import { useCallback, useReducer } from 'react'
import { AxiosError } from 'axios'

import type { adjustHeatValue } from 'src/Providers/SwitchHeat'

import { TemperatureUpdateState } from 'src/Features/SwitchHeat/Adjustment/types'
import { useSwitchHeatVersion } from 'src/Hooks/useSwitchHeatVersion'
import { useAppStateStore } from 'src/Store/appState'
import { getAppInsights } from 'src/telemetryService'

type UpdatedTemperature = {
  sensorId: string
  value: number
  originalValue?: number
  eTag: string
}

export type UpdatingTemperatureState = UpdatedTemperature & {
  state: TemperatureUpdateState
  responseCode?: number
  hasOutdatedETag?: boolean
}

type Action =
  | { type: 'transition'; payload: { sensorId: string; state: TemperatureUpdateState } }
  | { type: 'setState'; payload: UpdatedTemperature[] }
  | { type: 'setResponse'; payload: { sensorId: string; responseCode?: number; hasOutdatedETag: boolean } }

/**
 * Response code 412 indicates that the eTag in the request is outdated.
 * If the value in the backend get changed by others (users or system)
 * after the value is entered in the form, this will happen. */
const RESPONSE_CODE_INDICATING_OUTDATED_ETAG = 412

const reducer = (state: UpdatingTemperatureState[], action: Action): UpdatingTemperatureState[] => {
  switch (action.type) {
    case 'transition':
      return state.map(s => (s.sensorId === action.payload.sensorId ? { ...s, state: action.payload.state } : s))
    case 'setState':
      return action.payload.map(temp => ({ ...temp, state: TemperatureUpdateState.Idle }))
    case 'setResponse':
      return state.map(s =>
        s.sensorId === action.payload.sensorId
          ? { ...s, responseCode: action.payload.responseCode, hasOutdatedETag: action.payload.hasOutdatedETag }
          : s
      )
    default:
      return state
  }
}

type OwnParams = {
  adjustHeatValueFunc: typeof adjustHeatValue
}

export const useTemperatureAdjustmentBehaviour = ({ adjustHeatValueFunc }: OwnParams) => {
  const queueMessage = useAppStateStore(state => state.appMessages.queueMessage)
  const [updateState, dispatch] = useReducer(reducer, [])
  const isUpdating = updateState.some(s => [TemperatureUpdateState.Idle, TemperatureUpdateState.Updating].includes(s.state))
  const version = useSwitchHeatVersion()

  const transition = (sensorId: string, state: TemperatureUpdateState) => {
    dispatch({ type: 'transition', payload: { sensorId, state } })
  }

  const submitSingle = useCallback(
    async ({ sensorId, value, eTag, originalValue }: UpdatedTemperature) => {
      transition(sensorId, TemperatureUpdateState.Updating)
      let success = false
      try {
        await adjustHeatValueFunc({
          sensorId,
          requestedValue: value,
          eTag,
          version,
        })
        transition(sensorId, TemperatureUpdateState.Success)
        success = true
      } catch (e) {
        if (e instanceof AxiosError) {
          const responseCode = e.response?.status
          const hasOutdatedETag = responseCode === RESPONSE_CODE_INDICATING_OUTDATED_ETAG
          dispatch({ type: 'setResponse', payload: { sensorId, responseCode, hasOutdatedETag } })
        }
        queueMessage({
          type: 'error',
          message: 'switchHeat.heatManagement.updating.failed',
        })
        transition(sensorId, TemperatureUpdateState.Failed)
      }

      const appInsights = getAppInsights()
      appInsights?.trackEvent(
        { name: `adjust-heat-value-${success ? 'success' : 'fail'}` },
        {
          sensorId,
          requestedValue: value,
          originalValue,
        }
      )
    },
    [adjustHeatValueFunc, queueMessage, version]
  )

  const submitValues = (values: UpdatedTemperature[]) => {
    dispatch({ type: 'setState', payload: values })
    values.forEach(submitSingle)
  }

  return {
    isUpdating,
    updateState,
    submitValues,
  }
}
