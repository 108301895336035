import { callApiGet, callApiPost } from 'src/msGraphClient'

import { ApiType } from 'src/Types/ApiType'

import config from 'src/Config/Config'
import type { AssetStatusCause, AssetStatusEntry, AssetStatusEntryDomain } from 'src/Types/AssetStatusEntry'
import { AssetStatusEntityStatus } from 'src/Types/AssetStatusEntry'
import { toLocalISO8601 } from 'src/Utils/format'

const {
  ASSET_STATUS_NO_CONTACT,
  ASSET_STATUS_ENTER_NO_CONTACT,
  ASSET_STATUS_EXIT_NO_CONTACT,
  ASSET_STATUS_IRREGULAR_DATA,
  ASSET_STATUS_ENTER_IRREGULAR_DATA,
  ASSET_STATUS_EXIT_IRREGULAR_DATA,
} = config.API_FRAGMENTS.apim

type GetAssetStatusParams = {
  status: AssetStatusEntityStatus
  baneDataIds: string[]
  fromDate: number
  domain: AssetStatusEntryDomain
}

type AssetStatusEntryContract = {
  baneDataId: string
  status: string
  startTime: number
  endTime: number
  causeId: number
  startComment: string
  endComment: string | null
  createdTime?: number
  createdBy?: string
  lastUpdatedTime?: number
  lastUpdatedBy?: string
  domain: string
  isManual: boolean
  cause: AssetStatusCause
}

export const getAssetStatus = async ({ status, baneDataIds, fromDate, domain }: GetAssetStatusParams) => {
  const urlParams = new URLSearchParams({
    baneDataIds: baneDataIds.join(','),
    fromDate: new Date(fromDate).toJSON(),
    domain,
  })

  const result = await callApiGet<AssetStatusEntryContract[]>(
    `${getAssetStatusUrl(status, 'get')}?${urlParams.toString()}`,
    ApiType.APIM,
    {
      parseDates: true,
    }
  )
  return result.map<AssetStatusEntry>(assetStatus => {
    const {
      baneDataId,
      status,
      startTime,
      endTime,
      causeId,
      createdTime,
      startComment,
      endComment,
      lastUpdatedTime,
      lastUpdatedBy,
      createdBy,
      domain,
      isManual,
      cause,
    } = assetStatus
    return {
      baneDataId,
      status: status as AssetStatusEntityStatus,
      startTime,
      endTime,
      causeId,
      startComment,
      endComment: endComment || undefined,
      domain: domain as AssetStatusEntryDomain,
      createdTime,
      createdBy,
      lastUpdatedTime,
      lastUpdatedBy,
      isManual,
      cause,
    }
  })
}

const getAssetStatusUrl = (status: AssetStatusEntityStatus, action: 'get' | 'enter' | 'exit') => {
  switch (status) {
    case AssetStatusEntityStatus.NoContact:
      switch (action) {
        case 'get':
          return ASSET_STATUS_NO_CONTACT
        case 'enter':
          return ASSET_STATUS_ENTER_NO_CONTACT
        case 'exit':
          return ASSET_STATUS_EXIT_NO_CONTACT
        default:
          throw new Error(`Action ${action} is not configured/known...`)
      }
    case AssetStatusEntityStatus.IrregularData:
      switch (action) {
        case 'get':
          return ASSET_STATUS_IRREGULAR_DATA
        case 'enter':
          return ASSET_STATUS_ENTER_IRREGULAR_DATA
        case 'exit':
          return ASSET_STATUS_EXIT_IRREGULAR_DATA
        default:
          throw new Error(`Action ${action} is not configured/known...`)
      }
    default:
      throw new Error(`Status ${status} is not configured/known...`)
  }
}

type PostEnterAssetStatusParams = {
  status: AssetStatusEntityStatus
  baneDataId: string
  startTime: Date
  endTime: Date
  causeId: number
  domain: AssetStatusEntryDomain
  comment: string
}

export const postEnterAssetStatus = async (params: PostEnterAssetStatusParams) => {
  const { status, ...restOfParams } = params
  const body = JSON.stringify({
    ...restOfParams,
    startTime: toLocalISO8601(params.startTime),
    endTime: toLocalISO8601(params.endTime),
  })
  await callApiPost<string>(getAssetStatusUrl(status, 'enter'), ApiType.APIM, body)
}

type PostExitAssetStatusParams = {
  status: AssetStatusEntityStatus
  baneDataId: string
  startTime: Date
  causeId: number
  domain: AssetStatusEntryDomain
  comment: string
}

export const postExitAssetStatus = async (params: PostExitAssetStatusParams) => {
  const { status, ...restOfParams } = params
  const body = JSON.stringify({
    ...restOfParams,
    startTime: toLocalISO8601(params.startTime),
  })
  await callApiPost<string>(getAssetStatusUrl(status, 'exit'), ApiType.APIM, body)
}
