import type { QueryStatus } from 'react-query'

const queryStatusPriority = ['error', 'idle', 'loading', 'success']

/**
 * The overall query status among several that is the least advanced one.
 *
 * @param statuses - Query statuses to compare.
 * @param ignoreIdle - If set, idle statuses will be ignored as long as any of them are different from idle.
 */
export const getLeastAdvancedQueryStatus = (statuses: QueryStatus[], ignoreIdle: boolean = false): QueryStatus => {
  if (ignoreIdle && statuses.every(s => s === 'idle')) {
    return 'idle'
  }

  const arr = ignoreIdle ? statuses.filter(s => s !== 'idle') : statuses

  return arr.sort((a, b) => queryStatusPriority.indexOf(a) - queryStatusPriority.indexOf(b))[0]
}

/**
 * Returns true if all statuses is done and any is successful.
 *
 * @param statuses - Query statuses to compare.
 */
export const hasAnySuccessfulQueryStatus = (statuses: QueryStatus[]): boolean => {
  const done = statuses.every(status => status === 'success' || status === 'error')
  const anySuccess = statuses.includes('success')
  return done && anySuccess
}

/**
 * Creates a URLSearchParams object that stringifies values, and removes null and undefined values.
 *
 * @param params - URL params.
 */
export const createApiParams = (params: { [key: string]: string | number | boolean | undefined | null }) => {
  const stringParams: { [key in keyof typeof params]: string } = {}

  return new URLSearchParams(
    Object.entries(params).reduce((acc, [key, value]) => {
      if (value !== null && value !== undefined) {
        acc[key] = value.toString()
      }
      return acc
    }, stringParams)
  )
}
