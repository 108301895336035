import type { ReactNode } from 'react'
import Box from '@mui/material/Box'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

const responsive = {
  xxlargeDesktop: {
    breakpoint: { max: 4000, min: 2240 },
    items: 6,
    slidesToSlide: 5,
  },
  xlargeDesktop: {
    breakpoint: { max: 2240, min: 1940 },
    items: 5,
    slidesToSlide: 4,
  },
  largeDesktop: {
    breakpoint: { max: 1940, min: 1640 },
    items: 4,
    slidesToSlide: 3,
  },
  mediumDesktop: {
    breakpoint: { max: 1640, min: 1340 },
    items: 3,
    slidesToSlide: 2,
  },
  desktop: {
    breakpoint: { max: 1340, min: 1199 },
    items: 2,
    slidesToSlide: 1,
  },
  smallDesktop: {
    breakpoint: { max: 1199, min: 1080 },
    items: 3,
    slidesToSlide: 2,
  },
  xsmallDesktop: {
    breakpoint: { max: 1080, min: 899 },
    items: 2,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 899, min: 750 },
    items: 3,
    slidesToSlide: 2,
  },
  smallTablet: {
    breakpoint: { max: 750, min: 470 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 470, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
}

export const LegendCarousel = ({ children }: { children: ReactNode }) => (
  <Box ml={2} mr={2}>
    <Carousel responsive={responsive} centerMode focusOnSelect={false}>
      {children}
    </Carousel>
  </Box>
)
