import { getWeatherForecast } from 'src/Providers/Weather'
import { useQueryWithErrorHandling } from './useQueryWithErrorHandling'

type UseWeatherParams = {
  baneDataLocationId?: string
  fromDate?: number
  toDate?: number
}

export const useWeather = ({ baneDataLocationId, fromDate, toDate }: UseWeatherParams) => {
  const query = useQueryWithErrorHandling(
    ['weather', { baneDataLocationId, fromDate, toDate }],
    () => {
      if (!baneDataLocationId || !fromDate || !toDate) {
        return undefined
      }

      return getWeatherForecast({
        baneDataLocationId,
        fromDate,
        toDate,
      })
    },
    {
      enabled: !!baneDataLocationId && !!fromDate && !!toDate,
    },
    'weather.fetchError'
  )

  return {
    ...query,
    weather: query.data,
  }
}
