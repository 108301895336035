import type { MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import IconButton from '@mui/material/IconButton'
import PushPinIcon from '@mui/icons-material/PushPin'
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined'
import { styled } from '@mui/material/styles'
import type SvgIcon from '@mui/material/SvgIcon/SvgIcon'
import { ignoreStyleProps } from 'src/Utils/style'

type OwnProps = {
  onClick: (saveState: boolean | undefined) => void
  pinned: boolean | undefined
  expanded: boolean
}

const getRotatingIcon = (icon: typeof SvgIcon) => {
  return styled(
    icon,
    ignoreStyleProps('expanded')
  )<{ expanded: boolean }>(
    ({ expanded }) => `
  transform: rotate(${!expanded ? '-90deg' : 0});
  transition-duration: 200ms;
  transition-property: transform;
`
  )
}

const StyledPinnedIcon = getRotatingIcon(PushPinIcon)
const StyledPinnedOutlinedIcon = getRotatingIcon(PushPinOutlinedIcon)

export const PinnedIconButton = ({ onClick, pinned, expanded }: OwnProps) => {
  const { t } = useTranslation()

  const isSaved = typeof pinned !== 'undefined'

  const handlePinnedClick: MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation()
    const shouldSave = typeof pinned === 'undefined'
    onClick(shouldSave ? expanded : undefined)
  }

  const iconExpanded = isSaved ? pinned : expanded

  let key: string
  if (isSaved) {
    if (pinned) {
      key = 'pinned.opened'
    } else {
      key = 'pinned.closed'
    }
  } else if (expanded) {
    key = 'pinned.open'
  } else {
    key = 'pinned.close'
  }
  const title = t(key)

  return (
    <IconButton
      title={title}
      size="small"
      onClick={handlePinnedClick}
      data-test-pinned={isSaved}
      data-test-expanded={iconExpanded}
    >
      {isSaved ? (
        <StyledPinnedIcon expanded={iconExpanded} fontSize="small" color="primary" />
      ) : (
        <StyledPinnedOutlinedIcon expanded={iconExpanded} fontSize="small" color="primary" />
      )}
    </IconButton>
  )
}
