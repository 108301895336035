import { NavLink } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'

import { LegendBand } from 'src/Components/Chart/Legends/LegendBand'
import { LegendPhasesIcon } from 'src/Components/Chart/Legends/LegendPhasesIcon'
import { LegendCarousel } from './LegendCarousel'
import { StyledLegendButton, StyledMissingValueText, StyledPlainContainer, StyledPointMachine } from './StyledLegendComponents'

import type { Alarm } from 'src/Types/Alarm'
import type { Asset } from 'src/Types/AssetTypes'
import { AssetType } from 'src/Types/AssetTypes'
import type { MachineSwing, SlipCurrent } from 'src/Types/SwingTypes'

import { toggleArrayElement } from 'src/Utils/array'
import { flexibleTranslation } from 'src/Utils/language'
import { getChartLineColorByIndex } from 'src/Utils/chart'
import { getCardId } from 'src/Utils/cardId'

import { SetReferenceCurveButton } from './SetReferenceCurveButton'
import { isUnselectedSequenceSwing } from './isUnselectedSequenceSwing'

import { useBreakpointDown } from 'src/Hooks/useBreakpoint'
import { useLanguage } from 'src/Hooks/useLanguage'

import themeColors from 'src/theme'

type OwnProps = {
  asset: Asset
  pointMachineSwings: MachineSwing[]
  slipCurrents: SlipCurrent[]
  inactivePointMachines: number[]
  activeSlipCurrent: number | undefined
  activePhases: number | undefined
  activeAlarmPhases: number | undefined
  activeReferenceCurve: number | undefined
  onInactivePointMachinesUpdated: (inactivePointMachines: number[]) => void
  onActiveSlipCurrentUpdated: (activeSlipCurrent: number | undefined) => void
  onActivePhasesUpdated: (activePhases: number | undefined) => void
  onActiveAlarmPhasesUpdated: (machineIndex: number | undefined, alarmsWithPhaseIds: Alarm[] | undefined) => void
  onActiveReferenceCurveUpdated: (activeReferenceCurve: number | undefined) => void
}

const getAssetTypeFromSwing = (swing: MachineSwing) =>
  swing.switchBaneDataId && /-SSP-/.test(swing.switchBaneDataId) ? AssetType.DerailerMachine : AssetType.SwitchPointMachine

export const SwingLegend = ({
  asset,
  pointMachineSwings,
  slipCurrents,
  inactivePointMachines,
  activeSlipCurrent,
  activePhases,
  activeAlarmPhases,
  activeReferenceCurve,
  onInactivePointMachinesUpdated,
  onActiveSlipCurrentUpdated,
  onActivePhasesUpdated,
  onActiveAlarmPhasesUpdated,
  onActiveReferenceCurveUpdated,
}: OwnProps) => {
  const { t } = useLanguage()
  const isMediaLG = useBreakpointDown('lg')
  const isMediaSM = useBreakpointDown('sm')

  const machineIndexes = pointMachineSwings.map((s, i) => i)

  const [alarmsWithPhaseIdsArray, setAlarmsWithPhaseIdsArray] = useState<(any[] | undefined)[]>()

  useEffect(() => {
    const array = pointMachineSwings.map(swing => {
      const alarms = swing.swingAnalysis?.alarms
      return alarms?.filter(a => a.alarmPhaseId !== null)
    })
    setAlarmsWithPhaseIdsArray(array)
  }, [pointMachineSwings])

  const togglePhases = (machineIndex: number) => {
    if (activePhases !== machineIndex) {
      if (activeAlarmPhases !== undefined) {
        onActiveAlarmPhasesUpdated(undefined, [])
      }

      const inactive = machineIndexes.filter(i => i !== machineIndex && !pointMachineSwings[i].isFromSequence)
      onInactivePointMachinesUpdated(inactive)

      onActivePhasesUpdated(machineIndex)

      if (activeReferenceCurve !== undefined) {
        onActiveReferenceCurveUpdated(machineIndex)
      }

      if (activeSlipCurrent !== undefined) {
        onActiveSlipCurrentUpdated(machineIndex)
      }
    } else {
      onActivePhasesUpdated(undefined)
    }
  }

  const renderPhaseButton = (swing: MachineSwing, machineIndex: number) => {
    const supportsPhases = true
    const phasesDisabled = !swing.swingAnalysis?.phases?.length
    const phasesActive = activePhases === machineIndex

    return (
      supportsPhases && (
        <div>
          {phasesDisabled ? (
            <StyledMissingValueText>{t(flexibleTranslation('switches.chart.phasesMissing', isMediaSM))}</StyledMissingValueText>
          ) : (
            <StyledLegendButton
              type="button"
              active={phasesActive}
              title={phasesActive ? t('switches.chart.hidePhases') : t('switches.chart.showPhases')}
              onClick={() => togglePhases(machineIndex)}
            >
              <LegendPhasesIcon bands={themeColors.chartColors.bands.slice(0, 3)} inactive={!phasesActive} />
              <span>{t(flexibleTranslation('switches.chart.phasesLabel', isMediaSM))}</span>
            </StyledLegendButton>
          )}
        </div>
      )
    )
  }

  const toggleAlarmPhases = (machineIndex: number) => {
    const alarms = alarmsWithPhaseIdsArray !== undefined ? alarmsWithPhaseIdsArray[machineIndex] : []
    if (activeAlarmPhases !== machineIndex) {
      const inactive = machineIndexes.filter(i => i !== machineIndex && !pointMachineSwings[i].isFromSequence)
      onInactivePointMachinesUpdated(inactive)

      if (activePhases !== undefined) {
        onActivePhasesUpdated(undefined)
      }

      if (activeReferenceCurve !== undefined) {
        onActiveReferenceCurveUpdated(machineIndex)
      }

      if (activeSlipCurrent !== undefined) {
        onActiveSlipCurrentUpdated(machineIndex)
      }

      onActiveAlarmPhasesUpdated(machineIndex, alarms)
    } else {
      onActiveAlarmPhasesUpdated(undefined, alarms)
    }
  }

  const renderAlarmPhasesButton = (machineIndex: number) => {
    const supportsAlarmPhases = true
    const alarmPhasesDisabled = alarmsWithPhaseIdsArray !== undefined ? !alarmsWithPhaseIdsArray[machineIndex]?.length : true
    const alarmPhaseActive = activeAlarmPhases === machineIndex

    return (
      supportsAlarmPhases && (
        <div>
          {alarmPhasesDisabled ? (
            <StyledMissingValueText>{t('alarms.alarmPhases.noAlarms')}</StyledMissingValueText>
          ) : (
            <StyledLegendButton
              type="button"
              active={alarmPhaseActive}
              title={alarmPhaseActive ? t('alarms.alarmPhases.hide') : t('alarms.alarmPhases.show')}
              onClick={() => toggleAlarmPhases(machineIndex)}
            >
              <LegendPhasesIcon bands={[themeColors.error, themeColors.warning]} inactive={!alarmPhaseActive} />{' '}
              <span>{t('alarms.alarmPhases.title')}</span>
            </StyledLegendButton>
          )}
        </div>
      )
    )
  }

  const toggleSlipCurrent = (machineIndex: number) => {
    if (activeSlipCurrent !== machineIndex) {
      onActiveSlipCurrentUpdated(machineIndex)

      const inactive = machineIndexes.filter(i => i !== machineIndex && !pointMachineSwings[i].isFromSequence)
      onInactivePointMachinesUpdated(inactive)

      if (activeReferenceCurve !== undefined) {
        onActiveReferenceCurveUpdated(machineIndex)
      }

      if (activePhases !== undefined) {
        onActivePhasesUpdated(machineIndex)
      } else if (activeAlarmPhases !== undefined) {
        const alarms = alarmsWithPhaseIdsArray !== undefined ? alarmsWithPhaseIdsArray[machineIndex] : []
        onActiveAlarmPhasesUpdated(machineIndex, alarms)
      }
    } else {
      onActiveSlipCurrentUpdated(undefined)
    }
  }

  const renderSlipCurrentButton = (machineIndex: number, lineColor: string) => {
    const supportsSlipCurrent = true
    const slipCurrentActive = activeSlipCurrent === machineIndex
    const slipCurrentDisabled = !slipCurrents[machineIndex]

    return (
      supportsSlipCurrent && (
        <div>
          {slipCurrentDisabled ? (
            <StyledMissingValueText>
              {t(flexibleTranslation('switches.chart.slipCurrentMissing', isMediaSM))}
            </StyledMissingValueText>
          ) : (
            <StyledLegendButton
              type="button"
              active={slipCurrentActive}
              title={slipCurrentActive ? t('switches.chart.hideSlipCurrent') : t('switches.chart.showSlipCurrent')}
              onClick={() => toggleSlipCurrent(machineIndex)}
            >
              <LegendBand color={slipCurrentActive ? lineColor : themeColors.disabled} dashed />
              <span>{t(flexibleTranslation('switches.chart.slipCurrentLabel', isMediaSM))}</span>
            </StyledLegendButton>
          )}
        </div>
      )
    )
  }

  const toggleReferenceCurve = (machineIndex: number) => {
    if (machineIndex !== activeReferenceCurve) {
      onActiveReferenceCurveUpdated(machineIndex)

      const inactive = machineIndexes.filter(i => i !== machineIndex && !pointMachineSwings[i].isFromSequence)
      onInactivePointMachinesUpdated(inactive)

      if (activeSlipCurrent !== undefined) {
        onActiveSlipCurrentUpdated(machineIndex)
      }

      if (activePhases !== undefined) {
        onActivePhasesUpdated(machineIndex)
      } else if (activeAlarmPhases !== undefined) {
        const alarms = alarmsWithPhaseIdsArray !== undefined ? alarmsWithPhaseIdsArray[machineIndex] : []
        onActiveAlarmPhasesUpdated(machineIndex, alarms)
      }
    } else {
      onActiveReferenceCurveUpdated(undefined)
    }
  }

  const handleShowReferenceCurveModal = (machineIndex: number) => {
    const inactive = machineIndexes.filter(i => i !== machineIndex && !pointMachineSwings[i].isFromSequence)
    onInactivePointMachinesUpdated(inactive)
    onActiveReferenceCurveUpdated(undefined)
    onActivePhasesUpdated(undefined)
  }

  const renderReferenceCurveButton = (swing: MachineSwing, machineIndex: number) => {
    const supportsReferenceCurve = true
    const referenceCurveActive = activeReferenceCurve === machineIndex
    const referenceCurveDisabled = !swing.referenceCurve

    return (
      supportsReferenceCurve && (
        <>
          <div>
            {referenceCurveDisabled ? (
              <StyledMissingValueText>
                {t(flexibleTranslation('switches.chart.referenceCurveMissing', isMediaSM))}
              </StyledMissingValueText>
            ) : (
              <StyledLegendButton
                type="button"
                active={referenceCurveActive}
                title={referenceCurveActive ? t('switches.chart.hideReferenceCurve') : t('switches.chart.showReferenceCurve')}
                onClick={() => toggleReferenceCurve(machineIndex)}
              >
                <LegendBand
                  color={referenceCurveActive ? themeColors.chartColors.referenceCurve[0] : themeColors.disabled}
                  bandWidth={4}
                />
                <span>{t(flexibleTranslation('switches.chart.referenceCurveLabel', isMediaSM))}</span>
              </StyledLegendButton>
            )}
          </div>
          <SetReferenceCurveButton swing={swing} onShowReferenceCurveModal={() => handleShowReferenceCurveModal(machineIndex)} />
        </>
      )
    )
  }

  const togglePointMachine = (machineIndex: number) => {
    const arr = toggleArrayElement(inactivePointMachines, machineIndex)
    onInactivePointMachinesUpdated(arr)
  }

  const content = () =>
    pointMachineSwings.map((swing, machineIndex) => {
      if (isUnselectedSequenceSwing(asset.baneDataId, swing)) {
        const swingAssetType = getAssetTypeFromSwing(swing)
        const cardId = swing.switchBaneDataId ? getCardId(swing.switchBaneDataId, swingAssetType) : undefined
        return (
          <StyledPointMachine key={swing.baneDataId} isMediaLG={isMediaLG}>
            <Typography fontSize={14}>
              {t('switches.chart.relatedSequenceSwingMessage', {
                baneDataName: swing.isInSequenceWithInfo?.baneDataNameParsed,
              })}{' '}
              {cardId ? (
                <NavLink to={`/switches${document.location.search}#${cardId}`} replace>
                  <strong>{swing.baneDataNameParsed}</strong>
                </NavLink>
              ) : (
                <strong>{swing.baneDataNameParsed}</strong>
              )}
            </Typography>
          </StyledPointMachine>
        )
      }

      const pmActive = !inactivePointMachines.includes(machineIndex)
      const lineColor = getChartLineColorByIndex(machineIndex)
      const isComplete = swing.isComplete
      const active = pmActive && isComplete

      const incompleteText = t('switches.chart.incompleteSwingLegend')
      const pmTitle = pmActive ? t('switches.chart.hidePointMachine') : t('switches.chart.showPointMachine')

      return (
        <StyledPointMachine key={swing.baneDataId} isMediaLG={isMediaLG}>
          <div>
            <StyledLegendButton
              type="button"
              active={active}
              data-active={active}
              disabled={!isComplete}
              title={isComplete ? pmTitle : incompleteText}
              data-testid="point-machine-toggle"
              onClick={() => togglePointMachine(machineIndex)}
            >
              {isComplete && <LegendBand color={pmActive ? lineColor : themeColors.disabled} />}
              <span>{swing.baneDataNameParsed}</span>
            </StyledLegendButton>
          </div>
          {isComplete ? (
            <>
              {renderPhaseButton(swing, machineIndex)}
              {renderAlarmPhasesButton(machineIndex)}
              {renderSlipCurrentButton(machineIndex, lineColor)}
              {renderReferenceCurveButton(swing, machineIndex)}
            </>
          ) : (
            <StyledMissingValueText>{incompleteText}</StyledMissingValueText>
          )}
        </StyledPointMachine>
      )
    })

  return pointMachineSwings.length > 3 ? (
    <LegendCarousel>{content()}</LegendCarousel>
  ) : (
    <StyledPlainContainer isMediaLG={isMediaLG}>{content()}</StyledPlainContainer>
  )
}
