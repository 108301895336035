import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import ListItem from '@mui/material/ListItem'
import SearchIcon from '@mui/icons-material/SearchOutlined'

import { LocationsSelector } from './Locations/LocationsSelector'
import { DatePeriodSelector } from 'src/Features/SelectedDatePeriod/DatePeriodSelector/DatePeriodSelector'
import { Button } from 'src/Components/Button'
import { useLocationsAndDatePeriodSearch } from 'src/Hooks/useLocationsAndDatePeriodSearch'
import { ErrorBoundary } from 'src/Components/ErrorBoundary'

const useStyles = makeStyles()({
  searchButton: {
    padding: 10,
    marginLeft: 10,
    minWidth: 250,
    borderRadius: 25,
    fontSize: 15,
  },
  searchIcon: {
    verticalAlign: 'top',
  },
})

export const LocationsAndDatePeriodSelector = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const { searchDisabled, triggerSearch } = useLocationsAndDatePeriodSearch()

  return (
    <>
      <ListItem>
        <ErrorBoundary>
          <LocationsSelector />
        </ErrorBoundary>
      </ListItem>
      <ListItem>
        <ErrorBoundary>
          <DatePeriodSelector />
        </ErrorBoundary>
      </ListItem>
      <ListItem>
        <Button
          buttonClass={classes.searchButton}
          disabled={searchDisabled}
          onClick={triggerSearch}
          endIcon={<SearchIcon className={classes.searchIcon} />}
        >
          {t('general.search')}
        </Button>
      </ListItem>
    </>
  )
}
