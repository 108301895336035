import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'

import ButtonGroup from '@mui/material/ButtonGroup'

import { Button } from 'src/Components/Button'
import themeColors from 'src/theme'

const StyledButtonGroup = styled(ButtonGroup)(
  props => `
  align-items: center;
  min-width: 100%;
  ${props.theme.breakpoints.down('sm')} {
    margin: 0;
  }
`
)

const StyledButtonLeft = styled(Button)`
  border-radius: 25px 0 0 25px;
  width: 50%;
  padding: 10px 16px;

  &:disabled {
    background-color: ${themeColors.primary};
    color: #fff;
    border: 1px solid ${themeColors.primary};
  }
`
const StyledButtonRight = styled(Button)`
  border-radius: 0 25px 25px 0;
  width: 50%;
  padding: 10px 16px;

  &:disabled {
    background-color: ${themeColors.primary};
    color: #fff;
    border: 1px solid ${themeColors.primary};
  }
`

type OwnProps = {
  isCardView: boolean
  onCardViewUpdated: (cardView: boolean) => void
}

export const ViewSelectorButton = ({ isCardView, onCardViewUpdated }: OwnProps) => {
  const { t } = useTranslation()

  return (
    <StyledButtonGroup variant="contained" disableElevation aria-label={t('sideMenu.view.title')}>
      <StyledButtonLeft variant="outlined" disabled={!isCardView} onClick={() => onCardViewUpdated(false)}>
        {t('sideMenu.view.stream')}
      </StyledButtonLeft>
      <StyledButtonRight variant="outlined" disabled={isCardView} onClick={() => onCardViewUpdated(true)}>
        {t('sideMenu.view.card')}
      </StyledButtonRight>
    </StyledButtonGroup>
  )
}
