import { startOfHour } from 'date-fns'

import { ApiType } from 'src/Types/ApiType'
import type { Weather } from 'src/Types/WeatherTypes'

import { callApiGet } from 'src/msGraphClient'
import config from 'src/Config/Config'

const { FORECASTS } = config.API_FRAGMENTS.apim

export type GetWeatherParams = {
  baneDataLocationId: string
  fromDate: number
  toDate: number
}

export const getWeatherForecast = ({ baneDataLocationId, fromDate, toDate }: GetWeatherParams) => {
  // When fetching weather for a partial hour, the current hour's readings won't be included,
  // so extend the start time to the beginning of the hour.
  const beginningOfFromHour = startOfHour(fromDate)

  const urlParams = new URLSearchParams({
    baneDataLocationId,
    fromDate: beginningOfFromHour.toJSON(),
    toDate: new Date(toDate).toJSON(),
  })

  return callApiGet<Weather[]>(`${FORECASTS}?${urlParams.toString()}`, ApiType.APIM, { parseDates: true })
}
