import type { SeverityLevel } from '@microsoft/applicationinsights-web'
import { getAppInsights } from 'src/telemetryService'
import { cloneError, deriveError, getStacktraceText } from 'src/Tracking/errors'
import StackTrace from 'stacktrace-js'

export const getErrorWithRealStack = async (error: Error) => {
  const errorWithStack = cloneError(error)
  try {
    const stackFrames = await StackTrace.fromError(error)
    const stack = getStacktraceText(error, stackFrames)
    errorWithStack.stack = stack
  } catch (e) {
    console.error(e)
  }
  return errorWithStack
}

export const trackException = async (
  exception: unknown,
  severityLevel?: SeverityLevel,
  customProperties?: Record<string, any>
) => {
  const ai = getAppInsights()
  if (!ai) {
    return
  }

  let error: Error | undefined
  if (exception instanceof Error) {
    error = exception
  } else {
    error = deriveError(`${exception}`, 2)
  }
  const errorWithStack = await getErrorWithRealStack(error)

  ai.trackException(
    {
      exception: errorWithStack,
      severityLevel,
    },
    { ...customProperties, href: window.location.href }
  )
}
