import type { ReactElement } from 'react'
import { styled } from '@mui/material/styles'

type OwnProps = {
  term: string
  description: string | number | ReactElement
  title?: string
  className?: string
}

const StyledList = styled('dl')`
  display: flex;
  margin: 4px 0;
`

const StyledTerm = styled('dt')`
  font-weight: bold;
`

const StyledDescription = styled('dd')`
  margin-inline-start: ${props => props.theme.spacing(1)};
`

export const Definition = ({ term, description, title = '', className = '' }: OwnProps) => (
  <StyledList className={className} title={title}>
    <StyledTerm key={term}>{term}:</StyledTerm>
    <StyledDescription>{description}</StyledDescription>
  </StyledList>
)
