import { useState, useCallback, useEffect } from 'react'
import * as d3 from 'd3'
import debounce from '@mui/material/utils/debounce'

import type { ChartFocus } from 'src/Types/ChartTypes'

const ZOOM_FOCUS_DELAY = 16

type UseChartFocusParams = {
  fromDate: number
  toDate: number
}

export const useChartFocus = ({ fromDate, toDate }: UseChartFocusParams) => {
  const initialState = () => ({
    fromDate,
    toDate,
    transform: d3.zoomIdentity,
  })

  const [chartFocus, setChartFocus] = useState<ChartFocus>(initialState)

  useEffect(() => {
    setChartFocus(initialState)
  }, [fromDate, toDate]) // eslint-disable-line react-hooks/exhaustive-deps

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateFocus = useCallback(debounce(setChartFocus, ZOOM_FOCUS_DELAY), [])

  const zoomUpdated = useCallback(
    (left: number, right: number, transform: d3.ZoomTransform) => {
      updateFocus({
        fromDate: left,
        toDate: right,
        transform,
      })
    },
    [updateFocus]
  )

  return { chartFocus, zoomUpdated }
}
