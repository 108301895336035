import { AssetType } from 'src/Types/AssetTypes'
import { CriticalityComponentTypeEnum } from 'src/Types/AlarmCriticality'

/**
 * The Statistics-related CriticalityComponentTypeId.
 * See doc on AssetType for more info/references
 * @param assetType
 */
export const getComponentTypeIdOrUndefined = (assetType: AssetType): CriticalityComponentTypeEnum | undefined => {
  switch (assetType) {
    case AssetType.SwitchPointMachine:
    case AssetType.DerailerMachine:
      return CriticalityComponentTypeEnum.Switch // 1

    case AssetType.TrackCircuit:
      return CriticalityComponentTypeEnum.TrackCircuit // 2

    case AssetType.SwitchHeat:
      return CriticalityComponentTypeEnum.SwitchHeat // 3

    case AssetType.Bender:
    case AssetType.Rack:
      return CriticalityComponentTypeEnum.SignalEarthFault // 4

    case AssetType.SwitchMachineHeat:
      return CriticalityComponentTypeEnum.MachineHeat // 5

    case AssetType.Unknown:
    default:
      return undefined
  }
}
