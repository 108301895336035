import { RainIcon } from './RainIcon'
import { CloudinessIcon } from './CloudinessIcon'

import type { Weather } from 'src/Types/WeatherTypes'

import { getPrecipitation } from 'src/Features/Weather/utils'

type OwnProps = {
  weather: Weather
  onWhiteBackground?: boolean
  small?: boolean
}

export const WeatherIcon = ({ weather, onWhiteBackground = false, small = false }: OwnProps) => {
  const Icon = getPrecipitation(weather) === 'none' ? CloudinessIcon : RainIcon

  return <Icon weather={weather} onWhiteBackground={onWhiteBackground} small={small} />
}
