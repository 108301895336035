import type { AffectedSystem } from 'src/Types/SystemStatusAlert'
import { AppPage } from 'src/Types/AppPage'
import { getAffectedSystemDefinitionByRoute, getGroupedAffectedSystems } from 'src/Features/SystemStatusAlerts/routeDefinitions'

/**
 * Returns true if the given route's local system in its SYSTEM_MESSAGE_SYSTEMS
 * is among "affected" systems for the alert, meaning included in the affectedSystems
 * of the alert (ref. param systemsForAlert).
 * For example, for the routes for Track Circuits pages (i.e. /trackcircuits and /trackcircuits-cards),
 * AffectedSystem.TrackCircuit is a local system not appearing in other pages' SYSTEM_STATUS_SYSTEMS).
 * @param route Typically the current route (e.g. /trackcircuits-cards)
 * @param systemsForAlert The (by default) affected systems given by the alert (not all values here will
 * necessarily be among "affected" systems for the alert if there also is a
 * HideFromOtherPages present in the Description of the alert).
 */
export const aLocalSystemForRouteIsAmongAffected = (route: string, systemsForAlert: AffectedSystem[]) => {
  const { allLocalAffectedSystems } = getGroupedAffectedSystems()

  const definition = getAffectedSystemDefinitionByRoute(route)

  if (definition?.localSystems?.length) {
    return definition.localSystems?.some(system => systemsForAlert.includes(system))
  }

  if (allLocalAffectedSystems.some(system => systemsForAlert.includes(system))) {
    return false
  }

  return route !== `/${AppPage.Favorites}`
}
