import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { getLanguage } from 'src/Utils/language'

export const useLanguage = () => {
  const { t, i18n } = useTranslation<string>()
  const currentLanguage = getLanguage(i18n.language)

  // Fix issue with translations not updating
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const location = useLocation()

  return {
    t,
    i18n,
    currentLanguage,
  }
}
