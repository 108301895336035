import { useQueryWithErrorHandling } from 'src/Hooks/NetworkData/useQueryWithErrorHandling'
import { getSwitchHeatModus } from 'src/Providers/SwitchHeat'

export const useSwitchHeatAvailableModus = (modusSensorId: string | undefined) => {
  const { data: availableModuses, status } = useQueryWithErrorHandling(
    ['switchHeatModus', { modusSensorId }],
    () => (modusSensorId ? getSwitchHeatModus(modusSensorId) : undefined),
    {},
    'switchHeat.sensorInfo.fetchError'
  )

  return { availableModuses, status }
}
