import { useQuery } from 'react-query'
import type { QueryKey, QueryFunction, UseQueryOptions } from 'react-query/types'

import type { QueryError } from 'src/Types/QueryError'

import { getCustomErrorMessage, useAppErrorMessage } from '../UseAppErrorMessage'

const MAX_FAILURES = 3

export const useQueryWithErrorHandling = <TResult = unknown>(
  queryKey: QueryKey,
  queryFn: QueryFunction<TResult>,
  queryConfig: UseQueryOptions<TResult, QueryError>,
  genericErrorKey: string
) => {
  const query = useQuery<TResult, QueryError>(queryKey, queryFn, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: (failureCount, error) => {
      const customError = getCustomErrorMessage(error)
      return failureCount < MAX_FAILURES && !customError
    },
    ...queryConfig,
  })

  useAppErrorMessage(query, genericErrorKey)

  return query
}
