import { ApiType } from 'src/Types/ApiType'
import { AssetType } from 'src/Types/AssetTypes'

import type { DateRange } from 'src/Types/DatePeriod'
import type { AssetWithAlarms } from 'src/Types/AssetWithAlarms'
import { InternalAlarmSystem } from 'src/Types/AlarmSystem'
import type { OrchestratedRack, Rack } from 'src/Types/Rack'
import type { GetOrchestratedAssetsParams } from 'src/Types/GetOrchestratedAssetsParams'

import { callApiGet } from 'src/msGraphClient'
import { getCriticalityValue } from 'src/Utils/criticality'
import { getOrchestratedAssetIdParams } from 'src/Utils/asset'
import config from 'src/Config/Config'

const { RACKS } = config.API_FRAGMENTS.apim

export const getOrchestratedRacks = (params: GetOrchestratedAssetsParams): Promise<AssetWithAlarms[]> => {
  const urlParams = new URLSearchParams({
    fromDate: new Date(params.dateRange.fromDate).toJSON(),
    toDate: new Date(params.dateRange.toDate).toJSON(),
    onlyWithAlarms: params.onlyWithAlarms.toString(),
    feFilterEnabled: (!params.enableAllAlarmTypes).toString(),
  })

  const { idKey, value } = getOrchestratedAssetIdParams(params)
  urlParams.set(idKey, value)

  return callApiGet<OrchestratedRack[]>(`${RACKS}?${urlParams.toString()}`, ApiType.APIM, { parseDates: true })
    .then(res =>
      res.map<AssetWithAlarms>(entry => {
        const asset: Rack = {
          assetType: AssetType.Rack,
          baneDataId: entry.assetData.baneDataId,
          name: entry.assetData.name,
          baneDataLocationName: entry.assetData.locationName,
          baneDataLocationId: entry.assetData.locationId,
          isMonitored: entry.status.isMonitored,
          componentCriticalityLocal: getCriticalityValue(entry.assetData, 'ComponentCriticalityLocal'),
          alarmCriticalityAbsolute: getCriticalityValue(entry.assetData, 'AlarmCriticalityAbsolute'),
          totalCriticalityAbsolute: getCriticalityValue(entry.assetData, 'TotalCriticalityAbsolute'),
          statusWithoutAssetStatusWindows: entry.status.statusWithoutAssetStatusWindows,
          statusWithAssetStatusWindows: entry.status.statusWithAssetStatusWindows,
          showCriticality: entry.status.showCriticality,
          assetStatusWindows: entry.status.assetStatusWindows,
          installDate: entry.assetData.installDate,
          sectionSensors: entry?.rackAssetData?.sectionSensors?.length
            ? entry.rackAssetData.sectionSensors.map(rs => ({
                ...rs,
                baneDataLocationName: entry.assetData.locationName,
                baneDataLocationId: entry.assetData.locationId,
                neighbors: entry.rackAssetData.sectionSensors
                  .filter(n => n.baneDataId !== rs.baneDataId)
                  .map(n => ({
                    baneDataId: n.baneDataId,
                    name: n.name,
                  })),
              }))
            : [],
          dailyStatus: entry.status.dailyStatus,
          fromKm: entry.assetData.fromKm,
          toKm: entry.assetData.toKm,
        }

        return {
          asset,
          alarms: entry.aggregateAlarms.map(alarm => ({
            ...alarm,
            internalAlarmSystem: InternalAlarmSystem.AggregatedRackAlarm,
          })),
        }
      })
    )
    .then(result => result.sort((a, b) => a.asset.name.localeCompare(b.asset.name, config.STRING_LOCALE)))
}

export const getOrchestratedRacksAtLocations = (
  dateRange: DateRange,
  locationIds: string[],
  onlyWithAlarms: boolean,
  enableAllAlarmTypes: boolean
) => getOrchestratedRacks({ dateRange, locationIds, onlyWithAlarms, enableAllAlarmTypes })

export const getOrchestratedRacksWithIds = (
  dateRange: DateRange,
  baneDataIds: string[],
  onlyWithAlarms: boolean,
  enableAllAlarmTypes: boolean
) => getOrchestratedRacks({ dateRange, baneDataIds, onlyWithAlarms, enableAllAlarmTypes })
