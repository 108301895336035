import { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import { Alert } from 'src/Components/Alert'
import { RackEarthCurrentRenderer } from './rackEarthCurrentRenderer'

import type { ChartFocusUpdatedEventHandler, ChartLabels } from 'src/Types/ChartTypes'
import type { DatePeriod } from 'src/Types/DatePeriod'
import type { RackSectionSensor } from 'src/Types/Rack'
import { useDomainConstantsStore } from 'src/Store/domainConstants'
import { useUserProfileStore } from 'src/Store/userProfile'
import { useChartStyles } from 'src/Components/Chart/chartStyles'
import { useRackSectionHistory } from 'src/Hooks/NetworkData/useRackSectionHistory'
import { useLanguage } from 'src/Hooks/useLanguage'
import { getDateRange } from 'src/Features/SelectedDatePeriod/predefinedDatePeriods'
import { useRackEarthCurrentLegends } from 'src/Components/Chart/Legends/useRackEarthCurrentLegends'
import { ChartLegendsList } from 'src/Components/Chart/Legends/ChartLegendsList'
import { useChartResizeBehaviour } from 'src/Hooks/useChartResizeBehaviour'
import { createPageLink } from 'src/Utils/pageLink'
import { LinkButton } from 'src/Components/LinkButton'
import { AppPage } from 'src/Types/AppPage'

const useStyles = makeStyles()(theme => ({
  container: {
    margin: 16,
    [theme.breakpoints.down('md')]: {
      margin: 4,
    },
    [theme.breakpoints.down('sm')]: {
      margin: 2,
    },
  },
  chart: {
    position: 'relative',
  },
}))

type OwnProps = {
  selectedRackSections: RackSectionSensor[]
  datePeriod: DatePeriod
  onZoomUpdated?: ChartFocusUpdatedEventHandler
  view: 'stream' | 'card'
}

export const RackEarthCurrentChart = ({ selectedRackSections, datePeriod, onZoomUpdated, view }: OwnProps) => {
  const { t, currentLanguage } = useLanguage()
  const { classes } = useStyles()
  const { classes: chartClasses } = useChartStyles()
  const chartRef = useRef<HTMLDivElement>(null)
  const location = useLocation()
  const isSignalEarthPage = location.pathname.startsWith('/signalearth')

  const sensorCodes = useDomainConstantsStore(state => state.sensorCodes.sensorCodes.SEF)
  const hideChartZoomButtons = useUserProfileStore(state => state.settings.settings.hideChartZoomButtons)
  const [renderer, setRenderer] = useState<RackEarthCurrentRenderer>()
  const [inactiveSensors, setInactiveSensors] = useState<number[]>([])

  const selectedRSIds = useMemo(() => selectedRackSections.map(rs => rs.baneDataId), [selectedRackSections])

  const { sensorHistory, status } = useRackSectionHistory({
    sensorIds: selectedRSIds,
    datePeriod,
  })

  const inactiveSensorsUpdated = (inactiveSensors: number[]) => {
    setInactiveSensors(inactiveSensors)
    if (renderer) {
      renderer.setInactiveSensors(inactiveSensors)
    }
  }

  useChartResizeBehaviour(renderer)

  const { fromDate, toDate } = useMemo(() => getDateRange(datePeriod), [datePeriod])

  useEffect(() => {
    const chart = chartRef.current
    if (chart && sensorHistory) {
      const labels: ChartLabels = {
        zoomIn: t('general.zoomIn'),
        zoomOut: t('general.zoomOut'),
        resetZoom: t('general.resetZoom'),
      }

      const chartRenderer = new RackEarthCurrentRenderer({
        chart,
        timeline: {
          start: fromDate,
          end: toDate,
        },
        classes: {
          tooltip: chartClasses.tooltip,
          tooltipArrow: chartClasses.tooltipArrow,
          zoomButton: chartClasses.zoomButton,
        },
        onZoomUpdated,
      })
      setRenderer(chartRenderer)

      setInactiveSensors([])
      chartRenderer.setData(sensorHistory, selectedRackSections)
      chartRenderer.setInactiveSensors([])
      chartRenderer.setLabels(labels, currentLanguage)
      chartRenderer.setShowZoomButtons(!hideChartZoomButtons)
      chartRenderer.render()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensorHistory, currentLanguage, chartClasses, fromDate, toDate, sensorCodes, t])

  const legends = useRackEarthCurrentLegends(sensorHistory, selectedRackSections, inactiveSensors)

  if (status === 'idle') {
    return null
  }

  if (status === 'error') {
    return (
      <Alert severity="error" margin={16}>
        {t('racks.historyFetchError')}
      </Alert>
    )
  }

  return (
    <>
      {status === 'loading' && (
        <div className={classes.container}>
          <CircularProgress color="inherit" size={15} /> {t('racks.chart.loading')}
        </div>
      )}
      {(!sensorHistory || !sensorHistory.length) && status === 'success' && (
        <div className={classes.container}>{t('racks.chart.noneFound')}</div>
      )}
      {sensorHistory && !!sensorHistory.length && (
        <div className={classes.container}>
          {view === 'card' && (
            <Box textAlign="right">
              <LinkButton href={createPageLink(AppPage.SignalEarthStream, { rsIds: sensorHistory[0].twinId || '' })}>
                {isSignalEarthPage
                  ? t('general.openStreamView')
                  : `${t('general.goTo', { value: t('assetName.rack').toLowerCase() })} ${sensorHistory[0].twinId}`}
              </LinkButton>
            </Box>
          )}
          <div className={classes.chart} ref={chartRef} />
          <ChartLegendsList
            legends={legends}
            inactiveIndexes={inactiveSensors}
            onInactiveIndexesUpdated={inactiveSensorsUpdated}
            emptyLegendsLabel={t('racks.chart.noData')}
            loading={status === 'loading'}
          />
        </div>
      )}
    </>
  )
}
