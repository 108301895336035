import type { SwitchHeatApiVersions } from 'src/Providers/SwitchHeat'
import { getHistoricHeatSensorValuesForSwitch } from 'src/Providers/SwitchHeat'
import { useQueryWithErrorHandling } from './useQueryWithErrorHandling'

export const useHistoricSwitchHeatSensorData = (
  heatCabinetId: string,
  codeSystemKeys: string[],
  date: number,
  version: SwitchHeatApiVersions
) => {
  const query = useQueryWithErrorHandling(
    ['switchHeatSensorData', { heatCabinetId, codeSystemKeys, date }],
    () => getHistoricHeatSensorValuesForSwitch(heatCabinetId, codeSystemKeys, date, version),
    {},
    'switchHeat.sensorInfo.fetchError'
  )

  return {
    ...query,
    switchHeatSensorData: query.data,
  }
}
