import type { TooltipConfig } from 'src/Types/ChartTypes'

import { clamp } from './number'
import themeColors from 'src/theme'

const DEFAULT_TOOLTIP_MARGIN = 6
const DEFAULT_TOOLTIP_ARROW_WIDTH = 10
const DEFAULT_TOOLTIP_ARROW_HEIGHT = 12
const DEFAULT_TOOLTIP_ARROW_CORNER_OFFSET = 6
const DEFAULT_TOOLTIP_Y_OFFSET = -6

export const getTooltipConfig = (config: Partial<TooltipConfig> = {}) => {
  const defaultConfig: TooltipConfig = {
    arrowWidth: DEFAULT_TOOLTIP_ARROW_WIDTH,
    arrowHeight: DEFAULT_TOOLTIP_ARROW_HEIGHT,
    arrowCornerOffset: DEFAULT_TOOLTIP_ARROW_CORNER_OFFSET,
    yOffset: DEFAULT_TOOLTIP_Y_OFFSET,
    tooltipMargin: DEFAULT_TOOLTIP_MARGIN,
    chartMargin: { top: 0, right: 0, bottom: 0, left: 0 },
  }

  return {
    ...defaultConfig,
    ...config,
  }
}

export const getTooltipPosition = (
  tx: number,
  ty: number,
  tooltipNode: HTMLDivElement,
  chartWidth: number,
  tooltipConfig: Partial<TooltipConfig>
) => {
  const config = getTooltipConfig(tooltipConfig)

  const { clientWidth: tooltipWidth, clientHeight: tooltipHeight } = tooltipNode

  const y = Math.floor(ty - tooltipHeight - config.arrowHeight + config.yOffset)

  const targetX = tx - tooltipWidth / 2
  const min = config.tooltipMargin
  const max = chartWidth - tooltipWidth - config.tooltipMargin
  const x = Math.floor(clamp(targetX, min, max))

  const arrowMargin = config.arrowCornerOffset + config.tooltipMargin
  const centeredArrowX = Math.floor(tx - config.arrowWidth / 2)
  const minArrowX = Math.floor(arrowMargin + config.chartMargin.left)
  const maxArrowX = Math.floor(chartWidth - arrowMargin - config.arrowWidth - config.chartMargin.right)

  const arrowX = clamp(centeredArrowX, minArrowX, maxArrowX)
  const arrowY = y + tooltipHeight

  return {
    box: {
      x,
      y,
    },
    arrow: {
      x: arrowX,
      y: arrowY,
      tipX: Math.floor(tx),
    },
  }
}

export const tooltipArrowPolygon = (tipOffset: number, tooltipConfig: Partial<TooltipConfig> = {}) => {
  const config = getTooltipConfig(tooltipConfig)

  const baseX = tipOffset < 0 ? Math.abs(tipOffset) : 0
  const topLeft = `${baseX},0`
  const topRight = `${baseX + config.arrowWidth},0`
  const bottom = `${Math.max(tipOffset, 0)},${config.arrowHeight}`

  return `${topLeft} ${topRight} ${bottom}`
}

export const tooltipArrowBoxWidth = (tipOffset: number, tooltipConfig: Partial<TooltipConfig> = {}) => {
  const { arrowWidth } = getTooltipConfig(tooltipConfig)
  return Math.max(Math.abs(tipOffset - arrowWidth / 2) + arrowWidth / 2, arrowWidth)
}

const chartLineColors = themeColors.chartColors.lines
export const getChartLineColorByIndex = (index: number) => chartLineColors[index % chartLineColors.length]
