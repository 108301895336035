import ListItem from '@mui/material/ListItem'

import { AssetIdFilterSelector } from 'src/Components/SidePanelMenu/FilterSelectors/AssetIdFilterSelector'
import { StatusFilterSelector } from 'src/Components/SidePanelMenu/FilterSelectors/StatusFilterSelector'
import { SystemFilterSelector } from 'src/Components/SidePanelMenu/FilterSelectors/SystemFilterSelector'
import { SortDropdown } from 'src/Components/SidePanelMenu/SortDropdown'

import type { AlarmTypeIdFilter, AssetIdFilter, Filter, StatusFilter, SystemFilter } from 'src/Types/Filter'
import { FilterType } from 'src/Types/Filter'
import type { Asset } from 'src/Types/AssetTypes'
import { SortOrder } from 'src/Types/SortOrder'
import type { SystemStatus } from 'src/Types/SystemStatus'
import { AlarmTypeIdFilterSelector } from 'src/Components/SidePanelMenu/FilterSelectors/AlarmTypeIdFilterSelector'
import type { GenericCardListRoutes } from 'src/Features/GenericCardListPage/Config'

import { AppPage } from 'src/Types/AppPage'

function getFilter(filterType: FilterType.AssetId, filters: Filter[]): AssetIdFilter | undefined
function getFilter(filterType: FilterType.System, filters: Filter[]): SystemFilter | undefined
function getFilter(filterType: FilterType.Status, filters: Filter[]): StatusFilter | undefined
function getFilter(filterType: FilterType.AlarmTypeId, filters: Filter[]): AlarmTypeIdFilter | undefined
function getFilter(filterType: FilterType, filters: Filter[]): Filter | undefined {
  return filters.find(f => f.type === filterType)
}

type OwnProps = {
  route: GenericCardListRoutes
  assets: Asset[]
  filters: Filter[]
  sortOrder: SortOrder
  disableCriticalitySort?: boolean
  statusOptions: SystemStatus[]
  systemOptions: string[]
  onFiltersUpdated: (filters: Filter[]) => void
  onSortOrderUpdated: (sortOrder: SortOrder) => void
}

export const AssetListFilters = ({
  route,
  assets,
  filters,
  sortOrder,
  disableCriticalitySort,
  statusOptions,
  systemOptions,
  onFiltersUpdated,
  onSortOrderUpdated,
}: OwnProps) => {
  const filterUpdated = (filter: Filter) => {
    let updatedFilters = filters.filter(f => f.type !== filter.type)
    if ('values' in filter) {
      if (filter.values.length) {
        updatedFilters.push(filter)
      }
    } else {
      updatedFilters.push(filter)
    }

    if (filter.type === FilterType.System) {
      // FilterType.System only exists on Alarms view
      // Remove the selected alarm type filter if any, since changing the
      // system filter changes the type filter list
      updatedFilters = updatedFilters.filter(f => f.type !== FilterType.AlarmTypeId)
    }
    onFiltersUpdated(updatedFilters)
  }

  const assetIdFilter = getFilter(FilterType.AssetId, filters)
  const statusFilter = getFilter(FilterType.Status, filters)
  const systemFilter = getFilter(FilterType.System, filters)

  const sortOrderOptions = [
    SortOrder.LatestAlarm,
    SortOrder.Criticality,
    SortOrder.Alphabetical,
    SortOrder.Unopened,
    SortOrder.Kilometer,
  ]

  const filterDropdowns = [
    <SortDropdown
      key="sort"
      sortOrderOptions={sortOrderOptions}
      sortOrder={sortOrder}
      onSortOrderUpdated={onSortOrderUpdated}
      disableCriticalitySort={disableCriticalitySort}
    />,
    <AssetIdFilterSelector key="assetId" filter={assetIdFilter} assets={assets} onFilterUpdated={filterUpdated} />,
    <StatusFilterSelector
      key="status"
      statusOptions={statusOptions}
      isAlarmView={route === AppPage.AlarmCards}
      filter={statusFilter}
      onFilterUpdated={filterUpdated}
    />,
  ]

  if (systemOptions?.length > 1) {
    filterDropdowns.push(
      <SystemFilterSelector key="system" systemOptions={systemOptions} filter={systemFilter} onFilterUpdated={filterUpdated} />
    )
  }

  if (route === AppPage.AlarmCards) {
    const alarmTypeIdFilter = getFilter(FilterType.AlarmTypeId, filters)
    filterDropdowns.push(
      <AlarmTypeIdFilterSelector
        key="typeId"
        filter={alarmTypeIdFilter}
        onFilterUpdated={filterUpdated}
        assetTypeSystems={systemFilter?.values}
        status={statusFilter?.values}
      />
    )
  }

  return (
    <>
      {filterDropdowns.map(filter => (
        <ListItem key={filter.key!}>{filter}</ListItem>
      ))}
    </>
  )
}
