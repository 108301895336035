import {
  getInitialPageLoadTime,
  scriptEvalTime,
  sessionInitialPageLoadTime,
  sessionScriptLoadDuration,
} from 'src/Tracking/trackingValues'

// Track first script load time, before auth redirects
export const initTelemetryMetrics = () => {
  const initialPageLoadTime = getInitialPageLoadTime()
  if (!initialPageLoadTime) {
    return
  }
  if (!sessionInitialPageLoadTime.hasValue()) {
    sessionInitialPageLoadTime.set(initialPageLoadTime)
  }
  if (!sessionScriptLoadDuration.hasValue()) {
    // Store the value in sessionStorage temporarily, will be tracked later.
    sessionScriptLoadDuration.set(scriptEvalTime - initialPageLoadTime)
  }
}
