import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'

import { SubHeaderWithExpandableInfo } from 'src/Components/SubHeaderWithExpandableInfo'
import { SensorDataChart } from 'src/Features/TrackCircuits/SensorDataChart/SensorDataChart'
import { WeatherChart } from 'src/Features/Weather/WeatherChart/WeatherChart'

import { TrackCircuitCurrentType } from 'src/Types/TrackCircuitCurrentTypes'
import type { TrackCircuit } from 'src/Types/TrackCircuitTypes'
import themeColors from 'src/theme'
import { useChartFocus } from 'src/Components/Chart/useChartFocus'
import { mapToLocations } from 'src/Features/Weather/WeatherChart/mapToLocations'
import { SubHeaderKey } from 'src/Providers/Settings/SubHeader'

type OwnProps = {
  selectedFromDate: number
  selectedToDate: number
  selectedTrackCircuits: TrackCircuit[]
  onAddTrackCircuits?: (tcBaneDataIds: string[]) => void
}

const useStyles = makeStyles()(theme => ({
  card: {
    backgroundColor: '#fff',
    borderRadius: 0,
    margin: 16,
    overflow: 'inherit',
    [theme.breakpoints.down('sm')]: {
      margin: '8px 4px',
    },
  },
  cardContent: {
    paddingBottom: 0,
  },
  subSectionContainer: {
    border: `1px solid ${themeColors.sectionHeader}`,
    backgroundColor: '#fff',
    '& > p': {
      paddingLeft: 8,
    },
  },
}))

export const TrackCircuitsStreamCard = ({
  selectedFromDate,
  selectedToDate,
  selectedTrackCircuits,
  onAddTrackCircuits,
}: OwnProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const { chartFocus, zoomUpdated } = useChartFocus({ fromDate: selectedFromDate, toDate: selectedToDate })

  const locations = mapToLocations(selectedTrackCircuits)

  const selectedBaneDataIds = useMemo(() => selectedTrackCircuits.map(tc => tc.baneDataId), [selectedTrackCircuits])

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <SubHeaderWithExpandableInfo
          title={t('trackCircuits.returnCurrent')}
          hideText={t('trackCircuits.hideReturnCurrent')}
          isDefaultExpanded
          subHeaderKey={SubHeaderKey.TrackCircuitsReturnCurrent}
        >
          <div className={classes.subSectionContainer}>
            {selectedTrackCircuits.length === 0 && <p>{t('trackCircuits.infoReturn')}</p>}
            {selectedTrackCircuits.length > 0 && (
              <SensorDataChart
                fromDate={selectedFromDate}
                toDate={selectedToDate}
                baneDataIds={selectedBaneDataIds}
                currentType={TrackCircuitCurrentType.RC}
                onZoomUpdated={zoomUpdated}
                zoomSync={chartFocus}
                onAddOccupiedTrackCircuits={onAddTrackCircuits}
                selectedTrackCircuits={selectedTrackCircuits}
              />
            )}
          </div>
        </SubHeaderWithExpandableInfo>
        <SubHeaderWithExpandableInfo
          title={t('trackCircuits.feedCurrent')}
          hideText={t('trackCircuits.hideFeedCurrent')}
          subHeaderKey={SubHeaderKey.TrackCircuitsFeedCurrent}
        >
          <div className={classes.subSectionContainer}>
            {selectedTrackCircuits.length === 0 && <p>{t('trackCircuits.infoFeed')}</p>}
            {selectedTrackCircuits.length > 0 && (
              <SensorDataChart
                fromDate={selectedFromDate}
                toDate={selectedToDate}
                baneDataIds={selectedBaneDataIds}
                currentType={TrackCircuitCurrentType.FC}
                onZoomUpdated={zoomUpdated}
                zoomSync={chartFocus}
                onAddOccupiedTrackCircuits={onAddTrackCircuits}
                selectedTrackCircuits={selectedTrackCircuits}
              />
            )}
          </div>
        </SubHeaderWithExpandableInfo>

        <Box sx={{ mt: 2 }}>
          {locations.map(({ locationId, locationName }, index) => (
            <SubHeaderWithExpandableInfo
              title={t('weatherChart.title', { stationName: locationName })}
              hideText={t('weatherChart.hideWeather')}
              forcedClosed={index > 0}
              key={locationId}
              subHeaderKey={SubHeaderKey.WeatherChart}
            >
              <div className={classes.subSectionContainer}>
                <WeatherChart
                  baneDataLocationId={locationId}
                  fromDate={selectedFromDate}
                  toDate={selectedToDate}
                  chartFocus={chartFocus}
                />
              </div>
            </SubHeaderWithExpandableInfo>
          ))}
        </Box>
      </CardContent>
    </Card>
  )
}
