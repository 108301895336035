import { getSwingDetails } from 'src/Providers/Swing'
import { useQueryWithErrorHandling } from './useQueryWithErrorHandling'

type UseSwingsParams = {
  swingId: string
}

export const useSwingDetails = ({ swingId }: UseSwingsParams) => {
  const query = useQueryWithErrorHandling(
    ['swingDetails', { swingId }],
    () => getSwingDetails(swingId),
    {
      staleTime: 5 * 60 * 1000,
    },
    'switches.swings.details.fetchError'
  )

  return {
    ...query,
    swingData: query.data,
  }
}
