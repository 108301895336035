import { useMemo } from 'react'
import { useLanguage } from 'src/Hooks/useLanguage'
import type { PointMachineHeatSensorData, PointMachineHeatChartAsset } from 'src/Types/PointMachineHeatSensorStatus'
import type { ChartLegend } from 'src/Components/Chart/Legends/ChartLegend'

export const usePointerMachineHeatSensorLegends = (
  sensorData: PointMachineHeatSensorData[] | undefined,
  assets: PointMachineHeatChartAsset[],
  inactiveIndexes: number[]
): ChartLegend[] => {
  const { t } = useLanguage()

  return useMemo(
    () =>
      (sensorData || []).map((data, i) => {
        const isActive = !inactiveIndexes.includes(i)
        const title = isActive
          ? t('switches.pointMachineHeat.chart.hidePointMachine')
          : t('switches.pointMachineHeat.chart.showPointMachine')

        const assetId = data.baneDataId || data.parentBaneDataId
        const sensorName = assets.find(a => a.id === assetId)?.name!

        return {
          id: `${i}`,
          label: sensorName,
          buttonTitle: title,
        }
      }),
    [assets, inactiveIndexes, sensorData, t]
  )
}
