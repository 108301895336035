import type { QueryStatus } from 'react-query'
import { useTranslation } from 'react-i18next'
import type { TFunction } from 'i18next'
import ListItem from '@mui/material/ListItem'
import { NoResult } from 'src/Components/NoResult'
import { FullWidthLoader } from 'src/Components/FullWidthLoader'
import { SidePanelMenu } from 'src/Components/SidePanelMenu/SidePanelMenu'
import { StreamView } from 'src/Features/TrackCircuits/StreamView/StreamView'
import { ViewSelectorButton } from 'src/Components/SidePanelMenu/ViewSelectorButton'
import type { AssetWithAlarms } from 'src/Types/AssetWithAlarms'
import { QueryStringStateKeys, useQueryStringState } from 'src/Store/useQueryStringState'
import { useTrackCircuitsWithAlarms } from 'src/Hooks/NetworkData/useTrackCircuitsWithAlarms'
import { createPageLink } from 'src/Utils/pageLink'
import { useNavigate } from 'react-router-dom'
import { useFeatureOverride } from 'src/Hooks/useFeatureOverride'
import { SessionFeatures } from 'src/Hooks/useSessionFeature'
import { AppPage } from 'src/Types/AppPage'
import { MainContentWithFilterMenu } from 'src/Components/MainContentWithFilterMenu'
import { SystemStatusAlerts } from 'src/Features/SystemStatusAlerts/SystemStatusAlerts'

const getUserFeedback = (t: TFunction, status: QueryStatus, assetsWithAlarms: AssetWithAlarms[]) => {
  if (status === 'loading') {
    return <FullWidthLoader />
  }

  if (status === 'error') {
    return <NoResult>{t('trackCircuits.fetchError')}</NoResult>
  }

  if (!assetsWithAlarms.length) {
    return <NoResult>{t('trackCircuits.noneFound')}</NoResult>
  }

  return null
}

export const TrackCircuitStreamPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const isRewrittenNavigation = useFeatureOverride(SessionFeatures.UseRewrittenNavigation)
  const enableAllAlarmTypes = useFeatureOverride(SessionFeatures.EnableAllAlarmTypes)

  const [selectedStretchLocations] = useQueryStringState(QueryStringStateKeys.stretchLocations)
  const [datePeriod] = useQueryStringState(QueryStringStateKeys.datePeriod)

  const { trackCircuitsWithAlarms = [], status } = useTrackCircuitsWithAlarms({
    idType: 'baneDataLocationIds',
    ids: selectedStretchLocations,
    datePeriod,
    enableAllAlarmTypes,
  })

  const filterMenu = (
    <SidePanelMenu hideClearButton hideSaveButton>
      {!isRewrittenNavigation && (
        <ListItem>
          <ViewSelectorButton isCardView={false} onCardViewUpdated={() => navigate(createPageLink(AppPage.TrackCircuitsCards))} />
        </ListItem>
      )}
    </SidePanelMenu>
  )

  return (
    <MainContentWithFilterMenu filterMenu={filterMenu} contentHeader={<SystemStatusAlerts />}>
      <>
        {getUserFeedback(t, status, trackCircuitsWithAlarms)}
        <StreamView />
      </>
    </MainContentWithFilterMenu>
  )
}
