import { getWorkOrders } from 'src/Providers/WorkOrderProvider'
import { useQueryWithErrorHandling } from 'src/Hooks/NetworkData/useQueryWithErrorHandling'

export const useWorkOrders = (banedataId: string, options: { completedOnly: true } | { nonCompletedOnly: true }) => {
  const query = useQueryWithErrorHandling(
    ['useWorkOrders', { banedataId, ...options }],
    () => {
      if (!banedataId) {
        return undefined
      }

      return getWorkOrders({
        banedataId,
        ...options,
      })
    },
    {
      enabled: !!banedataId,
    },
    'workOrders.fetchError'
  )

  return {
    ...query,
    workOrders: query.data?.workOrders,
  }
}
export const useCompletedWorkOrders = (banedataId: string) => useWorkOrders(banedataId, { completedOnly: true })
export const useNonCompletedWorkOrders = (banedataId: string) => useWorkOrders(banedataId, { nonCompletedOnly: true })
