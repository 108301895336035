import { useExGenToggledOn } from 'src/Hooks/useSwitchHeatVersion'
import type { AssetWithAlarms } from 'src/Types/AssetWithAlarms'
import type { DatePeriod } from 'src/Types/DatePeriod'
// eslint-disable-next-line max-len
import { SwitchHeatCardExpandedContent as SwitchHeatCardExpandedContentV2 } from 'src/Features/SwitchHeat/SwitchHeatCardExpandedContent'
import { SwitchHeatCardExpandedContentV1 } from 'src/Features/SwitchHeat/SwitchHeatCardExpandedContentV1'

type OwnProps = {
  assetWithAlarms: AssetWithAlarms
  datePeriod: DatePeriod
}

export const SwitchHeatCardExpandedContentWrap = ({ assetWithAlarms, datePeriod }: OwnProps) => {
  const isExGen = useExGenToggledOn()

  return isExGen ? (
    <SwitchHeatCardExpandedContentV2 assetWithAlarms={assetWithAlarms} datePeriod={datePeriod} />
  ) : (
    <SwitchHeatCardExpandedContentV1 assetWithAlarms={assetWithAlarms} datePeriod={datePeriod} />
  )
}
