import { styled } from '@mui/material/styles'

import { ignoreStyleProps } from 'src/Utils/style'
import themeColors from 'src/theme'
import Button from '@mui/material/Button'

type LegendButtonProps = { active?: boolean }

export const LegendButton = styled(
  Button,
  ignoreStyleProps('active')
)<LegendButtonProps>(({ active, theme }) => {
  return `
    display: inline-flex;
    align-items: center;
    border: none;
    outline: ${active ? '2px' : '1px'} solid ${active ? themeColors.orange : themeColors.primary};
    border-radius: 25px;
    padding: 3px 10px;
    margin: 2px;
    cursor: pointer;
    font-size: 10px;
    text-align: left;
    color: ${themeColors.primary};
    background-color: #fff;
    line-height: 1.75;
    -webkit-text-stroke-width: ${active ? '.3px' : '0'};
    text-transform: none;
    ${theme.breakpoints.up('md')} {
      font-size: 13px;
    }

    :hover {
      outline: 2px solid ${themeColors.tertiary};
      color: ${themeColors.tertiary};
    }
    
    :disabled {
      outline: 1px solid ${themeColors.disabled};
    }

    > span {
      display: inline-block;
      flex: 1;
      margin-left: 6px;
    }
  `
})
