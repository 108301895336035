import { ApiType } from 'src/Types/ApiType'
import { AssetType } from 'src/Types/AssetTypes'

import type { DateRange } from 'src/Types/DatePeriod'
import type { AssetWithAlarms } from 'src/Types/AssetWithAlarms'
import { InternalAlarmSystem } from 'src/Types/AlarmSystem'
import type { GetOrchestratedAssetsParams } from 'src/Types/GetOrchestratedAssetsParams'
import type { OrchestratedSwitchHeatCabinet, SwitchHeatCabinet } from 'src/Types/SwitchHeatCabinet'

import { callApiGet } from 'src/msGraphClient'
import { getCriticalityValue } from 'src/Utils/criticality'
import { getOrchestratedAssetIdParams } from 'src/Utils/asset'
import config from 'src/Config/Config'

const { SWITCH_HEAT_CABINETS } = config.API_FRAGMENTS.apim

export const getOrchestratedSwitchHeatCabinets = (params: GetOrchestratedAssetsParams): Promise<AssetWithAlarms[]> => {
  const urlParams = new URLSearchParams({
    fromDate: new Date(params.dateRange.fromDate).toJSON(),
    toDate: new Date(params.dateRange.toDate).toJSON(),
    onlyWithAlarms: params.onlyWithAlarms.toString(),
    feFilterEnabled: (!params.enableAllAlarmTypes).toString(),
  })

  const { idKey, value } = getOrchestratedAssetIdParams(params)
  urlParams.set(idKey, value)

  return callApiGet<OrchestratedSwitchHeatCabinet[]>(`${SWITCH_HEAT_CABINETS}?${urlParams.toString()}`, ApiType.APIM, {
    parseDates: true,
  })
    .then(res =>
      res.map<AssetWithAlarms>(entry => {
        const asset: SwitchHeatCabinet = {
          assetType: AssetType.SwitchHeat,
          baneDataId: entry.assetData.baneDataId,
          name: entry.assetData.name,
          baneDataLocationName: entry.assetData.locationName,
          baneDataLocationId: entry.assetData.locationId,
          assetStatusWindows: entry.status.assetStatusWindows,
          isMonitored: entry.status.isMonitored,
          componentCriticalityLocal: getCriticalityValue(entry.assetData, 'ComponentCriticalityLocal'),
          alarmCriticalityAbsolute: getCriticalityValue(entry.assetData, 'AlarmCriticalityAbsolute'),
          totalCriticalityAbsolute: getCriticalityValue(entry.assetData, 'TotalCriticalityAbsolute'),
          installDate: entry.cabinetAssetData.installDate,
          cabinetClassName: entry.cabinetAssetData.cabinetClassName,
          switchHeatElements: entry.cabinetAssetData.switchHeatElements,
          statusWithoutAssetStatusWindows: entry.status.statusWithoutAssetStatusWindows,
          statusWithAssetStatusWindows: entry.status.statusWithAssetStatusWindows,
          showCriticality: entry.status.showCriticality,
          dailyStatus: entry.status.dailyStatus,
          fromKm: entry.assetData.fromKm,
          toKm: entry.assetData.toKm,
        }

        return {
          asset,
          alarms: entry.aggregateAlarms.map(alarm => {
            let switchHeatElement = entry.cabinetAssetData.switchHeatElements?.find(
              switchHeatElement => switchHeatElement.baneDataId === alarm.baneDataId
            )

            if (!switchHeatElement) {
              switchHeatElement = entry.cabinetAssetData.switchHeatElements?.find(switchHeatElement =>
                switchHeatElement.heatElements?.find(he => he.baneDataId === alarm.baneDataId)
              )
            }

            return {
              ...alarm,
              internalAlarmSystem: InternalAlarmSystem.AggregatedSwitchHeatAlarm,
              switchHeatElementName: switchHeatElement?.name,
              switchName: switchHeatElement?.switchName,
            }
          }),
        }
      })
    )
    .then(result => result.sort((a, b) => a.asset.name.localeCompare(b.asset.name, config.STRING_LOCALE)))
}

export const getOrchestratedSwitchHeatCabinetsAtLocations = (
  dateRange: DateRange,
  locationIds: string[],
  onlyWithAlarms: boolean,
  enableAllAlarmTypes: boolean
) => getOrchestratedSwitchHeatCabinets({ dateRange, locationIds, onlyWithAlarms, enableAllAlarmTypes })

export const getOrchestratedSwitchHeatCabinetsWithIds = (
  dateRange: DateRange,
  baneDataIds: string[],
  onlyWithAlarms: boolean,
  enableAllAlarmTypes: boolean
) => getOrchestratedSwitchHeatCabinets({ dateRange, baneDataIds, onlyWithAlarms, enableAllAlarmTypes })
