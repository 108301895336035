import type { ReactElement } from 'react'
import { styled } from '@mui/material/styles/'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import type { SelectChangeEvent } from '@mui/material/Select'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'

import { Checkbox } from 'src/Components/Checkbox'
import { useLogReactParams } from 'src/Hooks/useLogReactParams'

export type FilterDropdownProps = {
  id: string
  label: string
  selectedValues: string[]
  options: string[]
  optionLabels: string[] | ReactElement[]
  onUpdate: (values: string[]) => void
  onRenderValue?: (selected: unknown) => string
  disabled?: boolean
}

const StyledFormControl = styled(FormControl)(
  props => `
  margin: ${props.theme.spacing(1)};
  width: calc(100% - 8px);
`
)

export const FilterDropdown = ({
  id,
  label,
  selectedValues,
  options,
  optionLabels,
  onUpdate,
  onRenderValue,
  disabled = false,
}: FilterDropdownProps) => {
  useLogReactParams('FilterDropdown', { id, label, selectedValues, options, optionLabels, onUpdate, onRenderValue, disabled })

  const valuesUpdated = (event: SelectChangeEvent<string[]>) => onUpdate(event.target.value as string[])

  const defaultValueRenderer = (selected: unknown) =>
    (selected as string[])
      .filter(v => {
        if (options.indexOf(v) >= 0) {
          return true
        }
        console.debug(
          `${label} option ${v} not among available options ${options} (unit test in GenericCardListPage.test.ts should fail)`
        )
        return false
      })
      .map(v => optionLabels[options.indexOf(v)])
      .join(', ')

  return (
    <StyledFormControl>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        labelId={`${id}-label`}
        id={`${id}-select`}
        multiple
        value={selectedValues}
        onChange={valuesUpdated}
        renderValue={onRenderValue || defaultValueRenderer}
        disabled={disabled}
      >
        {options.map((option, i) => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={selectedValues.includes(option)} />
            {typeof optionLabels[i] === 'string' ? <ListItemText primary={optionLabels[i]} /> : optionLabels[i]}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  )
}
