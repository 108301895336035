import type { ChangeEvent, FocusEvent } from 'react'
import { useState } from 'react'
import { styled } from '@mui/material/styles'
import { TextField, Popper, Paper } from '@mui/material'
import type { InputProps as StandardInputProps } from '@mui/material/Input/Input'

import { useLanguage } from 'src/Hooks/useLanguage'
import { ignoreStyleProps } from 'src/Utils/style'
import themeColors from 'src/theme'

const StyledTextField = styled(
  TextField,
  ignoreStyleProps('valid')
)<{ valid: boolean }>(
  ({ valid }) => `
  .MuiInputBase-root {
    padding: 0;
    margin: 0;
    border-radius: 0;

    .MuiInputBase-input {
      padding: 4px 4px;
      margin: 0;
      font-size: 14px;
      -moz-appearance: textfield;
      border-width: 1px;
      border-style: solid;
      border-color: ${!valid ? themeColors.errorText : 'transparent'};
      text-align: right;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
`
)

const StyledPopperContent = styled(Paper)`
  padding: 0.1rem 0.2rem;
  background-color: #f8f8f8;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.5);
  outline: solid 1px rgba(0, 0, 0, 0.3);
  font-size: ${themeColors.fontSize};
  pointer-events: none;
`

const DATA_ID_ATTRIBUTE_NAME = 'data-id'

type OwnProps = {
  sensorId: string
  value: number | undefined
  isValid: boolean
  onChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, sensorId: string, value: number | undefined) => void
  disabled?: boolean
  minValue?: number
  maxValue?: number
}

export const TemperatureInput = ({ sensorId, value, isValid, onChange, disabled = false, minValue, maxValue }: OwnProps) => {
  const { t } = useLanguage()

  const [inputEl, setInputEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: FocusEvent<HTMLElement>) => {
    setInputEl(event.currentTarget)
  }

  const handleBlur = () => {
    setInputEl(null)
  }

  const handleValueChange: StandardInputProps['onChange'] = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const target = e.target
    const parsedValue = parseInt(target.value, 10)
    const sensorId = target.getAttribute(DATA_ID_ATTRIBUTE_NAME)!
    const value = !Number.isNaN(parsedValue) ? parsedValue : undefined
    onChange(e, sensorId, value)
  }

  const hasMinMaxValues = typeof minValue === 'number' && typeof maxValue === 'number'

  return (
    <>
      <StyledTextField
        label=""
        variant="outlined"
        size="small"
        type="number"
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', [DATA_ID_ATTRIBUTE_NAME]: sensorId }}
        onChange={handleValueChange}
        onFocus={handleClick}
        onBlur={handleBlur}
        value={typeof value === 'number' ? `${value}` : ''}
        valid={isValid}
        disabled={disabled}
      />
      {hasMinMaxValues && (
        <Popper
          open={!!inputEl}
          anchorEl={inputEl}
          placement="bottom-end"
          modifiers={[
            {
              name: 'flip',
              enabled: true,
              options: {
                altBoundary: true,
                rootBoundary: 'document',
                padding: 8,
              },
            },
            {
              name: 'preventOverflow',
              enabled: true,
              options: {
                altAxis: true,
                altBoundary: true,
                tether: true,
                rootBoundary: 'document',
                padding: 8,
              },
            },
          ]}
        >
          <StyledPopperContent>{t('switchHeat.temperatureForm.minMaxMessage', { minValue, maxValue })}</StyledPopperContent>
        </Popper>
      )}
    </>
  )
}
