import type { ReactNode } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import { SubHeaderKey } from 'src/Providers/Settings/SubHeader'
import { SubHeaderWithExpandableInfo } from 'src/Components/SubHeaderWithExpandableInfo'
import { useLanguage } from 'src/Hooks/useLanguage'

type OwnProps = {
  modusComponent?: ReactNode
  formComponent: ReactNode
}

export const TemperatureFormExpandable = ({ modusComponent, formComponent }: OwnProps) => {
  const { t } = useLanguage()

  return (
    <SubHeaderWithExpandableInfo
      title={t('switchHeat.temperatureForm.header')}
      isDefaultExpanded
      subHeaderKey={SubHeaderKey.SwitchHeatTemperatureForm}
    >
      <Box m={2}>
        <Grid container direction="column" gap={2}>
          {modusComponent && <Grid item>{modusComponent}</Grid>}
          {formComponent && <Grid item>{formComponent}</Grid>}
          {!modusComponent && !formComponent && <Grid item>{t('switchHeat.temperatureForm.noTemperatureData')}</Grid>}
        </Grid>
      </Box>
    </SubHeaderWithExpandableInfo>
  )
}
