import { makeStyles } from 'tss-react/mui'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Collapse from '@mui/material/Collapse'
import Skeleton from '@mui/material/Skeleton'

import { CustomToolbar } from 'src/Components/CustomToolbar'

import themeColors from 'src/theme'

const useStyles = makeStyles()({
  card: {
    padding: 5,
    borderRadius: 0,
    height: 141,
    width: 120,
    margin: 5,
  },
  cardContent: {
    padding: 5,
  },
  iconSkeleton: {
    marginLeft: 30,
  },
  weatherTimeline: {
    backgroundColor: themeColors.body,
    padding: 0,
    minHeight: 35,
  },
  collapse: {
    overflow: 'unset',
    width: '100%',
    cursor: 'pointer',
    userSelect: 'none',
  },
  horizontalScroll: {
    display: 'inline-flex',
    float: 'left',
    width: 'auto',
  },
})

type OwnProps = {
  expanded: boolean
}

const textSkeleton = <Skeleton variant="text" animation="wave" height={34} width="100%" />

const NUM_SKELETON_DAYS = 7

export const WeatherTimelineSkeleton = ({ expanded }: OwnProps) => {
  const { classes } = useStyles()

  return (
    <CustomToolbar className={classes.weatherTimeline}>
      {!expanded ? (
        textSkeleton
      ) : (
        <Collapse in timeout="auto" className={classes.collapse} unmountOnExit>
          <div className={classes.horizontalScroll}>
            {[...Array(NUM_SKELETON_DAYS).keys()].map(i => (
              // eslint-disable-next-line react/no-array-index-key
              <Card className={classes.card} key={i}>
                <CardContent className={classes.cardContent}>
                  {textSkeleton}
                  <Skeleton variant="circular" animation="wave" height={35} width={35} className={classes.iconSkeleton} />
                  {textSkeleton}
                  {textSkeleton}
                </CardContent>
              </Card>
            ))}
          </div>
          <div>{textSkeleton}</div>
        </Collapse>
      )}
    </CustomToolbar>
  )
}
