import { useMemo } from 'react'

import { SubHeaderWithExpandableInfo } from 'src/Components/SubHeaderWithExpandableInfo'
import { RackEarthCurrentChart } from 'src/Features/SignalEarthFaults/RackEarthCurrentChart/RackEarthCurrentChart'
import { NoResult } from 'src/Components/NoResult'
import { Box } from '@mui/material'

import type { AssetWithAlarms } from 'src/Types/AssetWithAlarms'
import type { DatePeriod } from 'src/Types/DatePeriod'
import type { Rack } from 'src/Types/Rack'

import { useLanguage } from 'src/Hooks/useLanguage'
import { Definition } from 'src/Components/Definition'
import { WeatherChart } from 'src/Features/Weather/WeatherChart/WeatherChart'
import { getDateRange } from 'src/Features/SelectedDatePeriod/predefinedDatePeriods'
import { useChartFocus } from 'src/Components/Chart/useChartFocus'
import { SubHeaderKey } from 'src/Providers/Settings/SubHeader'

type OwnProps = {
  assetWithAlarms: AssetWithAlarms
  datePeriod: DatePeriod
}

export const RackCardExpandedContent = ({ assetWithAlarms, datePeriod }: OwnProps) => {
  const { t } = useLanguage()

  const asset = assetWithAlarms.asset as Rack
  const sectionSensors = asset.sectionSensors

  const { fromDate, toDate } = useMemo(() => getDateRange(datePeriod), [datePeriod])
  const { chartFocus, zoomUpdated } = useChartFocus({ fromDate, toDate })

  if (!sectionSensors.length) {
    return <NoResult>{t('racks.noRelatedSensor')}</NoResult>
  }

  return (
    <>
      <Definition term={t('general.name')} description={asset.name} />
      <Box mt={2} mb={2}>
        <Definition term={t('racks.relatedSectionSensors.title')} description={sectionSensors.map(s => s.name).join(', ')} />
      </Box>
      <SubHeaderWithExpandableInfo title={t('racks.chart.rack')} isDefaultExpanded subHeaderKey={SubHeaderKey.RackChart}>
        <RackEarthCurrentChart
          selectedRackSections={sectionSensors}
          datePeriod={datePeriod}
          onZoomUpdated={zoomUpdated}
          view="card"
        />
      </SubHeaderWithExpandableInfo>
      <SubHeaderWithExpandableInfo
        title={t('weatherChart.title', { stationName: asset.baneDataLocationName })}
        hideText={t('weatherChart.hideWeather')}
        isDefaultExpanded
        subHeaderKey={SubHeaderKey.WeatherChart}
      >
        <WeatherChart baneDataLocationId={asset.baneDataLocationId} fromDate={fromDate} toDate={toDate} chartFocus={chartFocus} />
      </SubHeaderWithExpandableInfo>
    </>
  )
}
