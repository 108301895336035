import type { ReactNode, ErrorInfo } from 'react'
import React from 'react'
import { trackException, getErrorWithRealStack } from 'src/Tracking/trackException'
import { SeverityLevel } from '@microsoft/applicationinsights-web'
import { ErrorPanel } from 'src/Components/ErrorPanel'

type OwnProps = {
  children?: ReactNode
}

type OwnState = {
  hasError: boolean
  error?: Error
  errorInfo?: ErrorInfo
}

export class ErrorBoundary extends React.Component<OwnProps, OwnState> {
  constructor(props: OwnProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      error,
    }
  }

  async componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { componentStack } = errorInfo
    let componentStackText = componentStack
    try {
      const stackError = new Error()
      stackError.stack = componentStack!
      const finalStackError = await getErrorWithRealStack(stackError)
      if (finalStackError.stack) {
        componentStackText = finalStackError.stack
      }
    } catch (e) {
      console.error(e)
    }
    await trackException(error, SeverityLevel.Error, {
      componentStack: componentStackText,
      renderError: true,
    })
    this.setState({ hasError: true, error, errorInfo })
  }

  render() {
    const { hasError, error, errorInfo } = this.state
    if (hasError) {
      return <ErrorPanel error={error} errorInfo={errorInfo} />
    }

    return this.props.children
  }
}
