import { TableCell, TableHead as MuiTableHead, TableRow, TableSortLabel, styled } from '@mui/material'

import type { Order, TableHead as TableHeadType } from 'src/Types/TableTypes'

import themeColors from 'src/theme'

const StyledTableColumnHeader = styled(TableCell)`
  font-weight: bold;
`

const StyledTableSortLabel = styled(TableSortLabel)`
  &.Mui-active {
    color: ${themeColors.primary};
  }

  &:hover {
    color: ${themeColors.primary};
    opacity: 1;
    text-decoration: underline;
    text-underline-offset: 4px;
  }
`

type TableHeadProps = {
  canSortColumns: boolean
  expandable: boolean
  order?: Order
  orderBy?: string
  tableHeads: TableHeadType[]
  onSort: (id: string) => void
}

export const TableHead = ({ canSortColumns, expandable, order, orderBy, tableHeads, onSort }: TableHeadProps) => {
  return (
    <MuiTableHead>
      <TableRow>
        {expandable && <TableCell />}
        {tableHeads.map(head => (
          <StyledTableColumnHeader key={head.id}>
            {canSortColumns ? (
              <StyledTableSortLabel
                active={orderBy === head.id}
                direction={orderBy === head.id ? order : 'asc'}
                onClick={() => onSort(head.id)}
              >
                {head.value}
              </StyledTableSortLabel>
            ) : (
              head.value
            )}
          </StyledTableColumnHeader>
        ))}
      </TableRow>
    </MuiTableHead>
  )
}
