import type { ReactNode } from 'react'
import { styled } from '@mui/material/styles'

import { ignoreStyleProps } from 'src/Utils/style'

export type CustomIconProps = {
  title: string
  color?: string
  className?: string
  width?: number
  height?: number
  flipX?: boolean
  onClick?: () => void
  children?: ReactNode
}

const StyledIcon = styled('svg', ignoreStyleProps('flipX'))<{ flipX: boolean }>`
  width: 1em;
  height: 1em;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  ${props => props.flipX && 'transform: scaleX(-1);'}
`

export const CustomIcon = ({
  title,
  color = 'currentColor',
  className,
  width = 24,
  height = 24,
  flipX = false,
  onClick,
  children,
}: CustomIconProps) => (
  <StyledIcon
    flipX={flipX}
    className={className}
    focusable="false"
    viewBox={`0 0 ${width} ${height}`}
    role="img"
    fill={color}
    onClick={onClick}
  >
    {children}
    <title>{title}</title>
  </StyledIcon>
)
