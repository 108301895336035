import type { QueryStatus } from 'react-query'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import HelpIcon from '@mui/icons-material/HelpOutline'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'

import { MobileSideMenuButton } from './MobileSideMenuButton'
import { LanguageSelector } from './LanguageSelector'
import { TeamsLogo } from './TeamsLogo'
import { UserProfile } from 'src/Components/UserProfile'
import { TodaysWeather } from 'src/Features/Weather/TodaysWeather'
import { CustomToolbar } from 'src/Components/CustomToolbar'

import type { Weather } from 'src/Types/WeatherTypes'

import { useBreakpointDown, useBreakpointUp } from 'src/Hooks/useBreakpoint'
import config from 'src/Config/Config'
import Logo from 'src/Assets/banenor-logo.svg'
import SantaHat from 'src/Assets/santa_hat_icon.svg'
import { useStartPage } from 'src/Hooks/useStartPage'

const useStyles = makeStyles()(theme => ({
  headerLogo: {
    padding: '8px 16px 0 5px',
    '& img': {
      width: 145,
    },
  },
  santaHat: {
    position: 'absolute',
    marginLeft: -16,
    marginTop: -8,
    [theme.breakpoints.down('lg')]: {
      marginLeft: -12,
      marginTop: -10,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: -8,
    },
    '& img': {
      width: 30,
      transform: 'rotate(30deg)',
      [theme.breakpoints.down('lg')]: {
        width: 25,
      },
      [theme.breakpoints.down('sm')]: {
        width: 22,
      },
    },
  },
  headerText: {
    flex: 1,
    [theme.breakpoints.down('lg')]: {
      fontSize: 16,
      marginLeft: -5,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      marginLeft: -15,
    },
  },
  languageButton: {
    paddingLeft: 24,
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  externalLink: {
    marginRight: 10,

    '& > a': {
      display: 'flex',
      alignItems: 'center',
      color: '#fff',
      textDecoration: 'none',

      '& > span': {
        [theme.breakpoints.down(1305)]: {
          display: 'none',
        },
      },

      '& > *:last-child': {
        width: 32,
        marginLeft: 4,
      },
    },
  },
  mainHeader: {
    minHeight: 46,
    height: 46,
    paddingLeft: 24,
    paddingRight: 24,
  },
}))

const StyledLink = styled(Link)`
  color: #fff;
  text-decoration: none;
`

type OwnProps = {
  weather?: Weather[]
  queryStatus: QueryStatus
}

export const MainHeader = ({ weather, queryStatus }: OwnProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const isSmall = useBreakpointDown('sm')
  const isMedium = useBreakpointDown('md')
  const isLarge = useBreakpointUp('lg')
  const isChristmasEdition = new Date().getMonth() === 11
  const { startPageLink } = useStartPage()
  const startPage = startPageLink ?? '/'

  return (
    <CustomToolbar className={classes.mainHeader}>
      {isMedium && <MobileSideMenuButton />}
      {!isSmall && (
        <StyledLink to={startPage} style={{ marginTop: isLarge ? '10px' : '5px' }}>
          <span className={classes.headerLogo}>
            <img src={Logo} alt="Bane NOR logo" />
          </span>
        </StyledLink>
      )}
      <Typography variant="h5" component="h1" className={classes.headerText}>
        <StyledLink to={startPage}>{t('appHeader')}</StyledLink>
        {isChristmasEdition && (
          <span className={classes.santaHat}>
            <img src={SantaHat} alt="Nisselue" />
          </span>
        )}
      </Typography>
      <Tooltip title={t('general.contactUsTooltip')} placement="bottom" arrow>
        <span className={classes.externalLink}>
          <a href={config.CONTACT_EMAIL} target="_blank" rel="noopener noreferrer">
            <span>{t('general.contactUs')}</span>
            <EmailOutlinedIcon />
          </a>
        </span>
      </Tooltip>
      <Tooltip title={t('general.faqTooltip')} placement="bottom" arrow>
        <span className={classes.externalLink}>
          <a href={config.FAQ_URL} target="_blank" rel="noopener noreferrer">
            <span>{t('general.faqLink')}</span>
            <HelpIcon />
          </a>
        </span>
      </Tooltip>
      <span className={classes.externalLink}>
        <a href={config.TEAMS_URL} target="_blank" rel="noopener noreferrer">
          <span>{t('general.communityLink')}</span>
          <TeamsLogo />
        </a>
      </span>
      <TodaysWeather weather={weather} queryStatus={queryStatus} />
      <span className={classes.languageButton}>
        <UserProfile />
        <LanguageSelector />
      </span>
    </CustomToolbar>
  )
}
