import type { ReactNode } from 'react'
import { createContext } from 'react'

const soundCache: { [key: string]: HTMLAudioElement } = {}

const playSound = (soundName: string) => {
  let sound = soundCache[soundName]
  if (!sound) {
    sound = new Audio(soundName)
    soundCache[soundName] = sound
  }
  sound.play()
}

const contextValue = { playSound }

export const SoundManagerContext = createContext({ playSound })

export const SoundManager = ({ children }: { children: ReactNode }) => (
  <SoundManagerContext.Provider value={contextValue}>{children}</SoundManagerContext.Provider>
)
