import type { ComponentProps } from 'react'
import { styled } from '@mui/material/styles'
import { Tabs } from '@mui/material'
import themeColors from 'src/theme'

export const StyledCardTabs = styled(Tabs)`
  min-height: auto;
  border-bottom: solid 1px ${themeColors.secondary};

  .MuiTabs-indicator {
    height: 4px;
  }
`

export const CardTabs = (props: ComponentProps<typeof StyledCardTabs>) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <StyledCardTabs variant="scrollable" allowScrollButtonsMobile {...props} />
}
