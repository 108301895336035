import { useLocation } from 'react-router-dom'

/**
 * Returns the card id from the url if it exists.
 */
export const useCardIdFromUrl = () => {
  const { hash } = useLocation()

  const parts = hash.split('#')
  if (parts.length !== 2) {
    return undefined
  }

  const cardId = parts[1]
  return cardId.startsWith('card_') ? cardId : undefined
}
