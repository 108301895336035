import type { AssetStatusEntryDomain, AssetStatusEntityStatus } from 'src/Types/AssetStatusEntry'

export type ManualAssetStatusModel = {
  baneDataId: string
  status: AssetStatusEntityStatus
  startTime: number
  endTime: number | undefined
  domain: AssetStatusEntryDomain
  causeId: number
  enterAssetStatusComment: string | undefined
  exitAssetStatusComment: string | undefined
  isManual: boolean
}

export type ManualAssetStatusCause = {
  status?: AssetStatusEntityStatus
  id: number
  name: string
  domain?: AssetStatusEntryDomain
}

export enum ChangeManualAssetStatusEventType {
  Enter,
  Exit,
}

export type EnterAssetStatusEvent = {
  type: ChangeManualAssetStatusEventType.Enter
  cause: number
  endTime: number | undefined
  comment: string
}

export type ExitAssetStatusEvent = {
  type: ChangeManualAssetStatusEventType.Exit
  comment: string
}

export type ChangeManualAssetStatusEvent = EnterAssetStatusEvent | ExitAssetStatusEvent
