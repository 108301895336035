/* eslint global-require:0 */
import type { Configuration, SilentRequest } from '@azure/msal-browser'

import { ApiType } from 'src/Types/ApiType'

import { isLocal, version, isProd, isTestEnv } from 'src/reactAppEnv'
import { isBranchBuild } from 'src/Utils/basePath'

import localDevConfig from './config.local-dev'
import prodConfig from './config.production'
import testConfig from './config.testconfig'
import devConfig from './config.development'

export type APIConfig = {
  tokenRequest: Omit<SilentRequest, 'account'>
  endpoint: string
}

const endpoints = {
  ACCESS_GROUPS: 'token/groups',
  POINT_MACHINE_ZOOM_LEVELS: 'codesystems/machinezoom',
  // heat cabinets
  ADJUST_HEAT: 'switchheat/values/requested',
  ADJUST_HEAT_V2: 'switchheatv2/values/requested',
  CURRENT_CABINET_VALUES: 'switchheat/values/current',
  CURRENT_CABINET_VALUES_V2: 'switchheatv2/values/current',
  HISTORY: 'switchheat/values/history/asset/codesystems',
  HISTORY_V2: 'switchheatv2/values/history/asset/codesystems',
  HISTORIC_VALUES: 'switchheat/values/history/current/asset/codesystems',
  HISTORIC_VALUES_V2: 'switchheatv2/values/history/current/asset/codesystems',
  MODUS: 'switchheatv2/modus',
  MODUS_UPDATE: 'switchheatv2/modus/requested',
  // assets
  RAILWAYS_DEEP: 'assets/railways/withSectionsAndLocations',
  RAILWAYS: 'assets/railways',
  LOCATIONS: 'assets/locations',
  SENSOR_CODES: 'codesystems/all',
  SENSOR_TABS: 'codesystems/tabs',
  TRACK_CIRCUITS: 'assets/TrackCircuits',
  SWITCH_HEAT_CONNECTED_SWITCHES: 'assets/SwitchHeatCabinets/ConnectedSwitches',
  SWITCH_HEAT_CABINETS_BY_SWITCH: 'assets/SwitchHeatCabinets/BySwitch',
  // alarms
  ALARM_TYPES: 'alarms/AlarmTypes',
  TRACK_CIRCUIT_ALARMS: 'alarms/TrackCircuitAlarms',
  LAST_ALARMS: 'alarms/Alarms/last',
  ALARMS_BY_EVENT_ID: 'alarms/Alarms/eventIds',
  ALARMS: 'alarms/Alarms',
  // weather
  FORECASTS: 'Weather/Forecasts',
  // system status
  ALERTS: 'systemstatus/Alerts',
  // TC sensor data
  CURRENTS: 'TrackCircuit/Currents',
  CURRENTS_COMPRESSED: 'TrackCircuit/Currents/Compressed',
  // statistics
  OCCUPIED_TRACK_CIRCUITS: 'StatisticsRead/GetOccupiedTrackCircuits',
  CRITICALITY_RESET: 'StatisticsRead/CriticalityReset',
  // orchestrated track circuits
  ORCHESTRATED_TRACK_CIRCUITS: 'Orchestrate/trackcircuits',
  // switches
  POINT_MACHINE_SWITCHES: 'Orchestrate/switches',
  SWINGS: 'Orchestrate/swings',
  SWINGS_DETAILS: 'Orchestrate/swing',
  PM_HEAT_SWITCHES: 'Orchestrate/switches_pm_heat',
  SWITCH_HEAT_CABINETS: 'Orchestrate/cabinets',
  DERAILERS: 'Orchestrate/derailers',
  // benders
  BENDERS: 'Orchestrate/benders',
  BENDER_DETAILS: 'Orchestrate/benders/bender',
  CURRENT_BENDER_VALUES: 'SignalEarthLeakage/values/current',
  BENDER_HISTORY: 'SignalEarthLeakage/bender/values/history',
  BENDER_HISTORIC_VALUES: 'SignalEarthLeakage/values/history/current/asset/codesystems',
  // racks
  RACKS: 'Orchestrate/racks',
  RACK_SECTIONS_HISTORY: 'SignalEarthLeakage/rack/values/history',
  // point machine heat
  POINT_MACHINE_HEAT_HISTORY: 'pmheat/values/history/assets/codesystems',
  POINT_MACHINE_HEAT_SENSORS: 'pmheat/twins/banedata',
  // frontend settings
  FRONTEND_SETTINGS: 'user/frontend',
  // reference curve
  REFERENCE_CURVE: 'Orchestrate/referenceCurve',
  // Workorders
  WORK_ORDERS: 'workorders/WorkOrders',

  // Asset Status Causes
  ASSET_STATUS_CAUSES: 'AssetStatus-IrregularData/Cause' /* Also 'AssetStatus-OutOfOrder/Cause' is supported */,

  // Asset Status: No contact
  ASSET_STATUS_NO_CONTACT: 'AssetStatus-NoContact/NoContact',
  ASSET_STATUS_ENTER_NO_CONTACT: 'AssetStatus-NoContact/NoContactStatus/Enter',
  ASSET_STATUS_EXIT_NO_CONTACT: 'AssetStatus-NoContact/NoContactStatus/ExitAll',

  // Asset Status: Irregular Data
  ASSET_STATUS_IRREGULAR_DATA: 'AssetStatus-IrregularData/IrregularData',
  ASSET_STATUS_ENTER_IRREGULAR_DATA: 'AssetStatus-IrregularData/IrregularDataStatus/Enter',
  ASSET_STATUS_EXIT_IRREGULAR_DATA: 'AssetStatus-IrregularData/IrregularDataStatus/ExitAll',
}

export type Endpoints = typeof endpoints

export type AppFeatures = {
  MANUAL_ASSET_STATUS?: boolean
  EXGEN_UPDATE?: boolean
}

export type Config = {
  MSAL_CONFIG: Configuration
  API: Record<ApiType, APIConfig>
  API_FRAGMENTS: Record<ApiType, Endpoints>
  TELEMETRY_KEY: string
  STRING_LOCALE: string
  isLocal: boolean
  version: string
  TEAMS_URL: string
  FAQ_URL: string
  CONTACT_EMAIL: string
  PRIVACY_INFO_URL: string
  FEATURES?: AppFeatures
}

const common: Partial<Config> = {
  API_FRAGMENTS: {
    [ApiType.APIM]: endpoints,
  },
  TELEMETRY_KEY: '',
  STRING_LOCALE: 'nb-NO',
  TEAMS_URL:
    'https://teams.microsoft.com/l/team/19%3a200e644bd8324387afd9dd80f18f283d%40thread.skype/conversations?groupId=2b730def-df3b-4d67-ae7f-5d1cbacdabb1&tenantId=6ee535f2-3064-4ac9-81d8-4ceb2ff790c6', // eslint-disable-line max-len
  FAQ_URL: 'https://banenor.sharepoint.com/sites/DT_Sviktmodebibliotek ',
  CONTACT_EMAIL: 'mailto:smartvedlikehold@banenor.no',
  PRIVACY_INFO_URL:
    'https://banenor.sharepoint.com/sites/DT_Sviktmodebibliotek/SitePages/Personvernerkl%C3%A6ring-Sporoverv%C3%A5kingsappen.aspx', // eslint-disable-line max-len
}

let envConfig: Config
if (isLocal) {
  envConfig = localDevConfig as Config
} else if (isProd) {
  envConfig = prodConfig as Config
} else if (isTestEnv) {
  envConfig = testConfig as Config
} else {
  envConfig = devConfig as Config
  if (isBranchBuild) {
    envConfig.MSAL_CONFIG.auth.redirectUri += '/aad-callback/'
  }
}

const config: Config = {
  ...common,
  ...envConfig,
  isLocal,
  version,
}

export default config
