import type { MutationFunction, UseMutationOptions } from 'react-query'
import { useMutation } from 'react-query'
import type { QueryError } from 'src/Types/QueryError'
import { getCustomErrorMessage } from 'src/Hooks/UseAppErrorMessage'
import { useAppStateStore } from 'src/Store/appState'
import type { AppMessage } from 'src/Types/AppMessage'
import { useLanguage } from 'src/Hooks/useLanguage'

export const useMutationWithErrorHandling = <TResult = unknown, TVariables = unknown>(
  mutationFn: MutationFunction<TResult, TVariables>,
  mutationConfig: UseMutationOptions<TResult, QueryError, TVariables>,
  genericErrorKey: string,
  genericSuccessKey?: string
) => {
  const queueMessage = useAppStateStore(state => state.appMessages.queueMessage)
  const { currentLanguage } = useLanguage()

  const { onError, onSuccess } = mutationConfig

  const mutation = useMutation<TResult, QueryError, TVariables>(mutationFn, {
    ...mutationConfig,
    onError(...args) {
      onError?.(...args)
      let message: AppMessage = {
        type: 'error',
        message: genericErrorKey,
      }

      const customMessage = mutation.error ? getCustomErrorMessage(mutation.error) : undefined

      if (customMessage) {
        message = {
          type: 'error',
          message: customMessage[currentLanguage],
          raw: true,
        }
      }

      queueMessage(message)
    },
    onSuccess(...args) {
      onSuccess?.(...args)
      if (!genericSuccessKey) {
        return
      }
      const message: AppMessage = {
        type: 'success',
        message: genericSuccessKey,
      }
      queueMessage(message)
    },
  })
  return mutation
}
