import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InfoIcon from '@mui/icons-material/InfoOutlined'

import { SortOrder } from 'src/Types/SortOrder'
import themeColors from 'src/theme'
import { useLogReactParams } from 'src/Hooks/useLogReactParams'

export type SortDropdownProps = {
  sortOrderOptions: SortOrder[]
  sortOrder: SortOrder
  disableCriticalitySort?: boolean
  onSortOrderUpdated: (sortOrder: SortOrder) => void
}

const StyledFormControl = styled(FormControl)(
  props => `
  margin: ${props.theme.spacing(1)};
  width: 100%;
`
)

const StyledDisabledDiv = styled('div')`
  color: ${themeColors.disabledText};
  vertical-align: middle;
  display: flex;
  align-items: center;
  padding: 6px 16px;
  svg {
    padding-left: 5px;
  }
`

export const SortDropdown = ({ sortOrderOptions, sortOrder, disableCriticalitySort, onSortOrderUpdated }: SortDropdownProps) => {
  useLogReactParams('SortDropdown', { sortOrderOptions, sortOrder, disableCriticalitySort, onSortOrderUpdated })

  const { t } = useTranslation()

  return (
    <StyledFormControl>
      <InputLabel id="sort-label">{t('sideMenu.sortOrder.label')}</InputLabel>
      <Select
        labelId="sort-label"
        id="sort-dropdown"
        value={sortOrder}
        onChange={event => onSortOrderUpdated(event.target.value as SortOrder)}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        {sortOrderOptions.map(sortOrderOption => {
          return disableCriticalitySort && sortOrderOption === SortOrder.Criticality ? (
            <StyledDisabledDiv key={sortOrderOption} title={t('sideMenu.sortOrder.criticalityInfo')}>
              {t(`sideMenu.sortOrder.${sortOrderOption.toLowerCase()}`)}
              <InfoIcon color="primary" />
            </StyledDisabledDiv>
          ) : (
            <MenuItem key={sortOrderOption} value={sortOrderOption}>
              {t(`sideMenu.sortOrder.${sortOrderOption.toLowerCase()}`)}
            </MenuItem>
          )
        })}
      </Select>
    </StyledFormControl>
  )
}
