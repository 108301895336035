import { styled } from '@mui/material/styles'
import { AlarmIndicator } from 'src/Components/AlarmIndicator'
import type { SystemStatus } from 'src/Types/SystemStatus'

const StyledValueContainer = styled('span')`
  vertical-align: super;
  margin-right: ${props => props.theme.spacing(1)};
`

type OwnProps = {
  alarmIndicator: SystemStatus | 'OkToday'
  value: string | undefined
}

export const AlarmIndicatorWithLabel = ({ alarmIndicator, value }: OwnProps) => {
  return (
    <>
      <StyledValueContainer>{value}</StyledValueContainer>
      <AlarmIndicator alarmIndicator={alarmIndicator} size="small" title={value} />
    </>
  )
}
