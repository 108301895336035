import { useEffect, useRef } from 'react'

import { useAppStateStore } from 'src/Store/appState'
import { QueryStringStateKeys, useQueryStringState } from 'src/Store/useQueryStringState'
import { getDateRange } from 'src/Features/SelectedDatePeriod/predefinedDatePeriods'

export const useSelectedDateRange = (dependencies: any[] = []) => {
  const [selectedDatePeriod] = useQueryStringState(QueryStringStateKeys.datePeriod)
  const dateRange = selectedDatePeriod ? getDateRange(selectedDatePeriod) : undefined
  const selectedDateRange = useRef(dateRange)

  const lastUpdated = useAppStateStore(state => state.selectedDatePeriod.lastUpdated)
  const newDeps = [lastUpdated, ...dependencies]
  const deps = useRef(newDeps)

  const depsEqual = newDeps.every((d, i) => d === deps.current[i])

  useEffect(() => {
    selectedDateRange.current = dateRange
    deps.current = newDeps
  }, newDeps) // eslint-disable-line react-hooks/exhaustive-deps

  return depsEqual ? selectedDateRange.current : dateRange
}
