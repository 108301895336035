/**
 * AssetStatusEntity contains the types referring to BN.M40.AssetStatus
 */

/**
 * This is AssetStatusDto in Orchestrate...
 */
export type AssetStatusEntry = {
  baneDataId: string
  status: AssetStatusEntityStatus
  startTime: number
  endTime: number
  causeId: number
  domain: AssetStatusEntryDomain
  startComment: string
  endComment?: string
  isManual: boolean
  createdTime?: number
  lastUpdatedTime?: number
  createdBy?: string
  lastUpdatedBy?: string
  cause?: AssetStatusCause
}

export type AssetStatusCause = { name_No: string; name_En: string; description_URL?: string | null }

/** Keep in sync with enum WindowStatusValue */
export enum AssetStatusEntityStatus {
  OutOfOrder = 'Out_of_Order',
  NoContact = 'NoContact',
  Maintenance = 'Maintenance',
  IrregularData = 'IrregularData',
}

export enum AssetStatusEntityStatusId {
  IrregularData = 1,
  NoContact = 2,
}

export type AssetStatusEntryCause = {
  status?: AssetStatusEntityStatus
  id: number
  name: {
    no: string
    en: string
  }
  domain?: AssetStatusEntryDomain
}

export enum AssetStatusEntryDomain {
  TrackCircuit = 'TrackCircuit',
  PointMachine = 'PointMachine',
  SwitchHeat = 'SwitchHeat',
  SignalEarthFault = 'SignalEarthFault',
  Vibration = 'Vibration',
  SurveillanceSystem = 'SurveillanceSystem',
  DerailerMachine = 'DerailerMachine',
  MachineHeat = 'MachineHeat',
}

export enum AssetStatusEntryDomainId {
  TrackCircuit = 1,
  PointMachine = 2,
  SwitchHeat = 3,
  SignalEarthFault = 4,
  Vibration = 5,
  SurveillanceSystem = 6,
  DerailerMachine = 7,
  MachineHeat = 8,
}
