import type { ReactNode } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import ExpandLessIcon from '@mui/icons-material/ExpandLessOutlined'
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreOutlined'

import { ExpandCollapseButton } from 'src/Components/ExpandCollapseButton'
import { BetaChip } from 'src/Components/BetaChip'

import themeColors from 'src/theme'
import { ignoreStyleProps } from 'src/Utils/style'

const StyledFullWidthContainer = styled('div')`
  border: 1px solid ${themeColors.sectionHeader};
  margin: 0;
`

const StyledActions = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
`

const StyledListItem = styled(
  ListItem,
  ignoreStyleProps('alwaysExpanded')
)<{ alwaysExpanded: boolean }>(
  props => `
  padding: 0;
  cursor: ${props.alwaysExpanded ? 'default' : 'pointer'};
  
  > .MuiListItemButton-root {
    background-color: ${themeColors.sectionHeader};
    padding: 0.5rem 1rem;
    
    ${props.theme.breakpoints.down('sm')} {
      padding: 6px 8px;
    }
    
    &:focus, &:hover {
      background-color: ${themeColors.sectionHeaderHover};
    }
  }
  
  .Mui-focusVisible {
    background-color: ${themeColors.sectionHeaderHover};
  }
`
)

type OwnProps = {
  title: string
  isDefaultExpanded?: boolean
  alwaysExpanded?: boolean
  children: ReactNode
  isBeta?: boolean
}

export const HeaderWithExpandableInfo = ({
  children,
  title,
  isDefaultExpanded = false,
  alwaysExpanded = false,
  isBeta = false,
}: OwnProps) => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(isDefaultExpanded || alwaysExpanded)

  const toggleExpanded = () => setExpanded(isExpanded => !isExpanded)

  const hideText = `${t('general.hide')} ${title.toLowerCase()}`

  const expandButton = expanded ? <ExpandLessIcon fontSize="large" /> : <ExpandMoreIcon fontSize="large" />

  return (
    <StyledFullWidthContainer>
      <List disablePadding>
        <StyledListItem alwaysExpanded={alwaysExpanded}>
          <ListItemButton onClick={!alwaysExpanded ? toggleExpanded : undefined}>
            <ListItemText
              primary={
                <>
                  {title} {isBeta && <BetaChip />}
                </>
              }
              primaryTypographyProps={{ variant: 'h5' }}
            />
            {!alwaysExpanded && expandButton}
          </ListItemButton>
        </StyledListItem>
      </List>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {children}
        {!alwaysExpanded && (
          <StyledActions>
            <ExpandCollapseButton isExpanded={expanded} onToggleExpand={toggleExpanded} largeIcon text={hideText} />
          </StyledActions>
        )}
      </Collapse>
    </StyledFullWidthContainer>
  )
}
