import { PublicClientApplication } from '@azure/msal-browser'
import type { AxiosRequestConfig, RawAxiosRequestHeaders } from 'axios'
import axios from 'axios'

import type { ApiType } from './Types/ApiType'

import type { APIConfig } from 'src/Config/Config'
import config from 'src/Config/Config'
import { parseDates } from './Utils/format'
import { getSessionFeatures } from 'src/Providers/SessionFeatures'

const { API, MSAL_CONFIG } = config

type Options = {
  [key: string]: any
}

let msalInstance: PublicClientApplication | undefined
let resolveMsalAuthenticated: (instance: PublicClientApplication) => void
let rejectMsalAuthenticated: (reason: any) => void
export const msalAuthenticatedPromise = new Promise<PublicClientApplication>((resolve, reject) => {
  resolveMsalAuthenticated = resolve
  rejectMsalAuthenticated = reject
})

export const createInstance = () => {
  if (msalInstance) {
    return msalInstance
  }
  try {
    msalInstance = new PublicClientApplication(MSAL_CONFIG)
    msalInstance
      .initialize()
      .then(() => msalInstance!.handleRedirectPromise())
      .then(() => resolveMsalAuthenticated(msalInstance!))
      .catch(e => rejectMsalAuthenticated(e))
    return msalInstance
  } catch (e) {
    rejectMsalAuthenticated(e)
    throw e
  }
}

export const getInstance = () => msalInstance

export const getAccount = () => {
  if (!msalInstance) {
    return undefined
  }

  const allAccounts = msalInstance.getAllAccounts()
  const account =
    allAccounts.find(acc => acc.username.includes('spordrift')) ||
    allAccounts.find(acc => acc.username.includes('banenor')) ||
    allAccounts[0]

  return account
}

export const callMSGraph = (endpoint: string, token: string, options: Options) => {
  const optionalHeaders: RawAxiosRequestHeaders = {}
  try {
    const sessionFeatures = getSessionFeatures()
    if (sessionFeatures.length) {
      optionalHeaders['app-features'] = sessionFeatures.join(',')
    }
  } catch (e) {
    console.warn(e)
  }

  return axios({
    ...options,
    headers: {
      Authorization: `Bearer ${token}`,
      ...optionalHeaders,
    },
    url: endpoint,
  })
}

export const callAPI = async (urlFragment: string, apiType: ApiType, options: Options) => {
  const account = getAccount()

  if (!account || !msalInstance) {
    throw new Error('unauthorized')
  }

  const apiConfig: APIConfig = API[apiType]

  const url = `${apiConfig.endpoint}${urlFragment}`

  return msalInstance
    .acquireTokenSilent({
      ...apiConfig.tokenRequest,
      account,
    })
    .then(response => callMSGraph(url, response.accessToken, options))
}

/**
 * Performs a GET call against an msal-based API.
 *
 * @param url - URL fragment to call. The base part of the URL used is based on the API type provided.
 * @param apiType - The API to use. The correct base URL and application ID will automatically be used.
 * @param options - Any additional axios options to use or override.
 */
export const callApiGet = <T = any>(url: string, apiType: ApiType, options: Options = {}): Promise<T> => {
  return callAPI(url, apiType, {
    ...options,
    method: 'get',
  }).then(res => res.data)
}

/**
 * Performs a POST call against an msal-based API.
 *
 * @param url - URL fragment to call. The base part of the URL used is based on the API type provided.
 * @param apiType - The API to use. The correct base URL and application ID will automatically be used.
 * @param data - POST body.
 * @param options - Any additional axios options to use or override.
 */
export const callApiPost = <T = any>(url: string, apiType: ApiType, data: string, options: Options = {}): Promise<T> => {
  return callAPI(url, apiType, {
    ...options,
    method: 'post',
    data,
  }).then(res => res.data)
}

/**
 * Performs a PUT call against an msal-based API.
 *
 * @param url - URL fragment to call. The base part of the URL used is based on the API type provided.
 * @param apiType - The API to use. The correct base URL and application ID will automatically be used.
 * @param body - The body of the query.
 * @param options - Any additional axios options to use or override.
 */
export const callApiPut = <T = any>(url: string, apiType: ApiType, body: Options, options: Options = {}): Promise<T> => {
  return callAPI(url, apiType, {
    ...options,
    method: 'put',
    data: body,
  }).then(res => res.data)
}

/**
 * Performs a PATCH call against an msal-based API.
 *
 * @param url - URL fragment to call. The base part of the URL used is based on the API type provided.
 * @param apiType - The API to use. The correct base URL and application ID will automatically be used.
 * @param body - The body of the query.
 * @param options - Any additional axios options to use or override.
 */
export const callApiPatch = <T = any>(url: string, apiType: ApiType, body: Options, options: Options = {}): Promise<T> => {
  return callAPI(url, apiType, {
    ...options,
    method: 'patch',
    data: body,
  }).then(res => res.data)
}

/**
 * Performs a DELETE call against an msal-based API.
 *
 * @param url - URL fragment to call. The base part of the URL used is based on the API type provided.
 * @param apiType - The API to use. The correct base URL and application ID will automatically be used.
 * @param options - Any additional axios options to use or override.
 */
export const callApiDelete = <T = any>(url: string, apiType: ApiType, options: Options = {}): Promise<T> => {
  return callAPI(url, apiType, {
    ...options,
    method: 'delete',
  }).then(res => res.data)
}

type AxiosConfigWithParseDates = AxiosRequestConfig & {
  parseDates?: boolean
}

axios.interceptors.response.use(response => {
  // Try to parse all ISO 8601 dates found in the response
  const config: AxiosConfigWithParseDates = response.config
  if (config.parseDates === true) {
    parseDates(response.data)
  }

  return response
})
