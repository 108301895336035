import * as d3 from 'd3'
import * as d3zoom from 'd3-zoom'
import { nanoid } from 'nanoid'
import { format } from 'date-fns/format'
import { areIntervalsOverlapping } from 'date-fns/areIntervalsOverlapping'

import { resetZoomButton, zoomInButton, zoomOutButton } from 'src/Components/Chart/chartStyles'
import { getTooltipMarkup } from 'src/Components/Chart/ChartRenderer'

import type {
  SimpleSelection,
  LinearTimeScale,
  LinearNumberScale,
  Extent,
  ChartLabels,
  Rect,
  SVGGSelection,
  SVGRectSelection,
  NumberInterval,
  LogNumberScale,
  ChartEntry,
  ChartFocusUpdatedEventHandler,
} from 'src/Types/ChartTypes'
import { Language } from 'src/Types/LanguageTypes'
import type { SensorHistory } from 'src/Types/SensorTwin'
import type { SensorCode } from 'src/Types/SensorCode'
import type { AggregatedBenderAlarm } from 'src/Types/AggregatedBenderAlarm'
import type { AlarmType } from 'src/Types/AlarmType'
import type { Bender } from 'src/Types/Bender'

import { maxNum, clamp } from 'src/Utils/number'
import { createTimeMultiFormat } from 'src/Utils/createTimeMultiFormat'
import { getD3Locale } from 'src/Language/d3Locale'
import { formatNumber } from 'src/Utils/format'
import {
  getTooltipPosition,
  tooltipArrowBoxWidth,
  tooltipArrowPolygon,
  getTooltipConfig,
  getChartLineColorByIndex,
} from 'src/Utils/chart'
import themeColors from 'src/theme'
import { enablePageScrollForChartRenderer } from 'src/Components/Chart/enablePageScrollForChartRenderer'

type ChartProps = {
  chart: HTMLDivElement
  timeline: NumberInterval
  numFullScaleSensors: number
  classes: {
    tooltip: string
    tooltipArrow: string
    zoomButton: string
  }
  alarmTypes: AlarmType[]
  onZoomUpdated?: ChartFocusUpdatedEventHandler
}

const OPTIMIZE_DATA = true
const Y_AXIS_SCALE = 1000000
const Y_AXIS_UNIT = 'Ω'
const Y_AXIS_UNIT_SCALED = `M ${Y_AXIS_UNIT}`
const Y_AXIS_MIN = 1000
const Y_ZERO = 0.000001 // Can't do log(0)

const parseValueHistory = (sensorHistory: SensorHistory, timeline: NumberInterval, dataPointThreshold: number): ChartEntry[] => {
  const mapped = sensorHistory.values ? [...sensorHistory.values] : []

  if (typeof sensorHistory.currentAtFromTime === 'number') {
    mapped.unshift([timeline.start, sensorHistory.currentAtFromTime])
  }

  if (mapped.length) {
    // Extend each line to the end of the chart by duplicating the last point unless it's too old
    const lastEntry = mapped.slice(-1)[0]

    if (dataPointThreshold <= 0 || timeline.end - lastEntry[0] < dataPointThreshold) {
      mapped.push([timeline.end, lastEntry[1]])
    }
  }

  if (!OPTIMIZE_DATA) {
    return mapped
  }

  return mapped
    .flatMap<ChartEntry>((entry, i, arr) => {
      const next = arr[i + 1]
      if (next === undefined || dataPointThreshold <= 0 || next[0] - entry[0] < dataPointThreshold) {
        return [entry]
      }
      return [entry, [entry[0], null]]
    })
    .filter((entry, i, arr) => {
      // Always return the first and last entries
      if (i === 0 || i === arr.length - 1) {
        return true
      }

      // Optimize by skipping entries with the same measurement as the next.
      // If the rendered step algorithm is changed to curveStepAfter, this should
      // compare against the previous entry instead
      return entry[1] !== arr[i - 1][1]
    })
}

const parseAlarms = (alarms: AggregatedBenderAlarm[]) =>
  alarms.reduce<AggregatedBenderAlarm[]>((acc, alarm) => {
    // Attempt to join sequential alarms
    const prev = acc[acc.length - 1]
    let add = true
    if (prev?.lastRealToDateTime === alarm.firstRealFromDateTime) {
      add = false
      prev.firstRealFromDateTime = alarm.lastRealToDateTime
    }

    if (add) {
      acc.push({ ...alarm })
    }

    return acc
  }, [])

const renderValueCurve = (
  data: [number, number | null][],
  xScale: LinearTimeScale,
  yScale: LinearNumberScale,
  context: CanvasRenderingContext2D
) => {
  const mapped = data.flatMap<[number, number]>(d => {
    const v = d[1]
    return v !== null ? [[d[0], v]] : [[d[0], -Infinity]]
  })

  return d3
    .line()
    .curve(d3.curveStepAfter)
    .x(d => xScale(d[0])!)
    .y(d => yScale(d[1] || Y_AXIS_MIN)!)
    .defined(d => d[1] !== -Infinity)
    .context(context)(mapped)
}

const cullData = (data: [number, number | null][], left: number, right: number) => {
  const firstIndex = data.findIndex((o, i) => data[i + 1] && data[i + 1][0] >= left)

  const lastIndex = data
    .slice()
    .reverse()
    .findIndex((o, i) => data[i] && data[i][0] >= right)

  // If not found, use the rest of the array
  const lastEntry = lastIndex === -1 ? data.length : lastIndex + 1

  if (firstIndex !== -1) {
    return data.slice(firstIndex, lastEntry)
  }

  return []
}

const formatTick = (unit: string, decimals: number, value: number) => `${formatNumber(value, decimals, false)} ${unit}`

const formatYTick = (value: number) => formatTick(Y_AXIS_UNIT_SCALED, 2, value / Y_AXIS_SCALE)

enum ElementClass {
  canvas = 'canvas',
  dataCurve = 'dataCurve',
  tooltipPoint = 'tooltip-point',
  tooltipArrow = 'tooltip-arrow',
  tooltipPoints = 'tooltip-points',
  zoomInButton = 'zoom-in-button',
  zoomOutButton = 'zoom-out-button',
  resetZoomButton = 'reset-zoom-button',
  alarm = 'alarm',
  alarmText = 'alarm-text',
}
const classSelector = (key: ElementClass) => `.${key}`

const CHART_MARGIN: Rect = { top: 36, right: 0, bottom: 20, left: 60 }
const CHART_HEIGHT = 400
const ZOOM_BUTTON_MARGIN = 10
const ZOOM_BUTTON_SPACING = 35
const MIN_ZOOM = 1
const MAX_ZOOM_MAGIC_CONSTANT = 1000000 // This magic number results in a nice max zoom resolution
const X_PIXELS_PER_TICK = 90
const PIXEL_RATIO = 1
const TOOLTIP_DATE_FORMAT = 'dd.MM.yyyy HH:mm:ss'
const TOOLTIP_POINT_RADIUS = 4
const ALARM_TEXT_FONT_SIZE = 14
const ANNOTATION_LINE_HEIGHT = 16
const ANNOTATION_OFFSET = ANNOTATION_LINE_HEIGHT + 4
// const ANNOTATION_SPACING = 4
const MIN_ALARM_WIDTH = 2

export class BenderIsolationHistoryRenderer {
  chart: HTMLDivElement

  chartWidth: number

  timeline: NumberInterval

  sensorHistory: SensorHistory[] = []

  dataCurves: ChartEntry[][] = []

  alarms?: AggregatedBenderAlarm[]

  alarmTypes: AlarmType[]

  inactiveSensors: number[] = []

  benders: Bender[] = []

  isMultipleBenderView: boolean = false

  numFullScaleSensors: number

  svgContainer: SimpleSelection<SVGSVGElement>

  canvas: SimpleSelection<HTMLCanvasElement>

  canvasContext: CanvasRenderingContext2D

  offScreenCanvas: HTMLCanvasElement

  offScreenContext: CanvasRenderingContext2D

  xScale: LinearTimeScale

  yLeftScale: LogNumberScale

  yRightScale: LinearNumberScale

  currentXScale: LinearTimeScale

  currentYScale: LinearNumberScale

  currentYRightScale: LinearNumberScale

  xAxis: SVGGSelection

  yAxis: SVGGSelection

  yAxisGrid: SVGGSelection

  // yAxis2: SVGGSelection

  zoom: d3.ZoomBehavior<SVGSVGElement, unknown>

  showZoomButtons = true

  onZoomUpdated?: ChartFocusUpdatedEventHandler

  clipUrl: string

  svgClipPath: SVGRectSelection

  mouseOverlay: SVGRectSelection

  tooltip: SimpleSelection<HTMLDivElement>

  tooltipArrow: SimpleSelection<SVGSVGElement>

  tooltipPoints: SVGGSelection

  multiTimeFormat?: ReturnType<typeof createTimeMultiFormat>

  labels: ChartLabels = {}

  seriesSensorCodes: SensorCode[] = []

  currentLanguage: Language = Language.no

  constructor({ chart, timeline, numFullScaleSensors, classes, alarmTypes, onZoomUpdated }: ChartProps) {
    this.chart = chart
    this.chartWidth = 0
    this.timeline = timeline
    this.numFullScaleSensors = numFullScaleSensors
    this.alarmTypes = alarmTypes

    this.xScale = d3.scaleTime()

    this.yLeftScale = d3.scaleLog().range([CHART_HEIGHT - CHART_MARGIN.bottom, CHART_MARGIN.top])

    this.yRightScale = d3.scaleLinear().range([CHART_HEIGHT - CHART_MARGIN.bottom, CHART_MARGIN.top])

    this.currentXScale = this.xScale
    this.currentYScale = this.yLeftScale
    this.currentYRightScale = this.yRightScale

    this.zoom = d3zoom.zoom<SVGSVGElement, any>().on('zoom', event => this.onZoom(event))

    this.onZoomUpdated = onZoomUpdated

    const canvasHeight = Math.floor(CHART_HEIGHT * PIXEL_RATIO)

    this.canvas = d3
      .select(this.chart)
      .html(null)
      .append('canvas')
      .style('height', `${CHART_HEIGHT}px`)
      .attr('height', canvasHeight)
      .style('width', '10px')
      .attr('width', 10)
      .style('position', 'absolute')
      .style('pointer-events', 'none')
      .classed(ElementClass.canvas, true)

    this.canvasContext = this.canvas.node()!.getContext('2d')!
    this.canvasContext.scale(PIXEL_RATIO, PIXEL_RATIO)

    this.offScreenCanvas = document.createElement('canvas')
    this.offScreenCanvas.height = canvasHeight
    this.offScreenContext = this.offScreenCanvas.getContext('2d')!
    this.offScreenContext.scale(PIXEL_RATIO, PIXEL_RATIO)

    this.svgContainer = d3.select(this.chart).append('svg').attr('height', CHART_HEIGHT)

    const clipId = nanoid()
    this.clipUrl = `url(#${clipId})`

    this.svgClipPath = this.svgContainer
      .append('clipPath')
      .attr('id', clipId)
      .append('rect')
      .attr('x', CHART_MARGIN.left + 1)
      .attr('y', CHART_MARGIN.top)
      .attr('height', CHART_HEIGHT - CHART_MARGIN.top - CHART_MARGIN.bottom)

    this.xAxis = this.svgContainer.append('g').attr('transform', `translate(0,${CHART_HEIGHT - CHART_MARGIN.bottom})`)

    this.yAxisGrid = this.svgContainer
      .append('g')
      .attr('transform', `translate(${CHART_MARGIN.left},0)`)
      .attr('color', themeColors.disabled)

    this.yAxis = this.svgContainer.append('g').attr('transform', `translate(${CHART_MARGIN.left},0)`)

    this.mouseOverlay = this.svgContainer
      .append('rect')
      .attr('x', CHART_MARGIN.left + 1)
      .attr('y', CHART_MARGIN.top)
      .attr('height', CHART_HEIGHT - CHART_MARGIN.top - CHART_MARGIN.bottom)
      .style('fill', 'none')
      .style('pointer-events', 'all')
      .on('mouseout', () => this.hideTooltip())
      .on('mousemove', event => this.mouseMove(event as MouseEvent))

    enablePageScrollForChartRenderer(this.svgContainer, this.zoom)

    d3.select(this.chart)
      .append('button')
      .classed(ElementClass.resetZoomButton, true)
      .classed(classes.zoomButton, true)
      .style('right', `${CHART_MARGIN.right + ZOOM_BUTTON_MARGIN}px`)
      .style('bottom', `${CHART_MARGIN.bottom + 2 * ZOOM_BUTTON_SPACING + ZOOM_BUTTON_MARGIN}px`)
      .html(resetZoomButton)
      .on('click', () => this.resetZoom())

    d3.select(this.chart)
      .append('button')
      .classed(ElementClass.zoomInButton, true)
      .classed(classes.zoomButton, true)
      .style('right', `${CHART_MARGIN.right + ZOOM_BUTTON_MARGIN}px`)
      .style('bottom', `${CHART_MARGIN.bottom + ZOOM_BUTTON_SPACING + ZOOM_BUTTON_MARGIN}px`)
      .html(zoomInButton)
      .on('click', () => this.zoom.scaleBy(this.svgContainer.transition().duration(250), 1.5))

    d3.select(this.chart)
      .append('button')
      .classed(ElementClass.zoomOutButton, true)
      .classed(classes.zoomButton, true)
      .style('right', `${CHART_MARGIN.right + ZOOM_BUTTON_MARGIN}px`)
      .style('bottom', `${CHART_MARGIN.bottom + ZOOM_BUTTON_MARGIN}px`)
      .html(zoomOutButton)
      .on('click', () => this.zoom.scaleBy(this.svgContainer.transition().duration(250), 0.5))

    this.tooltip = d3.select(this.chart).append('div').classed(classes.tooltip, true).style('pointer-events', 'none')

    this.tooltipPoints = this.svgContainer
      .append('g')
      .classed(ElementClass.tooltipPoints, true)
      .style('pointer-events', 'none')
      .attr('clip-path', this.clipUrl)

    this.tooltipArrow = d3
      .select(this.chart)
      .append('svg')
      .style('position', 'absolute')
      .attr('height', getTooltipConfig().arrowHeight)
      .style('pointer-events', 'none')

    this.tooltipArrow.append('polygon').classed(classes.tooltipArrow, true).classed(ElementClass.tooltipArrow, true)

    this.hideTooltip()
    this.sizeUpdated()
  }

  getDomain() {
    if (this.sensorHistory.length === 0) {
      return {
        xMin: this.timeline.start,
        xMax: this.timeline.end,
        yMax: 0,
        yMin: 1,
      }
    }

    const sensorHistoryYVals = this.sensorHistory.slice(this.numFullScaleSensors).flatMap(history => {
      if (history.values?.length) {
        return history.values.map(v => v[1])
      }

      return typeof history.currentAtFromTime === 'number' ? [history.currentAtFromTime] : []
    })

    const maxYVal = maxNum(sensorHistoryYVals)

    return {
      xMin: this.timeline.start,
      xMax: this.timeline.end,
      yMax: maxYVal < 10000000 ? 10000000 : 100000000,
      yMin: Y_AXIS_MIN,
    }
  }

  hideTooltip() {
    this.tooltipPoints.style('display', 'none')
    this.tooltip.style('display', 'none')
    this.tooltipArrow.style('display', 'none')
  }

  updateTooltip(mouseX: number) {
    const xScale = this.currentXScale
    const yScale = this.currentYScale
    const yRightScale = this.currentYRightScale

    const overlayX = Math.round(clamp(mouseX, 0, this.chartWidth))
    const timelineDate = xScale.invert(overlayX)
    const timelineMillis = timelineDate.valueOf()

    const dataValues = this.dataCurves.flatMap((values, i) => {
      const dataPoint = values.find((v, i) => (i < values.length - 1 ? values[i + 1][0] >= timelineMillis : false))
      // Not found or before first entry
      if (!dataPoint || timelineMillis < values[0][0] || this.inactiveSensors.includes(i)) {
        return []
      }

      const codeSystem = this.sensorHistory[i].codeSystem
      const sensorId = this.sensorHistory[i].twinId

      return {
        value: dataPoint[1],
        label: this.isMultipleBenderView ? sensorId : codeSystem,
        index: i,
      }
    })

    const renderX = xScale(timelineMillis)
    const targetYLeft = maxNum([
      ...dataValues
        .slice(this.numFullScaleSensors)
        .filter(v => !this.inactiveSensors.includes(v.index))
        .map(v => v.value),
    ])
    const targetYRight = maxNum([
      ...dataValues
        .slice(0, this.numFullScaleSensors)
        .filter(v => !this.inactiveSensors.includes(v.index))
        .map(v => v.value),
    ])

    const renderYLeft = yScale(targetYLeft || 0)
    const renderYRight = yRightScale(targetYRight || 0)
    let renderY = Math.min(renderYLeft || Infinity, renderYRight || Infinity)
    if (renderY === Infinity) {
      renderY = CHART_HEIGHT - CHART_MARGIN.bottom
    }

    if (renderX !== undefined && (renderYLeft !== undefined || renderYRight !== undefined)) {
      if (!Number.isNaN(renderYLeft)) {
        this.tooltipPoints
          .selectAll<SVGCircleElement, SensorHistory>(classSelector(ElementClass.tooltipPoint))
          .attr('transform', (d, i) => {
            const scaleFunc = i < this.numFullScaleSensors ? yRightScale : yScale
            return `translate(${renderX},${scaleFunc(dataValues[i]?.value || Y_ZERO)})`
          })
          .style('display', (d, i) => (!dataValues[i] || this.inactiveSensors.includes(i) ? 'none' : null))
      }

      const tooltipText = dataValues
        .flatMap(val => {
          if (this.inactiveSensors.includes(val.index) || val.value === null) {
            return []
          }

          const sensorCodeKeyFromTwinId = val.label.split('.').length > 1 ? val.label.split('.')[1] : val.label
          const sensorCodeKey = this.isMultipleBenderView ? sensorCodeKeyFromTwinId : val.label
          const code = this.seriesSensorCodes.find(uc => uc.key === sensorCodeKey)
          const sensorName = this.benders.find(b => b.baneDataId === val.label.split('.')[0])?.name
          const textLabel = this.isMultipleBenderView && sensorName ? sensorName : code?.localization.full[this.currentLanguage]

          const text =
            textLabel && code
              ? `${textLabel}: ${val.value.toLocaleString('nb-NO')} ${code.metric.displayName}`
              : val.value.toLocaleString('nb-NO')

          return `<li style="color: ${getChartLineColorByIndex(val.index)}"><span>${text}</span></li>`
        })
        .join('')

      this.tooltip.html(getTooltipMarkup(format(timelineMillis, TOOLTIP_DATE_FORMAT), tooltipText, dataValues.length))

      const tooltipPosition = getTooltipPosition(renderX, renderY, this.tooltip.node()!, this.chartWidth, {
        chartMargin: CHART_MARGIN,
      })

      this.tooltip.style('left', `${tooltipPosition.box.x}px`).style('top', `${tooltipPosition.box.y}px`)

      const arrowPos = tooltipPosition.arrow
      const tipOffset = arrowPos.tipX - arrowPos.x
      this.tooltipArrow
        .attr('width', tooltipArrowBoxWidth(tipOffset))
        .style('left', `${Math.min(arrowPos.x, arrowPos.tipX)}px`)
        .style('top', `${arrowPos.y}px`)
        .select(classSelector(ElementClass.tooltipArrow))
        .attr('points', tooltipArrowPolygon(tipOffset))
    }
  }

  getAlarmColor(alarmTypeId: number) {
    const alarmType = this.alarmTypes.find(at => at.type.id === alarmTypeId)
    return alarmType?.classification.id === 'Alarm'
      ? themeColors.chartColors.alarms.error
      : themeColors.chartColors.alarms.warning
  }

  getAlarmTextColor(alarmTypeId: number) {
    const alarmType = this.alarmTypes.find(at => at.type.id === alarmTypeId)
    return alarmType?.classification.id === 'Alarm'
      ? themeColors.chartColors.alarmText.error
      : themeColors.chartColors.alarmText.warning
  }

  containedIntervalWidth(toDraw: NumberInterval, visible: NumberInterval) {
    // If the interval extends outside the current rendered interval, cut it in the relevant ends
    const xScale = this.currentXScale
    const scaledToDate = xScale(toDraw.end)!
    const scaledFromDate = xScale(toDraw.start)!
    const fullWidth = Math.max(scaledToDate - scaledFromDate, MIN_ALARM_WIDTH)
    const leftCutoff = Math.max(xScale(visible.start)! - scaledFromDate, 0)
    const rightCutoff = Math.max(scaledToDate - xScale(visible.end)!, 0)
    return fullWidth - leftCutoff - rightCutoff
  }

  mouseMove(event: MouseEvent) {
    const overlay = this.mouseOverlay.node()
    if (this.sensorHistory.length && overlay) {
      const mouseX = d3.pointer(event, overlay)[0]
      this.tooltipPoints.style('display', null)
      this.tooltip.style('display', null)
      this.tooltipArrow.style('display', null)
      this.updateTooltip(mouseX)
    }
  }

  setData(
    sensorHistory: SensorHistory[],
    alarms: AggregatedBenderAlarm[],
    dataPointThreshold: number,
    thresholdCodeSystems: string[]
  ) {
    this.sensorHistory = sensorHistory

    const domain = this.getDomain()

    this.dataCurves = sensorHistory.map(history => {
      const threshold = thresholdCodeSystems.includes(history.codeSystem) ? dataPointThreshold : 0
      return parseValueHistory(history, this.timeline, threshold)
    })
    this.alarms = alarms ? parseAlarms(alarms) : undefined

    this.xScale.domain([domain.xMin, domain.xMax])
    this.yLeftScale.domain([domain.yMin, domain.yMax]).nice()
    this.yRightScale.domain([0, 100])
    this.zoom.scaleExtent([MIN_ZOOM, (domain.xMax - domain.xMin) / MAX_ZOOM_MAGIC_CONSTANT])
    this.resetZoom()
  }

  setInactiveSensors(inactiveSensors: number[], render: boolean = true) {
    this.inactiveSensors = inactiveSensors
    if (render) {
      this.updateSensorVisibility()
      this.update()
    }
  }

  setBenders(benders: Bender[]) {
    this.benders = benders
    this.isMultipleBenderView = benders.length > 1
  }

  updateSensorVisibility() {
    this.svgContainer.selectAll(classSelector(ElementClass.dataCurve)).attr('display', (d, i) => {
      return this.inactiveSensors.includes(i) ? 'none' : null
    })
  }

  setLabels(labels: ChartLabels, seriesSensorCodes: SensorCode[], currentLanguage: Language) {
    this.labels = labels
    this.seriesSensorCodes = seriesSensorCodes
    this.currentLanguage = currentLanguage

    d3.select(this.chart).select(classSelector(ElementClass.resetZoomButton)).attr('title', labels.resetZoom)

    d3.select(this.chart).select(classSelector(ElementClass.zoomInButton)).attr('title', labels.zoomIn)

    d3.select(this.chart).select(classSelector(ElementClass.zoomOutButton)).attr('title', labels.zoomOut)

    this.multiTimeFormat = createTimeMultiFormat(getD3Locale(currentLanguage))
  }

  setShowZoomButtons(showZoomButtons: boolean) {
    this.showZoomButtons = showZoomButtons
  }

  private getChartWidth() {
    const parent = this.chart.parentElement
    return parent ? parent.clientWidth : 0
  }

  sizeUpdated() {
    const newWidth = Math.max(0, this.getChartWidth())
    if (newWidth !== this.chartWidth) {
      this.chartWidth = newWidth

      const clipWidth = this.chartWidth - CHART_MARGIN.left - CHART_MARGIN.right - 1
      const canvasWidth = Math.floor(this.chartWidth * PIXEL_RATIO)

      this.canvas.style('width', `${this.chartWidth}px`).attr('width', canvasWidth)

      this.canvasContext.beginPath()
      this.canvasContext.rect(
        CHART_MARGIN.left,
        CHART_MARGIN.top,
        this.chartWidth - CHART_MARGIN.left - CHART_MARGIN.right,
        CHART_HEIGHT - CHART_MARGIN.top - CHART_MARGIN.bottom
      )
      this.canvasContext.clip()

      this.offScreenCanvas.width = canvasWidth

      this.svgContainer.attr('viewBox', [0, 0, this.chartWidth, CHART_HEIGHT].toString()).attr('width', this.chartWidth)

      this.xScale.range([CHART_MARGIN.left, this.chartWidth - CHART_MARGIN.right])

      this.svgClipPath.attr('width', this.chartWidth - CHART_MARGIN.left - CHART_MARGIN.right)

      const extent: Extent = [
        [CHART_MARGIN.left, CHART_MARGIN.top],
        [this.chartWidth - CHART_MARGIN.right, CHART_HEIGHT - CHART_MARGIN.bottom],
      ]

      this.zoom.translateExtent(extent).extent(extent)

      this.resetZoom()

      this.mouseOverlay.attr('width', clipWidth)
    }
  }

  updateAlarms(left: number, right: number) {
    if (this.alarms && this.alarms.length > 0) {
      const xScale = this.currentXScale

      const visibleInterval: NumberInterval = { start: left, end: right }

      // Only render alarms within the render area, and fit them within the bounds for performance reasons.
      // Without this optimization, zooming far in cane make SVGs no longer render
      // because of the resulting extremely wide shapes
      const onScreen = this.alarms.filter(alarm =>
        areIntervalsOverlapping({ start: alarm.firstRealFromDateTime, end: alarm.lastRealToDateTime }, visibleInterval)
      )

      const alarmElements = this.svgContainer.selectAll<SVGRectElement, AggregatedBenderAlarm>(classSelector(ElementClass.alarm))

      alarmElements
        .filter(d => areIntervalsOverlapping(visibleInterval, { start: d.firstRealFromDateTime, end: d.lastRealToDateTime }))
        .attr('width', d =>
          this.containedIntervalWidth({ start: d.firstRealFromDateTime, end: d.lastRealToDateTime }, visibleInterval)
        )
        .attr('x', d => xScale(Math.max(d.firstRealFromDateTime, left))!)
        .style('display', null)

      alarmElements
        .filter(d => !onScreen.includes(d))
        .filter(d => !areIntervalsOverlapping(visibleInterval, { start: d.firstRealFromDateTime, end: d.lastRealToDateTime }))
        .style('display', 'none')

      if (this.alarms?.length === 1) {
        const alarmText = this.svgContainer.selectAll<SVGTextElement, AggregatedBenderAlarm>(
          classSelector(ElementClass.alarmText)
        )

        if (onScreen.length) {
          // Attach alarm text to the first visible alarm

          const targetX = xScale(onScreen[0].firstRealFromDateTime)

          const alarmTextWidth = alarmText.node()!.getBBox().width

          if (targetX !== undefined) {
            // const y = targetX + alarmTextWidth > this.chartWidth - paddingTextWidth - CHART_MARGIN.right - ANNOTATION_SPACING
            //   ? ANNOTATION_OFFSET - ANNOTATION_LINE_HEIGHT
            //   : ANNOTATION_OFFSET
            const y = ANNOTATION_OFFSET

            alarmText
              .attr('x', Math.min(Math.max(targetX, CHART_MARGIN.left), this.chartWidth - CHART_MARGIN.right - alarmTextWidth))
              .attr('y', y)
              .style('display', null)
          }
        } else {
          alarmText.style('display', 'none')
        }
      }
    }
  }

  private renderCurves(left: number = -Infinity, right: number = Infinity) {
    const ctx = this.offScreenContext

    ctx.clearRect(0, 0, this.chartWidth + 1, CHART_HEIGHT)
    this.dataCurves.forEach((dc, i) => {
      if (!this.inactiveSensors.includes(i)) {
        const culledData = cullData(dc, left, right)

        ctx.beginPath()
        ctx.lineWidth = 1
        ctx.strokeStyle = getChartLineColorByIndex(i)
        renderValueCurve(culledData, this.currentXScale, this.yLeftScale, ctx)
        ctx.stroke()
      }
    })

    this.canvasContext.clearRect(0, 0, this.chartWidth, CHART_HEIGHT)
    this.canvasContext.drawImage(this.offScreenCanvas, 0, 0)
  }

  update() {
    const xScale = this.currentXScale
    const left = xScale.invert(CHART_MARGIN.left).valueOf()
    const right = xScale.invert(this.chartWidth - CHART_MARGIN.right).valueOf()

    this.renderCurves(left, right)
    this.updateAlarms(left, right)

    let axisBottom = d3.axisBottom(xScale).ticks(Math.floor(this.chartWidth / X_PIXELS_PER_TICK))
    const timeFormat = this.multiTimeFormat
    if (timeFormat) {
      axisBottom = axisBottom.tickFormat(v => timeFormat(v.valueOf()))
    }

    this.xAxis.call(axisBottom)

    d3.select(this.chart)
      .selectAll(classSelector(ElementClass.zoomInButton))
      .style('display', this.showZoomButtons ? 'inherit' : 'none')
    d3.select(this.chart)
      .selectAll(classSelector(ElementClass.zoomOutButton))
      .style('display', this.showZoomButtons ? 'inherit' : 'none')
    d3.select(this.chart)
      .selectAll(classSelector(ElementClass.resetZoomButton))
      .style('display', this.showZoomButtons ? 'inherit' : 'none')
  }

  resetZoom() {
    this.zoom.transform(this.svgContainer.transition().duration(0), d3.zoomIdentity)
  }

  onZoom(event: d3.D3ZoomEvent<SVGSVGElement, any>) {
    const transform: d3.ZoomTransform = event.transform
    this.currentXScale = transform.rescaleX(this.xScale)

    this.hideTooltip()
    this.update()

    if (this.onZoomUpdated) {
      const fromDate = this.currentXScale.invert(CHART_MARGIN.left).valueOf()
      const toDate = this.currentXScale.invert(this.chartWidth).valueOf()
      this.onZoomUpdated(fromDate, toDate, transform)
    }
  }

  render() {
    const yLeftScale = this.currentYScale

    this.yAxis.call(
      d3
        .axisLeft(yLeftScale)
        .tickFormat(v => formatYTick(v.valueOf()))
        .ticks(4)
    )

    this.yAxisGrid.call(
      d3
        .axisLeft(yLeftScale)
        .tickFormat(v => '')
        .ticks(4)
        .tickSize(-this.chartWidth + CHART_MARGIN.right + CHART_MARGIN.left)
    )

    this.svgContainer.selectAll(classSelector(ElementClass.alarm)).remove()

    this.svgContainer.selectAll(classSelector(ElementClass.alarmText)).remove()

    if (this.alarms && this.alarms?.length > 0) {
      this.svgContainer
        .selectAll(classSelector(ElementClass.alarm))
        .data(this.alarms)
        .enter()
        .insert('rect', 'g')
        .classed(ElementClass.alarm, true)
        .attr('fill', alarm => this.getAlarmColor(alarm.alarmTypeId))
        .attr('height', CHART_HEIGHT - CHART_MARGIN.top - CHART_MARGIN.bottom)
        .attr('y', CHART_MARGIN.top)
        .style('pointer-events', 'none')
        .attr('clip-path', this.clipUrl)

      if (this.alarms.length === 1) {
        this.svgContainer
          .selectAll(classSelector(ElementClass.alarmText))
          .data(this.alarms)
          .join('text')
          .classed(ElementClass.alarmText, true)
          .attr('font-size', ALARM_TEXT_FONT_SIZE)
          .attr('fill', alarm => this.getAlarmTextColor(alarm.alarmTypeId))
          .attr('dominant-baseline', 'hanging')
          .attr('y', ANNOTATION_OFFSET)
          .text(alarm => {
            const alarmType = this.alarmTypes.find(at => at.type.id === alarm.alarmTypeId)
            return alarmType ? alarmType.type.localization.full[this.currentLanguage] : ''
          })
      }
    }

    // Tooltip
    this.tooltipPoints
      .raise()
      .selectAll(classSelector(ElementClass.tooltipPoint))
      .data(this.dataCurves)
      .join('circle')
      .classed(ElementClass.tooltipPoint, true)
      .attr('r', TOOLTIP_POINT_RADIUS)

    this.update()
  }
}
