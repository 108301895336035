import { styled } from '@mui/material/styles'

const StyledSvg = styled('svg')`
  vertical-align: middle;
`

type OwnProps = {
  dashed?: boolean
  color: string
  bandWidth?: number
}

const ICON_WIDTH = 16

export const LegendBand = ({ dashed, color = 'currentColor', bandWidth = 2 }: OwnProps) => (
  <StyledSvg height={bandWidth} width={ICON_WIDTH}>
    <path strokeWidth={bandWidth * 2} stroke={color} strokeDasharray={dashed ? '6 4' : undefined} d={`M0,0H${ICON_WIDTH}`} />
  </StyledSvg>
)
