import { useState } from 'react'
import Box from '@mui/material/Box'

import { StyledMissingValueText, StyledLegendButton } from './StyledLegendComponents'
import { SwingSetReferenceCurveDialog } from './SwingSetReferenceCurveDialog'

import type { MachineSwing } from 'src/Types/SwingTypes'
import { formatLocalDateTime } from 'src/Utils/format'
import { flexibleTranslation } from 'src/Utils/language'

import { saveReferenceCurve } from 'src/Providers/ReferenceCurve'
import { useAppStateStore } from 'src/Store/appState'
import { useAccess } from 'src/Hooks/useAccess'
import { useBreakpointDown } from 'src/Hooks/useBreakpoint'
import { useLanguage } from 'src/Hooks/useLanguage'

type OwnProps = {
  swing: MachineSwing
  onShowReferenceCurveModal?: () => void
  onHideReferenceCurveModal?: () => void
}

export const SetReferenceCurveButton = ({ swing, onShowReferenceCurveModal, onHideReferenceCurveModal }: OwnProps) => {
  const { t } = useLanguage()
  const isMediaSM = useBreakpointDown('sm')
  const [referenceCurveSwing, setReferenceCurveSwing] = useState<MachineSwing>()
  const hasReferenceCurveWriteAccess = useAccess('ReferenceCurve.Write')
  const queueMessage = useAppStateStore(state => state.appMessages.queueMessage)

  const setReferenceCurve = (swing: MachineSwing) => {
    setReferenceCurveSwing(swing)
    onShowReferenceCurveModal?.()
  }

  const handleAcceptSetReferenceCurve = async () => {
    if (!referenceCurveSwing) {
      return
    }
    try {
      await saveReferenceCurve(referenceCurveSwing.baneDataId, referenceCurveSwing.machineSwingId)
    } catch (error) {
      queueMessage({
        type: 'error',
        message: 'switches.setReferenceCurve.failureMessage',
      })
      return
    }
    queueMessage({
      type: 'success',
      raw: true,
      message: t('switches.setReferenceCurve.successMessage', {
        date: referenceCurveSwing.timestamp ? formatLocalDateTime(referenceCurveSwing.timestamp, false) : '-',
        baneDataName: referenceCurveSwing.baneDataName,
      }),
    })
    setReferenceCurveSwing(undefined)
    onHideReferenceCurveModal?.()
  }

  const handleRejectSetReferenceCurve = () => {
    setReferenceCurveSwing(undefined)
    onHideReferenceCurveModal?.()
  }

  const getReferenceCurveButton = () => (
    <div>
      {!swing.direction ? (
        <StyledMissingValueText>
          {t(flexibleTranslation('switches.setReferenceCurve.missingDirection', isMediaSM))}
        </StyledMissingValueText>
      ) : (
        <Box mt={3}>
          <StyledLegendButton
            type="button"
            active={false}
            title={t('switches.setReferenceCurve.buttonLabel')}
            onClick={() => setReferenceCurve(swing)}
          >
            <span>{t('switches.setReferenceCurve.buttonLabel')}</span>
          </StyledLegendButton>
        </Box>
      )}
    </div>
  )

  const getReferenceCurveModal = () => {
    if (!referenceCurveSwing || !referenceCurveSwing.timestamp) {
      return undefined
    }
    return (
      <SwingSetReferenceCurveDialog
        baneDataName={referenceCurveSwing.baneDataName}
        newCurveDate={referenceCurveSwing.referenceCurve?.date}
        currentCurveDate={referenceCurveSwing.timestamp}
        onAccept={handleAcceptSetReferenceCurve}
        onReject={handleRejectSetReferenceCurve}
      />
    )
  }

  if (!hasReferenceCurveWriteAccess) {
    return null
  }

  return (
    <>
      {getReferenceCurveButton()}
      {getReferenceCurveModal()}
    </>
  )
}
